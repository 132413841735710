import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
} from '@ant-design/icons';
import { Layout } from 'antd';
import React, { useState } from 'react';
import styles from './CoreLayoutComponent.module.css';
import TopNavigationBar from "../topNavBar/TopNavigationBar";
import classNames from "classnames";
import {LeftSideNavigation} from "../leftSideNavigation/LeftSideNavigation";

const { Header, Sider, Content } = Layout;

export const CoreLayoutComponent: (props: PropTypes) => JSX.Element = (props: PropTypes) => {
    const Component: React.Component = props.component;
    const [collapsed, setCollapsed] = useState(true);

    const renderLeftSideNav = () => {
        return (
            <Sider className={styles.navSidebar} trigger={null} collapsible collapsed={collapsed}>
                <LeftSideNavigation collapsed={collapsed}/>
            </Sider>
        );
    };

    const renderHeader = () => {
        return (
            <Header className={classNames(styles.header, {[styles.headerMarginCollapsed]: collapsed})}>
                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                    className: styles.trigger,
                    onClick: () => setCollapsed(!collapsed),
                })}
                <TopNavigationBar toggleSideMenu={() => {}} {...props} />
            </Header>
        );
    };

    return (
        <Layout className={styles.layout}>
            {renderLeftSideNav()}
            <Layout className={classNames(styles.coreLayout, {[styles.coreLayoutMarginCollapsed]: collapsed})}>
                {renderHeader()}
                <Content className={classNames(styles.CoreSection)}>
                    {/* @ts-ignore */}
                    <Component {...props}/>
                </Content>
            </Layout>
        </Layout>
    );
};

type PropTypes = {
    component: React.Component;
    removeAllCookies: () => void;
    onSignOut: () => void;
};
