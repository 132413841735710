import {RestServices} from "@eazy2biz/common-package-ui";

const CUSTOMER_TABLE_SERVICE = () => RestServices.getInstance().getCustomerTableService();

export const getAllCustomerTables = () => {
    return CUSTOMER_TABLE_SERVICE().getAllCustomerTablesForCompany();
};

export const getAllRecordsForTable = (tableId: string) => {
    return CUSTOMER_TABLE_SERVICE().getAllCustomerTableRecordsForCustomerTable(tableId);
};

export const getCustomerTableById = (id: string) => {
    return CUSTOMER_TABLE_SERVICE().getCustomerTableById(id);
};
