import { DragListItemConfig } from "../../DragListItemConfig";
import styles from './DragListItem.module.css';
import { HamburgerMenuIcon, EyeOpenIcon, EyeNoneIcon } from '@radix-ui/react-icons';
import { cloneDeep } from "lodash";
import { Draggable, DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import React from "react";
import classNames from "classnames";

/**
 * Draggable List Item Component
 * @param props
 * @constructor
 */
export const DragListItem = (props: PropTypes) => {
  const hideIconProps = {
    className: styles.hideIconProps,
    onClick: () => {
      const updatedItem: DragListItemConfig = cloneDeep(props.item);
      updatedItem.selected = !updatedItem.selected;
      props.updateItem(updatedItem);
    }
  };

  const renderItem = (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
    return (
      <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className={styles.dragListItemWrapper}>
        <div className={classNames(styles.leftWrapper, props.listItemWrapperClassName)}>
          <HamburgerMenuIcon className={styles.hamburgerMenuButton}/>
          {
            props.customItemRender
                ? props.customItemRender(props.item)
                : (
                    <>
                      {props.item.name}
                    </>
                )
          }
        </div>
        {props.item.selected ? <EyeOpenIcon {...hideIconProps}/> : <EyeNoneIcon {...hideIconProps}/>}
      </div>
    );
  }

  return (
    <Draggable draggableId={props.item.id} index={props.index}>
      {renderItem}
    </Draggable>
  );
};

type PropTypes = {
  item: DragListItemConfig;
  index: number;
  updateItem: (item: DragListItemConfig) => void;
  listItemWrapperClassName?: string
  customItemRender?: (item : DragListItemConfig) => React.ReactNode;
}
