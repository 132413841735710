import {CardResponse, CardStatus, Stage, StageTypes} from "@eazy2biz/common-util";
import {checkIfUserPartOfUserGroups, getCurrentUserId} from "@eazy2biz/common-package-ui";

export const isSubmitButtonVisible = (type: StageTypes) => {
    return type === StageTypes.FORM_STAGE;
};

export const isItemCompleteAllowed = (stage: Stage, item: CardResponse, hasPermissionToOverrideStage: boolean = false): boolean => {
    return stage.type !== StageTypes.EXIT_STAGE &&
        item.status === CardStatus.ACTIVE &&
        (hasPermissionToOverrideStage ||
            item.assignee.includes(getCurrentUserId()) ||
            checkIfUserPartOfUserGroups(item.assigneeUserGroupIds || [])
        );
};
