import {ActionResult, ActionResultTypes, WhatsappMessageDetails} from "@eazy2biz/common-util";

export const getWhatsappMessageAction = (): ActionResult<WhatsappMessageDetails> => {
    return {
        type: ActionResultTypes.WHATSAPP_MESSAGE,
        resultDetails: {
            toPhoneNumbers: [],
            templateId: '',
            templateVariableMapping: {},
            enabled: true,
        }
    };
};
