import { VariableField } from "../../components/TextVariableInput/TextVariableInput";

export const sortVariableFieldsByName = (variableFields: VariableField[]): VariableField[] => {
  return variableFields.sort((vf1, vf2) => vf1.name > vf2.name ? 1 : -1);
};

export const updateVariableField = (
  id: string,
  value: string,
  variableFields: VariableField[]
): VariableField[] => {
  const copyOfVariableFields = Array.from(variableFields);
  const indexOfVariableField = copyOfVariableFields.findIndex(vf => {
    return (vf.id && vf.id === id) || (vf.name === id)
  });

  const variableFieldToUpdate = copyOfVariableFields[indexOfVariableField];

  copyOfVariableFields.splice(indexOfVariableField, 1);
  variableFieldToUpdate.value = value;
  copyOfVariableFields.push(variableFieldToUpdate);

  return copyOfVariableFields;
}
