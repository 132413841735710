import { TemplateCategory, TemplateCreateRequest, TemplateParameter, TemplateTypes, WhatsappMessageTemplateAdditionalInfo, WhatsappMessageTemplateDetails } from "@eazy2biz/common-util";
import {StrictBuilder} from "builder-pattern";

export const getWhatsappTemplateCreateRequest = (
  templateName: string,
  templateDetails: WhatsappMessageTemplateDetails,
  templateParameters: TemplateParameter[],
  templateAdditionalInfo: WhatsappMessageTemplateAdditionalInfo
): TemplateCreateRequest => {
  return StrictBuilder<TemplateCreateRequest>()
    .name(templateName)
    .category(TemplateCategory.WHATSAPP_MESSAGE)
    .type(TemplateTypes.USER)
    .templateDetails(templateDetails)
    .parameters(templateParameters)
    .templateAdditionalInfo(templateAdditionalInfo)
    .build()
};