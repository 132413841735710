import {Menu} from "antd";
import React, {ReactElement} from "react";
import {Footer} from "../footer/Footer";
import styles from './LeftSideNavigation.module.css';
import logo from "../../../../assets/eazy2biz_logo.png";
import shortLogo from "../../../../assets/short_logo.svg";
import {resolveFeatureFlag, RoutesEnum} from "@eazy2biz-ui/common-package";
import {useHistory} from "react-router-dom";
import classNames from "classnames";
import {HomeIcon, PersonIcon, CardStackIcon, TableIcon} from '@radix-ui/react-icons'
import {LEFT_SIDE_NAVIGATION} from "../../../../contents/DisplayContent";

export const LeftSideNavigation = (props: PropTypes) => {
    const {collapsed} = props;
    const history = useHistory();
    const defaultSelection = getSelectionFromPath(history.location.pathname);

    const renderMenuButtons = (config: MenuItemsConfig, index: number) => {
        const handleMenuItemClick = () => {
            config.onClick && config.onClick();

            if (!config.onClick && config.link) {
                if (config.link !== RoutesEnum.APP_DASHBOARD){
                    history.push(config.link);
                } else {
                    history.replace(config.link);
                }

            }
        };

        return (
            <Menu.Item
                key={index}
                icon={config.icon}
                eventKey={config.id}
                onClick={handleMenuItemClick}
            >
                {config.label}
            </Menu.Item>
        );
    };

    return(
        <div className={styles.sideBarWrapper}>
            <img className={styles.topNavBarLogo} src={collapsed? shortLogo : logo} alt='eazy2biz-logo' />
            <Menu
                className={classNames(styles.menu)}
                theme="dark"
                mode="inline"
                selectedKeys={[defaultSelection]}>
                {navButtonsList.map(renderMenuButtons)}
            </Menu>
            {!collapsed && <Footer/>}
        </div>
    );
};

type PropTypes = {
    collapsed: boolean;
};

interface MenuItemsConfig {
    id: string,
    label: string;
    icon: ReactElement;
    link?: RoutesEnum;
    onClick?: () => void;
}


const navButtonsList: MenuItemsConfig[] = [
    {
        id: '0',
        icon: <HomeIcon />,
        label: LEFT_SIDE_NAVIGATION.DASHBOARD,
        link: RoutesEnum.APP_DASHBOARD
    },
    {
        id: '1',
        icon: <PersonIcon />,
        label: LEFT_SIDE_NAVIGATION.USER_MANAGEMENT,
        link: RoutesEnum.USERS
    },
    {
        id: '2',
        icon: <TableIcon />,
        label: LEFT_SIDE_NAVIGATION.CUSTOMER_TABLES,
        link: RoutesEnum.CUSTOMER_TABLE
    },
    {
        id: '3',
        icon: <CardStackIcon />,
        label: LEFT_SIDE_NAVIGATION.TEMPLATES_MANAGEMENT,
        link: RoutesEnum.TEMPLATE_MANAGEMENT_HOMEPAGE
    },
];

const getSelectionFromPath = (path: string): string => {
    if (path.includes('manageUsers')) {
        return "1";
    }

    if (path.includes('tables')) {
        return "2";
    }

    if (path.includes('templateManagement')) {
        return "3";
    }

    return '0';
};
