import {Elements, FlowElement} from "react-flow-renderer";
import {FormEntity} from "@eazy2biz-ui/common-package";
import {StageData, StageOtherConfigurations} from "../../../entity/workflowBuilder/StageData";
import {
    findElementById,
    getEdgesFromSource,
    getFilteredElementsMap,
    getStartStageFromStages,
} from "../../../helpers/workflowBuilderHelpers/runtimeHelpers/WorkflowBuilderFlowHelper";
import {EdgeData} from "../../../entity/workflowBuilder/EdgeData";
import {LabelTypes} from "../../../components/canvas/types/LabelTypes";
import {cloneDeep, set} from "lodash";
import {transformActionsToPayload} from "./ActionsPayloadMapper";
import {Edge} from "react-flow-renderer/dist/types";
import {
    AccessTypes,
    Field,
    FieldTypes,
    GetStageFormRequest,
    Stage,
    StageTypes,
    Tag,
    WorkflowCreateRequest,
    WorkflowUpdateAttributes,
} from "@eazy2biz/common-util";
import {getApprovalFieldIdForStage} from "../../../helpers/formBuilderHelpers/FormFieldHelper";
import {StrictBuilder} from "builder-pattern";
import {getCurrentUserId, Logger} from "@eazy2biz/common-package-ui";
import {END_STAGE_ID} from "../../../components/workflowBuilder/initalData/InitalStages";

/**
 * Transforms into form payload.
 * @param form
 */
const transformFormToPayload = (form: FormEntity): Field[] => {
    return [
        ...form.formFields.filter((field) => field.type !== FieldTypes.APPROVAL),
        ...form.formFields.filter((field) => field.type === FieldTypes.APPROVAL)
    ];
};

/**
 * Transforms Approval Stage
 * @param stage
 * @param fields
 */
const transformApprovalStage = (stage: Stage, fields: Field[]): Stage => {
    const fieldId = getApprovalFieldIdForStage(fields, stage._id);
    set(stage, `fieldAccessMap.${fieldId}`, AccessTypes.RW);
    return stage;
};


/**
 * Recursive DFS Fn to form the stage payload.
 * @param stageEntity
 * @param outwardEdges
 * @param stages
 * @param edges
 * @param positiveLabels
 * @param endStages
 * @param visited array
 * @param fields form fields
 */
const transformBranchingStages = (
    stageEntity: Stage,
    stageOtherConfig: StageOtherConfigurations,
    outwardEdges: Edge[],
    stages: Elements<StageData>,
    edges: Elements<EdgeData>,
    positiveLabels: Map<string, FlowElement<StageData>>,
    endStages: Map<string, FlowElement<StageData>>,
    visited: Set<string>,
    fields: Field[]
) => {

    // Branch Stage
    const nextLeftEdge: Edge[] = getEdgesFromSource(edges as Edge[], outwardEdges[0].target);
    const nextRightEdge: Edge[] = getEdgesFromSource(edges as Edge[], outwardEdges[1].target);

    const nextLeftNode: FlowElement<StageData> = findElementById(stages, nextLeftEdge[0].target);
    const nextRightNode: FlowElement<StageData> = findElementById(stages, nextRightEdge[0].target);

    let nextStageId, branchStageId: string | undefined;

    if (positiveLabels.has(nextLeftEdge[0].source)) {
        nextStageId = nextRightNode.id;
        branchStageId = nextLeftNode.id;
    } else {
        nextStageId = nextLeftNode.id;
        branchStageId = nextRightNode.id;
    }

    // Merging all the end stages.
    if (nextStageId && endStages.has(nextStageId)) {
        stageEntity.nextStage = END_STAGE_ID;
    } else {
        stageEntity.nextStage = nextStageId;
    }

    // Merging all the end stages.
    if (branchStageId && endStages.has(branchStageId)) {
        branchStageId = END_STAGE_ID;
    }

    stageEntity = transformActionsToPayload(stageEntity, fields, stageOtherConfig, branchStageId);

    if (stageEntity.type === StageTypes.APPROVAL_STAGE) {
        stageEntity = transformApprovalStage(stageEntity, fields);
    }

    return [
        stageEntity,
        ...getStagesFromTree(nextLeftNode , stages, edges, positiveLabels, endStages, visited, fields),
        ...getStagesFromTree(nextRightNode , stages, edges, positiveLabels, endStages, visited, fields)
    ];
}

/**
 * Recursive DFS Fn to form the stage payload.
 * @param node
 * @param stages
 * @param edges
 * @param positiveLabels
 * @param endStages
 * @param visited array
* @param fields form fields
 */
const getStagesFromTree = (
    node: FlowElement<StageData>,
    stages: Elements<StageData>,
    edges: Elements<EdgeData>,
    positiveLabels: Map<string, FlowElement<StageData>>,
    endStages: Map<string, FlowElement<StageData>>,
    visited: Set<string>,
    fields: Field[]
): Stage[] =>
{
    if (endStages.has(node.id) || visited.has(node.id)) {
        return [];
    }

    visited.add(node.id);

    let stageEntity: Stage | undefined = node.data?.stageConfiguration;
    let stageOtherConfig = node.data?.otherConfigurations || {};

    if (!stageEntity) {
        throw getError();
    }

    // // @ts-ignore
    // stageEntity.fieldAccessMap = convertMapToObject(stageEntity.fieldAccessMap as Map<string, AccessTypes>);

    const outwardEdges: Edge[] = getEdgesFromSource(edges as Edge[], node.id);

    if (outwardEdges.length === 2) {
        // Branch Stage
        return transformBranchingStages(stageEntity, stageOtherConfig, outwardEdges, stages, edges, positiveLabels, endStages, visited, fields);
    } else {
        // Non-Branch Stage
        const nextNode: FlowElement<StageData> = findElementById(stages, outwardEdges[0].target);

        // Merging all the end stages and setting the nextStage for non-branch
        stageEntity.nextStage = endStages.has(nextNode.id) ? END_STAGE_ID : nextNode.id;

        stageEntity = transformActionsToPayload(stageEntity, fields, stageOtherConfig);

        return [stageEntity, ...getStagesFromTree(nextNode, stages, edges, positiveLabels, endStages, visited, fields)];
    }
}

/**
 * Transforms Stages into the stages payload.
 * @param stages
 * @param edges
 * @param fields form fields
 */
const transformIntoStagePayload = (stages: Elements<StageData>, edges: Elements<EdgeData>, fields: Field[]): Stage[] => {
    const positiveLabels: Map<string, FlowElement<StageData>> = getFilteredElementsMap(stages, [LabelTypes.APPROVED, LabelTypes.TRUE]);
    const endStages: Map<string, FlowElement<StageData>> = getFilteredElementsMap(stages, [StageTypes.EXIT_STAGE]);
    const visited: Set<string> = new Set<string>();

    const startStage: FlowElement<StageData> = getStartStageFromStages(stages);
    const endStage = transformEndStage(endStages);

    return [...getStagesFromTree( startStage, stages, edges, positiveLabels, endStages, visited, fields), endStage];
}

/**
 * Selecting one of the end stage and fixing end Stage id in the stage entity.
 * @param endStages
 */
const transformEndStage = (endStages: Map<string, FlowElement<StageData>>): Stage => {

    let endStageElement: FlowElement<StageData> | undefined;

    endStages.forEach((element) => endStageElement = element);

    if (!endStageElement || !endStageElement.data) {
        (new Logger("workflow-ui")).logError('', 'Error finding end stages for workflow', '', '');
        throw getError();
    }

    return endStageElement.data.stageConfiguration;
};

/**
 * Transform into workflow payload
 * @param name
 * @param description
 * @param stages
 * @param edges
 * @param form
 * @param tags
 */
export const getWorkflowCreateRequest = (
    name: string,
    description: string,
    stages: Elements,
    edges: Elements,
    form: FormEntity,
    tags: Tag[]
): WorkflowCreateRequest => {

    const fields: Field[] = transformFormToPayload(form);

    return StrictBuilder<WorkflowCreateRequest>()
        .name(name)
        .description(description)
        .access(getDefaultWorkflowAccess())
        .tags(tags)
        .fields(fields)
        .stages(transformIntoStagePayload(cloneDeep(stages), cloneDeep(edges), fields))
        .workflowBlueprint({
            stages,
            edges
        })
        .build();
};

export const getWorkflowUpdateAttributesFromWorkflowCreateRequest = (
    workflowCreateRequest : WorkflowCreateRequest
) : WorkflowUpdateAttributes => {
    return StrictBuilder<WorkflowUpdateAttributes>()
        .name(workflowCreateRequest.name)
        .description(workflowCreateRequest.description)
        .access(workflowCreateRequest.access)
        .tags(workflowCreateRequest.tags)
        .fields(workflowCreateRequest.fields)
        .stages(workflowCreateRequest.stages)
        .workflowBlueprint(workflowCreateRequest.workflowBlueprint)
        .build()
}

const getDefaultWorkflowAccess = () => ({
    "editors": [getCurrentUserId()],
    "readers": [getCurrentUserId()]
});

const getError = (message: string = 'Error while transforming stages'): Error => {
    return new Error(message);
}


export const getGetStageFormRequest = (workflowId: string, stageId?: string): GetStageFormRequest => {
    return StrictBuilder<GetStageFormRequest>()
        .workflowId(workflowId)
        .stageId(stageId)
        .build();
};

export const getStageFromStageArray = (stageArray: Array<any>, id: String) => {
    let stageData = {};
    stageArray.map((stage: any) => {
        if (stage._id === id) {
            stageData = stage;
        }
    });
    return stageData;
};
