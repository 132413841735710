export const TEMPLATE_DASHBOARD_HEADER = 'Manage Templates';

// action buttons
export const CREATE_NEW_TEMPLATE = {
  WHATSAPP: 'Create new whatsapp template',
  PDF: 'Create new PDF template'
};


export const CREATE_TEMPLATE_STRINGS = {
  CREATE: 'Create',
  UPDATE: 'Update',
  SECTION_NAME_STRINGS: {
    SETTINGS: 'Template settings',
    CONFIGURATION: 'Template configuration',
  },
  TEMPLATE_CREATION_SUCCESS: {
    WHATSAPP: 'Whatsapp template created successfully',
    PDF: 'Pdf template created successfully'
  },
  TEMPLATE_NOT_FOUND: 'Template not found',
  TEMPLATE_CREATION_FAILURE: {
    WHATSAPP: 'Failed to create new whatsapp template.',
    PDF: 'Failed to create new pdf template.'
  },
  TEMPLATE_CREATION_PROGRESS: {
    WHATSAPP: 'Creating new whatsapp template...',
    PDF: 'Creating new pdf template...'
  },
  PDF: {
    FILE_INPUT_LABEL: 'Content file (HTML only)',
    NON_HTML_FILE_ERROR: 'Only .html files allowed!',
    PARSING_ERROR: 'Error parsing document. Please try another document.',
  },
};

export const TEMPLATE_SETTING_STRINGS = {
  CATEGORY_HEADER: 'Category',
  CATEGORY_SUB_HEADER: 'Choose a message template.',
  NAME_HEADER: 'Name',
  NAME_SUB_HEADER: 'Name your message template.',
  NAME_INPUT_PLACEHOLDER: 'Enter message template name...',
  LANGUAGES_HEADER: 'Languages',
  LANGUAGES_SUB_HEADER: 'Choose languages for your message template. You can delete or add more languages later.',
  CATEGORY_STRINGS: {
    UTILITY_OPTION_HEADER: 'Utility',
    UTILITY_OPTION_SUB_HEADER: 'Send account updates, order updates, alerts, and more to share important information.',
    MARKETING_OPTION_HEADER: 'Marketing',
    MARKETING_OPTION_SUB_HEADER: 'Send promotional offers, product announcements, and more to increase awareness and engagement.',
    OTP_OPTION_HEADER: 'One-time passwords',
    OTP_OPTION_SUB_HEADER: 'Send codes that allow your customers to access their accounts.',
  }
};

export const TEMPLATE_CONFIGURATION_STRINGS = {
  HEADER: 'Header',
  HEADER_DESCRIPTION: "Add a title or choose which type of media you'll use for this header.",
  BODY: 'Body',
  BODY_DESCRIPTION: "Enter the text for your message in the language you've selected.",
  FOOTER_MESSAGE: 'via Eazy2Biz',
  OPTIONAL_TAG: 'optional',
  CREATE_TEMPLATE: 'Create',
  UPDATE_TEMPLATE: 'Update',
  ADD_VARIABLE: {
    ADD_VARIABLE_BUTTON: 'Add variable'
  }
};

/**
 * Generic Message content
 */
export const GENERIC_MESSAGE = {
  FOOTER_TEXT: 'Sent via eazy2biz'
};
