import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getCardByIdForWorkflow} from "../../../services/CardService";
import {Toast} from "@eazy2biz-ui/common-components";
import CardDetails from "../CardDetails";
import {CachedStore, StoreEntityTypes} from "@eazy2biz/common-package-ui";
import {CardResponse, WorkflowResponse} from "@eazy2biz/common-util";

/**
 * Accessing card details via direct url route.
 * This component internally uses CardDetails
 * @constructor
 */
export const ItemDetailsComponent = () => {
    // @ts-ignore
    const {itemId, workflowId} = useParams();
    const history = useHistory();

    const [item, setItem] = useState<CardResponse>();
    const [workflow, setWorkflow] = useState<WorkflowResponse>();

    useEffect(() => {
        CachedStore.getEntityFromListById<WorkflowResponse>(StoreEntityTypes.WORKFLOWS, workflowId)
            .then((response : WorkflowResponse) => {
                    setWorkflow(response)
                }
            ).catch(() => Toast.errorString())
        getCardByIdForWorkflow(itemId, workflowId)
            .then((response : CardResponse) => setItem(response))
            .catch(() => Toast.errorString());
    }, [itemId, workflowId]);

    if (!item || !workflow) {
        return null;
    }

    const handleClose = () => {
        history.replace('/workflow/' + workflowId);
    };

    return (
        <CardDetails
            workflow={workflow}
            cardData={item}
            cardModalShow
            onHide={handleClose}/>
    );
}
