import styles from "./IdentitiesWrapperModal.module.css";
import creative from "../../../../assets/Sign_in_up_creative.png";
import Modal from "react-bootstrap/Modal";
import {CloseButton, ModalTitle} from "@eazy2biz-ui/common-components";
import {ReactElement} from "react";
import { AND, PRIVACY_POLICY, SignUpPolicy, TERMS } from "../../../../contents/DisplayContent";
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from "@eazy2biz/common-package-ui";

/**
 * Generic Wrapper for Identities Modal for sign in and up pages.
 * @param props
 */
export const IdentitiesWrapperModal = (props: PropTypes) => {

    const renderFooter = (): ReactElement => {
        return (
          <div>
              {props.getFooter && props.getFooter()}
              {SignUpPolicy}
              <a className={styles.link} href={TERMS_OF_USE_URL} target={'_blank'}>{TERMS}</a>
              {AND}
              <a className={styles.link} href={PRIVACY_POLICY_URL} target={'_blank'}>{PRIVACY_POLICY}</a>
          </div>);
    };

    return(
        <Modal show dialogClassName={styles.identitiesModal} backdropClassName={styles.identitiesBackdrop}>
            <div className={styles.identitiesModalContent}>
                <img src={creative}/>
                <div className={styles.identitiesModalForm}>
                    <Modal.Header className={styles.identitiesModalHeader}>
                        <ModalTitle title={props.title} subTitle={props.subTitle}/>
                        <CloseButton onClose={props.onClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        {props.getBody()}
                    </Modal.Body>
                    <Modal.Footer>
                        {renderFooter()}
                    </Modal.Footer>
                </div>
            </div>
        </Modal>
    );
};

type PropTypes = {
    title: string;
    subTitle?: string;
    onClose: () => void;
    getBody: () => ReactElement;
    getFooter?: () => ReactElement;
};
