import {
  GenericButton,
  GenericCronField,
  GenericModal, GenericTag, GenericTagTypes,
  ModalSubHeaderComponent,
} from "@eazy2biz-ui/common-components";
import React, { useState, useEffect, ReactElement } from "react";
import { SchedulableTriggerCategory, SchedulableTriggerResponse } from "@eazy2biz/common-util";
import styles from "../../GenericCardForm/GenericCardForm.module.css";
import { SCHEDULER_STRINGS } from "../../../../contents/DisplayContent";
import { createScheduledTrigger } from "../../../services/SceduledTriggerService";
import Toast from "../../../genericComponents/Toast/Toast";
import { getScheduledTriggerPayload } from "../../../services/helpers/ScheduledTriggerServiceHelper";
import InputField from "../../../genericComponents/InputField/InputField";
import { Divider } from "antd";
import { GenericException } from "@eazy2biz/common-package-ui";

/**
 * Create or Update or View Schedule Trigger Modal
 * @param props
 * @constructor
 */
export const GenericScheduledTriggerModal = (props: PropTypes) => {
    const { openModal, onClose, scheduledTrigger, triggerCategory } = props;

    if (!openModal) {
        return null;
    }

    const [name, setName] = useState(scheduledTrigger?.details.name || 'Untitled Trigger');
    const [description, setDescription] = useState(scheduledTrigger?.details.description || '');
    const [cronValue, setCronValue] = useState(scheduledTrigger?.cronSchedule || '');
    const [createMode, setCreateMode] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [createUpdateScheduledTriggerProgress, setCreateUpdateScheduledTriggerProgress] = useState<boolean>(false);
    const [taskDetails, setTaskDetails] = useState(scheduledTrigger?.scheduledTaskDetails || {});

    useEffect(() => {
      setCreateMode(!scheduledTrigger);
    }, [scheduledTrigger]);

    const isEditDisabled = () => {
      return !(createMode || editMode);
    };

    const handleCreateTrigger = () => {
      try {
        setCreateUpdateScheduledTriggerProgress(true);
        Toast.load(SCHEDULER_STRINGS.CREATE_TRIGGER_PROGRESS);
        createScheduledTrigger(getScheduledTriggerPayload(
          name,
          description,
          triggerCategory,
          cronValue,
          taskDetails,
          props.referenceId
          ))
          .then(() => {
            Toast.success(SCHEDULER_STRINGS.CREATE_TRIGGER_SUCCESS);
            props.onSubmit && props.onSubmit();
            onClose();
          })
          .catch((e) => Toast.error(e, SCHEDULER_STRINGS.CREATE_TRIGGER_ERROR))
          .finally(() => {
            setCreateUpdateScheduledTriggerProgress(false);
          });
      } catch (e: any) {
        Toast.error(e, SCHEDULER_STRINGS.CREATE_TRIGGER_ERROR);
        setCreateUpdateScheduledTriggerProgress(false);
      }
    };

    // TODO: handle edit of trigger.
    const handleEditTrigger = () => {};

    const handleSubmit = () => {
      createMode && handleCreateTrigger();
      editMode && handleEditTrigger();
    };

    const renderModalTitle = () => {
      return createMode ? SCHEDULER_STRINGS.CREATE_TRIGGER : (editMode) ? SCHEDULER_STRINGS.UPDATE_TRIGGER : '';
    };

    const renderModalSubTitle = () => {
        return (
            <ModalSubHeaderComponent
                title={SCHEDULER_STRINGS.MY_TRIGGERS}
                name={props.name}
            />
        );
    };

  const renderLeftHeader = () => {
    if (!createMode) {
      return <GenericTag title={SCHEDULER_STRINGS.SCHEDULED} type={GenericTagTypes.ACTIVE} />;
    }

    return null;
  };

    const renderModalContent = () => {
      return (
        <div>
          <InputField
            id={'scheduleName'}
            type='text'
            label={SCHEDULER_STRINGS.SCHEDULE_NAME}
            initialValue={name}
            disabled={isEditDisabled()}
            onChange={(e) => setName(e.target.value)} />
          <InputField
            id={'scheduleDescription'}
            type='text'
            label={SCHEDULER_STRINGS.SCHEDULE_DESCRIPTION}
            required={false}
            disabled={isEditDisabled()}
            initialValue={description}
            onChange={(e) => setDescription(e.target.value)} />
          <Divider />
          <GenericCronField
            label={SCHEDULER_STRINGS.SELECT_PERIOD}
            value={cronValue}
            disabled={isEditDisabled()}
            onChange={setCronValue}
          />
          <Divider />
          {props.renderScheduleTaskDetails(taskDetails, setTaskDetails, isEditDisabled())}
        </div>
      );
    };

  const renderFooter = () => {
    if (!createMode && !editMode) {
      return null;
    }

    return (
      <div className={styles.footerCtn}>
        <GenericButton
          isLoading={createUpdateScheduledTriggerProgress}
          onClick={handleSubmit}
          buttonText={createMode ? SCHEDULER_STRINGS.CREATE_TRIGGER : SCHEDULER_STRINGS.UPDATE_TRIGGER}
        />
      </div>
    );
  };

    return (
      <GenericModal
        show={openModal}
        title={renderModalTitle()}
        subHeader={renderModalSubTitle}
        leftHeader={renderLeftHeader}
        bodyContent={renderModalContent}
        footerContent={renderFooter}
        onClose={onClose}
        dialogClassName={styles.modal}
      />
    );
};

type PropTypes = {
  name: string;
  openModal: boolean;
  onClose: () => void;
  scheduledTrigger?: SchedulableTriggerResponse;
  onSubmit?: () => void;
  triggerCategory: SchedulableTriggerCategory;
  referenceId: string;
  renderScheduleTaskDetails: (taskDetails: any, setTaskDetails: (taskDetails: any) => void, readOnly: boolean) => ReactElement | null;
};
