import {FormElementConfig} from "../../../entity/formBuilder/FormElementConfig";
import {getField, getIsRequiredValidation} from "../FormFieldHelper";
import {cloneDeep} from "lodash";
import {AccessTypes, Field, FieldAccessMap, FieldValidationType} from "@eazy2biz/common-util";
import {FormEntity, PaletteItemConfig} from "@eazy2biz-ui/common-package";
import {PaletteConfig} from "@eazy2biz-ui/common-components";

const getIsFieldRequiredFromField = (field: Field): boolean => {
    const validation = field.validations.find((validation) => validation.fieldValidationType === FieldValidationType.REQUIRED);
    return validation !== undefined;
};

/**
 * Transform to UI form items
 * @param formElements
 * @param fieldAccessMap
 * @return FormElementConfig[]
 */
export const getItemsFromFields = (formElements: Field[], fieldAccessMap: FieldAccessMap): FormElementConfig[] => {
    const items: FormElementConfig[] = formElements.map((element) => {
        return {
           element,
           visibility: fieldAccessMap[element._id] || AccessTypes.RW,
           isRequired: getIsFieldRequiredFromField(element)
       };
    });
    return items;
};

export const toggleFieldRequired = (formElement: FormElementConfig): FormElementConfig => {
    formElement.isRequired = !formElement.isRequired;
    if (formElement.isRequired) {
        formElement.element.validations.push(getIsRequiredValidation())
    } else {
        formElement.element.validations =
            formElement.element.validations.filter(
                (validation) => validation.fieldValidationType !== FieldValidationType.REQUIRED);
    }

    return formElement;
}

/**
 * Transforms field to the backend save field.
 * @param element
 */
const transformField = (element: FormElementConfig): Field => {
    return element.element;
};

/**
 * This function transforms the state of form builder for save.
 * @param formBuilderConfig
 * @return FORM_ENTITY_AND_FIELD_ACCESS_MAP
 */
export const transformBuilderForSave = (formBuilderConfig: FormElementConfig[]): FORM_ENTITY_AND_FIELD_ACCESS_MAP => {
    const formFields: Field[] = [];
    const fieldAccessMap: FieldAccessMap = {};

    formBuilderConfig.forEach((formElement) => {
        formFields.push(transformField(formElement));
        fieldAccessMap[formElement.element._id] = formElement.visibility;
    });

    return {
        form: {
            formFields
        },
        fieldAccessMap
    };
}

/**
 * Get Field with default values from palette config.
 * @param config
 * @param stageId
 * @return FormElementConfig
 */
export const getItemFromPaletteConfig = (configId: string, stageId: string): FormElementConfig => {
    const config: PaletteItemConfig<any> | undefined = PaletteConfig.find((config) => config.id === configId);
    const isRequired = true;
    if (!config) {
        throw new Error('Item config not found');
    }
    return {
        element: getField(config.type, stageId, 'Help text here',isRequired, config.label),
        visibility: AccessTypes.RW,
        isRequired,
    };
}

export const getFieldConfigById = (fields: FormElementConfig[], id: string): FormElementConfig => {
    const config: FormElementConfig | undefined = fields.find((field) => field.element._id === id);

    if (!config) {
        throw new Error('Item config not found');
    }

    return config;
};

/**
 * Updates Field in the list.
 * @param updatedField
 * @param fields
 */
export const updateField = (updatedField: FormElementConfig, fields: FormElementConfig[]): FormElementConfig[] => {
    return fields.map((field) => {
        return ((field.element._id === updatedField.element._id)? updatedField : cloneDeep(field));
    });
};

export const deleteField = (fields: FormElementConfig[],fieldId ?: string,): FormElementConfig[] => {
    let newfields = cloneDeep(fields);
    return newfields.filter((field) => field.element._id !== fieldId);
}

type FORM_ENTITY_AND_FIELD_ACCESS_MAP = {
    form: FormEntity,
    fieldAccessMap: FieldAccessMap
}
