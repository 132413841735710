import {
  enabledPath,
  EntityDefinitionTypes,
  EntitySearch,
  FieldSearch,
  SchedulableTriggerCategory,
  SchedulableTriggerCreateRequest,
  ScheduledTaskFrequency,
  ScheduledTaskType,
  SearchTypes
} from "@eazy2biz/common-util";
import { StrictBuilder } from "builder-pattern";
import { allowedCronMinuteValue, ErrorTypes, GenericException, validateCron } from "@eazy2biz/common-package-ui";
import { CRON_FIELD } from "../../../contents/DisplayContent";
import {
  externalReferenceIdPath,
  schedulableTriggerCategoryPath,
  schedulableTriggerTypePath, scheduledTaskTypePath
} from "../constants/EntityConstants";

export const getFilterPayloadForTrigger = (
  schedulableTriggerCategory?: SchedulableTriggerCategory,
  schedulableTriggerType?: EntityDefinitionTypes,
  scheduledTaskType?: ScheduledTaskType,
  referenceId?: string
): EntitySearch => {
  const searchFilters: FieldSearch[] = [];

  schedulableTriggerCategory && searchFilters.push({
    field: schedulableTriggerCategoryPath,
    type: SearchTypes.VALUE_EQUAL,
    value: schedulableTriggerCategory,
  });

  schedulableTriggerType && searchFilters.push({
    field: schedulableTriggerTypePath,
    type: SearchTypes.VALUE_EQUAL,
    value: schedulableTriggerType,
  });

  scheduledTaskType && searchFilters.push({
    field: scheduledTaskTypePath,
    type: SearchTypes.VALUE_EQUAL,
    value: scheduledTaskType,
  });

  referenceId && searchFilters.push({
    field: externalReferenceIdPath,
    type: SearchTypes.VALUE_EQUAL,
    value: referenceId,
  });

  referenceId && searchFilters.push({
    field: enabledPath,
    type: SearchTypes.VALUE_EQUAL,
    value: true,
  });

  return new EntitySearch(searchFilters);
};

export const getScheduledTriggerPayload = (
  name: string,
  description: string,
  triggerCategory: SchedulableTriggerCategory,
  cron: string,
  taskDetails: any,
  referenceId: string,
): SchedulableTriggerCreateRequest => {

  if (cron === '' || !validateCron(cron)) {
    const message = CRON_FIELD.INVALID_CRON + JSON.stringify(allowedCronMinuteValue);
    throw new GenericException(ErrorTypes.VALIDATION_ERROR, message, message);
  }

  return StrictBuilder<SchedulableTriggerCreateRequest>()
    .name(name)
    .description(description)
    .cronSchedule(cron)
    .schedulableTriggerType(EntityDefinitionTypes.USER_DEFINED)
    .schedulableTriggerCategory(triggerCategory)
    .scheduledTaskFrequency(ScheduledTaskFrequency.CRON_BASED)
    .scheduledTaskType(ScheduledTaskType.EXECUTE_ACTION)
    .scheduledTaskDetails(taskDetails)
    .externalReferenceId(referenceId)
    .build();
};
