import styles from "./TodoHomePage.module.css";
import TodoTable from "../common/TodoTable/TodoTable";
import { GenericButton, InfoWrapper, TourType, useFeatureTour } from "@eazy2biz-ui/common-components";
import { PlusIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import { CreateTodoModal } from "../common/CreateTodoModal/CreateTodoModal";
import { CREATE_TODO } from "../../../contents/DisplayContent";
import { TODO_INFO } from "@eazy2biz-ui/common-package";

/**
 * This Component is used for displaying my todos on the homepage.
 * @constructor
 */
const TodoHomepageComponent  = (props: PropTypes) => {

    const [showCreateTodoModal, setShowCreateTodoModal] = useState(false);

  const {getRef} = useFeatureTour();

    return (
        <div className={styles.todoTable}>
            <div className={styles.todoTableHeader} ref={getRef(2, TourType.HOMEPAGE)}>
                <InfoWrapper infoContent={TODO_INFO}>
                  <span className={styles.todoTableTitle}>{"My To Do’s"}</span>
                </InfoWrapper>
                <GenericButton
                    buttonText={CREATE_TODO.NEW_TODO_BUTTON}
                    CustomIcon={PlusIcon}
                    onClick={() => setShowCreateTodoModal(true)}
                />
            </div>
            <TodoTable refreshTable={props.refreshData} onRefreshCallback={props.onRefreshCallback}/>
            {showCreateTodoModal &&
                <CreateTodoModal
                    show
                    onRefreshCallback={props.onRefreshCallback}
                    onClose={() => setShowCreateTodoModal(false)}
                />
            }
        </div>
    );
}

export default TodoHomepageComponent;

type PropTypes = {
    refreshData?: boolean;
    onRefreshCallback?: (value: boolean) => void;
}
