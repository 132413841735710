import { StageData } from "../../../entity/workflowBuilder/StageData";
import { ReactElement, useEffect, useState } from "react";
import { EditInput, GenericButton, NumberSelect, TourType, useFeatureTour } from "@eazy2biz-ui/common-components";
import { ActionConfigurationsComponent } from "./actionConfigurations/ActionConfigurationsComponent";
import { workflowContent } from "../../../../contents/DisplayContent";
import editProcessIcon from "../../../../assets/editProcess.svg";
import styles from "./StageConfigurations.module.css";
import { cloneDeep } from "lodash";
import { Field, Stage, StageAssignee, StageTypes } from "@eazy2biz/common-util";
import { StageAssigneeComponent } from "./stageAssigneeComponent/StageAssigneeComponent";
import { parse, pattern } from "iso8601-duration";
import moment from "moment";

/**
 * Stage Configurations Component
 * @param props
 * @constructor
 */
const StageConfiguration = (props: PropTypes): ReactElement | null => {
  const { stage, workflowEditMode } = props;
  if (stage === null) {
    return null;
  }

  const { onToggleFormBuilder, onStageUpdate, onStageDelete } = props;

  const { stageConfiguration } = stage;
  const { details, assignee } = stageConfiguration;
  const [stageName, setStageName] = useState(details.name);
  const [stageAssignee, setStageAssignee] = useState<StageAssignee>(assignee);
  const [dueInDays, setDueInDays] = useState(0);
  const [dueInHours, setDueInHours] = useState(0);
  const [stageActions, setStageActions] = useState(stageConfiguration.actions);

  const {getRef, triggerTour} = useFeatureTour();

  useEffect(() => {
    const { stageConfiguration } = props.stage;
    const { assignee } = stageConfiguration;
    setStageName(stageConfiguration.details.name);
    setStageAssignee(assignee);
    setStageActions(stageConfiguration.actions);
    setDuration(stageConfiguration.dueInDays);
  }, [props.stage]);

  const setDuration = (dueString: string) => {
    // For backward compatability
    if (!pattern.test(dueString.toString())) {
      dueString = "P" + dueString + "D";
    }
    const duration = parse(dueString);
    setDueInDays(duration.days || 0);
    setDueInHours(duration.hours || 0);
  };

  const handleDueHrsChange = (value: number) => {
    setDueInHours(value);
  };

  const handleDueDaysChange = (value: number) => {
    setDueInDays(value);
  };

  const handleAssigneeSelection = (updatedStageAssignee: StageAssignee) => {
    setStageAssignee(updatedStageAssignee);
  };

  const handleSaveStage = () => {
    let data = cloneDeep(stage);
    data.stageConfiguration.assignee = stageAssignee;
    data.stageConfiguration.details.name = stageName;
    data.stageConfiguration.dueInDays = moment.duration({days: dueInDays, hours: dueInHours}).toISOString();
    data.stageConfiguration.actions = stageActions;
    data.details.label = stageName;
    onStageUpdate(stage.id, data);
  };

  const handleDeleteStage = () => {
    onStageDelete(stage.id);
  };

  const handleStageNameChange = (value: string) => {
    setStageName(value);
  };

  const renderHeader = () => {
    return (
      <div className={styles.stageHeader} onMouseEnter={
        () => {![StageTypes.ENTRY_STAGE, StageTypes.CONDITIONAL_STAGE].includes(stage.stageConfiguration.type) && triggerTour(TourType.STAGE_CONFIGURATIONS)}
      }>
        <div className={styles.stageNameWrapper}>
          <EditInput value={stageName} size={'large'} onChange={handleStageNameChange} />
        </div>
        {[StageTypes.ENTRY_STAGE, StageTypes.FORM_STAGE, StageTypes.APPROVAL_STAGE].includes(
          stage.stageConfiguration.type
        ) && (
          <div className={styles.editForm} onClick={() => onToggleFormBuilder(stage.id)}>
            <img src={editProcessIcon} className={styles.editProcessIcon}></img>
            {'Edit Form'}
          </div>
        )}
      </div>
    );
  };

  const renderStageAssignment = () => {
    if (
      [StageTypes.ENTRY_STAGE, StageTypes.CONDITIONAL_STAGE].includes(stage.stageConfiguration.type)
    ) {
      return null;
    }

    return (
      <StageAssigneeComponent
        stageAssignee={stageAssignee}
        onAssigneesSelect={handleAssigneeSelection}
        fields={props.fields}
      />
    )
  };

  const renderStageDueComponent = () => {
    if (
      [StageTypes.ENTRY_STAGE, StageTypes.CONDITIONAL_STAGE].includes(stage.stageConfiguration.type)
    ) {
      return null;
    }
    return (
      <div className={styles.otherFieldsHeader} ref={getRef(1, TourType.STAGE_CONFIGURATIONS)}>
        <span className={styles.otherFieldsTitle}>{'Due in'}</span>
        <div className={styles.datePicker}>
          <NumberSelect
            id={'dueInDays'}
            onChange={handleDueDaysChange}
            min={0}
            max={30}
            defaultValue={1}
            value={dueInDays}
          />
          <span>days</span>
          <NumberSelect
            id={'dueInHrs'}
            onChange={handleDueHrsChange}
            min={0}
            max={24}
            defaultValue={0}
            value={dueInHours}
          />
          <span>hrs</span>
        </div>
      </div>
    );
  };

  const renderActionConfigurations = () => {
    if ([StageTypes.CONDITIONAL_STAGE].includes(stage.stageConfiguration.type)) {
      return null;
    }
    return (
      <ActionConfigurationsComponent
        currentStageId={stage.id}
        fields={props.fields}
        stageType={stage.stageConfiguration.type}
        stageActions={stageActions}
        stages={props.stages}
        updateStageActions={setStageActions}
      />
    );
  };

  return (
    <>
      {renderHeader()}
      {renderStageAssignment()}
      {renderStageDueComponent()}
      {renderActionConfigurations()}
      <div className={styles.stageButtons}>
        {stage.details.type !== StageTypes.ENTRY_STAGE && !workflowEditMode ? (
          <GenericButton
            className={styles.deleteStageButton}
            onClick={handleDeleteStage}
            buttonText={workflowContent.DELETE}
          />
        ) : null}
        <GenericButton
          className={styles.saveStageButton}
          onClick={handleSaveStage}
          buttonText={workflowContent.SAVE}
        />
      </div>
    </>
  );
};

type PropTypes = {
  fields: Field[];
  stages: Stage[];
  workflowEditMode: boolean;
  stage: StageData;
  onToggleFormBuilder: (stageId?: string) => void;
  onStageUpdate: (id: string, data: StageData) => void;
  onStageDelete: (stageId: string) => void;
};
export default StageConfiguration;
