import {PropTypes as DefaultInputPropTypes} from "../../InputFieldWithPlaceholder";
import {Mention, MentionsInput, SuggestionDataItem} from "react-mentions";
import {useEffect, useState} from "react";
import styles from "./InputFieldSinglePlaceholderComponent.module.css";
import './InputFieldSinglePlaceholderComponentMentionStyle.css'
import {MARK_UP} from "../../constants/Constants";
import {UserPlaceholderEntity} from "../../entity/UserPlaceholderEntity";
import {FieldPlaceholder} from "@eazy2biz/common-util";
import {SinglePlaceholderConfig} from "../../config/SinglePlaceholderConfig";
import {get} from "lodash";
import {
    getSinglePlaceholderSuggestionDisplayValuePathByType,
    getSinglePlaceholderTriggerByType
} from "../../constants/SinglePlaceholderConfig";

/**
 * Placeholder for only single type of insertions for a single resource.
 * Example: only userName, only userEmail, only FieldName.
 * @param props
 * @constructor
 */
export const InputFieldSinglePlaceholderComponent = (props: PropTypes) => {
    const {
        placeholder,
        type = 'textarea',
        valueOfPlaceholderInputField,
        onPlaceholderInputValueChange,
        singlePlaceholderConfig
    } = props;

    const [placeholderValues, setPlaceholderValues] = useState<SuggestionDataItem[]>(new Array());

    useEffect(() => {
        switch (singlePlaceholderConfig.basePlaceholder) {
            default:
                setUserPlaceholderValues();
        }
    }, [props.usersList]);

    const getUserPlaceholderValue = (user: any): SuggestionItem => {
        const finalPlaceholder: FieldPlaceholder = singlePlaceholderConfig.basePlaceholder;
        finalPlaceholder.entityId = user._id;
        return {
            id: finalPlaceholder.convertToString(),
            display: get(user, finalPlaceholder.key, ''),
            suggestionDisplayValue: get(
                user,
                singlePlaceholderConfig.suggestionValueKeyPath || getSinglePlaceholderSuggestionDisplayValuePathByType(finalPlaceholder.placeholderType),
                get(user, finalPlaceholder.key, ''))
        };
    };

    const setUserPlaceholderValues = () => {
        const userPlaceholderValues = props.usersList.map(getUserPlaceholderValue) || [];
        setPlaceholderValues(userPlaceholderValues);
    };

    const handleChange = (event: any, newValue: string) => {
        onPlaceholderInputValueChange({ target: { value: newValue}});
    };

    return(
        <MentionsInput
            singleLine={type !== 'textarea'}
            value={valueOfPlaceholderInputField}
            onChange={handleChange}
            placeholder={placeholder}
            className={'placeholderInput'}>
            <Mention
                markup={MARK_UP}
                trigger={getSinglePlaceholderTriggerByType(singlePlaceholderConfig.basePlaceholder.placeholderType)}
                data={placeholderValues}
                renderSuggestion={(suggestion: SuggestionDataItem) => (suggestion as SuggestionItem).suggestionDisplayValue}
                appendSpaceOnAdd
                className={styles.placeholderMention}/>
        </MentionsInput>
    );
};

interface PropTypes extends DefaultInputPropTypes {
    valueOfPlaceholderInputField: string;
    onPlaceholderInputValueChange: (event: any) => void;
    usersList: UserPlaceholderEntity[];
    singlePlaceholderConfig: SinglePlaceholderConfig;
};

interface SuggestionItem extends SuggestionDataItem {
    suggestionDisplayValue: string;
}

