import {
  AdvancedFilterConfig,
  AppliedFilterConfig,
  AppliedFilterItemConfig,
  RowData
} from "@eazy2biz-ui/common-components";
import { clone, get } from "lodash";
import {
  CustomerTableFilterConfig
} from "../components/tableLanding/recordsTable/CustomerTableFilterMenu/CustomerTableFilterMenu";
import { TABLE_FILTER_MENU } from "../../contents/DisplayContent";
import { Operators } from "@eazy2biz/common-util";

export const getAppliedFilterConfigFromRecordsFilters = (filterConfig: CustomerTableFilterConfig, setFilter: Function): AppliedFilterConfig => {
  const appliedFilters: AppliedFilterItemConfig[] = [];

  const filterConfigCopy = clone(filterConfig);

  filterConfig.archivedOnly && appliedFilters.push({
    title: TABLE_FILTER_MENU.ARCHIVED_ONLY,
    onRemove: () => {
      filterConfigCopy.archivedOnly = false;
      setFilter(filterConfigCopy);
    }
  });

  filterConfig.date && appliedFilters.push({
    title: 'Created before',
    onRemove: () => {
      filterConfigCopy.date = undefined;
      setFilter(filterConfigCopy);
    }
  });

  filterConfig.advancedFilters.length > 0 && appliedFilters.push({
    title: 'Advanced filters',
    onRemove: () => {
      filterConfigCopy.advancedFilters = [];
      setFilter(filterConfigCopy);
    }
  });

  return {
    filters: appliedFilters,
  };
};
