import {
    ActionResult,
    ActionResultTypes, AssigneeTypes, FieldPlaceholder, FieldPlaceholderType,
    Stage,
    StageAssignee,
    StageTypes,
    TodoCreateDetails,
    TodoTypes
} from "@eazy2biz/common-util";

const getTodoTypeForStage = (stageType: StageTypes = StageTypes.FORM_STAGE): TodoTypes => {
    return stageType === StageTypes.APPROVAL_STAGE ? TodoTypes.APPROVAL : TodoTypes.SUBMIT_FORM;
};

const getAssigneesForTodo = (stageAssignee: StageAssignee) => {
  switch (stageAssignee.type) {
      case AssigneeTypes.FORM_FIELD:
          const fieldPlaceholder = new FieldPlaceholder();
          fieldPlaceholder.placeholderType = FieldPlaceholderType.CARD_FORM;
          fieldPlaceholder.key = stageAssignee.formFieldId || '';
          fieldPlaceholder.entityId = '';
          fieldPlaceholder.entityClassId = '';
          return {
              assigneeUserId: fieldPlaceholder.convertToString(),
              assigneeUserGroupId: [],
          };
      default:
        return {
            assigneeUserId: stageAssignee.assignees,
            assigneeUserGroupId: stageAssignee.userGroupIds || [],
        };
  }
};

/**
 * Creates TODO_CREATE action.
 * @param stageEntity
 * @returns actionResult of TodoCreateDetail
 */
export const getTodoCreateAction = (stageEntity: Stage): ActionResult<TodoCreateDetails> => {
    return {
        type: ActionResultTypes.TODO_CREATE,
        // @ts-ignore
        resultDetails: {
            title: stageEntity.details.name,
            description: stageEntity.details.description || '',
            dueInDays: stageEntity.dueInDays,
            type: getTodoTypeForStage(stageEntity.type),
            ...getAssigneesForTodo(stageEntity.assignee)
        }
    };
};
