export const HELLO_WORLD = 'Hello World';

/*
 * TABLE Display Content
 * */
export const EMPTY_RESULTS_TEXT = 'No results';
export const FILTER_ACTION_TEXT = 'Filter';
export const NO_RESULTS_FOUND = 'No results found';

export const UNKNOWN_ERROR_RETRY = 'Unknown Error occurred. Please try again!';

/*
 * Dynamic Placeholder Display Content
 * */
export const SEARCH_PLACEHOLDER = 'Search ...';
export const BROWSE_BY_RESOURCE = 'Browse by resource';
export const PLACEHOLDER_OPTIONS = {
  USERS: 'Users',
  FORM: 'Form',
  CARD_SYSTEM: 'Item System',
  DATABASE: 'Customer Table',
  SYSTEM: 'System',
  CARD_STAGE_SYSTEM: 'Item Stage System',
};
export const PLACEHOLDER_SELECTION_ERROR = 'An error occurred while selecting';
/*
 * Confirmation Modal Content
 * */
export const TITLE = 'Are You Sure ?';
export const MESSAGE = 'Do you really want to delete this record? This process can not be undone';

export const MODAL_SUB_HEADER = {
  DUE_ON: ' Due on',
  ASSIGNED: 'Assigned To',
  CREATED: 'Created by',
  CREATED_ON: 'Created on'
};

export const CARD_STAGE_STRING = {
  ADDED_BY: ' Stage Completed by ',
  ADDED_TIME: ' Stage Completed On '
};
/**
 * Text Variable Input content
 */
export const TEXT_VARIABLE_INPUT = {
  VARIABLES: 'Variables'
};

/**
 * Step Form content
 */
export const GENERIC_STEP_FORM_BUTTONS = {
  CONTINUE: 'Continue',
  BACK: 'Back'
};
/**
 * Generic Message content
 */
export const GENERIC_MESSAGE = {
  FOOTER_TEXT: 'Sent via eazy2biz'
};

export const UPLOAD_FILE_STRINGS = {
  UPLOAD_FILE_TEXT: 'Click or drag file to this area to upload',
  MAX_UPLOAD_COUNT_ERROR: 'Cannot upload files more than {0}. Remove files and then try again.',
  ATTACHMENT_FETCH_ERROR: 'Error getting details.',
  DOCUMENT_PICK_ERROR: 'Error while selecting document.',
  DOCUMENT_UPLOAD_ERROR: 'Error uploading document',
  DOCUMENT_DOWNLOAD_ERROR: 'Error while downloading document',
  DOCUMENT_PREVIEW_ERROR: 'Error opening document',
  DOCUMENT_DELETE_ERROR: 'Error deleting document',
  DOWNLOADING_FILE: 'Downloading file in progress.',
  INVALID_FILE_TYPE: 'Invalid file type.',
};

export const COMMENTS_STRING = {
  ERROR_FETCH_COMMENTS: 'Error fetching comments.',
  ERROR_ADD_COMMENT: 'Error while adding the comment',
  ADD_COMMENT: 'Add Comment',
  COMMENT_ADDED_SUCCESS: 'Comment Added'
};

export const SCHEDULER_STRINGS = {
  CREATE: '+ Trigger',
  CREATE_TRIGGER: 'Create Trigger',
  UPDATE_TRIGGER: 'Update Trigger',
  SELECT_PERIOD: 'Select Schedule',
  SCHEDULED: 'Scheduled',
  TRIGGERED: 'Triggered',
  MY_TRIGGERS: 'My Triggers',
  CREATE_TRIGGER_PROGRESS: 'Creating scheduled trigger...',
  CREATE_TRIGGER_SUCCESS: 'Trigger is successfully scheduled.',
  CREATE_TRIGGER_ERROR: 'Error in scheduling trigger. Please try again.',
  DELETE_TRIGGER_PROGRESS: 'Deleting scheduled trigger...',
  DELETE_TRIGGER_SUCCESS: 'Trigger is successfully deleted.',
  DELETE_TRIGGER_ERROR: 'Error in deleting trigger. Please try again.',
  SCHEDULE_NAME: 'Schedule name',
  SCHEDULE_DESCRIPTION: 'Schedule description',
};

export const CRON_FIELD = {
  INVALID_CRON: 'Minutes can only have values within ',
};

export const ADVANCED_FILTER_STRINGS = {
  FIELD: 'Select Field:',
  OPERATOR: 'Operator:',
  OPERATOR_FIELD_PLACEHOLDER: 'Eg. Less Than',
  SECOND_FIELD: 'Value'
};

export const ALERT_MODAL = {
  CONFIRM: 'Confirm',
  CANCEL: 'Cancel',
  TITLE: 'Are you sure you want to continue ?',
};

export const TEMPLATES_MODAL = {
  FETCHING_TEMPLATES: 'Fetching templates...',
  FETCH_TEMPLATES_ERROR: 'Error fetching templates. Please try again.',
  CREATE_NEW: 'Create new',
};

export const TABLE_SETTINGS = {
  SETTINGS: 'Settings',
  CLEAR: 'Clear',
  APPLY: 'Apply',
};
