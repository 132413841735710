import {InputField, Toast} from "@eazy2biz-ui/common-components";
import {useState} from "react";
import {emailField, passwordField} from "../../constants/keys";
import {confirmSignUp} from "../../services/GlobalUserService";
import {useHistory, useParams} from "react-router-dom";
import SignUpPinInput from "./SignUpPinInput";
import {ConfirmSignUpTitle, ERROR_STRINGS, SUCCESS_STRINGS} from "../../../contents/DisplayContent";
import {RoutesEnum} from "@eazy2biz-ui/common-package";
import {IdentitiesWrapperModal} from "../common/identitiesWrapperModal/IdentitiesWrapperModal";
import {IdentityGenericButton} from "../common/identityGenericButton/IdentityGenericButton";
import { GenericException, ErrorTypes } from "@eazy2biz/common-package-ui";

/**
 * Confirm User Sign Up OTP Modal Component
 * @param props
 * @constructor
 */
const ConfirmUserSignUpForm = (props: PropTypes): JSX.Element => {
    const history = useHistory();
    // @ts-ignore
    const {userEmail} = useParams();
    const initialValue = props.email || userEmail;

    const [email, setEmail] = useState(initialValue || '');
    const [pwd, setPwd] = useState('');
    const [confirmingSignUp, setConfirmingSignUp] = useState<boolean>(false);

    const handleFieldsChange = (e: any) => {
        switch (e.target.id) {
            case emailField: setEmail(e.target.value); break;
            case passwordField: setPwd(e.target.value); break;
        }
    };

    /**
     * Handles successful user sign up.
     */
    const handleSubmit = () => {
        setConfirmingSignUp(true);
        confirmSignUp(email,pwd)
            .then(() => {
                Toast.success(SUCCESS_STRINGS.CONFIRM_SIGN_UP);
                setConfirmingSignUp(false);

                props.onUserSignUpConfirmed && props.onUserSignUpConfirmed();

                !props.onUserSignUpConfirmed && history.push(RoutesEnum.USER_SIGN_IN);
            }).catch(() => {
                setConfirmingSignUp(false);
                Toast.error(new GenericException(ErrorTypes.UNKNOWN_ERROR, '', ERROR_STRINGS.CONFIRM_SIGN_UP), ERROR_STRINGS.CONFIRM_SIGN_UP);
            });
    };

    const onClose = () => {
        props.onClose && props.onClose();
        !props.onClose && history.replace(RoutesEnum.USER_SIGN_IN);
    }


    const renderSuccessMessage = () => {
        return (
            <div>
                Please enter the OTP sent on your mail.
            </div>
        );
    };

    const renderSignUpForm = () => {
        return (
            <form>
                {renderSuccessMessage()}
                <InputField
                    id={emailField}
                    label={'Email'}
                    type={"email"}
                    initialValue={initialValue}
                    disabled={!!initialValue}
                    onChange={handleFieldsChange}
                    required
                    isInValid={email === '' && !!initialValue}
                />

                <SignUpPinInput length={6} setPasscode={setPwd} />
                <IdentityGenericButton isLoading={confirmingSignUp} onClick={handleSubmit} buttonText="Confirm Sign Up"/>
            </form>
        );
    };

    return (
        <IdentitiesWrapperModal
            title={ConfirmSignUpTitle}
            onClose={onClose}
            getBody={renderSignUpForm}
        />
    );
}

export default ConfirmUserSignUpForm;

type PropTypes = {
    email: string;
    onUserSignUpConfirmed: () => void;
    onClose: () => void;
};
