import { generateTableDataFromItems, RowMetaData } from "@eazy2biz-ui/common-components";
import { getFormattedDate, getUserNameById } from "@eazy2biz/common-package-ui";
import { SchedulableTriggerResponse, ScheduledTaskFrequency } from "@eazy2biz/common-util";
import { getColumnsForScheduler, SchedulerHeaders } from "../configs/SchedulerTableConfig";
import { getFormattedTime } from "../../../helpers/Previews/GenericWhatsappMessagePreview";
import cronstrue from 'cronstrue';

const getRowMetaDataForScheduledTrigger = (scheduledTrigger: SchedulableTriggerResponse): RowMetaData => {
  return {
      id: scheduledTrigger._id.toString()
  };
};

const getValueForScheduledTrigger = (scheduledTrigger: SchedulableTriggerResponse, key: string) => {
  switch (key) {
    case SchedulerHeaders.NAME:
      return scheduledTrigger.details.name;
    case SchedulerHeaders.SCHEDULE_FREQUENCY:
      return scheduledTrigger.scheduledTaskFrequency;
    case SchedulerHeaders.SCHEDULE_TIME_CRON:
      return (scheduledTrigger.scheduledTaskFrequency === ScheduledTaskFrequency.CRON_BASED) ? cronstrue.toString(scheduledTrigger.cronSchedule || '') : getFormattedTime(scheduledTrigger.scheduleTime || new Date());
    case SchedulerHeaders.UPDATED_BY:
      return getUserNameById(scheduledTrigger.metaData.lastUpdatedBy, '');
    case SchedulerHeaders.UPDATED_ON:
      return getFormattedDate(scheduledTrigger.metaData.lastUpdatedDate);
    default:
      return "";
  }
};

export const getTableDataForScheduledTriggersList = (ScheduledTriggers: SchedulableTriggerResponse[]) => {
  return generateTableDataFromItems(
    ScheduledTriggers,
    getColumnsForScheduler(),
    getValueForScheduledTrigger,
    getRowMetaDataForScheduledTrigger
  );
};
