import {ReportEntityType} from "@eazy2biz/common-util";
import React, {useState} from "react";
import {ConfigureChartWorkflowDurationField} from "./workflow/ConfigureChartWorkflowDurationField";

import styles from './ConfigureChartDurationField.module.css'

export const ConfigureChartDurationField = (
    props : {
        entityType: ReportEntityType;
        entityId: string;
        entity: any;
        showChartConfigureFormFieldErrors: boolean;
        handleChartDurationFieldChange: (newDurationField : string) => void;
    }
) : JSX.Element => {

    const {
        entityType,
        entityId,
        entity,
        showChartConfigureFormFieldErrors,
        handleChartDurationFieldChange
    } = props;

    const [selectedDurationField, setSelectedDurationField] = useState<any>();

    const getChartDurationFieldComponent = () : React.ReactNode => {
        switch (entityType) {
            case ReportEntityType.WORKFLOW:
                return (
                    <ConfigureChartWorkflowDurationField
                        entityId={entityId}
                        workflowResponse={entity}
                        showChartConfigureFormFieldErrors={showChartConfigureFormFieldErrors}
                        selectedDurationField={selectedDurationField}
                        setSelectedDurationField={
                            (fieldId) => {
                                setSelectedDurationField(fieldId);
                                handleChartDurationFieldChange(fieldId)
                            }
                        }
                    />
                );
        }
    }

    return (
        <>
            {
                getChartDurationFieldComponent()
            }
        </>
    )
}
