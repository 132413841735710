import styles from './GenericHeader.module.css';

export const GenericHeader = (props: PropTypes): JSX.Element => {
  return (
    <div className={styles.sectionHeaderZone}>
      <div className={styles.sectionHeader}>
          <div>
          <div className={styles.sectionHeaderText}>{props.header}</div>
          <div className={styles.sectionSubHeader}>{props.subHeader && props.subHeader()}</div>
          </div>
      </div>
      <div className={styles.sectionButtons}>
        {props.sectionButtons && props.sectionButtons()}
      </div>
    </div>
  );
};

type PropTypes = {
  header: string | JSX.Element;
  subHeader?: () => JSX.Element | null;
  sectionButtons?: () => JSX.Element | null;
}
