import { message } from 'antd';
import {UNKNOWN_ERROR_RETRY} from "../../../contents/DisplayContent";
import styles from "./Toast.module.css";
import { GenericException, Logger } from '@eazy2biz/common-package-ui';

const MESSAGE_KEY = 'toastMessageKey';

const destroyMessage = (e?: MouseEvent, key: string = MESSAGE_KEY) => {
    message.destroy(key);
};

const getMessageConfig = (content: string, duration: ToastLength, key: string = MESSAGE_KEY) => {
    return {
        content,
        className: styles.toast,
        key,
        onClick: destroyMessage,
        duration
    }
};

const info = (messageText: string, duration: ToastLength = ToastLength.SHORT) => {
    message.info(getMessageConfig(messageText, duration));
};

const success = (messageText: string, duration: ToastLength = ToastLength.SHORT) => {
    message.success(getMessageConfig(messageText, duration));
};

const warn = (messageText: string, duration: ToastLength = ToastLength.SHORT) => {
    message.warning(getMessageConfig(messageText, duration));
};

const error = (exception: GenericException, messageText: string = UNKNOWN_ERROR_RETRY, duration: ToastLength = ToastLength.NEVER) => {
    new Logger('[ERROR]').logError('', messageText, 'UI-ERROR', exception);
    message.error(getMessageConfig(exception.displayMessage || messageText, duration));
};

const errorString = (messageText: string = UNKNOWN_ERROR_RETRY, duration: ToastLength = ToastLength.NEVER) => {
    message.error(getMessageConfig(messageText, duration));
};

const load = (messageText: string, duration: ToastLength = ToastLength.NEVER) => {
    message.loading(getMessageConfig(messageText, duration));
};

export default {
    info,
    success,
    warn,
    error,
    load,
    destroyMessage,
    errorString
};

export enum ToastLength {
    LONG = 10,
    SHORT = 3,
    NEVER = 0
}
