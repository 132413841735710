import ReactFlow, {Background, ConnectionLineType, Controls, FlowElement, MiniMap} from 'react-flow-renderer';
import NodeTypes from "./types/NodeTypes";
import EdgeTypes from "./types/EdgeTypes";
import React from "react";
import {Elements} from "react-flow-renderer/dist/types";
import {cloneDeep, isEqual, set} from "lodash";
import Dagre, {Edge} from 'dagre';
import {StageTypes} from "@eazy2biz/common-util";

class WorkflowCanvas extends React.Component<PropTypes, StateType> {
    private readonly NODE_WIDTH = 300;
    private readonly NODE_HEIGHT = 50;
    private readonly GRAPH_OFFSET_X = 250;
    private readonly GRAPH_OFFSET_Y = 100;

    constructor(props: PropTypes) {
        super(props);
        this.state = {
            elements: this.getLayoutedElements(props.stages || [], props.edges || [])
        };
    }

    componentDidUpdate(prevProps: Readonly<PropTypes>) {
        if (!isEqual(prevProps.stages, this.props.stages) || prevProps.selectedNodeId !== this.props.selectedNodeId) {
            this.setState({
                elements: this.getLayoutedElements(this.props.stages, this.props.edges)
            });
        }
    }

    enrichStageElement = (stage: FlowElement) => {
        const {onElementClick, selectedNodeId, readOnly} = this.props;
        stage.data.props.onClick = onElementClick;
        stage.data.props.isReadOnly = readOnly;
        stage.data.props.onSecondaryClick = this.props.onSecondaryNodeClick;
        if (selectedNodeId === stage.id) {
            stage.data.props.isSelected = true;
            stage.data.props.isHighlighted = true;
        }

        return stage;
    };

    enrichEdge = (edge: FlowElement) => {
        const {onAddElementClick, readOnly, onEdgeConnectionUpdate} = this.props;
        set(edge, 'data.onClick', onAddElementClick);
        set(edge, 'data.onEdgeConnectionUpdate', onEdgeConnectionUpdate);
        set(edge, 'data.isReadOnly', readOnly);
        return edge;
    };

    getLayoutedElements = (stages: Elements, edges: Elements ): Elements => {
        const nodeWidth = this.NODE_WIDTH;
        const nodeHeight = this.NODE_HEIGHT;
        let stagesCopy: Elements = cloneDeep(stages);
        let edgesCopy: Elements = cloneDeep(edges);

        const dagreGraph = new Dagre.graphlib.Graph();
        dagreGraph.setDefaultEdgeLabel(() => ({}));

        dagreGraph.setGraph({
            width: 210,
            height: 75,
            ranker: 'tight-tree'
        });

        stagesCopy.forEach((el) => {
            dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
        });

        edgesCopy = edgesCopy.map((el: FlowElement<Edge>) => {
            // @ts-ignore
            dagreGraph.setEdge(el.source, el.target);
            return this.enrichEdge(el);
        });

        Dagre.layout(dagreGraph);

        stagesCopy = stagesCopy.map((el: FlowElement<Node>) => {
            const nodeWithPosition = dagreGraph.node(el.id);
            // @ts-ignore
            el.position = {
                x: nodeWithPosition.x + this.GRAPH_OFFSET_X,
                y: nodeWithPosition.y + this.GRAPH_OFFSET_Y,
            };

            return this.enrichStageElement(el);
        });

        return([...stagesCopy, ...edgesCopy]);
    };

    handleOnPaneClick = (event: any) => {
        event.stopPropagation();
        if (this.props.selectedNodeId !== undefined) {
            this.props.onElementClick();
        }
    }

    reactFlowProps = {
        nodesDraggable: true,
        nodesConnectable: false,
        connectionLineType: ConnectionLineType.Straight,
        onPaneClick: this.handleOnPaneClick,
        nodeTypes: NodeTypes,
        edgeTypes: EdgeTypes,
    };

    render() {

        return (
            <ReactFlow
                elements={this.state.elements}
                {...this.reactFlowProps}
            >
                {!this.props.minimapDisable && <MiniMap/>}
                <Controls />
                <Background />
            </ReactFlow>
        );
    };
}

type PropTypes = {
    readOnly: boolean;
    stages: Elements;
    edges: Elements;
    onElementClick: (elementId?: string) => void;
    onEdgeConnectionUpdate?: (event: any, edgeId: string, stageType: StageTypes) => void;
    onAddElementClick: (event: any, edgeId: string, stageType: StageTypes) => void;
    selectedNodeId?: string;
    onSecondaryNodeClick?: (elementId?: string) => void;
    minimapDisable?: boolean;
};

type StateType = {
    elements: Elements;
};

export default WorkflowCanvas;
