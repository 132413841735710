import {getCurrentUserId} from "@eazy2biz/common-package-ui";
import {Elements} from "react-flow-renderer/dist/types";
import {findElementById} from "../../../helpers/workflowBuilderHelpers/runtimeHelpers/WorkflowBuilderFlowHelper";
import { ActionTypes, AssigneeTypes, Stage, StageTypes } from "@eazy2biz/common-util";
import {StageOtherConfigurations, StageProps} from "../../../entity/workflowBuilder/StageData";

/**
 * Returns New stage Configurations with default values.
 * @param id
 * @param name
 * @param stageTypes
 * @param nextStageId
 */
export const getNewStageConfiguration = (id: string, name: string, stageType?: StageTypes, nextStageId?: string): Stage => {
    return {
        _id: id,
        assignee: {
            type: AssigneeTypes.INTERNAL,
            assignees: [getCurrentUserId()],
            userGroupIds: [],
        },
        actions: {
            [ActionTypes.STAGE_ENTRY]: [],
            [ActionTypes.STAGE_EXIT]: [],
            [ActionTypes.SLA_BREACH]: []
        },
        details: {
            name,
            description: "",
            companyId: 0
        },
        fieldAccessMap: {},
        type: stageType || StageTypes.FORM_STAGE,
        workflowId: "",
        nextStage: nextStageId || '',
        dueInDays: "P1D"
    };
};

export const getNewStageOtherConfiguration = (): StageOtherConfigurations => {
    return {
        condition: undefined
    };
};

export const getDefaultStageProps = (): StageProps => {
    return {
        isHighlighted: false,
        isReadOnly: false,
        isSelected: false
    };
};

export const updateTargetStageId = (stageConfiguration: Stage, targetStageId?: string): Stage => {
    stageConfiguration.nextStage = targetStageId || '';
    return stageConfiguration;
}

export const getStageLabelById = (stages:Elements,stageId:string) => {
    let stageData = findElementById(stages,stageId);
    return stageData.data.details.label;
}
