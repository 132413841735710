import React, {UIEvent, useEffect, useRef, useState} from "react";
import styles from "./CardList.module.css";
import {ArrowRightIcon, ArrowLeftIcon} from "@radix-ui/react-icons";
import classNames from "classnames";
import {CustomCreateCard} from "./CustomCreateCard/CustomCreateCard";
import {Card, CardPropTypes} from "../card/card";

/**
 * Generic List of cards.
 * @param props
 * @constructor
 */
export const CardList = (props: PropTypes) => {
    const [showGradient, toggleGradient] = useState<boolean>(false);
    const [showGradientLeft, toggleGradientLeft] = useState<boolean>(false);

    const sideScroll = (
        speed: number,
        distance: number,
        step: number
    ) => {
        let scrollAmount = 0;
        const slideTimer = setInterval(() => {
            if (cardListContainer.current) {
                cardListContainer.current.scrollLeft += step;
                scrollAmount += Math.abs(step);
                if (scrollAmount >= distance) {
                    clearInterval(slideTimer);
                }
            }
        }, speed);
    };


    const manageScroll = (event: UIEvent<HTMLElement>) => {
        const percentage = 100 * event.currentTarget.scrollLeft / (event.currentTarget.scrollWidth - event.currentTarget.clientWidth);
        if (percentage >= 90) {
            toggleGradient(false);
        } else {
            toggleGradient(true);
        }

        if (percentage < 5) {
            toggleGradientLeft(false);
        } else {
            toggleGradientLeft(true);
        }
    };

    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const cardListContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        toggleGradient(cardListContainer.current?.clientWidth! < cardListContainer.current?.scrollWidth!);
    }, [cardListContainer.current]);

    const renderAllCards = () => {
        return (
            <div
                ref={cardListContainer}
                className={styles.listSection}
                onScroll={manageScroll}
            >
                {props.cardProps.map((props) => <Card {...props}/>)}
                {
                    props.onCreateCustom &&
                    <CustomCreateCard
                        title={props.customCreateTitle}
                        onClick={props.onCreateCustom}
                        disabled={props.createCustomDisabled}
                    />
                }
            </div>
        );
    }

    return (
        <div

            ref={scrollContainerRef}
            className={classNames(styles.section, {[styles.sectionGradient]: showGradient, [styles.sectionGradientLeft]: showGradientLeft})}>
            {
                showGradientLeft && <ArrowLeftIcon
                    color={'#757575'}
                    className={classNames(styles.scrollButtons, styles.listScrollLeftButton)}
                    onClick={() => sideScroll(10, 300, -25)}
                />

            }
            { renderAllCards() }
            {
                props.cardProps.length > 0 &&
                <ArrowRightIcon
                    color={'#757575'}
                    className={classNames(styles.scrollButtons, styles.listScrollRightButton)}
                    onClick={() => sideScroll(10, 300, 25)}
                />
            }
        </div>
    );
}


type PropTypes ={
    cardProps: CardPropTypes[];
    onCreateCustom?: () => void;
    createCustomDisabled?: boolean;
    customCreateTitle?: string;
};
