import {
    AggregatorType,
    BarChartDetails,
    BarChartType,
    ChartGrouping,
    createdDatePath,
    DateFieldAggregationType,
    FieldTypes, getWorkflowFieldIdFromFieldPath,
    lastUpdatedDatePath,
    ReportEntityType,
    WorkflowResponse
} from "@eazy2biz/common-util";

import {DropdownSelect, GenericRadioSelect, InputField, ToggleButton} from "@eazy2biz-ui/common-components";

import styles from './ConfigureWorkflowBarChartDetails.module.css'
import React from "react";
import {
    displayNameForAggregatorType,
    displayNameForDateFieldAggregationType
} from "../../../../../../../../contents/DisplayContent";
import {Builder} from "builder-pattern";
import {cloneDeep} from "lodash";

export const ConfigureWorkflowBarChartDetails = (
    props : {
        entityType: ReportEntityType;
        entityId: string;
        workflowResponse: WorkflowResponse;
        showChartConfigureFormFieldErrors: boolean;

        barChartDetails : BarChartDetails,
        setBarChartDetails : (barChartDetails : BarChartDetails) => void;

        subGroupingEnabled : boolean
        setSubGroupingEnabled : (subGroupingEnabled: boolean) => void
    }
) : JSX.Element => {

    const {
        entityType,
        entityId,
        workflowResponse,
        showChartConfigureFormFieldErrors,
        barChartDetails,
        setBarChartDetails,
        subGroupingEnabled,
        setSubGroupingEnabled
    } = props;

    const checkIfDateAggregationRequired = (fieldId : string | undefined) => {
        return fieldId
            && (
                fieldId === createdDatePath
                || fieldId === lastUpdatedDatePath
                || workflowResponse.fields.find(
                    workflowField =>
                        workflowField._id === getWorkflowFieldIdFromFieldPath(fieldId)
                        && workflowField.type === FieldTypes.DATE
                )
            );
    }

    return (
        <>
            <div className={styles.barChartTypeConfiguration}>
                <div className={styles.configureBarTypeText}> Select Bar Type </div>

                <GenericRadioSelect
                    defaultOption={barChartDetails.barChartType}
                    options={[
                        {
                            header: 'Vertical',
                            value: BarChartType.VERTICAL,
                        },
                        {
                            header: 'Horizontal',
                            value: BarChartType.HORIZONTAL,
                        }
                    ]}
                    onChange={(selectedBarChartType : BarChartType) => {
                        barChartDetails.barChartType = selectedBarChartType;
                        setBarChartDetails(cloneDeep(barChartDetails));
                    }}
                    className={styles.barTypeRadioButton}
                />
            </div>

            <div style={{display: "flex", flexDirection: barChartDetails.barChartType === BarChartType.VERTICAL ? "column" : "column-reverse"}}>
                <div className={styles.xAxisConfigurationBox}>
                    <div className={styles.configureXAxisText}> Configure {barChartDetails.barChartType === BarChartType.VERTICAL ? 'X' : 'Y'} Axis </div>

                    <div className={styles.xAxisConfiguration}>
                        <div className={styles.configureXAxisLabel}>
                            <InputField
                                id="xAxisFieldLabel"
                                label="Label"
                                highlightFieldError={showChartConfigureFormFieldErrors}
                                isInValid={!barChartDetails.groupingLabel}
                                initialValue={barChartDetails.groupingLabel || ''}
                                type="text"
                                onChange={(e) => {
                                    barChartDetails.groupingLabel = e.target.value;
                                    setBarChartDetails(cloneDeep(barChartDetails));
                                }}
                                required={true}
                            />
                        </div>

                        <div className={styles.configureXAxisFieldAndAggregator}>
                            <div
                                className={styles.configureXAxisField}
                                style={{width: checkIfDateAggregationRequired(barChartDetails.groupingField) ? '50%' : '100%'}}
                            >
                                <DropdownSelect
                                    placeholder={'Workflow Field'}
                                    label={'Workflow Field'}
                                    selectedItem={barChartDetails.groupingField}
                                    onItemSelect={
                                        (fieldId) => {
                                            barChartDetails.groupingField = fieldId;
                                            setBarChartDetails(cloneDeep(barChartDetails));
                                        }
                                    }
                                    loading={workflowResponse?.fields === null}
                                    isErrored={
                                        showChartConfigureFormFieldErrors &&
                                        (
                                            barChartDetails.groupingField === null
                                            || barChartDetails.groupingField === undefined
                                        )
                                    }
                                    menuData={
                                        [
                                            {
                                                _id: createdDatePath,
                                                name: 'Created Date'
                                            },
                                            {
                                                _id: lastUpdatedDatePath,
                                                name: 'Last Modified Date'
                                            },
                                            ...workflowResponse.fields?.map(
                                                (field) => ({
                                                    _id: `fieldsMap.${field._id}`,
                                                    name: field.name
                                                })
                                            )
                                        ]
                                    }
                                />
                            </div>
                            {
                                checkIfDateAggregationRequired(barChartDetails.groupingField) && (
                                    <div className={styles.configureXAxisAggregator}>
                                        <DropdownSelect
                                            placeholder={'Combine data for every'}
                                            label={'Combine data for every'}
                                            selectedItem={barChartDetails.dateGroupingFieldAggregationType}
                                            onItemSelect={
                                                (dateGroupingFieldAggregationType) => {
                                                    barChartDetails.dateGroupingFieldAggregationType =
                                                        dateGroupingFieldAggregationType as DateFieldAggregationType;
                                                    setBarChartDetails(cloneDeep(barChartDetails));
                                                }
                                            }
                                            isErrored={
                                                showChartConfigureFormFieldErrors &&
                                                (
                                                    barChartDetails.dateGroupingFieldAggregationType === null
                                                    || barChartDetails.dateGroupingFieldAggregationType === undefined
                                                )
                                            }
                                            menuData={
                                                Object.keys(DateFieldAggregationType)
                                                    .map(
                                                        (dateAggregationTypeString : string) => DateFieldAggregationType[dateAggregationTypeString as keyof typeof DateFieldAggregationType]
                                                    )
                                                    .map(
                                                        (dateFieldAggregationType : DateFieldAggregationType) => ({
                                                            _id: dateFieldAggregationType,
                                                            name: displayNameForDateFieldAggregationType[dateFieldAggregationType]
                                                        })
                                                    )
                                            }
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className={styles.yAxisConfigurationBox}>
                    <div className={styles.configureYAxisText}> Configure {barChartDetails.barChartType === BarChartType.VERTICAL ? 'Y' : 'X'} Axis </div>

                    <div className={styles.yAxisConfiguration}>
                        <div className={styles.configureYAxisLabel}>
                            <InputField
                                id="yAxisFieldLabel"
                                label="Label"
                                highlightFieldError={showChartConfigureFormFieldErrors}
                                isInValid={!barChartDetails.aggregationLabel}
                                initialValue={barChartDetails.aggregationLabel || ''}
                                type="text"
                                onChange={(e) => {
                                    barChartDetails.aggregationLabel = e.target.value;
                                    setBarChartDetails(cloneDeep(barChartDetails));
                                }}
                                required={true}
                            />
                        </div>

                        <div className={styles.configureYAxisFieldAndAggregator}>
                            <div className={styles.configureYAxisField}>
                                <DropdownSelect
                                    placeholder={'Workflow Field'}
                                    label={'Workflow Field'}
                                    selectedItem={barChartDetails.aggregationField}
                                    onItemSelect={
                                        (fieldId) => {
                                            barChartDetails.aggregationField = fieldId;
                                            setBarChartDetails(cloneDeep(barChartDetails));
                                        }
                                    }
                                    loading={workflowResponse?.fields === null}
                                    isErrored={
                                        showChartConfigureFormFieldErrors &&
                                        (
                                            barChartDetails.aggregationField === null
                                            || barChartDetails.aggregationField === undefined
                                        )
                                    }
                                    menuData={
                                        workflowResponse.fields?.filter(
                                            field => field.type === FieldTypes.NUMBER
                                        ).map(
                                            (field) => ({
                                                _id: `fieldsMap.${field._id}`,
                                                name: field.name
                                            })
                                        )
                                    }
                                />
                            </div>
                            <div className={styles.configureYAxisAggregator}>
                                <DropdownSelect
                                    placeholder={'Aggregator'}
                                    label={'Aggregator'}
                                    selectedItem={barChartDetails.aggregator}
                                    onItemSelect={
                                        (aggregator) => {
                                            barChartDetails.aggregator = aggregator as AggregatorType;
                                            setBarChartDetails(cloneDeep(barChartDetails));
                                        }
                                    }
                                    isErrored={
                                        showChartConfigureFormFieldErrors &&
                                        (
                                            barChartDetails.aggregator === null
                                            || barChartDetails.aggregator === undefined
                                        )
                                    }
                                    menuData={
                                        Object.keys(AggregatorType)
                                            .map(
                                                (aggregatorTypeString : string) => AggregatorType[aggregatorTypeString as keyof typeof AggregatorType]
                                            )
                                            .map(
                                                (aggregatorType : AggregatorType) => ({
                                                    _id: aggregatorType,
                                                    name: displayNameForAggregatorType[aggregatorType]
                                                })
                                            )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div className={styles.subGroupingConfigurationBox}>
                <div className={styles.subGroupingConfigurationBoxHeader}>
                    {
                        subGroupingEnabled && (
                            <div className={styles.configureSubGroupingText}> Configure Grouping </div>
                        )
                    }

                    <ToggleButton
                        label="Add Grouping"
                        value={subGroupingEnabled}
                        wrapperClassName={styles.subGroupingToggleWrapper}
                        className={styles.subGroupingToggle}
                        onToggle={() => {
                            const newSubGroupingEnabled = !subGroupingEnabled;
                            setSubGroupingEnabled(newSubGroupingEnabled)

                            if(!newSubGroupingEnabled) {
                                delete barChartDetails.subGrouping;
                                setBarChartDetails(cloneDeep(barChartDetails))
                            }
                        }}
                    />
                </div>

                {
                    subGroupingEnabled && (
                        <div className={styles.subGroupingConfiguration}>
                            <div className={styles.configureSubGroupingLabel}>
                                <InputField
                                    id="subGroupingFieldLabel"
                                    label="Label"
                                    highlightFieldError={showChartConfigureFormFieldErrors}
                                    isInValid={!barChartDetails.subGrouping?.label}
                                    initialValue={barChartDetails.subGrouping?.label || ''}
                                    type="text"
                                    onChange={(e) => {
                                        const subGrouping : ChartGrouping =
                                            barChartDetails.subGrouping || Builder<ChartGrouping>().build();
                                        subGrouping.label = e.target.value;
                                        barChartDetails.subGrouping = subGrouping;
                                        setBarChartDetails(cloneDeep(barChartDetails));
                                    }}
                                    required={true}
                                />
                            </div>

                            <div className={styles.configureSubGroupingFieldAndAggregator}>
                                <div
                                    className={styles.configureSubGroupingField}
                                    style={{width: checkIfDateAggregationRequired(barChartDetails.subGrouping?.field) ? '50%' : '100%'}}
                                >
                                    <DropdownSelect
                                        placeholder={'Workflow field'}
                                        label={'Workflow field'}
                                        selectedItem={barChartDetails.subGrouping?.field}
                                        onItemSelect={
                                            (fieldId) => {
                                                const subGrouping : ChartGrouping =
                                                    barChartDetails.subGrouping || Builder<ChartGrouping>().build();

                                                subGrouping.field = fieldId;
                                                barChartDetails.subGrouping = subGrouping;
                                                setBarChartDetails(cloneDeep(barChartDetails));
                                            }
                                        }
                                        loading={workflowResponse?.fields === null}
                                        isErrored={
                                            showChartConfigureFormFieldErrors &&
                                            (
                                                barChartDetails.subGrouping?.field === null
                                                || barChartDetails.subGrouping?.field === undefined
                                            )
                                        }
                                        menuData={
                                            [
                                                {
                                                    _id: createdDatePath,
                                                    name: 'Created Date'
                                                },
                                                {
                                                    _id: lastUpdatedDatePath,
                                                    name: 'Last Modified Date'
                                                },
                                                ...workflowResponse.fields?.map(
                                                    (field) => ({
                                                        _id: `fieldsMap.${field._id}`,
                                                        name: field.name
                                                    })
                                                )
                                            ]
                                        }
                                    />
                                </div>

                                {
                                    checkIfDateAggregationRequired(barChartDetails.subGrouping?.field) && (
                                        <div className={styles.configureSubGroupingAggregator}>
                                            <DropdownSelect
                                                placeholder={'Combine by'}
                                                label={'Combine by'}
                                                selectedItem={barChartDetails.subGrouping?.dateFieldAggregationType}
                                                onItemSelect={
                                                    (dateFieldAggregationType) => {
                                                        const subGrouping : ChartGrouping =
                                                            barChartDetails.subGrouping || Builder<ChartGrouping>().build();
                                                        subGrouping.dateFieldAggregationType =
                                                            dateFieldAggregationType as DateFieldAggregationType;
                                                        barChartDetails.subGrouping = subGrouping;
                                                        setBarChartDetails(cloneDeep(barChartDetails));
                                                    }
                                                }
                                                isErrored={
                                                    showChartConfigureFormFieldErrors &&
                                                    (
                                                        barChartDetails.subGrouping?.dateFieldAggregationType === null
                                                        || barChartDetails.subGrouping?.dateFieldAggregationType === undefined
                                                    )
                                                }
                                                menuData={
                                                    Object.keys(DateFieldAggregationType)
                                                        .map(
                                                            (dateAggregationTypeString : string) => DateFieldAggregationType[dateAggregationTypeString as keyof typeof DateFieldAggregationType]
                                                        )
                                                        .map(
                                                            (dateFieldAggregationType : DateFieldAggregationType) => ({
                                                                _id: dateFieldAggregationType,
                                                                name: displayNameForDateFieldAggregationType[dateFieldAggregationType]
                                                            })
                                                        )
                                                }
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
}
