export const TODO_COMPLETE_FORM = {
    MY_TODO_TITLE: ' My To Do',
    DUE_ON: ' Due on ',
    SUCCESS: 'Todo completed',
    COMPLETE_TODO_BUTTON: 'Mark as completed',
    COMMENTS: 'Comments',
    COMPLETED: 'Completed'
};

export const TODO_ERROR_STRINGS = {
    EMPTY_COMMENT: 'Please add rejection comments and then try again.'
};

export const CREATE_TODO = {
    NEW_TODO_BUTTON: " New Todo",
    CREATE_TODO_TITLE: 'Create Todo',
    CREATE_TODO_SUCCESS: 'Todo Successfully Created',
    CREATE_TODO_FAILURE: 'Todo Creation Failed',
    PROGRESS: 'Creating new todo...',
};

export const TODO_FILTER_MENU = {
    DUE_BEFORE: "Due Before:",
    PENDING_ONLY: "Pending Todo Only",
    ASSIGNED: "Assigned To:"
}
