import { Menu, Dropdown } from 'antd';
import {ReactElement} from "react";

/**
 * Collapsible dropdown menu
 * @param props
 * @constructor
 */
const DropdownWrapper = (props: PropTypes) => {
    const { menuData, children } = props;

    const menu = (
        <Menu>
            {
                menuData.map((item: any) => {
                    return (
                        <Menu.Item key={item.id} onClick={(e) => {props.onClick(e, item.id, item.label)}}>
                            <div id={item.id} key={item.id}>
                                {item.label}
                            </div>
                        </Menu.Item>
                    );
                })
            }
        </Menu>
    );

    return (
        <>
            <Dropdown overlay={menu} overlayStyle={{zIndex:10000}} trigger={['click']} placement="bottomRight" arrow>
                {children}
            </Dropdown>
        </>
    );
};

export {DropdownWrapper};

type PropTypes = {
    children: ReactElement;
    menuData: DropdownMenuItem[];
    onClick: (event: any, id: any, label: string) => void;
};

export interface DropdownMenuItem {
    id: number;
    label: string;
    data: object;
}
