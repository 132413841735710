import {
    getMarkerEnd, getSmoothStepPath,
} from 'react-flow-renderer';
import {EdgeProps} from "./EdgeProps";


export default function ReadOnlyEdge(props: EdgeProps) {

    const {
        id,
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
        style = {},
        arrowHeadType,
        markerEndId,
    } = props;

    const edgePath = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

    return (
        <path
            id={id}
            style={style}
            className="react-flow__edge-path"
            d={edgePath}
            markerEnd={markerEnd}
        />
    );
}