import styles from "./StageAssigneeComponent.module.css";
import {
  AssigneeSelect,
  DropdownSelect,
  MenuData,
  ToggleButton,
  TourType,
  useFeatureTour
} from "@eazy2biz-ui/common-components";
import { useState } from "react";
import { STAGE_CONFIGURATIONS } from "../../../../../contents/DisplayContent";
import { AssigneeTypes, Field, FieldTypes, StageAssignee } from "@eazy2biz/common-util";

/**
 * Stage Assignee component.
 * @param props
 * @constructor
 */
export const StageAssigneeComponent = (props: PropTypes) => {
  const {stageAssignee} = props;

  const [assigneeType, setAssigneeType] = useState(stageAssignee.type);
  const [assigneeIds, setAssigneeIds] = useState(stageAssignee.assignees || []);
  const [assigneeUserGroupIds, setAssigneeUserGroupIds] = useState(stageAssignee.userGroupIds || []);
  const [employeeFormFieldId, setEmployeeFormFieldId] = useState<string>(stageAssignee.formFieldId || '');

  const {getRef} = useFeatureTour();
  const getEmployeeFormFields = (): MenuData[] => {
    return props.fields.filter((field) => field.type === FieldTypes.EMPLOYEE)
      .map((field) => ({
        _id: field._id,
        name: field.name
      }))
  };

  const toggleFormFieldAssignee = (value: boolean) => {
    if (value) {
      props.onAssigneesSelect(getStageAssigneeForFormType(employeeFormFieldId));
      setAssigneeType(AssigneeTypes.FORM_FIELD);
    } else {
      props.onAssigneesSelect(getStageAssigneeForInternalType(assigneeIds, assigneeUserGroupIds));
      setAssigneeType(AssigneeTypes.INTERNAL);
    }
  }

  const handleFormFieldSelect = (id: string) => {
    setEmployeeFormFieldId(id);
    props.onAssigneesSelect(getStageAssigneeForFormType(id));
  };

  const handleAssigneeSelection = (userIds: string[], userGroupIds: string[] = []) => {
    setAssigneeIds(userIds);
    setAssigneeUserGroupIds(userGroupIds);

    props.onAssigneesSelect(getStageAssigneeForInternalType(userIds, userGroupIds));
  };

  const renderAssigneeDropdown = () => {

    if (assigneeType !== AssigneeTypes.INTERNAL) {
      return null;
    }

    return (
      <AssigneeSelect
        onAssigneesSelect={handleAssigneeSelection}
        selectedAssignees={assigneeIds}
        selectedAssigneeUserGroupIds={assigneeUserGroupIds}
        userGroups
      />
    );
  };

  const renderFormFieldAssigneeDropdown = () => {

    if (assigneeType !== AssigneeTypes.FORM_FIELD) {
      return null;
    }

    return (
      <DropdownSelect
        label={STAGE_CONFIGURATIONS.ASSIGN_TO}
        menuData={getEmployeeFormFields()}
        selectedItem={employeeFormFieldId || ''}
        onItemSelect={handleFormFieldSelect} />
    );
  };

  return (
    <div className={styles.otherFieldsHeader} ref={getRef(0, TourType.STAGE_CONFIGURATIONS)}>
      <ToggleButton
        value={assigneeType === AssigneeTypes.FORM_FIELD}
        onToggle={toggleFormFieldAssignee}
        label={STAGE_CONFIGURATIONS.ASSIGNEE_FORM_TOGGLE} />
      <div className={styles.assigneeDropdown}>
        {renderAssigneeDropdown()}
        {renderFormFieldAssigneeDropdown()}
      </div>
    </div>
  );
};

type PropTypes = {
  stageAssignee: StageAssignee;
  onAssigneesSelect: (stageAssignee: StageAssignee) => void;
  fields: Field[];
};

const getStageAssigneeForFormType = (id?: string): StageAssignee => {
  return {
    type: AssigneeTypes.FORM_FIELD,
    assignees: [],
    userGroupIds: [],
    formFieldId: id
  };
};

const getStageAssigneeForInternalType = (userIds: string[], userGroupIds: string[]): StageAssignee => {
  return {
    type: AssigneeTypes.INTERNAL,
    assignees: userIds,
    userGroupIds: userGroupIds,
  };
};
