import {PlaceholderOption} from "../../entity/PlaceholderOption";
import {FieldPlaceholder, FieldPlaceholderType, placeholderRegex} from "@eazy2biz/common-util";
import {UserPlaceholderEntity} from "../../entity/UserPlaceholderEntity";
import {markupRegex} from "../../constants/Constants";
import {get} from "lodash";
import { humanizeSnakeCase } from "@eazy2biz/common-package-ui";
import { DynamicPlaceholderConfig } from "../../config/DynamicPlaceholderConfig";

/**
 * Generate Display value from the placeholder.
 * @param placeholderString
 * @param users
 * @param fields
 */
export const getDisplayValueFromPlaceholder = (placeholderString: string, users: UserPlaceholderEntity[], fields: PlaceholderOption[], config: DynamicPlaceholderConfig) => {
    const placeholder: FieldPlaceholder | undefined = FieldPlaceholder.getFromString(placeholderString);

    if (placeholder) {
        switch (placeholder.placeholderType) {
            case FieldPlaceholderType.USER:
                const targetUser = users.filter((user: UserPlaceholderEntity) => user._id === placeholder.entityId)[0];
                if (targetUser) {
                    return `@${get(targetUser, placeholder.key)}`;
                }
                break;
            case FieldPlaceholderType.CARD_FORM:
                const targetField = fields.filter((field: PlaceholderOption) => field.key === placeholder.key)[0];
                if (targetField) {
                    return `@field: ${targetField.name}-${placeholder.subKey || ''}`;
                }
                break;
            case FieldPlaceholderType.DATABASE:
                return `@table: field`;
                break;
            case FieldPlaceholderType.CARD_SYSTEM:
                return `@item: ${humanizeSnakeCase(placeholder.key.toLocaleLowerCase())}`;
                break;
            case FieldPlaceholderType.SYSTEM:
                return `@system: ${humanizeSnakeCase(placeholder.key.toLocaleLowerCase())}`;
                break;
            case FieldPlaceholderType.CARD_STAGE_SYSTEM:
                const stage = config.stages.find((stage) => stage._id === placeholder.entityClassId);
                return `@system: ${stage?.details.name || placeholder.entityClassId}-${humanizeSnakeCase(placeholder.entityId || '')}`;
                break;
        }
    }

    return 'WIP';
};

export const getMarkupValue = (placeHolderValue: string, users: UserPlaceholderEntity[], fields: PlaceholderOption[], config: DynamicPlaceholderConfig) => {
    return `@[${getDisplayValueFromPlaceholder(placeHolderValue, users, fields, config)}](${placeHolderValue})`;
}

/**
 * Process placeholder string into display Value
 * @param value
 * @param users
 * @param fields
 */
export const processInput = (value: string, users: UserPlaceholderEntity[], fields: PlaceholderOption[], config: DynamicPlaceholderConfig) => {
    const placeholderJSON = value.match(placeholderRegex);

    if (placeholderJSON && placeholderJSON.length) {
        placeholderJSON.forEach((json: string) => {
            value = value.replace(json, getMarkupValue(json, users, fields, config));
        });
    }

    return value;
};

/**
 * Processes input event for placeholders.
 */
export const processEvent = (e: any) => {
    let value = e.target.value;
    const placeholderMarkup = value.match(markupRegex);
    const placeholderJSON = value.match(placeholderRegex);
    if (placeholderMarkup && placeholderMarkup.length && placeholderMarkup.length === placeholderJSON.length) {
        placeholderMarkup.forEach((markup: string, idx: number) => {
            value = value.replace(markup, placeholderJSON[idx]);
        });
    }
    return { target: { ...e.target, value } };
};
