import {RestServices} from "@eazy2biz/common-package-ui";
import {
    ForgotPasswordConfirmRequest,
    GlobalUserResponse,
    PhoneNumberContact,
    SignInResponse
} from "@eazy2biz/common-util";
import {getConfirmSignUpPayload, getSignInPayload, getSignUpPayload} from "./helpers/IdentityHelper";

const GLOBAL_USER_SERVICE = () => RestServices.getInstance().getIdentitiesService().getGlobalUserService();

export const signIn = (
    email: string,
    password: string
) : Promise<SignInResponse> => {
    return GLOBAL_USER_SERVICE().signIn(getSignInPayload(email, password));
};

/**
 * Signs Up user
 * @param name
 * @param email
 * @param password
 */
export const signUp = (
    name : string,
    email : string,
    password : string,
    phoneNumber : PhoneNumberContact,
) : Promise<GlobalUserResponse> => {
    return GLOBAL_USER_SERVICE().signUp(getSignUpPayload(name, email, password, phoneNumber));
};


/**
 * Confirm Sign Up
 * @param email email
 * @param passcode passcode
 */
export const confirmSignUp = (
    email: string,
    passcode: string
) => {
    return GLOBAL_USER_SERVICE().confirmSignUp(getConfirmSignUpPayload(email, passcode));
};

/**
 * Fetches Global User for current User.
 */
export const getGlobalUserByIdToken = () : Promise<GlobalUserResponse> => {
    return GLOBAL_USER_SERVICE().getGlobalUserByIdToken();
};

export const initiateChangePassword = (email: string) => {
    return GLOBAL_USER_SERVICE().initiateForgotPassword({emailAddress: email});
};

export const changePassword = (forgotPasswordConfirmRequest: ForgotPasswordConfirmRequest) => {
  return GLOBAL_USER_SERVICE().confirmForgotPassword(forgotPasswordConfirmRequest);
};
