// TODO: this file is still to be completed

import { GENERIC_MESSAGE } from "../../../contents/DisplayContent";
import { TemplateParameter, TemplateResponse, WhatsappMessageTemplateDetails } from "@eazy2biz/common-util";
import { StringWithParametersCompleteDetails, WhatsappTemplateTypes } from "@eazy2biz/common-util";
import { StrictBuilder } from "builder-pattern";

export type WhatsappTemplateConfigurationType = {
  name: string;
  templateType: WhatsappTemplateTypes;
  footer: string;
  header: StringWithParametersCompleteDetails;
  body: StringWithParametersCompleteDetails;
  language: string;
  templateParameters: TemplateParameter[];
};

export const getDefaultWhatsappTemplateConfiguration = (whatsappTemplate?: TemplateResponse): WhatsappTemplateConfigurationType => {
  const templateDetails: WhatsappMessageTemplateDetails | undefined = whatsappTemplate?.templateDetails as WhatsappMessageTemplateDetails;
  return StrictBuilder<WhatsappTemplateConfigurationType>()
     .name(whatsappTemplate?.details.name || '')
     .templateType(templateDetails?.whatsappTemplateType || WhatsappTemplateTypes.UTILITY)
     .footer(templateDetails?.footer || GENERIC_MESSAGE.FOOTER_TEXT)
     .header({
      text: templateDetails?.header?.text || '',
      parameters: templateDetails?.header?.parameters || []
     })
     .body({
      text: templateDetails?.body?.text ||  '',
      parameters: templateDetails?.body?.parameters || []
     })
    .language(templateDetails?.language || 'en')
    .templateParameters(whatsappTemplate?.parameters || [])
    .build();
};

export const checkIfMandatoryFieldsAreFilledForWhatsappTemplateSettings = (
  whatsappTemplateConfiguration: WhatsappTemplateConfigurationType
): boolean => {
  return whatsappTemplateConfiguration.name !== '';
};

export const checkIfMandatoryFieldsAreFilledForWhatsappTemplateConfiguration = (
  whatsappTemplateConfiguration: WhatsappTemplateConfigurationType
): boolean => {
  return whatsappTemplateConfiguration.header.text !== '' &&
  whatsappTemplateConfiguration.body.text !== '';
};
