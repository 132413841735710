import {Button, Skeleton} from "antd";
import {GenericButtonTypes} from "./GenericButtonTypes";
import styles from './GenericButton.module.css';
import classNames from "classnames";
import {forwardRef, ReactElement} from "react";
import {CheckIcon, Cross1Icon} from "@radix-ui/react-icons";

/**
 * Generic Button Component
 * @param props
 * @constructor
 */
const GenericButton = forwardRef((props:ButtonType, ref: any) => {
    const { type = GenericButtonTypes.PRIMARY, isLoading, CustomIcon = getDefaultIcon(type), buttonText, onClick, className, disabled = false, showSkeleton } = props;

    if (showSkeleton) {
        return <Skeleton.Button active block />;
    }

    const getWrapperClassNames = () => {
        switch (type) {
            case GenericButtonTypes.SECONDARY:
                return styles.genericButtonSecondary;
            case GenericButtonTypes.SUCCESS:
                return styles.genericButtonSuccess;
            case GenericButtonTypes.REJECT:
                return styles.genericButtonReject;
            case GenericButtonTypes.DASHED:
                return styles.genericButtonDashed;
            default:
                return styles.genericButtonPrimary;
        }
    };

    return (
        <span className={classNames(styles.genericButton, getWrapperClassNames())}>
              <Button
                  loading={isLoading}
                  className={
                      classNames(
                          type === GenericButtonTypes.DASHED ? styles.btnCtnDashed : styles.btnCtn,
                          className,
                      )
                  }
                  onClick={onClick}
                  icon={CustomIcon ? <CustomIcon /> : null}
                  disabled={disabled}
                  ref={ref}>
                  {buttonText}
              </Button>
        </span>
    )
});

interface ButtonType{
    onClick: () => void;
    buttonText?: string | ReactElement;
    isLoading?: boolean;
    CustomIcon?: any;
    type?: GenericButtonTypes;
    className?: string;
    disabled?: boolean;
    showSkeleton?: boolean;
}

export default GenericButton

const getDefaultIcon = (type: GenericButtonTypes) => {
  switch (type) {
      case GenericButtonTypes.REJECT:
          return Cross1Icon;
      case GenericButtonTypes.SUCCESS:
          return CheckIcon;
      default:
          return '';
  }
};
