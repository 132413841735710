import {SendEmail} from "./Actions/SendEmailAction/SendEmail";
import {
    ActionResult,
    ActionResultTypes,
    BaseActionResultDetails,
    ConditionExpression,
    Field, Stage
} from "@eazy2biz/common-util";
import {useState} from "react";
import ConditionBuilder from "../../conditionComponent/ConditionBuilder";
import {CONDITION_COMPONENT_STRINGS} from "../../../../../contents/DisplayContent";
import {PlusIcon} from "@radix-ui/react-icons";
import styles from './ActionDetailSelectorComponent.module.css';
import {CloseButton} from "@eazy2biz-ui/common-components";
import {WhatsappMessageActionComponent} from "./Actions/WhatsappMessage/WhatsappMessageActionComponent";
import {PushNotificationActionComponent} from "./Actions/PushNotification/PushNotificationActionComponent";
import { MessageActionComponent } from "./Actions/MessageAction/MessageActionComponent";
import { TriggerWorkflowActionComponent } from "./Actions/TriggerWorkflowAction/TriggerWorkflowActionComponent";
import { SaveTableRecordActionComponent } from "./Actions/SaveTableRecordAction/SaveTableRecordActionComponent";

export const ActionDetailSelectorComponent = (props: PropTypes) => {

    const {actionResult, onConditionUpdate, condition} = props;

    const [conditionBuilderModelVisible, setConditionBuilderModalVisible] = useState(false);

    const handleConditionSave = (condition?: ConditionExpression) => {
        setConditionBuilderModalVisible(false);
        onConditionUpdate(condition);
    };

    const removeCondition = () => {
        onConditionUpdate();
    };

    const renderResultDetail = () => {
        switch(actionResult.type) {
            case ActionResultTypes.WHATSAPP_MESSAGE:
                return <WhatsappMessageActionComponent {...props} />;
            case ActionResultTypes.EMAIL:
                return <SendEmail {...props}/>;
            case ActionResultTypes.PUSH_NOTIFICATION:
                return <PushNotificationActionComponent {...props} />;
            case ActionResultTypes.MESSAGE:
                return <MessageActionComponent {...props} />
            case ActionResultTypes.TRIGGER_WORKFLOW:
                return <TriggerWorkflowActionComponent {...props} />
            case ActionResultTypes.SAVE_TABLE_RECORD:
                return <SaveTableRecordActionComponent {...props} />
            default:
                return null;
        }
    };

    const renderAddConditionButton = () => {
        return (
            <div className={styles.addConditionBtn_Action} onClick={() => setConditionBuilderModalVisible(true)}>
                <PlusIcon />
                <span>{CONDITION_COMPONENT_STRINGS.ADD_NEW_CONDITION}</span>
            </div>
        );
    };

    const renderConditionPlaceholder = () => {
        return(
            <div className={styles.actionConditionPlaceholder}>
                <span onClick={() => setConditionBuilderModalVisible(true)}>Condition Added</span>
                <CloseButton size={'small'} onClose={removeCondition}/>
            </div>
        );
    };

    return(
        <>
            {!condition && renderAddConditionButton()}
            {condition && renderConditionPlaceholder()}
            <div className={styles.resultDetailsWrapper}>
                {renderResultDetail()}
            </div>
            {conditionBuilderModelVisible &&
                <ConditionBuilder
                    fields={props.fields}
                    onClose={() => setConditionBuilderModalVisible(false)}
                    onSubmit={handleConditionSave}
                    condition={condition}
                />
            }
        </>
    );

}

type PropTypes = {
    fields: Field[];
    stages: Stage[];
    actionResult: ActionResult<BaseActionResultDetails>;
    condition?: ConditionExpression;
    onConditionUpdate: (condition?: ConditionExpression) => void;
    onActionResultUpdate: (actionResult: ActionResult<BaseActionResultDetails>) => void;
};
