import { ReactElement } from 'react';
import { FormElementConfig } from '../../../entity/formBuilder/FormElementConfig';
import styles from './FieldConfigurations.module.css';
import { cloneDeep } from 'lodash';
import { toggleFieldRequired } from '../../../helpers/formBuilderHelpers/runtimeHelpers/FormBuilderHelper';
import { ToggleButton } from '@eazy2biz-ui/common-components';
import { FieldTypes } from '@eazy2biz/common-util';
import { DropdownConfigurations } from './customConfigurations/DropdownConfigurations';
import { DatabaseConfigurations } from './customConfigurations/DatabaseConfigurations';
import { ArrowLeftIcon } from '@radix-ui/react-icons';
import { TableFieldConfigurations } from './customConfigurations/TableFieldConfigurations';

const FieldConfigurations = (props: PropTypes): ReactElement => {

    const handleFieldNameChange = (fieldLabel:string) => {
        let updatedField:FormElementConfig = cloneDeep(props.field);
        updatedField.element.name = fieldLabel;
        updatedField.element.helpText = fieldLabel;
        props.onFieldUpdate(updatedField);
    }

    const handleHelpTextChange = (helpText:string) => {
        let updatedField:FormElementConfig = cloneDeep(props.field);
        updatedField.element.helpText = helpText;
        props.onFieldUpdate(updatedField);
    }

    const changeFieldRequirement = () => {
        let updatedField:FormElementConfig = toggleFieldRequired(cloneDeep(props.field));
        props.onFieldUpdate(updatedField)
    };

    const renderCustomFieldConfigurations = () => {
        switch (props.field.element.type) {
            case FieldTypes.DROPDOWN:
                return <DropdownConfigurations field={props.field} onFieldUpdate={props.onFieldUpdate} />;
            case FieldTypes.DATABASE:
                return <DatabaseConfigurations field={props.field} onFieldUpdate={props.onFieldUpdate} />;
            case FieldTypes.TABLE:
                return <TableFieldConfigurations field={props.field} onFieldUpdate={props.onFieldUpdate} />
            default: return null;
        }
    }

    return (
        <div className={styles.fieldSettings}>
            <div className={styles.fieldSettingsHeader}>
                <ArrowLeftIcon width={'20'} height={'20'} fontSize={"20px"} onClick={props.onClose}/>
                <p>Field Settings</p>
            </div>
            <div className={styles.inputFieldsCtn}>
                <label>Field Name</label>
                <input type='text' id= {'fieldName'} value={props.field.element.name} onChange={(e)=>handleFieldNameChange(e.target.value)}/>
            </div>
            <div className={styles.inputFieldsCtn}>
                <label>Field Help Text</label>
                <input type='text' id={'fieldHelpText'} value={props.field.element.helpText} onChange={(e)=>handleHelpTextChange(e.target.value)}/>
            </div>
            <ToggleButton label="Field Required ?" value={props.field.isRequired} wrapperClassName={styles.fieldRequiredCtn} className={styles.toggleCtn} onToggle={changeFieldRequirement}/>
            {renderCustomFieldConfigurations()}
        </div>
    );
};

type PropTypes = {
    field: FormElementConfig;
    onFieldUpdate: (updatedField: FormElementConfig) => void;
    onClose: () => void;
};

export default FieldConfigurations;
