import {useCookies} from "react-cookie";
import React from "react";
import CoreComponent from "../core/CoreComponent";
import {CookiesService} from "@eazy2biz-ui/common-package";

const CustomCookiesProvider: (props: any) => JSX.Element = (props: any) => {

    const [cookies, setCookie, removeCookie] = useCookies();

    CookiesService.init(cookies, setCookie, removeCookie);

    return (
        <CoreComponent {...props} />
    );
}

export default CustomCookiesProvider;