import {
    AdvancedFilterComponent, AdvancedFilterConfig,
    AssigneeSelect, DropdownMultiSelect,
    GenericButton,
    GenericButtonTypes,
    InputField, MenuData,
    ToggleButton
} from "@eazy2biz-ui/common-components";
import { getCurrentUserId } from "@eazy2biz/common-package-ui";
import { cloneDeep } from "lodash";
import { ITEM_FILTER_MENU } from "../../../../../contents/DisplayContent";
import styles from "./ItemFilterMenu.module.css";
import {MixerHorizontalIcon} from "@radix-ui/react-icons";
import { Badge, Divider } from "antd";
import { Field, WorkflowResponse } from "@eazy2biz/common-util";
import { useState } from "react";
import classNames from "classnames";
import {getOperatorMenuForField} from "@eazy2biz-ui/common-package";

/**
 * To do Filter Menu
 * @param props
 * @constructor
 */
export const ItemFilterMenu = (props: PropTypes) => {
    const {filterConfig} = props;
    const {visibilityConfig} = filterConfig;

    const [advancedFilterVisible, setAdvancedFilterVisible] = useState(false);

    const onDueDateChange = (e: any) => {
        const filter = cloneDeep(filterConfig);
        filter.dueDate = e.target.value;
        props.onFilterChange(filter);
    };

    const onCreatedDateBeforeChange = (e: any) => {
        const filter = cloneDeep(filterConfig);
        filter.createdDateBefore = e.target.value;
        props.onFilterChange(filter);
    };

    const onCompletedDateBeforeChange = (e: any) => {
        const filter = cloneDeep(filterConfig);
        filter.completedDateBefore = e.target.value;
        props.onFilterChange(filter);
    };

    const onPendingOnlyToggle = () => {
        const filter = cloneDeep(filterConfig);
        filter.pendingOnly = !filter.pendingOnly;
        if (filter.pendingOnly) {
            filter.completedOnly = false;
            filter.completedDateBefore = undefined;
        }
        props.onFilterChange(filter);
    };

    const assigneeChange = (ids: string[]) => {
        const filter = cloneDeep(filterConfig);
        filter.assigneeUserId = ids[0];
        props.onFilterChange(filter);
    };

    const currentStageChange = (stageIds: string[] = []) => {
        const filter = cloneDeep(filterConfig);
        filter.currentStages = stageIds;
        props.onFilterChange(filter);
    };

    const handleAdvancedFilterChange = (filters: AdvancedFilterConfig[]) => {
        const filter = cloneDeep(filterConfig);
        filter.advancedFilters = filters;
        props.onFilterChange(filter);
        handleAdvancedFilterToggle();
        props.onClose();
    };

    const handleAdvancedFilterToggle = () => {
        setAdvancedFilterVisible(!advancedFilterVisible);
    };

    const renderAdvancedFilters = () => {
        return (
          <AdvancedFilterComponent
            toggleAdvancedFilter={handleAdvancedFilterToggle}
            firstFieldMenuData={props.fields}
            filters={filterConfig.advancedFilters}
            onChange={handleAdvancedFilterChange}
            getFieldType={(f) => getOperatorMenuForField(f.firstFieldId, props.fields)}
            applyFilterButtonVisible={true}
          />
        );
    };

    if (advancedFilterVisible) {
        return renderAdvancedFilters();
    }

    return(
        <div>
            {
                visibilityConfig.createdDateBefore &&
                <InputField
                    label={ITEM_FILTER_MENU.CREATED_BEFORE}
                    onChange={onCreatedDateBeforeChange}
                    id={ITEM_FILTER_MENU.CREATED_BEFORE}
                    required={false}
                    type='date'/>
            }
            {
                visibilityConfig.dueDateBefore &&
                <InputField
                    className={styles.menuItem}
                    label={ITEM_FILTER_MENU.DUE_BEFORE}
                    onChange={onDueDateChange}
                    id={ITEM_FILTER_MENU.DUE_BEFORE}
                    required={false}
                    type='date'/>
            }
            {
                visibilityConfig.completedDateBefore &&
                <InputField
                    className={styles.menuItem}
                    label={ITEM_FILTER_MENU.COMPLETED_BEFORE}
                    onChange={onCompletedDateBeforeChange}
                    id={ITEM_FILTER_MENU.COMPLETED_BEFORE}
                    required={false}
                    type='date'/>
            }
            {
                visibilityConfig.assigneeUserId && <AssigneeSelect
                label={ITEM_FILTER_MENU.ASSIGNED_TO}
                required={false}
                multiSelect={false}
                selectedAssignees={[filterConfig.assigneeUserId || '']}
                onAssigneesSelect={assigneeChange}/>
            }
            {
              visibilityConfig.currentStage && <DropdownMultiSelect
                label={ITEM_FILTER_MENU.CURRENT_STAGE}
                menuData={getStagesMenuDataForWorkflow(props.workflow)}
                selectedItem={filterConfig.currentStages}
                onItemSelect={currentStageChange}
                />
            }
            {visibilityConfig.pendingOnly && <ToggleButton value={filterConfig.pendingOnly} onToggle={onPendingOnlyToggle} label={ITEM_FILTER_MENU.PENDING_ONLY} />}
            <Divider />
            <GenericButton
                className={classNames(styles.advancedFilterButton)}
                buttonText={
                    <Badge
                      offset={[2, 3]}
                      dot
                      count={filterConfig.advancedFilters.length}
                      status={'default'}>
                        <div>{ITEM_FILTER_MENU.ADVANCED_FILTER}</div>
                    </Badge>
                }
                type={GenericButtonTypes.SECONDARY}
                CustomIcon={MixerHorizontalIcon}
                onClick={handleAdvancedFilterToggle} />
        </div>
    );
};

type PropTypes = {
    workflow: WorkflowResponse;
    onFilterChange: (config: ItemFilterConfig) => void;
    filterConfig: ItemFilterConfig;
    fields: Field[];
    onClose: () => void;
};

export class ItemFilterConfig {
    visibilityConfig: ItemFiltersVisibilityConfig = new ItemFiltersVisibilityConfig();
    dueDate?: Date;
    pendingOnly: boolean = true;
    completedOnly: boolean = false;
    createdDateBefore?: Date;
    completedDateBefore?: Date;
    assigneeUserId?: string;
    currentStages: string[] = [];
    advancedFilters: AdvancedFilterConfig[] = [];

    constructor() {
        this.assigneeUserId = getCurrentUserId();
    }

    static getClearFilter = () => {
        const filter = new ItemFilterConfig();
        filter.pendingOnly = false;
        filter.assigneeUserId = undefined;
        return filter;
    }
}

export class ItemFiltersVisibilityConfig {
    dueDateBefore: boolean = true;
    pendingOnly: boolean = true;
    createdDateBefore: boolean = true;
    completedDateBefore: boolean = true;
    assigneeUserId: boolean = true;
    currentStage: boolean = true;
}

const getStagesMenuDataForWorkflow = (workflow: WorkflowResponse): MenuData[] => {
  return workflow.stages.map((stage) => ({
      _id: stage._id,
      name: stage.details.name,
  }));
};
