import {UIApplicationInit,} from "@eazy2biz-ui/common-package";
import {getGlobalUserByIdToken} from "../services/GlobalUserService";
import {
    CompanyResponse,
    GlobalUserCompanyMembership,
    GlobalUserResponse
} from "@eazy2biz/common-util";

export const handleCompanySignIn = (globalUserCompanyMembership: GlobalUserCompanyMembership) => {
    return UIApplicationInit.getInstance().postCompanySignIn(globalUserCompanyMembership);
    // await setTimeout(() => {}, 500);
}

/**
 * Fetches User and Signs In to the passed Company.
 * @param company Company to sign into.
 */
export const signInToTheCompany = async (company: CompanyResponse) : Promise<GlobalUserCompanyMembership | undefined> => {
    const response: GlobalUserResponse = await getGlobalUserByIdToken();
    return response.companyMemberships.find(
        (element) => element.companyId === company._id);
};
