import React from "react";
import MyWorkflowIcon from "../../../assets/myWorkflowIcon.svg";
import WorkflowCanvas from "../canvas/WorkflowCanvas";
import { Elements, Node } from "react-flow-renderer/dist/types";
import initialEdges from "./initalData/InitialEdges";
import {
  EditInput,
  GenericButton,
  GenericButtonTypes,
  GenericTag,
  GenericTagTypes,
  GenericTrowser,
  Toast
} from "@eazy2biz-ui/common-components";
import { Drawer } from "antd";
import "./drawer.css";
import FormBuilder from "../formBuilder/FormBuilder/FormBuilder";
import initialWorkflowBuilderState from "./initalData/initialWorkflowBuilderState";
import StageConfigurations from "./stageConfigurations/StageConfigurations";
import {
  addStage,
  deleteStageV2
} from "../../helpers/workflowBuilderHelpers/runtimeHelpers/WorkflowBuilderStageHelper";
import { StageData } from "../../entity/workflowBuilder/StageData";
import {
  validateAndPublishWorkflow,
  validateAndPublishWorkflowUpdate
} from "../../helpers/workflowBuilderHelpers/publishHelpers/PublishWorkflowHelper";
import {
  findElementById,
  updateElementById
} from "../../helpers/workflowBuilderHelpers/runtimeHelpers/WorkflowBuilderFlowHelper";
import { fieldAccessMapPath } from "../../entity/workflowBuilder/workflow/PathConstants";
import {
  WORKFLOW_BUILDER_ERROR_STRINGS,
  WORKFLOW_BUILDER_SUCCESS_STRINGS,
  workflowContent
} from "../../../contents/DisplayContent";
import styles from "./WorkflowBuilder.module.css";
import { ConditionExpression, Details, FieldAccessMap, SecurityGroupFeatures, StageTypes } from "@eazy2biz/common-util";
import {
  getDraftWorkflowState,
  getDraftWorkflowStateKey,
  getWorkflowStateJSONForDraft,
  LocalStorageService,
  resolveFeatureFlag,
  RoutesEnum
} from "@eazy2biz-ui/common-package";
import {
  getOrderedStageListToCurrentStage, getStageDataFromElements
} from "../../helpers/workflowBuilderHelpers/runtimeHelpers/workflow/WorkflowGraphHelper";
import ConditionBuilder from "./conditionComponent/ConditionBuilder";
import { cloneDeep } from "lodash";
import { FormEntity, UserActions, UserAuthorizationService } from "@eazy2biz/common-package-ui";
import {
  getFilteredFormFieldsForStages,
  updateFieldForUpdatedStage
} from "../../helpers/workflowBuilderHelpers/runtimeHelpers/FormHelper";
import { EdgeData } from "../../entity/workflowBuilder/EdgeData";
import { Edge } from "react-flow-renderer/nocss";
import { EdgeConnectionComponent } from "./edgeConnectionComponent/EdgeConnectionComponent";

class WorkflowBuilder extends React.Component<PropTypes, WorkflowBuilderStateType> {
  constructor(props: PropTypes) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = (): WorkflowBuilderStateType => {
    return {
      ...initialWorkflowBuilderState(),
      edges: this.processEdges(initialEdges)
    };
  };

  componentDidMount() {
    const workflowStateJSON = LocalStorageService.getItem(getDraftWorkflowStateKey());
    const workflowState = getDraftWorkflowState(workflowStateJSON);
    if (workflowState) {
      this.setState({
        workflowId: workflowState.workflowId,
        workflowDetail: workflowState.workflowDetail,
        stages: workflowState.stages,
        edges: workflowState.edges,
        form: workflowState.form,
        isDraft: true,
        workflowEditMode: workflowState.workflowId !== undefined
      });
    }

    // TODO: fix for workflow.
    UserAuthorizationService.getInstance()
      .isActionAllowed(UserActions.CREATE, SecurityGroupFeatures.USERS)
      .then((allowed) => {
        if (!allowed) {
          Toast.warn(WORKFLOW_BUILDER_ERROR_STRINGS.NO_PERMISSION_FOR_WORKFLOW);
          this.handleClose();
        }
      });
  }

  handleClose = () => {
    this.state.workflowId && this.clearLocalDraft();
    this.props.history.replace(RoutesEnum.APP_DASHBOARD);
  };

  /**
   * Add button click callback to edges.
   * @param edges
   * @return edges
   */
  processEdges = (edges: Elements): Elements => {
    edges.forEach((edge) => {
      edge.data.onClick = this.handleAddStage;
      edge.data.onEdgeConnectionUpdate = this.handleEdgeConnectionUpdate;
    });
    return edges;
  };

  /**
   * Callback for add stage button
   * @param event
   * @param edgeId
   * @param stageType
   */
  handleAddStage = (event: any, edgeId: string, stageType: StageTypes): void => {
    event.stopPropagation();
    const { stages, edges, form } = this.state;
    try {
      this.setState(addStage(edgeId, stages, edges, stageType, form));
    } catch (err) {
      Toast.errorString(WORKFLOW_BUILDER_ERROR_STRINGS.ERROR_ADDING_STAGE);
    }
  };

  handleEdgeConnectionUpdate = (event: any, edgeId: string): void => {
    event.stopPropagation();
    this.setState({selectedEdgeId: edgeId});
  };

  handleFormUpdate = (updatedForm: FormEntity, fieldAccessMap: FieldAccessMap) => {
    const { selectedFormBuilderStageId, stages } = this.state;
    if (selectedFormBuilderStageId !== undefined) {
      this.setState({
        form: updatedForm,
        stages: updateElementById(
          stages,
          selectedFormBuilderStageId,
          fieldAccessMap,
          fieldAccessMapPath
        ),
        formBuilderVisible: false,
        selectedFormBuilderStageId: undefined
      });
    }
  };

  /**
   * Callback for stage Selection/Deselection
   * @param stageId
   */
  handleStageSelection = (stageId?: string) => {
    this.setState({
      selectedStageId: stageId,
      leftDrawerVisible: stageId !== undefined
    });
  };

  /**
   * Removes a stage.
   * @param stageId
   */
  handleRemoveStage = (stageId: string) => {
    try {
      const { stages, edges, form } = this.state;
      const {
        stages: newStages,
        edges: newEdges,
        form: newForm
      } = deleteStageV2(stageId, stages as Node<StageData>[], edges as Edge<EdgeData>[], form);

      this.setState({
        stages: newStages,
        edges: newEdges,
        form: newForm,
        selectedStageId: undefined,
        selectedFormBuilderStageId: undefined,
        leftDrawerVisible: false
      });
    } catch (e: any) {
      Toast.error(e);
    }
  };

  /**
   * Handles update Stage
   * @param id
   * @param data
   */
  handleUpdateStage = (id: string, data: StageData) => {
    this.handleStageSelection();
    this.setState({
      stages: updateElementById(this.state.stages, id, data, 'data'),
      form: updateFieldForUpdatedStage(this.state.form, data)
    });
  };

  /**
   * Update Conditional Stage - Condition
   * @param stageId
   * @param condition
   */
  handleUpdateStageCondition = (stageId: string, condition?: ConditionExpression) => {
    const stageData: StageData = cloneDeep(findElementById(this.state.stages, stageId).data);
    stageData.otherConfigurations.condition = condition;

    this.handleUpdateStage(stageId, stageData);
    this.toggleFormBuilder();
  };

  toggleFormBuilder = (stageId?: string) => {
    this.setState({
      formBuilderVisible: !this.state.formBuilderVisible,
      selectedFormBuilderStageId: stageId
    });
  };

  clearLocalDraft = () => {
    LocalStorageService.removeItem(getDraftWorkflowStateKey());
    this.setState(this.getInitialState());
  };

  /**
   * Transform and publishes the workflow.
   */
  handleWorkflowPublish = () => {
    this.setState({
      workflowPublishInProgress: true
    });

    const { workflowId, workflowDetail, stages, edges, form } = this.state;

    try {
      if (workflowId) {
        Toast.load(workflowContent.UPDATE_WORKFLOW_PROGRESS);
        validateAndPublishWorkflowUpdate(
          workflowId,
          workflowDetail.name,
          workflowDetail.description,
          stages,
          edges,
          form,
          []
        )
          .then(() => {
            Toast.success(WORKFLOW_BUILDER_SUCCESS_STRINGS.PUBLISH_SUCCESS);
            LocalStorageService.removeItem(getDraftWorkflowStateKey());
            this.handleClose();
          })
          .catch((e) =>
            Toast.error(e, WORKFLOW_BUILDER_ERROR_STRINGS.UNKNOWN_SAVE_ERROR))
          .finally(() =>
            this.setState({
              workflowPublishInProgress: false
            })
          );
      } else {
        Toast.load(workflowContent.CREATE_WORKFLOW_PROGRESS);
        validateAndPublishWorkflow(
          workflowDetail.name,
          workflowDetail.description,
          stages,
          edges,
          form
        )
          .then(() => {
            Toast.success(WORKFLOW_BUILDER_SUCCESS_STRINGS.PUBLISH_SUCCESS);
            LocalStorageService.removeItem(getDraftWorkflowStateKey());
            this.handleClose();
          })
          .catch((e) => Toast.error(e, WORKFLOW_BUILDER_ERROR_STRINGS.UNKNOWN_SAVE_ERROR))
          .finally(() =>
            this.setState({
              workflowPublishInProgress: false
            })
          );
      }
    } catch (e: any) {
      Toast.error(e, WORKFLOW_BUILDER_ERROR_STRINGS.UNKNOWN_SAVE_ERROR);
      this.setState({
        workflowPublishInProgress: false
      });
    }
  };

  handleWorkflowSave = () => {
    const { workflowId, workflowDetail, stages, edges, form } = this.state;
    const workflowStateJSON = getWorkflowStateJSONForDraft({
      workflowId,
      workflowDetail,
      stages,
      edges,
      form
    });
    LocalStorageService.setItem(getDraftWorkflowStateKey(), workflowStateJSON);
    Toast.success(WORKFLOW_BUILDER_SUCCESS_STRINGS.SAVE_DRAFT);
    this.setState({
      isDraft: true
    });
  };

  handleWorkflowSimulate = () => {
    //todo: handle workflow simulate
  };

  handleWorkflowNameChange = (value: string) => {
    this.setState((prevState) => ({
      workflowDetail: {
        ...prevState.workflowDetail,
        name: value
      }
    }));
  };

  renderConditionBuilder = (stageData: StageData) => {
    const { stages, edges, form } = this.state;
    const stageIdsList = getOrderedStageListToCurrentStage(stages, edges, stageData.id);
    const fields = getFilteredFormFieldsForStages(form.formFields, stageIdsList);

    return (
      <ConditionBuilder
        fields={fields}
        condition={stageData.otherConfigurations.condition}
        onClose={() => this.toggleFormBuilder()}
        onSubmit={(condition?: ConditionExpression) =>
          this.handleUpdateStageCondition(stageData.stageConfiguration._id, condition)
        }
      />
    );
  };

  renderFormBuilder = (stageData: StageData): React.ReactElement | null => {
    const { form, selectedFormBuilderStageId, stages, edges, workflowEditMode } = this.state;

    if (!selectedFormBuilderStageId) {
      return null;
    }

    const stageIdsList = getOrderedStageListToCurrentStage(
      stages,
      edges,
      selectedFormBuilderStageId
    );

    return (
      <FormBuilder
        form={form}
        workflowEditMode={workflowEditMode}
        stages={stages}
        stageIdsList={stageIdsList}
        stageData={stageData}
        onToggleFormBuilder={this.toggleFormBuilder}
        onFormUpdate={this.handleFormUpdate}
      />
    );
  };

  renderStageFormBuilderByType = () => {
    const { selectedFormBuilderStageId, stages, formBuilderVisible } = this.state;

    if (!formBuilderVisible || !selectedFormBuilderStageId) {
      return null;
    }

    const stageData: StageData = findElementById(stages, selectedFormBuilderStageId).data;

    if (
      [StageTypes.FORM_STAGE, StageTypes.ENTRY_STAGE, StageTypes.APPROVAL_STAGE].includes(
        stageData.stageConfiguration.type
      )
    ) {
      return this.renderFormBuilder(stageData);
    }

    if (stageData.stageConfiguration.type === StageTypes.CONDITIONAL_STAGE) {
      return this.renderConditionBuilder(stageData);
    }

    return null;
  };

  renderLeftDrawer = (): React.ReactElement | null => {
    const { selectedStageId, leftDrawerVisible, stages, edges, form, workflowEditMode } =
      this.state;

    if (!selectedStageId) {
      return null;
    }

    const stageIdsList = getOrderedStageListToCurrentStage(stages, edges, selectedStageId);
    const fields = getFilteredFormFieldsForStages(form.formFields, stageIdsList);
    const dfsStages = getStageDataFromElements(stages.filter((stage) => stageIdsList.includes(stage.id)));

    return (
      <Drawer
        placement="left"
        closeIcon={false}
        visible={leftDrawerVisible}
        getContainer={false}
        style={{ position: 'absolute', paddingTop: '0' }}
        mask={false}>
        <StageConfigurations
          fields={fields}
          stages={dfsStages}
          workflowEditMode={workflowEditMode}
          stage={findElementById(stages, selectedStageId).data}
          onToggleFormBuilder={this.toggleFormBuilder}
          onStageUpdate={this.handleUpdateStage}
          onStageDelete={this.handleRemoveStage}
        />
      </Drawer>
    );
  };

  renderWorkflowCanvas() {
    const { stages, edges, selectedStageId } = this.state;

    return (
      <WorkflowCanvas
        readOnly={false}
        edges={edges}
        stages={stages}
        onAddElementClick={this.handleAddStage}
        onEdgeConnectionUpdate={this.handleEdgeConnectionUpdate}
        onElementClick={this.handleStageSelection}
        selectedNodeId={selectedStageId}
        onSecondaryNodeClick={this.toggleFormBuilder}
      />
    );
  }

  renderTitle = () => {
    const { isDraft } = this.state;
    return (
      <>
        <img className={styles.workflowBuilderIcon} src={MyWorkflowIcon} />
        <EditInput
          value={this.state.workflowDetail.name}
          size={'large'}
          onChange={this.handleWorkflowNameChange}
        />
        {this.state.workflowId && <GenericTag title={workflowContent.UPDATE_WORKFLOW} type={GenericTagTypes.ACTIVE} />}

        {isDraft && <GenericTag title={workflowContent.DRAFT} type={GenericTagTypes.ERROR} />}
      </>
    );
  };

  renderWorkflowBuilderHeader = () => {
    return (
      <>
        {resolveFeatureFlag('WORKFLOW_SIMULATE') && (
          <span className={styles.actionButton}>
            <GenericButton
              onClick={this.handleWorkflowSimulate}
              buttonText={workflowContent.SIMULATE}
              type={GenericButtonTypes.SECONDARY}
            />
          </span>
        )}
        <span className={styles.actionButton}>
          <GenericButton
            onClick={this.clearLocalDraft}
            buttonText={workflowContent.RESET}
            type={GenericButtonTypes.SECONDARY}
          />
        </span>
        <span className={styles.actionButton}>
          <GenericButton
            onClick={this.handleWorkflowSave}
            buttonText={workflowContent.SAVE}
            type={GenericButtonTypes.SECONDARY}
          />
        </span>
        <span className={styles.actionButton}>
          <GenericButton
            onClick={this.handleWorkflowPublish}
            buttonText={(this.state.workflowId) ? workflowContent.UPDATE_WORKFLOW : workflowContent.PUBLISH}
            isLoading={this.state.workflowPublishInProgress}
          />
        </span>
      </>
    );
  };

  renderWorkflowBuilderBody = () => {
    return (
      <>
        {this.renderLeftDrawer()}
        {this.renderWorkflowCanvas()}
      </>
    );
  };

  renderEdgeConnectionComponent = () => {
    if (!this.state.selectedEdgeId) {
      return null;
    }

    const {selectedEdgeId, edges, stages} = this.state;

    return (
      <EdgeConnectionComponent
        edges={edges}
        stages={stages}
        edgeId={selectedEdgeId}
        onClose={() => this.setState({selectedEdgeId: undefined})}
        onUpdate={(stages, edges) => this.setState({edges, stages})} />
    );
  };

  render() {
    return (
      <>
        <GenericTrowser
          headerLeftButtons={this.renderWorkflowBuilderHeader}
          title={this.renderTitle}
          children={this.renderWorkflowBuilderBody}
          closeButton
          handleClose={this.handleClose}
        />
        {this.renderStageFormBuilderByType()}
        {this.renderEdgeConnectionComponent()}
      </>
    );
  }
}

type PropTypes = {
  history: any;
};

export type WorkflowBuilderStateType = {
  workflowId?: string;
  workflowEditMode: boolean;
  isDraft: boolean;
  workflowDetail: Details;
  form: FormEntity;
  stages: Elements;
  edges: Elements;
  selectedStageId?: string;
  selectedFormBuilderStageId?: string;
  leftDrawerVisible: boolean;
  formBuilderVisible: boolean;
  workflowPublishInProgress: boolean;
  selectedEdgeId?: string;
};

export default WorkflowBuilder;
