import { ColumnHeader } from '@eazy2biz-ui/common-components';

export enum SchedulerHeaders {
  NAME = 'name',
  SCHEDULE_FREQUENCY = 'scheduledTaskFrequency',
  SCHEDULE_TIME_CRON = 'scheduleTimeCron',
  UPDATED_ON = 'updatedOn',
  UPDATED_BY = 'updatedBy',
}

export const getColumnsForScheduler = (): ColumnHeader[] => {
  const columns = [
    {
      Header: 'Schedule Name',
      accessor: SchedulerHeaders.NAME
    },
    {
      Header: 'Time / Duration cron',
      accessor: SchedulerHeaders.SCHEDULE_TIME_CRON
    },
    {
      Header: 'Last Updated On',
      accessor: SchedulerHeaders.UPDATED_ON
    },
    {
      Header: 'Last Updated By',
      accessor: SchedulerHeaders.UPDATED_BY
    }
  ];
  return columns;
};


