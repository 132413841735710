import { Elements, FlowElement } from "react-flow-renderer";
import {StageData} from "../../../../entity/workflowBuilder/StageData";
import {EdgeData} from "../../../../entity/workflowBuilder/EdgeData";
import {findElementById, getEdgesFromSource, getStartStageFromStages} from "../WorkflowBuilderFlowHelper";
import { Stage, StageTypes } from "@eazy2biz/common-util";
import {Edge} from "react-flow-renderer/dist/types";

type DFS_RESPONSE= {
    stages: string[];
    found: boolean;
}

/**
 * Recursive dfs function.
 * @param currentStage
 * @param stages
 * @param edges
 * @param destinationStageId
 * @param visited
 */
const dfsForStage = (
    currentStage: FlowElement<StageData>,
    stages: FlowElement<StageData>[],
    edges: FlowElement<EdgeData>[],
    destinationStageId: string,
    visited: Set<string>
): DFS_RESPONSE => {

    if (currentStage.id === destinationStageId) {
        return {
            stages: [],
            found: true
        }
    }

    if (visited.has(currentStage.id) || currentStage.data?.stageConfiguration.type === StageTypes.EXIT_STAGE) {
        return {
            stages: [],
            found: false
        }
    }

    const outwardEdges: Edge[] = getEdgesFromSource(edges as Edge[], currentStage.id);
    visited.add(currentStage.id);

    // @ts-ignore
    if (!currentStage.data?.details.isLabel && [StageTypes.CONDITIONAL_STAGE, StageTypes.APPROVAL_STAGE].includes(currentStage.data?.stageConfiguration.type)) {
        // BRANCH STAGE
        let nextStage = findElementById(stages, outwardEdges[0].target);
        let response = dfsForStage(nextStage, stages, edges, destinationStageId, visited);

        nextStage = findElementById(stages, outwardEdges[1].target);
        let rightResponse = dfsForStage(nextStage, stages, edges, destinationStageId, visited);

        const found = response.found || rightResponse.found;
        const list = found? [currentStage.id] : [];
        return {
            found,
            stages: [...list, ...response.stages, ...rightResponse.stages]
        };
    }


    const nextStage = findElementById(stages, outwardEdges[0].target);
    const response = dfsForStage(nextStage, stages, edges, destinationStageId, visited);

    if (!currentStage.data?.details.isLabel && response.found) {
        response.stages = [currentStage.id, ...response.stages];
    }

    return response;

};

/**
 * Returns a ordered list of all the stages earlier than the current Stage
 * @param stages
 * @param edges
 * @param currentStageId
 */
export const getOrderedStageListToCurrentStage = (
    stages: FlowElement<StageData>[],
    edges: FlowElement<EdgeData>[],
    currentStageId: string
): string[] => {
    const visited = new Set<string>();
    const startStage = getStartStageFromStages(stages);

    const response = dfsForStage(startStage, stages, edges, currentStageId, visited);

    response.stages.push(currentStageId);

    return response.stages;
};

export const getStageDataFromElements = (stageElements: Elements<StageData>): Stage[] => {
    // @ts-ignore
    return stageElements.map((stageElement) => stageElement.data?.stageConfiguration);
};
