import {PlaceholderOptionsList} from "../PlaceholderOptionsList";
import { Field, FieldPlaceholder as Placeholder, FieldPlaceholderType } from "@eazy2biz/common-util";
import {PLACEHOLDER_OPTIONS} from "../../../../../contents/DisplayContent";
import {PlaceholderOption} from "../../entity/PlaceholderOption";
import { useState } from "react";
import { FieldPlaceholderForType } from "./FieldPlaceholderForType";
import { allowedFieldTypePlaceholder } from "../../helpers/FormFieldPlaceholderHelper";
import { DynamicPlaceholderConfig } from "../../config/DynamicPlaceholderConfig";

interface IFieldPlaceholderProps {
    config: DynamicPlaceholderConfig;
    options: PlaceholderOption[];
    goBack: () => void;
    callback: (placeholderString: Placeholder) => void;
}

export const FieldPlaceholder = (props: IFieldPlaceholderProps): JSX.Element => {
    const { options, callback, goBack } = props;
    const [selectedField, setSelectedField] = useState<Field | null>(null);

    const handleOnPick = (fieldId: string) => {
        const field = props.config.formFields.find(field => field._id === fieldId);

        if (field) {
            if (allowedFieldTypePlaceholder.includes(field.type)) {
                setSelectedField(field);
            } else {
                handleAddPlaceholder(fieldId);
            }
        }
    };

    const handleAddPlaceholder = (key: string, subKey?: string) => {
        const placeholder: Placeholder = new Placeholder();
        placeholder.placeholderType = FieldPlaceholderType.CARD_FORM;
        placeholder.key = key;
        placeholder.entityId = '';
        placeholder.entityClassId = '';
        placeholder.subKey = subKey;
        callback(placeholder);
    };

    const handleFieldTypePlaceholderBack = () => {
        setSelectedField(null);
    };

    if (selectedField) {
        return (<FieldPlaceholderForType config={props.config} field={selectedField} goBack={handleFieldTypePlaceholderBack} onPick={handleAddPlaceholder} />);
    }

    return <PlaceholderOptionsList goBack={goBack} headerTitle={PLACEHOLDER_OPTIONS.FORM} placeholderOptions={options} onPick={handleOnPick} />;
};
