import {
    BaseChartDetails,
    ChartType,
    PieChartDetails,
    ReportEntityType
} from "@eazy2biz/common-util";

import {ConfigureWorkflowPieChartDetails} from "./workflow/ConfigureWorkflowPieChartDetails";
import {useEffect, useState} from "react";
import {Builder} from "builder-pattern";

import styles from './ConfigurePieChartDetails.module.css'

export const ConfigurePieChartDetails = (
    props : {
        entityType: ReportEntityType;
        entityId: string;
        entity: any;
        showChartConfigureFormFieldErrors: boolean;
        handleChartDetailsChange: (newChartDetails : BaseChartDetails<ChartType>) => void;
    }
) : JSX.Element => {

    const {
        entityType,
        entityId,
        entity,
        showChartConfigureFormFieldErrors,
        handleChartDetailsChange,
    } = props;

    const [pieChartDetails , setPieChartDetails] = useState<PieChartDetails>(
        Builder<PieChartDetails>()
            .build()
    )

    useEffect(() => {
        handleChartDetailsChange(pieChartDetails);
    }, [pieChartDetails])

    const getPieChartComponent = () => {
        switch (entityType) {
            case ReportEntityType.WORKFLOW:
                return (
                    <ConfigureWorkflowPieChartDetails
                        entityType={entityType}
                        entityId={entityId}
                        workflowResponse={entity}
                        showChartConfigureFormFieldErrors={showChartConfigureFormFieldErrors}

                        pieChartDetails={pieChartDetails}
                        setPieChartDetails={setPieChartDetails}
                    />
                )
        }
    }

    return (
        <>
            {
                getPieChartComponent()
            }
        </>
    )
}
