import { GenericModal } from "../../genericComponents/GenericModal/GenericModal";
import { ALERT_MODAL } from "../../../contents/DisplayContent";
import GenericButton from "../../genericComponents/GenericButton/GenericButton";
import { GenericButtonTypes } from "../../genericComponents/GenericButton/GenericButtonTypes";
import styles from './AlertModal.module.css';
import alertIcon from '../../../assets/alertIcon.svg';
import { AlertModalTypes } from "./AlertModalTypes";

/**
 * Generic Alert modal
 * @param props
 * @constructor
 */
export const BaseAlertModal = (
    props : {
      type?: AlertModalTypes
      title?: string,
      message?: string,
      showCancelButton?: boolean,
      cancelButtonText?: string,
      onCancel?: () => void,
      confirmButtonText?: string,
      onConfirm?: () => void,
    }
) => {

  const {
    type = AlertModalTypes.ALERT,
    title = ALERT_MODAL.TITLE,
    message,
    showCancelButton,
    cancelButtonText = ALERT_MODAL.CANCEL,
    onCancel,
    confirmButtonText = ALERT_MODAL.CONFIRM,
    onConfirm,
  } = props;

  const renderBodyContent = () => {
    return (
      <div className={styles.bodyWrapper}>
        <img className={styles.img} src={getImage(type)} />
        <div>
          {title}
        </div>
      </div>
    );
  };

  const handleClose = () => {
    onCancel && onCancel();
  };

  const handleConfirm = () => {
    onConfirm && onConfirm();
  };

  const renderFooterContent = () => {
    return (
      <div className={styles.footerWrapper}>
        {showCancelButton &&
          <GenericButton buttonText={cancelButtonText} type={GenericButtonTypes.SECONDARY} onClick={handleClose} />}
        {onConfirm && <GenericButton buttonText={confirmButtonText} type={getButtonType(type)} onClick={handleConfirm} />}
      </div>
    );
  };

  return (
    <GenericModal
      show
      hideHeader
      dialogClassName={styles.alertDialog}
      modalClassName={styles.alertModal}
      backdropClassName={styles.alertDialogBackdrop}
      contentWrapperClassName={getContentStyles(type)}
      onClose={handleClose}
      bodyContent={renderBodyContent}
      footerContent={renderFooterContent}
    />
  );
};

const getButtonType = (type: AlertModalTypes) => {
  switch (type) {
    case AlertModalTypes.SUCCESS:
      return GenericButtonTypes.SUCCESS;
    default:
      return GenericButtonTypes.REJECT;
  }
};

const getContentStyles = (type: AlertModalTypes) => {
  switch (type) {
    case AlertModalTypes.SUCCESS:
      return styles.modalContentWrapperSuccess;
    default:
      return styles.modalContentWrapperReject;
  }
};

const getImage = (type: AlertModalTypes) => {
  switch (type) {
    case AlertModalTypes.SUCCESS:
      return '';
    default:
      return alertIcon;
  }
};
