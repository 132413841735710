import React from "react";
import styles from './genericTrowser.module.css';
import {CloseButton} from "../../../index";

/**
 * This is full screen page.
 * @param props
 * @constructor
 */
export const GenericTrowser = (props: GenericTrowserProps) => {

    const renderHeader = () => {
        if (props.header) {
            return props.header();
        } else {
            return (
                <>
                    <div className={styles.genericTrowserTitle}>
                        {props.title && props.title()}
                    </div>
                    <div className={styles.genericTrowserLeftButtons}>
                        {props.headerLeftButtons && props.headerLeftButtons()}
                        {props.closeButton && <span className={styles.actionButton}><CloseButton onClose={props.handleClose} /></span>}
                    </div>
                </>
            );
        }
    };

    return (
        <div className={styles.genericTrowserWrapper}>
            <div className={styles.genericTrowserHeader}>
                {renderHeader()}
            </div>
            <div className={styles.genericTrowserBody}>
                {props.children()}
            </div>
        </div>
    );
};


type GenericTrowserProps = {
    header?: () => JSX.Element;
    title?: () => JSX.Element;
    headerLeftButtons?: () => JSX.Element;
    children: () => JSX.Element;
    closeButton: boolean;
    handleClose: () => void;
};
