import { PlaceholderOptionsList } from "../PlaceholderOptionsList";
import { Field } from "@eazy2biz/common-util";
import { getPlaceholderOptionsForField } from "../../helpers/FormFieldPlaceholderHelper";
import { useEffect, useState } from "react";
import { PlaceholderOption } from "../../entity/PlaceholderOption";
import { Spinner } from "../../../Spinner/Spinner";
import { DynamicPlaceholderConfig } from "../../config/DynamicPlaceholderConfig";

/**
 * Second level placeholder for field type.
 * @param props
 * @constructor
 */
export const FieldPlaceholderForType = (props: PropTypes) => {

  const [options, setOptions] = useState<PlaceholderOption[] | null>(null);

  useEffect(() => {
    getPlaceholderOptionsForField(props.field, props.config)
      .then(res => setOptions(res));
  }, []);

  const handleOnPick = (subKey: string) => {
    props.onPick(props.field._id, subKey);
  };

  if (!options) {
    return <Spinner />;
  }

  return (
    <PlaceholderOptionsList
        goBack={props.goBack}
        headerTitle={props.field.name}
        placeholderOptions={options}
        onPick={handleOnPick} />
  );
};

type PropTypes = {
  field: Field;
  config: DynamicPlaceholderConfig;
  goBack: () => void;
  onPick: (key: string, subKey: string) => void;
}
