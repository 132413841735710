import { Form } from "antd";
import { AccessTypes, TriggerWorkflowDetails } from "@eazy2biz/common-util";
import { useEffect, useState } from "react";
import { cloneDeep, get, set } from "lodash";
import {
    CardFormFieldSelect,
    DropdownSelect,
    MenuData,
    Spinner,
    TextVariableInput,
    Toast,
    VariableField
} from "@eazy2biz-ui/common-components";
import { ActionComponentProps } from "../interfaces/ActionComponentProps";
import styles from "../actionForm.module.css";
import { CachedStore, StoreEntityTypes } from "@eazy2biz/common-package-ui";
import { getStageForm } from "../../../../../../services/WorkflowService";

const validateMessages = {
    required: '${label} is required!'
};

/**
 * Trigger Workflow Action Component.
 * @param props
 * @constructor
 */
export const TriggerWorkflowActionComponent = (props: ActionComponentProps) => {

    const triggerWorkflowDetail = props.actionResult.resultDetails as TriggerWorkflowDetails;

    const convertVariableFieldsToObject = (values: VariableField[]): object => {
        const variablesObject = {};
        values.map((variablesField) => {
            set(variablesObject, variablesField.id, variablesField.value);
        });

        return variablesObject;
    };

    const workflows = CachedStore.getCachedStoreList(StoreEntityTypes.WORKFLOWS) || [];
    const [loading, setLoading] = useState(false);

    const [selectedWorkflowId, setSelectedWorkflowId] = useState<string>(triggerWorkflowDetail.workflowId || '');
    const [formValues, setFormValues] = useState<VariableField[]>([]);

    useEffect(() => {
        handleWorkflowChange(triggerWorkflowDetail.workflowId || '');
    }, []);

    const handleWorkflowChange = (id: string) => {
        setSelectedWorkflowId(id);
        setFormValues([]);
        id && id !== '' && getStageForm(id)
        .then((form) => {
            form && setFormValues(
              form[AccessTypes.RW].map((field) => ({
                  id: field._id,
                  name: field.name,
                  value: get(triggerWorkflowDetail.formValues, field._id, ''),
              }))
            );
        })
        .catch(() => Toast.errorString())
        .finally(() => setLoading(false));
    };

    const handleFormValuesUpdate = (variableFields: VariableField[]) => {
        setFormValues(variableFields);
        const updatedTriggerWorkflowDetail: TriggerWorkflowDetails = {
            workflowId: selectedWorkflowId,
            formValues: convertVariableFieldsToObject(variableFields),
        };
        const updatedAction = cloneDeep(props.actionResult);
        updatedAction.resultDetails = updatedTriggerWorkflowDetail;
        props.onActionResultUpdate(updatedAction);
    };

    const handleSave = (e: any) => {
        e?.stopPropagation();
        const updatedTriggerWorkflowDetail: TriggerWorkflowDetails = {
            workflowId: selectedWorkflowId,
            formValues: convertVariableFieldsToObject(formValues),
        };
        const updatedAction = cloneDeep(props.actionResult);
        updatedAction.resultDetails = updatedTriggerWorkflowDetail;
        props.onActionResultUpdate(updatedAction);
    };

    const getTemplateMenuData = (): MenuData[] => {
        return workflows.map((workflow) => ({
            _id: workflow._id,
            name: workflow.details.name
        }));
    };

    const renderVariableFieldDropdown = (variableField: VariableField, onChange: (e: any) => void) => {
        return (
          <CardFormFieldSelect
            key={variableField.id}
            label={variableField.name}
            fields={props.fields}
            initialValue={variableField.value}
            onChange={onChange}
          />
        );
    };

    return(
        <>
            <Form validateMessages={validateMessages}>
                <Form.Item name={['workflowId']} rules={[{ required: true } ]} className={styles.actionDetailsFormItem}>
                    <DropdownSelect
                        label={'Workflow:'}
                        menuData={getTemplateMenuData()}
                        selectedItem={selectedWorkflowId || ''}
                        onItemSelect={handleWorkflowChange}
                        onBlur={handleSave}
                    />
                </Form.Item>
                {
                    loading ? <Spinner /> :
                    <Form.Item name={['formValues']} className={styles.actionDetailsFormItem}>
                        <TextVariableInput
                            key={'WORKFLOW_ACTION'}
                            variableFields={formValues}
                            setVariableFields={handleFormValuesUpdate}
                            onItemBlur={handleSave}
                            renderInputField={renderVariableFieldDropdown}
                        />
                    </Form.Item>
                }
            </Form>
        </>
    );
};
