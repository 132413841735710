export const HELLO_WORLD = "Hello World";

/*
* Invites Page Display Content
* */
export const INVITES_HEADER = "Invites";

/*
* All Users Page Display Content
* */
export const INVITE_USER_BUTTON_TEXT = "Invite User";
export const ALL_USERS_HEADER = "All Users";
export const SEND_INVITE_BUTTON = "Send Invite";
export const INVITE_USER_MODAL_TITLE = "Invite User";
export const INVITES_BUTTON = "Invites";
export const DEPARTMENTS_BUTTON = "Departments";
export const USER_GROUPS_BUTTON = "User Groups";

/*
* User Groups Page Display Content
* */
export const DEPARTMENTS_HEADER = "Departments";
export const USER_GROUPS_HEADER = "User Groups";
export const CREATE_DEPARTMENT_BUTTON_TEXT = "Create Department";
export const CREATE_USER_GROUP_BUTTON_TEXT = "Create User Group";
export const SUBMIT_BUTTON_TEXT = "Submit";
export const MANAGE_MEMBERSHIP_TITLE = "Manage Membership";
export const ADD_USER_BUTTON_TEXT = "Add User";
export const CREATE_DEPARTMENT = 'Create Department';
export const CREATE_USER_GROUP = 'Create User Group';

/*
* Miscellaneous
* */
export const ADD_BUTTON_TEXT = "Add";
export const CANCEL_BUTTON_TEXT = "Cancel";

/*
* Toast Message String
* */
export const SUCCESS_STRINGS = {
    USER_INVITE_SUCCESS: "User invited successfully",
    USER_GROUP_CREATE_SUCCESS: "New user group created",
    ADD_NEW_MEMBER_SUCCESS: "New member added successfully",
    DELETE_MEMBER_SUCCESS: "Removed member"
}

export const ERROR_STRINGS = {
    USER_INVITE_FAILURE: "Couldn't invite user",
    USER_GROUP_CREATE_FAILURE: "Failed to create new user group",
    ADD_NEW_MEMBER_FAILURE: "Failed to add member!",
    DELETE_MEMBER_FAILURE: "Failed to remove member"
}

export const INVITE_USER = {
    EMAIL_ADDRESS : "Email Address",
    NAME:"Name",
    GROUPS_TO_INVITE : "Select User Groups to Invite To"
}