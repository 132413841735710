import GenericButton from "../../genericComponents/GenericButton/GenericButton";
import { GenericButtonTypes } from "../../genericComponents/GenericButton/GenericButtonTypes";
import { SCHEDULER_STRINGS } from "../../../contents/DisplayContent";
import styles from './GenericTriggersComponent.module.css';
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, GenericTable } from "../../genericComponents/table/Table";
import { ColumnHeader } from "../../genericComponents/table/interfaces/ColumnHeader";
import { getColumnsForScheduler } from "./configs/SchedulerTableConfig";
import {
  EntityDefinitionTypes,
  SchedulableTriggerCategory,
  SchedulableTriggerResponse,
  ScheduledTaskType
} from "@eazy2biz/common-util";
import { GenericScheduledTriggerModal } from "./GenericScheduledTriggerModal/GenericScheduledTriggerModal";
import { ReactElement, useEffect, useState } from "react";
import {
  deleteScheduledTrigger,
  getAllScheduledTriggersForTypeAndCategoryAndDefinition
} from "../../services/SceduledTriggerService";
import Toast from "../../genericComponents/Toast/Toast";
import { getTableDataForScheduledTriggersList } from "./helpers/ScheduledTriggerListHelper";
import { RowData } from "../../genericComponents/table/interfaces/TableRow";
import { getArrayPage } from "../../genericComponents/table/helpers/GenericTableHelper";
import { useAlertModal } from "../AlertModal/AlertModalProvider";

/**
 * Generic Component for triggers.
 * @param props
 * @constructor
 */
export const GenericTriggersComponent = (props: PropTypes) => {

  const {
    schedulableTriggerCategory,
    schedulableTriggerType,
    scheduledTaskType
  } = props;

  const {setAlertModalConfig} = useAlertModal();
  const columns: ColumnHeader[] = getColumnsForScheduler();
  const [scheduledTriggers, setScheduledTriggers] = useState<SchedulableTriggerResponse[]>([]);
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [showScheduledTriggerModal, setShowScheduledTriggerModal] = useState(false);
  const [selectedTriggerId, setSelectedTriggerId] = useState<string | undefined>();

  useEffect(() => {
    fetchScheduledTriggers();
  }, []);

  const fetchScheduledTriggers = () => {
    getAllScheduledTriggersForTypeAndCategoryAndDefinition(
      schedulableTriggerCategory,
      schedulableTriggerType,
      scheduledTaskType,
      props.referenceId
    )
      .then((response) => {
        setScheduledTriggers(response);
        updateData(response);
      })
      .catch(() => Toast.errorString());
  };

  const handleOnCreateTrigger = () => {
    setShowScheduledTriggerModal(true);
  };

  const handleSelectedTrigger = (id?: string) => {
    setShowScheduledTriggerModal(!!id);
    setSelectedTriggerId(id);
  };

  const handlePageChange = (page?: number, pageSize?: number) => {
    updateData(scheduledTriggers, page, pageSize);
  };

  const updateData = (triggerList: SchedulableTriggerResponse[], page: number = DEFAULT_PAGE_NUMBER, pageSize: number = DEFAULT_PAGE_SIZE) => {
    setTableData(getTableDataForScheduledTriggersList(getArrayPage(triggerList, pageSize, page)));
  };

  const handleCloseModal = () => {
    setShowScheduledTriggerModal(false);
    setSelectedTriggerId(undefined);
  };

  const handleDeleteTrigger = (triggerId: string) => {
    Toast.load(SCHEDULER_STRINGS.DELETE_TRIGGER_PROGRESS);
    deleteScheduledTrigger(triggerId)
      .then(() => {
        fetchScheduledTriggers();
        Toast.success(SCHEDULER_STRINGS.DELETE_TRIGGER_SUCCESS);
      })
      .catch((e) => Toast.error(e, SCHEDULER_STRINGS.DELETE_TRIGGER_ERROR));
  };

  const handleDeleteRowButtonClick = (triggerId: string) => {
    setAlertModalConfig({
      showCancelButton: true,
      onConfirm: () => handleDeleteTrigger(triggerId),
    });
  };

  const renderSchedulerTable = () => {
    return (
      <GenericTable
        columns={columns}
        data={tableData}
        defaultActionEnabled
        paginationEnabled
        totalItems={scheduledTriggers.length}
        onPageChange={handlePageChange}
        onDefaultActionClick={handleSelectedTrigger}
        onRowDeleteClick={handleDeleteRowButtonClick}
      />
    );
  };

  const renderScheduledTriggerModal = () => {
    const selectedTrigger = scheduledTriggers.find((trigger) => trigger._id === selectedTriggerId);
    return (
      <GenericScheduledTriggerModal
        openModal={showScheduledTriggerModal}
        onClose={handleCloseModal}
        scheduledTrigger={selectedTrigger}
        onSubmit={fetchScheduledTriggers}
        triggerCategory={props.schedulableTriggerCategory}
        renderScheduleTaskDetails={props.renderScheduleTaskDetails}
        name={props.name}
        referenceId={props.referenceId}
      />
    );
  }

  return (
    <div>
      <div className={styles.headerRow}>
        <GenericButton
          buttonText={SCHEDULER_STRINGS.CREATE}
          onClick={handleOnCreateTrigger}
          type={GenericButtonTypes.SECONDARY} />
      </div>
      {renderSchedulerTable()}
      {renderScheduledTriggerModal()}
    </div>
  );
};

type PropTypes = {
  schedulableTriggerCategory: SchedulableTriggerCategory;
  schedulableTriggerType?: EntityDefinitionTypes;
  scheduledTaskType?: ScheduledTaskType;
  renderScheduleTaskDetails: (taskDetails: any, setTaskDetails: (taskDetails: any) => void, readOnly: boolean) => ReactElement | null;
  name: string;
  referenceId: string;
};
