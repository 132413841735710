import { TemplateResponse } from "@eazy2biz/common-util";
import { StrictBuilder } from "builder-pattern";
import { get } from "lodash";

export type PdfTemplateConfigurationType = {
  name: string;
  content: string;
};

export const getDefaultPdfTemplateConfiguration = (pdfTemplate?: TemplateResponse): PdfTemplateConfigurationType => {
  return StrictBuilder<PdfTemplateConfigurationType>()
    .name(pdfTemplate?.details.name || '')
    .content(get(pdfTemplate, 'templateDetails.content.text', ''))
    .build();
};
