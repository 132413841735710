import {
  ITEM_DETAILS_STRINGS, ITEMS_STRINGS,
  STAGE_FORM_ERROR_STRING,
  WORKFLOW_BUILDER_SUCCESS_STRINGS,
  workflowContent
} from "../../../contents/DisplayContent";
import { GenericCardForm, ModalSubHeaderComponent, Toast } from '@eazy2biz-ui/common-components';
import {createCardForWorkflow} from "../../services/CardService";
import {getStageForm} from "../../services/WorkflowService";
import {useState, useEffect} from "react";
import {Stage, StageFormEntity, WorkflowResponse} from "@eazy2biz/common-util";
import { CachedStore, StoreEntityTypes, getStartStageFromArray, FormFieldInput } from "@eazy2biz/common-package-ui";

interface ICreateItemModal {
    openModal: boolean;
    setOpenModal: (state: boolean) => void;
    workflowId: string;
    onSubmit?: () => void;
}

export const CreateItemModal = (props: ICreateItemModal) => {
    const { openModal, setOpenModal, workflowId } = props;

    if (!openModal) {
        return null;
    }

    const [stageForm, setStageForm] = useState<StageFormEntity>();
    const [workflowName, setWorkflowName] = useState('');
    const [stageArray, setStageArray] = useState<Stage[]>([]);
    const [createItemProgress, setCreateItemProgress] = useState<boolean>(false);

    useEffect(() => {
        Toast.load(ITEMS_STRINGS.FETCHING_CARD_FORM);
        CachedStore.getEntityFromListById<WorkflowResponse>(StoreEntityTypes.WORKFLOWS, workflowId, true).then(
            (response: WorkflowResponse) => {
                setStageArray((response && response.stages) || []);
                setWorkflowName(response.details.name);
            }
        ).catch(() => {
            handleError();
        });

        getStageForm(workflowId)
            .then((res : StageFormEntity) => {
                setStageForm(res);
                Toast.destroyMessage();
            })
            .catch(() => {
                handleError();
            });
    }, []);

    const handleError = (messageString?: string) => {
        setOpenModal(false);
        Toast.errorString(messageString);
    };

    const handleCreateItem = (fieldsMap: FormFieldInput[]) => {
        Toast.load(ITEMS_STRINGS.CREATE_ITEM_PROGRESS);
        setCreateItemProgress(true);
        createCardForWorkflow(fieldsMap, [], workflowId)
            .then(() => {
                setOpenModal(false)
                Toast.success(WORKFLOW_BUILDER_SUCCESS_STRINGS.CREATE_ITEM_SUCCESS);
                props.onSubmit && props.onSubmit();
                setCreateItemProgress(false);
            })
            .catch(
                (err) => {
                    Toast.error(err, STAGE_FORM_ERROR_STRING.CREATE_ITEM_ERROR);
                    setCreateItemProgress(false);
                });
    };


    const renderModalSubTitle = () => {
        return (
            <ModalSubHeaderComponent
                title={ITEM_DETAILS_STRINGS.MY_ITEMS}
                name={workflowName}
            />
        );
    };

    return (
        <GenericCardForm
            show={openModal}
            onClose={() => {
                setOpenModal(false);
            }}
            isSubmitting={createItemProgress}
            stageForm={stageForm}
            stageData={getStartStageFromArray(stageArray)}
            modalSubTitle={renderModalSubTitle()}
            modalTitle={workflowContent.ITEM_MODAL_TITLE}
            submitButtonText={workflowContent.ITEM_SUBMIT_BUTTON}
            onSubmit={handleCreateItem}
        />
    );
};

export default CreateItemModal;
