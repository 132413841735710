import {
    AggregatorType, AreaChartDetails,
    ChartGrouping,
    createdDatePath,
    DateFieldAggregationType,
    FieldTypes,
    lastUpdatedDatePath,
    ReportEntityType,
    WorkflowResponse
} from "@eazy2biz/common-util";

import {DropdownSelect, InputField, ToggleButton} from "@eazy2biz-ui/common-components";

import styles from './ConfigureWorkflowAreaChartDetails.module.css'
import React from "react";
import {
    displayNameForAggregatorType,
    displayNameForDateFieldAggregationType
} from "../../../../../../../../contents/DisplayContent";
import {Builder} from "builder-pattern";
import {cloneDeep} from "lodash";

export const ConfigureWorkflowAreaChartDetails = (
    props : {
        entityType: ReportEntityType;
        entityId: string;
        workflowResponse: WorkflowResponse;
        showChartConfigureFormFieldErrors: boolean;

        areaChartDetails : AreaChartDetails,
        setAreaChartDetails : (areaChartDetails : AreaChartDetails) => void;

        subGroupingEnabled : boolean
        setSubGroupingEnabled : (subGroupingEnabled: boolean) => void
    }
) : JSX.Element => {

    const {
        entityType,
        entityId,
        workflowResponse,
        showChartConfigureFormFieldErrors,
        areaChartDetails,
        setAreaChartDetails,
        subGroupingEnabled,
        setSubGroupingEnabled
    } = props;

    const checkIfDateAggregationRequired = (fieldId : string | undefined) => {
        return fieldId
            && (
                fieldId === createdDatePath
                || fieldId === lastUpdatedDatePath
                || workflowResponse.fields.find(
                    workflowField =>
                        fieldId === workflowField._id
                        && workflowField.type === FieldTypes.DATE
                )
            );
    }

    return (
        <>
            <div className={styles.xAxisConfigurationBox}>
                <div className={styles.configureXAxisText}> Configure X Axis </div>

                <div className={styles.xAxisConfiguration}>
                    <div className={styles.configureXAxisLabel}>
                        <InputField
                            id="xAxisFieldLabel"
                            label="Label"
                            highlightFieldError={showChartConfigureFormFieldErrors}
                            isInValid={!areaChartDetails.groupingLabel}
                            initialValue={areaChartDetails.groupingLabel || ''}
                            type="text"
                            onChange={(e) => {
                                areaChartDetails.groupingLabel = e.target.value;
                                setAreaChartDetails(cloneDeep(areaChartDetails));
                            }}
                            required={true}
                        />
                    </div>

                    <div className={styles.configureXAxisFieldAndAggregator}>
                        <div className={styles.configureXAxisField}>
                            <DropdownSelect
                                placeholder={'Workflow Field'}
                                label={'Workflow Field'}
                                selectedItem={areaChartDetails.groupingField}
                                onItemSelect={
                                    (fieldId) => {
                                        areaChartDetails.groupingField = fieldId;
                                        setAreaChartDetails(cloneDeep(areaChartDetails));
                                    }
                                }
                                loading={workflowResponse?.fields === null}
                                isErrored={
                                    showChartConfigureFormFieldErrors &&
                                    (
                                        areaChartDetails.groupingField === null
                                        || areaChartDetails.groupingField === undefined
                                    )
                                }
                                menuData={
                                    [
                                        {
                                            _id: createdDatePath,
                                            name: 'Created Date'
                                        },
                                        {
                                            _id: lastUpdatedDatePath,
                                            name: 'Last Modified Date'
                                        },
                                        ...workflowResponse.fields?.filter(
                                            field => field.type === FieldTypes.DATE
                                        ).map(
                                            (field) => ({
                                                _id: `fieldsMap.${field._id}`,
                                                name: field.name
                                            })
                                        )
                                    ]
                                }
                            />
                        </div>
                        <div className={styles.configureXAxisAggregator}>
                            <DropdownSelect
                                placeholder={'Combine data for every'}
                                label={'Combine data for every'}
                                selectedItem={areaChartDetails.dateGroupingFieldAggregationType}
                                onItemSelect={
                                    (dateGroupingFieldAggregationType) => {
                                        areaChartDetails.dateGroupingFieldAggregationType =
                                            dateGroupingFieldAggregationType as DateFieldAggregationType;
                                        setAreaChartDetails(cloneDeep(areaChartDetails));
                                    }
                                }
                                isErrored={
                                    showChartConfigureFormFieldErrors &&
                                    (
                                        areaChartDetails.dateGroupingFieldAggregationType === null
                                        || areaChartDetails.dateGroupingFieldAggregationType === undefined
                                    )
                                }
                                menuData={
                                    Object.keys(DateFieldAggregationType)
                                        .map(
                                            (dateAggregationTypeString : string) => DateFieldAggregationType[dateAggregationTypeString as keyof typeof DateFieldAggregationType]
                                        )
                                        .map(
                                            (dateFieldAggregationType : DateFieldAggregationType) => ({
                                                _id: dateFieldAggregationType,
                                                name: displayNameForDateFieldAggregationType[dateFieldAggregationType]
                                            })
                                        )
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.yAxisConfigurationBox}>
                <div className={styles.configureYAxisText}> Configure Y Axis </div>

                <div className={styles.yAxisConfiguration}>
                    <div className={styles.configureYAxisLabel}>
                        <InputField
                            id="yAxisFieldLabel"
                            label="Label"
                            highlightFieldError={showChartConfigureFormFieldErrors}
                            isInValid={!areaChartDetails.aggregationLabel}
                            initialValue={areaChartDetails.aggregationLabel || ''}
                            type="text"
                            onChange={(e) => {
                                areaChartDetails.aggregationLabel = e.target.value;
                                setAreaChartDetails(cloneDeep(areaChartDetails));
                            }}
                            required={true}
                        />
                    </div>

                    <div className={styles.configureYAxisFieldAndAggregator}>
                        <div className={styles.configureYAxisField}>
                            <DropdownSelect
                                placeholder={'Workflow Field'}
                                label={'Workflow Field'}
                                selectedItem={areaChartDetails.aggregationField}
                                onItemSelect={
                                    (fieldId) => {
                                        areaChartDetails.aggregationField = fieldId;
                                        setAreaChartDetails(cloneDeep(areaChartDetails));
                                    }
                                }
                                loading={workflowResponse?.fields === null}
                                isErrored={
                                    showChartConfigureFormFieldErrors &&
                                    (
                                        areaChartDetails.aggregationField === null
                                        || areaChartDetails.aggregationField === undefined
                                    )
                                }
                                menuData={
                                    workflowResponse.fields?.filter(
                                        field => field.type === FieldTypes.NUMBER
                                    ).map(
                                        (field) => ({
                                            _id: `fieldsMap.${field._id}`,
                                            name: field.name
                                        })
                                    )
                                }
                            />
                        </div>
                        <div className={styles.configureYAxisAggregator}>
                            <DropdownSelect
                                placeholder={'Aggregator'}
                                label={'Aggregator'}
                                selectedItem={areaChartDetails.aggregator}
                                onItemSelect={
                                    (aggregator) => {
                                        areaChartDetails.aggregator = aggregator as AggregatorType;
                                        setAreaChartDetails(cloneDeep(areaChartDetails));
                                    }
                                }
                                isErrored={
                                    showChartConfigureFormFieldErrors &&
                                    (
                                        areaChartDetails.aggregator === null
                                        || areaChartDetails.aggregator === undefined
                                    )
                                }
                                menuData={
                                    Object.keys(AggregatorType)
                                        .map(
                                            (aggregatorTypeString : string) => AggregatorType[aggregatorTypeString as keyof typeof AggregatorType]
                                        )
                                        .map(
                                            (aggregatorType : AggregatorType) => ({
                                                _id: aggregatorType,
                                                name: displayNameForAggregatorType[aggregatorType]
                                            })
                                        )
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.subGroupingConfigurationBox}>
                <div className={styles.subGroupingConfigurationBoxHeader}>
                    {
                        subGroupingEnabled && (
                            <div className={styles.configureSubGroupingText}> Configure Grouping </div>
                        )
                    }

                    <ToggleButton
                        label="Add Grouping"
                        value={subGroupingEnabled}
                        wrapperClassName={styles.subGroupingToggleWrapper}
                        className={styles.subGroupingToggle}
                        onToggle={() => {
                            const newSubGroupingEnabled = !subGroupingEnabled;
                            setSubGroupingEnabled(newSubGroupingEnabled)

                            if(!newSubGroupingEnabled) {
                                delete areaChartDetails.subGrouping;
                                setAreaChartDetails(cloneDeep(areaChartDetails))
                            }
                        }}
                    />
                </div>

                {
                    subGroupingEnabled && (
                        <div className={styles.subGroupingConfiguration}>
                            <div className={styles.configureSubGroupingLabel}>
                                <InputField
                                    id="subGroupingFieldLabel"
                                    label="Label"
                                    highlightFieldError={showChartConfigureFormFieldErrors}
                                    isInValid={!areaChartDetails.subGrouping?.label}
                                    initialValue={areaChartDetails.subGrouping?.label || ''}
                                    type="text"
                                    onChange={(e) => {
                                        const subGrouping : ChartGrouping =
                                            areaChartDetails.subGrouping || Builder<ChartGrouping>().build();
                                        subGrouping.label = e.target.value;
                                        areaChartDetails.subGrouping = subGrouping;
                                        setAreaChartDetails(cloneDeep(areaChartDetails));
                                    }}
                                    required={true}
                                />
                            </div>

                            <div className={styles.configureSubGroupingFieldAndAggregator}>
                                <div
                                    className={styles.configureSubGroupingField}
                                    style={{width: checkIfDateAggregationRequired(areaChartDetails.subGrouping?.field) ? '50%' : '100%'}}
                                >
                                    <DropdownSelect
                                        placeholder={'Workflow field'}
                                        label={'Workflow field'}
                                        selectedItem={areaChartDetails.subGrouping?.field}
                                        onItemSelect={
                                            (fieldId) => {
                                                const subGrouping : ChartGrouping =
                                                    areaChartDetails.subGrouping || Builder<ChartGrouping>().build();

                                                subGrouping.field = fieldId;
                                                areaChartDetails.subGrouping = subGrouping;
                                                setAreaChartDetails(cloneDeep(areaChartDetails));
                                            }
                                        }
                                        loading={workflowResponse?.fields === null}
                                        isErrored={
                                            showChartConfigureFormFieldErrors &&
                                            (
                                                areaChartDetails.subGrouping?.field === null
                                                || areaChartDetails.subGrouping?.field === undefined
                                            )
                                        }
                                        menuData={
                                            [
                                                {
                                                    _id: createdDatePath,
                                                    name: 'Created Date'
                                                },
                                                {
                                                    _id: lastUpdatedDatePath,
                                                    name: 'Last Modified Date'
                                                },
                                                ...workflowResponse.fields?.map(
                                                    (field) => ({
                                                        _id: `fieldsMap.${field._id}`,
                                                        name: field.name
                                                    })
                                                )
                                            ]
                                        }
                                    />
                                </div>

                                {
                                    checkIfDateAggregationRequired(areaChartDetails.subGrouping?.field) && (
                                        <div className={styles.configureSubGroupingAggregator}>
                                            <DropdownSelect
                                                placeholder={'Combine by'}
                                                label={'Combine by'}
                                                selectedItem={areaChartDetails.subGrouping?.dateFieldAggregationType}
                                                onItemSelect={
                                                    (dateFieldAggregationType) => {
                                                        const subGrouping : ChartGrouping =
                                                            areaChartDetails.subGrouping || Builder<ChartGrouping>().build();
                                                        subGrouping.dateFieldAggregationType =
                                                            dateFieldAggregationType as DateFieldAggregationType;
                                                        areaChartDetails.subGrouping = subGrouping;
                                                        setAreaChartDetails(cloneDeep(areaChartDetails));
                                                    }
                                                }
                                                isErrored={
                                                    showChartConfigureFormFieldErrors &&
                                                    (
                                                        areaChartDetails.subGrouping?.dateFieldAggregationType === null
                                                        || areaChartDetails.subGrouping?.dateFieldAggregationType === undefined
                                                    )
                                                }
                                                menuData={
                                                    Object.keys(DateFieldAggregationType)
                                                        .map(
                                                            (dateAggregationTypeString : string) => DateFieldAggregationType[dateAggregationTypeString as keyof typeof DateFieldAggregationType]
                                                        )
                                                        .map(
                                                            (dateFieldAggregationType : DateFieldAggregationType) => ({
                                                                _id: dateFieldAggregationType,
                                                                name: displayNameForDateFieldAggregationType[dateFieldAggregationType]
                                                            })
                                                        )
                                                }
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
}
