import React, { ReactElement } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { Field, FieldTypes } from "@eazy2biz/common-util";
import styles from "./CardFormFieldComponent.module.css";
import dragIcon from "../../../assets/Drag.svg";
import { getFieldIconFromPaletteConfig } from "../../palette/PaletteConfig";
import classNames from "classnames";
import { getHTMLInputTypeFromFieldType } from "@eazy2biz-ui/common-package";
import { CardFormDropdownComponent } from "./components/CardFormDropdownComponent/CardFormDropdownComponent";
import { CardFromUploadComponent } from "./components/CardFromUploadComponent/CardFromUploadComponent";
import { CardFormTableRecordComponent } from "./components/CardFormTableRecordComponent/CardFormTableRecordComponent";
import { CardFormDateComponent } from "./components/CardFormDateComponent/CardFormDateComponent";
import { CardFormEmployeeComponent } from "./components/CardFormEmployeeComponent/CardFormEmployeeComponent";
import { CardFormTableInputField } from "./components/CardFormTableInputField/CardFormTableInputField";

/**
 * Generic CardFormField
 * @param props
 * @constructor
 */
export const CardFormFieldComponent = (props: PropTypes): ReactElement => {
  const {
    field,
    onFieldSelect,
    onFieldDelete,
    fieldSelectable,
    isSelected = false,
    isReadOnly = false,
    isRequired = false,
    fieldDeletable = false,
    defaultValue = '',
    isInValid = false,
    onChange,
    inValidText = '',
    validateValue,
    disabled = false
  } = props;

  const handleChange = (event: any, fieldType?: FieldTypes) => {
    let value = event.target.value;
    // Parsing number value
    if (fieldType === FieldTypes.NUMBER) {
      value = Number.parseInt(value);
    }
    onChange && onChange(field._id, value);
  };

  const handleOnBlur = () => {
    validateValue && validateValue(field);
  };

  const handleFieldSelect = (e: any) => {
    e.stopPropagation();
    fieldSelectable && onFieldSelect && onFieldSelect(field._id);
  };

  const handleFieldDelete = (e: any) => {
    e.stopPropagation();
    onFieldDelete && onFieldDelete(field._id);
  };

  const renderDeleteIcon = () => {
    if (isSelected && fieldDeletable) {
      return (
        <AiOutlineDelete className={styles.cardFormFieldDeleteIcon} onClick={handleFieldDelete} />
      );
    }
  };

  const renderInputByType = () => {
    const inputClassNames = classNames(styles.inputClassName, {
      [styles.inputInvalid]: isInValid,
      'is-invalid': isInValid,
      [styles.cardFormFieldDisabled]: disabled,
      [styles.cardFormInputTextArea]: [FieldTypes.LONG_TEXT, FieldTypes.TABLE].includes(field.type)
    });

    const inputProps = {
      key: field._id,
      className: inputClassNames,
      readOnly: isReadOnly,
      placeholder: field.helpText,
      defaultValue,
      required: isRequired,
      onChange: (e: any) => {
        handleChange(e, field.type);
      },
      onBlur: handleOnBlur,
      type: getHTMLInputTypeFromFieldType(field.type),
      disabled
    };

    switch (field.type) {
      case FieldTypes.DATE:
        return <CardFormDateComponent {...inputProps} />;
      case FieldTypes.LONG_TEXT:
        return <textarea {...inputProps} />;
      case FieldTypes.DROPDOWN:
        return <CardFormDropdownComponent {...inputProps} field={field} />;
      case FieldTypes.EMPLOYEE:
        return <CardFormEmployeeComponent {...inputProps} field={field} />;
      case FieldTypes.FILE:
        return <CardFromUploadComponent {...inputProps} />;
      case FieldTypes.DATABASE:
        return <CardFormTableRecordComponent {...inputProps} field={field}/>;
      case FieldTypes.TABLE:
        return <CardFormTableInputField {...inputProps} field={field}/>
      default:
        return <input {...inputProps} />;
    }
  };

  const renderInput = () => {
    return (
      <>
        {renderInputByType()}
        <div className="invalid-feedback">{inValidText}</div>
      </>
    );
  };

  const cardFieldWrapperClassNames = classNames(styles.cardFormFieldWrapper, {
    [styles.cardFormLargeFieldWrapper]: [FieldTypes.LONG_TEXT, FieldTypes.TABLE].includes(field.type),
    [styles.cardFormFieldSelected]: isSelected,
    [styles.cardFormField]: !isSelected
  });
  const inputWrapperClassNames = classNames(styles.cardFormField, 'has-validation');

  return (
    <div key={field._id} className={cardFieldWrapperClassNames} onClick={handleFieldSelect}>
      <div className={inputWrapperClassNames}>
        <div className={!isRequired ? styles.cardFormFieldHeader : styles.requiredCardFormField}>
          <div className={styles.cardFormFieldLabelIcon}>
            <img
              src={!props.isSelected ? getFieldIconFromPaletteConfig(field.type) : dragIcon} />
            <label>{field.name}</label>
          </div>
          {renderDeleteIcon()}
        </div>
        {renderInput()}
      </div>
    </div>
  );
};

type PropTypes = {
  fieldSelectable?: boolean;
  isSelected?: boolean;
  onFieldSelect?: (fieldId: string) => void;
  fieldDeletable?: boolean;
  onFieldDelete?: (fieldId: string) => void;
  isReadOnly?: boolean;
  isRequired?: boolean;
  field: Field;
  defaultValue?: string;
  isInValid?: boolean;
  onChange?: (fieldId: string, value: string) => void;
  validateValue?: (field: Field) => void;
  inValidText?: string;
  disabled?: boolean;
};
