import React, { useEffect, useState } from "react";
import { getStageForm } from "../../services/WorkflowService";
import {
  GenericButton,
  GenericButtonTypes,
  GenericDescriptionText,
  GenericModal,
  GenericTag,
  GenericTagTypes,
  ItemDataComponent,
  ModalSubHeaderComponent,
  Spinner,
  StageFormComponent,
  Toast,
  ToastLength,
  useAlertModal
} from "@eazy2biz-ui/common-components";
import { completeCardStageForWorkflow, discardCardById, updateCardStageForWorkflow } from "../../services/CardService";
import {
  ApprovalValues,
  CardResponse,
  CardStatus,
  SecurityGroupFeatures,
  Stage,
  StageFormEntity,
  WorkflowResponse
} from "@eazy2biz/common-util";
import { ITEM_DETAILS_STRINGS, TAG_STAGE, WORKFLOW_HOMEPAGE } from "../../../contents/DisplayContent";
import { isItemCompleteAllowed, isSubmitButtonVisible } from "../../helpers/CardDetailsHelper";
import styles from "./CardDetailsComponent.module.css";
import { Pencil1Icon, Share1Icon, TrashIcon } from "@radix-ui/react-icons";
import QuickActionButton from "./quickAction/QuickActionButton";
import { QuickActionButtonTypes } from "./quickAction/QuickActionButtonTypes";
import {
  CachedStore,
  getStageFromStageArray,
  StoreEntityTypes,
  UserAuthorizationService,
  WorkflowActions
} from "@eazy2biz/common-package-ui";
import { ItemActivityDetailsComponent } from "./itemActivityDetailsComponent/ItemActivityDetailsComponent";


const CardDetails = (props: PropType) => {
  const { cardData: item, cardModalShow, workflow } = props;

  if (!cardModalShow || !item) {
    return null;
  }

  const {setAlertModalConfig} = useAlertModal();

  const [cardData, setCardData] = React.useState<CardResponse>(item);
  const [currentStage, setCurrentStage] = React.useState<Stage>();
  const [stageFieldMap, setStageFieldMap] = React.useState(new Array());
  const [stageEditFieldsMap, setStageEditFieldsMap] = React.useState(new Array());
  const [stageForm, setStageForm] = React.useState<StageFormEntity>();
  const [hasPermissionToOverrideStage, setHasPermissionToOverrideStage] = React.useState<boolean | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (!workflow) {
      CachedStore.getEntityFromListById<WorkflowResponse>(StoreEntityTypes.WORKFLOWS, cardData.workflowId)
        .then((response: WorkflowResponse) => {
          setDataFromWorkflow(response);
        })
        .catch(() => Toast.errorString(WORKFLOW_HOMEPAGE.ERROR_FETCH_WORKFLOW));
    } else {
      // @ts-ignore
      setDataFromWorkflow(workflow);
    }

    getStageForm(cardData.workflowId, cardData.stageId)
      .then((response: StageFormEntity) => {
        setStageForm(response || {});
      })
      .catch(() => Toast.errorString(ITEM_DETAILS_STRINGS.ERROR_COMPLETE_CARD));

    const userAuthorizationService = UserAuthorizationService.getInstance();

    userAuthorizationService.isActionAllowed(WorkflowActions.OVERRIDE_CARD_STAGE, SecurityGroupFeatures.WORKFLOW)
      .then(hasPermission => {
        // user is admin and can override stage
        setHasPermissionToOverrideStage(hasPermission);
      });

  }, [cardData]);

  const setDataFromWorkflow = (workflow: WorkflowResponse) => {
    setCurrentStage(getStageFromStageArray(workflow.stages, cardData.stageId));
  };

  const completeStage = () => {
    setIsSubmitting(true);
    completeCardStageForWorkflow(
      cardData._id,
      cardData.workflowId,
      stageFieldMap
    ).then(() => {
      props.onHide();
    }).catch(() => {
      Toast.errorString();
    }).finally(() => setIsSubmitting(false));
  };

  const handleSubmit = (value?: ApprovalValues) => {
    if (value === ApprovalValues.REJECTED) {
      setAlertModalConfig({
        showCancelButton: true,
        onConfirm: completeStage,
        confirmButtonText: ITEM_DETAILS_STRINGS.REJECTED_ITEM
      });
    } else {
      completeStage();
    }
  };

  const handleDeleteAction = () => {
    discardCardById(cardData._id, cardData.workflowId)
      .then(() => {
        Toast.success("Item is discarded.", ToastLength.LONG);
        props.onHide();
      }).catch(() => Toast.errorString());

  };

  const handleCardDeleteClick = () => {
    setAlertModalConfig({
      showCancelButton: true,
      onConfirm: handleDeleteAction
    });
  };

  const renderEditAndDiscardButtons = () => {
    return (
      <>
        <QuickActionButton buttonText={ITEM_DETAILS_STRINGS.DISCARD} type={QuickActionButtonTypes.DISCARD} onClick={handleCardDeleteClick} getIcon={() => <TrashIcon />} />
        <QuickActionButton buttonText={ITEM_DETAILS_STRINGS.EDIT} type={QuickActionButtonTypes.EDIT} onClick={() => {setEditMode(true) }} getIcon={() => <Pencil1Icon />} />
      </>
    )
  };

  const renderQuickActions = () => {
    return (
      <div className={styles.renderQuickActions}>
        <a href={"/workflow/" + cardData.workflowId + "/item-details/" + cardData._id} target="_blank">
          <QuickActionButton buttonText={ITEM_DETAILS_STRINGS.SHARE} type={QuickActionButtonTypes.SHARE} onClick={() => { }} getIcon={() => <Share1Icon />} />
        </a>
        {cardData.status !== CardStatus.DISCARDED && hasPermissionToOverrideStage && renderEditAndDiscardButtons()}
      </div>
    );
  };

  const getModalSubTitle = () => {
    const { workflow, cardData } = props;

    return (
        <ModalSubHeaderComponent
            number={cardData.details.name}
            title={ITEM_DETAILS_STRINGS.MY_ITEMS}
            name={workflow?.details.name}
            dueOn={cardData.status === CardStatus.ACTIVE ? cardData.dueDate : undefined}
            assignees={cardData.assignee}
            assigneeUserGroupIds={cardData.assigneeUserGroupIds}
            createdBy={cardData.metaData.createdBy}
            createdOn={cardData.metaData.createdDate}
        />
    );
  };

  const renderCurrentStageForm = () => {
    if (!currentStage || !isItemCompleteAllowed(currentStage, cardData, hasPermissionToOverrideStage)) {
      return null;
    }

    return (
      <div className={styles.formWrapper}>
        <hr />
        <p className={styles.stageNameLabel}>{currentStage.details.name}</p>
        <StageFormComponent
          stageForm={stageForm}
          fieldsMap={stageFieldMap}
          setFieldMap={setStageFieldMap}
          onSubmit={handleSubmit}
        />
        {
          currentStage && isSubmitButtonVisible(currentStage.type) &&
          <GenericButton
            onClick={handleSubmit}
            buttonText={ITEM_DETAILS_STRINGS.SUBMIT_BUTTON}
            className={styles.nextStageButton}
            isLoading={isSubmitting}
          />
        }
      </div>
    );
  };

  const renderTag = () => {
    switch (cardData.status) {
      case CardStatus.COMPLETED:
        return (<GenericTag title={TAG_STAGE.COMPLETED} type={GenericTagTypes.ACTIVE} />);
      case CardStatus.ARCHIVED:
        return (<GenericTag title={TAG_STAGE.ARCHIVED} type={GenericTagTypes.WARN} />);
      case CardStatus.DISCARDED:
        return (<GenericTag title={TAG_STAGE.DISCARDED} type={GenericTagTypes.ERROR} />);
      default:
        return (<GenericTag title={TAG_STAGE.PENDING} type={GenericTagTypes.PENDING} />);
    }
  };

  const handleEditSubmit = () => {
    updateCardStageForWorkflow(
      cardData._id,
      cardData.workflowId,
      stageEditFieldsMap
    ).then((res) => {
      setCardData(res);
      setEditMode(false);
      Toast.success(ITEM_DETAILS_STRINGS.EDIT_SUCESS);
    }).catch(() => {
      Toast.errorString(ITEM_DETAILS_STRINGS.EDIT_ERROR);
    })
  }

  const handleEditCancelButton = () => {
        setEditMode(false);
        setShowSpinner(true);
  }


  const renderEditMode = () => {
    return(
      <div className={styles.editMode}>
        <GenericButton type={GenericButtonTypes.SECONDARY} buttonText={ITEM_DETAILS_STRINGS.CANCEL} onClick={handleEditCancelButton} />
        <GenericButton buttonText={ITEM_DETAILS_STRINGS.SAVE} onClick={handleEditSubmit} />
      </div>
    )
  }

  const handleSpinner = () => {
    setTimeout(() => {
      setShowSpinner(false);
  }, 250);
  }



  const renderItemDataComponent = () => {
    if (showSpinner) {
      return (
        <>
          <Spinner/>
          {handleSpinner()}
        </>
      );
    }
    else{
      return(
        <ItemDataComponent
        workflow={workflow}
        cardData={cardData}
        stageId={currentStage && currentStage._id}
        editMode={editMode}
        fieldsMap={stageEditFieldsMap}
        setFieldsMap={setStageEditFieldsMap}
      />
      )
    }
  }

  const renderBody = () => {
    return (
      <div className={styles.itemDetailsModalBody}>
        <div className={styles.itemDetailsMainBodyWrapper}>
          <GenericDescriptionText text={cardData.details.description}/>
          {renderQuickActions()}
          {renderItemDataComponent()}
          {editMode && renderEditMode()}
          {!editMode && renderCurrentStageForm()}
        </div>
        <ItemActivityDetailsComponent cardData={cardData} />
      </div>
    );
  }

  if (!currentStage || !workflow) {
    return null;
  }

  return (
    <>
      <GenericModal
        show
        title={cardData.details.name}
        leftHeader={renderTag}
        subHeader={getModalSubTitle}
        bodyContent={renderBody}
        contentScrollDisable
        dialogClassName={styles.itemDetailsModalDialog}
        onClose={props.onHide} />
    </>
  );
};

type PropType = {
  workflow?: WorkflowResponse;
  cardData: CardResponse;
  cardModalShow: boolean;
  onHide: () => void;
};

export default CardDetails;
