import {memo} from 'react';
import {Handle, Position} from "react-flow-renderer";
import CheckMarkIcon from "../../../../assets/CheckMark.svg";
import {StageData} from "../../../entity/workflowBuilder/StageData";

type NodeProps = {
    data: StageData;
};

const EndNode = memo((props: NodeProps) => {
    const nodeData = props.data as StageData;
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "8px 16px",
            position: "static",
            width: "210px",
            height: "33px",
            left: "0px",
            top: "71px",
            background: "#F4F4F4",
            borderRadius: "10px",
            /* Inside Auto Layout */
            flex: "none",
            order: 2,
            flexGrow: 0,
            margin: "2px 0px"
        }}>
            <Handle
                type="target"
                position={Position.Top}
                id="a"
                style={{ background: '#555' }}
                isConnectable
            />
            <div style={{
                fontFamily: "Lato",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "14px",
                lineHeight: "17px",
                /* identical to box height */
                textAlign: "center",
                /* Green */
                color: "#28A745",
            }}>
                <img src={CheckMarkIcon} />
                <span style={{marginLeft: "5px"}}>{nodeData.details.label}</span>
            </div>

        </div>
    );
});

export default EndNode;
