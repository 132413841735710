import * as React from 'react';
import { Tabs } from 'antd';
import { TodoTable } from '@eazy2biz-ui/todo-ui';
import styles from './WorkflowDetails.module.css';
import {SecurityGroupFeatures, WorkflowResponse} from "@eazy2biz/common-util";
import WorkflowItemsTabComponent from "../workflowItemsTabComponent/WorkflowItemsTabComponent";
import WorkflowReportTabComponent from './../workflowReportTabComponent/WorkflowReportTabComponent';
import { UserAuthorizationService, WorkflowActions } from '@eazy2biz/common-package-ui';
import WorkflowSchedulersTabComponent from "../workflowSchedulersTabComponent/WorkflowSchedulersTabComponent";
import { useHistory } from "react-router-dom";
import { RoutesEnum } from "@eazy2biz-ui/common-package";
import { useState } from "react";

enum WorkflowTabKeys {
  TODOS = 'todos',
  ITEMS = 'items',
  REPORTS = 'reports',
  SCHEDULERS = 'schedulers'
}

const WorkflowDetails = (props: PropTypes) => {
  const { TabPane } = Tabs;
  const { onRefreshCallback, refreshData } = props;
  const [hasPermissionToViewReport, setHasPermissionToViewReport] = React.useState<boolean | undefined>(undefined);
  const [activeKey, setActiveKey] = useState(getTabKey(props.defaultTab || WorkflowTabKeys.TODOS.toString()));

  const history = useHistory();

  React.useEffect(() => {
    if (!getTabKey(props.defaultTab)) {
      handleTabChange(WorkflowTabKeys.TODOS.toString());
    }
    const userAuthorizationService = UserAuthorizationService.getInstance();
    userAuthorizationService.isActionAllowed(WorkflowActions.VIEW_REPORT, SecurityGroupFeatures.WORKFLOW)
      .then(hasPermission => {
        setHasPermissionToViewReport(hasPermission);
      });
  }, []);

  const handleTabChange = (activeKey: string) => {
    setActiveKey(activeKey);
    const path = RoutesEnum.WORKFLOW_HOMEPAGE_TAB
      .replace(':id', props.workflow._id)
      .replace(':tab', activeKey);
    history.replace(path);
  };

  return (
    <div className={styles.workflowDetailsSection}>
      <Tabs activeKey={activeKey} onChange={handleTabChange}>
        <TabPane tab="My To Do's" key={WorkflowTabKeys.TODOS.toString()} >
          <TodoTable workflowId={props.workflow._id} refreshTable={refreshData} onRefreshCallback={onRefreshCallback} />
        </TabPane>
        <TabPane tab="My Items" key={WorkflowTabKeys.ITEMS.toString()}>
          <WorkflowItemsTabComponent workflow={props.workflow} refreshTable={refreshData} onRefreshCallback={onRefreshCallback} />
        </TabPane>
        {
          hasPermissionToViewReport ?
            <TabPane tab="My Report" key={WorkflowTabKeys.REPORTS.toString()}>
              <WorkflowReportTabComponent workflow={props.workflow} refreshTable={refreshData} />
            </TabPane> : null
        }
        <TabPane tab="Schedulers" key={WorkflowTabKeys.SCHEDULERS.toString()}>
          <WorkflowSchedulersTabComponent workflow={props.workflow} />
        </TabPane>
      </Tabs>
    </div>
  );
};

type PropTypes = {
  workflow: WorkflowResponse;
  refreshData: boolean;
  onRefreshCallback?: (value: boolean) => void;
  defaultTab?: string;
};

export default WorkflowDetails;

const getTabKey = (value?: string): string | undefined => {
  return Object.keys(WorkflowTabKeys).find(key => key.toLocaleLowerCase() === value) ? value : undefined;
};
