import {DropdownSelect} from "@eazy2biz-ui/common-components";
import {
    createdDatePath,
    lastUpdatedDatePath,
    Field,
    FieldTypes,
    WorkflowResponse, getFieldPathFromWorkflowFieldId
} from "@eazy2biz/common-util";

export const ConfigureChartWorkflowDurationField = (
    props : {
        entityId: string;
        workflowResponse: WorkflowResponse;
        showChartConfigureFormFieldErrors: boolean;

        selectedDurationField : Field
        setSelectedDurationField: (selectedField : string) => void;
    }
) : JSX.Element => {

    const {
        entityId,
        workflowResponse,
        showChartConfigureFormFieldErrors,
        selectedDurationField,
        setSelectedDurationField
    } = props;

    return (
        <>
            <DropdownSelect
                placeholder={'Select Duration Field'}
                label={'Select Duration Field'}
                selectedItem={selectedDurationField?._id}
                onItemSelect={
                    (fieldId) => setSelectedDurationField(fieldId)
                }
                loading={workflowResponse?.fields === null}
                isErrored={
                    showChartConfigureFormFieldErrors &&
                    (
                        selectedDurationField === null
                        || selectedDurationField === undefined
                    )
                }
                menuData={
                    [
                        {
                            _id: createdDatePath,
                            name: 'Created Date'
                        },
                        {
                            _id: lastUpdatedDatePath,
                            name: 'Last Modified Date'
                        },
                        ...workflowResponse.fields?.filter(
                            field => field.type === FieldTypes.DATE
                        ).map(
                            (field) => ({
                                _id: getFieldPathFromWorkflowFieldId(field._id),
                                name: field.name
                            })
                        )
                    ]
                }
            />
        </>
    )
}
