import * as React from 'react';
import { GenericTag, GenericWhatsappMessagePreview, TextVariableInput, VariableField } from '@eazy2biz-ui/common-components';
import { TEMPLATE_CONFIGURATION_STRINGS } from '../../../../../contents/DisplayContent';
import {
  getFormattedContentForWhatsappParameterText,
  getVariableInputsFromParametersWithSampleValue,
  getVariableNames,
  updatePlainTextWithVariableValues
} from "../../../../helpers/whatsapp/WhatsappTemplateConfigurationHelper";
import { BodyContent } from './bodyContent/BodyContent';
import styles from './WhatsappTemplateConfiguration.module.css';
import { HeaderContent } from './headerContent/HeaderContent';
import { WhatsappTemplateConfigurationType } from '../../../../helpers/whatsapp/CreateWhatsappTemplateHelper';
import { useEffect } from "react";
import { cloneDeep } from "lodash";
import { getParametersWithSampleValue } from "../../../../helpers/TemplateHelper";

export const WhatsappTemplateConfiguration = (props: WhatsappTemplateConfigurationPropsType): JSX.Element => {
  const [bodyContent, setBodyContent] = React.useState<string>('');
  const [headerContent, setHeaderContent] = React.useState<string>('');
  const [variableFields, setVariableFields] = React.useState<VariableField[]>([]);

  useEffect(() => {
    setBodyContent(parseTextContent(props.configuration.body.text));
    setHeaderContent(parseTextContent(props.configuration.header.text));
    setVariableFields(getVariableInputsFromParametersWithSampleValue(props.configuration.templateParameters));
  }, []);

  const updateVariablesForText = (header: string, body: string, variables: VariableField[]) => {

    const variableNames: string[] = getVariableNames([header, body]);

    setVariableFields(variableNames.map(variableName => ({
          id: variableName,
          name: variableName,
          value: variableFields.find((field) => field.name === variableName)?.value || '',
        })));

    const updatedConfiguration = cloneDeep(props.configuration);
    props.updateConfiguration({
      ...updatedConfiguration,
      header: getFormattedContentForWhatsappParameterText(header),
      body: getFormattedContentForWhatsappParameterText(body),
      templateParameters: getParametersWithSampleValue(variableFields)
    });
  };

  const handleHeaderChange = (header: string) => {
    setHeaderContent(header);
    updateVariablesForText(header, bodyContent, variableFields);
  };

  const handleBodyChange = (body: string) => {
    setBodyContent(body);
    updateVariablesForText(headerContent, body, variableFields);
  };

  const handleVariableUpdate = (variables: VariableField[]) => {
    setVariableFields(variables);
    const updatedConfiguration = cloneDeep(props.configuration);
    props.updateConfiguration({
      ...updatedConfiguration,
      templateParameters: getParametersWithSampleValue(variableFields)
    });
  };

  return (
    <div className={styles.configurationContent}>
      {
        variableFields.length ? (
          <div className={styles.variableFieldsDisplay}>
            <TextVariableInput
              key={'WHATSAPP_CONFIG'}
              disabled={props.readOnly}
              variableFields={variableFields}
              setVariableFields={handleVariableUpdate}
            />
          </div>
        ) : null
      }
      <div className={styles.configurationList}>
        <div className={styles.configurationSection}>
          <div className={styles.header}>
            <span>{TEMPLATE_CONFIGURATION_STRINGS.HEADER}</span>
            <GenericTag title={TEMPLATE_CONFIGURATION_STRINGS.OPTIONAL_TAG} />
          </div>
          <div className={styles.description}>
            {TEMPLATE_CONFIGURATION_STRINGS.HEADER_DESCRIPTION}
          </div>
          <div>
            <HeaderContent
              value={headerContent}
              onChange={handleHeaderChange}
              readOnly={props.readOnly} />
          </div>
        </div>
        <div className={styles.configurationSection}>
          <div className={styles.header}>
            <span>{TEMPLATE_CONFIGURATION_STRINGS.BODY}</span>
          </div>
          <div className={styles.description}>
            {TEMPLATE_CONFIGURATION_STRINGS.BODY_DESCRIPTION}
          </div>
          <div className={styles.content}>
            <BodyContent
              value={bodyContent}
              onChange={handleBodyChange}
              readOnly={props.readOnly}/>
          </div>
        </div>
      </div>
      <div className={styles.preview}>
        <GenericWhatsappMessagePreview msg={[
            {
              header: updatePlainTextWithVariableValues(headerContent, variableFields),
              body: updatePlainTextWithVariableValues(bodyContent, variableFields)
            }
          ]} />
      </div>
    </div>
  );
}

type WhatsappTemplateConfigurationPropsType = {
  configuration: WhatsappTemplateConfigurationType,
  updateConfiguration: (configuration: WhatsappTemplateConfigurationType) => void;
  readOnly?: boolean;
};

const parseTextContent = (content: string) => {
  content = content.replaceAll('{{', "@");
  return content.replaceAll('}}', "");
};
