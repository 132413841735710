import styles from './BodyContent.module.css';
import {InputField} from "@eazy2biz-ui/common-components";
import { WhatsappTemplateConfigurationType } from "../../../../../helpers/whatsapp/CreateWhatsappTemplateHelper";

export const BodyContent = (props: PropsType): JSX.Element => {
  const handleChange = (e: any) => {
    props.onChange(e.target.value);
  };

  return (
    <div className={styles.container}>
      <InputField
        id={'body'}
        label={''}
        required={false}
        type={'textarea'}
        value={props.value}
        disabled={props.readOnly}
        className={styles.textArea}
        onChange={handleChange}
      />
    </div>
  );
};

type PropsType = {
  value: string,
  onChange: (text: string) => void;
  readOnly?: boolean;
};
