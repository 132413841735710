import { useEffect, useState } from "react";
import { WorkflowCardFooter, WorkflowCardProps, WorkflowCardText } from "./WorkflowCard";
import { SecurityGroupFeatures, TemplateCategory, TemplateResponse, WorkflowResponse } from "@eazy2biz/common-util";
import {
  CardList,
  CardPropTypes,
  TemplatesSelectionModal,
  Toast,
  TourType,
  useFeatureTour
} from "@eazy2biz-ui/common-components";
import { WORKFLOW_HOMEPAGE } from "../../../contents/DisplayContent";
import { getAllEnabledWorkflows } from "../../services/WorkflowService";
import { useHistory } from "react-router-dom";
import CreateItemModal from "./CreateItemModal";
import {
  getDraftWorkflowStateKey,
  getWorkflowDraftJSONFromTemplate,
  LocalStorageService,
  RoutesEnum
} from "@eazy2biz-ui/common-package";
import { UserActions, UserAuthorizationService } from "@eazy2biz/common-package-ui";

const WorkflowList = (props: PropTypes) => {
    const [allWorkflows, setAllWorkflows] = useState<WorkflowResponse[]>([]);
    const [createItemForWorkflow, setCreateItemForWorkflow] = useState<string>('');
    const [showCreateItemModal, setCreateItemModal] = useState(false);
    const [createWorkflowAllowed, setCreateWorkflowAllowed] = useState(false);
    const [workflowTemplateModal, setWorkflowTemplateModal] = useState(false);

    const history = useHistory();

  const {getRef, triggerTour} = useFeatureTour();

    useEffect(() => {
        // TODO: FIX SecurityGroupFeatures
        UserAuthorizationService.getInstance().isActionAllowed(UserActions.CREATE, SecurityGroupFeatures.USERS)
            .then((allowed) => setCreateWorkflowAllowed(allowed));

        getAllEnabledWorkflows().then((response: WorkflowResponse[]) => {
                setAllWorkflows(response.reverse());
            })
            .catch(() => {
                Toast.errorString(WORKFLOW_HOMEPAGE.ERROR_FETCH_WORKFLOW_LIST);
            });
    }, []);

    const handleCardOnClick = (workflow: WorkflowResponse) => {
      const path = RoutesEnum.WORKFLOW_HOMEPAGE_TAB
        .replace(':id', workflow._id)
        .replace(':tab', 'todos');
        history.push({
            pathname: path
        });
    };

    const handleTemplateSelect = (template: TemplateResponse) => {
      LocalStorageService.setItem(
        getDraftWorkflowStateKey(),
        getWorkflowDraftJSONFromTemplate(template)
      );
      history.push(RoutesEnum.WORKFLOW_BUILDER);
    };

    const handleCreateCustomClick = () => {
        history.push(RoutesEnum.WORKFLOW_BUILDER);
    };

  const handleCreateCustomCard = () => {
    setWorkflowTemplateModal(true);
  };

    const handleOnCreateItemModalClick = (workflow?: WorkflowResponse) => {
        setCreateItemForWorkflow(workflow? workflow._id : '');
        setCreateItemModal(workflow !== undefined);
    };

    const getWorkflowCardProps = (): CardPropTypes[] => {
        return allWorkflows.map((workflow) => (
            {
                key: workflow._id,
                className: WorkflowCardProps.className,
                cardTitle: WorkflowCardProps.cardTitle(workflow),
                cardText: <WorkflowCardText workflow={workflow} handleOnCardClick={() => handleCardOnClick(workflow)} />,
                cardFooter: <WorkflowCardFooter handleOnFooterClick={() => handleOnCreateItemModalClick(workflow)} />,
                onCardBodyClick: () => handleCardOnClick(workflow),
            }
        ));
    };

    return (
        <div ref={getRef(3, TourType.HOMEPAGE)} onMouseEnter={() => triggerTour(TourType.HOMEPAGE)}>
            <CardList
                cardProps={getWorkflowCardProps()}
                createCustomDisabled={!createWorkflowAllowed}
                onCreateCustom={handleCreateCustomCard} />
            <CreateItemModal
                openModal={showCreateItemModal}
                setOpenModal={() => handleOnCreateItemModalClick()}
                workflowId={createItemForWorkflow}
                onSubmit={props.handleOnCreateItemSuccess}
            />
          {workflowTemplateModal && <TemplatesSelectionModal
            templateCategory={TemplateCategory.WORKFLOW}
            title={WORKFLOW_HOMEPAGE.WORKFLOW_TEMPLATES}
            onTemplateSelect={handleTemplateSelect}
            onClose={() => setWorkflowTemplateModal(false)}
            onCreateCustom={handleCreateCustomClick}
          />}
        </div>
    );
}

export default WorkflowList;

type PropTypes ={
    handleOnCreateItemSuccess: () => void;
}
