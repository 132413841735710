import {AggregatorType, ChartType, DateFieldAggregationType} from "@eazy2biz/common-util";

export const HELLO_WORLD = "Hello World";

export const SUBMIT = "Submit";

export const REPORT_NOT_SELECTED = "Please select a report to view";
export const REPORT_DURATION_NOT_SELECTED = "Please choose a report duration";
export const CHART_DURATION_NOT_SELECTED = "Please choose a chart duration";
export const CHART_DATA_NOT_FOUND = "No data present in this duration";

export const TABLE_CHART = {
    DOWNLOAD_EXCEL: 'Download Excel'
};

export const reportContent = {
    EDIT_REPORT : "Edit Report",
    DELETE_REPORT : "Delete Report",
    DELETE_REPORT_ALERT_MESSAGE : "Are you sure you want to delete this report?\nThis action cannot be undone.",
    SELECT_REPORT : "Select Report",
    DISCARD_REPORT_CHANGES : "Discard Changes",
    UPDATE_REPORT : "Update Report",
    UPDATE_REPORT_ALERT_MESSAGE : "Are you sure you want to update this report?\nThis action cannot be undone.",
    CHANGE_REPORT : "Change Report",
    SELECT_REPORT_DURATION : "Select Report Duration",
    REPORT_START_DATE: "Report Start Date",
    REPORT_END_DATE: "Report End Date",

};

export const chartContent = {
    DELETE_CHART : "Delete Chart",
    SELECT_CHART_DURATION : "Select Chart Duration",
    CHART_START_DATE: "Chart Start Date",
    CHART_END_DATE: "Chart End Date",

    CONFIGURE_CHART : "Configure Chart",
    CREATE_CHART : "Create Chart",
    SELECT_CHART_TYPE : "Select Chart Type",
    CHART_CREATE_REQUIRED_INPUTS_INVALID : "Please enter all required chart inputs to view preview"
};

export const displayNameForChart = {
    [ChartType.LINE] : "Line",
    [ChartType.AREA] : "Area",
    [ChartType.BAR] : "Bar",
    [ChartType.PIE] : "Pie",
    [ChartType.TABLE] : "Table",
}

export const displayNameForDateFieldAggregationType = {
    [DateFieldAggregationType.HOUR] : 'Hour',
    [DateFieldAggregationType.DAY_OF_WEEK] : 'Day Of Week',
    [DateFieldAggregationType.DAY_OF_MONTH] : 'Day Of Month',
    [DateFieldAggregationType.DAY_OF_YEAR] : 'Day of Year',
    [DateFieldAggregationType.WEEK] : 'Week',
    [DateFieldAggregationType.MONTH] : 'Month',
    [DateFieldAggregationType.QUARTER] : 'Quarter',
    [DateFieldAggregationType.YEAR] : 'Year',
}

export const displayNameForAggregatorType = {
    [AggregatorType.SUM] : "Sum",
    [AggregatorType.COUNT] : "Count",
    [AggregatorType.AVG] : "Average",
    [AggregatorType.MIN] : "Minimum",
    [AggregatorType.MAX] : "Maximum"
}



/*
* Toast Message String
* */
export const SUCCESS_STRINGS = {
}

export const ERROR_STRINGS = {
    INVALID_REPORTS_INPUT : "Please enter all required inputs"
}
