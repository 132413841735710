import React, {Component} from 'react';
import {ChevronRightIcon} from '@radix-ui/react-icons';
import {getStageFormForWorkflowStage} from '../../services/WorkflowService';
import {
  GenericCardForm,
  GenericModal,
  ItemDataComponent,
  GenericTag,
  GenericTagTypes,
  ModalSubHeaderComponent
} from '@eazy2biz-ui/common-components';
import styles from './TodoCompleteForm.module.css';
import {
  ApprovalValues,
  CardResponse,
  Stage,
  StageFormEntity,
  TodoStatus,
  UserTodoExecutableActionResponse,
  WorkflowResponse
} from "@eazy2biz/common-util";
import {TODO_COMPLETE_FORM} from "../../../contents/DisplayContent";
import {
  CachedStore,
  FormFieldInput,
  getStageFromStageArray,
  isApprovalStage,
  StoreEntityTypes,
} from '@eazy2biz/common-package-ui';
import {getCardById} from "../../services/CardService";
import {isTodoSubmitEnabled} from "../../helpers/TodoFormHelper";

/**
 * To do complete for item linked.
 */
class TodoCompleteForm extends Component<PropType, StateType> {

  constructor(props: PropType) {
    super(props);
    this.state = {
      stageArray: [],
      workflowFields: [],
      workflowName: ''
    };
  }

  componentDidMount() {
    const { todo: {actionIdentifier} } = this.props;
    if (!actionIdentifier) {
      return;
    }
    const workflowId = actionIdentifier.workflowId;
    const stageId = actionIdentifier.stageId;



    if (this.state.workflowName === '') {
      CachedStore.getEntityFromListById<WorkflowResponse>(StoreEntityTypes.WORKFLOWS, workflowId).then(
          (response: WorkflowResponse) => {
            if (response) {
              this.setState({
                stageArray: response.stages,
                workflowFields: response.fields,
                workflowName: response.details.name || workflowId,
                currentStage: getStageFromStageArray(response.stages, stageId),
                workflow: response,
              });
            }
          }
      );
    }

    getCardById(actionIdentifier.cardId, workflowId).then(
        (response: CardResponse) => this.setState({itemData: response})
    );

    if (!this.state.stageForm) {
      getStageFormForWorkflowStage(stageId, workflowId).then((response: StageFormEntity) => {
        this.setState({ stageForm: response });
      });
    }
  }

  isSubmitEnabled() {
    return !isApprovalStage(this.state.currentStage);
  }

  getModalSubTitle = () => {
    const { workflowName, itemData } = this.state;
    const {todo} = this.props;

    return (
        <ModalSubHeaderComponent
            number={itemData?.details.name}
            title={TODO_COMPLETE_FORM.MY_TODO_TITLE}
            name={workflowName}
            dueOn={todo.status === TodoStatus.PENDING ? todo.dueDate : undefined}
            createdOn={todo.metaData.createdDate}
            assignees={todo.assigneeUserId}
            assigneeUserGroupIds={todo.assigneeUserGroupId}
            createdBy={todo.metaData.createdBy}
        />
    );
  };

  getLeftHeader = () => {
    switch(this.props.todo.status) {
      case TodoStatus.COMPLETED:
        return <GenericTag title="Completed" type={GenericTagTypes.COMPLETED}/>
      case TodoStatus.APPROVED:
        return <GenericTag title="Approved" type={GenericTagTypes.ACTIVE}/>
      case TodoStatus.REJECTED:
        return <GenericTag title="Rejected" type={GenericTagTypes.ERROR}/>
      case TodoStatus.STALE:
        return <GenericTag title="Stale" type={GenericTagTypes.ERROR}/>
      default:
        return <GenericTag title="Pending" type={GenericTagTypes.PENDING}/>
    }
  };

  getModalContent = () => {
    const {todo: {actionIdentifier}} = this.props;
    const {workflow, itemData} = this.state;
    if (!actionIdentifier) {
      return null;
    }
    return (
        <div>
          <div className={styles.itemPageHyperlink}>
            <a
                href={"/workflow/" + actionIdentifier.workflowId + "/item-details/" + actionIdentifier.cardId}
                onClick={() => this.props.onClose()}
                target="_blank">
              Go to Item Details
              <ChevronRightIcon />
            </a>
          </div>
          {itemData && <ItemDataComponent cardData={itemData} stageId={actionIdentifier.stageId} workflow={workflow} />}
        </div>
    );
  };

  getTodoTitle = () => {
    return "#" + this.props.todo.todoNumber + " " + this.props.todo.details.name;
  }

  render() {
    const { stageForm, currentStage } = this.state;
    const { onClose, onTodoSubmit } = this.props;

    if (!isTodoSubmitEnabled(this.props.todo)) {
      // IF TO DO IS ALREADY CLOSED OR CURRENT USER IS NOT THE ASSIGNEE
      return (
          <GenericModal
              show
              title={this.getTodoTitle()}
              leftHeader={this.getLeftHeader}
              subHeader={this.getModalSubTitle}
              bodyContent={this.getModalContent}
              onClose={onClose}
              dialogClassName={styles.modal}
          />
      );
    } else {
      return (
        <GenericCardForm
          show
          stageData={currentStage}
          stageForm={stageForm}
          onSubmit={onTodoSubmit}
          onClose={onClose}
          modalTitle={this.getTodoTitle()}
          leftHeader={this.getLeftHeader()}
          modalSubTitle={this.getModalSubTitle()}
          modalContent={this.getModalContent()}
          enableSubmitButton={this.isSubmitEnabled()}
          submitButtonText={TODO_COMPLETE_FORM.COMPLETE_TODO_BUTTON}
        />
      );
    }
  }
}

type PropType = {
  todo: UserTodoExecutableActionResponse;
  onClose: () => void;
  onTodoSubmit: (fieldMap: FormFieldInput[], value?: ApprovalValues) => void;
};

type StateType = {
  stageForm?: StageFormEntity;
  stageArray?: Stage[];
  workflowFields?: any;
  workflowName: string;
  currentStage?: Stage;
  workflow?: WorkflowResponse;
  itemData?: CardResponse;
};

export default TodoCompleteForm;
