import { TemplateParameter, TemplateStatus } from "@eazy2biz/common-util";
import { GenericTagTypes, VariableField } from "@eazy2biz-ui/common-components";

export const getTagTypeForTemplateStatus = (status?: TemplateStatus) => {
  switch (status) {
    case TemplateStatus.NEW:
    case TemplateStatus.PENDING_APPROVAL:
      return GenericTagTypes.ACTIVE;
    case TemplateStatus.REJECTED:
      return GenericTagTypes.ERROR;
    case TemplateStatus.PENDING_DELETION:
    case TemplateStatus.PAUSED:
      return GenericTagTypes.WARN;
    case TemplateStatus.DISABLED:
    case TemplateStatus.DELETED:
      return GenericTagTypes.DARK;
    default:
      return GenericTagTypes.COMPLETED;
  }
};

export const getParametersWithSampleValue = (variableFields: VariableField[]): TemplateParameter[] => {
  return variableFields.map(vf => {
    return {
      name: vf.name,
      details: {
        example: vf.value
      }
    }
  });
};
