import React from "react";
import {IdentityWrapper} from "@eazy2biz-ui/identities-ui";
import styles from './CoreComponent.module.css';
import {CoreLayoutComponent} from "./coreLayoutComponent/CoreLayoutComponent";
import { AlertModalProvider, FeatureTourProvider } from "@eazy2biz-ui/common-components";

const CoreComponent: (props: PropTypes) => JSX.Element = (props: PropTypes) => {
    return(
        <div className={styles.CoreWrapper}>
            <AlertModalProvider>
              <FeatureTourProvider>
                {/* @ts-ignore */}
                <IdentityWrapper CoreSection={props.disableDefaultLayout ? props.component : CoreLayoutComponent} {...props}/>
              </FeatureTourProvider>
            </AlertModalProvider>
        </div>
    );
};

type PropTypes = {
    component: React.Component;
    disableDefaultLayout: boolean;
}

export default CoreComponent;
