import {UploadField} from "../../../../genericComponents/UploadField/UploadField";
import {InputProps as DefaultInputPropTypes} from "antd/lib/input/Input";
import {Field} from "@eazy2biz/common-util";
import styles from './CardFormUploadComponent.module.css';
import classNames from "classnames";

/**
 * Card form field for FILE type.
 * @param props
 * @constructor
 */
export const CardFromUploadComponent = (props: PropTypes) => {

    let defaultValues = [];

    if (props.defaultValue instanceof Array) {
        // @ts-ignore
        defaultValues = props.defaultValue;
    }

    return (
        <UploadField
            className={classNames(styles.cardFromUploadField)}
            onChange={props.onChange}
            readOnly={props.readOnly}
            defaultValue={defaultValues}/>
    );
}

interface PropTypes extends DefaultInputPropTypes {
};
