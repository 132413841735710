import {PropTypes as DefaultPropTypes} from "../InputField/InputField";
import {DynamicPlaceholder} from "./GenericPlaceholder/DynamicPlaceholder";
import React, {useEffect, useState} from "react";
import styles from './InputFieldWithPlaceholder.module.css';
import {Mention, MentionsInput} from "react-mentions";
import {CachedStore, StoreEntityTypes} from "@eazy2biz/common-package-ui";
import {
    getMarkupValue,
    processEvent,
    processInput
} from "./helpers/placeholder/dynamicPlaceholderHelper";
import classNames from "classnames";
import {DynamicPlaceholderConfig} from "./config/DynamicPlaceholderConfig";
import {PlaceholderOption as PlaceHolderOptionType} from './entity/PlaceholderOption';
import {UserPlaceholderEntity} from "./entity/UserPlaceholderEntity";
import {MARK_UP, placeholderOpenShortcut} from "./constants/Constants";
import {convertFieldList} from "./helpers/FormFieldPlaceholderHelper";
import "./dynamicPlaceholderInputMentionStyle.css";
import {InputFieldSinglePlaceholderComponent} from "./components/InputFieldSinglePlaceholderComponent/InputFieldSinglePlaceholderComponent";
import {SinglePlaceholderConfig} from "./config/SinglePlaceholderConfig";
import {FieldPlaceholder, LocalUserResponse} from "@eazy2biz/common-util";

/**
 * Placeholder Input Field.
 * @param props
 * @constructor
 */
export const InputFieldWithPlaceholder = (props: PropTypes) => {
    const {
        onChange,
        id,
        initialValue,
        label,
        required = true,
        config = new DynamicPlaceholderConfig(),
        className,
        type = 'textarea',
        singleValueMode,
    } = props;
    const [isMenuOpen, togglePlaceholder] = useState<boolean>(false);
    const [valueOfInputField, setValueOfInputField] = useState<string>(initialValue? initialValue.toString() : '');
    const [users, setUsers] = useState<UserPlaceholderEntity[]>([]);
    const [fields, setFields] = useState<PlaceHolderOptionType[]>([]);

    const [fetchingUsers, setFetchingUsers] = useState(config.usersEnabled);
    const [fetchingFields, setFetchingFields] = useState(config.formFieldsEnabled);

    let inputRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null);

    useEffect(() => {
        if (props.value === '') {
            setValueOfInputField('');
        }
    }, [props.value]);

    useEffect(() => {
        config.usersEnabled && fetchUserList();
        config.formFieldsEnabled && fetchFieldList();
    }, []);

    useEffect(() => {
        if (!fetchingUsers && !fetchingFields) {
            setValueOfInputField(processInput(valueOfInputField, users, fields, config));
        }
    }, [fetchingUsers, fetchingFields]);

    const fetchUserList = () => {
        CachedStore.getStoreList(StoreEntityTypes.LOCAL_USERS).then((response = []) => {
            setUsers((response as LocalUserResponse[]).filter(config.filterUserComparator) as UserPlaceholderEntity[]);
            setFetchingUsers(false);
        });
    };

    const fetchFieldList = () => {
        setFields(convertFieldList(config.getFilteredFields()));
        setFetchingFields(false);
    };

    /**
     * Handles Input Field Value change in input text box.
     * @param e
     */
    const handleInputFieldChange = (e: any) => {
        if (e.target.value.slice(-1) === placeholderOpenShortcut) {
            togglePlaceholder(true);
        } else {
            togglePlaceholder(false);
        }
        setValueOfInputField(e.target.value);
        onChange(processEvent(e));
        inputRef && inputRef.current && inputRef.current.focus();
    };

    /**
     * Calculates and returns the new display value.
     * @param placeholder
     */
    const getValueWithAddedPlaceholder = (placeholder: FieldPlaceholder) => {
        const placeholderMarkupValue = getMarkupValue(placeholder.convertToString(), users, fields, config);
        if (singleValueMode) {
            // In case of single value, just replace the entire text with new value.
            return placeholderMarkupValue;
        }

        const inputFieldValue =
            valueOfInputField.slice(-1) === placeholderOpenShortcut ? valueOfInputField.slice(0, -1) : valueOfInputField;

        return inputFieldValue + placeholderMarkupValue;
    }

    /**
     * Callback on placeholder selection.
     * @param placeholder
     */
    const placeholderCallback = (placeholder: FieldPlaceholder) => {

        const event = {
            target: {
                id: id,
                value: getValueWithAddedPlaceholder(placeholder),
            }
        };
        handleInputFieldChange(event);
        props.addedPlaceHolderCallback && props.addedPlaceHolderCallback(placeholder);
    };

    const handlePlaceHolderMenuClose = () => {
        togglePlaceholder(false);
        if (valueOfInputField.slice(-1) === placeholderOpenShortcut) {
            setValueOfInputField(valueOfInputField.slice(0, -1));
        }
    };

    /**
     * Dynamic Mentions component with multiple resources.
     */
    const renderDynamicMentionsComponent = () => {
        return (
          <>
              <MentionsInput
                  singleLine={type !== 'textarea'}
                  disabled={fetchingFields || fetchingFields || props.disabled}
                  onBlur={props.onBlur}
                  className={classNames("dynamicPlaceholderInput", props.className)}
                  value={valueOfInputField}
                  // @ts-ignore
                  inputRef={inputRef}
                  readOnly={singleValueMode}
                  onChange={handleInputFieldChange}>
                  <Mention
                      trigger=""
                      className={styles.placeholderMention}
                      data={[]}
                      appendSpaceOnAdd={true}
                      markup={MARK_UP}
                  />
              </MentionsInput>
              {   !props.placeHolderButtonDisabled && (
                  <div className={styles.addPlaceHolderButton} onClick={() => togglePlaceholder(true)}>
                      + Variable (Press /)
                  </div>
              )}
              { isMenuOpen &&
              <DynamicPlaceholder
                  userPlaceholderList={users}
                  fieldPlaceHolderList={fields}
                  config={config}
                  callback={placeholderCallback}
                  onClose={handlePlaceHolderMenuClose} />
              }
          </>
      );
    };

    const placeholderInputClassNames = classNames(styles.placeholderInputWrapper,
        className,
        {
            [styles.placeholderInputRequired]: required,
        });

    return (
        <div className={placeholderInputClassNames}>
            <p>{label}</p>
            {
                props.singlePlaceholderConfig !== undefined ?
                    <InputFieldSinglePlaceholderComponent
                        {...props}
                        valueOfPlaceholderInputField={valueOfInputField}
                        onPlaceholderInputValueChange={handleInputFieldChange}
                        usersList={users}
                        singlePlaceholderConfig={props.singlePlaceholderConfig}
                    /> :
                    renderDynamicMentionsComponent()
            }
        </div>
    );
};

export interface PropTypes extends DefaultPropTypes {
    placeHolderButtonDisabled?: boolean;
    config?: DynamicPlaceholderConfig;
    singlePlaceholderConfig?: SinglePlaceholderConfig;
    singleValueMode?: boolean; // Allowing only single value in the input box.
    addedPlaceHolderCallback?: (placeholder: FieldPlaceholder) => void; // Callback whenever a placeholder is selected.
};
