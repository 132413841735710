import {Form} from 'antd';
import {PushNotificationDetails} from "@eazy2biz/common-util";
import {ChangeEventHandler, useState} from "react";
import {cloneDeep} from "lodash";
import {
    AssigneeSelect,
    DynamicPlaceholderConfig,
    InputFieldWithPlaceholder,
} from "@eazy2biz-ui/common-components";
import {ActionComponentProps} from "../interfaces/ActionComponentProps";
import styles from '../actionForm.module.css';

const validateMessages = {
    required: '${label} is required!'
};

/**
 *
 * @param props
 * @constructor
 */
export const PushNotificationActionComponent = (props: ActionComponentProps) => {

    const pushNotificationDetails = props.actionResult.resultDetails as PushNotificationDetails;

    const [toLocalUserIds, setToLocalUserIds] = useState(pushNotificationDetails.toLocalUserIds || []);
    const [title, setTitle] = useState(pushNotificationDetails.title || '');
    const [body, setBody] = useState(pushNotificationDetails.body || '');

    const placeholderConfig = new DynamicPlaceholderConfig(true, true);
    placeholderConfig.setFormFields(props.fields);
    placeholderConfig.stages = props.stages;

    const handleTitleChange: ChangeEventHandler<any> = (event) => {
        setTitle(event.target.value);
    };

    const handleBodyChange: ChangeEventHandler<any> = (event) => {
        setBody(event.target.value);
    };

    const handleSave = (e: any) => {
        e.stopPropagation();
        const updatedPushNotificationDetails: PushNotificationDetails = {
            toLocalUserIds,
            title,
            body,
            enabled: true,
        };
        const updatedAction = cloneDeep(props.actionResult);
        updatedAction.resultDetails = updatedPushNotificationDetails;
        props.onActionResultUpdate(updatedAction);
    };

    return(
        <>
            <Form validateMessages={validateMessages}>
                <Form.Item name={['to']} rules={[{ required: true }]} className={styles.actionDetailsPlaceholderItem}>
                    <AssigneeSelect
                        onAssigneesSelect={setToLocalUserIds}
                        selectedAssignees={toLocalUserIds}
                        multiSelect />
                </Form.Item>
                <Form.Item name={['title']} rules={[{ required: true } ]} className={styles.actionDetailsFormItem}>
                    <InputFieldWithPlaceholder
                        config={placeholderConfig}
                        id={'title'}
                        label={'Title:'}
                        type={'text'}
                        onChange={handleTitleChange}
                        initialValue={title}
                        onBlur={handleSave}
                        required
                    />
                </Form.Item>
                <Form.Item name={['body']} rules={[{ required: true } ]} className={styles.actionDetailsFormItem}>
                    <InputFieldWithPlaceholder
                        config={placeholderConfig}
                        id={'body'}
                        label={'Body:'}
                        type={'text'}
                        onChange={handleBodyChange}
                        initialValue={body}
                        onBlur={handleSave}
                        required
                    />
                </Form.Item>
            </Form>
        </>
    );
};
