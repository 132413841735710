import { Field, LocalUserEntity, Stage } from "@eazy2biz/common-util";
import { UserPlaceholderProperties } from "./UserPlaceholderProperties";
import { PlaceholderFieldType } from "../entity/PlaceholderFieldType";

export class DynamicPlaceholderConfig {
    usersEnabled: boolean = true;
    formFieldsEnabled: boolean = false;
    formFields: Field[] = [];
    itemSystemFieldsEnabled: boolean = false;
    systemFieldsEnabled: boolean = false;
    databaseFieldsEnabled: boolean = false;
    itemStageSystemFieldsEnabled: boolean = false;
    filterUserComparator: (user: LocalUserEntity) => boolean = (user) => true;
    userProperties: object = UserPlaceholderProperties;
    stages: Stage[] = [];
    fieldType: PlaceholderFieldType = PlaceholderFieldType.TEXT;
    filterFieldComparator: (field: Field) => boolean = (fields) => true;

    constructor(
        usersEnabled: boolean = true,
        formFieldsEnabled: boolean = false,
        databaseFieldsEnabled: boolean = false,
        itemSystemFieldsEnabled: boolean = true,
        systemFieldsEnabled: boolean = true,
        itemStageSystemFieldsEnabled: boolean = true
    ) {
        this.usersEnabled = usersEnabled;
        this.formFieldsEnabled = formFieldsEnabled;
        this.itemSystemFieldsEnabled = itemSystemFieldsEnabled;
        this.systemFieldsEnabled = systemFieldsEnabled;
        this.databaseFieldsEnabled = databaseFieldsEnabled;
        this.itemStageSystemFieldsEnabled = itemStageSystemFieldsEnabled;
    }

    setFieldType(fieldType: PlaceholderFieldType) {
        this.fieldType = fieldType;
    }

    setFormFields(fields: Field[]) {
        this.formFields = fields;
    }

    setUserProperties(properties: object) {
        this.userProperties = properties;
    }

    setUserFilterComparator(filter: (user: LocalUserEntity) => boolean) {
        this.filterUserComparator = filter;
    }

    setFieldFilterComparator(filter: (field: Field) => boolean) {
        this.filterFieldComparator = filter;
    }

    getFilteredFields(): Field[] {
        return this.formFields.filter(this.filterFieldComparator);
    }
}
