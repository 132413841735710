import {StageData} from "../../../entity/workflowBuilder/StageData";
import FormNode from "./FormNode";
import ReadOnlyFormNode from "./ReadOnlyFormNode";
import {memo} from "react";

export type NodeProps = {
    data: StageData;
};

const GenericStageNode = memo((props: NodeProps) => {
  return props.data.props.isReadOnly? ReadOnlyFormNode(props) : FormNode(props);
});

export default GenericStageNode;