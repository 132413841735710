import { AlertModal } from "./AlertModal";
import { createContext, useContext, useState } from "react";
import {AlertModalConfig} from "./AlertModalConfig";

/**
 * Alert modal provider.
 * @param props
 * @constructor
 */
export const AlertModalProvider = (props: any) => {
  const [alertModalConfig, setAlertModalConfig] = useState<AlertModalConfig | undefined>();

  return (
    <AlertModalContext.Provider value={{alertModalConfig, setAlertModalConfig}}>
      {props.children}
      <AlertModal />
    </AlertModalContext.Provider>
  );
};


type AlertModalContext = {
  alertModalConfig?: AlertModalConfig,
  setAlertModalConfig: (modalConfig?: AlertModalConfig) => void,
};

const initialState: AlertModalContext = {
  setAlertModalConfig: () => {},
};

const AlertModalContext = createContext(initialState);
export const useAlertModal = () => useContext(AlertModalContext);
