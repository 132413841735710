import {
    GenericButton,
    InputField,
    Toast,
    GenericModal,
    AssigneeSelect
} from "@eazy2biz-ui/common-components";
import {
    ERROR_STRINGS,
    INVITE_USER_MODAL_TITLE,
    SEND_INVITE_BUTTON,
    SUCCESS_STRINGS,
    INVITE_USER
} from "../../contents/DisplayContent";
import React, { useState } from "react";
import { inviteUser } from "../services/UserInvitesService";
import { ErrorTypes, GenericException, getCurrentCompanyId } from "@eazy2biz/common-package-ui";
import { UserInviteResponse } from "@eazy2biz/common-util";
import styles from './InviteUserModal.module.css'
/**
 * Modal for inviting user.
 * @param props
 * @constructor
 */
export const InviteUserModal = (props: PropTypes) => {

    const [inviteEmail, setInviteEmail] = React.useState<string>();
    const [inviteUserName, setInviteUserName] = React.useState<string>();
    const companyId = getCurrentCompanyId();
    const [userGroup, setUserGroup] = useState<string[]>([]);



    const handleFieldsChange = (e: any) => {
        switch (e.target.id) {
            case "invite-user-email": setInviteEmail(e.target.value); break;
            case "invite-user-name": setInviteUserName(e.target.value); break;
        }
    };

    const handleUGChange = (localIds: string[], ugIds: string[] = []) => {
        setUserGroup(ugIds);
    };

    const inviteNewUser = () => {
        if (inviteUserName && inviteEmail && companyId && userGroup) {
            inviteUser(inviteUserName, inviteEmail, userGroup, [], {}).then((response: UserInviteResponse) => {
                props.onClose();
                Toast.success(SUCCESS_STRINGS.USER_INVITE_SUCCESS);
            }).catch(e => {
                Toast.error(new GenericException(ErrorTypes.API_ERROR, '', ERROR_STRINGS.USER_INVITE_FAILURE), ERROR_STRINGS.USER_INVITE_FAILURE);
            });
        }
    };

    const renderBody = () => {
        return (
            <div className={styles.inviteUserfield}>
                <InputField id={'invite-user-email'} label={INVITE_USER.EMAIL_ADDRESS} type={'text'} onChange={handleFieldsChange} />
                <InputField id={'invite-user-name'} label={INVITE_USER.NAME} type={'text'} onChange={handleFieldsChange} />
                <AssigneeSelect selectedAssignees={userGroup} onAssigneesSelect={handleUGChange}  userGroups={true} localUser ={false}/>
            </div>
        );
    };

    const renderFooter = () => {
      return <GenericButton onClick={inviteNewUser} buttonText={SEND_INVITE_BUTTON} />;
    };

    return (
        <GenericModal show={props.showModal} title={INVITE_USER_MODAL_TITLE} onClose={props.onClose} bodyContent={renderBody} footerContent={renderFooter} />
    );
};

type PropTypes = {
    showModal: boolean;
    onClose: () => void;
}
