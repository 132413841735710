import { Field, FieldTypes } from "@eazy2biz/common-util";
import { PlaceholderOption } from "../entity/PlaceholderOption";
import { get } from "lodash";
import { getCustomerTableById } from "../../../services/CustomerTableService";
import { DynamicPlaceholderConfig } from "../config/DynamicPlaceholderConfig";

/**
 * Converts Form Fields to placeholder list.
 * @param formFields
 */
export const convertFieldList = (formFields: Field[]): PlaceholderOption[] => {
    return formFields.map(formField => {
        return {
            key: formField._id,
            name: formField.name
        } as PlaceholderOption;
    });
};

export const allowedFieldTypePlaceholder = [FieldTypes.DATABASE, FieldTypes.EMPLOYEE];

export const getPlaceholderOptionsForField = (field: Field,  config: DynamicPlaceholderConfig): Promise<PlaceholderOption[]> => {
    switch (field.type) {
        case FieldTypes.EMPLOYEE:
            return Promise.resolve(Object.keys(config.userProperties).map((key) => ({
                key,
                name: get(config.userProperties, key)
            })));
        case FieldTypes.DATABASE:
            return getPlaceholderOptionsForDatabaseField(field);
        default:
            return Promise.resolve([]);
    }
};

export const getPlaceholderOptionsForDatabaseField = async (field: Field): Promise<PlaceholderOption[]> => {
    if (field.fieldConfigurations?.groupCorrelationKey) {
        const table = await getCustomerTableById(field.fieldConfigurations?.groupCorrelationKey);
        if (table) {
            return table.tableHeaders.map((tableHeader) => ({
                key: tableHeader._id,
                name: tableHeader.name
            }));
        }
    }

    return Promise.resolve([]);
};
