const setItemInLocalStorage = (key: string, value: string) => {
    localStorage.setItem(key, value);
};

const getItemFromLocalStorage = (key: string): string | null => {
    return localStorage.getItem(key);
};

const removeItemFromLocalStorage = (key: string) => {
    localStorage.removeItem(key);
};

export const LocalStorageService = {
    setItem: setItemInLocalStorage,
    getItem: getItemFromLocalStorage,
    removeItem: removeItemFromLocalStorage,
};
