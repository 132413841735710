// TO DO
export const TODO_INFO = 'Todo are tasks created for all the employees in your company.';

// WORKFLOW BUILDER
// ACTIONS
export const ENTRY_ACTIONS = 'These are automated actions which will be triggered when the item enters this stage.';
export const EXIT_ACTIONS = 'These are automated actions which will be triggered when the item leaves this stage.';
export const SLA_ACTIONS = 'These are automated actions which will be triggered when no action is taken by the assignee within due time.';


// WORKFLOWS
export const WORKFLOW_INFO = 'Create your own Workflow to automate tasks for your business processes.';

// CUSTOMER TABLES
export const TABLE_INFO = 'Create your own Table to store business entities like products, customers, vendors etc.';
export const RECORD_INFO = 'Find your records here for the table.';
