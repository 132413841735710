import {
    ActivityCreateRequest,
    ActivityGroupIdentifierRequest,
    ActivityType,
    EntityType
} from "@eazy2biz/common-util";
import {StrictBuilder} from "builder-pattern";

export const getCardActivityPayload = (itemId: string, workflowId: string): ActivityGroupIdentifierRequest => {
    return {
        activityType: ActivityType.AUDIT,
        entityType: EntityType.CARD,
        entityId: itemId,
        entityClassId: workflowId
    };
};

export const getCardCommentPayload = (cardId: string, workflowId: string): ActivityGroupIdentifierRequest => {
    return {
        activityType: ActivityType.COMMENT,
        entityType: EntityType.CARD,
        entityId: cardId,
        entityClassId: workflowId
    };
};

export const addCommentPayload = (message: string, cardId: string, workflowId: string): ActivityCreateRequest => {
    return StrictBuilder<ActivityCreateRequest>()
        .activityType(ActivityType.COMMENT)
        .entityType(EntityType.CARD)
        .entityId(cardId)
        .entityClassId(workflowId)
        .message(message)
        .build();
};
