import {CustomerTableHeader, CustomerTableHeaderTypes, CustomerTableResponse} from "@eazy2biz/common-util";
import {TableBuilderPaletteConfig} from "@eazy2biz-ui/common-components";
import uuid from "react-uuid";
import {CustomerTableBuilderStateType} from "../components/customerTableColumnsBuilder/CustomerTableColumnsBuilder";
import {TABLE_COLUMNS_BUILDER, TABLE_HEADER_DEFAULT_NAMES} from "../../contents/DisplayContent";

export const getCustomerTableHeaderFromPaletteId = (id: string): CustomerTableHeader => {
    const paletteItem = TableBuilderPaletteConfig.find((item) => item.id === id);
    return getDefaultTableHeaderForType(paletteItem?.type || CustomerTableHeaderTypes.TEXT);
};

export const getDefaultTableHeaderForType = (type: CustomerTableHeaderTypes): CustomerTableHeader => {
    return {
        _id: uuid(),
        name: TABLE_HEADER_DEFAULT_NAMES[type],
        headerType: type,
        helpText: type.toString().toLocaleLowerCase(),
        validations: []
    };
};

const getDefaultTableTitleHeader = () => {
    const tableHeader = getDefaultTableHeaderForType(CustomerTableHeaderTypes.TEXT);
    tableHeader.name = TABLE_COLUMNS_BUILDER.TITLE_HEADER;
    tableHeader.helpText = TABLE_COLUMNS_BUILDER.TITLE_HEADER_HELP_TEXT;

    return tableHeader;
};

export const getDefaultTableBuilderState = (): CustomerTableBuilderStateType => {
    const tableHeader = getDefaultTableTitleHeader();
    return {
        formConfigurationsVisible: false,
        tableHeaders: [tableHeader],
        title: TABLE_COLUMNS_BUILDER.UNTITLED_TABLE,
        titleHeaderId: tableHeader._id,
      };
    };
    
export const getTableBuilderState = (customerTable: CustomerTableResponse): CustomerTableBuilderStateType => {
  return {
    formConfigurationsVisible: false,
    tableHeaders: customerTable.tableHeaders,
    title: customerTable.details.name,
    titleHeaderId: customerTable.titleTableHeaderId
  }
}
