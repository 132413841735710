import {CookieKeys} from "./CookieKeys";
import {CookieSetOptions} from "universal-cookie/cjs/types";
import {getEnvironmentConfig} from "../context/ApplicationContext";

export class CookiesService {
    private readonly cookies: any;
    private readonly setCookie: Function;
    private readonly removeCookie: Function;
    private sessionMap: Map<CookieKeys, any> = new Map<CookieKeys, any>();

    private static INSTANCE: CookiesService;

    private constructor(
        cookies: any,
        setCookie: Function,
        removeCookie: Function
    ) {
        this.cookies = cookies;
        this.setCookie = setCookie;
        this.removeCookie = removeCookie;
    }

    static init(
        cookies: any,
        setCookie: Function,
        removeCookie: Function
    ) {
        if (!this.INSTANCE) {
            this.INSTANCE = new CookiesService(cookies, setCookie, removeCookie);
        }
    }

    static getInstance(): CookiesService {
        return this.INSTANCE;
    }

    getFromCookies = (key: CookieKeys): any => {
        if (this.sessionMap.has(key)) {
            return this.sessionMap.get(key);
        }
        return this.cookies[key.toString()];
    }

    setToCookies = (key: CookieKeys, value: any, options: CookieSetOptions = {}): void => {
        this.sessionMap.set(key, value);
        this.setCookie(key.toString(), value, {
            path: '/',
            maxAge: getEnvironmentConfig().cacheExpiry.hardExpiry,
            ...options
        });
    }

    removeFromCookies = (key: CookieKeys): void => {
        this.sessionMap.delete(key);
        this.removeCookie(key.toString());
    }

    /**
     * Deletes all cookies.
     */
     removeAllCookies = (): void => {
         this.sessionMap = new Map<CookieKeys, any>();
        for (const key in CookieKeys) {
            // @ts-ignore
            this.removeFromCookies(CookieKeys[key.toString()])
        }
    }
}
