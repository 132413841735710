import {
    EntityDefinitionTypes,
    SchedulableTriggerCategory,
    ScheduledTaskType,
    WorkflowResponse
} from "@eazy2biz/common-util";
import { GenericTriggersComponent } from "@eazy2biz-ui/common-components";
import { CreateWorkflowTriggerDetails } from "./createWorkflowTriggerDetails/CreateWorkflowTriggerDetails";

/**
 * My Items Tab Component
 * @param props
 * @constructor
 */
const WorkflowSchedulersTabComponent = (props: PropTypes) => {
    const {workflow} = props;

    const renderScheduleTaskDetails = (taskDetails: any, setTaskDetails: (taskDetails: any) => void, readOnly: boolean) => {
        return (
          <CreateWorkflowTriggerDetails
            workflow={workflow}
            taskDetails={taskDetails}
            setTaskDetails={setTaskDetails}
            readOnly={readOnly}
          />
        );
    };

    return(
      <>
        <GenericTriggersComponent
            name={workflow.details.name}
            schedulableTriggerCategory={SchedulableTriggerCategory.WORKFLOW}
            schedulableTriggerType={EntityDefinitionTypes.USER_DEFINED}
            scheduledTaskType={ScheduledTaskType.EXECUTE_ACTION}
            renderScheduleTaskDetails={renderScheduleTaskDetails}
            referenceId={workflow._id}
        />
      </>
    );
};

type PropTypes = {
    workflow: WorkflowResponse;
};

export default WorkflowSchedulersTabComponent;
