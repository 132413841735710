// react-step-progress bar doesn't support typescript
import { Steps } from 'antd';

const { Step } = Steps;

export const GenericStepProgressBar = (props: PropsType) => {
  const {steps, stepIndex} = props;

  /**
   * Depending on the number of steps
   * determine the width of the steps indicator
   */
  return (
    <Steps size='small' current={stepIndex} style={{colorScheme: 'dark', width: (steps.length * 20) + '%'}}>
      {
        steps.map((step: StepsInfo, idx: number) => <Step key={idx} title={step.title} />)
      }
    </Steps>
  );
};

export type StepsInfo = { 
  title: string;
  description?: string;
};

type PropsType = {
  steps: StepsInfo[];
  stepIndex: number;
};
