import React, {useState} from "react";
import { BackNavigationLink, GenericHeader, InfoWrapper } from "@eazy2biz-ui/common-components";
import styles from './CustomerTableHomepage.module.css';
import {CustomerTableList} from "../customerTableList/CustomerTableList";
import {TableLanding} from "../tableLanding/TableLanding";
import {CustomerTableResponse} from "@eazy2biz/common-util";
import { TABLE_INFO } from "@eazy2biz-ui/common-package";
import { CUSTOMER_TABLE_HOMEPAGE_STRINGS } from "../../../contents/DisplayContent";

/**
 * Customer Table Homepage
 * @constructor
 */
export const CustomerTableHomepage = () => {
    const [selectedTable, setSelectedTable] = useState<CustomerTableResponse>();

    const renderSubHeader = (): JSX.Element => {
        return <BackNavigationLink/>
    };

    return (
        <div className={styles.container}>
            <GenericHeader
              header={<InfoWrapper infoContent={TABLE_INFO}>{CUSTOMER_TABLE_HOMEPAGE_STRINGS.TITLE}</InfoWrapper>}
              subHeader={renderSubHeader} />
            <CustomerTableList selectedTable={selectedTable} setSelectedTable={setSelectedTable} />
            <TableLanding customerTable={selectedTable} />
        </div>
    );
};
