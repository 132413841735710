import {Elements} from "react-flow-renderer/dist/types";
import {
    getDefaultStageProps,
    getNewStageConfiguration, getNewStageOtherConfiguration
} from "../../../helpers/workflowBuilderHelpers/runtimeHelpers/StageConfigurationHelper";
import {StageTypes} from "@eazy2biz/common-util";

export const START_STAGE_ID: string = 'STAGE:START';
export const END_STAGE_ID: string = 'STAGE:END';

const defaultStartStageLabel = 'Start Form';
const defaultEndStageLabel = 'Completed';

export const initialStages = (): Elements<Node> => [
    {
        id: START_STAGE_ID,
        type: 'START_NODE',
        data: {
            // @ts-ignore
            id: START_STAGE_ID,
            details: {
                label: defaultStartStageLabel,
                type: StageTypes.ENTRY_STAGE,
                isLabel: false
            },
            stageConfiguration: getNewStageConfiguration(START_STAGE_ID, defaultStartStageLabel, StageTypes.ENTRY_STAGE, END_STAGE_ID),
            otherConfigurations: getNewStageOtherConfiguration(),
            props: getDefaultStageProps()
        },
    },
    {
        id: END_STAGE_ID,
        type: 'END_NODE',
        data: {
            // @ts-ignore
            id: END_STAGE_ID,
            details: {
                label: defaultEndStageLabel,
                type: StageTypes.EXIT_STAGE,
                isLabel: false
            },
            stageConfiguration: getNewStageConfiguration(END_STAGE_ID, defaultEndStageLabel, StageTypes.EXIT_STAGE),
            otherConfigurations: getNewStageOtherConfiguration(),
            props: getDefaultStageProps()
        }
    }
];
