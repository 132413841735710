import {workflowIdPath} from './Constants';
import {SearchTypes} from "@eazy2biz/common-util";

export const getWorkflowIdFilter = (workflowId?: string) => {
  if (workflowId) {
    return [
      {
        type: SearchTypes.VALUE_EQUAL,
        field: workflowIdPath,
        value: workflowId
      }
    ];
  }

  return [];
};
