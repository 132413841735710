import {AggregationResult, ChartType, PieChartDetails, ReportResponse} from "@eazy2biz/common-util";
import {Moment} from 'moment'
import React from "react";
import {GenericPieChart} from "@eazy2biz-ui/common-components";
import {transformChartData} from "../../helpers/ChartHelper";

export const PieChartComponent = (
    props: {
        reportResponse : ReportResponse,
        pieChartDetails : PieChartDetails,
        chartStartDate : Moment,
        chartEndDate : Moment,
        chartData: AggregationResult[]
    }

): JSX.Element => {

    const {
        reportResponse,
        pieChartDetails,
        chartStartDate,
        chartEndDate,
        chartData
    } = props;

    return (
        <GenericPieChart
            data={transformChartData(chartData, ChartType.PIE, pieChartDetails)}
            groupingFieldKey={'groupingFieldValue'}
            aggregationValueKey={'aggregationValue'}
        />
    );
}
