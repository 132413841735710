import {PlaceholderOptionsList} from "../PlaceholderOptionsList";
import {FieldPlaceholder, FieldPlaceholderType} from "@eazy2biz/common-util";
import {Component} from "react";
import { PlaceholderOption } from "../../entity/PlaceholderOption";
import {DynamicPlaceholderConfig} from "../../config/DynamicPlaceholderConfig";
import {clone} from "lodash";

export enum Level {
    ENTITY_LEVEL = 0,
    KEY_LEVEL = 1
}

export interface BaseState extends Readonly<any>{
    level: Level;
    options: PlaceholderOption[];
    headerString: string;
    icon?: JSX.Element;
    placeholder: FieldPlaceholder;
}

export interface BaseProps extends Readonly<any>{
    config: DynamicPlaceholderConfig;
    goBack: () => void;
    callback: (placeholderString: FieldPlaceholder) => void;
}

export abstract class BasePlaceholderMenu<PROPS extends BaseProps, STATE extends BaseState> extends Component<PROPS, STATE> {
    protected constructor(props: PROPS) {
        super(props);
        // @ts-ignore
        this.state = {
            level: Level.ENTITY_LEVEL,
            options: this.getPlaceholderList(),
            headerString: this.getPlaceholderHeaderString(),
            icon: this.getIcon(),
            placeholder: this.getBasePlaceholder()
        };
    }

    handleGoBack = () => {
        if (this.state.level === Level.ENTITY_LEVEL) {
            this.props.goBack();
        } else {
            this.setState({
                level: Level.ENTITY_LEVEL,
                placeholder: this.getBasePlaceholder(),
                options: this.getPlaceholderList(),
                icon: this.getIcon()
            });
        }
    };

    getEntityLevelPlaceholder = (placeholder: FieldPlaceholder, value: string) => {
        const updatedPlaceholder: FieldPlaceholder = new FieldPlaceholder();
        updatedPlaceholder.placeholderType = placeholder.placeholderType;
        updatedPlaceholder.entityClassId = placeholder.entityClassId;
        updatedPlaceholder.entityId = value;
        updatedPlaceholder.key = placeholder.key;
        updatedPlaceholder.subKey = placeholder.subKey;
        return updatedPlaceholder;
    };

    getFinalPlaceholder(placeholder: FieldPlaceholder, value: string): FieldPlaceholder {
        const updatedPlaceholder: FieldPlaceholder = new FieldPlaceholder();
        updatedPlaceholder.placeholderType = placeholder.placeholderType;
        updatedPlaceholder.entityClassId = placeholder.entityClassId;
        updatedPlaceholder.entityId = placeholder.entityId;
        updatedPlaceholder.key = value;
        updatedPlaceholder.subKey = placeholder.subKey;
        return updatedPlaceholder;
    };

    handleOnPick = (key: string, name: string = '') => {
        const placeholder: FieldPlaceholder = clone(this.state.placeholder);

        if (this.state.level === Level.ENTITY_LEVEL) {
            this.setState({
                placeholder: this.getEntityLevelPlaceholder(placeholder, key),
                level: Level.KEY_LEVEL,
                options: this.transformEntityToOption(key),
                icon: undefined,
                headerString: name,
            });
        } else {
            this.props.callback(this.getFinalPlaceholder(placeholder, key));
        }
    };

    getBasePlaceholder(): FieldPlaceholder {
        const placeholder: FieldPlaceholder = new FieldPlaceholder();
        placeholder.placeholderType = this.getPlaceholderType();
        placeholder.entityClassId = '';
        placeholder.entityId = '';
        placeholder.key = '';
        placeholder.subKey = '';
        return placeholder;
    };

    abstract getPlaceholderType(): FieldPlaceholderType;
    abstract getPlaceholderList(): PlaceholderOption[];
    abstract getPlaceholderHeaderString(): string;
    abstract getIcon(): JSX.Element;
    abstract transformEntityToOption(key: string): PlaceholderOption[];

  render() {
      const {icon, headerString, options} = this.state;

      return <PlaceholderOptionsList icon={icon} goBack={this.handleGoBack} headerTitle={headerString} placeholderOptions={options} onPick={this.handleOnPick} />
  };

}
