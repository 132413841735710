import { RestServices } from "@eazy2biz/common-package-ui";
import {
    getAddLocalUserToUserGroupRequest,
    getAddUserGroupToUserGroupRequest,
    getUserGroupCreateRequest,
    getRemoveLocalUserFromUserGroupRequest, getRemoveUserGroupFromUserGroupRequest
} from "./helpers/UserGroupServiceHelper";
import {UserGroupResponse, UserGroupType} from "@eazy2biz/common-util";

const USER_GROUP_SERVICE = () => RestServices.getInstance().getIdentitiesService().getUserGroupService();

export const addLocalUserToUserGroup = (
    localUserId: string,
    userGroupId: string
) : Promise<UserGroupResponse> => {
    return USER_GROUP_SERVICE()
        .addLocalUserToUserGroup(
            getAddLocalUserToUserGroupRequest(localUserId, userGroupId)
        );
}

export const removeLocalUserFromUserGroup = (
    localUserId: string,
    userGroupId: string
) : Promise<UserGroupResponse> => {
    return USER_GROUP_SERVICE()
        .removeLocalUserFromUserGroup(
            getRemoveLocalUserFromUserGroupRequest(localUserId, userGroupId)
        );
}

export const addUserGroupToUserGroup = (
    userGroupId: string,
    parentUserGroupId: string
) : Promise<UserGroupResponse> => {
    return USER_GROUP_SERVICE()
        .addUserGroupToUserGroup(
            getAddUserGroupToUserGroupRequest(userGroupId, parentUserGroupId)
        );
}

export const removeUserGroupFromUserGroup = (
    userGroupId: string,
    parentUserGroupId: string
) : Promise<UserGroupResponse> => {
    return USER_GROUP_SERVICE()
        .removeUserGroupFromUserGroup(
            getRemoveUserGroupFromUserGroupRequest(userGroupId, parentUserGroupId)
        );
}

export const createUserGroup = (
    name: string,
    description: string,
    userGroupType: UserGroupType,
) : Promise<UserGroupResponse> => {
    return USER_GROUP_SERVICE()
        .createUserGroup(
            getUserGroupCreateRequest(name, description, userGroupType)
        );
}
