import {InputField} from "@eazy2biz-ui/common-components";
import {companyNameField, descriptionField, emailField} from "../../constants/keys";

/**
 * Company Details
 * @param props
 * @constructor
 */
const CompanyDetails: (props: PropsType) => JSX.Element = (props: PropsType): JSX.Element => {
    return (
        <form>
            <InputField
                id={companyNameField}
                label={'Company Name'}
                type={"text"}
                onChange={props.handleFieldsChange}
            />

            <InputField
                id={emailField}
                label={"Email Id"}
                type={"email"}
                onChange={props.handleFieldsChange}
            />

            <InputField
                id={descriptionField}
                label={'Description'}
                type={"text"}
                onChange={props.handleFieldsChange}
                required={false}
            />
        </form>
    );
}

type PropsType = {
    handleFieldsChange: (e: any) => void;
};

export default CompanyDetails;