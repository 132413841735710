import {CustomerTableResponse} from "@eazy2biz/common-util";
import {RecordsTable} from "./recordsTable/RecordsTable";
import {RECORDS_LANDING_STRINGS} from "../../../contents/DisplayContent";
import {GenericButton, GenericButtonTypes, GenericHeader, InfoWrapper} from "@eazy2biz-ui/common-components";
import React, { useState } from "react";
import {RecordFormModal} from "../recordForm/RecordFormModal";
import styles from './TableLanding.module.css';
import { RECORD_INFO } from "@eazy2biz-ui/common-package";
import { CustomerTableColumnsBuilder } from "../customerTableColumnsBuilder/CustomerTableColumnsBuilder";

/**
 * Table Landing
 * @param props
 * @constructor
 */
export const TableLanding = (props: PropTypes) => {
  if (!props.customerTable) {
    return null;
  }

  const [createRecordModal, setCreateRecordModal] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [editTable, setEditTable] = useState(false)

  const handleSubmit = () => {
    toggleCreateRecord();
    setRefreshTable(true);
  };

  const toggleCreateRecord = () => {
    setCreateRecordModal(!createRecordModal);
  };
  
  const toggleEditTable = () => {
    setEditTable(!editTable)
  }

  const handleTableEditSuccess = () => {
    toggleEditTable()
  }

  const toggleEditTableBuilder = () => {
    toggleEditTable()
  }

  const renderSectionButtons = (): JSX.Element => {
    return (
      <>
        <GenericButton buttonText={RECORDS_LANDING_STRINGS.EDIT_TABLE} onClick={toggleEditTable} type={GenericButtonTypes.SECONDARY} />
        <GenericButton buttonText={RECORDS_LANDING_STRINGS.CREATE_RECORD} onClick={toggleCreateRecord} />
      </>
    )
  }

    return (
        <div className={styles.tableLandingWrapper}>
            <GenericHeader
              header={
                <InfoWrapper infoContent={RECORD_INFO}>{RECORDS_LANDING_STRINGS.RECORDS}</InfoWrapper>
              }
              sectionButtons={renderSectionButtons}
            />
            <RecordsTable
              customerTable={props.customerTable}
              refreshTable={refreshTable}
              setRefreshTable={setRefreshTable}
        />
        {editTable &&
          <CustomerTableColumnsBuilder
            editMode
            customerTable={props.customerTable}
            onSuccess={handleTableEditSuccess}
            onToggleFormBuilder={toggleEditTableBuilder}
          />
        }
            {createRecordModal &&
                <RecordFormModal
                    tableId={props.customerTable._id.toString()}
                    onSubmit={handleSubmit}
                    onClose={toggleCreateRecord}/>
            }
        </div>
    );
};

type PropTypes = {
    customerTable?: CustomerTableResponse;
};