import { DropdownSelect, GenericButton, GenericModal, MenuData, Toast } from "@eazy2biz-ui/common-components";
import { Elements } from "react-flow-renderer/dist/types";
import { useState } from "react";
import { Edge, FlowElement } from "react-flow-renderer/nocss";
import { EdgeData } from "../../../entity/workflowBuilder/EdgeData";
import {
  findElementById,
  getEdgesForTarget,
  getEdgesFromSource,
  getFilteredElementsMap,
  getStartStageFromStages,
  updateElementById
} from "../../../helpers/workflowBuilderHelpers/runtimeHelpers/WorkflowBuilderFlowHelper";
import { StageData } from "../../../entity/workflowBuilder/StageData";
import { cloneDeep } from "lodash";
import { StageTypes } from "@eazy2biz/common-util";
import uuid from "react-uuid";

/**
 * Modal Component to change the stage connections.
 * @param props
 * @constructor
 */
export const EdgeConnectionComponent = (props: PropTypes) => {
  const selectedEdge: Edge<EdgeData> = findElementById(props.edges, props.edgeId) as Edge<EdgeData>;

  const [allowedTargetStages] = useState<Elements>(getAllowedTargetStages(props.stages, props.edges, selectedEdge));

  if (
    !selectedEdge ||
    !isConnectionChangeAllowed(selectedEdge, props.stages, props.edges) ||
    allowedTargetStages.length < 2
  ) {
    Toast.warn('This is not allowed.');
    props.onClose();
    return null;
  }

  const [selectedStageId, setSelectedStageId] = useState(selectedEdge.target);

  const handleUpdate = () => {
    // Do not update if no change.
    if (selectedStageId !== selectedEdge.target) {
      // Update the target for the current selected edge.
      const updatedEdges: Elements<EdgeData> = updateElementById(
        cloneDeep(props.edges), props.edgeId, selectedStageId, 'target');

      const sourceStage: FlowElement<StageData> = findElementById(props.stages, selectedEdge.source);

      let updatedStages: Elements<StageData> = cloneDeep(props.stages);

      // Update nextStage of source only if it was the positive edge.
      if (sourceStage.data?.stageConfiguration.nextStage === selectedEdge.target) {
        updatedStages = updateElementById(
          updatedStages, selectedEdge.source, selectedStageId, 'data.stageConfiguration.nextStage');
      }

      const targetStage: FlowElement<StageData> = findElementById(props.stages, selectedEdge.target);

      // Remove end stage if dangling end stage is left after the update.
      if (
        targetStage.data?.stageConfiguration.type === StageTypes.EXIT_STAGE &&
        getEdgesForTarget(props.edges as Edge[], targetStage.id).length === 1
      ) {
        updatedStages = updatedStages.filter((stage) => stage.id !== targetStage.id);
      }

      // Check if the selected stage is the newly added exit stage.
      try {
        findElementById(props.stages, selectedStageId);
      } catch (e) {
        // Add new exit stage if selected.
        updatedStages.push(findElementById(allowedTargetStages, selectedStageId));
      }

      props.onUpdate(updatedStages, updatedEdges);
    }
    props.onClose();
  };

  const renderBody = () => {
    const menuData: MenuData[] = allowedTargetStages.map((stage) => ({
      name: stage.data?.details.label || '',
      _id: stage.id || '',
    }));

    return (
      <div>
        <DropdownSelect
          label={'Select stage'}
          menuData={menuData}
          selectedItem={selectedStageId}
          onItemSelect={setSelectedStageId} />
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <GenericButton buttonText={'Update'} onClick={handleUpdate} />
    );
  };

  return (
    <GenericModal
      show
      title={'Update connection'}
      bodyContent={renderBody}
      footerContent={renderFooter}
      onClose={props.onClose} />
  );
};

type PropTypes = {
  edgeId: string;
  stages: Elements<StageData>;
  edges: Elements<EdgeData>;
  onUpdate: (stages: Elements<StageData>, edges: Elements) => void;
  onClose: () => void;
};

/**
 * Returns the list of stages for which are potential targets.
 *
 * 1. Should not make a cycle.
 * 2. Labels cannot be targets
 * 3. Entry stage cannot be a target.
 * 4. Always add a new exit stage unless the current target is itself exit stage.
 * @param stages
 * @param edges
 * @param selectedEdge
 */
const getAllowedTargetStages = (
  stages: Elements<StageData>,
  edges: Elements<EdgeData>,
  selectedEdge: Edge<EdgeData>): Elements<StageData> => {
  const stageIdsFromStart = getStagesFromStart(stages, edges, selectedEdge.source).map(stage => stage.id);

  const allowedStages = stages.filter((stage) => {
    // Cannot make a cycle
    // Cannot connect to labels.
    // Cannot connect to entry stage.
    if (
      stageIdsFromStart.includes(stage.id) ||
      stage.data?.details.isLabel ||
      [StageTypes.ENTRY_STAGE].includes(stage.data?.details.type as StageTypes)
    ) {
      return false;
    }

    // Only show exit stage if it's the current target.
    if ([StageTypes.EXIT_STAGE].includes(stage.data?.details.type as StageTypes) && selectedEdge.target !== stage.id) {
      return false;
    }

    return true;
  });

  // If no exit stage is present, add a new exit stage.
  if (getFilteredElementsMap(allowedStages, [StageTypes.EXIT_STAGE]).size === 0) {
    const exitStages = getFilteredElementsMap(stages, [StageTypes.EXIT_STAGE]).values();
    const newExitStage: FlowElement<StageData> = cloneDeep(exitStages.next().value);
    newExitStage.id = uuid();

    allowedStages.push(newExitStage);
  }

  return allowedStages;
};

/**
 * Checks if the connection change is allowed for this edge.
 * @param selectedEdge
 * @param stages
 * @param edges
 */
const isConnectionChangeAllowed = (
  selectedEdge: Edge<EdgeData>,
  stages: Elements<StageData>,
  edges: Elements<EdgeData>): boolean => {
  const targetStage = findElementById(stages, selectedEdge.target);

  const endStages = getFilteredElementsMap(stages,  [StageTypes.EXIT_STAGE]);

  // If the target is end and only one.
  if (endStages.size > 1 && targetStage?.data?.details.type as StageTypes === StageTypes.EXIT_STAGE) {
    return true;
  }

  // @ts-ignore
  const edgesToTarget = getEdgesForTarget(edges, selectedEdge.target);

  return edgesToTarget.length > 1;
};

/**
 * fn to return all stages from start to target.
 * @param stages
 * @param edges
 * @param stageId
 */
const getStagesFromStart = (
  stages: Elements<StageData>,
  edges: Elements<EdgeData>,
  stageId: string
): Elements<StageData> => {
  const startStage = getStartStageFromStages(stages);
  let sourceStage: FlowElement<StageData> = findElementById(stages, stageId);
  if (sourceStage.data?.details.isLabel) {
    const parentEdges = getEdgesForTarget(edges as Edge[], sourceStage.id);
    stageId = parentEdges[0].source;
  }
  return dfsForParentStages(startStage, stageId, stages, edges);
};

/**
 * DFS fn to return all stages from start to target.
 * @param stage
 * @param targetStageId
 * @param stages
 * @param edges
 */
const dfsForParentStages = (
  stage: FlowElement<StageData>,
  targetStageId: string,
  stages: Elements<StageData>,
  edges: Elements<EdgeData>,
): Elements<StageData> => {

  if (stage.data?.stageConfiguration.type === StageTypes.EXIT_STAGE) {
    return [];
  }

  if (stage.id === targetStageId) {
    return [stage];
  }

  const outwardEdges: Edge[] = getEdgesFromSource(edges as Edge[], stage.id);

  let result: Elements<StageData> = [];

  outwardEdges.forEach(edge => {
    if (result.length === 0) {
      // @ts-ignore
      let nextStage: FlowElement<StageData> = findElementById(stages, edge.target);
      if (nextStage.data?.details.isLabel) {
        const labelOutwardEdge: Edge[] = getEdgesFromSource(edges as Edge[], nextStage.id);
        nextStage = findElementById(stages, labelOutwardEdge[0].target);
      }

      if (nextStage) {
        result = dfsForParentStages(nextStage, targetStageId, stages, edges);
      }
    }
  });

  return result.length ? [...result, stage]: [];
};

