import {
    AggregationResult, AreaChartDetails, BarChartDetails,
    CardResponse,
    ChartResponse,
    ChartType,
    GetChartDataRequest, LineChartDetails, PieChartDetails,
    ReportResponse, TableChartDetails
} from "@eazy2biz/common-util";
import {Moment} from "moment/moment";
import React, {useEffect, useState} from "react";
import {getChartDataByIdForReport} from "../services/ChartsService";
import {StrictBuilder} from "builder-pattern";
import styles from "./ChartComponent.module.css";
import {
    EmptyDataPlaceholderGraphic,
    GenericDateRangePicker,
    getDefaultDateRangePresets
} from "@eazy2biz-ui/common-components";
import {chartContent, CHART_DATA_NOT_FOUND, CHART_DURATION_NOT_SELECTED} from "../../contents/DisplayContent";
import {TableChartComponent} from "./charts/TableChartComponent";
import {AreaChartComponent} from "./charts/AreaChartComponent";
import {BarChartComponent} from "./charts/BarChartComponent";
import {PieChartComponent} from "./charts/PieChartComponent";
import {LineChartComponent} from "./charts/LineChartComponent";
import {Card, Skeleton} from "antd";
import {DEFAULT_VERBOSE_DATE_FORMAT} from "@eazy2biz/common-package-ui";
import classNames from "classnames";
import moment from "moment";

export const ChartComponent = (
    props: {
        reportResponse : ReportResponse,
        chartResponse : ChartResponse,
        reportStartDate : Moment,
        reportEndDate : Moment,
        editMode : boolean
    }

): JSX.Element => {

    const {reportResponse, chartResponse, reportStartDate, reportEndDate, editMode} = props;

    const [chartStartDate, setChartStartDate] = useState<Moment | null>(moment().subtract(moment.duration(chartResponse.defaultChartDuration)).startOf('day'));
    const [chartEndDate, setChartEndDate] = useState<Moment | null>(moment().endOf('day'));

    const [chartData, setChartData] = useState<any[] | null>(null);

    useEffect(() => {
        setChartStartDate(reportStartDate)
        setChartEndDate(reportEndDate)
    }, [reportStartDate, reportEndDate]);

    useEffect(() => {
        if(chartStartDate && chartEndDate) {
            getChartDataByIdForReport(
                StrictBuilder<GetChartDataRequest>()
                    .startDate(chartStartDate.toDate())
                    .endDate(chartEndDate.toDate())
                    .build(),
                chartResponse._id,
                reportResponse._id
            ).then(
                chartData => setChartData(chartData)
            )
        }
    }, [chartStartDate, chartEndDate])


    const getChartComponent = (chartStartDate: Moment, chartEndDate: Moment) => {
        switch (chartResponse.type) {
            case ChartType.LINE:
                return (
                    <LineChartComponent
                        reportResponse={reportResponse}
                        lineChartDetails={chartResponse.chartDetails as LineChartDetails}
                        chartStartDate={chartStartDate}
                        chartEndDate={chartEndDate}
                        chartData={chartData as unknown as AggregationResult[]}
                    />
                );
            case ChartType.AREA:
                return (
                    <AreaChartComponent
                        reportResponse={reportResponse}
                        areaChartDetails={chartResponse.chartDetails as AreaChartDetails}
                        chartStartDate={chartStartDate}
                        chartEndDate={chartEndDate}
                        chartData={chartData as unknown as AggregationResult[]}
                    />
                );
            case ChartType.BAR:
                return (
                    <BarChartComponent
                        reportResponse={reportResponse}
                        barChartDetails={chartResponse.chartDetails as BarChartDetails}
                        chartStartDate={chartStartDate}
                        chartEndDate={chartEndDate}
                        chartData={chartData as unknown as AggregationResult[]}
                    />
                );
            case ChartType.PIE:
                return (
                    <PieChartComponent
                        reportResponse={reportResponse}
                        pieChartDetails={chartResponse.chartDetails as PieChartDetails}
                        chartStartDate={chartStartDate}
                        chartEndDate={chartEndDate}
                        chartData={chartData as unknown as AggregationResult[]}
                    />
                );
            case ChartType.TABLE:
                return (
                    <TableChartComponent
                        reportResponse={reportResponse}
                        tableName={chartResponse.details.name}
                        tableChartDetails={chartResponse.chartDetails as TableChartDetails}
                        chartStartDate={chartStartDate}
                        chartEndDate={chartEndDate}
                        tableChartData={chartData as unknown as CardResponse[]}
                    />
                );

        }
    }

    return (
        <Card
            className={classNames(styles.chartCard, {[styles.editableChartCard] : editMode})}
            bodyStyle={{height : '100%', display: 'flex', flexDirection: "column"}}
        >
            <div className={styles.chartHeader}>
                <div className={styles.chartDetailsContainer}>
                    <div className={styles.chartName}>
                        {
                            chartResponse.details.name
                        }
                    </div>
                    {
                        chartResponse.details.description && (
                            <div className={styles.chartDescription}>
                                {
                                    chartResponse.details.description
                                }
                            </div>
                        )
                    }
                </div>
                <div className={styles.dateRangePickerContainer}>
                    <GenericDateRangePicker
                        defaultStartDate={chartStartDate}
                        defaultEndDate={chartEndDate}
                        handleRangeChange={(startDate: Moment | null, endDate : Moment | null) => {
                            setChartStartDate(startDate);
                            setChartEndDate(endDate);
                        }}
                        rangePresets={getDefaultDateRangePresets()}
                        placeholders={[chartContent.CHART_START_DATE, chartContent.CHART_END_DATE]}
                        dateFormat={DEFAULT_VERBOSE_DATE_FORMAT}
                    />
                </div>
            </div>

            {
                chartStartDate !== null && chartEndDate !== null
                    ? (
                        <Skeleton
                            active loading={chartData === undefined || chartData === null}>
                            <div className={styles.chartContainer}>
                                {
                                    chartData?.length !== 0 ? (
                                        getChartComponent(chartStartDate, chartEndDate)
                                    ) : (
                                        <div className={styles.emptyTablePlaceholderContainer}>
                                            <EmptyDataPlaceholderGraphic
                                                text={CHART_DATA_NOT_FOUND}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </Skeleton>
                    )
                    : (
                        <div className={styles.emptyTablePlaceholderContainer}>
                            <EmptyDataPlaceholderGraphic
                                text={CHART_DURATION_NOT_SELECTED}
                            />
                        </div>
                    )
            }

        </Card>
    );
}
