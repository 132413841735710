import { ActionResult, ActionResultTypes, TriggerWorkflowDetails } from "@eazy2biz/common-util";

export const getTriggerWorkflowActionDetails = (): ActionResult<TriggerWorkflowDetails> => {
    return {
        type: ActionResultTypes.TRIGGER_WORKFLOW,
        resultDetails: {
            workflowId: '',
            formValues: {},
        }
    };
};
