import {RestServices} from "@eazy2biz/common-package-ui";
import {CompanyResponse} from "@eazy2biz/common-util";
import {getCompanyCreateRequest} from "./helpers/CompanyHelper";

const COMPANY_SERVICE = () => RestServices.getInstance().getIdentitiesService().getCompanyService();

/**
 * Creates a company
 * @param name
 * @param description
 */
export const createCompany = (
    name : string,
    description : string,
    email : string
) : Promise<CompanyResponse> => {
    return COMPANY_SERVICE().createCompany(getCompanyCreateRequest(name, description, email));
}

export const getCompanyDetails = (companyId: string) : Promise<CompanyResponse> => {
    return COMPANY_SERVICE().getCompanyById(companyId);
}

export const getCompaniesForGlobalUser = () : Promise<CompanyResponse[]> => {
    return COMPANY_SERVICE().getAllCompaniesForGlobalUser();
}

