import {ApprovalValues, Field} from "@eazy2biz/common-util";
import {CheckIcon, Cross1Icon} from "@radix-ui/react-icons";
import classNames from "classnames";
import styles from './ApprovalFieldDisplay.module.css';

/**
 * Read Only Approval Field Display
 * @param props
 * @constructor
 */
export const ApprovalFieldDisplay = (props: PropTypes) => {
    const isApproved = props.value === ApprovalValues.APPROVED;

    const textClassnames = classNames(
        styles.approvalDisplayText,
        {
            [styles.approvedTextStyle]: isApproved,
        });

    return (
        <>
            <div className={styles.iconWrapper}>
                {isApproved ? <CheckIcon color='green' /> : <Cross1Icon color='red' />}
            </div>
            <p className={textClassnames}>Item {isApproved ? 'approved' : 'rejected'}.</p>
        </>
    );
};

type PropTypes = {
    field: Field;
    value: string;
};
