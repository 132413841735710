import { useFeatureTour } from "./FeatureTourProvider";
import { TOUR_CONFIGS } from "./TourConfigs";
import { Tour, TourProps } from "antd";
import { TourType } from "./entity/TourType";
import { LocalStorageService } from "@eazy2biz-ui/common-package";
import styles from './FeatureTour.module.css';

/**
 * Generic feature tour
 * @param props
 * @constructor
 */
export const FeatureTour = () => {
  const {showTour, hideTour, getRef, tourType} = useFeatureTour();

  return (
    <Tour
      open={isTourAllowed(showTour, tourType)}
      className={styles.tourWrapper}
      rootClassName={'raghav'}
      onClose={hideTour}
      steps={tourType && isTourAllowed(showTour, tourType) ? getSteps(tourType, getRef, hideTour) : []}
    />
  );
};

const getSteps = (tourType: TourType, getRef: (idx: number, tourType: TourType) => any, closeTour: () => void) => {
  const stepConfigs: TourProps['steps'] = TOUR_CONFIGS[tourType || ''] || [];
  stepConfigs.forEach((config, idx) => {
    config.target = () => getRef(idx, tourType)?.current;
    if (idx + 1 === stepConfigs.length) {
      config.prevButtonProps = {
        onClick: () => {
          closeTour();
          handleDontShowAgain(tourType);
        },
        children: `Don't show again`
      }
    }
  });
  return stepConfigs;
};

const FEATURE_TOUR_TYPE_LOCAL_KEY = 'FEATURE_TOUR_ALLOWED_LOCAL_KEY111/';

const handleDontShowAgain = (tourType: TourType | undefined) => {
  LocalStorageService.setItem(FEATURE_TOUR_TYPE_LOCAL_KEY + tourType?.toLocaleLowerCase(), 'false');
};

export const isTourAllowed = (showTour?: boolean, tourType?: TourType) => {
  return showTour && tourType && !LocalStorageService.getItem(FEATURE_TOUR_TYPE_LOCAL_KEY + tourType.toLocaleLowerCase());
};


