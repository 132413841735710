import {EntityType, TodoStatus, UserTodoExecutableActionResponse} from "@eazy2biz/common-util";
import {FormFieldInput} from "@eazy2biz/common-package-ui";
import React, {useState} from "react";
import {get} from "lodash";
import {
    ApprovalField, CommentsComponent,
    GenericDescriptionText,
    GenericModal,
    GenericTag,
    GenericTagTypes,
    InputField,
    ModalSubHeaderComponent,
    Toast
} from "@eazy2biz-ui/common-components";
import {TODO_COMPLETE_FORM, TODO_ERROR_STRINGS} from "../../../../../contents/DisplayContent";
import {isTodoSubmitEnabled} from "../../../../helpers/TodoFormHelper";
import styles from './DefaultTodoCompleteModal.module.css';

/**
 * To do completion modal for Default to do types.
 * @param props
 * @constructor
 */
export const DefaultTodoCompleteModal = (props: PropType) => {
    const COMMENT_ID = "comment";
    const {todo, onClose} = props;

    const [comment, setComment] = useState(get(todo, "params." + COMMENT_ID, ""));

    const getFieldsMap = (): FormFieldInput[] => {
        return [{
            id: COMMENT_ID,
            value: comment
        }];
    };

    const handleRejection = () => {
        if (!comment || comment === "") {
            Toast.errorString(TODO_ERROR_STRINGS.EMPTY_COMMENT);
        } else {
            props.onTodoReject(getFieldsMap());
        }
    };

    const renderRightBody = () => {
        return (
            <div className={styles.defaultTodoRightBodyWrapper}>
                <CommentsComponent
                    entityId={todo._id}
                    entityType={EntityType.USER_TODO}
                />
            </div>
        );
    }

    const renderBody = () => {
        return (
            <div className={styles.defaultTodoBodyWrapper}>
                <div className={styles.defaultTodoLeftBodyWrapper}>
                    <GenericDescriptionText text={todo.details.description}/>
                    <InputField
                        id={COMMENT_ID}
                        label={TODO_COMPLETE_FORM.COMMENTS}
                        placeholder={TODO_COMPLETE_FORM.COMMENTS}
                        type="textarea"
                        onChange={(e) => setComment(e.target.value)}
                        disabled={!isTodoSubmitEnabled(todo)}
                        required={false}
                        initialValue={comment}
                    />
                </div>
                {renderRightBody()}
            </div>
        );
    };

    const renderFooter = () => {
        if (todo.status !== TodoStatus.PENDING) {
            return null;
        }
        return(
            <ApprovalField
                disabled={!isTodoSubmitEnabled(todo)}
                handleApproval={() => props.onTodoComplete(getFieldsMap())}
                approveButtonText={TODO_COMPLETE_FORM.COMPLETED}
                handleRejection={handleRejection} />
        );
    };

    const renderLeftHeader = () => {
        switch(todo.status) {
            case TodoStatus.COMPLETED:
              return <GenericTag title="Completed" type={GenericTagTypes.COMPLETED}/>
            case TodoStatus.APPROVED:
              return <GenericTag title="Approved" type={GenericTagTypes.ACTIVE}/>
            case TodoStatus.REJECTED:
              return <GenericTag title="Rejected" type={GenericTagTypes.ERROR}/>
            default:
              return <GenericTag title="Pending" type={GenericTagTypes.PENDING}/>
          }
    }

    const getModalSubTitle = () => {
        return (
            <ModalSubHeaderComponent
                title={TODO_COMPLETE_FORM.MY_TODO_TITLE}
                number={todo.todoNumber}
                dueOn={todo.status === TodoStatus.PENDING ? todo.dueDate : undefined}
                createdOn={todo.metaData.createdDate}
                assignees={todo.assigneeUserId}
                assigneeUserGroupIds={todo.assigneeUserGroupId}
                createdBy={todo.metaData.createdBy}
            />
        );
    };

    return(
        <GenericModal
            show
            title={todo.details.name}
            subHeader={getModalSubTitle}
            leftHeader={renderLeftHeader}
            bodyContent={renderBody}
            footerContent={renderFooter}
            onClose={onClose}
            dialogClassName={styles.modal}
        />
    );
};

type PropType = {
    todo: UserTodoExecutableActionResponse;
    onClose: () => void;
    onTodoComplete: (fieldMap: FormFieldInput[]) => void;
    onTodoReject: (fieldMap?: FormFieldInput[]) => void;
};
