import {ColumnHeader} from "../interfaces/ColumnHeader";
import {RowData, RowMetaData} from "../interfaces/TableRow";

export const generateTableDataFromItems = <T>(
    items: T[],
    columns: ColumnHeader[],
    getValueForItem: (item: T, key: string) => any,
    getRowMetaData: (item: T) => RowMetaData
): RowData[] => {

    let data: RowData[];

    data = items.map((item: T) => {
        const itemData: RowData = {
            rowMetaData: getRowMetaData(item)
        };
        columns.forEach((col) => {
            const key = col.accessor;
            Object.assign(itemData, { [key]: getValueForItem(item, key) });
        });

        return itemData;
    });
    return data;
};

export const getArrayPage = <T>(array: T[], page_size: number, page_number: number) => {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
};