import React from 'react'
import {IoClose} from 'react-icons/io5';
import styles from './CloseButton.module.css'
import classNames from "classnames";

function CloseButton(props:CloseFunction) {
    const {size = 'large'} = props;

    return (
        <IoClose className={classNames(styles.closeButton, props.className, {[styles.smallCloseButton]: size === 'small'})} onClick={props.onClose}/>
    )
}

interface CloseFunction {
    onClose: () => void;
    size?: 'large' | 'small' | undefined;
    className?: string;
}

export default CloseButton
