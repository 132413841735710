import styles from "./CustomCreateCard.module.css";
import { PlusIcon } from "@radix-ui/react-icons";
import classNames from "classnames";
import { useFeatureTour } from "../../../FeatureTour/FeatureTourProvider";
import { TourType } from "../../../FeatureTour/entity/TourType";

/**
 * Custom Card for create in list.
 * @constructor
 */
export const CustomCreateCard = (props: PropTypes) => {

    const {getRef} = useFeatureTour();
    const handleOnClick = () => {
        !props.disabled && props.onClick();
    };
    return (
        <div className={classNames(styles.createCustomCard)}
             onClick={handleOnClick}
             ref={getRef(0, TourType.HOMEPAGE)}>
            <div className={classNames(styles.createCustomCardDisplay, {[styles.createCustomCardDisabled]: props.disabled})}>
                <PlusIcon className={styles.createCustomIcon}/>
                <div className={styles.createCustomCardTitle}>{props.title || 'Create Custom'}</div>
            </div>
        </div>
    );
}

type PropTypes = {
    title?: string,
    onClick: () => void,
    disabled?: boolean
};
