import {CustomerTableHeader, CustomerTableHeaderTypes} from "@eazy2biz/common-util";
import {ToggleButton} from "@eazy2biz-ui/common-components";
import styles from './TableHeaderConfigurationsComponent.module.css';
import {DropdownConfigurations} from "./customConfigurations/DropdownConfigurations";
import {isTableHeaderRequired, toggleHeaderRequired} from "../../../helpers/TableHeaderConifgurationsHelper";
import {useState} from "react";

/**
 * Header Configurations
 * @param props
 * @constructor
 */
export const TableHeaderConfigurationsComponent = (props: PropTypes) => {
    const [isHeaderRequired, setIsHeaderRequired] = useState(isTableHeaderRequired(props.tableHeader));

    const handleHeaderName = (tableHeaderLabel: string) => {
        let updatedHeader: CustomerTableHeader = props.tableHeader;
        updatedHeader.name = tableHeaderLabel;
        updatedHeader.helpText = tableHeaderLabel;
        props.updateTableHeader(updatedHeader);
    }

    const handleHelpTextChange = (helpText:string) => {
        let updatedHeader: CustomerTableHeader = props.tableHeader;
        updatedHeader.helpText = helpText;
        props.updateTableHeader(updatedHeader);
    }

    const changeFieldRequirement = () => {
        props.updateTableHeader(toggleHeaderRequired(props.tableHeader));
        setIsHeaderRequired(!isHeaderRequired);
    };

    const renderCustomFieldConfigurations = () => {
        switch (props.tableHeader.headerType) {
            case CustomerTableHeaderTypes.DROPDOWN:
                return <DropdownConfigurations tableHeader={props.tableHeader} onHeaderUpdate={props.updateTableHeader} />;
            default: return null;
        }
    }

    return (
        <div className={styles.tableHeaderSettings}>
            <div className={styles.tableHeaderSettingsHeader}>
                <p>Column Settings</p>
            </div>
            <div className={styles.inputFieldsCtn}>
                <label>Column Name</label>
                <input type='text' id= {'tableHeaderName'} value={props.tableHeader.name} onChange={(e)=>handleHeaderName(e.target.value)}/>
            </div>
            <div className={styles.inputFieldsCtn}>
                <label>Column Help Text</label>
                <input type='text' id={'tableHeaderHelpText'} value={props.tableHeader.helpText} onChange={(e)=>handleHelpTextChange(e.target.value)}/>
            </div>
            <ToggleButton
                label="Required ?"
                value={isHeaderRequired}
                wrapperClassName={styles.tableHeaderRequiredCtn}
                className={styles.toggleCtn}
                onToggle={changeFieldRequirement} />
            {renderCustomFieldConfigurations()}
        </div>
    );
};

type PropTypes = {
    tableHeader: CustomerTableHeader;
    updateTableHeader: (tableHeader: CustomerTableHeader) => void;
};
