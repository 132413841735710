import React, {ReactElement, useState} from "react";
import {InputNumber} from "antd";

export const NumberSelect = (props: PropTypes): ReactElement => {
    const {id, min, max, defaultValue, onChange, bordered, value, isInValid, highlightFieldError, disabled} = props;

    const [showFieldErrorOnBlur, setShowFieldErrorOnBlur] = useState(false)

    return (
        <InputNumber
            id={id}
            min={min || 0}
            max={max || 10}
            defaultValue={defaultValue || 1}
            {
                ...(
                    isInValid && (showFieldErrorOnBlur || (highlightFieldError !== undefined ? highlightFieldError : true))
                        ? {status: 'error'}
                        : {}
                )
            }
            onChange={onChange}
            onBlur={() => setShowFieldErrorOnBlur(true)}
            value={value}
            bordered={bordered || true}
            disabled={disabled}
        />
    );
};

type PropTypes = {
    id: string;
    bordered?: boolean;
    onChange: (value: number | null) => void;
    min?: number;
    max?: number;
    defaultValue?: number;
    value?: number | null;
    isInValid?: boolean;
    highlightFieldError? : boolean;
    disabled?: boolean
};
