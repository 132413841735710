import { InfoCircledIcon } from "@radix-ui/react-icons";
import styles from  './InfoWrapper.module.css';
import { Popover } from "antd";
import { ReactElement } from "react";

export const InfoWrapper = (props: PropTypes) => {
  return (
    <div>
      {props.children}
      <Popover content={props.infoContent} overlayClassName={styles.infoContentWrapper}>
        <InfoCircledIcon width={10} className={styles.infoIcon} />
      </Popover>
    </div>
  );
};

type PropTypes = {
  children: any;
  infoContent: ReactElement | string;
}
