import {AssigneeSelect, InputField, ToggleButton} from "@eazy2biz-ui/common-components";
import {cloneDeep} from "lodash";
import {TODO_FILTER_MENU} from "../../../../../contents/DisplayContent";
import {getCurrentUserId} from "@eazy2biz/common-package-ui";

/**
 * To do Filter Menu
 * @param props
 * @constructor
 */
export const TodoFilterMenu = (props: PropTypes) => {
    const {filterConfig} = props;

    const onDueDateChange = (e: any) => {
        const filter = cloneDeep(filterConfig);
        filter.dueDate = e.target.value;
        props.onFilterChange(filter);
    };

    const assigneeChange = (ids: string[]) => {
        const filter = cloneDeep(filterConfig);
        filter.assigneeUserId = ids[0];
        props.onFilterChange(filter);
    };

    const onToggleActiveOnly = () => {
        const filter = cloneDeep(filterConfig);
        filter.pendingOnly = !filter.pendingOnly;
        props.onFilterChange(filter);
    };

    return(
        <div>
            <InputField
                label={TODO_FILTER_MENU.DUE_BEFORE}
                onChange={onDueDateChange}
                id='dueBefore'
                required={false}
                type='date'/>
            <AssigneeSelect
                label={TODO_FILTER_MENU.ASSIGNED}
                required={false}
                multiSelect={false}
                selectedAssignees={[filterConfig.assigneeUserId || '']}
                onAssigneesSelect={assigneeChange}/>
            <ToggleButton value={filterConfig.pendingOnly} onToggle={onToggleActiveOnly} label={TODO_FILTER_MENU.PENDING_ONLY}/>
        </div>
    );
};

type PropTypes = {
    onFilterChange: (config: TodoFilterConfig) => void;
    filterConfig: TodoFilterConfig;
};

export class TodoFilterConfig {
    dueDate?: Date;
    pendingOnly: boolean = true;
    assigneeUserId?: string;

    constructor() {
        this.assigneeUserId = getCurrentUserId();
    }

    static getClearFilter = () => {
        const filter = new TodoFilterConfig();
        filter.pendingOnly = false;
        filter.assigneeUserId = undefined;
        return filter;
    }
}
