import {
    ActionResult,
    ActionResultTypes,
    TriggerStageCompletionDetail
} from "@eazy2biz/common-util";

/**
 * Creates TRIGGER_STAGE_COMPLETE action.
 * @returns actionResult of TriggerStageCompletionDetail
 */
export const getTriggerStageCompletionAction = (): ActionResult<TriggerStageCompletionDetail> => {
    return {
        type: ActionResultTypes.TRIGGER_STAGE_COMPLETE,
        resultDetails: {
            fieldsMap: {}
        }
    };
};