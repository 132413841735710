import {BarChartDetails, BarChartType, BaseChartDetails, ChartType, ReportEntityType} from "@eazy2biz/common-util";

import {ConfigureWorkflowBarChartDetails} from "./workflow/ConfigureWorkflowBarChartDetails";
import {useEffect, useState} from "react";
import {Builder} from "builder-pattern";

export const ConfigureBarChartDetails = (
    props : {
        entityType: ReportEntityType;
        entityId: string;
        entity: any;
        showChartConfigureFormFieldErrors: boolean;
        handleChartDetailsChange: (newChartDetails : BaseChartDetails<ChartType>) => void;
    }
) : JSX.Element => {

    const {
        entityType,
        entityId,
        entity,
        showChartConfigureFormFieldErrors,
        handleChartDetailsChange,
    } = props;

    const [barChartDetails , setBarChartDetails] = useState<BarChartDetails>(
        Builder<BarChartDetails>()
            .barChartType(BarChartType.VERTICAL)
            .build()
    )

    const [subGroupingEnabled , setSubGroupingEnabled] = useState<boolean>(false)

    useEffect(() => {
        handleChartDetailsChange(barChartDetails);
    }, [barChartDetails])

    const getBarChartComponent = () => {
        switch (entityType) {
            case ReportEntityType.WORKFLOW:
                return (
                    <ConfigureWorkflowBarChartDetails
                        entityType={entityType}
                        entityId={entityId}
                        workflowResponse={entity}
                        showChartConfigureFormFieldErrors={showChartConfigureFormFieldErrors}

                        barChartDetails={barChartDetails}
                        setBarChartDetails={setBarChartDetails}
                        subGroupingEnabled={subGroupingEnabled}
                        setSubGroupingEnabled={setSubGroupingEnabled}
                    />
                )
        }
    }

    return (
        <>
            {
                getBarChartComponent()
            }
        </>
    )
}
