import React from "react";
import styles from './Footer.module.css';

export const Footer: (props: PropTypes) => JSX.Element = (props: PropTypes) => {
    return(
        <div className={styles.footerWrapper}>
            <hr/>
            <span>Contact us:</span>
            <br/>
            <span className={styles.spanMargin}>9911896843</span>
            <br/>
            <span className={styles.spanMargin}>9540509324</span>
            <br/>
            <hr/>
            <span className={styles.copyright}>Copyright © 2022</span>
            <br/>
            <span className={styles.copyright}>All rights reserved</span>
        </div>
    );
};

type PropTypes = {
};
