import React from "react";
import styles from "./UserAndUserGroup.module.css";
import {Container} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import {
    BackNavigationLink,
    CloseButton,
    GenericButton,
    GenericButtonTypes,
    GenericHeader,
    GenericTable,
    InputField,
    ModalTitle,
    Toast
} from "@eazy2biz-ui/common-components";
import {
    getColumnsForUserGroupsList,
    getTableDataForUserGroupsList
} from "../helpers/UserGroupsHelper";
import {
    CREATE_DEPARTMENT,
    CREATE_DEPARTMENT_BUTTON_TEXT,
    CREATE_USER_GROUP,
    CREATE_USER_GROUP_BUTTON_TEXT,
    DEPARTMENTS_BUTTON,
    DEPARTMENTS_HEADER,
    ERROR_STRINGS,
    SUBMIT_BUTTON_TEXT,
    SUCCESS_STRINGS,
    USER_GROUPS_BUTTON,
    USER_GROUPS_HEADER
} from "../../contents/DisplayContent";
import {ManageMembershipDrawerComponent} from "./ManageMembershipDrawerComponent";
import {useHistory} from "react-router-dom";
import {
    GenericException,
    ErrorTypes,
    CachedStore,
    StoreEntityTypes,
    UserAuthorizationService,
    UserGroupActions
} from "@eazy2biz/common-package-ui";
import {SecurityGroupFeatures, UserGroupResponse, UserGroupType} from "@eazy2biz/common-util";
import {createUserGroup} from "../services/UserGroupService";

export const UserGroupsComponent = (props: { isDepartmentView?: boolean }): JSX.Element => {
    const { isDepartmentView } = props;
    const columns = getColumnsForUserGroupsList();
    const [showModal, toggleModalState] = React.useState<boolean>(false);
    const [showMembershipDrawer, toggleMembershipDrawer] = React.useState<boolean>(false);
    const [refresh, setRefresh] = React.useState<boolean>(true);
    const [userGroupName, setUserGroupName] = React.useState<string>();
    const [userGroupOpened, openUserGroup] = React.useState<UserGroupResponse>();
    const [userGroupDescription, setUserGroupDescription] = React.useState<string>();
    const [userGroups, setUserGroups] = React.useState(new Array());
    const [hasPermissionToCreateUG, setHasPermissionToCreateUG] = React.useState<boolean>(false);

    const history = useHistory();

    React.useEffect(() => {
        const userAuthorizationService = UserAuthorizationService.getInstance();
        // TODO: FIX SecurityGroupFeatures
        userAuthorizationService.isActionAllowed(UserGroupActions.CREATE, SecurityGroupFeatures.USERS)
            .then(hasPermission => {
                setHasPermissionToCreateUG(true);
            });
    }, []);

    React.useEffect(() => {
        if (refresh) {
            CachedStore.getStoreList(StoreEntityTypes.USER_GROUPS, true)
                .then((userGroups: UserGroupResponse[]) => {
                setUserGroups(getTableDataForUserGroupsList(userGroups, isDepartmentView ? UserGroupType.DEPARTMENT : UserGroupType.USER_DEFINED));
            });
            setRefresh(false);
        }
    }, [refresh]);

    const handleUserGroupsButtonClick = () => {
        history.replace('/manageUsers/userGroups');
    };

    const handleDepartmentsButtonClick = () => {
        history.replace('/manageUsers/departments');
    };

    const createNewUserGroup = () => {
        if (userGroupName) {
            createUserGroup(
                userGroupName,
                userGroupDescription || '',
                isDepartmentView ? UserGroupType.DEPARTMENT : UserGroupType.USER_DEFINED
            )
                .then((response : UserGroupResponse) => {
                    setRefresh(true);
                    toggleModalState(false);
                    Toast.success(SUCCESS_STRINGS.USER_GROUP_CREATE_SUCCESS);
                })
                .catch(() => {
                    Toast.error(new GenericException(ErrorTypes.API_ERROR, '', ERROR_STRINGS.USER_GROUP_CREATE_FAILURE), ERROR_STRINGS.USER_GROUP_CREATE_FAILURE);
                });
        }
    };

    const onClose = () => {
        toggleModalState(false);
    }

    const onCloseMembershipDrawer = () => {
        toggleMembershipDrawer(false);
    }

    const launchMembershipModal = (id: string) => {
        toggleMembershipDrawer(true);
        openUserGroup(userGroups.find(grp => grp._id === id));
    }

    const handleUserGroupNameChange = (event: any) => {
        setUserGroupName(event.target.value);
    }

    const handleUserGroupDescriptionChange = (event: any) => {
        setUserGroupDescription(event.target.value);
    }

    const renderSubHeader = (): JSX.Element => {
        return <BackNavigationLink/>;
    }

    const renderSectionButtons = (): JSX.Element => {
        return (
            <>
                {
                    isDepartmentView &&
                    <GenericButton
                        onClick={handleUserGroupsButtonClick}
                        buttonText={USER_GROUPS_BUTTON}
                        type={GenericButtonTypes.SECONDARY}
                    />
                }
                {
                    hasPermissionToCreateUG &&
                    !isDepartmentView &&
                    <GenericButton
                        onClick={handleDepartmentsButtonClick}
                        buttonText={DEPARTMENTS_BUTTON}
                        type={GenericButtonTypes.SECONDARY}
                    />
                }
                {
                    hasPermissionToCreateUG &&
                    <GenericButton
                        onClick={() => toggleModalState(true)}
                        buttonText={isDepartmentView ? CREATE_DEPARTMENT : CREATE_USER_GROUP}
                    />
                }
            </>
        );
    }

    const renderUGCreationModal = () => {
        return (
            <Modal key={'new-group-modal'} show={showModal}>
                <Modal.Header>
                    <ModalTitle title={isDepartmentView ? CREATE_DEPARTMENT_BUTTON_TEXT : CREATE_USER_GROUP_BUTTON_TEXT}/>
                    <CloseButton onClose={onClose}/>
                </Modal.Header>
                <Modal.Body>
                    <div className={styles.inputArea}>
                        <InputField id={'user-group-name'} label={'Name'} type={'text'} onChange={handleUserGroupNameChange} />
                        <InputField id={'user-group-description'} label={'Description'} type={'text'} onChange={handleUserGroupDescriptionChange} required={false}/>
                    </div>
                    <div className={styles.submitBtn}>
                        <GenericButton onClick={createNewUserGroup} buttonText={SUBMIT_BUTTON_TEXT} />
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    return (
        <Container className={styles.container}>
            <GenericHeader
                header={isDepartmentView ? DEPARTMENTS_HEADER : USER_GROUPS_HEADER}
                subHeader={renderSubHeader}
                sectionButtons={renderSectionButtons}
            />
            {renderUGCreationModal()}
            { userGroupOpened ?
                <ManageMembershipDrawerComponent
                    userGroup={userGroupOpened}
                    onClose={onCloseMembershipDrawer}
                    visible={showMembershipDrawer} /> :
                null
            }
            <GenericTable
                defaultTitleClickEnabled={true}
                onDefaultTitleClick={launchMembershipModal}
                columns={columns}
                data={userGroups}
                showSearchOption={true}
                showFiltersOption={true} />
        </Container>
    );
};
