import {
    AreaChartDetails, BarChartDetails,
    BaseChartDetails,
    ChartCreateRequest,
    ChartType,
    EntityDefinitionTypes,
    EntitySearch, LineChartDetails, PieChartDetails,
    ReportEntityType,
    ReportResponse, TableChartDetails
} from "@eazy2biz/common-util";
import React, {useState} from "react";
import {EmptyDataPlaceholderGraphic, InputField, NumberSelect, ToggleButton} from "@eazy2biz-ui/common-components";
import styles from "./ConfigureChart.module.css";
import moment from "moment";
import classNames from "classnames";
import {ConfigureChartAdditionalDetails} from "./chartAdditionalDetails/ConfigureChartAdditionalDetails";
import {Card} from "antd";
import {ChartPreview} from "./ChartPreview";
import {chartContent} from "../../../../contents/DisplayContent";
import {
    checkValidCreateChartForm,
    convertCreateChartFormToChartPreviewForm,
    CreateChartForm
} from "./CreateChartHelper";

export const ConfigureChart = (
    props : {
        selectedReport : ReportResponse,
        chartType : ChartType,
        entityType: ReportEntityType;
        entityId: string;
        showChartConfigureFormFieldErrors: boolean;
        createChartForm: CreateChartForm;
        setCreateChartForm: (createChartForm : CreateChartForm) => void;
    }
) : JSX.Element => {

    const {
        selectedReport,
        chartType,
        entityType,
        entityId,
        showChartConfigureFormFieldErrors,
        createChartForm,
        setCreateChartForm
    } = props;

    const handleFormUpdate = (formField : string, formValue : any) => {
        // @ts-ignore
        setCreateChartForm({...createChartForm, [formField] : formValue})
    }

    return (
        <div className={styles.chartConfiguration}>
            <div className={styles.chartParameters}>
                <InputField
                    id="name"
                    label="Name"
                    highlightFieldError={showChartConfigureFormFieldErrors}
                    isInValid={!createChartForm?.name}
                    initialValue={createChartForm?.name || ''}
                    type="text"
                    onChange={(e) => handleFormUpdate('name', e.target.value)}
                />

                <div className={styles.descriptionField}>
                    <InputField
                        id="description"
                        label="Description"
                        initialValue={createChartForm?.description || ''}
                        required={false}
                        type="textArea"
                        onChange={(e) => handleFormUpdate('description', e.target.value)}
                    />
                </div>

                <div className={styles.chartDuration} >
                    <div className={styles.chartDurationHeader}>
                        <div>
                            <span className={styles.otherFieldsTitle}>Default Chart Duration</span>
                            <span className={classNames(styles.otherFieldsTitle, styles.labelMandatoryText)}>*</span>
                        </div>
                        <ToggleButton
                            label="Same as Report duration?"
                            value={createChartForm?.chartDurationSameAsReportDuration || false}
                            wrapperClassName={styles.chartDurationSameAsReportDurationToggleWrapper}
                            className={styles.chartDurationSameAsReportDurationToggle}
                            onToggle={
                                () =>
                                    handleFormUpdate(
                                        'chartDurationSameAsReportDuration',
                                        !(createChartForm?.chartDurationSameAsReportDuration || false)
                                    )
                            }
                            toggleButtonOnLeft={true}
                        />
                    </div>

                    <div className={styles.datePicker}>
                        <NumberSelect
                            id={'defaultChartDurationInDays'}
                            onChange={(value) => handleFormUpdate('defaultChartDurationInDays', value)}
                            highlightFieldError={showChartConfigureFormFieldErrors}
                            isInValid={
                                (createChartForm?.defaultChartDurationInDays === 0 || createChartForm?.defaultChartDurationInDays === null)
                                && (createChartForm?.defaultChartDurationInMonths === 0 || createChartForm?.defaultChartDurationInMonths === null)
                            }
                            min={0}
                            max={30}
                            defaultValue={0}
                            value={
                                createChartForm?.chartDurationSameAsReportDuration
                                    ? moment.duration(selectedReport.defaultReportDuration).days()
                                    : createChartForm?.defaultChartDurationInDays
                            }
                            disabled={createChartForm?.chartDurationSameAsReportDuration}
                        />
                        <span className={styles.datePickerLabel}>days</span>
                        <NumberSelect
                            id={'defaultChartDurationInMonths'}
                            onChange={(value) => handleFormUpdate('defaultChartDurationInMonths', value)}
                            highlightFieldError={showChartConfigureFormFieldErrors}
                            isInValid={
                                (createChartForm?.defaultChartDurationInDays === 0 || createChartForm?.defaultChartDurationInDays === null)
                                && (createChartForm?.defaultChartDurationInMonths === 0 || createChartForm?.defaultChartDurationInMonths === null)
                            }
                            min={0}
                            max={24}
                            defaultValue={1}
                            value={
                                createChartForm?.chartDurationSameAsReportDuration
                                    ? moment.duration(selectedReport.defaultReportDuration, 'months').asMonths()
                                    : createChartForm?.defaultChartDurationInMonths
                            }
                            disabled={createChartForm?.chartDurationSameAsReportDuration}
                        />
                        <span className={styles.datePickerLabel}>months</span>
                    </div>
                </div>

                <div className={styles.chartAdditionalDetails}>
                    <ConfigureChartAdditionalDetails
                        chartType={chartType}
                        entityType={entityType}
                        entityId={entityId}
                        showChartConfigureFormFieldErrors={showChartConfigureFormFieldErrors}
                        handleChartAdditionalDetailsChange={
                            (formField : string, value : any) =>
                                handleFormUpdate(formField, value)
                        }
                    />
                </div>

            </div>

            <Card className={styles.chartPreview} bodyStyle={{height: '100%', display: "flex", flexDirection: "column", justifyContent: "center"}}>
                {
                    checkValidCreateChartForm(createChartForm, chartType, selectedReport) ? (
                        <ChartPreview
                            selectedReport={selectedReport}
                            chartType={chartType}
                            previewChartForm={
                                convertCreateChartFormToChartPreviewForm(createChartForm, chartType, selectedReport)
                            }
                        />
                    ) : (
                        <div className={styles.emptyTablePlaceholderContainer}>
                            <EmptyDataPlaceholderGraphic
                                text={chartContent.CHART_CREATE_REQUIRED_INPUTS_INVALID}
                            />
                        </div>
                    )
                }
            </Card>
        </div>
    )
};

// const getMonths
