import {
    CustomerTableHeader,
    CustomerTableHeaderValidation,
    CustomerTableHeaderValidationType
} from "@eazy2biz/common-util";

export const isTableHeaderRequired = (header: CustomerTableHeader): boolean => {
    return !!header.validations.find((element) => element.headerValidationType === CustomerTableHeaderValidationType.REQUIRED);
};

export const toggleHeaderRequired = (header: CustomerTableHeader): CustomerTableHeader => {
    if (isTableHeaderRequired(header)) {
        header.validations =
            header.validations.filter(
                (validation) => validation.headerValidationType !== CustomerTableHeaderValidationType.REQUIRED);
    } else {
        header.validations.push(new CustomerTableHeaderValidation(CustomerTableHeaderValidationType.REQUIRED, true));
    }

    return header;
};
