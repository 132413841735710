import {
  GenericButton, GenericModal,
  StageFormComponent
} from '@eazy2biz-ui/common-components';
import React, {ReactElement, useState} from 'react';
import styles from './GenericCardForm.module.css';
import { ApprovalValues, Stage, StageFormEntity } from "@eazy2biz/common-util";
import { FormFieldInput } from '@eazy2biz/common-package-ui';

const GenericCardForm: (props: PropTypes) => JSX.Element | null = (props: PropTypes) => {
  const { stageData, isSubmitting,leftHeader } = props;
  const [fieldsMap, setFieldMap] = React.useState(new Array<any>());
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  if (!stageData || !props.stageForm) {
    return null;
  }

  const handleSubmit = (approvalValue?: ApprovalValues) => {
    props.onSubmit(fieldsMap, approvalValue);
  };

  const renderStageForm = () => {
    return (
        <StageFormComponent
          stageForm={props.stageForm}
          fieldsMap={fieldsMap}
          setFieldMap={setFieldMap}
          onSubmit={handleSubmit}
          disableSubmit={setDisableSubmitButton}
          disableForm={props.disableForm}
        />
    );
  };

  const renderSubHeader = () => {
    return props.modalSubTitle || null;
  };

  const renderLeftHeader = () => {
    return leftHeader|| null;
  };

  const renderModelBody = () => {
    return(
        <>
          {
            props.modalContent &&
            <div className={styles.modalContent}>
              {props.modalContent}
              <hr />
            </div>
          }
          <div className={styles.formContent}>
            <p className={styles.stageName}>
              {stageData && stageData.details && stageData.details.name}
            </p>
            {renderStageForm()}
          </div>
        </>
    );
  };

  const renderFooter = () => {
    if (!props.enableSubmitButton) {
      return null;
    }

    return (
      <div className={styles.footerCtn}>
        <GenericButton
            isLoading={isSubmitting}
            onClick={handleSubmit}
            buttonText={props.submitButtonText}
            disabled={disableSubmitButton || props.disableForm}
        />
      </div>
    );
  };

  return (
      <GenericModal
          show={props.show}
          title={props.modalTitle}
          subHeader={renderSubHeader}
          leftHeader={renderLeftHeader}
          bodyContent={renderModelBody}
          footerContent={renderFooter}
          onClose={props.onClose}
          dialogClassName={styles.modal}
      />
  );
};

type PropTypes = {
  show: boolean;
  stageData?: Stage;
  stageForm?: StageFormEntity;
  onSubmit: (fieldMap: FormFieldInput[], value?: ApprovalValues) => void;
  onClose: () => void;
  isSubmitting?: boolean;
  modalTitle: string;
  leftHeader?: ReactElement<any, any> | null | string;
  modalSubTitle?: ReactElement<any, any> | null | string;
  modalContent?: ReactElement<any, any> | null | string;
  submitButtonText: string;
  enableSubmitButton?: boolean;
  disableForm?: boolean;
};

const defaultProps = {
  enableSubmitButton: true,
  modalSubTitle: null,
  modalContent: null
};

// @ts-ignore
GenericCardForm.defaultProps = defaultProps;

export default GenericCardForm;
