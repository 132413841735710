import { DataSourceType } from "../entity/EditableTableData";
import uuid from "react-uuid";
import { get, set } from "lodash";
import { TableInputRow } from "@eazy2biz/common-package-ui";

export const getDataSourcesFromInputs = (inputs: TableInputRow[]): DataSourceType[] => {
  return inputs.map(getDataSourceFromInput);
};

export const getDataSourceFromInput = (input: TableInputRow, index: number): DataSourceType => {
  return {
    id: input.id,
    index,
    ...input.values,
  };
};

export const getNewDataSource = (index: number): DataSourceType => {
  return {
    id: uuid(),
    index
  }
};

export const getTableRowInputsFromDataSources = (data: readonly DataSourceType[]): TableInputRow[] => {
  return data.map(getTableRowInputFromDataSource);
};

const getTableRowInputFromDataSource = (data: DataSourceType): TableInputRow => {
  const tableInput: TableInputRow = {
    id: data.id.toString(),
    values: {},
  };

  Object.keys(data)
    .filter(key => !['id', 'index'].includes(key))
    .forEach(key => set(tableInput.values, key, get(data, key, '')))
  return tableInput;
};
