import {Environments} from "@eazy2biz/common-util";

export const CURRENT_ENVIRONMENT = () => process.env.REACT_APP_ENV ?? Environments.QAL.toString();

export const configs = {
  [Environments.DEV.toString()]: {
    apiVersion: "v1",
    hostUrl: "http://localhost:6543/",
    cacheExpiry: {
      hardExpiry: 86400, // 1 day in secs
      softExpiry: 3600 // 1 hours in secs
    },
    appHostUrl: 'http://localhost:3000',
    communicationEmail: 'test.eazy2biz@gmail.com',
  },
  [Environments.DEV2.toString()]: {
    apiVersion: "v1",
    hostUrl: "http://localhost:6543/",
    cacheExpiry: {
      hardExpiry: 86400, // 1 day in secs
      softExpiry: 3600 // 1 hours in secs
    },
    appHostUrl: 'http://localhost:3000',
    communicationEmail: 'test.eazy2biz@gmail.com',
  },
  [Environments.QAL.toString()]: {
    apiVersion: "v1",
    hostUrl: "https://api.qal.eazy2biz.com/",
    cacheExpiry: {
      hardExpiry: 86400, // 1 day in secs
      softExpiry: 3600 // 1 hours in secs
    },
    appHostUrl: 'http://app.qal.eazy2biz.com',
    communicationEmail: 'test.eazy2biz@gmail.com',
  },
  [Environments.E2E.toString()]: {
    apiVersion: "v1",
    hostUrl: "https://api.e2e.eazy2biz.com/",
    cacheExpiry: {
      hardExpiry: 86400, // 1 day in secs
      softExpiry: 3600 // 1 hours in secs
    },
    appHostUrl: 'http://app.e2e.eazy2biz.com',
    communicationEmail: 'test.eazy2biz@gmail.com',
  },
  [Environments.PROD.toString()]: {
    apiVersion: "v1",
    hostUrl: "https://api.eazy2biz.com/",
    cacheExpiry: {
      hardExpiry: 86400, // 1 day in secs
      softExpiry: 3600 // 1 hours in secs
    },
    appHostUrl: 'https://app.eazy2biz.com',
    communicationEmail: 'no-reply@eazy2biz.com',
  },
};
