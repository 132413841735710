import {Button, Divider} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {DropdownSelect} from "@eazy2biz-ui/common-components";
import React, {useEffect, useState} from "react";
import {EntitySearch, FieldSearch, ReportEntityType, ReportResponse, SearchTypes} from "@eazy2biz/common-util";
import {getFilteredReports} from "../../services/ReportService";
import {reportContent} from "../../../contents/DisplayContent";
import {AddReportModal} from "./AddReportModal";

export const ReportSelector = (
    props: {
        entityType: ReportEntityType,
        entityId: string,
        selectedReport : ReportResponse | null,
        handleReportSelected: (reportSelected : ReportResponse | null) => void,
        refreshData : boolean,
        handleRefreshCallBack : (newRefreshValue : boolean) => void,
    }
): JSX.Element => {

    const { entityType, entityId, selectedReport, handleReportSelected, refreshData, handleRefreshCallBack } = props;

    const [reports, setReports] = useState<ReportResponse[] | null>(null);

    const [reFetchData, setReFetchData] = useState(false);

    const [showReportCreateModal, setShowReportCreateModal] = useState<boolean>(false)

    useEffect(() => {
        setReFetchData(refreshData);
    }, [refreshData])

    useEffect(() => {
        if(reFetchData) {
            setReports(null);
            getFilteredReports(
                new EntitySearch([
                    new FieldSearch(SearchTypes.VALUE_EQUAL, 'entityType', entityType),
                    new FieldSearch(SearchTypes.VALUE_EQUAL, 'entityId', entityId)
                ])
            ).then(
                (reportResponses : ReportResponse[]) => {
                    setReports(reportResponses)
                    handleRefreshCallBack(false)
                    setReFetchData(false)
                }
            )
        }
    }, [reFetchData]);

    return (
        <>
            <DropdownSelect
                placeholder={reportContent.SELECT_REPORT}
                label={selectedReport ? reportContent.CHANGE_REPORT : reportContent.SELECT_REPORT}
                selectedItem={selectedReport?._id}
                onItemSelect={(reportId) => {
                    handleReportSelected(reports?.find(reportResponse => reportResponse._id === reportId) || null)
                }}
                additionalDropdownRender={(menu) => (
                    <>
                        <Divider style={{ margin: '8px 0' }} />
                        <Button block type="text" icon={<PlusOutlined />} onClick={() => setShowReportCreateModal(true)}>
                            Add Report
                        </Button>
                    </>
                )}
                loading={reports === null}
                additionalCustomStyles={{width: 300}}
                menuData={reports?.map((report) => ({ _id: report._id, name: report.details.name })) || []}
            />

            {
                showReportCreateModal && (
                    <AddReportModal
                        entityType={entityType}
                        entityId={entityId}
                        onSuccessfulReportCreation={() => {
                            setShowReportCreateModal(false)
                            setReFetchData(true);
                        }}
                    />
                )
            }
        </>
    );
}
