import { Select } from "antd";
import styles from './DropdownSelect.module.css';
import { MenuData } from './MenuData';
import * as React from "react";
import {GenericDropdownRow} from "../GenericDropdownRow/GenericDropdownRow";
import { CloseCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { ReactElement } from "react";

/**
 * Dropdown select component.
 * @param props
 * @constructor
 */
export const DropdownSelect = (props: PropTypes) => {
    const {
        placeholder,
        menuData,
        selectedItem,
        onItemSelect,
        label,
        additionalDropdownRender,
        loading
    } = props;
    const { Option } = Select;

    const handleItemSelection = (id: string) => {
        onItemSelect(id);
    };

    const renderDropdownMenu = (menu: ReactElement) => {
        return(
          <>
              {menu}
              {
                  additionalDropdownRender && additionalDropdownRender(menu)
              }
          </>
        );
    };

    const getMenuData = (): MenuData[] => {
      return menuData;
    };

    const renderSelectOptions = () => {
        return (
          getMenuData().map((item: MenuData, index: number) => {
                return (
                    <Option key={item._id} value={item._id} menuData={item} id={index}>
                        <GenericDropdownRow
                            id={item._id}
                            className={props.rowClassName}
                            value={item.name}
                            tagTitle={item.tagTitle}
                            renderIcon={item.renderIcon}/>
                    </Option>
                );
            })
        );
    };

    const renderLabel = () => {
        return (
            <p className={styles.dropdownLabel}>{label}</p>
        )
    };

    return (
        <>
            {label && renderLabel()}
             <Select
                 allowClear
                 style={props.additionalCustomStyles}
                 showSearch
                 filterOption={
                     (inputValue, option) =>
                         (option?.menuData as MenuData).name?.toLowerCase().includes(inputValue.toLowerCase())
                 }
                 clearIcon={<CloseCircleOutlined className={classNames(styles.dropdownClearBtnPosition)}/>}
                 onBlur={props.onBlur}
                 disabled={props.disabled}
                 defaultValue={selectedItem}
                 loading={loading}
                 {...(props.isErrored ? {status: 'error'} : {})}
                 onChange={handleItemSelection}
                 placeholder={placeholder}
                 className={classNames(props.className)}
                 dropdownRender={renderDropdownMenu}
                 dropdownClassName={classNames(styles.dropdownStyle)}>
                 {renderSelectOptions()}
             </Select>
        </>
    );
};

type PropTypes = {
    placeholder?: string;
    menuData: MenuData[];
    label?: string;
    selectedItem?: string;
    onItemSelect: (id: string) => void;
    disabled?: boolean;
    isErrored?: boolean;
    className?: string;
    onBlur?: React.FocusEventHandler<HTMLElement>;
    rowClassName?: string;
    additionalCustomStyles? : React.CSSProperties;
    additionalDropdownRender? : (menu: ReactElement) => ReactElement,
    loading?: boolean
}


