import React, { Component, HTMLInputTypeAttribute } from "react";
import styles from './InputField.module.css';
import { Input } from 'antd';
import classNames from "classnames";
import {GenericDatePicker} from "./GenericDatePicker/GenericDatePicker";
import { set } from "lodash";

class InputField extends Component<PropTypes, StateType> {
  constructor(props: PropTypes) {
    super(props);
    this.state = {
      inputValue: props.initialValue ?? '',
      showFieldErrorOnBlur: false
    };
  }

  componentDidUpdate(prevProps: Readonly<PropTypes>) {
    if (prevProps.value !== this.props.value && this.props.value !== undefined) {
      this.setState({inputValue: this.props.value});
    }
  }

  handleInputFieldOnChange = (e: any) => {
    const copyEvent = {
      target: {
        value: '',
        id: e.target.id,
      }
    };
    set(copyEvent, 'target.value', getValueForType(e.target.value, this.props.valueType));
    this.setState({inputValue: copyEvent.target.value});
    this.props.onChange(copyEvent);
  };

  renderDefaultTextInput = (props: any) => {
    return (
        <Input {...props}/>
    );
  };

  renderInputByType = () => {
    const {
      id,
      type,
      prefix,
      size,
      placeholder,
      bordered,
      required = true,
      isInValid = false,
      disabled = false,
      onBlur,
      highlightFieldError
    } = this.props;

    const showInvalidInput =
        isInValid && (this.state.showFieldErrorOnBlur || (highlightFieldError !== undefined ? highlightFieldError : true))

    const inputClassNames = classNames(styles.inputCtnControl, { [styles.inputInvalid]: showInvalidInput, 'is-invalid': showInvalidInput})

    const inputProps = {
      className: inputClassNames,
      prefix: prefix ?? null,
      id,
      disabled,
      placeholder,
      type,
      value: this.state.inputValue,
      size: size ?? 'middle',
      bordered: bordered ?? true,
      onChange: this.handleInputFieldOnChange,
      required,
      onBlur : (e : any) => {
        this.setState({
          inputValue : this.state.inputValue,
          showFieldErrorOnBlur : true
        })
        onBlur && onBlur(e);
      },
      highlightFieldError : highlightFieldError
    };

    switch (this.props.type) {
      case 'date':
        return (<GenericDatePicker {...inputProps} />)
      case 'textarea':
        // @ts-ignore
        return (<Input.TextArea {...inputProps} />);
      default:
        return this.renderDefaultTextInput(inputProps);
    }
  };

  render() {

    const {
      label,
      className,
      required = true,
      inValidText = '',
    } = this.props;

    const inputWrapperClassNames = classNames(styles.inputCtn, 'has-validation', { [styles.inputCtnRequired]: required});

    return (
      <React.Fragment>
        <div className={`${styles.inputGroupCustom} ${className ?? null}`}>
          <div className={inputWrapperClassNames}>
            {label && <p>{label}</p>}
            {this.renderInputByType()}
            <div className="invalid-feedback">
              {inValidText}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export interface PropTypes {
  id: string;
  label?: string;
  type: string;
  initialValue?: string | number;
  value?: string;
  placeholder?: string;
  className?: string;
  size?: 'small' | 'large' | 'middle';
  prefix?: JSX.Element;
  bordered?: boolean;
  onChange: (e: any) => void;
  required?: boolean;
  isInValid?: boolean;
  inValidText?: string;
  disabled?: boolean;
  onBlur?: (e: any) => void;
  highlightFieldError? : boolean;
  valueType?: HTMLInputTypeAttribute;
}

type StateType = {
  inputValue: string | number;
  showFieldErrorOnBlur: boolean
};

export default InputField;


const getValueForType = (value?: any, valueType?: HTMLInputTypeAttribute) => {
  switch (valueType) {
    case 'number':
      return value ? Number.parseInt(value) : value;
    default:
      return value;
  }
};
