import styles from './ErrorPage.module.css';
import { Link } from 'react-router-dom';
import { RoutesEnum } from "@eazy2biz-ui/common-package";
import { ERROR_PAGE_TEXT } from '../../../contents/DisplayContent'

const ErrorPage = () => {

    return (
        <div className={styles.errorPageHeader}>
            <div className={styles.errorPageSection}>
                <div className={styles.errorPageBody}>
                    <h2 className={styles.errorPageHeader}>
                        {ERROR_PAGE_TEXT.ERROR_404}
                    </h2>
                    <p className={styles.errorPageSubHeader}>{ERROR_PAGE_TEXT.ERROR_BODY}</p>
                    <Link to={RoutesEnum.APP_DASHBOARD} className={styles.errorPageLink}>{ERROR_PAGE_TEXT.ERROR_LINK}</Link>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage;