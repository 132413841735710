import React, {ReactElement} from 'react';
import styles from './ModalTitle.module.css';

function ModalTitle(props: Title) {
    return (
        <div className={styles.modalTitle}>
            <div>{props.title}</div>
            <div className={styles.modalSubTitle}>
                {props.subTitle}
            </div>
        </div>
    )
}

interface Title {
    title: ReactElement<any, any> | null | string;
    subTitle?: string
}

export default ModalTitle
