import { GenericListInput, ToggleButton } from "@eazy2biz-ui/common-components";
import {FormElementConfig} from "../../../../entity/formBuilder/FormElementConfig";
import { cloneDeep, set } from "lodash";
import {FieldValidation, FieldValidationType} from "@eazy2biz/common-util";
import { getMultiSelectEnabledForField } from "@eazy2biz-ui/common-package";

/**
 * Custom Configurations fot dropdown field type.
 * @param props
 * @constructor
 */
export const DropdownConfigurations = (props: PropTypes) => {

    const validation = props.field.element.validations.find((validation) => validation.fieldValidationType === FieldValidationType.VALUE_IN_ARRAY);

    const defaultValues: string[] = validation?.validationValue || [];

    const handleDropdownValuesChange = (values: string[]) => {
        let updatedField:FormElementConfig = cloneDeep(props.field);
        updatedField.element.validations =
            [
                new FieldValidation(FieldValidationType.VALUE_IN_ARRAY, values),
                ...updatedField.element.validations.filter(
                    (validation) => validation.fieldValidationType !== FieldValidationType.VALUE_IN_ARRAY
                )
            ];
        props.onFieldUpdate(updatedField);
    };

    const handlerMultiSelectEnabledUpdate = (value: boolean) => {
      let updatedField:FormElementConfig = cloneDeep(props.field);
      set(updatedField.element, "fieldConfigurations.otherConfigurations.multiSelect", value);
      props.onFieldUpdate(updatedField);
    };

    return (
      <>
        <ToggleButton
          value={getMultiSelectEnabledForField(props.field.element)}
          onToggle={handlerMultiSelectEnabledUpdate}
          label={'Allow Multi select ?'} />
        <GenericListInput id='dropdownValues' onChange={handleDropdownValuesChange} defaultValues={defaultValues}/>
      </>
    );
};

type PropTypes = {
    field: FormElementConfig;
    onFieldUpdate: (updatedField: FormElementConfig) => void;
};
