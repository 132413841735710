import {PlaceholderOptionsList} from "../PlaceholderOptionsList";
import {
    CustomerTableRecordResponse,
    CustomerTableResponse, FieldPlaceholder, FieldPlaceholderType
} from "@eazy2biz/common-util";
import {PlaceholderOption} from "../../entity/PlaceholderOption";
import { useEffect, useState } from "react";
import { DynamicPlaceholderConfig } from "../../config/DynamicPlaceholderConfig";
import { getAllCustomerTables, getAllRecordsForTable } from "../../../../services/CustomerTableService";
import { Spinner } from "../../../Spinner/Spinner";

interface ITablePlaceholderProps {
    config: DynamicPlaceholderConfig;
    goBack: () => void;
    callback: (placeholderString: FieldPlaceholder) => void;
}

export const TablePlaceholder = (props: ITablePlaceholderProps): JSX.Element => {
    const { callback, goBack } = props;
    const [tables, setTables] = useState<CustomerTableResponse[]>([]);
    const [selectedTable, setSelectedTable] = useState<CustomerTableResponse | null>(null);
    const [records, setRecords] = useState<CustomerTableRecordResponse[]>([]);
    const [selectedRecord, setSelectedRecord] = useState<CustomerTableRecordResponse | null>(null);
    const [currentLevel, setCurrentLevel] = useState<TablePlaceholderLevel>(TablePlaceholderLevel.TABLE);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        getAllCustomerTables()
          .then(res => {
              setTables(res);
          })
          .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        if (selectedTable) {
            setLoading(true);
            getAllRecordsForTable(selectedTable._id)
              .then(res => {
                  setRecords(res);
              })
              .finally(() => setLoading(false));
        }
    }, [selectedTable]);

    const handleOnPick = (id: string) => {
        switch (currentLevel) {
            case TablePlaceholderLevel.COLUMN:
                handleAddPlaceholder(id);
                break;
            case TablePlaceholderLevel.RECORD:
                setCurrentLevel(TablePlaceholderLevel.COLUMN);
                setSelectedRecord(records.find(record => record._id === id) || null);
                break;
            default:
                setCurrentLevel(TablePlaceholderLevel.RECORD);
                setSelectedTable(tables.find(table => table._id === id) || null);
        }
    };

    const handleAddPlaceholder = (key: string, subKey?: string) => {
        const placeholder: FieldPlaceholder = new FieldPlaceholder();
        placeholder.placeholderType = FieldPlaceholderType.DATABASE;
        placeholder.key = key;
        placeholder.entityClassId = selectedTable?._id || '';
        placeholder.entityId = selectedRecord?._id || '';
        placeholder.subKey = subKey;
        callback(placeholder);
    };

    const handleGoBack = () => {
        switch (currentLevel) {
            case TablePlaceholderLevel.COLUMN:
                setCurrentLevel(TablePlaceholderLevel.RECORD);
                setSelectedRecord(null);
                break;
            case TablePlaceholderLevel.RECORD:
                setCurrentLevel(TablePlaceholderLevel.TABLE);
                setSelectedTable(null);
                break;
            default:
                goBack();
        }
    };

    const getPlaceholderOptions = (): PlaceholderOption[] => {
        switch (currentLevel) {
            case TablePlaceholderLevel.COLUMN:
                return selectedTable?.tableHeaders.map((header => {
                    const headerContent = selectedRecord?.tableHeaderContents.find((content) => content.header._id === header._id);
                    return {
                        key: header._id,
                        name: headerContent?.content || ''
                    };
                })) || [];
            case TablePlaceholderLevel.RECORD:
                return records.map(record => {
                    const headerContent = record?.tableHeaderContents.find((content) => content.header._id === selectedTable?.titleTableHeaderId);
                    return {
                        key: record._id,
                        name: headerContent?.content || ''
                    };
                });
            default:
                return tables.map(table => ({
                    key: table._id,
                    name: table.details.name
                }));
        }
    };


    if(loading) {
        return <Spinner />
    }

    return <PlaceholderOptionsList goBack={handleGoBack} headerTitle={currentLevel.toString()} placeholderOptions={getPlaceholderOptions()} onPick={handleOnPick} />;
};

enum TablePlaceholderLevel {
    TABLE = 'Tables',
    RECORD = 'Records',
    COLUMN = 'Columns'
}
