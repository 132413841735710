import styles from "./WorkflowCard.module.css";
import {PlusIcon} from "@radix-ui/react-icons";
import {CREATE_ITEM_ACTION} from "../../../contents/DisplayContent";
import {useEffect, useState} from "react";
import {WorkflowResponse} from "@eazy2biz/common-util";
import {getAllCardsCountForWorkflow} from "../../services/CardService";
import {getAllPendingTodosCount} from "../../services/UserTodoService";
import { TourType, useFeatureTour } from "@eazy2biz-ui/common-components";

export interface WorkflowCardTextProps {
    workflow: WorkflowResponse;
    handleOnCardClick: () => void;
}

export interface WorkflowCardFooterProps {
    handleOnFooterClick: () => void;
}

export const WorkflowCardText = (props: WorkflowCardTextProps) => {
    const [itemsCount, setItemsCount] = useState<number>(-1);
    const [todoCount, setTodoCount] = useState<number>(-1);


    useEffect(() => {

        getAllCardsCountForWorkflow(props.workflow._id || '').then((response) => {
            setItemsCount(response);
        });

        getAllPendingTodosCount(props.workflow._id).then((response) => {
            setTodoCount(response);
        })
    }, []);

    return (
        <div className={styles.workflowCardText} onClick={props.handleOnCardClick}>
            {todoCount !== -1 && <div>{todoCount + " ToDos"}</div>}
            {itemsCount !== -1 && <div>{itemsCount + " Items"}</div>}
        </div>
    );
};



export const WorkflowCardFooter = (props: WorkflowCardFooterProps) => {
    const {getRef} = useFeatureTour();
    return (
        <button ref={getRef(1, TourType.HOMEPAGE)} className={styles.createItemButton} onClick={props.handleOnFooterClick}>
            <PlusIcon className={styles.createItemIcon}/>
            <div>{CREATE_ITEM_ACTION}</div>
        </button>
    );
};

export const WorkflowCardProps = {
    className: styles.workflowCardDisplay,
    cardTitle: (workflow: WorkflowResponse) => workflow.details.name,
};
