import {
    CardResponse,
    ChartResponse,
    ReportResponse,
    TableChartDetails
} from "@eazy2biz/common-util";
import {Moment} from 'moment'
import React, {useEffect, useState} from "react";
import {DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, GenericTable, getArrayPage} from "@eazy2biz-ui/common-components";
import {
    getColumnsForTableChart,
    getTableRowDataForCardItems
} from "../../helpers/TableChartHelper";

export const TableChartComponent = (
    props: {
        reportResponse : ReportResponse,
        tableName: string
        tableChartDetails : TableChartDetails,
        chartStartDate : Moment,
        chartEndDate : Moment,
        tableChartData: CardResponse[],
        simulation?: boolean
    }

): JSX.Element => {

    const {reportResponse, tableName, tableChartDetails, chartStartDate, chartEndDate, tableChartData} = props;

    const [tableData, setTableData] = useState(new Array());

    useEffect(() => {
        setTableData(
            getTableRowDataForCardItems(tableColumns, getArrayPage(tableChartData, DEFAULT_PAGE_SIZE, DEFAULT_PAGE_NUMBER))
        );
    }, [tableChartData])

    const tableColumns = getColumnsForTableChart(tableChartDetails.tableColumns);

    const handlePageChange = (page: number, pageSize: number) => {
        setTableData(
            getTableRowDataForCardItems(
                tableColumns, getArrayPage(tableChartData, pageSize, page)
            )
        );
    };

    return (
        <GenericTable
            tableName={`${reportResponse.details.name}_${tableName}`}
            columns={tableColumns}
            data={tableData}
            totalItems={tableChartData.length}
            paginationEnabled
            showSettings
            onPageChange={handlePageChange}
            downloadEnabled={!props.simulation}
        />
    );
}
