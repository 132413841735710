import React from "react";
import {
    GenericButton,
    GenericButtonTypes,
    GenericTable,
    BackNavigationLink,
    Toast,
    GenericHeader,
} from "@eazy2biz-ui/common-components";
import {getColumnsForUsersList, getTableDataForUsersList} from "../services/helpers/UsersHelper";
import {Container} from "react-bootstrap";
import styles from "./UserAndUserGroup.module.css";
import {
    ALL_USERS_HEADER,
    DEPARTMENTS_BUTTON, ERROR_STRINGS,
    INVITE_USER_BUTTON_TEXT,
    INVITES_BUTTON, SUCCESS_STRINGS,
    USER_GROUPS_BUTTON
} from "../../contents/DisplayContent";
import {useHistory} from "react-router-dom";
import {
    CachedStore, ErrorTypes, GenericException,
    StoreEntityTypes,
    UserActions,
    UserAuthorizationService
} from "@eazy2biz/common-package-ui";
import {InviteUserModal} from "./InviteUserModal";
import { SecurityGroupFeatures } from "@eazy2biz/common-util";



export const UsersComponent = (): JSX.Element => {
    const columns = getColumnsForUsersList();
    const [showModal, toggleShowModal] = React.useState<boolean>(false);
    const [users, setUsers] = React.useState(new Array());
    const [hasPermissionToInviteUsers, setHasPermissionToInviteUsers] = React.useState<boolean | undefined>(undefined);
    const history = useHistory();
    const handleUserGroupsButtonClick = () => {
        history.push('/manageUsers/userGroups');
    };


    const handleDeleteRow = (id: string) => {
       console.log("Clicked on" , id);
    }
    const handleInvitesButtonClick = () => {
        history.push('/manageUsers/invites');
    };

    const handleDepartmentsButtonClick = () => {
        history.push('/manageUsers/departments');
    };

    React.useEffect(() => {
        const userAuthorizationService = UserAuthorizationService.getInstance();

        userAuthorizationService.isActionAllowed(UserActions.CREATE, SecurityGroupFeatures.USERS)
            .then(hasPermission => {
                // user is admin and can invite users
                setHasPermissionToInviteUsers(hasPermission);
            });

        CachedStore.getStoreList(StoreEntityTypes.LOCAL_USERS).then((response) => {
            if (response) {
                setUsers(getTableDataForUsersList(response));
            }
        }).catch((e: any) => {
            Toast.error(e);
        });
    }, []);

    const onClose = () => {
        toggleShowModal(false);
    }

    const renderSubHeader = (): JSX.Element => {
        return <BackNavigationLink/>
    };

    const renderSectionButtons = (): JSX.Element => {
        return (
            <>
                <>
                    <GenericButton
                        onClick={handleUserGroupsButtonClick}
                        buttonText={USER_GROUPS_BUTTON}
                        type={GenericButtonTypes.SECONDARY}
                    />
                    {
                        <GenericButton
                            onClick={handleDepartmentsButtonClick}
                            buttonText={DEPARTMENTS_BUTTON}
                            type={GenericButtonTypes.SECONDARY}
                        />
                    }
                </>
                    <GenericButton onClick={handleInvitesButtonClick} buttonText={INVITES_BUTTON} type={GenericButtonTypes.SECONDARY}/>
                    { hasPermissionToInviteUsers !== false ?
                        <GenericButton
                            showSkeleton={hasPermissionToInviteUsers === undefined}
                            onClick={() => toggleShowModal(true)}
                            buttonText={INVITE_USER_BUTTON_TEXT} />
                        : null
                    }
            </>
        )
    }

    return (
        <Container className={styles.container}>
            <GenericHeader header={ALL_USERS_HEADER} subHeader={renderSubHeader} sectionButtons={renderSectionButtons} />
            <GenericTable
                onRowRemoveClick={handleDeleteRow}
                columns={columns}
                data={users}
                showSearchOption={true}
                showFiltersOption={true} />
            <InviteUserModal showModal={showModal} onClose={onClose} />
        </Container>
    );
};
