import {Bar, Column} from "@ant-design/charts";
import React from "react";
import {BarChartType} from "@eazy2biz/common-util";

import styles from './GenericBarChart.module.css'

export const GenericBarChart = (
    props: {
        barChartType : BarChartType,
        data: any[],
        groupingFieldKey : string,
        subGroupingFieldKey?: string,
        aggregationValueKey : string,
    }

): JSX.Element => {

    const {
        barChartType,
        data,
        groupingFieldKey,
        subGroupingFieldKey,
        aggregationValueKey,
    } = props;

    return (
        <div className={styles.barChart}>
            {
                barChartType === BarChartType.VERTICAL ? (
                    <Column
                        autoFit={true}
                        data={data}
                        isGroup={subGroupingFieldKey !== undefined}
                        xField={groupingFieldKey}
                        yField={aggregationValueKey}
                        seriesField={subGroupingFieldKey ? subGroupingFieldKey : groupingFieldKey}
                        legend={{
                            position: 'bottom-right',
                        }}
                        label={{
                            position: 'top',
                            style: {
                                fill: '#000000',
                                opacity: 0.8,
                            },
                            layout: [
                                {
                                    type: 'interval-hide-overlap',
                                },
                                {
                                    type: 'adjust-color',
                                },
                            ],
                        }}
                        // columnBackground={{
                        //     style: {
                        //         fill: 'rgba(0,0,0,0.1)',
                        //     },
                        // }}
                        columnStyle={{
                            radius: [2, 2, 0, 0],
                        }}
                        xAxis={{
                            label: {
                                autoHide: true,
                                autoRotate: false,
                            },
                        }}
                        yAxis={{
                            max : Math.max(...data.map(dataPoint => dataPoint[aggregationValueKey] as number), 0) * 1.1
                        }}
                    />
                ) : (
                    <Bar
                        autoFit={true}
                        data={data}
                        isGroup={subGroupingFieldKey !== undefined}
                        xField={aggregationValueKey}
                        yField={groupingFieldKey}
                        seriesField={subGroupingFieldKey ? subGroupingFieldKey : groupingFieldKey}
                        legend={{
                            position: 'bottom-right',
                        }}
                        label={{
                            position: 'right',
                            style: {
                                fill: '#000000',
                                opacity: 0.8,
                            },
                            layout: [
                                {
                                    type: 'interval-hide-overlap',
                                },
                                {
                                    type: 'adjust-color',
                                },
                            ]
                        }}
                        // barBackground={{
                        //     style: {
                        //         fill: 'rgba(0,0,0,0.1)',
                        //     },
                        // }}
                        barStyle={{
                            radius: [2, 2, 0, 0],
                        }}
                        xAxis={{
                            max : Math.max(...data.map(dataPoint => dataPoint[aggregationValueKey] as number), 0) * 1.1
                        }}
                    />
                )
            }
        </div>
    );
}
