import { ProColumns } from "@ant-design/pro-components";
import { DataSourceType } from "../entity/EditableTableData";
import { ReactNode } from "react";
import { TableInputColumn } from "@eazy2biz/common-package-ui";

export const getProColumnsFromInputColumn = (columns: TableInputColumn[], editComponent: (id: string) => ReactNode, readOnly?: boolean): ProColumns<DataSourceType>[] => {
  const proColumns = columns.map(getProColumnFromTableInputColumn);
  !readOnly && proColumns.push(optionColumn(editComponent));
  return proColumns;
};

const getProColumnFromTableInputColumn = (column: TableInputColumn): ProColumns<DataSourceType> => {
  return {
    title: column.name,
    dataIndex: column.id,
    tooltip: column.description,
    fieldProps: (form, { rowKey, rowIndex }) => {
      return {
        placeholder: column.placeholder
      };
    },
    formItemProps: (form, { rowIndex }) => {
      return {
        initialValue: column.defaultValue,
        rules:
          rowIndex > 1 ? [{ required: true, message: 'Required' }] : [],
      };
    },
    editable: (text, record, index) => {
      return column.editable;
    },
    width: column.width,
  };
};

const optionColumn = (editComponent: (id: string) => ReactNode): ProColumns<DataSourceType> => ({
  title: 'Actions',
  valueType: 'option',
  width: '4%',
  render: (text, record, index, action) => [editComponent(record.id.toString())]
});
