const flagsMap = {
    WORKFLOW_SIMULATE:  {
        name: 'WORKFLOW_SIMULATE',
        active: false
    },
    EDITABLE_EMAIL_ACTION_FROM: {
        name: 'EDITABLE_EMAIL_ACTION_FROM',
        active: false
    },
    WORKFLOW_CARD_TAG: {
        name: 'WORKFLOW_CARD_TAG',
        active: false
    },
    GOOGLE_SIGN_IN: {
        name: 'GOOGLE_SIGN_IN',
        active: false
    },
    WHATSAPP_TEMPLATE_LANGUAGE: {
        name: 'WHATSAPP_TEMPLATE_LANGUAGE',
        active: false
    },
    WHATSAPP_ACTION: {
        name: 'WHATSAPP_ACTION',
        active: true,
    },
    MESSAGE_ACTION: {
        name: 'MESSAGE_ACTION',
        active: false,
    },
    PDF_TEMPLATE: {
        name: 'PDF_TEMPLATE',
        active: true,
    },
};

export default flagsMap;
