import {ChartType, ReportEntityType, WorkflowResponse} from "@eazy2biz/common-util";
import {useEffect, useState} from "react";
import {ConfigureChartDurationField} from "./chartDurationField/ConfigureChartDurationField";
import {ConfigureChartFilters} from "./chartFilters/ConfigureChartFilters";
import {ConfigureChartDetails} from "./chartDetails/ConfigureChartDetails";
import {Skeleton} from "antd";
import {CachedStore, StoreEntityTypes} from "@eazy2biz/common-package-ui";

export const ConfigureChartAdditionalDetails = (
    props : {
        chartType : ChartType,
        entityType: ReportEntityType;
        entityId: string;
        showChartConfigureFormFieldErrors: boolean;
        handleChartAdditionalDetailsChange: (formField : string, value : any) => void;
    }
) : JSX.Element => {

    const {
        chartType,
        entityType,
        entityId,
        showChartConfigureFormFieldErrors,
        handleChartAdditionalDetailsChange
    } = props;

    const [entity, setEntity] = useState<any>();

    useEffect(() => {
        switch (entityType) {
            case ReportEntityType.WORKFLOW:
                CachedStore.getEntityFromListById<WorkflowResponse>(StoreEntityTypes.WORKFLOWS, entityId)
                    .then((workflowResponse : WorkflowResponse) => setEntity(workflowResponse))
                break;
        }
    }, [entityId, entityType])


    return (
        <>
            <Skeleton active loading={entity === null || entity === undefined}>

                <ConfigureChartDurationField
                    entityType={entityType}
                    entityId={entityId}
                    entity={entity}
                    showChartConfigureFormFieldErrors={showChartConfigureFormFieldErrors}
                    handleChartDurationFieldChange={(newDurationField) => {
                        handleChartAdditionalDetailsChange('durationDateField', newDurationField)
                    }}
                />

                <ConfigureChartDetails
                    chartType={chartType}
                    entityType={entityType}
                    entityId={entityId}
                    entity={entity}
                    showChartConfigureFormFieldErrors={showChartConfigureFormFieldErrors}
                    handleChartDetailsChange={(newChartDetails) => {
                        handleChartAdditionalDetailsChange('chartDetails', newChartDetails)
                    }}
                />

                <ConfigureChartFilters
                    entityType={entityType}
                    entityId={entityId}
                    entity={entity}
                    handleChartFiltersChange={(newFilters) => {
                        handleChartAdditionalDetailsChange(
                            'filters',
                            newFilters
                        )
                    }}
                />

            </Skeleton>
        </>
    );
}
