import {Field, FieldTypes, FieldValidation, FieldValidationType} from "@eazy2biz/common-util";
import {WORKFLOW_BUILDER_ERROR_STRINGS} from "../../../contents/DisplayContent";
import {ErrorTypes, GenericException} from "@eazy2biz/common-package-ui";

/**
 * Get Field with default values.
 * @param type
 * @param stageId
 * @param helpText
 * @param isRequired
 * @param displayName
 * @return Field
 */
export const getField = (
    type: FieldTypes,
    stageId: string,
    helpText :string = '',
    isRequired: boolean = false,
    displayName?: string,
    validations: FieldValidation[] = []
): Field => {
    if (isRequired) {
        validations.push(getIsRequiredValidation());
    }

    let stageName = displayName;

    if (!stageName && type === FieldTypes.APPROVAL) {
        stageName = "Approval";
    }

    return {
        _id: `FORM_FIELD:${Math.round(Math.random()*10000)}`,
        name: stageName || "Untitled",
        ownerId: stageId,
        type,
        helpText,
        validations,
        fieldConfigurations: {}
    };
}

export const getIsRequiredValidation = (): FieldValidation => {
    return {
        validationValue: '',
        fieldValidationType: FieldValidationType.REQUIRED,
    };
}

export const getApprovalFieldIdForStage = (formFields: Field[], stageId: string): string => {
    const approvalField: Field | undefined = formFields.find(
        (e) => e.ownerId === stageId && e.type === FieldTypes.APPROVAL
    );

    if (!approvalField) {
        throw new GenericException(ErrorTypes.NOT_FOUND, 'Approval field not found for stageId=' + stageId, WORKFLOW_BUILDER_ERROR_STRINGS.UNKNOWN_SAVE_ERROR);
    }

    return approvalField._id;
}
