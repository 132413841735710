import {
    completeCardPayload,
    getCardCreateRequest,
    getCardFiltersFromConfig,
    getCardFilterTypesFromConfig
} from "./helpers/CardServiceHelper";
import {FormFieldInput, RestServices} from "@eazy2biz/common-package-ui";
import {CardResponse} from "@eazy2biz/common-util";
import {ItemFilterConfig} from '../components/workflow/Items/ItemFilterComponents/ItemFilterMenu'

const CARD_SERVICE = () => RestServices.getInstance().getCardService();

export const getAllCardsForWorkflow = (workflowId: string) : Promise<CardResponse[]> => {
    return CARD_SERVICE().getAllCardsForWorkflow(workflowId);
}

export const getAllCardsCountForWorkflow = (workflowId: string) : Promise<number> => {
    return CARD_SERVICE().getAllCardsCountForWorkflow(workflowId);
}

export const getCardByIdForWorkflow = (cardId: string, workflowId: string) : Promise<CardResponse> => {
    return CARD_SERVICE().getCardByIdForWorkflow(cardId, workflowId);
}

export const getFilterCardById = (config : ItemFilterConfig, workflowId : string) : Promise<CardResponse[]> => {
    return CARD_SERVICE().getFilteredCardsForWorkflow(getCardFiltersFromConfig(config), getCardFilterTypesFromConfig(config), workflowId);
}

export const discardCardById = (cardId: string, workflowId: string) : Promise<CardResponse> => {
    return CARD_SERVICE().discardCardByIdForWorkflow(cardId, workflowId);
}

export const createCardForWorkflow = (
    fieldsMap: FormFieldInput[],
    tags: string[],
    workflowId: string
) : Promise<CardResponse> => {
    return CARD_SERVICE()
        .createCardForWorkflow(
            getCardCreateRequest(fieldsMap, tags),
            workflowId
        );
}

export const completeCardStageForWorkflow = (
    cardId: string,
    workflowId: string,
    fields: FormFieldInput[]
) : Promise<CardResponse> => {
    return CARD_SERVICE()
        .completeCardStageForWorkflow(
            completeCardPayload(cardId, workflowId, fields)
        );
}

export const updateCardStageForWorkflow = (
    cardId: string,
    workflowId: string,
    fields: FormFieldInput[]
) : Promise<CardResponse> => {
    return CARD_SERVICE()
        .updateCardFormFieldsForWorkflow(
            completeCardPayload(cardId, workflowId, fields)
        );
}
