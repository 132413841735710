import { ApprovalValues, TodoStatus, TodoTypes, UserTodoExecutableActionResponse } from "@eazy2biz/common-util";
import { FormFieldInput } from "@eazy2biz/common-package-ui";
import { completeTodo } from "../../../services/UserTodoService";
import { Toast, useAlertModal } from "@eazy2biz-ui/common-components";
import { TODO_COMPLETE_FORM } from "../../../../contents/DisplayContent";
import TodoCompleteForm from "../TodoCompleteForm";
import { DefaultTodoCompleteModal } from "./DefaultTodoCompleteModal/DefaultTodoCompleteModal";

/**
 * Entry for all to do types for completion.
 * @param props
 * @constructor
 */
export const GenericTodoCompleteModal = (props: PropType) => {

    const {setAlertModalConfig} = useAlertModal();

    const todoComplete = (todoStatus : TodoStatus, fields: FormFieldInput[]) => {
        const { todo, onSubmit } = props;
        completeTodo(todo._id, todoStatus, fields).then(() => {
            Toast.success(TODO_COMPLETE_FORM.SUCCESS);
            onSubmit();
        }).catch(() => Toast.errorString());
    };

    const todoSubmit = (todoStatus : TodoStatus, fields: FormFieldInput[]) => {
        if (todoStatus === TodoStatus.REJECTED) {
            setAlertModalConfig({
                showCancelButton: true,
               onConfirm: () => todoComplete(todoStatus, fields)
            });
        } else {
            todoComplete(todoStatus, fields);
        }
    };

    const handleCompleteTodo = (fieldMap: FormFieldInput[], value?: ApprovalValues) => {
        todoSubmit(getTodoStatusForValue(value), fieldMap);
    };

    const handleTodoReject = (fieldMap: FormFieldInput[] = []) => {
        todoSubmit(
            TodoStatus.REJECTED,
            fieldMap
        );
    };

    switch (props.todo.type) {
        case TodoTypes.DEFAULT:
            return (<DefaultTodoCompleteModal todo={props.todo} onClose={props.onClose} onTodoComplete={handleCompleteTodo} onTodoReject={handleTodoReject}/>);
        default:
            return (<TodoCompleteForm todo={props.todo} onClose={props.onClose} onTodoSubmit={handleCompleteTodo} />)
    }
};

type PropType = {
    todo: UserTodoExecutableActionResponse;
    onClose: () => void;
    onSubmit: () => void;
};


const getTodoStatusForValue = (val?: ApprovalValues) => {
    switch (val) {
        case ApprovalValues.APPROVED:
            return TodoStatus.APPROVED;
        case ApprovalValues.REJECTED:
            return TodoStatus.REJECTED;
        default:
            return TodoStatus.COMPLETED;
    }
};
