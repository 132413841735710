import {get} from "lodash";
import {
    ColumnHeader,
    RowData,
    RowMetaData
} from "@eazy2biz-ui/common-components";
import {
    UserGroupMember,
    UserGroupMemberType
} from "../components/ManageMembershipDrawerComponent";
import {
    LocalUserResponse,
    UserGroupResponse,
    UserGroupType
} from "@eazy2biz/common-util";
import {getFormattedDate} from "@eazy2biz/common-package-ui";

const getValueForUserGroups = (userGroup: UserGroupResponse, key: string) => {
    switch (key) {
        case 'name':
            return get(userGroup, "details.name", "");
        case 'description':
            return get(userGroup, "details.description", "");
        case 'lastUpdatedDate':
            return getFormattedDate(get(userGroup, "metaData.lastUpdatedDate", new Date()));
        default:
            return "";
    }
};

export const getColumnsForUserGroupsList = (): ColumnHeader[] => {
    return [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Description',
            accessor: 'description',
        },
        {
            Header: 'Last Updated At',
            accessor: 'lastUpdatedDate',
        }
    ];
};

export const getColumnsForUserGroupMembership = (): ColumnHeader[] => {
    return [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Type',
            accessor: 'type',
        }
    ];
};

export const getTableDataForUserGroupsMembership = (members: UserGroupMember[]): RowData[] => {
    let data: RowData[] = [];
    members.forEach(member => {
        data.push({
            rowMetaData: {
                id: member.memberDetails._id,
            },
            // @ts-ignore
            id : member.memberDetails._id,
            name : member.type === UserGroupMemberType.USER_GROUP
                ? (member.memberDetails as UserGroupResponse).details.name
                : member.type === UserGroupMemberType.LOCAL_USER
                    ? (member.memberDetails as LocalUserResponse).name
                    : "",
            type : member.type,
        });
    });
    return data;
}

const getRowMetaDataForUserGroup = (userGroup: UserGroupResponse): RowMetaData => {
    return {
        id: userGroup._id
    }
};

export const getTableDataForUserGroupsList = (userGroups: UserGroupResponse[], userGroupType: UserGroupType): RowData[] => {
    const columns = getColumnsForUserGroupsList();
    let data: RowData[] = [];
    userGroups.forEach((userGroup: UserGroupResponse) => {
        let userGroupData: RowData = {
            rowMetaData: getRowMetaDataForUserGroup(userGroup)
        };
        columns.forEach((col: ColumnHeader) => {
            const key = col.accessor;
            Object.assign(userGroupData, {[key]: getValueForUserGroups(userGroup, key)});
        })
        if (userGroup.userGroupType === userGroupType) {
            data.push({
                ...userGroupData,
                ...userGroup,
            });
        }
    });
    return data;
}
