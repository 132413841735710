import {InputProps as DefaultInputPropTypes} from "antd/lib/input/Input";
import {CustomerTableHeader} from "@eazy2biz/common-util";
import {DropdownSelect, MenuData} from "@eazy2biz-ui/common-components";
import { getDropdownMenuForCustomerTableHeader } from "@eazy2biz-ui/common-package";

/**
 * Card form field of type dropdown.
 * @param props
 * @constructor
 */
export const TableHeaderDropdownComponent = (props: PropTypes) => {
  const menuData: MenuData[] = getDropdownMenuForCustomerTableHeader(props.header);

  const handleOnItemSelect = (id: string) => {
    props.onChange && props.onChange({
      // @ts-ignore
      target: {
        value: id
      }
    });
  };

  return (
      <DropdownSelect
          className={props.className}
          menuData={menuData}
          disabled={props.disabled || props.readOnly}
          selectedItem={props.defaultValue as string}
          onBlur={props.onBlur}
          onItemSelect={handleOnItemSelect} />
  );
};

interface PropTypes extends DefaultInputPropTypes {
  header: CustomerTableHeader;
};
