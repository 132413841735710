import {InputFieldWithPlaceholder} from "../../InputFieldWithPlaceholder";
import "./PlaceholderTextComponent.css";

/**
 * Generic Text View Component for strings with components.
 * @param props
 * @constructor
 */
export const PlaceholderTextComponent = (props: Proptypes) => {
  return (
      <div className={props.className}>
          <InputFieldWithPlaceholder
              id={props.id}
              required={false}
              label=""
              type="textarea"
              onChange={() => {}}
              disabled
              initialValue={props.value}
              className={'placeholderTextView'}
              placeHolderButtonDisabled
          />
      </div>
  );
};

type Proptypes = {
    id: string;
    value: string;
    className?: string;
}
