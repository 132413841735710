import React, { useEffect, useState } from "react";
import styles from "./Table.module.css";
import {
    CaretSortIcon,
    CaretUpIcon,
    CaretDownIcon,
    TrashIcon,
  Cross1Icon,
} from "@radix-ui/react-icons";
import {
  NO_RESULTS_FOUND,
} from "../../../contents/DisplayContent";
import {ColumnHeader} from "./interfaces/ColumnHeader";
import {RowData} from "./interfaces/TableRow";
import {PaginationComponent} from "./pagination/PaginationComponent";
import { get } from "lodash";
import { FilterComponentProps, FiltersOption } from "./filter/FilterOption";
import classNames from "classnames";
import {EmptyDataPlaceholderGraphic} from "../../components/EmptyDataPlaceholderGraphic/EmptyDataPlaceholderGraphic";
import { Skeleton } from "antd";
import {AppliedFilterConfig} from "./interfaces/AppliedFilterConfig";
import {AppliedFilterComponent} from "./components/AppliedFilterComponent";
import { TableSettings } from "./components/tableSettings/TableSettings";
import {TABLE_CHART} from "@eazy2biz-ui/reports-ui/src/contents/DisplayContent";
import {CSVLink} from "react-csv";
import uuid from "react-uuid";
import { GenericSearchInput } from "../GenericSearchInput/GenericSearchInput";


export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_NUMBER = 1;

/**
 * Get highlighted row colour.
 * @param row
 */
const getRowHighLightColour = (row: RowData): string | undefined => {
  if (row.rowMetaData) {
    return row.rowMetaData.highlightColour;
  }

  return undefined;
};

/**
 * Generic Table
 * @param props
 * @constructor
 */
export const GenericTable = (props: PropType) => {

  const {
    tableName,
    columns,
    data,
    showFiltersOption,
    showSearchOption,
    defaultTitleClickEnabled,
    onDefaultTitleClick,
    onRowDeleteClick,
    onRowRemoveClick ,
    onDefaultActionClick,
    getFilterComponent,
    loading,
    totalItems = props.data.length
  } = props;

  const [sortByColumn, setSortByColumn] = useState<string>();
  const [sortAscending, setSortAscending] = useState<boolean>(false);

  const [tableOrderedColumns, setTableOrderedColumns] = useState<ColumnHeader[]>(columns);

  const handleOnSortClick = (columnAccessor: string) => {
    if (sortByColumn !== columnAccessor) {
        setSortByColumn(columnAccessor);
        setSortAscending(false);
    } else {
        if (!sortAscending) {
            setSortAscending(true);
        } else {
            setSortByColumn(undefined);
            setSortAscending(false);
        }
    }
  };

  const getSortedRows = () => {
      if (sortByColumn) {
          // @ts-ignore
          return data.sort((row1, row2) => {
              // @ts-ignore
              return (row1[sortByColumn] <= row2[sortByColumn] && sortAscending);
          });
      }

      return data;
  }

  const handleOnTitleClick = (id: string) => {
      defaultTitleClickEnabled && onDefaultTitleClick && onDefaultTitleClick(id);
  };

  const handleDefaultRowClick = (e: any, id: string) => {
      e.stopPropagation();
      props.defaultActionEnabled && onDefaultActionClick && onDefaultActionClick(id);
  };

  const handleDeleteRow = (e: any, id: string) => {
    e.stopPropagation();
    props.onRowDeleteClick && props.onRowDeleteClick(id);
  };

  const handleRemoveRow = (e: any, id: string) => {
    e.stopPropagation();
    props.onRowRemoveClick && props.onRowRemoveClick(id);
  };

  const renderTableControlMenu = () => {
    return(
        <div className={styles.tableControlsMenu}>
          <div className={styles.tableControlsMenuLeft}>
              <div>
                {data.length
                    ? `Showing ${data.length}/${totalItems}`
                    : NO_RESULTS_FOUND}
              </div>
              <AppliedFilterComponent onClearFilters={props.onClearFilters} appliedFilterConfig={props.appliedFilterConfig}/>
          </div>
          <div className={styles.tableFarControls}>
            {showSearchOption && props.onSearch && renderSearch()}
            {showFiltersOption && getFilterComponent && <FiltersOption onClearFilters={props.onClearFilters} getFilterElement={getFilterComponent}/>}
            {props.showSettings && <TableSettings columns={columns} updatedColumns={setTableOrderedColumns} />}
          </div>
        </div>
    );
  };

  const renderSortIcon = (headerAccessor: string) => {
    if (headerAccessor === sortByColumn) {
        return (sortAscending ? <CaretUpIcon/> : <CaretDownIcon/>)
    }
    return (<CaretSortIcon/>);
  };


  const renderHeaders = () => {
    return (
        <thead className={styles.header}>
            <tr>
                {
                  tableOrderedColumns.map((col, idx) => (
                       <th key={col.Header} onClick={() => handleOnSortClick(col.accessor)}>
                            {col.Header}
                            {renderSortIcon(col.accessor)}
                       </th>
                    ))
                }
              {onRowDeleteClick && <th>Delete</th>}
              {onRowRemoveClick && <th>Remove</th>}
            </tr>
        </thead>
    );
  };

  const renderRowDeleteButton = (id: string) => {
    return (
        <td className={styles.removeBtn} key={id}>
          <TrashIcon onClick={(e) => handleDeleteRow(e, id)} />
        </td>
    );
  };


  const renderRowRemoveButton = (id: string) => {
    return (
      <td className={styles.removeBtn} key={id}>
        <Cross1Icon onClick={(e) => handleRemoveRow(e, id)} />
      </td>
    );
  };

  const renderRow = (row: RowData, idx: number) => {

    const rowStyle = {};

    if (getRowHighLightColour(row)) {
      // @ts-ignore
      rowStyle.backgroundColor = getRowHighLightColour(row);
    }

    return (
        <tr
            key={idx}
            className={classNames({[styles.tableRow]: props.defaultActionEnabled && onDefaultActionClick })}
            style={rowStyle}
            onClick={(e) => handleDefaultRowClick(e, row.rowMetaData.id)}>
          {
            tableOrderedColumns.map((col: ColumnHeader, i: number) => {
                  return (
                      <td title={get(row, col.accessor)} className={styles.titleClickable} onClick={() => handleOnTitleClick(row.rowMetaData.id)} key={idx.toString() + col.Header}>
                          { (i === 0 )? (<b>{get(row, col.accessor)}</b>) : get(row, col.accessor)}
                      </td>
                  )
             })
          }
          {onRowDeleteClick && renderRowDeleteButton(row.rowMetaData.id)}
          {onRowRemoveClick && renderRowRemoveButton(row.rowMetaData.id)}
        </tr>
    );

  };

  const renderTable = () => {
    return(
        <table className={styles.tableContents}>
          {renderHeaders()}
          <tbody>
            {getSortedRows().map(renderRow)}
          </tbody>
        </table>
    );
  };

  const renderEmptyTablePlaceholder = () => {
    return (
        <div className={styles.emptyTablePlaceholderContainer}>
            <EmptyDataPlaceholderGraphic />
        </div>
    );
  };

  const renderTableSkeleton = () => {
    return (
      <div className={styles.tableSkeletonContainer}>
          <div className={styles.skeletonBlock}>
            <Skeleton.Button active block />
          </div>
          <div className={styles.skeletonBlock}>
            <Skeleton.Button active block />
          </div>
          <div className={styles.skeletonBlock}>
            <Skeleton.Button active block />
          </div>
          <div className={styles.skeletonBlock}>
            <Skeleton.Button active block />
          </div>
          <div className={styles.skeletonBlock}>
            <Skeleton.Button active block />
          </div>
          <div className={styles.skeletonBlock}>
            <Skeleton.Button active block />
          </div>
      </div>
    );
  };

  const renderPagination = () => {
    if (props.totalItems && props.onPageChange) {
      return (
          <PaginationComponent total={props.totalItems} onPageChange={props.onPageChange}/>
      );
    }
  };

  const renderSearch = () => {
    return (
      <GenericSearchInput onSearch={props.onSearch || (() => {})} />
    );
  };

  const renderExcelDownload = () => {
    return (
      <div className={styles.excelDownload}>
        <CSVLink
          filename={tableName || uuid()}
          data={data}
          className={styles.excelDownloadButton}
          headers={columns.map(column => ({label : column.Header, key : column.accessor}))}>
          {TABLE_CHART.DOWNLOAD_EXCEL}
        </CSVLink>
      </div>
    );
  };

  return (
    <div className={styles.tableWrapper}>
      {renderTableControlMenu()}
      <div className={styles.table}>
        {renderTable()}
        {loading ? renderTableSkeleton() : !data.length && renderEmptyTablePlaceholder()}
      </div>
        {
            data.length !== 0 && (
                <div className={styles.tableFooter}>
                  <div>
                    {props.downloadEnabled && renderExcelDownload()}
                  </div>
                  {props.paginationEnabled && renderPagination()}
                </div>
            )
        }

    </div>
  );
};

type PropType = {
  tableName?: string;
  columns: ColumnHeader[];
  data: Array<RowData>;
  defaultTitleClickEnabled?: boolean;
  onDefaultTitleClick?: (id: string) => void;
  showFiltersOption?: boolean;
  getFilterComponent?: (filterProps: FilterComponentProps) => JSX.Element;
  onClearFilters?: () => void;
  showSearchOption?: boolean;
  onSearch?: (value: string) => void;
  onRowDeleteClick?: (id: string) => void;
  onRowRemoveClick?: (id: string) => void;
  onDefaultActionClick?: (id: string) => void;
  defaultActionEnabled?: boolean;
  paginationEnabled?: boolean;
  totalItems?: number;
  onPageChange?: (page: number, pageSize: number) => void;
  loading?: boolean;
  appliedFilterConfig?: AppliedFilterConfig;
  showSettings?: boolean;
  downloadEnabled? : boolean;
};
