import {ApprovalField, Toast} from "@eazy2biz-ui/common-components";
import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {acceptUserInvite, getInviteById, rejectUserInvite} from "../../services/InvitesService";
import {handleCompanySignIn} from "../../helpers/AuthHelper";
import {ERROR_STRINGS, SUCCESS_STRINGS} from "../../../contents/DisplayContent";
import {IdentitiesWrapperModal} from "../common/identitiesWrapperModal/IdentitiesWrapperModal";
import styles from './CompanyInviteConsentForm.module.css';
import {GlobalUserResponse, UserInviteResponse} from "@eazy2biz/common-util";
import {getCurrentGlobalUser} from "@eazy2biz-ui/common-package";

/**
 * Consent form for Company Invites.
 */
const CompanyInviteConsentForm = () => {
    const history = useHistory();
    // @ts-ignore
    const {inviteId} = useParams();
    const [invite, setInvite] = useState<UserInviteResponse>();
    const [companyName, setCompanyName] = useState('');
    const [enableInviteButtons, setEnableInviteButtons] = useState(false);

    useEffect(() => {
        getInviteById(inviteId)
            .then((response) => {
                setInvite(response.userInvite);
                setCompanyName(response.companyName);
                checkValidInvite(response.userInvite);
            })
            .catch(() => {
                Toast.errorString(ERROR_STRINGS.INVALID_INVITE);
                handleOnClose();
            });
    }, []);

    const checkValidInvite = (userInvite: UserInviteResponse) => {
        const globalUser = getCurrentGlobalUser();
        if (!globalUser) {
            Toast.errorString();
        }
         if (userInvite.emailAddress !== globalUser?.email) {
             Toast.errorString(ERROR_STRINGS.WRONG_SIGN_IN_INVITE);
         } else {
             setEnableInviteButtons(true);
         }
    };

    const handleApprove = async () => {
        acceptUserInvite(inviteId)
            .then((response: GlobalUserResponse) => {
                Toast.success(SUCCESS_STRINGS.ACCEPT_INVITE);
                const membership = response.companyMemberships.find(e => e.companyId.toString() === invite?.companyId.toString());
                membership && handleCompanySignIn(membership)
                    .then(handleOnClose);
            })
            .catch(
                () => { Toast.errorString(ERROR_STRINGS.ACCEPT_INVITE); }
            );
    };

    const handleReject = () => {
        rejectUserInvite(inviteId)
            .then(() => {
                Toast.success(SUCCESS_STRINGS.REJECT_INVITE);
                handleOnClose();
            })
            .catch(
                () => { Toast.errorString(ERROR_STRINGS.REJECT_INVITE); }
            );
    };

    const handleOnClose = () => {
        history.replace("/");
    };

    const renderBody = () => {
        return (
            <div className={styles.inviteConsentWrapper}>
                <div>
                    You have been invited to join:
                </div>
                <div className={styles.companyName}>
                    {companyName}
                </div>
                <br/>
                <div>
                    Do you you wish to join ?
                </div>
                <br/>
                <ApprovalField handleApproval={handleApprove} handleRejection={handleReject} disabled={!enableInviteButtons}/>
            </div>
        );
    };

    return (

        <IdentitiesWrapperModal
            title={`Invite Consent`}
            onClose={handleOnClose}
            getBody={renderBody}
        />
    );
};

export default CompanyInviteConsentForm;
