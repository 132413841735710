import {
    Condition,
    FieldPlaceholder,
    Operators,
} from "@eazy2biz/common-util";

/**
 * UI POJO for Condition.
 */
export class ICondition {
    firstValue?: FieldPlaceholder;
    op: Operators;
    secondValue?: string | number;

    constructor(firstValue?: FieldPlaceholder, op?: Operators, secondValue?: string | number) {
        this.firstValue = firstValue;
        this.op = op || Operators.EQUAL;
        this.secondValue = secondValue;
    }

    public static getFromCondition(condition: Condition) {
        return new ICondition(
            FieldPlaceholder.getFromString(condition.firstValue as string),
            condition.op,
            condition.secondValue
        );
    }

    public static getConditionPayload = (condition: ICondition) => {
        return new Condition(condition.firstValue?.convertToString() || "", condition.op, condition.secondValue);
    }
}
