import {Elements} from "react-flow-renderer";
import {WORKFLOW_BUILDER_ERROR_STRINGS} from "../../../../contents/DisplayContent";
import {FormEntity} from "@eazy2biz-ui/common-package";
import {getFilteredElementsMap} from "../runtimeHelpers/WorkflowBuilderFlowHelper";
import {StageTypes} from "@eazy2biz/common-util";
import { GenericException, ErrorTypes } from "@eazy2biz/common-package-ui";

const throwValidationError = (msg: string, displayMessage: string = WORKFLOW_BUILDER_ERROR_STRINGS.ERROR_ADDING_STAGE, err?: any) => {
    throw new GenericException(ErrorTypes.VALIDATION_ERROR, msg, displayMessage, err);
};

const validateForm = (form: FormEntity): void => {
    if (form.formFields && form.formFields.length === 0) {
        throwValidationError('Form Field is empty', WORKFLOW_BUILDER_ERROR_STRINGS.FIELDS_EMPTY);
    }
};

const validateWorkflow = (stages: Elements, edges: Elements): void => {
    if (stages.length < 2) {
        throwValidationError('Incorrect Stages');
    }

    if (getFilteredElementsMap(stages, [StageTypes.EXIT_STAGE]).size < 1) {
        throwValidationError('End Stage missing');
    }

    if (getFilteredElementsMap(stages, [StageTypes.ENTRY_STAGE]).size !== 1) {
        throwValidationError('Start Stage missing or many are present');
    }

    if (edges.length < 1) {
        throwValidationError('Edges missing');
    }

    // TODO: check for cycle.
};

const validateWorkflowName = (workflowName: string) => {
    if (!workflowName || workflowName === '' || workflowName.includes('Untitled') || workflowName.includes('untitled')) {
        throwValidationError('Please provide a valid workflow name.', 'Please provide a valid workflow name.');
    }
};

export const validateWorkflowAndForm = (workflowName: string, stages: Elements, edges: Elements, form: FormEntity): void => {
    validateWorkflowName(workflowName);
    validateForm(form);
    validateWorkflow(stages, edges);
};
