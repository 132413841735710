import React from "react";
import {getAllUserInvitesForCompany} from "../services/UserInvitesService";
import {BackNavigationLink, GenericButton, GenericHeader, GenericTable} from "@eazy2biz-ui/common-components";
import {getColumnsForInvitesList, getTableDataForInvitesList} from "../helpers/InvitesHelper";
import {Container} from "react-bootstrap";
import styles from "./UserAndUserGroup.module.css";
import {INVITE_USER_BUTTON_TEXT, INVITES_HEADER} from "../../contents/DisplayContent";
import {InviteUserModal} from "./InviteUserModal";
import {SecurityGroupFeatures, UserInviteResponse, UserInviteStatus} from "@eazy2biz/common-util";
import {UserActions, UserAuthorizationService} from "@eazy2biz/common-package-ui";

export const InvitesComponent = (): JSX.Element => {
    const columns = getColumnsForInvitesList();
    const [pendingInvites, setPendingInvites] = React.useState(new Array());
    const [showModal, toggleShowModal] = React.useState<boolean>(false);
    const [hasPermissionToInviteUsers, setHasPermissionToInviteUsers] = React.useState<boolean>(false);

    React.useEffect(() => {
        const userAuthorizationService = UserAuthorizationService.getInstance();
        userAuthorizationService.isActionAllowed(UserActions.CREATE, SecurityGroupFeatures.USERS)
            .then(hasPermission => {
                // user is admin and can invite users
                setHasPermissionToInviteUsers(hasPermission);
            });

        getAllUserInvitesForCompany().then((invites: UserInviteResponse[]) => {
            if (invites.length > 0) {
                setPendingInvites(
                    getTableDataForInvitesList(
                        invites.filter((invite: UserInviteResponse) => {
                            return invite.inviteStatus === UserInviteStatus.NEW
                        })
                    )
                );
            }
        });
    }, []);

    const handleInvitesModalClose = () => {
        toggleShowModal(false);
    }

    const renderSubHeader = (): JSX.Element => {
        return <BackNavigationLink/>;
    }

    const renderSectionButtons = (): JSX.Element | null => {
        return hasPermissionToInviteUsers ?
            <div>
                <GenericButton onClick={() => toggleShowModal(true)} buttonText={INVITE_USER_BUTTON_TEXT} />
            </div> : null;
    }

    return (
        <Container className={styles.container}>
            <GenericHeader
                header={INVITES_HEADER}
                subHeader={renderSubHeader}
                sectionButtons={renderSectionButtons}
            />
            <GenericTable columns={columns} data={pendingInvites ?? []} totalItems={(pendingInvites || []).length} showFiltersOption={true} showSearchOption={true} />
            <InviteUserModal showModal={showModal} onClose={handleInvitesModalClose} />
        </Container>
    );
};
