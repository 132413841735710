import {ReactElement} from "react";
import classNames from "classnames";
import { Card } from "antd";

import styles from './GenericImageButton.module.css'

const GenericImageButton = (
    props : {
        onClick: () => void;
        buttonText?: string | ReactElement;
        buttonImageSrc: string;
        className?: string;
        buttonTextClassName? : string;
        showSelected?: boolean;
    }
) : JSX.Element => {


    const {
        onClick,
        buttonText,
        buttonImageSrc,
        className,
        buttonTextClassName,
        showSelected
    } = props;

    return (
        <Card
            hoverable={true}
            className={classNames(
                styles.imageButtonCard,
                className,
                {
                    [styles.selectedImageButtonCard] : showSelected
                }
            )}
            onClick={onClick}
        >
            <img
                className={styles.imageStyles}
                src={buttonImageSrc}
            />
            {
                buttonText && (
                    <div
                        className={classNames(styles.buttonText, buttonTextClassName)}
                    >
                        {buttonText}
                    </div>
                )
            }
        </Card>
    );
}

export default GenericImageButton;
