import React from "react";
import {Pie} from "@ant-design/charts";

import styles from './GenericPieChart.module.css'

export const GenericPieChart = (
    props: {
        data: any[]
        groupingFieldKey : string,
        aggregationValueKey : string
    }

): JSX.Element => {

    const {
        data,
        groupingFieldKey,
        aggregationValueKey
    } = props;

    return (
       <div className={styles.pieChart}>
           <Pie
               autoFit={true}
               appendPadding={10}
               data={data}
               colorField={groupingFieldKey}
               angleField={aggregationValueKey}
               radius={0.9}
               label={{
                   type: 'spider',
                   labelHeight: 30,
                   content: '{name}\n{value} ({percentage})',
               }}
               interactions={[
                   {
                       type: 'pie-legend-active',
                   },
                   {
                       type: 'element-active',
                   },
               ]}
           />
       </div>
    );
}
