import {InputField, Toast} from "@eazy2biz-ui/common-components";
import {useState} from "react";
import {confirmPasswordField, emailField, passwordField, userNameField, countryCodeField, phoneNumberField} from "../../constants/keys";
import {signUp} from "../../services/GlobalUserService";
import styles from "./SignUpForm.module.css";
import {
    AlreadyMember,
    EmailPlaceholder, ERROR_STRINGS,
    NamePlaceholder, PasswordPlaceholder, SignInLinkText,
    SignUpHeaderText,
    SignUpSubHeaderText, SUCCESS_STRINGS, countryCodePlaceholder, phoneNumberPlaceholder, PASSWORD_ERROR_STRING
} from "../../../contents/DisplayContent";
import SignInWithGoogle from "./SignInWithGoogle";
import {PersonIcon, EnvelopeClosedIcon, LockClosedIcon} from "@radix-ui/react-icons";
import {resolveFeatureFlag} from "@eazy2biz-ui/common-package";
import {IdentitiesWrapperModal} from "../common/identitiesWrapperModal/IdentitiesWrapperModal";
import {IdentityGenericButton} from "../common/identityGenericButton/IdentityGenericButton";
import { GenericException, ErrorTypes, DEFAULT_COUNTRY_MOBILE_CODE } from "@eazy2biz/common-package-ui";
import { isValidPassword } from "../../helpers/PasswordHelper";

/**
 * SignUpForm
 * @param props
 * @constructor
 */
const SignUpForm = (props: PropTypes): JSX.Element => {
    const [email, setEmail] = useState('');
    const [pwd, setPwd] = useState('');
    const [confirmPwd, setConfirmPwd] = useState('');
    const [countryCode, setCountryCode] = useState(DEFAULT_COUNTRY_MOBILE_CODE);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [name, setName] = useState('');
    const [signUpSuccess, setSignUpSuccess] = useState(false);
    const [signUpInProgress, setSignUpInProgress] = useState<boolean>(false);
    const phoneNumberRegex = /^\d{10}$/;

    const handleFieldsChange = (e: any) => {
        switch (e.target.id) {
            case userNameField: setName(e.target.value); break;
            case emailField: setEmail(e.target.value); break;
            case passwordField: setPwd(e.target.value); break; // TODO: encrypt
            case countryCodeField: setCountryCode(e.target.value);break;
            case phoneNumberField: setPhoneNumber(e.target.value);break;
            case confirmPasswordField: setConfirmPwd(e.target.value); break; // TODO: encrypt
        }
    };

    /**
     * Handles successful user sign up.
     */
    const handleSubmit = () => {
        setSignUpInProgress(true);
        signUp(name, email, pwd, {countryCode, phoneNumber}).then(() => {
            setSignUpSuccess(true);
            Toast.success(SUCCESS_STRINGS.SIGN_UP);
            props.onSignUpSuccess(email, pwd);
        }).catch((error: GenericException) => {
            setSignUpInProgress(false);
            Toast.error(new GenericException(ErrorTypes.UNKNOWN_ERROR,  ERROR_STRINGS.SIGN_UP, error.displayMessage), ERROR_STRINGS.SIGN_UP)
        });
    };

    const navigateToLoginPage = () => {
        props.onClose();
    }

    const onClose = () => {
        props.onClose();
    }


    const renderSignUpForm = () => {
        return (
            <>
                <InputField
                    id={userNameField}
                    bordered={true}
                    label={'Name'}
                    placeholder={NamePlaceholder}
                    prefix={<PersonIcon className={styles.signUpFieldIcon} />}
                    type={"name"}
                    onChange={handleFieldsChange}
                />

                <InputField
                    id={emailField}
                    bordered={true}
                    label={'Email Id'}
                    placeholder={EmailPlaceholder}
                    type={"email"}
                    prefix={<EnvelopeClosedIcon className={styles.signUpFieldIcon} />}
                    onChange={handleFieldsChange}
                />

                <div className={styles.signUpPhoneField}>
                    <InputField
                        id={countryCodeField}
                        bordered={true}
                        disabled
                        label={'Code'}
                        placeholder={countryCodePlaceholder}
                        initialValue={DEFAULT_COUNTRY_MOBILE_CODE}
                        className={styles.signUpcountryCodeFieldInput}
                        type={"number"}
                        onChange={handleFieldsChange}
                    />
                   <InputField
                        id={phoneNumberField}
                        bordered={true}
                        label={'Phone Number'}
                        placeholder={phoneNumberPlaceholder}
                        className={styles.signUpPhoneNumberFieldInput}
                        type={"number"}
                        onChange={handleFieldsChange}
                        isInValid={phoneNumber!='' && !phoneNumberRegex.test(phoneNumber)}
                        inValidText={'Give Valid 10 Digit Phone No.'}
                    />
                </div>

                <InputField
                    id={passwordField}
                    bordered={true}
                    label={'Password'}
                    placeholder={PasswordPlaceholder}
                    type={"password"}
                    prefix={<LockClosedIcon className={styles.signUpFieldIcon} />}
                    onChange={handleFieldsChange}
                    isInValid={isValidPassword(pwd)}
                    inValidText={PASSWORD_ERROR_STRING.LENGTH_ERROR}
                />

                <InputField
                    id={confirmPasswordField}
                    bordered={true}
                    label={'Confirm Password'}
                    placeholder={PasswordPlaceholder}
                    type={"password"}
                    prefix={<LockClosedIcon className={styles.signUpFieldIcon} />}
                    onChange={handleFieldsChange}
                    isInValid={pwd !== confirmPwd && confirmPwd !== ''}
                    inValidText={PASSWORD_ERROR_STRING.CONFIRM_PASS_ERROR}
                />
            </>
        );
    };

    const renderSuccessMessage = () => {
        return (
            <div>
                Sign Up Successful. You will get a confirmation link on your mail.
            </div>
        );
    };

    const renderBody = () => {
        return(
            <>
                {
                    resolveFeatureFlag('GOOGLE_SIGN_IN') &&
                    <>
                        <SignInWithGoogle />
                        <div className={styles.signUpOptionsDivider}>or</div>
                    </>
                }
                <div>
                    <form>
                        {!signUpSuccess? renderSignUpForm() : renderSuccessMessage()}
                    </form>
                </div>
                {!signUpSuccess &&
                <>
                    <IdentityGenericButton
                        isLoading={signUpInProgress}
                        onClick={handleSubmit}
                        buttonText="Sign Up"
                        disabled={pwd !== confirmPwd && pwd !== '' && phoneNumber != ''}
                    />
                    <div className={styles.signInText}>{AlreadyMember} <span className={styles.signInLink} onClick={navigateToLoginPage}>{SignInLinkText}</span></div>
                </>
                }
            </>
        );
    };

    return (
        <IdentitiesWrapperModal
            title={SignUpHeaderText}
            subTitle={SignUpSubHeaderText}
            onClose={onClose}
            getBody={renderBody}
        />
    );
}

export default SignUpForm;

type PropTypes = {
    onClose: () => void;
    onSignUpSuccess: (email: string, pwd: string) => void;
}
