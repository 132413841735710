export enum RoutesEnum {
    APP_DASHBOARD = '/',
    USERS = '/manageUsers/users',
    INVITES = '/manageUsers/invites',
    USER_GROUPS = '/manageUsers/userGroups',
    DEPARTMENTS = '/manageUsers/departments',
    SWITCH_COMPANY = '/switchCompany',
    USER_SIGN_IN = '/signIn',
    CHANGE_PASSWORD = '/changePassword',
    CONFIRM_USER_SIGN_UP = '/confirmSignUp/:userEmail',
    USER_INVITATION = '/inviteConsent/:inviteId',
    WORKFLOW_BUILDER = '/workflowBuilder',
    INTERNAL_ITEM_DETAILS = '/workflow/:workflowId/item-details/:itemId',
    WORKFLOW_HOMEPAGE = '/workflow/:id',
    WORKFLOW_HOMEPAGE_TAB = '/workflow/:id/:tab',
    TEMPLATE_MANAGEMENT_HOMEPAGE = '/templateManagement/',
    TEMPLATE_MANAGEMENT_HOMEPAGE_TAB = '/templateManagement/:tab',
    UPDATE_WHATSAPP_TEMPLATE = '/templateManagement/updateWhatsappTemplate/:templateId',
    CREATE_WHATSAPP_TEMPLATE = '/templateManagement/createWhatsappTemplate/',
    UPDATE_PDF_TEMPLATE = '/templateManagement/createPdfTemplate/:templateId',
    CREATE_PDF_TEMPLATE = '/templateManagement/createPdfTemplate/',
    CUSTOMER_TABLE ='/tables',
    ERROR_PAGE = '*'
}
