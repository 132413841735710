import {CustomerTableHeader, CustomerTableHeaderTypes} from "@eazy2biz/common-util";
import {GenericListOrderComponent, ModalTitle, ToggleButton} from "@eazy2biz-ui/common-components";
import {TABLE_COLUMNS_BUILDER, TABLE_HEADER_DEFAULT_NAMES} from "../../../../contents/DisplayContent";
import styles from './CustomerTableBuilderHeaderPlayground.module.css';

/**
 * Customer Table Header Builder Playground view.
 * @param props
 * @constructor
 * @author Raghav Agarwal
 */
export const CustomerTableBuilderHeaderPlayground = (props: PropTypes) => {

    const renderRightActions = (tableHeader: CustomerTableHeader) => {
        if (props.titleHeaderId === tableHeader._id || tableHeader.headerType !== CustomerTableHeaderTypes.TEXT) {
            return null;
        }

        const handleToggle = (checked: boolean, event: MouseEvent) => {
            event.stopPropagation();
            props.updateTitleHeaderId(tableHeader._id)
        };

        return (
            <ToggleButton
                value={false}
                onToggle={handleToggle}
                label={TABLE_COLUMNS_BUILDER.MAKE_TITLE} />
        );
    };

    const renderHeaders = (tableHeader: CustomerTableHeader) => {
        return (
            <GenericListOrderComponent
                key={tableHeader._id}
                title={tableHeader.name}
                subTitle={TABLE_HEADER_DEFAULT_NAMES[tableHeader.headerType]}
                deletable
                onDelete={() => props.onFieldDelete(tableHeader._id)}
                onSelect={() => props.onFieldSelect(tableHeader._id)}
                selected={tableHeader._id === props.selectedHeaderId}
                tags={props.titleHeaderId === tableHeader._id ? [TABLE_COLUMNS_BUILDER.TITLE_HEADER] : []}
                renderRightActions={() => renderRightActions(tableHeader)}
            />
        );
    };

    return (
        <div className={styles.tableBuilderPlayground}>
            <ModalTitle title={TABLE_COLUMNS_BUILDER.TABLE_COLUMNS} />
            <div className={styles.headerWrapper}>
                {props.tableHeaders.map(renderHeaders)}
            </div>
        </div>
    );
};

type PropTypes = {
    tableHeaders: CustomerTableHeader[];
    selectedHeaderId?: string;
    onFieldSelect: (headerId?: string) => void;
    onFieldDelete: (headerId: string) => void;
    titleHeaderId: string;
    updateTitleHeaderId: (id: string) => void;
};
