import { generateTableDataFromItems, RowMetaData } from "@eazy2biz-ui/common-components";
import { getFormattedDate, humanizeSnakeCase } from "@eazy2biz/common-package-ui";
import { TemplateResponse } from "@eazy2biz/common-util";
import { getColumnsForPDFTemplatesList, PDFTemplatesListHeaders } from "../../config/pdf/PDFTemplatesListConfig";

const getRowMetaDataForTemplate = (template: TemplateResponse): RowMetaData => {
  return {
    id: template._id ?? ''
  };
};

const getValueForTemplate = (template: TemplateResponse, key: string) => {
  switch (key) {
    case PDFTemplatesListHeaders.TEMPLATE_NAME:
      return template.details.name;
    case PDFTemplatesListHeaders.LAST_UPDATED:
      return getFormattedDate(template.metaData.lastUpdatedDate);
    case PDFTemplatesListHeaders.STATUS:
      return humanizeSnakeCase(template.status.toLocaleLowerCase());
    default:
      return "";
  }
};

export const getTableDataForPDFTemplatesList = (templates: TemplateResponse[]) => {
  return generateTableDataFromItems(
    templates,
    getColumnsForPDFTemplatesList(),
    getValueForTemplate,
    getRowMetaDataForTemplate
  );
};
