import { TemplateCategory, TemplateResponse } from "@eazy2biz/common-util";
import { GenericModal } from "../../genericComponents/GenericModal/GenericModal";
import { useEffect, useState } from "react";
import { getAllTemplates } from "../../services/TemplateService";
import Toast from "../../genericComponents/Toast/Toast";
import { TEMPLATES_MODAL } from "../../../contents/DisplayContent";
import { Card } from "../card/card";
import GenericButton from "../../genericComponents/GenericButton/GenericButton";
import styles from './TemplatesSelectionModal.module.css';

/**
 * Generic Templates Selection Modal.
 * @param props
 * @constructor
 */
export const TemplatesSelectionModal = (props: PropTypes) => {

  const [templates, setTemplates] = useState<TemplateResponse[]>([]);

  useEffect(() => {
    Toast.load(TEMPLATES_MODAL.FETCHING_TEMPLATES);
    getAllTemplates(props.templateCategory)
      .then((response) => {
        setTemplates(response);
        Toast.destroyMessage();
        if (response.length === 0) {
          props.onCreateCustom && props.onCreateCustom();
        }
      }).catch((e) => Toast.error(e, TEMPLATES_MODAL.FETCH_TEMPLATES_ERROR))
  }, []);

  const renderRightHeader = () => {
    return (
      <div>
        {
          props.onCreateCustom &&
          <GenericButton buttonText={props.createCustomText || TEMPLATES_MODAL.CREATE_NEW} onClick={props.onCreateCustom} />
        }
      </div>
    );
  };

  const renderTemplateCard = (template: TemplateResponse) => {
    return (
      <Card
        key={template._id}
        cardTitle={template.details.name}
        className={styles.templateCard}
        onCardBodyClick={() => props.onTemplateSelect(template)}
      />
    );
  };

  const renderBody = () => {
    return (
      <div className={styles.templatesWrapper}>
        {templates.map(renderTemplateCard)}
      </div>
    );
  };

  return (
    <GenericModal
      show
      title={props.title}
      dialogClassName={styles.templateModal}
      contentWrapperClassName={styles.contentWrapper}
      rightHeader={renderRightHeader}
      bodyContent={renderBody}
      onClose={props.onClose} />
  );
};

type PropTypes = {
  title: string,
  templateCategory: TemplateCategory;
  onTemplateSelect: (template: TemplateResponse) => void;
  onClose: () => void;
  onCreateCustom?: () => void;
  createCustomText?: string;
};
