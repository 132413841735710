import {FieldSearch, SearchTypes, TemplateStatus} from "@eazy2biz/common-util";
import {statusPath} from "../constants/TemplateEntityConstants";

export const getApprovedTemplateFilters = (): FieldSearch[] => {
    return [{
        type: SearchTypes.VALUE_EQUAL,
        field: statusPath,
        value: TemplateStatus.APPROVED,
    }];
}
