import {get} from "lodash";
import {ColumnHeader, generateTableDataFromItems, RowData, RowMetaData} from "@eazy2biz-ui/common-components";
import {UserInviteResponse} from "@eazy2biz/common-util";
import { getFormattedDate, humanizeSnakeCase } from "@eazy2biz/common-package-ui";

const getValueForInvite = (invite: UserInviteResponse, key: string) => {
    switch (key) {
        case 'createdAt':
            return getFormattedDate(new Date(get(invite, "createdAt", "")));
        case 'emailAddress':
            return get(invite, "emailAddress", "");
        case 'inviteStatus':
            return humanizeSnakeCase(get(invite, "inviteStatus", "").toLocaleLowerCase());
        case 'id':
            return get(invite, "id", "");
        default:
            return "";
    }
};

const getMetaDataForInvites = (invite: UserInviteResponse): RowMetaData => {
    return {
        id: invite.id
    }
};

export const getColumnsForInvitesList = (): ColumnHeader[] => {
    return [
        {
            Header: 'Invite Status',
            accessor: 'inviteStatus',
        },
        {
            Header: 'Created At',
            accessor: 'createdAt',
        },
        {
            Header: 'Email',
            accessor: 'emailAddress',
        },
        {
            Header: 'Invite Id',
            accessor: 'id',
        }
    ];
};

export const getTableDataForInvitesList = (pendingInvites: UserInviteResponse[]): RowData[] => {
    return generateTableDataFromItems(pendingInvites, getColumnsForInvitesList(), getValueForInvite, getMetaDataForInvites);
}
