import {InviteUserRequest, SecurityGroupIdentifier} from "@eazy2biz/common-util";
import {StrictBuilder} from "builder-pattern";

export const getInviteUserRequest = (name: string, email: string, userGroupsToJoin: string[], securityGroupsToJoin : SecurityGroupIdentifier[], otherInfo: any) : InviteUserRequest => {
    return StrictBuilder<InviteUserRequest>()
        .name(name)
        .emailAddress(email)
        .userGroupsToJoin(userGroupsToJoin)
        .securityGroupsToJoin(securityGroupsToJoin)
        .otherInfo(otherInfo)        
        .build()
};
