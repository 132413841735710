import {ReactElement} from "react";
import {Draggable, DraggableProvided, DraggableStateSnapshot} from "react-beautiful-dnd";
import styles from './PaletteItem.module.css';
import {PaletteItemConfig} from "@eazy2biz-ui/common-package";

const PaletteItem = (props: PropTypes): ReactElement => {

    const renderItem = (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
        return (
            <>
                <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className={styles.formBuilderPaletteItem}>
                    <img src ={props.itemConfig.icon} className={styles.paletteIcon}/>
                    {props.itemConfig.label}
                    </div>
                {snapshot.isDragging &&
                <div className={styles.formBuilderPaletteItem}>
                    {props.itemConfig.label}
                </div>}
            </>
        );
    };

    return (
        <Draggable draggableId={props.itemConfig.id} index={props.index}>
            {renderItem}
        </Draggable>
    );
}

type PropTypes = {
    itemConfig: PaletteItemConfig<any>;
    index: number;
};

export default PaletteItem;
