import {RestServices} from "@eazy2biz/common-package-ui";
import {
    EntitySearch,
    ReportCreateRequest,
    ReportResponse,
    ReportUpdateAttributes
} from "@eazy2biz/common-util";

const REPORT_SERVICE = () => RestServices.getInstance().getReportService();

export const getAllReports = () : Promise<ReportResponse[]> => {
    return REPORT_SERVICE().getAllReports();
}

export const getAllReportsCount = () : Promise<number> => {
    return REPORT_SERVICE().getAllReportsCount();
}

export const createReport = (reportCreateRequest: ReportCreateRequest) : Promise<ReportResponse> => {
    return REPORT_SERVICE().createReports(reportCreateRequest);
}

export const getFilteredReports = (entitySearch: EntitySearch) : Promise<ReportResponse[]> => {
    return REPORT_SERVICE().getFilteredReports(entitySearch);
}

export const getFilteredReportsCount = (entitySearch: EntitySearch) : Promise<number> => {
    return REPORT_SERVICE().getFilteredReportsCount(entitySearch);
}

export const getReportById = (reportId: string) : Promise<ReportResponse> => {
    return REPORT_SERVICE().getReportById(reportId);
}

export const patchReportById = (reportId: string, reportUpdateAttributes: ReportUpdateAttributes) : Promise<ReportResponse> => {
    return REPORT_SERVICE().patchReportById(reportId, reportUpdateAttributes);
}

export const deleteReportById = (reportId: string) : Promise<ReportResponse> => {
    return REPORT_SERVICE().deleteReportById(reportId);
}
