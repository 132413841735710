import * as React from "react";
import { useEffect, useState } from "react";
import {
  BackNavigationLink,
  GenericHeader,
  GenericStepForm,
  GenericTag,
  SectionButtonsType,
  Toast
} from "@eazy2biz-ui/common-components";
import {
  CREATE_NEW_TEMPLATE,
  CREATE_TEMPLATE_STRINGS,
  TEMPLATE_CONFIGURATION_STRINGS
} from "../../../../contents/DisplayContent";
import styles from "./CreateWhatsappTemplate.module.css";
import { WhatsappTemplateSettings } from "./whatsappTemplateSettings/WhatsappTemplateSettings";
import { WhatsappTemplateConfiguration } from "./whatsappTemplateConfiguration/WhatsappTemplateConfiguration";
import {
  checkIfMandatoryFieldsAreFilledForWhatsappTemplateConfiguration,
  checkIfMandatoryFieldsAreFilledForWhatsappTemplateSettings,
  getDefaultWhatsappTemplateConfiguration,
  WhatsappTemplateConfigurationType
} from "../../../helpers/whatsapp/CreateWhatsappTemplateHelper";
import { createNewWhatsappTemplate, getWhatsappTemplateId } from "../../../services/whatsapp/WhatsappTemplateService";
import { useHistory, useParams } from "react-router-dom";
import { RoutesEnum } from "@eazy2biz-ui/common-package";
import { TemplateResponse } from "@eazy2biz/common-util";
import { getTagTypeForTemplateStatus } from "../../../helpers/TemplateHelper";

export const CreateWhatsappTemplate = (): JSX.Element => {
  const history = useHistory();
  // @ts-ignore
  let { templateId } = useParams();

  const [whatsappTemplateConfiguration, updateWhatsappTemplateConfiguration] =
  React.useState<WhatsappTemplateConfigurationType>(getDefaultWhatsappTemplateConfiguration());
  const [whatsAppTemplate, setWhatsappTemplate] = useState<TemplateResponse>();
  const [templateCreationProgress, setTemplateCreationProgress] = useState(false);
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    if (templateId) {
      getWhatsappTemplateId(templateId)
        .then(res => {
          updateWhatsappTemplateConfiguration(getDefaultWhatsappTemplateConfiguration(res));
          setWhatsappTemplate(res);
          setReadOnly(true);
        })
        .catch(() => {
          Toast.warn(CREATE_TEMPLATE_STRINGS.TEMPLATE_NOT_FOUND);
        })
    }
  }, []);

  const handleCreation = () => {
    Toast.load(CREATE_TEMPLATE_STRINGS.TEMPLATE_CREATION_PROGRESS.WHATSAPP);
    setTemplateCreationProgress(true);
    createNewWhatsappTemplate(whatsappTemplateConfiguration)
      .then(() => {
        Toast.success(CREATE_TEMPLATE_STRINGS.TEMPLATE_CREATION_SUCCESS.WHATSAPP);
        history.replace(RoutesEnum.TEMPLATE_MANAGEMENT_HOMEPAGE_TAB.replace(':tab', 'whatsapp'));
      }).catch((err) => Toast.error(err, CREATE_TEMPLATE_STRINGS.TEMPLATE_CREATION_FAILURE.WHATSAPP))
      .finally(() => setTemplateCreationProgress(false));
  };

  const getWhatsappTemplateSettingsButtons = (): SectionButtonsType => {
    return {
      back: {
        disabled: true
      },
      next: {
        disabled: !checkIfMandatoryFieldsAreFilledForWhatsappTemplateSettings(whatsappTemplateConfiguration)
      }
    };
  };

  const getWhatsappTemplateConfigurationButtons = (): SectionButtonsType => {
    return {
      back: {
        disabled: false
      },
      next: {
        buttonText: readOnly && templateId ? TEMPLATE_CONFIGURATION_STRINGS.UPDATE_TEMPLATE : TEMPLATE_CONFIGURATION_STRINGS.CREATE_TEMPLATE,
        onButtonClick: handleCreation,
        disabled: !checkIfMandatoryFieldsAreFilledForWhatsappTemplateConfiguration(whatsappTemplateConfiguration) || readOnly,
        isLoading: templateCreationProgress
      }
    };
  };

  const renderSubHeader = (): JSX.Element => {
    return <BackNavigationLink />
  };

  const renderHeader = (): JSX.Element => {
    return (
      <div className={styles.header}>
        {templateId && readOnly ? whatsappTemplateConfiguration.name : CREATE_NEW_TEMPLATE.WHATSAPP}
        {templateId && readOnly && <GenericTag title={whatsAppTemplate?.status} type={getTagTypeForTemplateStatus(whatsAppTemplate?.status)} />}
      </div>
    )
  };

  return (
    <div className={styles.container}>
      <GenericHeader
        header={renderHeader()}
        subHeader={renderSubHeader} />
      <GenericStepForm
        sectionButtons={[
          getWhatsappTemplateSettingsButtons(),
          getWhatsappTemplateConfigurationButtons(),
        ]}
        sections={[
          <WhatsappTemplateSettings
            configuration={whatsappTemplateConfiguration}
            updateConfiguration={updateWhatsappTemplateConfiguration}
            readOnly={readOnly}
          />,
          <WhatsappTemplateConfiguration
            configuration={whatsappTemplateConfiguration}
            updateConfiguration={updateWhatsappTemplateConfiguration}
            readOnly={readOnly}
          />
        ]}
        sectionNames={[
          CREATE_TEMPLATE_STRINGS.SECTION_NAME_STRINGS.SETTINGS,
          CREATE_TEMPLATE_STRINGS.SECTION_NAME_STRINGS.CONFIGURATION
        ]} />
    </div>
  );
};
