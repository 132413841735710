import { ReactElement } from "react";
import { Droppable, DroppableProvided } from "react-beautiful-dnd";
import PaletteItem from "./paletteItem/PaletteItem";
import "./Palette.css";
import { PaletteItemConfig } from "@eazy2biz-ui/common-package";
import { PALETTE_DROPPABLE_ID } from "../configs/dndConfig";
import { useFeatureTour } from "../../../FeatureTour/FeatureTourProvider";
import { TourType } from "../../../FeatureTour/entity/TourType";

const Palette = (props: PropTypes): ReactElement => {

    const {triggerTour, getRef} = useFeatureTour();
    const renderItems = () => {
        return props.paletteItems.map((itemConfig, index) => <PaletteItem itemConfig={itemConfig} index={index}/>);
    }

    const renderPalette = (provided: DroppableProvided) => {
        return(
            <div {...provided.droppableProps} ref={provided.innerRef} className={'formBuilder-palette'}>
                <div ref={getRef(0, TourType.FORM_BUILDER)} onMouseEnter={() => triggerTour(TourType.FORM_BUILDER)}>
                    {renderItems()}
                </div>
            </div>
        );
    }

    return (
        <Droppable droppableId={PALETTE_DROPPABLE_ID} isDropDisabled>
            {renderPalette}
        </Droppable>
    );
};

export default Palette;

type PropTypes = {
    paletteItems: PaletteItemConfig<any>[];
};
