import React, {useEffect, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import {DEFAULT_DATE_FORMAT} from "@eazy2biz/common-package-ui";
import {InputProps as DefaultInputPropTypes} from "antd/lib/input/Input";
import classNames from "classnames";
import styles from './GenericDateRangePicker.module.css';
import { Moment } from "moment";
import { CloseCircleOutlined } from "@ant-design/icons";
import generatePicker from "antd/lib/date-picker/generatePicker";
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import moment from "moment/moment";

/**
 * Generic Date Picker
 * @param props
 * @constructor
 */
export const GenericDateRangePicker = (props: PropTypes) => {

    const {dateFormat, label, defaultStartDate, defaultEndDate} = props;

    const { RangePicker } = generatePicker<Moment>(momentGenerateConfig);

    const [startDateMoment, setStartDateMoment] = useState<Moment | null>(defaultStartDate);
    const [endDateMoment, setEndDateMoment] = useState<Moment | null>(defaultEndDate);


    useEffect(() => {
        setStartDateMoment(defaultStartDate)
        setEndDateMoment(defaultEndDate)
    }, [defaultStartDate, defaultEndDate]);


    const handleOnRangeChange = (dates: [Moment | null, Moment | null] | null) => {
        const startDate = dates && dates[0] ? dates[0].startOf('day') : null;
        const endDate = dates && dates[1] ? dates[1].endOf('day') : null;
        setStartDateMoment(startDate)
        setEndDateMoment(endDate)
        props.handleRangeChange(startDate, endDate);
    };

    const renderLabel = () => {
        return (
            <p className={styles.dropdownLabel}>{label}</p>
        )
    };

    return (
        <>
            {label && renderLabel()}
            <RangePicker
                style={{height : 'fit-content'}}
                allowClear
                className={classNames(props.className, styles.dateRangePicker)}
                onChange={handleOnRangeChange}
                disabled={props.disabled || props.readOnly}
                inputReadOnly={props.readOnly}
                presets={props.rangePresets}
                placeholder={props.placeholders}
                value={[startDateMoment, endDateMoment]}
                format={dateFormat || DEFAULT_DATE_FORMAT}
                clearIcon={<CloseCircleOutlined/>}
                popupStyle={{zIndex:1100}}
            />
        </>
    );
};

export const getDefaultDateRangePresets = () : {
    label: string;
    value: [Moment, Moment];
}[] => {
    return [
        { label: 'Last 7 Days', value: [moment().add(-7, 'day'), moment()] },
        { label: 'Last 14 Days', value: [moment().add(-14, 'day'), moment()] },
        { label: 'Last 30 Days', value: [moment().add(-30, 'day'), moment()] },
        { label: 'Last 90 Days', value: [moment().add(-90, 'day'), moment()] },
    ];
}

interface PropTypes extends DefaultInputPropTypes {
    label?: string;
    defaultStartDate: Moment | null;
    defaultEndDate: Moment | null;
    handleRangeChange: (startDate: Moment | null, endDate : Moment | null) => void;
    rangePresets?: {
        label: string;
        value: [Moment, Moment];
    }[]
    placeholders?: [string, string]
    dateFormat? : string
};
