import { useEffect, useState } from 'react';
import { getFilterCardById } from '../../../services/CardService';
import { getAppliedFilterConfigFromItemFilters, getTableDataForItems } from '../../../helpers/CardTableHelper';
import CardDetails from '../../cardDetails/CardDetails';
import {DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, GenericTable, Toast, getArrayPage, FilterComponentProps} from "@eazy2biz-ui/common-components";
import {CardResponse, WorkflowResponse} from "@eazy2biz/common-util";
import {ITEMS_STRINGS} from "../../../../contents/DisplayContent";
import { getColumnsForItems } from '../../../configs/ItemsTableConfig';
import {ItemFilterMenu, ItemFilterConfig} from './ItemFilterComponents/ItemFilterMenu';

const ItemsTable = (props: PropType) => {
  const { workflow, filterConfig, setFilterConfig } = props;
  const [items, setItems] = useState<CardResponse[]>([]);
  const [tableData, setTableData] = useState(new Array());
  const [selectedItemId, setSelectedItemId] = useState<string>();
  const [showCard, setShowCard] = useState(false);
  const columns = getColumnsForItems();

  const getCards = () => {
      getFilterCardById(filterConfig, workflow._id)
          .then((response: CardResponse[]) => {
              setItems(response || []);
              setTableData(getTableDataForItems(getArrayPage(response, DEFAULT_PAGE_SIZE, DEFAULT_PAGE_NUMBER)));
          })
          .catch(() => Toast.errorString(ITEMS_STRINGS.ERROR_FETCH_ITEMS))
          .finally(() => {
              props.onRefreshCallback && props.onRefreshCallback(false);
          });
  }

  useEffect(() => {
    getCards();
  },[]);

  useEffect(() => {
    if (props.refreshTable) {
      getCards();
    }
  }, [props.refreshTable]);

  useEffect(() => {
    getCards();
  }, [filterConfig]);

  const handlePageChange = (page: number, pageSize: number) => {
      setTableData(getTableDataForItems(getArrayPage(items, pageSize, page)));
  };

  const handleClick = (itemId: string) => {
    setSelectedItemId(itemId);
    setShowCard(true);
  };

  const handleHide = () => {
    getCards();
    setShowCard(false);
    setSelectedItemId(undefined);
  };

  const renderItemFilter = (filterProps: FilterComponentProps) => {
    return(
        <ItemFilterMenu
          workflow={workflow}
          filterConfig={filterConfig}
          fields={workflow.fields}
          onClose={filterProps.onClose}
          onFilterChange={setFilterConfig} />
    );
  };

  const renderCardDetails = () => {
      if (showCard && selectedItemId) {
          const selectedItem = items.find((val => val._id === selectedItemId));
          if (selectedItem) {
              return (
                  <CardDetails
                      workflow={workflow}
                      cardData={selectedItem}
                      cardModalShow={showCard}
                      onHide={handleHide}
                  />
              );
          }
      }

      return;
  }

  const clearFilters = () => {
    setFilterConfig(ItemFilterConfig.getClearFilter());
  };

  return (
    <div>
      <GenericTable
        columns={columns}
        data={tableData}
        defaultActionEnabled
        paginationEnabled
        totalItems={items.length}
        onPageChange={handlePageChange}
        onDefaultActionClick={handleClick}
        showFiltersOption
        getFilterComponent={renderItemFilter}
        onClearFilters={clearFilters}
        showSettings
        downloadEnabled
        appliedFilterConfig={getAppliedFilterConfigFromItemFilters(() => filterConfig, setFilterConfig)}/>
        {renderCardDetails()}
    </div>
  );
};

type PropType = {
  workflow: WorkflowResponse;
  refreshTable: boolean;
  onRefreshCallback?: (value: boolean) => void;
  filterConfig: ItemFilterConfig;
  setFilterConfig: (filterConfig: ItemFilterConfig) => void;
};

export default ItemsTable;
