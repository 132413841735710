import { useEffect, useState } from "react";
import { CopyIcon, Pencil2Icon, PlusIcon, TrashIcon } from "@radix-ui/react-icons";
import WorkflowDetails from "./WorkflowDetails";
import MyWorkflowIcon from "../../../../assets/myWorkflowIcon.svg";
import styles from "./WorkflowHomepage.module.css";
import {
  AlertModalTypes,
  BackNavigationLink,
  GenericButton,
  GenericButtonTypes,
  Toast,
  TourType,
  useAlertModal,
  useFeatureTour
} from "@eazy2biz-ui/common-components";
import { WORKFLOW_HOMEPAGE, workflowContent } from "../../../../contents/DisplayContent";
import CreateItemModal from "../CreateItemModal";
import {
  getDraftWorkflowStateKey,
  getWorkflowDraftJSONFromBlueprint,
  LocalStorageService,
  RoutesEnum
} from "@eazy2biz-ui/common-package";
import { CachedStore, StoreEntityTypes, UserActions, UserAuthorizationService } from "@eazy2biz/common-package-ui";
import { useHistory, useParams } from "react-router-dom";
import { SecurityGroupFeatures, WorkflowResponse } from "@eazy2biz/common-util";
import { getAllPendingTodosCount } from "../../../services/UserTodoService";
import { disableWorkflowById } from "../../../services/WorkflowService";

const WorkflowHomepage = () => {
  // @ts-ignore
  const { id: workflowId, tab: defaultTab } = useParams();

  const [refreshData, setRefreshData] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [createWorkflowAllowed, setCreateWorkflowAllowed] = useState(false);
  const [editWorkflowAllowed, setEditWorkflowAllowed] = useState(false);
  const [workflow, setWorkflow] = useState<WorkflowResponse>();
  const [activeTodoCount, setActiveTodoCount] = useState<number>(1);
  const history = useHistory();

  const {triggerTour, getRef} = useFeatureTour();
  const {setAlertModalConfig} = useAlertModal();

  useEffect(() => {
    CachedStore.getEntityFromListById<WorkflowResponse>(StoreEntityTypes.WORKFLOWS, workflowId).then(
      (response: WorkflowResponse) => {
        setWorkflow(response);
      }
    );

    getAllPendingTodosCount(workflowId)
      .then((response) => {
        setActiveTodoCount(response);
      })

      .catch(() => Toast.errorString(WORKFLOW_HOMEPAGE.ERROR_FETCH_WORKFLOW));

    // TODO: FIX SecurityGroupFeatures
    UserAuthorizationService.getInstance().isActionAllowed(UserActions.CREATE, SecurityGroupFeatures.USERS)
        .then((allowed) => {
          setCreateWorkflowAllowed(allowed);
          setEditWorkflowAllowed(allowed);
        });
  }, [workflowId]);

  if (!workflow) {
    return null;
  }
  const { details } = workflow;

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleRefreshCallback = (value: boolean) => setRefreshData(value);

  const handleDuplicateWorkflow = () => {
    LocalStorageService.setItem(
      getDraftWorkflowStateKey(),
      getWorkflowDraftJSONFromBlueprint(workflow)
    );
    history.push(RoutesEnum.WORKFLOW_BUILDER);
  };

  const handleEditWorkflow = () => {
    LocalStorageService.setItem(
      getDraftWorkflowStateKey(),
      getWorkflowDraftJSONFromBlueprint(workflow, true)
    );
    history.push(RoutesEnum.WORKFLOW_BUILDER);
  };

  const disableWorkflow = () => {
    Toast.load(workflowContent.DISABLE_WORKFLOW_PROGRESS);
    disableWorkflowById(workflowId).then(() => {
      Toast.success(workflowContent.DISABLE_WORKFLOW_SUCCESS);
      history.replace(RoutesEnum.APP_DASHBOARD);
    }).catch((e) => Toast.error(e, workflowContent.DISABLE_WORKFLOW_ERROR));
  };

  const handleDisableWorkflow = () => {
    setAlertModalConfig({
      showCancelButton: true,
      onConfirm: disableWorkflow,
      type: AlertModalTypes.ALERT,
      title: workflowContent.DISABLE_WORKFLOW_ALERT_MESSAGE
    });
  };

  return (
    <div className={styles.workflowHomepage}>
      <div className={styles.workflowHeaderSection} onMouseEnter={() => triggerTour(TourType.WORKFLOW_HOMEPAGE)}>
        <div>
          <div className={styles.workflowNameSection}>
            <img alt="" className={styles.workflowIcon} src={MyWorkflowIcon} />
            <div className={styles.workflowName}>{details.name}</div>
          </div>
          <BackNavigationLink />
        </div>
        <div className={styles.workflowButtons}>
          <GenericButton
            ref={getRef(2, TourType.WORKFLOW_HOMEPAGE)}
            buttonText={workflowContent.DUPLICATE_WORKFLOW}
            CustomIcon={CopyIcon}
            onClick={handleDuplicateWorkflow}
            type={GenericButtonTypes.SECONDARY}
            disabled={!createWorkflowAllowed}
          />
          <GenericButton
            ref={getRef(1, TourType.WORKFLOW_HOMEPAGE)}
            type={GenericButtonTypes.SECONDARY}
            buttonText={workflowContent.EDIT_WORKFLOW}
            CustomIcon={Pencil2Icon}
            onClick={handleEditWorkflow}
            disabled={activeTodoCount > 0 || !editWorkflowAllowed}
          />
          <GenericButton
            type={GenericButtonTypes.SECONDARY}
            buttonText={workflowContent.DISABLE_WORKFLOW}
            CustomIcon={TrashIcon}
            onClick={handleDisableWorkflow}
            disabled={activeTodoCount > 0 || !editWorkflowAllowed}
          />
          <GenericButton
            ref={getRef(0, TourType.WORKFLOW_HOMEPAGE)}
            buttonText={workflowContent.NEW_ITEM}
            CustomIcon={PlusIcon}
            onClick={handleOpenModal}
          />
          <CreateItemModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            workflowId={workflow._id || ''}
            onSubmit={() => handleRefreshCallback(true)}
          />
        </div>
      </div>
      <div>
        <WorkflowDetails
          workflow={workflow}
          refreshData={refreshData}
          defaultTab={defaultTab}
          onRefreshCallback={handleRefreshCallback}
        />
      </div>
    </div>
  );
};

export default WorkflowHomepage;
