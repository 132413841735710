import { Input } from 'antd';
import styles from "./EditInput.module.css";

const EditInput = (props: PropType) => {
    const { value, size = 'small', onChange } = props;

    const handleOnChange = (event: { target: { value: string; }; }) => {
        onChange(event.target.value);
    }

    return (
        <div>
            <Input className={styles.inputName} bordered={false} value={value} size={size} onChange={handleOnChange}/>
        </div>
    )
};

type PropType = {
    value: string;
    size: any; //adding any here as there is limitation from antd on size as its type is sizeType defined there
    onChange: (value: string) => void;
}

export default EditInput;
