import { checkIfJWTTokenHasExpired } from "@eazy2biz/common-package-ui";
import {getCookieService, getEnvironmentConfig} from "../context/ApplicationContext";
import {CookieKeys} from "../cookies/CookieKeys";
import {RoutesEnum} from "../../resources/RoutesEnum";
import {SignInTokens} from "@eazy2biz/common-util";
import {UIApplicationInit} from "../context/UIApplicationInit";

/**
 * Checks if Token is expired.
 */
export const hasTokenExpired = async (): Promise<boolean> => {
    const accessToken: string = getCookieService().getFromCookies(CookieKeys.ACCESS_TOKEN);
    return Promise.resolve(!accessToken || checkIfJWTTokenHasExpired(accessToken));
};

/**
 * Saves refreshed tokens.
 */
export const refreshTokenCallback = async (response: SignInTokens) => {
    getCookieService().setToCookies(CookieKeys.ID_TOKEN, response.idToken);
    getCookieService().setToCookies(CookieKeys.ACCESS_TOKEN, response.accessToken, {maxAge: getEnvironmentConfig().cacheExpiry.softExpiry});
    getCookieService().setToCookies(CookieKeys.REFRESH_TOKEN, response.refreshToken);

    await setTimeout(() => {}, 500);

    return;
};

/**
 * Handles the sign out activity on token expiry.
 */
export const handleTokenExpire = () => {
    return UIApplicationInit.getInstance().postSignOutAppInit().then(() => {window.location.href = RoutesEnum.USER_SIGN_IN});
};


export const getAuthTokens = (): Promise<SignInTokens> => {
    return Promise.resolve({
        accessToken: getCookieService().getFromCookies(CookieKeys.ACCESS_TOKEN),
        refreshToken: getCookieService().getFromCookies(CookieKeys.REFRESH_TOKEN),
        idToken: getCookieService().getFromCookies(CookieKeys.ID_TOKEN)
    });
};
