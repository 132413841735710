import { ActionResult, ActionResultTypes, MessageDetail } from "@eazy2biz/common-util";

export const getMessageAction = (): ActionResult<MessageDetail> => {
    return {
        type: ActionResultTypes.MESSAGE,
        resultDetails: {
            toPhoneNumbers: [],
            messageTemplateId: '',
            senderHeaderId: '',
            body: '',
            enabled: true,
        }
    };
};
