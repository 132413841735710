import InputField from "../InputField/InputField";
import {useState} from "react";
import styles from './GenericListInput.module.css';
import GenericButton from "../GenericButton/GenericButton";
import {PlusIcon, CrossCircledIcon} from '@radix-ui/react-icons';
import { List } from "antd";
import {clone} from "lodash";

/**
 * Generic list Inputs
 * @param props
 * @constructor
 */
export const GenericListInput = (props: PropTypes) => {
    const [listValues, setListValues] = useState<string[]>(props.defaultValues);
    const [itemValue, setItemValue] = useState('');

    const handleOnItemAdd = () => {
        if (itemValue && itemValue !== '' && !listValues.includes(itemValue)) {
            const newValues = [...clone(listValues), itemValue];
            setListValues(newValues);
            props.onChange(newValues);
            setItemValue('');
        }
    };

    const handleRemoveItem = (index: number) => {
        let newValues = clone(listValues);
        newValues.splice(index, 1);
        setListValues(newValues);
    };

    const renderListItem = (item: string, index: number) => {
        return (
            <List.Item key={index + item} className={styles.listItemWrapper}>
                <div>{item}</div>
                <CrossCircledIcon onClick={() => handleRemoveItem(index)}/>
            </List.Item>
        );
    };

    const renderList = () => {
        return(
            <List
                className={styles.listWrapper}
                dataSource={listValues}
                renderItem={renderListItem}
            />
        );
    };

    return(
        <div>
            <div className={styles.listItemInputWrapper}>
                <InputField
                    id={props.id}
                    value={itemValue}
                    type={'text'}
                    className={styles.inputField}
                    onChange={(e) => setItemValue(e.target.value)}
                    label={'Enter List Value:'} />
                <GenericButton className={styles.addItemButton} onClick={handleOnItemAdd} CustomIcon={PlusIcon} />
            </div>
            {renderList()}
        </div>
    );
};

type PropTypes = {
    id: string;
    defaultValues: string[];
    onChange: (values: string[]) => void;
};
