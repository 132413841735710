import {InputProps as DefaultInputPropTypes} from "antd/lib/input/Input";
import {Field} from "@eazy2biz/common-util";
import classNames from "classnames";
import styles from './CardFormEmployee.module.css';
import { AssigneeSelect } from "../../../AssigneeSelect/AssigneeSelect";

/**
 * Card form field of type employees.
 * @param props
 * @constructor
 */
export const CardFormEmployeeComponent = (props: PropTypes) => {
  const handleAssigneeSelect = (assigneeIds: string[]) => {
    props.onChange && props.onChange({
    // @ts-ignore
      target: {
        // single select assignee component
        // @ts-ignore
        value: assigneeIds.length ? assigneeIds[0] : ''
      }
    });
  };

  return (
      <AssigneeSelect
          className={classNames(props.className, styles.cardDropdownSelect)}
          dropdownClassName={styles.cardDropdownWrapper}
          iconClassName={styles.iconClassName}
          disabled={props.disabled || props.readOnly}
          hideLabel
          multiSelect={false}
          onAssigneesSelect={handleAssigneeSelect}
          selectedAssignees={props.defaultValue ? [props.defaultValue.toString()] : []}/>
  );
};

interface PropTypes extends DefaultInputPropTypes {
  field: Field;
}
