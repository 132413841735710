import {ConfirmSignUpRequest, GlobalUserCreateRequest, PhoneNumberContact, SignInRequest} from "@eazy2biz/common-util";
import {Builder, StrictBuilder} from "builder-pattern";

export const getSignInPayload = (
    email: string,
    pass: string
) : SignInRequest => {
    return StrictBuilder<SignInRequest>()
        .emailAddress(email)
        .password(pass)
        .build()
};

export const getConfirmSignUpPayload = (
    email: string,
    passcode: string
) : ConfirmSignUpRequest => {
    return StrictBuilder<ConfirmSignUpRequest>()
        .emailAddress(email)
        .passcode(passcode)
        .build()
};

export const getSignUpPayload = (
    name : string,
    email : string,
    password : string,
    phoneNumber : PhoneNumberContact
) : GlobalUserCreateRequest => {
    return Builder<GlobalUserCreateRequest>()
        .name(name)
        .email(email)
        .password(password)
        .phoneNumber(phoneNumber)
        .build()
};
