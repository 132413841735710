import {
    GenericButton,
    GenericModal,
    InputField,
    ModalSubHeaderComponent,
    NumberSelect,
    Toast,
} from "@eazy2biz-ui/common-components";
import React, {useState} from "react";
import {EntityDefinitionTypes, ReportCreateRequest, ReportEntityType} from "@eazy2biz/common-util";
import styles from "./AddReportModal.module.css";
import {createReport} from "../../services/ReportService";
import {StrictBuilder} from "builder-pattern";
import {ERROR_STRINGS, SUBMIT} from "../../../contents/DisplayContent";

export const AddReportModal = (
    props : {
        entityType: ReportEntityType;
        entityId: string;
        onSuccessfulReportCreation : () => void
    }
) : JSX.Element => {

    const {
        entityType,
        entityId,
        onSuccessfulReportCreation
    } = props;

    const [reportName, setReportName] = useState(null);
    const [reportDescription, setReportDescription] = useState();

    const [defaultReportDurationInDays, setDefaultReportDurationInDays] = useState<number | null>(0);
    const [defaultReportDurationInMonths, setDefaultReportDurationInMonths] = useState<number | null>(0);

    const [reportCreateInProgress, setReportCreateInProgress] = useState(false);

    const [showFieldErrors, setShowFieldErrors] = useState(false)

    const handleCreateReport = () => {
        setShowFieldErrors(true);
        setReportCreateInProgress(true);
        if(reportName &&
            !(
                (defaultReportDurationInDays === 0 || defaultReportDurationInDays === null)
                && (defaultReportDurationInMonths === 0 || defaultReportDurationInMonths === null)
            )
        ) {
            createReport(
                StrictBuilder<ReportCreateRequest>()
                    .name(reportName)
                    .description(reportDescription)
                    .type(EntityDefinitionTypes.USER_DEFINED)
                    .entityType(entityType)
                    .entityId(entityId)
                    .defaultReportDuration(
                        `P${defaultReportDurationInMonths ? `${defaultReportDurationInMonths}M` : ''}${defaultReportDurationInDays ? `${defaultReportDurationInDays}D` : ''}`
                    )
                    .tags([])
                    .build()
            ).then(
                () => {
                    onSuccessfulReportCreation()
                    setReportCreateInProgress(false);
                }
            )
        }
        else {
            Toast.errorString(ERROR_STRINGS.INVALID_REPORTS_INPUT)
        }
    }

    return (
        <GenericModal
            show
            title={"Create Report"}
            subHeader={
                () =>
                    <ModalSubHeaderComponent
                        title={"Create a new report"}
                    />
            }
            bodyContent={() => (
                <>
                    <InputField
                        id="title"
                        label="Name"
                        highlightFieldError={showFieldErrors}
                        isInValid={!reportName}
                        initialValue={reportName || ''}
                        type="text"
                        onChange={(e) => setReportName(e.target.value)}
                    />

                    <div className={styles.descriptionField}>
                        <InputField
                            id="description"
                            label="Description"
                            initialValue={reportDescription || ''}
                            required={false}
                            type="textArea"
                            onChange={(e) => setReportDescription(e.target.value)}
                        />
                    </div>

                    <div className={styles.otherFieldsHeader} >
                        <span className={styles.otherFieldsTitle}>Default Report Duration</span><span className={styles.labelMandatoryText}>*</span>
                        <div className={styles.datePicker}>
                            <NumberSelect
                                id={'defaultReportDurationInDays'}
                                onChange={(value) => setDefaultReportDurationInDays(value)}
                                highlightFieldError={showFieldErrors}
                                isInValid={
                                    (defaultReportDurationInDays === 0 || defaultReportDurationInDays === null)
                                    && (defaultReportDurationInMonths === 0 || defaultReportDurationInMonths === null)
                                }
                                min={0}
                                max={30}
                                defaultValue={0}
                                value={defaultReportDurationInDays}
                            />
                            <span className={styles.datePickerLabel}>days</span>
                            <NumberSelect
                                id={'defaultReportDurationInMonths'}
                                onChange={(value) => setDefaultReportDurationInMonths(value)}
                                highlightFieldError={showFieldErrors}
                                isInValid={
                                    (defaultReportDurationInDays === 0 || defaultReportDurationInDays === null)
                                    && (defaultReportDurationInMonths === 0 || defaultReportDurationInMonths === null)
                                }
                                min={0}
                                max={24}
                                defaultValue={1}
                                value={defaultReportDurationInMonths}
                            />
                            <span className={styles.datePickerLabel}>months</span>
                        </div>
                    </div>
                </>
            )}
            footerContent={() => (
                <div className={styles.footerCtn}>
                    <GenericButton
                        isLoading={reportCreateInProgress}
                        onClick={handleCreateReport}
                        buttonText={SUBMIT}
                    />
                </div>
            )}
            onClose={onSuccessfulReportCreation}
            dialogClassName={styles.modal}
        />
    );
}
