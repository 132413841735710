import React, { ReactElement } from "react";
import {Droppable, DroppableProvided, DroppableStateSnapshot} from "react-beautiful-dnd";
import styles from './DropZone.module.css';
import classNames from "classnames";
import { TourType } from "../../../FeatureTour/entity/TourType";
import { useFeatureTour } from "../../../FeatureTour/FeatureTourProvider";

const DropZone = (props: PropTypes): ReactElement => {

    const {getRef} = useFeatureTour();
    const renderDroppableComponent = (provided: DroppableProvided, snapshot: DroppableStateSnapshot): React.ReactElement => {
        return (
          <div ref={getRef(1, TourType.FORM_BUILDER)}>
            <div {...provided.droppableProps} ref={provided.innerRef} className={classNames(styles.dropZone, {[styles.isDropping]: snapshot.isDraggingOver})}>
                {snapshot.isDraggingOver ? 'Drop the element to add.' : 'Drag & Drop elements here from the left panel to start building your form'}
            </div>
          </div>
        );
    };

    return (
        <Droppable droppableId={props.id}>
            {renderDroppableComponent}
        </Droppable>
    );
};

type PropTypes = {
    isDropping: boolean;
    id: string;
};

export default DropZone;
