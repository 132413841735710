import {ReportEntityType, WorkflowResponse} from '@eazy2biz/common-util';
import {ReportComponent} from '@eazy2biz-ui/reports-ui';

const WorkflowReportTabComponent = (props: PropTypes) => {
  const { workflow } = props;

  return (
    <div>
        <ReportComponent entityType={ReportEntityType.WORKFLOW} entityId={workflow._id} />
    </div>
  );
};

type PropTypes = {
  workflow: WorkflowResponse;
  refreshTable: boolean;
};

export default WorkflowReportTabComponent;
