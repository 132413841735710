import {AggregationResult, ChartType, LineChartDetails, ReportResponse} from "@eazy2biz/common-util";
import {Moment} from 'moment'
import React from "react";
import {
    GenericLineChart
} from "@eazy2biz-ui/common-components";
import {transformChartData} from "../../helpers/ChartHelper";

export const LineChartComponent = (
    props: {
        reportResponse : ReportResponse,
        lineChartDetails : LineChartDetails,
        chartStartDate : Moment,
        chartEndDate : Moment,
        chartData: AggregationResult[]
    }

): JSX.Element => {

    const {
        reportResponse, lineChartDetails, chartStartDate, chartEndDate, chartData
    } = props;


    return (
        <GenericLineChart
            data={transformChartData(chartData, ChartType.LINE, lineChartDetails)}
            groupingField={'groupingFieldValue'}
            aggregationValueKey={'aggregationValue'}
            aggregationFieldLabel={lineChartDetails.aggregationLabel}
            {...(lineChartDetails.subGrouping ? {subGroupingField : 'subGroupingFieldValue'} : {})}
        />
    );
}
