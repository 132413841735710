import React, {useState} from 'react';
import {CardFormFieldComponent} from '@eazy2biz-ui/common-components';
import { ApprovalField } from '../../genericComponents/ApprovalField/ApprovalField';
import {
  ApprovalValues,
  Field,
  FieldTypes,
  StageFormEntity,
  validateField
} from "@eazy2biz/common-util";
import styles from './StageFormComponent.module.css';
import {cloneDeep} from "lodash";
import {FormFieldInput, isRequiredField} from '@eazy2biz/common-package-ui';

const StageFormComponent = (props: PropType) => {

  const {stageForm, disableSubmit} = props;

  if (!stageForm) {
    return null;
  }

  const fields: Field[] = stageForm.RW;
  const [invalidFieldValueMap, setInvalidFieldValueMap] = useState(new Map<string, string | undefined>());

  const handleDisableCallback = () => {
    if (disableSubmit) {
      let invalid = false;
      invalidFieldValueMap.forEach(value => {
        invalid = invalid || !!value;
      });

      disableSubmit(invalid);
    }
  }

  const validateValueForField = async (field: Field) => {
    const value = props.fieldsMap.find(element => element.id === field._id);
    if (value) {
      const validationTextArray = validateField(field, value.value);

      const invalidFieldValueMapClone = cloneDeep(invalidFieldValueMap);
      invalidFieldValueMapClone.set(field._id, (validationTextArray.length === 0) ? undefined : validationTextArray[0]);
      setInvalidFieldValueMap(invalidFieldValueMapClone);
      // handleDisableCallback();
    }
  }

  const updateFieldValue = (fieldId: string, value: any) => {
    const fieldMapCopy: FormFieldInput[] = props.fieldsMap;
    const field = fieldMapCopy.find((value: FormFieldInput) => value.id === fieldId);
    if (field) {
      field.value = value;
    } else {
      fieldMapCopy.push({ id: fieldId, value });
    }

    props.setFieldMap(fieldMapCopy);
  };

  const handleApprovalFieldUpdate = (fieldId: string, value: ApprovalValues) => {
    updateFieldValue(fieldId, value);
    props.onSubmit(value);
  };

  const renderApprovalField = (fieldId: string) => {
    return (
      <>
        <ApprovalField
          disabled={props.disableForm}
          handleApproval={() => {
            handleApprovalFieldUpdate(fieldId, ApprovalValues.APPROVED);
          }}
          handleRejection={() => {
            handleApprovalFieldUpdate(fieldId, ApprovalValues.REJECTED);
          }}
        />
      </>
    );
  };

  const renderField = (field: Field) => {

    const invalidValue: boolean = !!invalidFieldValueMap.get(field._id);
    const defaultValue = props.fieldsMap.find((value: FormFieldInput) => value.id === field._id)?.value;
    return (
        <CardFormFieldComponent
          key={field._id}
          field={field}
          isRequired={isRequiredField(field)}
          onChange={updateFieldValue}
          defaultValue={defaultValue}
          validateValue={validateValueForField}
          isInValid={invalidValue}
          inValidText={invalidFieldValueMap.get(field._id)}
          disabled={props.disableForm}
        />
    );
  }

  const renderFormFields = () => {
    const inputFieldsMap = [];

    if (!fields) {
      return null;
    }

    for (let i = 0; i < fields.length; i++) {
      if (fields[i].type === FieldTypes.APPROVAL) {
        inputFieldsMap.push(renderApprovalField(fields[i]._id));
      } else {
        //Most of the field entity (Text,email,first_name,full_name etc require input field entity)
        inputFieldsMap.push(
            renderField(fields[i])
        );
      }
    }
    return inputFieldsMap;
  };

  return <div className={styles.stageFormComponentWrapper}>{renderFormFields()}</div>;
};

type PropType = {
  stageForm?: StageFormEntity | null;
  fieldsMap: FormFieldInput[];
  setFieldMap: (fieldsMap: Array<any>) => void;
  onSubmit: (val?: ApprovalValues) => void;
  disableSubmit?: (disable: boolean) => void;
  disableForm?: boolean;
};

export default StageFormComponent;
