import {RestServices, TodoFilterTypes} from "@eazy2biz/common-package-ui";
import { getWorkflowIdFilter } from "./helpers/UserTodoServiceHelper";

const TODO_SERVICE = () => RestServices.getInstance().getUserTodoService();

export const getAllPendingTodosCount = (workflowId?: string) : Promise<number> => {
    return TODO_SERVICE().filterTodosCount(
        [TodoFilterTypes.PENDING, TodoFilterTypes.CURRENT_USER],
        [
            ...getWorkflowIdFilter(workflowId),
        ]
    );
};