import { MenuData } from "@eazy2biz-ui/common-components";
import { Operators } from "@eazy2biz/common-util";

export const LogicalOperatorMenu: MenuData[] = [
  {
    _id: "1",
    name: 'All'
  },
  {
    _id: "2",
    name: 'Either'
  }
];

export const StringOperatorMenu = [
  {
    _id: Operators.NOT_EQUAL,
    name: 'Not Equal To'
  },
  {
    _id: Operators.EQUAL,
    name: 'Equal To'
  }
];

export const NumberOperatorMenu = [
  {
    _id: Operators.LESS_THAN,
    name: 'Less Than'
  },
  {
    _id: Operators.LESS_THAN_EQUAL,
    name: 'Less Than Equal To'
  },
  {
    _id: Operators.GREATER_THAN,
    name: 'Greater Than'
  },
  {
    _id: Operators.GREATER_THAN_EQUAL,
    name: 'Greater Than Equal To'
  },
];

export const OperatorMenu = [...StringOperatorMenu, ...NumberOperatorMenu];

export const getOperatorMenuForType = (inputType: string) => {
  switch (inputType) {
    case "number":
    case "date":
      return OperatorMenu;
    default:
      return StringOperatorMenu;
  }
};
