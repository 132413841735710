import {validateWorkflowAndForm} from "../validationHelpers/ValidateWorkflowChartHelper";
import {
    getWorkflowCreateRequest,
    getWorkflowUpdateAttributesFromWorkflowCreateRequest
} from "../../../services/helpers/workflow/WorkflowHelper";
import {Elements} from "react-flow-renderer";
import {createWorkflow, patchWorkflowById} from "../../../services/WorkflowService";
import {Tag, WorkflowCreateRequest} from "@eazy2biz/common-util";
import {validateCreateWorkflowPayload} from "../validationHelpers/ValidateWorklfow";
import {FormEntity} from "@eazy2biz-ui/common-package";

/**
 * Validates, Transforms and publishes workflow.
 * @param workflowName
 * @param workflowDescription
 * @param stages
 * @param edges
 * @param form
 * @param tags
 */
export const validateAndPublishWorkflow = (
    workflowName: string,
    workflowDescription: string = '',
    stages: Elements,
    edges: Elements,
    form: FormEntity,
    tags: Tag[] = []
) => {
    validateWorkflowAndForm(workflowName, stages, edges, form);

    const workflowCreateRequest: WorkflowCreateRequest = getWorkflowCreateRequest(workflowName, workflowDescription, stages, edges, form, tags);

    validateCreateWorkflowPayload(workflowCreateRequest);

    // Publishing payload.
    return createWorkflow(workflowCreateRequest);
};

export const validateAndPublishWorkflowUpdate = (
    workflowId: string,
    workflowName: string,
    workflowDescription: string = '',
    stages: Elements,
    edges: Elements,
    form: FormEntity,
    tags: Tag[]
) => {
    validateWorkflowAndForm(workflowName, stages, edges, form);

    const workflowCreateRequest: WorkflowCreateRequest = getWorkflowCreateRequest(workflowName, workflowDescription, stages, edges, form, tags);

    validateCreateWorkflowPayload(workflowCreateRequest);

    // Publishing payload.
    return patchWorkflowById(
        workflowId,
        getWorkflowUpdateAttributesFromWorkflowCreateRequest(workflowCreateRequest)
    );
};
