import React, { ReactElement } from "react";
import { Modal } from 'react-bootstrap';
import styles from './GenericFormBuilder.module.css';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { CloseButton, GenericButton } from '@eazy2biz-ui/common-components';
import DropZone from "./dropZone/DropZone";
import {MASTER_DROP_ZONE_ID, PALETTE_DROPPABLE_ID} from "./configs/dndConfig";
import Palette from "./palette/Palette";
import {PaletteItemConfig} from "@eazy2biz-ui/common-package";

export class GenericFormBuilder extends React.Component<PropTypes, {}> {
    constructor(props: PropTypes) {
        super(props);
        this.state = {};
    }

    /**
     * Handles Element drop.
     * @param result
     */
    handleElementDrop = (result: DropResult) => {
        if (!result.destination || result.destination.droppableId === PALETTE_DROPPABLE_ID) {
            return;
        }
        this.props.onElementDrop(result.draggableId);
    };

    renderLeftDrawer = (): React.ReactElement => {
        const {renderLeftConfigurations, formConfigurationsVisible, paletteItems} = this.props;
        return (
            <div className={styles.formBuilderLeftDrawer}>
                {!formConfigurationsVisible && <Palette paletteItems={paletteItems} />}
                {formConfigurationsVisible && renderLeftConfigurations()}
            </div>
        );
    };

    renderTitle = () => {
        const {title } = this.props;
        return (
            <Modal.Title>{title}</Modal.Title>
        );
    };

    renderHeaderRightButtons = () => {
        const { onToggleFormBuilder, onSaveForm } = this.props;
        return (
            <div className={styles.buttonCtn}>
                <div className={styles.individualButton}>
                    <GenericButton buttonText={'Save'} onClick={onSaveForm} />
                </div>
                <div className={styles.individualButton}>
                    <CloseButton onClose={onToggleFormBuilder} />
                </div>
            </div>
        );
    };

    renderFormPlayGround = () => {
        return (
            <div className={styles.formBuilderPlayground} onClick={this.props.onBodyClick}>
                <div className={styles.formBuilderFieldViewWrapper}>
                    {this.props.renderFormView()}
                </div>
                <DropZone  isDropping={false} id={MASTER_DROP_ZONE_ID} />
            </div>
        );
    };

    renderBody = () => {
        return (
            <div className={styles.formBuilderBodyWrapper}>
                <DragDropContext onDragEnd={this.handleElementDrop}>
                    {this.renderLeftDrawer()}
                    {this.renderFormPlayGround()}
                </DragDropContext>
            </div>
        );
    };

    render() {
        const { onToggleFormBuilder } = this.props;

        return (
            <Modal
                show
                dialogClassName={styles.formModalDialog}
                contentClassName={styles.formModalContent}
                backdropClassName={styles.formModalBackdrop}
                onHide={onToggleFormBuilder}>
                <Modal.Header>
                    {this.renderTitle()}
                    {this.renderHeaderRightButtons()}
                </Modal.Header>
                <Modal.Body className={styles.formModalBody}>
                    {this.renderBody()}
                </Modal.Body>
            </Modal>
        );
    }
}

type PropTypes = {
    onToggleFormBuilder: () => void;
    title: string | ReactElement;
    onSaveForm: () => void;
    renderFormView: () => any;
    paletteItems: PaletteItemConfig<any>[];
    formConfigurationsVisible: boolean;
    renderLeftConfigurations: () => any;
    onElementDrop: (paletteItemId: string) => void;
    onBodyClick?: () => void;
};
