import {FieldTypes} from "@eazy2biz/common-util";
import textIcon from '../../assets/textIcon.svg';
import calendarIcon from '../../assets/calendarIcon.svg';
import numberIcon from '../../assets/numberIcon.svg';
import emailIcon from '../../assets/emailIcon.svg';
import mobileIcon from '../../assets/mobileIcon.svg';
import personIcon from '../../assets/personIcon.svg';
import dropdownIcon from '../../assets/dropdownIcon.svg';
import attachmentIcon from '../../assets/attachmentIcon.svg';
import databaseIcon from '../../assets/databaseIcon.svg';
import gridIcon from '../../assets/gridIcon.svg';
import { PaletteItemConfig } from "@eazy2biz-ui/common-package";

export const PaletteConfig: PaletteItemConfig<FieldTypes>[] = [
    {
        id: '1',
        type: FieldTypes.TEXT,
        label: 'Short Text',
        icon: textIcon
    },
    {
        id: '2',
        type: FieldTypes.NUMBER,
        label: 'Number',
        icon: numberIcon
    },
    {
        id: '2-1',
        type: FieldTypes.PHONE_NUMBER,
        label: 'Mobile',
        icon: mobileIcon
    },
    {
        id: '3',
        type: FieldTypes.FULL_NAME,
        label: 'Full Name',
        icon: personIcon
    },
    {
        id: '4',
        type: FieldTypes.FIRST_NAME,
        label: 'First Name',
        icon: personIcon
    },
    {
        id: '5',
        type: FieldTypes.LAST_NAME,
        label: 'Last Name',
        icon: personIcon
    },
    {
        id: '6',
        type: FieldTypes.EMAIL,
        label: 'Email Id',
        icon: emailIcon
    },
    {
        id: '7',
        type: FieldTypes.DATE,
        label: 'Date',
        icon: calendarIcon
    },
    {
        id: '8',
        type: FieldTypes.LONG_TEXT,
        label: 'Long Text',
        icon: textIcon
    },
    {
        id: '9',
        type: FieldTypes.DROPDOWN,
        label: 'Dropdown',
        icon: dropdownIcon
    },
    {
        id: '10',
        type: FieldTypes.FILE,
        label: 'File upload',
        icon: attachmentIcon
    },
    {
        id: '11',
        type: FieldTypes.DATABASE,
        label: 'Database Field',
        icon: databaseIcon,
    },
    {
        id: '12',
        type: FieldTypes.EMPLOYEE,
        label: 'Employee Field',
        icon: personIcon,
    },
    {
        id: '13',
        type: FieldTypes.TABLE,
        label: 'Table Field',
        icon: gridIcon,
    },
];

export const getFieldIconFromPaletteConfig = (fieldType:FieldTypes): string => {
    const config: PaletteItemConfig<FieldTypes> | undefined = PaletteConfig.find((config) => config.type === fieldType);
    if (!config) {
        return '';
    }
    return config.icon;
}
