import { RestServices } from "@eazy2biz/common-package-ui";
import {GlobalUserResponse, UserInviteResponse} from "@eazy2biz/common-util";

const INVITES_SERVICE = () => RestServices.getInstance().getUserInvitesService();

export const rejectUserInvite = (inviteId: string) : Promise<UserInviteResponse> => {
    return INVITES_SERVICE().rejectUserInvite(inviteId);
}

export const acceptUserInvite = (inviteId: string) : Promise<GlobalUserResponse> => {
    return INVITES_SERVICE().acceptUserInvite(inviteId);
}

export const getInviteById = (inviteId: string) : Promise<{userInvite: UserInviteResponse, companyName: string}> => {
    return INVITES_SERVICE().getUserInviteById(inviteId);
};
