export const CUSTOMER_TABLE_HOMEPAGE_STRINGS = {
    TITLE: 'My Tables',
    CARD_RECORDS: ' records',
};

export const RECORDS_LANDING_STRINGS = {
    RECORDS: 'My Records',
    CREATE_RECORD: '+ Record',
    EDIT_TABLE: 'Edit Table'
};

export const TABLE_COLUMNS_BUILDER = {
    UNTITLED_TABLE: 'Untitled Table',
    TITLE_HEADER: 'Title',
    TITLE_HEADER_HELP_TEXT: 'Title header for record',
    TABLE_COLUMNS: 'Table Columns',
    MAKE_TITLE: 'Make Title',
    TITLE_HEADER_DELETE: 'Cannot delete title column',
    PREVIOUS_ADDED_COLUMN: 'Cannot delete previously added column',
    CREATE_TABLE_PROGRESS: 'Creating new table...',
    CREATE_TABLE_SUCCESS: 'Table was created successfully.',
    CREATE_TABLE_ERROR: 'Error creating a new table, fix the error and please try again.',
    UPDATE_TABLE_PROGRESS: 'Updating the table...',
    UPDATE_TABLE_SUCCESS: 'Table was updated successfully.',
    UPDATE_TABLE_ERROR: 'Error updating the table, fix the error and please try again.',
};

export const TABLE_HEADER_DEFAULT_NAMES = {
    NUMBER: 'Number',
    TEXT: 'Text',
    LONG_TEXT: 'Long text',
    EMAIL: 'Email Address',
    PHONE_NUMBER: 'Mobile Number',
    FILE: 'File',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    FULL_NAME: 'Full Name',
    DATE: 'Date',
    TIME: 'Time',
    RADIO: 'Radio',
    DROPDOWN: 'Dropdown',
};

export const RECORD_FORM = {
    SUBMIT: 'Submit',
    UPDATE: 'Update',
    TITLE: 'Create Record - ',
    CREATE_RECORD_PROGRESS: 'Inserting new record ...',
    CREATE_RECORD_SUCCESS: 'Record created successfully.',
    CREATE_RECORD_FAILURE: 'Record creation failed, please try again.',
    EDIT_RECORD_SUCCESS: 'Record edited successfully.',
    EDIT_RECORD_FAILURE: 'Record edit failed, please try again.',
};

export const TABLE_FILTER_MENU = {
    CREATED_DATE_BEFORE: 'Created before',
    ARCHIVED_ONLY: 'Archived Only'
};
