import {
    AreaChartDetails,
    BarChartDetails,
    BaseChartDetails, ChartResponse, ChartsUIGridConfig,
    ChartType, EntitySearch,
    LineChartDetails,
    PieChartDetails, ReportChartUIGridConfig, ReportResponse, TableChartDetails
} from "@eazy2biz/common-util";
import {validateSync} from "class-validator";
import {plainToClass} from "class-transformer";
import moment from "moment";
import {Builder, StrictBuilder} from "builder-pattern";

export type CreateChartForm = {
    name? : string,
    description?: string,
    chartDurationSameAsReportDuration?: boolean
    defaultChartDurationInDays? : number,
    defaultChartDurationInMonths? : number,
    durationDateField?: string,
    filters: EntitySearch,
    chartDetails? : BaseChartDetails<ChartType>,
};

export type PreviewChartForm = {
    name: string;
    description?: string;
    defaultChartDuration: string;
    durationDateField: string;
    chartType: ChartType;
    filters: EntitySearch;
    chartDetails: BaseChartDetails<ChartType>;
};

export const validateChartDetails = (chartType : ChartType, chartDetails : BaseChartDetails<ChartType>) => {
    switch (chartType) {
        case ChartType.LINE:
            return validateSync(plainToClass(LineChartDetails, chartDetails)).length === 0;
        case ChartType.AREA:
            return validateSync(plainToClass(AreaChartDetails, chartDetails)).length === 0;
        case ChartType.BAR:
            return validateSync(plainToClass(BarChartDetails, chartDetails)).length === 0;
        case ChartType.PIE:
            return validateSync(plainToClass(PieChartDetails, chartDetails)).length === 0;
        case ChartType.TABLE:
            return validateSync(plainToClass(TableChartDetails, chartDetails)).length === 0;
    }
    return false;
}

export const checkValidCreateChartForm = (
    createChartForm: CreateChartForm,
    chartType : ChartType,
    selectedReport: ReportResponse
) : boolean => {

    const defaultDurationInMonths =
        createChartForm.chartDurationSameAsReportDuration
            ? moment.duration(selectedReport.defaultReportDuration).months()
            : createChartForm.defaultChartDurationInMonths;
    const defaultDurationInDays =
        createChartForm.chartDurationSameAsReportDuration
            ? moment.duration(selectedReport.defaultReportDuration).days()
            : createChartForm.defaultChartDurationInDays;

    if(
        (defaultDurationInDays === 0 || defaultDurationInDays === null)
        && (defaultDurationInMonths === 0 || defaultDurationInMonths === null)
    ) {
        return false;
    }

    if(
        createChartForm.name
        && createChartForm.durationDateField
        && createChartForm.filters
        && createChartForm.chartDetails
        && validateChartDetails(chartType, createChartForm.chartDetails)
    ) {
        return true;
    }

    return false;
}

export const convertCreateChartFormToChartPreviewForm = (
    createChartForm : CreateChartForm,
    chartType : ChartType,
    selectedReport : ReportResponse,
) : PreviewChartForm => {

    const defaultDurationInMonths =
        createChartForm.chartDurationSameAsReportDuration
            ? moment.duration(selectedReport.defaultReportDuration).months()
            : createChartForm.defaultChartDurationInMonths;
    const defaultDurationInDays =
        createChartForm.chartDurationSameAsReportDuration
            ? moment.duration(selectedReport.defaultReportDuration).days()
            : createChartForm.defaultChartDurationInDays;

    return Builder<PreviewChartForm>()
        // @ts-ignore
        .name(createChartForm.name)
        .description(createChartForm.description)
        .defaultChartDuration(
            `P${defaultDurationInMonths ? `${defaultDurationInMonths}M` : ''}${defaultDurationInDays ? `${defaultDurationInDays}D` : ''}`,
        )
        // @ts-ignore
        .durationDateField(createChartForm.durationDateField)
        .chartType(chartType)
        .filters(createChartForm.filters)
        // @ts-ignore
        .chartDetails(createChartForm.chartDetails)
        .build()
}

export const getUpdatedUIGridConfigForCreatedChart = (createdChart : ChartResponse, selectedReport : ReportResponse) : ReportChartUIGridConfig[] =>  {

    const chartUIGridConfigsForReport : ReportChartUIGridConfig[] = selectedReport.chartUIGridConfigs;

    const maxYBottomReportChartUIGridConfig : ReportChartUIGridConfig | null = chartUIGridConfigsForReport.reduce(
        (prevValue : ReportChartUIGridConfig | null, currentValue: ReportChartUIGridConfig) =>
            prevValue !== null && (prevValue.yCoordinate + prevValue.height) > (currentValue.yCoordinate + currentValue.height) ? prevValue : currentValue
        , null
    )

    const minimumGridConfigForChart : { minWidth: number; minHeight: number; } = ChartsUIGridConfig.get(createdChart.type) || { minWidth: 3, minHeight: 6 };

    const reportUIGridConfigForCurrentChart =
        StrictBuilder<ReportChartUIGridConfig>()
            .chartId(createdChart._id)
            .xCoordinate(0)
            .yCoordinate(
                maxYBottomReportChartUIGridConfig
                    ? maxYBottomReportChartUIGridConfig.yCoordinate + maxYBottomReportChartUIGridConfig.height
                    : 0
            )
            .width(minimumGridConfigForChart.minWidth)
            .height(minimumGridConfigForChart.minHeight)
            .build()

    chartUIGridConfigsForReport.push(reportUIGridConfigForCurrentChart);

    return chartUIGridConfigsForReport;
}
