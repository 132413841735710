import {
    DropdownSelect,
} from '@eazy2biz-ui/common-components';
import styles from './ConditionComponent.module.css';
import {
    ConditionExpression,
    Field,
    LogicalOperator,
    Operators
} from "@eazy2biz/common-util";
import { random } from "lodash";
import { CONDITION_COMPONENT_STRINGS } from "../../../../contents/DisplayContent";
import { PlusIcon } from "@radix-ui/react-icons";
import {ICondition} from "../../../entity/conditionBuilder/ICondition";
import { ConditionStatementComponent } from "./conditionStatementComponent/ConditionStatementComponent";
import { LogicalOperatorMenu } from "@eazy2biz-ui/common-package";

/**
 * Inner Condition Component
 * @param props
 * @constructor
 */
const ConditionComponent = (props: PropTypes) => {

    const {subExpressionsMap, logicalOperator, onLogicalOpUpdate, onSubExpressionUpdate} = props;

    const handleDropdownSelection = (id: any) => {
        onLogicalOpUpdate(id === 1 ? LogicalOperator.AND : LogicalOperator.OR);
    };

    const addNewSubCondition = () => {
        const newSubCondition = new ICondition(undefined, Operators.EQUAL, '');
        onSubExpressionUpdate((new Date()).getMilliseconds() + "_" + random(), newSubCondition);
    };

    const renderSubCondition = (subCondition: ICondition, id: string) => {
        return (
          <ConditionStatementComponent
            subCondition={subCondition}
            id={id}
            fields={props.fields}
            onSubExpressionUpdate={onSubExpressionUpdate}
          />
        );
    };

    const renderLogicalOperatorRow = () => {
        return (
            <div className={styles.condition}>
                <span className={styles.conditionText}>If </span>
                <span className={styles.conditionText}>
                        <DropdownSelect
                            onItemSelect={handleDropdownSelection}
                            menuData={LogicalOperatorMenu}
                            selectedItem={logicalOperator === LogicalOperator.AND ? "1" : "2"}
                        />
                    </span>
                <span className={styles.conditionText}> of the below conditions are true</span>
            </div>
        );
    };

    const renderAddConditionButton = () => {
        return (
            <span className={styles.addConditionBtn} onClick={addNewSubCondition}>
                <PlusIcon />
                <span>{CONDITION_COMPONENT_STRINGS.ADD_NEW_CONDITION}</span>
            </span>
        );
    };

    const renderSubExpression = () => {
        const subExpressionComponents: JSX.Element[] = [];
        let counter = 0;

        subExpressionsMap.forEach((subCondition, id: string) => {
            counter++;
            subExpressionComponents.push(
                <div>
                    <div className={styles.subConditionsContainer}>
                        {renderSubCondition(subCondition as ICondition, id)}
                    </div>
                    { counter !== subExpressionsMap.size ? <span className={styles.logicalOperatorMaster}>{logicalOperator}</span> : null }
                </div>
            );
        })
        return subExpressionComponents;
    }

    return (
        <div>
            {renderLogicalOperatorRow()}
            <div className={styles.subConditions}>
                {renderSubExpression()}
            </div>
            {renderAddConditionButton()}
        </div>
    );
};

type PropTypes = {
    fields: Field[];
    logicalOperator: LogicalOperator;
    subExpressionsMap: Map<string, ICondition | ConditionExpression>;
    onLogicalOpUpdate: (op: LogicalOperator) => void;
    onSubExpressionUpdate: (id: string, subCondition?: ICondition) => void;
};

export default ConditionComponent;
