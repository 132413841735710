import { Form } from "antd";
import { FieldTypes, TemplateResponse, WhatsappMessageDetails } from "@eazy2biz/common-util";
import { ChangeEventHandler, useEffect, useState } from "react";
import { cloneDeep, get, set } from "lodash";
import {
    CardFormFieldSelect,
    DropdownSelect,
    DynamicPlaceholderConfig,
    InputField,
    InputFieldWithPlaceholder,
    MenuData,
    Spinner,
    TextVariableInput,
    Toast,
    VariableField
} from "@eazy2biz-ui/common-components";
import { ActionComponentProps } from "../interfaces/ActionComponentProps";
import { getAllApprovedWhatsappTemplates } from "../../../../../../services/TemplateService";
import styles from "../actionForm.module.css";
import { countryCodeField } from "@eazy2biz-ui/identities-ui/src/js/constants/keys";
import { countryCodePlaceholder } from "@eazy2biz-ui/identities-ui/src/contents/DisplayContent";
import whatsappStyles from "./WhatsappActionComponent.module.css";
import {
    appendCodeAndMobile,
    convertArrayToSeparatedString,
    convertSeparatedStringToArray, DEFAULT_COUNTRY_MOBILE_CODE,
    getCodeFromCountryCodedStrings,
    getMobileNumbersFromCountryCodedStrings
} from "@eazy2biz/common-package-ui";

const validateMessages = {
    required: '${label} is required!'
};

const SEPARATOR = ';';

/**
 *
 * @param props
 * @constructor
 */
export const WhatsappMessageActionComponent = (props: ActionComponentProps) => {

    const whatsAppMessageDetail = props.actionResult.resultDetails as WhatsappMessageDetails;

    const convertTemplateVariablesToVariableFields = (values: object): VariableField[] => {
        return Object.keys(values).map((id) => ({
            id: id,
            name: id,
            value: get(values, id, ''),
        }));
    };

    const convertVariableFieldsToObject = (values: VariableField[]): object => {
        const variablesObject = {};
        values.map((variablesField) => {
            set(variablesObject, variablesField.name, variablesField.value);
        });

        return variablesObject;
    };

    const [whatsappTemplates, setWhatsappTemplates] = useState<TemplateResponse[]>([]);
    const [loading, setLoading] = useState(true);

    const [toPhoneNumbers, setToPhoneNumbers] = useState(getMobileNumbersFromCountryCodedStrings(whatsAppMessageDetail.toPhoneNumbers) || []);
    const [countryCodes, setCountryCodes] = useState(getCodeFromCountryCodedStrings(whatsAppMessageDetail.toPhoneNumbers) || [DEFAULT_COUNTRY_MOBILE_CODE]);

    const [selectedTemplate, setSelectedTemplate] = useState<TemplateResponse>();
    const [templateVariables, setTemplateVariables] = useState<VariableField[]>(
        convertTemplateVariablesToVariableFields(whatsAppMessageDetail.templateVariableMapping));

    useEffect(() => {
        getAllApprovedWhatsappTemplates()
            .then((templates = []) => {
                setWhatsappTemplates(templates);
                setSelectedTemplate(templates.find((template) => template._id === whatsAppMessageDetail.templateId));
            })
            .catch(() => Toast.errorString())
            .finally(() => setLoading(false));
    }, []);

    const toMobilePlaceholderConfig = new DynamicPlaceholderConfig(false, true);
    toMobilePlaceholderConfig.setFormFields(props.fields);
    toMobilePlaceholderConfig.setUserProperties({mobile: 'mobile'});
    toMobilePlaceholderConfig.setFieldFilterComparator((field) => [FieldTypes.PHONE_NUMBER, FieldTypes.EMPLOYEE, FieldTypes.DATABASE].includes(field.type));

    const handleUpdateTo: ChangeEventHandler<any> = (event) => {
        setToPhoneNumbers(convertSeparatedStringToArray(event.target.value, SEPARATOR));
    };

    const handleUpdateCountryCode: ChangeEventHandler<any> = (event) => {
        setCountryCodes([event.target.value]);
    };

    const handleTemplateIdChange = (id: string) => {
        const updatedTemplate = whatsappTemplates.find((template) => template._id === id);
        updatedTemplate && setSelectedTemplate(updatedTemplate);
        updatedTemplate && setTemplateVariables(
            updatedTemplate.parameters.map((parameter) => ({
                id: parameter.name,
                name: parameter.name,
                value: '',
            }))
        );
    };

    const handleTemplatesVariables = (variableFields: VariableField[]) => {
        setTemplateVariables(variableFields);
    };

    const handleSave = (e: any) => {
        e?.stopPropagation();
        const updatedWhatsappMessageDetail: WhatsappMessageDetails = {
            toPhoneNumbers:appendCodeAndMobile(countryCodes, toPhoneNumbers),
            templateId: selectedTemplate?._id || '',
            templateVariableMapping: convertVariableFieldsToObject(templateVariables),
            enabled: true,
        };
        const updatedAction = cloneDeep(props.actionResult);
        updatedAction.resultDetails = updatedWhatsappMessageDetail;
        props.onActionResultUpdate(updatedAction);
    };

    const getTemplateMenuData = (): MenuData[] => {
        return whatsappTemplates.map((template) => ({
            _id: template._id,
            name: template.details.name
        }));
    };

    const renderVariableFieldDropdown = (variableField: VariableField, onChange: (e: any) => void) => {
        return (
          <CardFormFieldSelect
            key={variableField.id}
            label={variableField.name}
            fields={props.fields}
            initialValue={variableField.value}
            onChange={onChange}
          />
        );
    };


    if (loading) {
        return <Spinner />
    }

    return(
        <>
            <Form validateMessages={validateMessages}>
                <Form.Item name={['to']} rules={[{ required: true }]} className={styles.actionDetailsPlaceholderItem}>
                    <div className={whatsappStyles.toPhoneWrapper}>
                        <InputField
                          id={countryCodeField}
                          bordered={true}
                          disabled
                          label={'Code'}
                          placeholder={countryCodePlaceholder}
                          initialValue={countryCodes[0]}
                          className={whatsappStyles.countryCodeFieldInput}
                          type={"number"}
                          onChange={handleUpdateCountryCode}
                          onBlur={handleSave}
                        />
                        <InputFieldWithPlaceholder
                            config={toMobilePlaceholderConfig}
                            id={'to'}
                            label={'To:'}
                            type={'text'}
                            onChange={handleUpdateTo}
                            initialValue={convertArrayToSeparatedString(toPhoneNumbers)}
                            onBlur={handleSave}
                            required
                            className={whatsappStyles.toPhoneInput}
                        />
                    </div>
                </Form.Item>
                <Form.Item name={['templateId']} rules={[{ required: true } ]} className={styles.actionDetailsFormItem}>
                    <DropdownSelect
                        label={'Template Id:'}
                        menuData={getTemplateMenuData()}
                        selectedItem={selectedTemplate?._id || ''}
                        onItemSelect={handleTemplateIdChange}
                        onBlur={handleSave}
                    />
                </Form.Item>
                <Form.Item name={['templateVariables']} className={styles.actionDetailsFormItem}>
                    <TextVariableInput
                        key={'WHATSAPP_ACTION'}
                        variableFields={templateVariables}
                        setVariableFields={handleTemplatesVariables}
                        onItemBlur={handleSave}
                        renderInputField={renderVariableFieldDropdown}
                    />
                </Form.Item>
            </Form>
        </>
    );
};
