import InputField from "../../../InputField/InputField";
import {BROWSE_BY_RESOURCE, SEARCH_PLACEHOLDER} from "../../../../../contents/DisplayContent";
import styles from './PlaceholderSearchComponent.module.css';

export const PlaceholderSearchComponent = () => {
    const handleSearchInputChange = () => {
        // TODO: Handle Search.
    };

    return <InputField
        className={styles.placeholderSearch}
        required={false}
        id={'search-placeholder'}
        label={BROWSE_BY_RESOURCE}
        placeholder={SEARCH_PLACEHOLDER}
        type={'text'}
        onChange={handleSearchInputChange} />;
};
