import { VariableField } from '@eazy2biz-ui/common-components';
import { StringWithParametersCompleteDetails, TemplateParameter } from '@eazy2biz/common-util';

const regex = /@+([a-z]*[A-Z]*[1-9]*)*/gm;

export const updatePlainTextWithVariableValues = (plainText: string, variableFields: VariableField[]): string => {
  variableFields.forEach(variableField => {
    if (variableField.value.length !== 0) {
      plainText = plainText.replaceAll('@'+variableField.name, variableField.value);
    }
  });

  return plainText;
}

export const getVariableNames = (textBlobs: string[]): string[] => {
  const text = textBlobs.join(" ");
  const uniqueVariableNames = new Set(
    Array.from(text.matchAll(regex), match => match[0]).map(variableName => variableName.substring(1))
  );
  return Array.from(uniqueVariableNames);
};

const formatVariable = (variable: string): string => {
  return "{{" + variable.substring(1) + "}}";
}

export const getFormattedContentForWhatsappParameterText = (content: string): StringWithParametersCompleteDetails => {
  const formattedText = content.replaceAll(regex, formatVariable)
  return {
    text: formattedText,
    parameters: getVariableNames([content]).map(vn => {
      return { name: vn, details: {}, additionalDetails: {} };
    })
  }
};

export const getVariableInputsFromParametersWithSampleValue = (parameters: TemplateParameter[] = []): VariableField[] => {
  return parameters.map(param => {
    return {
      id: param.name,
      name: param.name,
      value: param.details.example
    };
  });
};
