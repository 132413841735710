import React, { ReactElement } from 'react';
import {CustomerTableHeader, CustomerTableHeaderTypes} from '@eazy2biz/common-util';
import styles from './TableHeaderFormField.module.css';
import classNames from 'classnames';
import { GenericDatePicker, getHeaderIconFromPaletteConfig, InputField } from "@eazy2biz-ui/common-components";
import {TableHeaderDropdownComponent} from "./TableHeaderDropdownComponent/TableHeaderDropdownComponent";
import {TableHeaderUploadComponent} from "./TableHeaderUploadComponent/TableHeaderUploadComponent";
import { getHTMLInputTypeFromTableHeaderType, getInputResponseTypeFromHeaderType } from "@eazy2biz-ui/common-package";

/**
 * Generic tableHeaderFormField
 * @param props
 * @constructor
 */
export const TableHeaderFormField = (props: PropTypes): ReactElement => {
  const {
    header,
    isReadOnly = false,
    isRequired = false,
    defaultValue = '',
    isInValid = false,
    onChange,
    inValidText = '',
    disabled = false
  } = props;

  const handleChange = (event: any) => {
    onChange && onChange(header._id, event.target.value);
  };

  const renderInputByType = () => {
    const inputClassNames = classNames(styles.inputClassName, {
      [styles.inputInvalid]: isInValid,
      'is-invalid': isInValid,
      [styles.tableHeaderFormFieldDisabled]: disabled,
      [styles.tableHeaderFormInputTextArea]: header.headerType === CustomerTableHeaderTypes.LONG_TEXT
    });

    const inputProps = {
      id: header._id,
      key: header._id,
      className: inputClassNames,
      readOnly: isReadOnly,
      placeholder: header.helpText,
      defaultValue,
      required: isRequired,
      onChange: handleChange,
      type: getHTMLInputTypeFromTableHeaderType(header.headerType),
      valueType: getInputResponseTypeFromHeaderType(header?.headerType),
      disabled
    };

    switch (header.headerType) {
      case CustomerTableHeaderTypes.DATE:
        return <GenericDatePicker {...inputProps} />;
      case CustomerTableHeaderTypes.LONG_TEXT:
        return <textarea {...inputProps} />;
      case CustomerTableHeaderTypes.DROPDOWN:
        return <TableHeaderDropdownComponent {...inputProps} header={header} />;
      case CustomerTableHeaderTypes.FILE:
        return <TableHeaderUploadComponent {...inputProps} header={header} />;
      default:
        return <InputField {...inputProps} />;
    }
  };

  const renderInput = () => {
    return (
      <>
        {renderInputByType()}
        <div className="invalid-feedback">{inValidText}</div>
      </>
    );
  };

  const tableHeaderFieldWrapperClassNames = classNames(styles.tableHeaderFormFieldWrapper, {
    [styles.tableHeaderFormLargeFieldWrapper]: header.headerType === CustomerTableHeaderTypes.LONG_TEXT,
  });
  const inputWrapperClassNames = classNames(styles.tableHeaderFormField, 'has-validation');

  return (
    <div key={header._id} className={tableHeaderFieldWrapperClassNames}>
      <div className={inputWrapperClassNames}>
        <div className={!isRequired ? styles.tableHeaderFormFieldHeader : styles.requiredTableHeaderFormField}>
          <div className={styles.tableHeaderFormFieldLabelIcon}>
            <img src={getHeaderIconFromPaletteConfig(header.headerType)} />
            <label>{header.name}</label>
          </div>
        </div>
        {renderInput()}
      </div>
    </div>
  );
};

type PropTypes = {
  isReadOnly?: boolean;
  isRequired?: boolean;
  header: CustomerTableHeader;
  defaultValue?: string;
  isInValid?: boolean;
  onChange?: (headerId: string, value: string) => void;
  inValidText?: string;
  disabled?: boolean;
};
