import styles from "./HomePage.module.css";
import {WorkflowHomepageComponent} from "@eazy2biz-ui/workflow-ui";
import {TodoHomepageComponent} from "@eazy2biz-ui/todo-ui";
import {useState} from "react";

/**
 * Component for application Homepage/ Dashboard.
 * @constructor
 */
const Homepage  = () => {
    const [refreshData, setRefreshData] = useState(false);
    const handleRefreshCallback = (value: boolean) => setRefreshData(value);

    return (
        <div className={styles.homepage}>
            <WorkflowHomepageComponent handleRefreshCallback={handleRefreshCallback} />
            <TodoHomepageComponent refreshData={refreshData} onRefreshCallback={handleRefreshCallback}/>
        </div>
    );
}

export default Homepage;
