import { FormFieldInput, ItemFilterTypes } from "@eazy2biz/common-package-ui";
import { CardCreateRequest, CompleteStageRequest, FieldSearch, SearchTypes } from "@eazy2biz/common-util";
import { StrictBuilder } from "builder-pattern";
import { ItemFilterConfig } from "../../components/workflow/Items/ItemFilterComponents/ItemFilterMenu";
import {
  assigneePath,
  createdDatePath, currentStagePath,
  dueDatePath,
  formFieldPath,
  lastUpdatedDatePath
} from "../constants/ItemEntityConstants";
import { AdvancedFilterConfig } from "@eazy2biz-ui/common-components";
import { convertToFieldsPayload, getSearchTypeFromOperator } from "@eazy2biz-ui/common-package";

export const getCardCreateRequest = (
    fields: FormFieldInput[],
    tags: string[]
) : CardCreateRequest => {
    return StrictBuilder<CardCreateRequest>()
        .fieldsMap(convertToFieldsPayload(fields))
        .tags(tags)
        .build();
};

export const getCardFilterTypesFromConfig = (config?: ItemFilterConfig): ItemFilterTypes[] => {
  if (!config) {
      return [];
  }

  const filterTypes: ItemFilterTypes[] = [];

    if (config.completedOnly) {
        filterTypes.push(ItemFilterTypes.COMPLETED);
    }

    if (config.pendingOnly) {
        filterTypes.push(ItemFilterTypes.ACTIVE);
    }

    return filterTypes;
};

export const getCardFiltersFromConfig = (config?: ItemFilterConfig) :  FieldSearch[] => {
    const filters : FieldSearch[] = [];
    if (config) {
        if (config.dueDate) {
            filters.push({
              type: SearchTypes.VALUE_LESS_THAN_EQUAL_TO,
              field: dueDatePath,
              value: config.dueDate.toString()
            });
        }

        if (config.createdDateBefore) {
            filters.push({
                type: SearchTypes.VALUE_LESS_THAN_EQUAL_TO,
                field: createdDatePath,
                value: config.createdDateBefore.toString()
            });
        }

        if (config.completedDateBefore) {
            filters.push({
                type: SearchTypes.VALUE_LESS_THAN_EQUAL_TO,
                field: lastUpdatedDatePath,
                value: config.completedDateBefore.toString()
            });
        }

        if (config.assigneeUserId) {
            filters.push({
              type: SearchTypes.VALUE_IN_ARRAY,
              field: assigneePath,
              value: config.assigneeUserId
            });
        }

      if (config.currentStages.length) {
        filters.push({
          type: SearchTypes.VALUE_IN_ARRAY,
          field: currentStagePath,
          value: config.currentStages
        });
      }
        filters.push(...getCardFiltersFromAdvancedFilters(config.advancedFilters));
    }
    return filters;
  };

export const getCardFiltersFromAdvancedFilters = (advancedFilters: AdvancedFilterConfig[] = []): FieldSearch[] => {
  return advancedFilters.map((filter) => ({
    type: getSearchTypeFromOperator(filter.operator),
    field: formFieldPath + filter.firstFieldId,
    value: filter.value
  }));
};



export const completeCardPayload = (
    cardId: string,
    workflowId: string,
    fields: FormFieldInput[]
) : CompleteStageRequest => {
    return StrictBuilder<CompleteStageRequest>()
        .cardId(cardId)
        .workflowId(workflowId)
        .fieldsMap(convertToFieldsPayload(fields))
        .build();
}
