import {ChartResponse, ReportEntityType, ReportResponse} from "@eazy2biz/common-util";
import {useState} from "react";
import {GenericButton, GenericButtonTypes} from "@eazy2biz-ui/common-components";
import {AddChartModal} from "./AddChartModal";

import styles from './AddChart.module.css'

export const AddChart = (
    props: {
        selectedReport : ReportResponse,
        handleOnChartSuccessfulCreate: (createdChart : ChartResponse) => void
        entityType: ReportEntityType;
        entityId: string;
    }
): JSX.Element => {

    const {
        selectedReport,
        handleOnChartSuccessfulCreate,
        entityType,
        entityId
    } = props;

    const [showAddChartModal, setShowAddChartModal] = useState<boolean>(false);

    return (
        <>
            <GenericButton
                buttonText={'Add Chart'}
                type={GenericButtonTypes.DASHED}
                className={styles.addChartButton}
                onClick={() => setShowAddChartModal(true)}
            />

            {
                showAddChartModal && (
                    <AddChartModal
                        selectedReport={selectedReport}
                        handleChartModalClosure={() => setShowAddChartModal(false)}
                        handleOnChartSuccessfulCreate={
                            (createdChart : ChartResponse) => {
                                setShowAddChartModal(false);
                                handleOnChartSuccessfulCreate(createdChart);
                            }
                        }
                        entityType={entityType}
                        entityId={entityId}
                    />
                )
            }
        </>
    );
}
