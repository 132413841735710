import {ReactElement} from 'react';
import {FormElementConfig} from '../../../../entity/formBuilder/FormElementConfig';
import {CardFormFieldComponent} from '@eazy2biz-ui/common-components';
import {FieldTypes} from "@eazy2biz/common-util";

/**
 * Form Builder Field Element in the Form Playground.
 * @param props
 * @constructor
 */
const FormBuilderField = (props: PropTypes): ReactElement => {
   const {itemConfig: {element}, isSelected, currentStageId, onFieldSelect, workflowEditMode} = props;

  return (
    <CardFormFieldComponent
      field={element}
      fieldSelectable
      isSelected={isSelected}
      onFieldSelect={onFieldSelect}
      fieldDeletable={
        element.ownerId === currentStageId && isSelected &&
        !workflowEditMode && element.type !== FieldTypes.APPROVAL
      }
      onFieldDelete={props.onFieldDelete}
      isReadOnly
      isRequired={props.itemConfig.isRequired}
      disabled={props.disabled}
    />
  );
};

type PropTypes = {
  currentStageId: string;
  workflowEditMode: boolean;
  itemConfig: FormElementConfig;
  onFieldSelect: (fieldId?: string) => void;
  onFieldDelete: (fieldId?: string) => void;
  isSelected: boolean;
  disabled: boolean;
};

export default FormBuilderField;
