import {ActionResult, ActionResultTypes, PushNotificationDetails} from "@eazy2biz/common-util";

export const getPushNotificationAction = (): ActionResult<PushNotificationDetails> => {
    return {
        type: ActionResultTypes.PUSH_NOTIFICATION,
        resultDetails: {
            toLocalUserIds: [],
            title: '',
            body: '',
            enabled: true,
        }
    };
};
