import { FieldTypes } from "@eazy2biz/common-util";
import { TEXT_VARIABLE_INPUT } from "../../../contents/DisplayContent";
import InputField from "../../genericComponents/InputField/InputField";
import { sortVariableFieldsByName, updateVariableField } from "../../helpers/TextVariableInput/TextVariableInput";
import styles from "./TextVariableInput.module.css";
import React, { ReactElement, useState } from "react";

export const TextVariableInput = (props: TextVariableInputPropsType): JSX.Element | null => {
  const { variableFields, setVariableFields } = props;

  if (!variableFields.length)
    return null;

  const renderInput = (variableField: VariableField) => {
    const handleOnChange = (e: any) => {
      const updatedVariableFields: VariableField[] = updateVariableField(
        variableField.id,
        e.target.value,
        variableFields
      );
      setVariableFields(updatedVariableFields);
      props.onItemBlur && props.onItemBlur();
    };

    if (props.renderInputField) {
      return props.renderInputField(variableField, handleOnChange);
    }

    return <InputField
      initialValue={variableField.value}
      id={variableField.id}
      key={variableField.name}
      label={variableField.name}
      type={FieldTypes.LONG_TEXT}
      onBlur={props.onItemBlur}
      placeholder={variableField.name}
      disabled={props.disabled}
      onChange={handleOnChange} />;
  };

  return (
    <div className={styles.container}>
      {!props.hideHeader && <div className={styles.header}>
        {TEXT_VARIABLE_INPUT.VARIABLES}
      </div>}
      <div className={styles.content}>
        {
          sortVariableFieldsByName(variableFields).map((variableField: VariableField) => {
            return (
              <>
                {renderInput(variableField)}
              </>
            );
          })
        }
      </div>
    </div>
  );
}

export type VariableField = {
  id: string;
  name: string;
  value: string
};

type TextVariableInputPropsType  = {
  hideHeader?: boolean;
  variableFields: VariableField[];
  setVariableFields: (variableFields: VariableField[]) => void;
  onItemBlur?: (e?: any) => void;
  renderInputField?: (variableField: VariableField, onChange: (e: any) => void) => ReactElement;
  disabled?: boolean;
}
