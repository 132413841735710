import React, {useEffect, useState} from "react";
import {
    LocationStateKeys,
    RoutesEnum, UIApplicationInit
} from "@eazy2biz-ui/common-package";
import {useHistory, useLocation} from "react-router-dom";
import {Spinner, Toast} from "@eazy2biz-ui/common-components";
import {ERROR_STRINGS} from "../../contents/DisplayContent";

const IdentityWrapper: (props: PropTypes) => JSX.Element = (props: PropTypes) => {
    const [userSignedIn, setUserSignedIn] = useState(false);
    const [initialLoad, setInitialLoad] = useState(false);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (!props.isWebPage) { // TODO: revisit this for website pages
            if (isCompanySignIn()) {
                postSignIn();
            } else if (isUserSignedIn()) {
                postUserSignIn();
            } else {
                history.replace(RoutesEnum.USER_SIGN_IN, {[LocationStateKeys.REDIRECT_URL]: location.pathname});
            }
        }
    }, [props.component]);

    const isUserSignedIn = (): boolean => {
        return document.cookie.match("accessToken=") !== null;
    };

    const isCompanySignIn = (): boolean => {
        return document.cookie.match("companyId=") !== null;
    };

    const postUserSignIn = (): void => {
        setInitialLoad(true);
        setUserSignedIn(true);
        UIApplicationInit.getInstance().postGlobalUserSignedInFromSession()
            .catch(() => Toast.errorString(ERROR_STRINGS.ERROR_LOADING_TRY_AGAIN))
            .finally(() => {
                setInitialLoad(false);
            });
    }

    const postSignIn = (): void => {
        setUserSignedIn(true);
        setInitialLoad(true);
        UIApplicationInit.getInstance().postGlobalUserSignedInFromSession()
            .then(() =>
                UIApplicationInit.getInstance().postCompanySignInFromSession()
                    .catch(() => Toast.errorString(ERROR_STRINGS.ERROR_LOADING_TRY_AGAIN))
            )
            .catch(() => Toast.errorString(ERROR_STRINGS.ERROR_LOADING_TRY_AGAIN))
            .finally(() => {
                setInitialLoad(false);
            });
    };

    const handleSignOut = async () => {
        setInitialLoad(true);
        await UIApplicationInit.getInstance().postSignOutAppInit();
        setTimeout(() => {
            history.replace(RoutesEnum.USER_SIGN_IN, {[LocationStateKeys.SIGN_OUT_FLAG]: true});
            setInitialLoad(false);
        }, 500);
    };

    const renderSection = (): JSX.Element => {
        if (initialLoad || (!userSignedIn && !props.isWebPage)) {
            return (<Spinner size={'large'}/>);
        }

        return (
            // @ts-ignore
            <props.CoreSection  onSignOut={handleSignOut} {...props}/>
        );
    }

    return(renderSection());
};

type PropTypes = {
    CoreSection: (props: PropTypes) => JSX.Element;
    component: React.Component;
    isWebPage: boolean;
}

export default IdentityWrapper;
