import {getSmoothStepPath} from 'react-flow-renderer';
import {EdgeProps} from "./EdgeProps";

export default function CustomEdge(props: EdgeProps) {

    const {
        id,
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
        style = {}
    } = props;

    const edgePath = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
            />
        </>
    );
}