import {Spin} from "antd";
import * as React from "react";
import {SpinSize} from "antd/lib/spin";

/**
 * Generic Spinner
 * @param props
 * @constructor
 */
export const Spinner = (props: PropTypes) => {

    const {size, tip = 'Loading...'} = props;

    return(
        <div style={{
            textAlign: 'center',
            paddingTop: '20%',
            width: '100%'
        }}>
            <Spin tip={tip} size={size}/>
        </div>
    )
}

type PropTypes = {
    size?: SpinSize;
    tip?: React.ReactNode;
};