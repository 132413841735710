import {AggregationResult, AreaChartDetails, ChartType, ReportResponse} from "@eazy2biz/common-util";
import {Moment} from 'moment'
import React from "react";
import {transformChartData} from "../../helpers/ChartHelper";
import {
    GenericAreaChart
} from "@eazy2biz-ui/common-components";

export const AreaChartComponent = (
    props: {
        reportResponse : ReportResponse,
        areaChartDetails : AreaChartDetails,
        chartStartDate : Moment,
        chartEndDate : Moment,
        chartData: AggregationResult[]
    }

): JSX.Element => {

    const {reportResponse, areaChartDetails, chartStartDate, chartEndDate, chartData} = props;

    return (
        <GenericAreaChart
            data={transformChartData(chartData, ChartType.AREA, areaChartDetails)}
            groupingField={'groupingFieldValue'}
            aggregationValueKey={'aggregationValue'}
            aggregationFieldLabel={areaChartDetails.aggregationLabel}
            {...(areaChartDetails.subGrouping ? {subGroupingField : 'subGroupingFieldValue'} : {})}
        />
    );
}
