import {CustomerTableSelect} from "@eazy2biz-ui/common-components";
import {FormElementConfig} from "../../../../entity/formBuilder/FormElementConfig";
import {FieldConfigurations} from "@eazy2biz/common-util";
import {useState} from "react";
import {cloneDeep, set} from "lodash";

/**
 * Custom Configurations fot dropdown field type.
 * @param props
 * @constructor
 */
export const DatabaseConfigurations = (props: PropTypes) => {

    const fieldConfigurations: FieldConfigurations | undefined
        = props.field.element.fieldConfigurations;

    const [tableId, setTableId] = useState(fieldConfigurations?.groupCorrelationKey);
    const [columnId, setColumnId] = useState(fieldConfigurations?.correlationKey);


    const handleTableIdChange = (updatedTableId: string) => {
        setTableId(updatedTableId);
        const updatedField = cloneDeep(props.field);
        set(updatedField.element, "fieldConfigurations.groupCorrelationKey", updatedTableId);
        props.onFieldUpdate(updatedField);
    };

    const handleTableColumnIdChange = (updatedTableColumnId: string) => {
        setColumnId(updatedTableColumnId);
        const updatedField = cloneDeep(props.field);
        set(updatedField.element, "fieldConfigurations.correlationKey", updatedTableColumnId);
        props.onFieldUpdate(updatedField);
    };

    return (
        <>
            <CustomerTableSelect
                onTableSelect={handleTableIdChange}
                selectedTableId={tableId}
                onTableColumnSelect={handleTableColumnIdChange}
                selectedTableColumnId={columnId} />
        </>
    );
};

type PropTypes = {
    field: FormElementConfig;
    onFieldUpdate: (updatedField: FormElementConfig) => void;
};
