import {RestServices } from "@eazy2biz/common-package-ui";
import {
    addCommentPayload,
    getCardActivityPayload,
    getCardCommentPayload
} from "./helpers/ActivityServiceHelper";
import {ActivityResponse} from "@eazy2biz/common-util";

const ACTIVITY_SERVICE = () => RestServices.getInstance().getActivityService();

export const getAllActivity = (cardId: string, workflowId: string) : Promise<ActivityResponse[]> => {
    return ACTIVITY_SERVICE().getAllActivities(getCardActivityPayload(cardId, workflowId));
};

export const getAllComments = (cardId: string, workflowId: string) : Promise<ActivityResponse[]> => {
    return ACTIVITY_SERVICE().getAllActivities(getCardCommentPayload(cardId, workflowId));
};

export const addCommentToCard = (message: string, cardId: string, workflowId: string) : Promise<ActivityResponse> => {
    return ACTIVITY_SERVICE().createActivity(addCommentPayload(message, cardId, workflowId));
}
