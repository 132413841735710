import * as React from 'react';
import { GENERIC_STEP_FORM_BUTTONS } from '../../../contents/DisplayContent';
import { getStepsInfo } from '../../helpers/GenericStepForm/GenericStepFormHelper';
import GenericButton from '../GenericButton/GenericButton';
import { GenericButtonTypes } from '../GenericButton/GenericButtonTypes';
import { GenericStepProgressBar } from '../GenericStepProgressBar/GenericStepProgressBar';
import styles from './GenericStepForm.module.css';

export const GenericStepForm = (props: PropsType): JSX.Element => {
  const [sectionIndex, setSectionIndex] = React.useState<number>(0);

  const handleNextNavigation = () => {
    if (sectionIndex < props.sections.length - 1) {
      setSectionIndex(sectionIndex + 1);
    }
    const { onButtonClick } = props.sectionButtons[sectionIndex].next;
    onButtonClick && onButtonClick();
  };

  const handleBackNavigation = () => {
    if (sectionIndex > 0) {
      setSectionIndex(sectionIndex - 1);
    }
    const { onButtonClick } = props.sectionButtons[sectionIndex].back;
    onButtonClick && onButtonClick();
  };

  return (
    <div className={styles.stepFormContainer}>
      <div className={styles.tooltipHeader}>
        <GenericStepProgressBar steps={getStepsInfo(props.sectionNames)} stepIndex={sectionIndex} />
        <div className={styles.tooltipActions}>
          <GenericButton
            className={styles.actionButton}
            disabled={props.sectionButtons[sectionIndex].back.disabled}
            type={GenericButtonTypes.SECONDARY}
            buttonText={props.sectionButtons[sectionIndex].back.buttonText ?? GENERIC_STEP_FORM_BUTTONS.BACK}
            onClick={handleBackNavigation}
            isLoading={props.sectionButtons[sectionIndex].back.isLoading}
          />
          <GenericButton
            className={styles.actionButton}
            disabled={props.sectionButtons[sectionIndex].next.disabled}
            type={GenericButtonTypes.PRIMARY}
            buttonText={props.sectionButtons[sectionIndex].next.buttonText ?? GENERIC_STEP_FORM_BUTTONS.CONTINUE}
            onClick={handleNextNavigation}
            isLoading={props.sectionButtons[sectionIndex].next.isLoading}
          />
        </div>
      </div>
      <div className={styles.content}>
        {props.sections.length && props.sections[sectionIndex]}
      </div>
    </div>
  );
};

export type SectionButtonType = {
  buttonText?: string;
  disabled?: boolean;
  onButtonClick?: () => void;
  isLoading?: boolean;
}

export type SectionButtonsType = {
  next: SectionButtonType;
  back: SectionButtonType;
}

type PropsType = {
  sections: React.ReactElement[];
  sectionNames: string[];
  sectionButtons: SectionButtonsType[];
}
