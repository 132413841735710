import styles from "./IdentityGenericButton.module.css";
import {GenericButton} from "@eazy2biz-ui/common-components";

export const IdentityGenericButton = (props: any) => {
    return (
        <GenericButton
            className={styles.identityGenericButton}
            {...props}
        />
    );
}