import styles from './QuickCardActionButton.module.css';
import classNames from "classnames";
import {QuickActionButtonTypes} from './QuickActionButtonTypes' 

const QuickActionButton = (props: PropTypes) => {

    return(
       <div className={classNames(styles.quickActionButton,{
            [styles.quickActionButtonShare]: props.type  === QuickActionButtonTypes.SHARE,
            [styles.quickActionButtonDiscard]:props.type === QuickActionButtonTypes.DISCARD,
            [styles.quickActionButtonEdit]:props.type === QuickActionButtonTypes.EDIT,
            }
            )} 
            onClick={props.onClick}>
            {props.getIcon && props.getIcon()}
            &nbsp;{props.buttonText || ''}
        </div>
    );
}

type PropTypes = {
    onClick: () => void;
    getIcon?: () => JSX.Element;
    type: QuickActionButtonTypes;
    buttonText?: string;
}
export default QuickActionButton;