import {
  ApprovalValues,
  CustomerTableHeader,
  CustomerTableHeaderTypes,
  Field,
  FieldTypes
} from "@eazy2biz/common-util";
import { AssigneeSelect, CustomerTableRecordSelect, DropdownSelect, InputField, MenuData } from "../../../index";
import {
  getDropdownMenuForCustomerTableHeader,
  getDropdownMenuForField,
  getHTMLInputTypeFromFieldType, getHTMLInputTypeFromTableHeaderType,
  getInputResponseTypeFromFieldType, getInputResponseTypeFromHeaderType
} from "@eazy2biz-ui/common-package";
import { HTMLInputTypeAttribute } from "react";
import { get } from "lodash";

const getMenuDataForApprovalField = (): MenuData[] => {
  return Object.keys(ApprovalValues).map((key) => ({
    _id: key,
    name: key
  }));
};

const getMenuDataForDropdownField = (field?: Field): MenuData[] => {
  return field ? getDropdownMenuForField(field) : [];
};

const renderSecondTextField = (inputProps: InputByTypeProps, type: string, valueType?: HTMLInputTypeAttribute) => {
  return (
    <InputField {...inputProps} type={type} valueType={valueType}/>
  );
};

const renderDropdownSecondField = (inputProps: InputByTypeProps, menuData: MenuData[]) => {
  return (
    <DropdownSelect
      key={inputProps.id}
      label={inputProps.label}
      selectedItem={inputProps.initialValue ?? ''}
      menuData={menuData}
      onItemSelect={(itemId) => inputProps.onChange({target: {value: itemId}})} />
  );
};

const renderEmployeeSecondField = (inputProps: InputByTypeProps) => {
  return (
    <AssigneeSelect
      key={inputProps.id}
      label={inputProps.label}
      onAssigneesSelect={(ids) => inputProps.onChange({target: {value: ids}})}
      selectedAssignees={inputProps.initialValue ?? []} />
  );
};

const renderDatabaseSecondField = (inputProps: InputByTypeProps, field: Field) => {
  return (
    <CustomerTableRecordSelect
      label={inputProps.label}
      key={inputProps.id}
      onRecordSelect={(record) => inputProps.onChange({target: {value: record?._id}})}
      selectedRecordId={inputProps.initialValue}
      tableId={get(field, 'fieldConfigurations.groupCorrelationKey', '')}
    />
  );
};

export const InputByFieldType = (props: InputByFieldTypeProps) => {
  switch (props.field?.type) {
    case FieldTypes.APPROVAL:
      return renderDropdownSecondField(props, getMenuDataForApprovalField());
    case FieldTypes.DROPDOWN:
      return renderDropdownSecondField(props, getMenuDataForDropdownField(props.field));
    case FieldTypes.EMPLOYEE:
      return renderEmployeeSecondField(props);
    case FieldTypes.DATABASE: return renderDatabaseSecondField(props, props.field);
      return
    default:
      return renderSecondTextField(props, getHTMLInputTypeFromFieldType(props.field?.type), getInputResponseTypeFromFieldType(props.field?.type));
  }
};

interface InputByFieldTypeProps extends InputByTypeProps {
  field?: Field;
}

export const InputByHeaderType = (props: InputByHeaderTypeProps) => {
  switch (props.header?.headerType) {
    case CustomerTableHeaderTypes.DROPDOWN:
      return renderDropdownSecondField(props, getDropdownMenuForCustomerTableHeader(props.header));
    default:
      return renderSecondTextField(props, getHTMLInputTypeFromTableHeaderType(props.header?.headerType), getInputResponseTypeFromHeaderType(props.header?.headerType));
  }
};

interface InputByHeaderTypeProps extends InputByTypeProps {
  header?: CustomerTableHeader;
}

interface InputByTypeProps {
  id: string;
  label: string;
  initialValue?: any;
  onChange: (e: any) => void;
}
