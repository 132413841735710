import styles from "./ConditionStatementComponent.module.css";
import {
  DropdownSelect,
  DynamicPlaceholderConfig, InputByFieldType,
  InputFieldWithPlaceholder
} from "@eazy2biz-ui/common-components";
import { CONDITION_COMPONENT_STRINGS } from "../../../../../contents/DisplayContent";
import {
  getFieldForPlaceholderField,
  getFieldTypeForPlaceholderField,
  getOperatorMenuForPlaceholderField
} from "../config/ConditionConfig";
import { TrashIcon } from "@radix-ui/react-icons";
import { Field, FieldPlaceholder, Operators } from "@eazy2biz/common-util";
import { ICondition } from "../../../../entity/conditionBuilder/ICondition";
import { cloneDeep } from "lodash";
import { getHTMLInputTypeFromFieldType, OperatorMenu } from "@eazy2biz-ui/common-package";

/**
 * Condition Statement component
 * @param props
 * @constructor
 */
export const ConditionStatementComponent = (props: PropTypes) => {

  const {subCondition, id, onSubExpressionUpdate} = props;

  const handleFieldSelection = (fieldPlaceholder: FieldPlaceholder, id: string, subExpression: ICondition) => {
    const subExpressionClone = cloneDeep(subExpression);
    subExpressionClone.firstValue = fieldPlaceholder;
    onSubExpressionUpdate(id, subExpressionClone);
  };
  const handleOperatorSelection = (itemId: string, id: string, subExpression: ICondition) => {
    const subExpressionClone = cloneDeep(subExpression);
    const operator: { _id: Operators, name: string } | undefined = OperatorMenu.find((operator) => operator._id === itemId);

    if (operator) {
      subExpressionClone.op = operator._id;
      onSubExpressionUpdate(id, subExpressionClone);
    }
  };
  const handleValueSelection = (e: any, id: string, subExpression: ICondition) => {
    const subExpressionClone = cloneDeep(subExpression);
    const type = getHTMLInputTypeFromFieldType(getFieldTypeForPlaceholderField(props.fields, subExpression.firstValue));

    subExpressionClone.secondValue = type === 'number' ? Number.parseFloat(e.target.value) :e.target.value;
    onSubExpressionUpdate(id, subExpressionClone);
  };

  const removeSubCondition = (id: string) => {
    onSubExpressionUpdate(id);
  };

  const getPlaceholderConfig = () => {
    const placeholderConfig = new DynamicPlaceholderConfig(true, true);
    placeholderConfig.setFormFields(props.fields);
    return placeholderConfig;
  };

  const renderSecondField = () => {
    return (
      <InputByFieldType
       id={`condition-value-${id}`}
       label={CONDITION_COMPONENT_STRINGS.SECOND_FIELD}
       initialValue={subCondition.secondValue}
       onChange={(e) => handleValueSelection(e, id, subCondition)}
       field={getFieldForPlaceholderField(props.fields, subCondition.firstValue)}
      />
    );
  };

  return (
    <div key={`subCondition-${id}`} className={styles.conditionRow}>
      <div className={styles.firstFieldWrapper}>
        <InputFieldWithPlaceholder
          config={getPlaceholderConfig()}
          key={`field1-${id}`}
          id={`condition-field`}
          label={CONDITION_COMPONENT_STRINGS.IF}
          type={'text'}
          singleValueMode
          initialValue={subCondition.firstValue?.convertToString() || ""}
          onChange={() => {}}
          addedPlaceHolderCallback={(fieldPlaceholder) => handleFieldSelection(fieldPlaceholder, id, subCondition)} />
      </div>
      <div className={styles.operatorWrapper}>
        <DropdownSelect
          // @ts-ignore
          id={`condition-operator-${id}`}
          label={CONDITION_COMPONENT_STRINGS.OPERATOR}
          placeholder={CONDITION_COMPONENT_STRINGS.OPERATOR_FIELD_PLACEHOLDER}
          onItemSelect={(itemId: string) => handleOperatorSelection(itemId, id, subCondition)}
          menuData={getOperatorMenuForPlaceholderField(props.fields, subCondition.firstValue)}
          selectedItem={subCondition.op}
        />
      </div>
      <div className={styles.secondFieldWrapper}>
        {renderSecondField()}
      </div>
      <div className={styles.deleteIcon} onClick={() => removeSubCondition(id)}>
        <TrashIcon />
      </div>
    </div>
  );
};

type PropTypes = {
  subCondition: ICondition;
  id: string;
  fields: Field[];
  onSubExpressionUpdate: (id: string, subCondition?: ICondition) => void;
};
