import {AggregationResult, BarChartDetails, ChartType, ReportResponse} from "@eazy2biz/common-util";
import {Moment} from 'moment'
import React from "react";
import {GenericBarChart} from "@eazy2biz-ui/common-components";
import {transformChartData} from "../../helpers/ChartHelper";

export const BarChartComponent = (
    props: {
        reportResponse : ReportResponse,
        barChartDetails : BarChartDetails,
        chartStartDate : Moment,
        chartEndDate : Moment,
        chartData: AggregationResult[]
    }

): JSX.Element => {

    const {
        reportResponse,
        barChartDetails,
        chartStartDate,
        chartEndDate,
        chartData
    } = props;


    return (
        <GenericBarChart
            barChartType={barChartDetails.barChartType}
            data={transformChartData(chartData, ChartType.BAR, barChartDetails)}
            groupingFieldKey={'groupingFieldValue'}
            aggregationValueKey={'aggregationValue'}
            {...(barChartDetails.subGrouping ? {subGroupingFieldKey : 'subGroupingFieldValue'} : {})}
        />
    );
}
