import {ColumnHeader} from "@eazy2biz-ui/common-components";

export enum TodoHeaders {
    TODO_NUMBER = 'todoNumber',
    NAME = 'name',
    WORKFLOW_NAME = 'workflowName',
    STAGE_NAME = 'stageName',
    DUE_ON = 'dueOn',
    CREATED_ON = 'createdOn',
    ASSIGNEE = 'assignee',
    CREATED_BY = 'createdBy',
    STATUS = 'status'
};

export const getColumnsForTodos = (isWorkflowTable: boolean): ColumnHeader[] => {
    const columns: ColumnHeader[] = [
        {
            Header: '#',
            accessor: TodoHeaders.TODO_NUMBER
        },
        {
            Header: 'Title',
            accessor: TodoHeaders.NAME
        }
        ];

    isWorkflowTable && columns.push({
        Header: 'Stage',
        accessor: TodoHeaders.STAGE_NAME
    });

    columns.push(...[
        {
            Header: 'Due On',
            accessor: TodoHeaders.DUE_ON
        },
        {
            Header: 'Created On',
            accessor: TodoHeaders.CREATED_ON
        },
        {
            Header: 'Assigned To',
            accessor: TodoHeaders.ASSIGNEE
        },
        {
            Header: 'Created By',
            accessor: TodoHeaders.CREATED_BY
        },
        {
            Header: 'Status',
            accessor: TodoHeaders.STATUS
        }
    ]);

    return columns;
};
