import {Select, Tag} from 'antd';
import type {CustomTagProps} from 'rc-select/lib/BaseSelect';
import styles from './DropdownSelect.module.css';
import GenericTag from '../GenericTag/GenericTag';
import {GenericTagTypes} from '../GenericTag/GenericTagTypes';
import {MenuData} from './MenuData';
import {GenericDropdownRow} from "../GenericDropdownRow/GenericDropdownRow";
import { CloseCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import * as React from "react";

export const DropdownMultiSelect = (props: PropTypes) => {
    const {placeholder, menuData, selectedItem, onItemSelect, label} = props;
    const {Option} = Select;

    const handleItemSelection = (event: any) => {
        onItemSelect(event);
    };

    const renderSelectOptions = () => {
        return (
            menuData.map((item: MenuData, index: number) => {
                return (
                    <Option key={item._id} value={item._id} id={index}>
                        <GenericDropdownRow
                          id={item._id}
                          value={item.name}
                          tagTitle={item.tagTitle}
                          className={props.rowClassName}
                          renderIcon={item.renderIcon} />
                    </Option>
                );
            })
        );
    };

    const tagRender = (props: CustomTagProps) => {
        const {value, onClose} = props;
        const item = menuData.find((data) => data._id === value);
        return (
            <GenericTag title={item?.name} onRemove={onClose} type={GenericTagTypes.MULTISELECT}/>
        );
    };


    const renderLabel = () => {
        return (
            <p className={styles.dropdownLabel}>{label}</p>
        )

    }

    return (
        <>
            {label && renderLabel()}
            <Select
                allowClear
                clearIcon={<CloseCircleOutlined className={classNames(styles.dropdownClearBtnPosition)}/>}
                disabled={props.disabled}
                onBlur={props.onBlur}
                mode="multiple"
                tagRender={tagRender}
                defaultValue={selectedItem}
                onChange={handleItemSelection}
                placeholder={placeholder}
                className={props.className}
                dropdownClassName = {classNames(styles.dropdownStyle)}>
                {renderSelectOptions()}
            </Select>
        </>
    );
};

type PropTypes = {
    placeholder?: string;
    menuData: MenuData[];
    label?: string;
    selectedItem: string[];
    onItemSelect: (id: string[]) => void;
    disabled?: boolean;
    className?: string;
    rowClassName?: string;
    onBlur?: React.FocusEventHandler<HTMLElement>;
}


