import {Switch} from "antd";
import {SwitchChangeEventHandler, SwitchSize} from "antd/lib/switch";
import styles from './ToggleButton.module.css';
import classNames from "classnames";

/**
 * Toggle Button
 * @param props
 * @constructor
 */
export const ToggleButton = (props: PropTypes) => {

    return (
        <div className={classNames(styles.toggleWrapper, props.wrapperClassName)}>
            {
                props.toggleButtonOnLeft
                    ? (
                        <>
                            <Switch size={props.size || 'small'} checked={props.value} className={classNames(styles.toggleButton, props.className)} onChange={props.onToggle}/>
                            <p className={props.labelClassName}>{props.label}</p>
                        </>
                    )
                    : (
                        <>
                            <p className={props.labelClassName}>{props.label}</p>
                            <Switch size={props.size || 'small'} checked={props.value} className={classNames(styles.toggleButton, props.className)} onChange={props.onToggle}/>
                        </>
                    )
            }
        </div>
    );
};

type PropTypes = {
    value: boolean;
    toggleButtonOnLeft?: boolean;
    onToggle: SwitchChangeEventHandler;
    className?: string;
    wrapperClassName?: string;
    size?: SwitchSize;
    label: string;
    labelClassName?: string;
};
