import {CompanyCreateRequest} from "@eazy2biz/common-util";
import {StrictBuilder} from "builder-pattern";

export const getCompanyCreateRequest = (
    name : string,
    description : string,
    email: string,
): CompanyCreateRequest => {
    return StrictBuilder<CompanyCreateRequest>()
        .name(name)
        .description(description)
        .companyEmail(email)
        .build()
    ;
}
