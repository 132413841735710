import {GenericButton, GenericModal} from '@eazy2biz-ui/common-components';
import styles from './ConditionBuilder.module.css';
import {CONDITION_COMPONENT_STRINGS, CONDITIONAL_STAGE, workflowContent} from "../../../../contents/DisplayContent";
import ConditionComponent from "./ConditionComponent";
import {useState} from "react";
import {Condition, ConditionExpression, Field} from "@eazy2biz/common-util";
import {
    cleanConditionExpression,
    getDefaultConditionExpression
} from "../../../helpers/workflowBuilderHelpers/runtimeHelpers/conditions/ConditionHelper";
import {cloneDeep} from "lodash";
import {ICondition} from "../../../entity/conditionBuilder/ICondition";

/**
 * Condition Builder Modal
 * @param props
 * @constructor
 */
const ConditionBuilder = (props: PropTypes) => {

    const {
        onClose,
        onSubmit,
        trueStageName,
        falseStageName
    } = props;

    const getDefaultSubConditionMap = (): Map<string, ICondition | ConditionExpression> => {
        const conditionMap = new Map<string, ICondition | ConditionExpression>();
        (props.condition || getDefaultConditionExpression()).subExpressions.forEach((subCondition, idx) => {
            const key = (new Date()).getMilliseconds() + "_" + idx;
            // TODO: Assumption for non nested conditions.
            conditionMap.set(key, ICondition.getFromCondition(subCondition as Condition));
        });

        return conditionMap;
    };

    const [logicalOperator, setLogicalOperator] = useState((props.condition || getDefaultConditionExpression()).logicalOp);
    const [expressionType] = useState((props.condition || getDefaultConditionExpression()).type);
    const [subConditionMap, setSubConditionMap] = useState<Map<string, ICondition | ConditionExpression>>(getDefaultSubConditionMap());

    /**
     * Get ConditionExpression from State
     */
    const getConditionFromState = (): ConditionExpression => {
        const subExpressionsToSave: Condition[] = [];
        subConditionMap.forEach((val) => {
            subExpressionsToSave.push(ICondition.getConditionPayload((val as ICondition)))
        });

        return {
            type: expressionType,
            logicalOp: logicalOperator,
            subExpressions: subExpressionsToSave
        };
    };

    const handleSubmit = () => {
        onSubmit(cleanConditionExpression(getConditionFromState()))
    };

    const handleSubConditionUpdate = (id: string, value?: ICondition) => {
        const subExpressionsMapClone = cloneDeep(subConditionMap);
        if (value) {
            subExpressionsMapClone.set(id, value)
        } else {
            subExpressionsMapClone.delete(id);
        }

        setSubConditionMap(subExpressionsMapClone);
    };


    const renderModalSubHeader = () => {
        return (
            <>
                {
                    trueStageName && falseStageName &&
                    <p>
                        <span>{CONDITIONAL_STAGE.CONDITION}</span>
                        <span className={styles.conditionalStageTrue}>{CONDITIONAL_STAGE.TRUE}</span>
                        <span>{`${CONDITIONAL_STAGE.GOES_TO} ${trueStageName}, `}</span>
                        <span>{CONDITIONAL_STAGE.CONDITION}</span>
                        <span className={styles.conditionalStageFalse}>{CONDITIONAL_STAGE.FALSE}</span>
                        <span>{`${CONDITIONAL_STAGE.GOES_TO} ${falseStageName}`}</span>
                    </p>
                }
            </>
        )
    }

    const renderModelBody = () => {
        return (
            <div className={styles.conditionBodyScroll}>
                <ConditionComponent
                    fields={props.fields}
                    logicalOperator={logicalOperator}
                    subExpressionsMap={subConditionMap}
                    onLogicalOpUpdate={setLogicalOperator}
                    onSubExpressionUpdate={handleSubConditionUpdate}
                />
            </div>
        )
    }

    const renderModelFooter = () => {
        return (
            <div>
                <GenericButton onClick={handleSubmit} buttonText={workflowContent.SAVE} className={styles.footerButton} />
            </div>
        )
    }


    return (
        <GenericModal
            show
            title={CONDITION_COMPONENT_STRINGS.CONDITION_BUILDER_TITLE}
            subHeader={renderModalSubHeader}
            bodyContent={renderModelBody}
            onClose={onClose}
            footerContent={renderModelFooter}
            dialogClassName={styles.modal}
        />
    );
};

type PropTypes = {
    fields: Field[];
    condition?: ConditionExpression;
    onClose: () => void;
    trueStageName?: string;
    falseStageName?: string;
    onSubmit: (condition?: ConditionExpression) => void;
};

export default ConditionBuilder;

