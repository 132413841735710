import { MagnifyingGlassIcon } from '@radix-ui/react-icons';
import { Input } from 'antd';
import { useEffect } from "react";

const { Search } = Input;

/**
 * Generic search box.
 * @param props
 * @constructor
 */
export const GenericSearchInput = (props: PropTypes) => {

  useEffect(() => {
    if (props.clearSearchValue) {
      props.setClearSearchValue && props.setClearSearchValue(false);
    }
  }, [props.clearSearchValue])

  if (props.clearSearchValue) {
    return null;
  }

  return (
    <Search
      placeholder={props.placeholder || "Type to search here"}
      onSearch={props.onSearch}
      allowClear
      enterButton={<MagnifyingGlassIcon style={{marginTop: '-2px'}} />}
      size={'small'}
    />
  );
};

type PropTypes = {
  clearSearchValue?: boolean;
  setClearSearchValue?: (val: boolean) => void;
  onSearch: (value: string) => void;
  placeholder?: string;
};
