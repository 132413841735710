import {FieldPlaceholderType} from "@eazy2biz/common-util";

export const getSinglePlaceholderTriggerByType = (type: FieldPlaceholderType) => {
    switch (type) {
        default:
            return ('@');
    }
};

export const getSinglePlaceholderSuggestionDisplayValuePathByType = (type: FieldPlaceholderType) => {
    switch (type) {
        case FieldPlaceholderType.USER:
            return 'name';
        default:
            return ('');
    }
};
