import {InputProps as DefaultInputPropTypes} from "antd/lib/input/Input";
import {CustomerTableRecordResponse, Field} from "@eazy2biz/common-util";
import {CustomerTableRecordSelect} from "../../../CustomerTableRecordSelect/CustomerTableRecordSelect";

/**
 * Card form field of type Database for customer table.
 * @param props
 * @constructor
 */
export const CardFormTableRecordComponent = (props: PropTypes) => {
  const {fieldConfigurations} = props.field;

  if (!fieldConfigurations || !fieldConfigurations.groupCorrelationKey || !fieldConfigurations.correlationKey) {
      return null;
  }

  const getValueForRecordKey = (record: CustomerTableRecordResponse) => {
      const headerContent = record.tableHeaderContents
          .find((element) => element.header._id === fieldConfigurations.correlationKey);

      if (headerContent) {
          return headerContent.content?.toString();
      }

      return '';
  };

  const handleOnItemSelect = (record?: CustomerTableRecordResponse) => {
    props.onChange && props.onChange({
        // @ts-ignore
        target: {
            value: record?._id || ''
        }
    });
  };

  const getRecordLabel = (record: CustomerTableRecordResponse) => {
      return (getValueForRecordKey(record));
  };

  return (
      <CustomerTableRecordSelect
          onRecordSelect={handleOnItemSelect}
          selectedRecordId={props.defaultValue?.toString()}
          tableId={fieldConfigurations.groupCorrelationKey}
          getRecordLabel={getRecordLabel}
          required={props.required}
          disabled={props.disabled || props.readOnly} />
  );
};

interface PropTypes extends DefaultInputPropTypes {
  field: Field;
}
