import {Image} from "react-bootstrap";
import * as React from "react";
import {EMPTY_RESULTS_TEXT} from "../../../contents/DisplayContent";
import styles from "./EmptyDataPlaceholderGraphic.module.css";
import emptyGraphic from '../../../assets/emptyBox.svg';
import { ReactNode } from "react";
import classNames from "classnames";

export const EmptyDataPlaceholderGraphic = (props: PropTypes): JSX.Element => {
    return (
      <div className={styles.emptyWrapper}>
        <div className={classNames(styles.emptyTableContainer, {[styles.emptyTableContainerSmall]: props.size === 's'})}>
          <Image src={emptyGraphic} width={'50%'} height={'70%'}/>
          <div className={styles.emptyTableText}>{props.text || EMPTY_RESULTS_TEXT}</div>
        </div>
      </div>
    );
};

type PropTypes = {
  size?: typeof Size;
  text?: string | ReactNode;
};

declare const Size: 's' | 'l';
