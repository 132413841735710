import {
    ActivityCreateRequest,
    ActivityGroupIdentifierRequest,
    ActivityType,
    EntityType
} from "@eazy2biz/common-util";
import {StrictBuilder} from "builder-pattern";

export const getCommentsPayload = (entityId: string, entityClassId: string, entityType: EntityType): ActivityGroupIdentifierRequest => {
    return {
        activityType: ActivityType.COMMENT,
        entityType,
        entityId,
        entityClassId
    };
};

export const addCommentPayload = (message: string, entityId: string, entityClassId: string, entityType: EntityType): ActivityCreateRequest => {
    return StrictBuilder<ActivityCreateRequest>()
        .activityType(ActivityType.COMMENT)
        .entityType(entityType)
        .entityId(entityId)
        .entityClassId(entityClassId)
        .message(message)
        .build();
};
