import {GenericListInput} from "@eazy2biz-ui/common-components";
import {
    CustomerTableHeader, CustomerTableHeaderValidation,
    CustomerTableHeaderValidationType,
} from "@eazy2biz/common-util";

/**
 * Custom Configurations fot dropdown header type.
 * @param props
 * @constructor
 */
export const DropdownConfigurations = (props: PropTypes) => {

    const validation = props.tableHeader.validations.find((validation) =>
        validation.headerValidationType === CustomerTableHeaderValidationType.VALUE_IN_ARRAY);

    const defaultValues: string[] = validation?.validationValue || [];

    const handleDropdownValuesChange = (values: string[]) => {
        let updatedHeader: CustomerTableHeader = props.tableHeader;
        updatedHeader.validations =
            [
                new CustomerTableHeaderValidation(CustomerTableHeaderValidationType.VALUE_IN_ARRAY, values),
                ...updatedHeader.validations.filter(
                    (validation) => validation.headerValidationType !== CustomerTableHeaderValidationType.VALUE_IN_ARRAY
                )
            ];
        props.onHeaderUpdate(updatedHeader);
    };

    return (<GenericListInput id='dropdownValues' onChange={handleDropdownValuesChange} defaultValues={defaultValues}/>);
};

type PropTypes = {
    tableHeader: CustomerTableHeader;
    onHeaderUpdate: (header: CustomerTableHeader) => void;
};
