import { TourType } from "./entity/TourType";
import { TourProps } from "antd";

export const TOUR_CONFIGS: {[key: string]: TourProps['steps']} = {
  [TourType.HOMEPAGE]: [
    {
      title: 'Create Workflow',
      description: 'Create your own workflow.',
      type: 'primary',
    },
    {
      title: 'Create Item',
      description: 'Create items in your workflow.',
    },
    {
      title: 'Complete Todo',
      description: 'Complete your daily assigned tasks.',
    },
    {
      title: 'Manage your workflows',
      description: 'View and manage your workflows.',
    }
  ],
  [TourType.WORKFLOW_HOMEPAGE]: [
    {
      title: 'Create Item',
      description: 'Create an Item in your workflow from here.',
      type: 'primary',
    },
    {
      title: 'Edit your Workflow',
      description: 'Missed adding elements, edit your workflow from here.'
    },
    {
      title: 'Duplicate workflow',
      description: 'Want to create a similar workflow ? Create by clicking here.'
    }
  ],
  [TourType.WORKFLOW_BUILD_START]: [
    {
      title: 'Configure Start Stage',
      description: 'This is the first stage for your workflow, click here to configure the properties.',
      type: 'primary',
    },
    {
      title: 'Edit your stage form from here.',
      description: 'This is the first form for your stage, edit it by clicking here.'
    },
    {
      title: 'Add a new stage to your workflow',
      description: 'Click here to add the next automated stage into your workflow'
    },
  ],
  [TourType.STAGE_CONFIGURATIONS]: [
    {
      title: 'Set Assignee to your stage',
      description: 'Assign this stage to a user, user group or a dynamic assignee from the form input.\n' +
        'A todo will be created automatically for the assignee as your item reached this stage.',

      type: 'primary',
    },
    {
      title: 'Set due date fort his stage.',
      description: 'Set the due date for this task.'
    },
    {
      title: 'Add actions to your stage',
      description: 'You can add many automated actions when an item enters/ leaves or due date is breached.'
    },
  ],
  [TourType.FORM_BUILDER]: [
    {
      title: 'Pick & drag form types',
      description: 'There are many types of form elements present. Pick and start dragging.',
      type: 'primary',
    },
    {
      title: 'Drag and drop the form item',
      description: 'Drop the form item here to add it to the form.'
    }
  ]
};
