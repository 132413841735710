import {InputProps as DefaultInputPropTypes} from "antd/lib/input/Input";
import {CustomerTableHeader} from "@eazy2biz/common-util";
import styles from './TableHeaderUploadComponent.module.css';
import classNames from "classnames";
import {UploadField} from "@eazy2biz-ui/common-components";

/**
 * Card form field for FILE type.
 * @param props
 * @constructor
 */
export const TableHeaderUploadComponent = (props: PropTypes) => {

    let defaultValues = [];

    if (props.defaultValue instanceof Array) {
        // @ts-ignore
        defaultValues = props.defaultValue;
    }

    return (
        <UploadField
            className={classNames(styles.tableHeaderUploadField, {[styles.tableHeaderUploadFieldReadOnly]: props.readOnly})}
            onChange={props.onChange}
            readOnly={props.readOnly}
            defaultValue={defaultValues}/>
    );
}

interface PropTypes extends DefaultInputPropTypes {
    header: CustomerTableHeader;
};
