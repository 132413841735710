// WORKFLOWS
export const workflowContent = {
  MY_WORKFLOWS_TILE: 'My Workflows',
  PUBLISH: 'Publish',
  SIMULATE: 'Simulate',
  SAVE: 'Save',
  DELETE: 'Delete',
  RESET: 'Reset',
  DRAFT: 'Draft',
  NEW_ITEM: 'New Item',
  DUPLICATE_WORKFLOW: ' Duplicate Workflow',
  EDIT_WORKFLOW: 'Edit Workflow',
  DISABLE_WORKFLOW: 'Disable Workflow',
  ITEM_DETAILS_INPUT: 'Enter Item Details',
  NAME: 'Name',
  DESCRIPTION: 'Description',
  ITEM_MODAL_TITLE: 'Create a new Item',
  ITEM_SUBMIT_BUTTON: 'Create Item',
  UPDATE_WORKFLOW: 'Update',
  CREATE_WORKFLOW_PROGRESS: 'Creating new workflow...',
  UPDATE_WORKFLOW_PROGRESS: 'Updating workflow...',
  DISABLE_WORKFLOW_ALERT_MESSAGE: 'Are you sure you want to disable this workflow?\nThis action cannot be undone.',
  DISABLE_WORKFLOW_PROGRESS: 'Disabling workflow...',
  DISABLE_WORKFLOW_SUCCESS: 'Workflow disabled successfully.',
  DISABLE_WORKFLOW_ERROR: 'There was some error while disabling this workflow, please try again.',
};

export const WORKFLOW_HOMEPAGE = {
  ERROR_FETCH_WORKFLOW: 'Error getting your workflow.',
  ERROR_FETCH_WORKFLOW_LIST: 'Error getting your workflows.',
  WORKFLOW_TEMPLATES: 'Workflow Templates',
};

export const ITEMS_STRINGS = {
  ERROR_FETCH_ITEMS: 'Error in getting your items',
  CREATE_ITEM_PROGRESS: 'Creating new item...',
  FETCHING_CARD_FORM: 'Loading form...',
};

export const WORKFLOW_BUILDER_ERROR_STRINGS = {
  UNKNOWN_SAVE_ERROR: 'Error saving workflow, please retry after some time.',
  ERROR_ADDING_STAGE: 'Error while adding new stage',
  ERROR_REMOVING_STAGE: "The stage couldn't be removed",
  FIELDS_EMPTY: 'Add at least one field',
  STAGES_NOT_ADDED: 'Add at least one stage',
  NO_PERMISSION_FOR_WORKFLOW: 'You are not authorized to do this.',
};

export const WORKFLOW_BUILDER_SUCCESS_STRINGS = {
  SAVE_DRAFT: 'Workflow saved as draft',
  CREATE_ITEM_SUCCESS: 'New item created successfully',
  PUBLISH_SUCCESS: 'Workflow Successfully Published'
};

export const STAGE_FORM_ERROR_STRING = {
  CREATE_ITEM_ERROR: 'New Item Creation Failed'
};

export const ITEM_DETAILS_STRINGS = {
  SUBMIT_BUTTON: 'Proceed to next stage',
  MY_ITEMS: ' My Item',
  SHARE: 'Share',
  DISCARD: 'Discard',
  EDIT: 'Edit',
  CANCEL: 'Cancel',
  SHARE_LINK: 'Sharable link copied!',
  EDIT_SUCESS: 'Changes are Successful',
  EDIT_ERROR: 'Error In Changing One or More Field Value',
  ACTIVITY: 'Activity',
  COMMENTS: 'Comments',
  ERROR_FETCH_ACTIVITIES: 'Error fetching Audit Trail.',
  ERROR_COMPLETE_CARD: 'Error completing card.',
  SAVE: 'Save',
  REJECTED_ITEM: 'Reject item',
};

export const CONDITIONAL_STAGE = {
  TITLE: 'New Conditional Stage',
  CONDITION: 'Condition ',
  GOES_TO: 'goes to ',
  TRUE: 'True ',
  FALSE: 'False '
};

export const CONDITION_COMPONENT_STRINGS = {
  CONDITION_BUILDER_TITLE: 'Condition Builder',
  IF: 'If',
  OPERATOR: 'Operator',
  ADD_NEW_CONDITION: ' Condition',
  FIRST_FIELD_PLACEHOLDER: 'Select field',
  OPERATOR_FIELD_PLACEHOLDER: 'Eg. Less Than',
  SECOND_FIELD: 'Value'
};

export const TAG_STAGE = {
  COMPLETED: 'Completed',
  PENDING: 'Pending',
  DISCARDED: 'Discarded',
  ARCHIVED: 'Archived'
};

export const REPORT_TABLE = {
  DOWNLOAD_EXCEL: 'Download Excel'
};

export const ITEM_FILTER_MENU = {
  DUE_BEFORE: 'Due Before',
  PENDING_ONLY: 'Active Items Only',
  CREATED_BEFORE: 'Created Before',
  COMPLETED_BEFORE: 'Completed Before',
  ASSIGNED_TO: 'Assigned To',
  CURRENT_STAGE: 'Current Stage',
  ADVANCED_FILTER: 'Advanced Filters',
  ONLY: ' Only'
};

export const STAGE_CONFIGURATIONS = {
  ASSIGNEE_FORM_TOGGLE: 'Select assignee from form',
  ASSIGN_TO: 'Assign To',
};

export const STAGE_ACTION_CONFIGURATIONS = {
  STAGE_ENTRY: 'Actions on Stage Entry',
  STAGE_EXIT: 'Actions on Stage Exit',
  SLA_BREACH: 'Actions on breach of due date'
};

/*
 * Card Display Content
 * */
export const CREATE_ITEM_ACTION = 'Create Item';
