import React from "react";
import {Layout, Layouts, Responsive, WidthProvider} from "react-grid-layout";

// these styles have been copied from
// 1. react-grid-layout(v 1.3.4) -> path : /node_modules/react-grid-layout/css/styles.css
// 2. react-resizable(v 3.0.5) -> path : /node_modules/react-resizable/css/styles.css
import './ResizeableGridLayout.css'

export const ResizeableGridLayout = (
    props: {
        children: React.ReactNode,
        layoutFetcher: () => Layout[],
        handleLayoutChange: (newLayout : Layout[]) => void,
    }
): JSX.Element => {

    const {
        layoutFetcher,
        handleLayoutChange
    } = props;

    const ResponsiveGridLayout = WidthProvider(Responsive);

    return (
        <>
            <ResponsiveGridLayout
                className={"layout"}
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                cols={{ lg: 8, md: 6, sm: 3, xs: 1, xxs: 1 }}
                rowHeight={50}
                margin={[20, 20]}
                layouts={{
                    lg : layoutFetcher(),
                    md: layoutFetcher(),
                    sm: layoutFetcher(),
                    xs : layoutFetcher(),
                    xxs: layoutFetcher()
                }}
                onLayoutChange={(
                    currentLayout: Layout[], allLayouts: Layouts,
                ) => {
                    handleLayoutChange(currentLayout)
                }}
            >

                {props.children}
            </ResponsiveGridLayout>

        </>
    );
}
