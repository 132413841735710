import {InputProps as DefaultInputPropTypes} from "antd/lib/input/Input";
import {DropdownSelect} from "../../../../genericComponents/select/DropdownSelect";
import {Field} from "@eazy2biz/common-util";
import {MenuData} from "../../../../genericComponents/select/MenuData";
import classNames from "classnames";
import styles from './CardFormDropdown.module.css';
import { getDropdownMenuForField, getMultiSelectEnabledForField } from "@eazy2biz-ui/common-package";
import { DropdownMultiSelect } from "../../../../genericComponents/select/DropdownMultiSelect";

/**
 * Card form field of type dropdown.
 * @param props
 * @constructor
 */
export const CardFormDropdownComponent = (props: PropTypes) => {

  const menuData: MenuData[] = getDropdownMenuForField(props.field);

  const handleOnItemSelect = (id: any) => {
    props.onChange && props.onChange({
      // @ts-ignore
      target: {
        value: id
      }
    });
  };
  const renderSingleSelect = () => {

    return (
        <DropdownSelect
            className={classNames(props.className, styles.cardDropdownSelect)}
            rowClassName={styles.cardDropdownRow}
            menuData={menuData}
            disabled={props.disabled || props.readOnly}
            selectedItem={props.defaultValue as string}
            onBlur={props.onBlur}
            onItemSelect={handleOnItemSelect} />
    );
  };

  const renderMultiSelect = () => {

    const getDefaultValue = () => {
      return props.defaultValue && props.defaultValue !== '' ? props.defaultValue as string[] : [];
    };

    return (
      <DropdownMultiSelect
        className={classNames(props.className, styles.cardDropdownSelect)}
        rowClassName={styles.cardDropdownRow}
        menuData={menuData}
        disabled={props.disabled || props.readOnly}
        selectedItem={getDefaultValue()}
        onItemSelect={handleOnItemSelect} />
    );
  };

  return getMultiSelectEnabledForField(props.field) ? renderMultiSelect() : renderSingleSelect();
};

interface PropTypes extends DefaultInputPropTypes {
  field: Field;
};
