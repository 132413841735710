import {Handle, Position} from 'react-flow-renderer';
import FormStageIcon from "../../../../assets/formStage.svg";
import ApprovalStageIcon from "../../../../assets/ApprovalStage.svg";
import ConditionStageIcon from "../../../../assets/ConditionStage.svg";
import {StageData} from "../../../entity/workflowBuilder/StageData";
import styles from './FormNode.module.css';
import classNames from "classnames";
import {StageTypes} from "@eazy2biz/common-util";
import {NodeProps} from "./GenericStageNode";
import {Tooltip} from "antd";
import {getUserNameById} from "@eazy2biz/common-package-ui";

const ReadOnlyFormNode = (props: NodeProps) => {
    const nodeData = props.data as StageData;
    const assigneeName = getUserNameById(nodeData.stageConfiguration.assignee.assignees[0]);

    const handleNodeClick = () => {
        nodeData.props.onClick &&
        nodeData.stageConfiguration.type !== StageTypes.CONDITIONAL_STAGE &&
        nodeData.props.onClick(nodeData.stageConfiguration._id);
    };

    // const handleSecondaryClick = () => {
    //     nodeData.props.onSecondaryClick && nodeData.props.onSecondaryClick(nodeData.stageConfiguration._id);
    // };

    const nodeWrapperClasses = classNames(
        styles.canvasFormNode,
        {
            [styles.canvasFormNodeSelected_Highlighted]: nodeData.props.isHighlighted || nodeData.props.isSelected,
            [styles.canvasApprovalNode]: nodeData.stageConfiguration.type === StageTypes.APPROVAL_STAGE,
            [styles.canvasConditionalNode]: nodeData.stageConfiguration.type === StageTypes.CONDITIONAL_STAGE
        }
    );

    const innerWrapperClasses = classNames(
        styles.innerNode,
        {
            [styles.canvasNodeHighlighted]: nodeData.props.isHighlighted,
            [styles.canvasNodeSelected]: nodeData.props.isSelected,
            [styles.canvasApprovalNode]: nodeData.stageConfiguration.type === StageTypes.APPROVAL_STAGE,
            [styles.canvasConditionalNode]: nodeData.stageConfiguration.type === StageTypes.CONDITIONAL_STAGE
        }
    );

    const getStageIcon = () => {
        if (nodeData.stageConfiguration.type === StageTypes.FORM_STAGE) {
            return(FormStageIcon);
        }

        if (nodeData.stageConfiguration.type === StageTypes.APPROVAL_STAGE) {
            return(ApprovalStageIcon);
        }

        return(ConditionStageIcon);
    };

    const getToolTipText = (): string => {
        return 'Assigned to: ' + assigneeName;
    };

    return (
        <Tooltip placement="top" title={getToolTipText()}>
            <div className={nodeWrapperClasses}>
                <Handle
                    type="target"
                    position={Position.Top}
                    id="a"
                    style={{ background: '#555' }}
                    isConnectable={true}
                />
                <div className={innerWrapperClasses}>
                    <div onClick={handleNodeClick} className={styles.canvasFormNodeLabel}>
                        <img className={styles.stageIcon} src={getStageIcon()}/>
                        {nodeData.details.label}
                    </div>
                </div>
                <Handle
                    type="source"
                    position={Position.Bottom}
                    id="a"
                    style={{ background: '#555' }}
                    isConnectable={true}
                />
            </div>
        </Tooltip>
    );
};

export default ReadOnlyFormNode;
