import {Line} from "@ant-design/charts";
import React from "react";

import styles from './GenericLineChart.module.css'

export const GenericLineChart = (
    props: {
        data: any[],
        groupingField : string,
        subGroupingField?: string,
        aggregationValueKey : string,
        aggregationFieldLabel : string,
    }

): JSX.Element => {

    const {
        data,
        groupingField,
        subGroupingField,
        aggregationValueKey,
        aggregationFieldLabel
    } = props;

    return (
        <div className={styles.lineChart}>
            <Line
                autoFit={true}
                data={data}
                padding="auto"
                xField={groupingField}
                yField={aggregationValueKey}
                {...(subGroupingField ? {seriesField : subGroupingField} : {})}
                legend={{
                    position: 'bottom-right',
                }}
                yAxis={{
                    max : Math.max(...data.map(dataPoint => dataPoint[aggregationValueKey] as number), 0) * 1.1
                }}
                meta={{
                    [aggregationValueKey]: {
                        alias: aggregationFieldLabel,
                    },
                }}
            />
        </div>
    );
}
