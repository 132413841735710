import {BaseChartDetails, ChartType, ReportEntityType} from "@eazy2biz/common-util";
import React from "react";
import {ConfigureLineChartDetails} from "./lineChart/ConfigureLineChartDetails";
import {ConfigureBarChartDetails} from "./barChart/ConfigureBarChartDetails";
import {ConfigureAreaChartDetails} from "./areaChart/ConfigureAreaChartDetails";
import {ConfigurePieChartDetails} from "./pieChart/ConfigurePieChartDetails";
import {ConfigureTableChartDetails} from "./tableChart/ConfigureTableChartDetails";

export const ConfigureChartDetails = (
    props : {
        chartType : ChartType,
        entityType: ReportEntityType;
        entityId: string;
        entity: any;
        showChartConfigureFormFieldErrors: boolean;
        handleChartDetailsChange: (newChartDetails : BaseChartDetails<ChartType>) => void;
    }
) : JSX.Element => {

    const {
        chartType,
        entityType,
        entityId,
        entity,
        showChartConfigureFormFieldErrors,
        handleChartDetailsChange
    } = props;

    const getChartDetailsComponent = () : React.ReactNode => {
        switch (chartType) {
            case ChartType.LINE:
                return (
                    <ConfigureLineChartDetails
                        entityType={entityType}
                        entityId={entityId}
                        entity={entity}
                        showChartConfigureFormFieldErrors={showChartConfigureFormFieldErrors}
                        handleChartDetailsChange={handleChartDetailsChange}
                    />
                );
            case ChartType.AREA:
                return (
                    <ConfigureAreaChartDetails
                        entityType={entityType}
                        entityId={entityId}
                        entity={entity}
                        showChartConfigureFormFieldErrors={showChartConfigureFormFieldErrors}
                        handleChartDetailsChange={handleChartDetailsChange}
                    />
                );
            case ChartType.BAR:
                return (
                    <ConfigureBarChartDetails
                        entityType={entityType}
                        entityId={entityId}
                        entity={entity}
                        showChartConfigureFormFieldErrors={showChartConfigureFormFieldErrors}
                        handleChartDetailsChange={handleChartDetailsChange}
                    />
                );
            case ChartType.PIE:
                return (
                    <ConfigurePieChartDetails
                        entityType={entityType}
                        entityId={entityId}
                        entity={entity}
                        showChartConfigureFormFieldErrors={showChartConfigureFormFieldErrors}
                        handleChartDetailsChange={handleChartDetailsChange}
                    />
                );
            case ChartType.TABLE:
                return (
                    <ConfigureTableChartDetails
                        entityType={entityType}
                        entityId={entityId}
                        entity={entity}
                        showChartConfigureFormFieldErrors={showChartConfigureFormFieldErrors}
                        handleChartDetailsChange={handleChartDetailsChange}
                    />
                );
        }
    }

    return (
        <>
            {
                getChartDetailsComponent()
            }
        </>
    );
}
