import {ReportChartUIGridConfig} from "@eazy2biz/common-util";
import {Layout} from "react-grid-layout";
import {StrictBuilder} from "builder-pattern";

export const convertReportChartUIGridConfigToGridLayout = (reportChartUIGridConfigs : ReportChartUIGridConfig[]) : Layout[] => {
    return reportChartUIGridConfigs.map(
        reportChartUIGridConfig =>
            StrictBuilder<Layout>()
                .i(reportChartUIGridConfig.chartId)
                .x(reportChartUIGridConfig.xCoordinate)
                .y(reportChartUIGridConfig.yCoordinate)
                .w(reportChartUIGridConfig.width)
                .h(reportChartUIGridConfig.height)
                .build()
    )
}

export const convertGridLayoutToReportChartUIGridConfig = (gridLayouts : Layout[]) : ReportChartUIGridConfig[] => {
    return gridLayouts.map(
        gridLayout =>
            StrictBuilder<ReportChartUIGridConfig>()
                .chartId(gridLayout.i)
                .xCoordinate(gridLayout.x)
                .yCoordinate(gridLayout.y)
                .width(gridLayout.w)
                .height(gridLayout.h)
                .build()
    )
}
