import React, {useEffect, useState} from "react";
import {DropdownSelect, MenuData, Toast} from "../../../index";
import styles from './CustomerTableSelect.module.css';
import classNames from "classnames";
import {CustomerTableResponse} from "@eazy2biz/common-util";
import {getAllCustomerTables} from "../../services/CustomerTableService";

/**
 * Assignee Select Component
 * @param props
 * @constructor
 */
export const CustomerTableSelect = (props: PropTypes) => {
    const {required = true, disabled} = props;

    const [tableMenuList, setTableMenuList] = useState<MenuData[]>([]);
    const [tables, setTables] = useState<CustomerTableResponse[]>([]);

    const convertTableListToData = (tables: CustomerTableResponse[]): MenuData[] => {
        return tables.map((table) => ({
            _id: table._id.toString(),
            name: table.details.name,
        }));
    };

    useEffect(() => {
        getAllCustomerTables()
        .then((response) => {
            setTableMenuList(convertTableListToData(response));
            setTables(response);
        })
        .catch(() => {
            Toast.errorString();
        });
    }, []);

    const getColumnMenuList = ():MenuData[] => {
        const list: MenuData[] = [];
        const selectedTable = tables.find((table) => table._id === props.selectedTableId);

        selectedTable && selectedTable.tableHeaders.forEach((tableHeader) => {
            list.push({
                _id: tableHeader._id,
                name: tableHeader.name
            });
        });

        return list;
    };


    const renderTableDropdown = () => {
        return (
            <>
                <span className={classNames(styles.tableTitle, {[styles.tableRequired]: required})}>{props.tableLabel || "Select Table"}</span>
                <div className={styles.tableDropdown}>
                    <DropdownSelect
                        placeholder={"Select Table"}
                        onItemSelect={props.onTableSelect}
                        menuData={tableMenuList}
                        disabled={disabled}
                        selectedItem={props.selectedTableId || ''}
                    />
                </div>
            </>
        );
    };

    const renderTableColumnDropdown = () => {
        return (
            <>
                <span className={classNames(styles.tableTitle, {[styles.tableRequired]: required})}>{props.tableColumnLabel || "Select Column Key"}</span>
                <div className={styles.tableDropdown}>
                    <DropdownSelect
                        placeholder={"Select Column Key"}
                        onItemSelect={props.onTableColumnSelect}
                        menuData={getColumnMenuList()}
                        disabled={disabled}
                        selectedItem={props.selectedTableColumnId || ''}
                    />
                </div>
            </>
        );
    };

    return (
        <>
            {renderTableDropdown()}
            {renderTableColumnDropdown()}
        </>
    );

};

type PropTypes = {
    tableLabel?: string;
    tableColumnLabel?: string;
    onTableSelect: (id: string) => void;
    selectedTableId?: string;
    required?: boolean;
    disabled?: boolean;
    onTableColumnSelect: (id: string) => void;
    selectedTableColumnId?: string;
};
