import { Field, FieldTypes, FieldValidationType } from "@eazy2biz/common-util";
import {HTMLInputTypeAttribute} from "react";
import { MenuData } from "@eazy2biz-ui/common-components";
import { get } from "lodash";
import { FormFieldInput } from "@eazy2biz/common-package-ui";

export const getHTMLInputTypeFromFieldType = (fieldType: FieldTypes | undefined): HTMLInputTypeAttribute => {
    switch (fieldType) {
        case FieldTypes.NUMBER:
        case FieldTypes.PHONE_NUMBER:
            return 'number';
        case FieldTypes.DATE: return 'date';
        case FieldTypes.EMAIL: return 'email';
        default: return 'text';
    }
};

export const getInputResponseTypeFromFieldType = (fieldType: FieldTypes | undefined): HTMLInputTypeAttribute => {
    switch (fieldType) {
        case FieldTypes.NUMBER:
            return 'number';
        default: return 'text';
    }
};

export const getDropdownMenuForField = (field: Field): MenuData[] => {
    const dropdownValidation =
      field.validations.find((validation) => validation.fieldValidationType = FieldValidationType.VALUE_IN_ARRAY);

    return (dropdownValidation?.validationValue || [])
      .map(
        (value: string) => ({
            _id: value, name: value
        })
      );
};

export const getMultiSelectEnabledForField = (field: Field): boolean => {
    return get(field, "fieldConfigurations.otherConfigurations.multiSelect", false);
};

export const convertToFieldsPayload = (fields: FormFieldInput[]): any => {
    let fieldsPayload: any = {};

    fields.forEach((field: FormFieldInput) => {
        fieldsPayload = {
            ...fieldsPayload,
            [field.id] : field.value
        }
    });

    return fieldsPayload;
};
