import React from 'react';
import { EditInput, GenericFormBuilder, TableBuilderPaletteConfig, Toast } from "@eazy2biz-ui/common-components";
import { CustomerTableHeader, CustomerTableResponse } from '@eazy2biz/common-util';
import {cloneDeep} from "lodash";
import {
  getCustomerTableHeaderFromPaletteId,
  getDefaultTableBuilderState,
  getTableBuilderState
} from "../../helpers/CustomerTableBuilderHelper";
import {CustomerTableBuilderHeaderPlayground} from "./customerTableBuilderHeaderPlayground/CustomerTableBuilderHeaderPlayground";
import {TABLE_COLUMNS_BUILDER} from "../../../contents/DisplayContent";
import {TableHeaderConfigurationsComponent} from "./tableHeaderConfigurationsComponent/TableHeaderConfigurationsComponent";
import { validateAndCreateTableRequest, validateAndUpdateTableRequest } from "../../helpers/CustomerTableServiceHelper";

/**
 * Customer Table Columns Builder
 */
export class CustomerTableColumnsBuilder extends React.Component<PropTypes, CustomerTableBuilderStateType> {
  constructor(props: PropTypes) {
    super(props);
    this.state = props.editMode && props.customerTable? getTableBuilderState(props.customerTable) : getDefaultTableBuilderState();
  }

  /**
   * Header Selection/ Deselection
   * @param fieldId
   */
  handleHeaderSelect = (headerId?: string) => {
    return this.setState({
      formConfigurationsVisible: headerId !== undefined,
      selectedHeaderId: headerId
    });
  };

  /**
   * Adding header to the table.
   * @param id palette Item id.
   */
  addTableHeader = (id: string) => {
    const tableHeadersCopy = cloneDeep(this.state.tableHeaders);
    tableHeadersCopy.push(getCustomerTableHeaderFromPaletteId(id));
    this.setState({
      tableHeaders: tableHeadersCopy
    });
  };

  /**
   * Updates the field Configurations.
   * @param updatedField
   */
  updateTableHeader = (updatedHeader: CustomerTableHeader) => {
    const tableHeadersCopy = cloneDeep(this.state.tableHeaders);
    this.setState({
      tableHeaders: tableHeadersCopy.map((header) => {
        return (header._id === updatedHeader._id)? updatedHeader: header;
      })
    });
  };

  deleteTableHeader = (headerId: string) => {
    if (this.props.editMode) {
      const headerIds = this.props.customerTable?.tableHeaders.map(header => header._id)
      if (headerIds?.includes(headerId)) { 
        Toast.warn(TABLE_COLUMNS_BUILDER.PREVIOUS_ADDED_COLUMN);
        return;
      }
    }
    if (headerId === this.state.titleHeaderId) {
      Toast.errorString(TABLE_COLUMNS_BUILDER.TITLE_HEADER_DELETE);
      return;
    }
    const tableHeadersCopy = cloneDeep(this.state.tableHeaders);
    this.setState({
      tableHeaders: tableHeadersCopy.filter((header) => header._id !== headerId)
    });
  };

  /**
   * Handles Save Form
   */
  handleSaveForm = () => {
    const {title, tableHeaders, titleHeaderId} = this.state;
    Toast.load(TABLE_COLUMNS_BUILDER.CREATE_TABLE_PROGRESS);
    validateAndCreateTableRequest(title, tableHeaders, titleHeaderId).then(() => {
      this.props.onSuccess();
      Toast.success(TABLE_COLUMNS_BUILDER.CREATE_TABLE_SUCCESS);
    }).catch((e) => Toast.error(e, TABLE_COLUMNS_BUILDER.CREATE_TABLE_ERROR));
  };

  handleUpdateCustomerTable = () => {
    if (!this.props.customerTable) return;
    const { title } = this.state;
    Toast.load(TABLE_COLUMNS_BUILDER.UPDATE_TABLE_PROGRESS);
    try {
      if (this.state.title !== this.props.customerTable?.details.name && this.props.customerTable) {
        validateAndUpdateTableRequest(title, this.props.customerTable).then(() => {
          this.props.onSuccess();
          Toast.success(TABLE_COLUMNS_BUILDER.UPDATE_TABLE_SUCCESS);
        }).catch((e) => Toast.error(e, TABLE_COLUMNS_BUILDER.UPDATE_TABLE_ERROR))
      }
      // handle the patch table headers api
    } catch (error) {
      Toast.errorString(TABLE_COLUMNS_BUILDER.UPDATE_TABLE_ERROR);
    } finally {
      // handle refresh table cards
    }
  }

  updateTitleKey = (id: string) => {
    this.setState({
      titleHeaderId: id
    });
  };

  updateTableName = (name: string) => {
    this.setState({
      title: name
    });
  };

  renderTableNameField = () => {
    return (
      <EditInput
        value={this.state.title}
        size={'large'}
        onChange={this.updateTableName}
      />
    );
  };

  renderFieldConfigurations = () => {
    const { selectedHeaderId, tableHeaders } = this.state;
    const selectedHeader = tableHeaders.find((header) => header._id === selectedHeaderId);

    if (!selectedHeaderId || !selectedHeader) {
      return null;
    }

    return (
      <TableHeaderConfigurationsComponent
          tableHeader={cloneDeep(selectedHeader)}
          updateTableHeader={this.updateTableHeader}/>
    );
  };

  renderFormPlayground = () => {
    const {selectedHeaderId, tableHeaders, titleHeaderId} = this.state;
    return (
        <CustomerTableBuilderHeaderPlayground
            selectedHeaderId={selectedHeaderId}
            tableHeaders={tableHeaders}
            titleHeaderId={titleHeaderId}
            updateTitleHeaderId={this.updateTitleKey}
            onFieldSelect={this.handleHeaderSelect}
            onFieldDelete={this.deleteTableHeader} />
    );
  };

  render() {
    const { onToggleFormBuilder } = this.props;

    return (
        <GenericFormBuilder
            onToggleFormBuilder={onToggleFormBuilder}
            title={this.renderTableNameField()}
            onSaveForm={this.props.editMode ? this.handleUpdateCustomerTable : this.handleSaveForm}
            renderFormView={this.renderFormPlayground}
            paletteItems={TableBuilderPaletteConfig}
            formConfigurationsVisible={this.state.formConfigurationsVisible}
            renderLeftConfigurations={this.renderFieldConfigurations}
            onElementDrop={this.addTableHeader}
            onBodyClick={() => this.handleHeaderSelect()} />
    );
  }
}

type PropTypes = {
  onToggleFormBuilder: () => void;
  onSuccess: () => void;
  tableId?: string;
  editMode?: boolean;
  customerTable?: CustomerTableResponse;
};

export type CustomerTableBuilderStateType = {
  tableHeaders: CustomerTableHeader[];
  selectedHeaderId?: string;
  formConfigurationsVisible: boolean;
  title: string;
  titleHeaderId: string;
};
