import {assigneePath, dueDatePath, workflowIdPath} from '../Constants';
import {
  CompleteTodoRequest,
  FieldSearch,
  SearchTypes,
  TodoStatus,
  TodoTypes,
  UserTodoCreateRequest,
} from "@eazy2biz/common-util";
import {TodoFilterConfig} from "../../components/common/TodoTable/TodoFilterMenu/TodoFilterMenu";
import {FormFieldInput, TodoFilterTypes} from '@eazy2biz/common-package-ui';
import {StrictBuilder} from "builder-pattern";
import moment from 'moment';

export const getCreateTodoPayload = (
    title: string,
    description: string,
    assigneeUserGroupId: string[],
    assigneeUserId: string[],
    dueInDays: number,
    type: TodoTypes = TodoTypes.DEFAULT,
    params?: object,
) : UserTodoCreateRequest => {
  return StrictBuilder<UserTodoCreateRequest>()
      .title(title)
      .description(description)
      .assigneeUserGroupId(assigneeUserGroupId)
      .assigneeUserId(assigneeUserId)
      .dueInDays(moment.duration({days: dueInDays}).toISOString())
      .type(type)
      .params(params)
      .build();
};

export const getTodoFilterTypesForConfig = (filterConfig?: TodoFilterConfig): TodoFilterTypes[] => {
  const filterTypes = [];
  filterConfig?.pendingOnly && filterTypes.push(TodoFilterTypes.PENDING);
  return filterTypes;
};

export const getTodoFiltersFromConfig = (config?: TodoFilterConfig) :  FieldSearch[] => {
  const filters : FieldSearch[] = [];
  if (config) {
    if (config.dueDate) {
      filters.push({
        type: SearchTypes.VALUE_LESS_THAN_EQUAL_TO,
        field: dueDatePath,
        value: config.dueDate.toString()
      });
    }

    if (config.assigneeUserId) {
      filters.push({
        type: SearchTypes.VALUE_IN_ARRAY,
        field: assigneePath,
        value: config.assigneeUserId
      });
    }
  }

  return filters;
};


export const getWorkflowIdFilter = (workflowId?: string) : FieldSearch[] => {
  if (workflowId) {
    return [
      {
        type: SearchTypes.VALUE_EQUAL,
        field: workflowIdPath,
        value: workflowId
      }
    ];
  }

  return [];
};

export const getCompleteTodoRequest = (todoStatus : TodoStatus, fields: FormFieldInput[]) : CompleteTodoRequest => {
  return StrictBuilder<CompleteTodoRequest>()
      .status(todoStatus)
      .params(convertToFieldsPayload(fields))
      .build();
};

export type completeTodoData = {
  status: TodoStatus;
  data: FormFieldInput[];
};

const convertToFieldsPayload = (fields: FormFieldInput[]): object => {
  let fieldsPayload: object = {};

  fields.forEach((field: FormFieldInput) => {
    fieldsPayload = {
      ...fieldsPayload,
      [field.id]: field.value
    };
  });

  return fieldsPayload;
};
