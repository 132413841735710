import {
  TemplateCategory,
  TemplateCreateRequest,
  TemplateTypes
} from "@eazy2biz/common-util";
import { StrictBuilder } from "builder-pattern";
import { PdfTemplateConfigurationType } from "./PdfConfigurationHelper";
import { VariableField } from "@eazy2biz-ui/common-components";
import { getParametersWithSampleValue } from "../TemplateHelper";

export const getCreatePdfTemplatePayload =
  (templateConfig: PdfTemplateConfigurationType, variables: VariableField[]): TemplateCreateRequest => {
  return StrictBuilder<TemplateCreateRequest>()
    .type(TemplateTypes.USER)
    .name(templateConfig.name)
    .category(TemplateCategory.PDF)
    .templateDetails({
      name: {
        text: templateConfig.name,
        parameters: [],
      },
      content: {
        text: templateConfig.content,
        parameters: variables.map((variable) => ({
          name: variable.id,
          details: {
            example: variable.value,
          },
        }))
      },
    })
    .templateAdditionalInfo({
      pageFormat: 'A4',
      pageOrientation: 'portrait',
      paginationOffset: 0,
      customHeader: false,
      customFooter: false,
    })
    .parameters(getParametersWithSampleValue(variables))
    .build();
};
