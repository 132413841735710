import { ProColumns } from "@ant-design/pro-components";
import { DataSourceType } from "./entity/EditableTableData";
import { TableInputRow } from "@eazy2biz/common-package-ui";

export const defaultData = [
  {
    id: 624748504,
    title: 'title',
    readonly: 'readonly',
    decs: 'decs',
    state: 'open',
    created_at: '1590486176000',
    update_at: '1590486176000',
  },
  {
    id: 624691229,
    title: 'title',
    readonly: 'readonly',
    decs: 'decs',
    state: 'closed',
    created_at: '1590481162000',
    update_at: '1590481162000',
  },
];

export const sampleColumns: ProColumns<DataSourceType>[] = [
  {
    title: 'C 1',
    dataIndex: 'title',
    tooltip: 'abc',
    tip: '1',
    fieldProps: (form, { rowKey, rowIndex }) => {
      return {
        placeholder: 'C3'
      };
    },
    formItemProps: (form, { rowIndex }) => {
      return {
        initialValue: "abc",
        rules:
          rowIndex > 1 ? [{ required: true, message: 'yes' }] : [],
      };
    },
    editable: (text, record, index) => {
      return index !== 0;
    },
    width: '25%',
  },
  {
    title: 'C2',
    tip: 'tip',
    dataIndex: 'readonly',
    tooltip: 'C2',
    readonly: true,
    width: '15%',
  },
  {
    title: 'C3',
    key: 'state',
    dataIndex: 'state',
    valueType: 'select',
    fieldProps: (form, { rowKey, rowIndex }) => {
      return {
        placeholder: 'C3'
      };
    },
    valueEnum: {
      open: {
        text: 'Error',
        status: 'Error',
      },
      closed: {
        text: 'Success',
        status: 'Success',
      },
    },
  },
  {
    title: 'C4',
    dataIndex: 'decs',
    fieldProps: (form, { rowKey, rowIndex }) => {
      if (form.getFieldValue([rowKey || '', 'title']) === '不好玩') {
        return {
          disabled: true,
        };
      }
      if (rowIndex > 9) {
        return {
          disabled: true,
        };
      }
      return {};
    },
  },
  {
    title: 'C5',
    dataIndex: 'created_at',
    valueType: 'date',
  },
  {
    title: 'C6',
    valueType: 'option',
    width: 200,
    render: (text, record, _, action) => []
  }
];

export const sampleInputColumns = [
  {
    id: '1',
    name: 'C1',
    description: 'C1',
    type: 'text',
    placeholder: 'C1 placeholder',
    defaultValue: '100',
    width: '10%',
    editable: true,
    properties: {},
  },
  {
    id: '2',
    name: 'C2',
    description: 'C2',
    type: 'text',
    placeholder: 'C2 placeholder',
    defaultValue: '200',
    width: '10%',
    editable: true,
    properties: {},
  },
  {
    id: '3',
    name: 'C3',
    description: 'C3',
    type: 'text',
    placeholder: 'C3 placeholder',
    defaultValue: '100',
    width: '10%',
    editable: true,
    properties: {},
  },
];

export const sampleInputs: TableInputRow[] = [
  {
    id: '1',
    values: {
      '1': 'R1',
      '2': 'R2',
      '3': 'R3',
    }
  },
  {
    id: '2',
    values: {
      '1': 'A1',
      '2': 'A2',
      '3': 'A3',
    }
  }
];



