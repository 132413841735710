import {RestServices } from "@eazy2biz/common-package-ui";
import {
    addCommentPayload,
    getCommentsPayload
} from "./helpers/ActivityServiceHelper";
import {ActivityResponse, EntityType} from "@eazy2biz/common-util";

const ACTIVITY_SERVICE = () => RestServices.getInstance().getActivityService();

export const getAllComments = (entityId: string, entityClassId: string, entityType: EntityType) : Promise<ActivityResponse[]> => {
    return ACTIVITY_SERVICE().getAllActivities(getCommentsPayload(entityId, entityClassId, entityType));
};

export const addComment = (message: string, entityId: string, entityClassId: string, entityType: EntityType) : Promise<ActivityResponse> => {
    return ACTIVITY_SERVICE().createActivity(addCommentPayload(message, entityId, entityClassId, entityType));
}
