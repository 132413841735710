import {cloneDeep} from "lodash";
import {getField} from "../../formBuilderHelpers/FormFieldHelper";
import {Field, FieldTypes, StageTypes} from "@eazy2biz/common-util";
import {FormEntity} from "@eazy2biz/common-package-ui";
import {StageData} from "../../../entity/workflowBuilder/StageData";

/**
 * Get system generated form fields for the added stage.
 * @param form
 * @param stageType
 * @param stageId
 * @return FormEntity
 */
export const addFormForAddedStage = (form: FormEntity, stageType: StageTypes, stageId: string): FormEntity => {
    const updatedForm: FormEntity = cloneDeep(form);
    switch (stageType) {
        case StageTypes.APPROVAL_STAGE:
            updatedForm.formFields.push(getField(FieldTypes.APPROVAL, stageId));
    }
    return updatedForm;
}

/**
 * Updates the field name in case of stage name change.
 * @param form
 * @param updatedStage
 */
export const updateFieldForUpdatedStage = (form: FormEntity, updatedStage: StageData): FormEntity => {
    const updatedForm: FormEntity = cloneDeep(form);

    if (updatedStage.stageConfiguration.type === StageTypes.APPROVAL_STAGE) {
        const approvalField = updatedForm.formFields.find(field => field.type === FieldTypes.APPROVAL && field.ownerId === updatedStage.stageConfiguration._id);
        if (approvalField) {
            approvalField.name = updatedStage.stageConfiguration.details.name + " Approval";
        }
    }

    return updatedForm;
}

export const getFilteredFormFieldsForStages = (fields: Field[], stageIds: string[]): Field[] => {
    return fields.filter((field) => stageIds.includes(field.ownerId));
};
