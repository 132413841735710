import MyWorkflowIcon from '../../../assets/myWorkflowIcon.svg';
import styles from './WorkflowHomePage.module.css';
import WorkflowList from '../workflow/WorkflowList';
import { workflowContent } from '../../../contents/DisplayContent';
import { InfoWrapper } from "@eazy2biz-ui/common-components";
import { WORKFLOW_INFO } from "@eazy2biz-ui/common-package";
/**
 * This Component is used for displaying workflow cards on the homepage.
 * @constructor
 */
const WorkflowHomepageComponent  = (props: PropTypes) => {

    const handleOnCreateItemSuccess = () => {
        props.handleRefreshCallback(true);
    };

    return (
      <>
          <div className={styles.homepageHeader}>
              <div className={styles.homepageHeaderSection}>
                  <img src={MyWorkflowIcon}/>
                  <InfoWrapper infoContent={WORKFLOW_INFO}>
                    <span className={styles.homepageHeaderTitle}>{workflowContent.MY_WORKFLOWS_TILE}</span>
                  </InfoWrapper>
              </div>
              <div className={styles.homepageHeaderContent}>{"Click on any workflow template to get started.."}</div>
          </div>
          <div>
              <WorkflowList handleOnCreateItemSuccess={handleOnCreateItemSuccess} />
          </div>
      </>
    );
}

export default WorkflowHomepageComponent;

type PropTypes = {
    handleRefreshCallback: (value: boolean) => void;
}
