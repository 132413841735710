import {
    getCompleteTodoRequest,
    getWorkflowIdFilter,
    getTodoFiltersFromConfig, getCreateTodoPayload, getTodoFilterTypesForConfig,
} from "./helpers/UserTodoHelper";
import {TodoFilterConfig} from "../components/common/TodoTable/TodoFilterMenu/TodoFilterMenu";
import {FormFieldInput, RestServices} from "@eazy2biz/common-package-ui";
import {
    TodoStatus,
    TodoTypes,
    UserTodoExecutableActionResponse
} from "@eazy2biz/common-util";

const TODO_SERVICE = () => RestServices.getInstance().getUserTodoService();
/**
 * Gets all Todos for the user.
 */
export const getAllTodosForCurrentUser = () : Promise<UserTodoExecutableActionResponse[]> => {
    return TODO_SERVICE().getAllTodosForCurrentUser();
};

export const getAllFilteredTodosForCurrentUser = (workflowId?: string, filterConfig?: TodoFilterConfig) : Promise<UserTodoExecutableActionResponse[]> => {
    return TODO_SERVICE().filterTodos(
        getTodoFilterTypesForConfig(filterConfig),
        [
            ...getWorkflowIdFilter(workflowId),
            ...getTodoFiltersFromConfig(filterConfig)
        ]);
};

/**
 * Completes To do
 * @param userTodoId todoId
 * @param todoStatus
 * @param fields
 */
export const completeTodo = (userTodoId: string, todoStatus : TodoStatus, fields: FormFieldInput[]) : Promise<UserTodoExecutableActionResponse> => {
    return TODO_SERVICE().completeTodo(userTodoId, getCompleteTodoRequest(todoStatus, fields));
}

export const createTodo = (
    title: string,
    description: string,
    assigneeUserGroupId: string[],
    assigneeUserId: string[],
    dueInDays: number,
    type: TodoTypes = TodoTypes.DEFAULT,
    params?: object
) : Promise<UserTodoExecutableActionResponse> => {
    return TODO_SERVICE()
        .createUserTodo(
            getCreateTodoPayload(
                title,
                description,
                assigneeUserGroupId,
                assigneeUserId,
                dueInDays,
                type,
                params,
            )
        );
}
