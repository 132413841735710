import {
  EditableProTable, ProColumns
} from "@ant-design/pro-components";
import React, { useEffect, useState } from "react";
import { DataSourceType } from "./entity/EditableTableData";
import { sampleInputColumns } from "./SampleTableData";
import { InputProps as DefaultInputPropTypes } from "antd/lib/input/Input";
import { getProColumnsFromInputColumn } from "./helpers/TableInputColumnHelper";
import {
  getDataSourcesFromInputs,
  getNewDataSource,
  getTableRowInputsFromDataSources
} from "./helpers/TableInputDataHelper";
import { CrossCircledIcon, FileTextIcon, Pencil2Icon, PlusIcon, TrashIcon } from "@radix-ui/react-icons";
import styles from './TableInputField.module.css';
import classNames from "classnames";
import { ActionRenderConfig } from "@ant-design/pro-utils/es/useEditableArray";
import { EmptyDataPlaceholderGraphic } from "../EmptyDataPlaceholderGraphic/EmptyDataPlaceholderGraphic";
import { TableInputColumn, TableInputRow } from "@eazy2biz/common-package-ui";

/**
 * Customisable Table Input Field.
 * @param props
 * @constructor
 */
export const TableInputField =  (props: PropTypes) => {

  const readOnly = () =>  props.disabled || props.readOnly;
  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);
  // @ts-ignore
  const [values, setValues] = useState<readonly DataSourceType[]>(getDataSourcesFromInputs(props.defaultValue as TableInputRow[] || []));
  const [proColumns, setProColumns] = useState<ProColumns<DataSourceType>[]>([]);

  const editComponent = (id: string) => {
    return <Pencil2Icon className={classNames(styles.hoverButton, styles.hoverButtonBlue)} onClick={() => setEditableRowKeys([...editableKeys, id])} />
  };

  useEffect(() => {
    setProColumns(getProColumnsFromInputColumn(props.columns || sampleInputColumns, editComponent, readOnly()));
  }, [props.columns, props.disabled, props.readOnly]);

  const handleChange = (values: readonly DataSourceType[]) => {
    setValues(values);
    props.onChange && props.onChange({
      // @ts-ignore
      target: {
        // @ts-ignore
        value: getTableRowInputsFromDataSources(values)
      }
    });
  };

  return (
      <EditableProTable<DataSourceType>
        rowKey="id"
        className={styles.tableWrapper}
        maxLength={10}
        // @ts-ignore
        recordCreatorProps={!readOnly() && RecordCreatorProps}
        toolBarRender={() => []}
        columns={proColumns}
        // For Pagination
        // request={async () => ({
        //   data: defaultData,
        //   total: 3,
        //   success: true,
        // })}
        value={values}
        onChange={handleChange}
        tableAlertRender={() => false}
        locale={{
          emptyText: <EmptyDataPlaceholderGraphic size={'s'}/>
        }}
        tableAlertOptionRender={() => false}
        columnEmptyText={"Please add Columns"}
        editable={readOnly() ? undefined : {
          editableKeys,
          saveText: <FileTextIcon className={classNames(styles.hoverButton, styles.hoverButtonGreen)} />,
          cancelText: <CrossCircledIcon className={classNames(styles.hoverButton, styles.hoverButtonRed)} />,
          actionRender: ActionIconRenderConfig,
          onlyAddOneLineAlertMessage: "Save the previous line and then proceed.",
          onChange: setEditableRowKeys,
        }}
      />
  );
};

interface PropTypes extends DefaultInputPropTypes {
  columns: TableInputColumn[];
}

const RecordCreatorProps = {
    icon: <PlusIcon className={styles.addIcon} />,
    creatorButtonText: <span className={styles.addRecordButtonText}>Add Record</span>,
    position: 'bottom',
    record: getNewDataSource,
};

const ActionIconRenderConfig = (row: DataSourceType, config: ActionRenderConfig<DataSourceType>, defaultDoms: {
  save: React.ReactNode;
  delete: React.ReactNode;
  cancel: React.ReactNode;
}) => [defaultDoms.save, renderDeleteIcon(row, config), defaultDoms.cancel];

const renderDeleteIcon = (row: DataSourceType, config: ActionRenderConfig<DataSourceType>) => {
  return <TrashIcon className={classNames(styles.hoverButton, styles.hoverButtonRed)} onClick={() => config.onDelete && config.onDelete(row.id, row)} />;
};
