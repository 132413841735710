import {Elements, FlowElement} from "react-flow-renderer";
import {Edge, Node} from "react-flow-renderer/dist/types";
import {StageData} from "../../../entity/workflowBuilder/StageData";
import {EdgeData} from "../../../entity/workflowBuilder/EdgeData";
import {ADDED_ELEMENTS_RESPONSE, AddStageElements} from "./WorkflowBuilderChartHelper";
import {addFormForAddedStage} from "./FormHelper";
import {Action, Field, StageTypes} from "@eazy2biz/common-util";
import {findElementById, getFilteredElementsMap} from "./WorkflowBuilderFlowHelper";
import {FormEntity} from "@eazy2biz-ui/common-package";
import {cloneDeep} from "lodash";
import {ErrorTypes, GenericException} from "@eazy2biz/common-package-ui";

/**
 * Handles stage Addition
 * @param edgeId
 * @param stages
 * @param edges
 * @param stageType
 * @param form
 */
export const addStage = (
    edgeId: string,
    stages: Elements<StageData>,
    edges: Elements<EdgeData>,
    stageType: StageTypes,
    form: FormEntity
): STAGES_EDGES_FORM => {

    const edge: Edge<EdgeData> | undefined = edges.find(e => e.id === edgeId) as Edge<EdgeData>;

    if (!edge) {
        throw (new Error('Edge not found!'));
    }

    // Adding canvas elements
    const updatedElements: ADDED_ELEMENTS_RESPONSE = AddStageElements(edgeId, stages, edges, stageType);

    return {
        stages: updatedElements.stages,
        edges: updatedElements.edges,
        form: addFormForAddedStage(form, stageType, updatedElements.addedStageId)
    };
};


export const deleteStageV2 = (
    stageId: string,
    oldStages: Node<StageData>[],
    oldEdges: Edge<EdgeData>[],
    oldForm: FormEntity
): STAGES_EDGES_FORM => {
    const stageToDelete: FlowElement<StageData> = findElementById(oldStages, stageId);
    const stageToDeleteType: StageTypes | undefined = stageToDelete.data?.stageConfiguration.type;

    if (!stageToDeleteType || [StageTypes.ENTRY_STAGE, StageTypes.EXIT_STAGE].includes(stageToDeleteType)) {
        throwError('Cannot Delete Entry / Exit Stage');
    }

    let edgesCopy: Edge<EdgeData>[] = cloneDeep(oldEdges);
    let stagesCopy: Node<StageData>[] = cloneDeep(oldStages);
    const formCopy: FormEntity = cloneDeep(oldForm);

    let edgesFromCurrent: Edge<EdgeData>[] = edgesCopy.filter((edge) => edge.source === stageId);

    // Deleting the stage.
    const stageIdsToDelete = [stageId];

    // Removing the child edges.
    const edgeIdsToDelete = edgesFromCurrent.map(edge => edge.id);

    let nextStageId: string | undefined;

    if ([StageTypes.FORM_STAGE].includes(<StageTypes.FORM_STAGE>stageToDeleteType)) {
        // Case of form stage.
        // Setting the nextStage
        nextStageId = edgesFromCurrent[0].target;
        if (edgesFromCurrent.length > 1) {
            throwError();
        }

    } else {
        // Case of branch stage.
        const labelIds: (string)[] = edgesFromCurrent.map((edge) => edge.target);

        const nextEdgesToStages: Edge<EdgeData>[] = edgesCopy.filter((edge) => labelIds.includes(edge.source || ''));

        const nextStageIds:  (string | undefined)[] = nextEdgesToStages.map((edge) => edge.target);

        // Next level stages after branch stage.
        const nextStages: Elements<StageData> =
            stagesCopy.filter((stage) => nextStageIds.includes(stage.id));

        const nextExitStages: Map<string, FlowElement<StageData>> = getFilteredElementsMap(nextStages, [StageTypes.EXIT_STAGE]);

        if (nextStages.length < 1 || nextStages.length > 2) {
            throwError();
        } else if (nextStages.length === 2 && nextExitStages.size < 1) {
            throwError('Stage deletion failed, Clear one branch then try again.');
        }

        // Deleting labels
        stageIdsToDelete.push(...labelIds);

        // Deleting next edges from labels -> stages.
        edgeIdsToDelete.push(...nextEdgesToStages.map(edge => edge.id));

        // Case where both the branches point to same stage.
        if (nextStages.length === 1) {
            // Setting next Stage Id.
            nextStageId = nextStages[0].id;
        } else {
            // Finding the next Empty Branch of the two branches and deleting the branch with Exit Node and keeping the other.
            if (nextStages[0].data?.stageConfiguration.type === StageTypes.EXIT_STAGE) {
                // Setting next Stage Id to other.
                nextStageId = nextStages[1].id;

                // Deleting Exit Stage
                stageIdsToDelete.push(nextStages[0].id);
            } else if (nextStages[1].data?.stageConfiguration.type === StageTypes.EXIT_STAGE) {
                // Setting next Stage Id to other.
                nextStageId = nextStages[0].id;

                // Deleting Exit Stage
                stageIdsToDelete.push(nextStages[1].id);
            }
        }
    }

    if (!nextStageId) {
        throwError();
    }

    // Deleting Edges.
    edgesCopy = edgesCopy.filter((edge) => !edgeIdsToDelete.includes(edge.id));

    // Modifying parent edges to next stage.
    edgesCopy.forEach((edge) => {
        if (edge.target === stageId) {
            // @ts-ignore
            edge.target = nextStageId;
        }
    });

    stagesCopy = stagesCopy.filter(stage => !stageIdsToDelete.includes(stage.id));

    // delete form fields attached to this stage
    formCopy.formFields = formCopy.formFields.filter((formField: Field) => formField.ownerId !== stageId);
    stagesCopy = cleaningFormFieldAccessMapForDeletedStage(stagesCopy, stageId, oldForm.formFields);

    return {
        edges: edgesCopy,
        stages: stagesCopy,
        form: formCopy
    };
};

const cleaningFormFieldAccessMapForDeletedStage =
    (stages: Node<StageData>[], deletedStageId: string, formFields: Field[]): Node<StageData>[] => {

    const deletedFieldIds: string[] =
        formFields.filter((field) => field.ownerId === deletedStageId)
            .map(field => field._id);

    return cleaningFormFieldAccessMapForDeletedFields(stages, deletedFieldIds);
};

/**
 *  Removing field from accessMap and check for usage in other stages.
 * @param stages
 * @param deletedFieldIds
 */
export const cleaningFormFieldAccessMapForDeletedFields =
    (stages: Node<StageData>[], deletedFieldIds: string[]): Node<StageData>[] => {

    stages.forEach((stage) => {
        const actions: Action[] = [
            // @ts-ignore
            ...stage.data?.stageConfiguration.actions.stage_exit,
            // @ts-ignore
            ...stage.data?.stageConfiguration.actions.stage_entry
        ];

        const fieldAccessMap = stage.data?.stageConfiguration.fieldAccessMap;

        if (fieldAccessMap) {
            deletedFieldIds.forEach((fieldId) => {
                delete fieldAccessMap[fieldId];

                // Checking for placeholder usage for field.
                actions.forEach((action) => {
                    if (matchTextInObject(fieldId, action)){
                        throwError('Field is referenced in other actions. Please clear those then try again.');
                    }

                });
            });
        }
    });

    return stages;
};

const matchTextInObject = (text: string, value: any): boolean => {
    if (typeof value === "string") return value.includes(text);
    return Object.values(value).some(val => matchTextInObject(text, val));
};

const throwError = (message: string = 'Unknown Error while deleting stage') => {
    throw (new GenericException(ErrorTypes.VALIDATION_ERROR, message, message));
};

type STAGES_EDGES_FORM = {
    stages: Elements<StageData>,
    edges: Elements<EdgeData>,
    form: FormEntity
};
