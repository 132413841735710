export const NavBar = {
    PROFILE: "Profile",
    SWITCH_COMPANY: "Switch Company",
    SIGN_OUT: "Sign Out"
};

export const ERROR_PAGE_TEXT = {
    ERROR_404: "404",
    ERROR_BODY: "Sorry, we couldn't find this page.",
    ERROR_LINK: "Back To Home Page"
};

export const LEFT_SIDE_NAVIGATION = {
    DASHBOARD: 'Dashboard',
    USER_MANAGEMENT: 'User Management',
    CUSTOMER_TABLES: 'Tables',
    TEMPLATES_MANAGEMENT: 'Templates Management',
}
