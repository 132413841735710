import { Popover } from 'antd';
import { GearIcon } from '@radix-ui/react-icons'
import React, { useState } from 'react';
import styles from './TableSettings.module.css';
import { TABLE_SETTINGS } from '../../../../../contents/DisplayContent';
import CloseButton from '../../../CloseButton/CloseButton';
import GenericButton from '../../../GenericButton/GenericButton';
import { ColumnHeader } from '../../interfaces/ColumnHeader';
import { DraggableListComponent } from '../../../../components/DraggableListComponent/DraggableListComponent';
import { DragListItemConfig } from '../../../../components/DraggableListComponent/DragListItemConfig';

/**
 * Table settings
 * @param props
 * @constructor
 */
export const TableSettings = (props: PropTypes) => {
  const [settingsMenuVisible, setSettingsMenuVisible] = useState(false);
  const [listItems, setListItems] = useState<DragListItemConfig[]>(getItemsFromColumns(props.columns));

  const handleApply = () => {
    props.updatedColumns(listItems.filter(item => item.selected).map(item => item.item));
    setSettingsMenuVisible(false);
  };

  const handleClear = () => {
    setListItems(getItemsFromColumns(props.columns));
    props.updatedColumns(props.columns);
    setSettingsMenuVisible(false);
  };

  const getHeader = () => {
    return (
      <div className={styles.settingsHeaderWrapper}>
        <span>{TABLE_SETTINGS.SETTINGS}</span>
        <div className={styles.settingsHeaderWrapper}>
          { <span className={styles.clearButton} onClick={handleClear}>{TABLE_SETTINGS.CLEAR}</span> }
          <CloseButton size='small' onClose={() => setSettingsMenuVisible(false)}/>
        </div>
      </div>
    );
  };

  const getMenuContent = () => {
    return (
      <>
        <DraggableListComponent listItems={listItems} setListItems={setListItems} />
        <GenericButton buttonText={TABLE_SETTINGS.APPLY} onClick={handleApply} />
      </>
    );
  };

  return (
    <Popover
      content={getMenuContent()}
      title={getHeader()}
      trigger="click"
      placement={'left'}
      open={settingsMenuVisible}
      onOpenChange={setSettingsMenuVisible}>
      <GearIcon
        className={styles.gearIcon}
        width={20}
        height={20}
        onClick={() => setSettingsMenuVisible(true)} />
    </Popover>
  );
};

type PropTypes = {
  columns: ColumnHeader[];
  updatedColumns: (columns: ColumnHeader[]) => void;
};

const getItemsFromColumns = (columns: ColumnHeader[]): DragListItemConfig[] => {
  return columns.map((column) => ({
    id: column.Header,
    name: column.Header,
    selected: true,
    draggable: true,
    item: column,
  }));
};
