import React  from 'react'
import styles from './GenericTag.module.css';
import classNames from "classnames";
import { GenericTagTypes } from './GenericTagTypes';
import {Cross1Icon} from '@radix-ui/react-icons';

function GenericTag(props : PropType) {

    const {title, type = GenericTagTypes.ACTIVE} = props;

    const getTagStyle = () => {
        switch (type) {
            case GenericTagTypes.DARK  :  return styles.tagDark;
            case GenericTagTypes.ACTIVE  :  return styles.tagActive;
            case GenericTagTypes.WARN    :  return styles.tagWarn;
            case GenericTagTypes.ERROR   :  return styles.tagError;
            case GenericTagTypes.PENDING :  return styles.tagPending;
            case GenericTagTypes.COMPLETED  :  return styles.tagCompleted;
            case GenericTagTypes.MULTISELECT  :  return styles.tagMultiSelect;
        }
    };

    return (
        <div className={classNames(styles.tag, props.contentClassName, getTagStyle())}>
            {title}
            {props.onRemove && <Cross1Icon height={12}  className={styles.removeButton} onClick={props.onRemove}/>}
        </div>
    );
}

interface PropType {
    title: string | React.ReactNode;
    type?: GenericTagTypes;
    onRemove?: () => void;
    contentClassName?: string;
}

export default GenericTag
