import {
    AlertModalTypes,
    GenericButton,
    GenericButtonTypes
} from "@eazy2biz-ui/common-components";
import React, {useState} from "react";

import styles from './ReportEditButtons.module.css'
import {BaseAlertModal} from "@eazy2biz-ui/common-components";
import {reportContent} from "../../../contents/DisplayContent";
import {Pencil2Icon} from "@radix-ui/react-icons";
import {ReportChartUIGridConfig, ReportResponse, ReportUpdateAttributes} from "@eazy2biz/common-util";
import {patchReportById} from "../../services/ReportService";
import {StrictBuilder} from "builder-pattern";

export const ReportEditButtons = (
    props: {
        editMode : boolean,
        selectedReport: ReportResponse,
        handleEditReportInitiateClick : () => void,
        newReportChartUIGridConfigsFetcher : () => ReportChartUIGridConfig[]
        handlePostEditReportConfirmation : () => void,
        handleEditReportDiscardClick : () => void,
    }

): JSX.Element => {

    const {
        editMode,
        selectedReport,
        handleEditReportInitiateClick,
        newReportChartUIGridConfigsFetcher,
        handlePostEditReportConfirmation,
        handleEditReportDiscardClick
    } = props;

    const [showEditConfirmationAlertModal, setShowEditConfirmationAlertModal] = useState(false);

    const [isUpdateReportLoading, setIsUpdateReportLoading] = useState(false);

    const handleEditReportLayout = () : void => {
        setIsUpdateReportLoading(true);
        patchReportById(
            selectedReport._id,
            StrictBuilder<ReportUpdateAttributes>()
                .chartUIGridConfigs(newReportChartUIGridConfigsFetcher())
                .build()
        ).then(() => {
            setIsUpdateReportLoading(false);
            handlePostEditReportConfirmation();
        })
    }


    return (<>
        {
            editMode ? (
                <div className={styles.reportEditActionButtons}>
                    <GenericButton
                        isLoading={isUpdateReportLoading}
                        className={styles.reportEditActionButton}
                        type={GenericButtonTypes.SUCCESS}
                        buttonText={reportContent.UPDATE_REPORT}
                        onClick={() => setShowEditConfirmationAlertModal(true)}
                    />
                    {
                        !isUpdateReportLoading && (
                            <GenericButton
                                className={styles.reportEditActionButton}
                                type={GenericButtonTypes.REJECT}
                                buttonText={reportContent.DISCARD_REPORT_CHANGES}
                                onClick={handleEditReportDiscardClick}
                            />
                        )
                    }
                </div>
            ) : (
                <GenericButton
                    className={styles.reportEditButton}
                    type={GenericButtonTypes.SECONDARY}
                    CustomIcon={Pencil2Icon}
                    buttonText={reportContent.EDIT_REPORT}
                    onClick={handleEditReportInitiateClick}
                />
            )
        }

        {
            showEditConfirmationAlertModal && (
                <BaseAlertModal
                    showCancelButton={true}
                    onCancel={() => setShowEditConfirmationAlertModal(false)}
                    onConfirm={() => {
                        setShowEditConfirmationAlertModal(false)
                        handleEditReportLayout()
                    }}
                    type={AlertModalTypes.ALERT}
                    title={reportContent.UPDATE_REPORT_ALERT_MESSAGE}
                />
            )
        }

    </>)
}
