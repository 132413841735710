import { Collapse } from 'antd';
import {ActionDetailSelectorComponent} from "./ActionDetailSelectorComponent";
import {
    Action,
    ActionResult,
    BaseActionResultDetails,
    ConditionExpression,
    Field,
    Stage
} from "@eazy2biz/common-util";
import {ActionResultConfig, getActionConfigForType} from "./configs/ActionConfig";
import {
    updateActionResultInList,
    updateConditionForActionInList
} from "../../../../helpers/workflowBuilderHelpers/runtimeHelpers/actions/ActionHelper";
import styles from './ActionCollapsiblePanel.module.css';
import * as React from "react";
import {ChevronDownIcon, ChevronUpIcon, TrashIcon} from '@radix-ui/react-icons';
import {cloneDeep} from "lodash";

/**
 * Component for Actions Accordions.
 * @param props
 * @constructor
 */
export const ActionCollapsiblePanel = (props: PropTypes) => {

    const {selectedActionList} = props;

    const handleUpdateActionResult = (actionResult: ActionResult<BaseActionResultDetails>, actionIndex: number) => {
        props.updateActionList(updateActionResultInList(selectedActionList, actionIndex, actionResult));
    };

    const handleUpdateConditionForAction = (actionIndex: number, condition?: ConditionExpression) => {
        props.updateActionList(updateConditionForActionInList(selectedActionList, actionIndex, condition));
    };

    const removeAction = (e: any, actionIndex: number) => {
        e.stopPropagation();
        const selectedActionListClone = cloneDeep(selectedActionList);
        selectedActionListClone.splice(actionIndex, 1)
        props.updateActionList(selectedActionListClone);
    };

    const getAccordionIcon = (panelProps: any): React.ReactNode => {
        return panelProps.isActive ? <ChevronUpIcon/> : <ChevronDownIcon/>;
    };

    const renderActionAccordionHeader = (title: string, actionIndex: number) => {
        return (
            <div className={styles.actionCollapseHeader}>
                <span>{title}</span>
                <TrashIcon className={styles.actionDeleteIcon} onClick={(e)=> removeAction(e, actionIndex)}/>
            </div>
        );
    };

    const renderPanel = (action: Action, actionIndex: number) => {
        const actionResult = action.actionResults[0];
        const config: ActionResultConfig = getActionConfigForType(actionResult.type);
        return(
            <Collapse.Panel
                header={renderActionAccordionHeader(config.label, actionIndex)}
                key={actionIndex + '_' + selectedActionList.length}
                className={styles.actionConfigurationsPanel}>
                <ActionDetailSelectorComponent
                    fields={props.fields}
                    stages={props.stages}
                    condition={action.conditions}
                    actionResult={actionResult}
                    onConditionUpdate={(condition?: ConditionExpression) => handleUpdateConditionForAction(actionIndex, condition)}
                    onActionResultUpdate={
                        (actionResult: ActionResult<BaseActionResultDetails>) => handleUpdateActionResult(actionResult, actionIndex)
                    }/>
            </Collapse.Panel>
        );
    };

    return(
        <>
            <Collapse
                accordion
                bordered={false}
                className={styles.actionConfigurationsCollapse}
                expandIconPosition={'right'}
                expandIcon={getAccordionIcon}
            >
                {
                    selectedActionList.map((action: Action, index: number) => {
                        return renderPanel(action, index);
                    })
                }
            </Collapse>
        </>
    );
}

type PropTypes = {
    fields: Field[];
    stages: Stage[];
    selectedActionList: Array<Action>;
    updateActionList: (list: Action[]) => void;
};
