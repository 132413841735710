import ItemsTable from "../Items/ItemsTable";
import {WorkflowResponse} from "@eazy2biz/common-util";
import WorkflowCanvas from "../../canvas/WorkflowCanvas";
import styles from "./WorkflowItemsTabComponent.module.css";
import {useState} from "react";
import {ToggleButton} from "@eazy2biz-ui/common-components";
import { ItemFilterConfig } from "../Items/ItemFilterComponents/ItemFilterMenu";
import { cloneDeep } from "lodash";

/**
 * My Items Tab Component
 * @param props
 * @constructor
 */
const WorkflowItemsTabComponent = (props: PropTypes) => {
    const [canvasVisible, setCanvasVisible] = useState(false);
    const [filterConfig, setFilterConfig] = useState<ItemFilterConfig>(new ItemFilterConfig());
    const [selectedStageId, setSelectedStageId] = useState<string | undefined>();

    const toggleCanvas = () => {
        setCanvasVisible(!canvasVisible);
    };

    const handleStageClick = (stageId?: string) => {
        setSelectedStageId(stageId);
        const filter = cloneDeep(filterConfig);
        filter.currentStages = stageId? [stageId] : [];
        setFilterConfig(filter);
    };

    const renderCanvas = () => {
        return(
            <div className={styles.workflowItemsCanvasView}>
                <WorkflowCanvas
                    readOnly
                    minimapDisable
                    // @ts-ignore
                    stages={props.workflow.workflowBlueprint.stages}
                    // @ts-ignore
                    edges={props.workflow.workflowBlueprint.edges}
                    onElementClick={handleStageClick}
                    selectedNodeId={selectedStageId}
                    onAddElementClick={() => {
                    }}/>
            </div>
        );
    };

    return(
        <div>
            <ToggleButton
                value={canvasVisible}
                onToggle={toggleCanvas}
                wrapperClassName={styles.canvasViewToggle}
                label={"Toggle Workflow"}/>
            {canvasVisible && renderCanvas()}
            {<ItemsTable {...props} filterConfig={filterConfig} setFilterConfig={setFilterConfig} />}
        </div>
    );
};

type PropTypes = {
    workflow: WorkflowResponse;
    refreshTable: boolean;
    onRefreshCallback?: (value: boolean) => void;
};

export default WorkflowItemsTabComponent;
