import StartNode from "../nodes/StartNode";
import EndNode from "../nodes/EndNode";
import ReadOnlyLabel from "../labels/ReadyOnlyLabel";
import GenericStageNode from "../nodes/GenericStageNode";

const NodeTypes = {
    START_NODE: StartNode,
    END_NODE: EndNode,
    FORM_NODE: GenericStageNode,

    // Labels
    READ_ONLY_LABEL: ReadOnlyLabel
};

export default NodeTypes;