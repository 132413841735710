import React, {useEffect, useRef, useState} from "react";
import {
    ChartResponse,
    ChartsUIGridConfig,
    ReportEntityType,
    ReportResponse
} from "@eazy2biz/common-util";
import {ReportSelector} from "./report/ReportSelector";
import {getAllChartsForReport} from "../services/ChartsService";
import moment from "moment";
import {Button, Divider, Space, Tooltip} from "antd";
import {Moment} from "moment/moment";
import styles from './ReportComponent.module.css';
import {
    chartContent,
    REPORT_DURATION_NOT_SELECTED,
    REPORT_NOT_SELECTED,
    reportContent
} from "../../contents/DisplayContent";
import {
    EmptyDataPlaceholderGraphic,
    GenericDateRangePicker,
    getDefaultDateRangePresets,
    Spinner,
    ResizeableGridLayout,
    useForceUpdate
} from "@eazy2biz-ui/common-components";
import {ChartComponent} from "./ChartComponent";
import {DEFAULT_VERBOSE_DATE_FORMAT} from "@eazy2biz/common-package-ui";
import {DeleteOutlined} from "@ant-design/icons";
import {Layout} from "react-grid-layout";
import {
    convertGridLayoutToReportChartUIGridConfig,
    convertReportChartUIGridConfigToGridLayout
} from "../helpers/ReportHelper";
import {StrictBuilder} from "builder-pattern";
import {ReportEditButtons} from "./report/ReportEditButtons";
import {ReportDeleteButtons} from "./report/ReportDeleteButtons";
import { AddChart } from "./charts/chartAddition/AddChart";
import {getUpdatedUIGridConfigForCreatedChart} from "./charts/chartAddition/CreateChartHelper";
import { useHistory, useLocation } from "react-router-dom";
import { generateUrlVariables, UrlSearchParams } from "@eazy2biz-ui/common-package";
import { getReportById } from "../services/ReportService";

export const ReportComponent = (
    props: {
        entityType: ReportEntityType;
        entityId: string;
    }

): JSX.Element => {

    const {entityType, entityId} = props;

    const [refreshData, setRefreshData] = useState<boolean>(true);
    const [editMode, setEditMode] = useState<boolean>(false)
    const [selectedReport, setSelectedReport] = useState<ReportResponse | null>(null);
    const [chartsInReport, setChartsInReport] = useState<ChartResponse[] | null>(null);
    const [reportStartDate, setReportStartDate] = useState<Moment | null>(null);
    const [reportEndDate, setReportEndDate] = useState<Moment | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const reportUIGridLayout = useRef<Layout[] | null>(null);

    const forceUpdate = useForceUpdate();
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (searchParams.has(UrlSearchParams.REPORT_ID.toString()) && searchParams.get(UrlSearchParams.REPORT_ID.toString()) !== '') {
            getReportById(searchParams.get(UrlSearchParams.REPORT_ID.toString()) || '')
              .then(res => {
                  handleReportSelect(res);
              })
              .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if(selectedReport) {
            getAllChartsForReport(selectedReport._id)
                .then((chartResponses : ChartResponse[]) => {
                    setChartsInReport(chartResponses);
                });
        }
        else {
            setSelectedReport(null)
            setChartsInReport(null)
            setEditMode(false)
            reportUIGridLayout.current = null;
        }
    }, [selectedReport]);

    const resetAllStates = () => {
        setSelectedReport(null)
        setChartsInReport(null)
        setReportStartDate(null)
        setReportEndDate(null)
        setEditMode(false)
        reportUIGridLayout.current = null;
    }

    useEffect(() => {
        if(refreshData) {
            resetAllStates()
        }
    }, [refreshData])

    const enhanceGridLayoutUIConfig = (chartResponses : ChartResponse[], reportResponse : ReportResponse, gridUILayout: Layout[]) : Layout[] =>  {

        return gridUILayout.map(layout => {

            const chartResponse = chartResponses.find(chartRes => chartRes._id === layout.i);

            const chartMinConfig : { minWidth: number; minHeight: number } | undefined =
                chartResponse ? ChartsUIGridConfig.get(chartResponse.type) : undefined;
            const minWidth = chartMinConfig?.minWidth || 3;
            const minHeight = chartMinConfig?.minHeight || 5;

            return StrictBuilder<Layout>()
                .i(layout.i)
                .x(layout.x || 0)
                .y(layout.y || 0)
                .w(layout.w || minWidth)
                .h(layout.h || minHeight)
                .minW(minWidth)
                .minH(minHeight)
                .static(!editMode)
                .build()
        })

    };

    const handleReportSelect = (reportSelected: ReportResponse | null) => {
        setSelectedReport(reportSelected)
        setChartsInReport(null)
        setEditMode(false)
        setReportStartDate(reportSelected ? moment().subtract(moment.duration(reportSelected.defaultReportDuration)).startOf('day') : null)
        setReportEndDate(reportSelected ? moment().endOf('day') : null)
        reportUIGridLayout.current =
          reportSelected
            ? convertReportChartUIGridConfigToGridLayout(reportSelected.chartUIGridConfigs)
            : null;

        history.replace(history.location.pathname + reportSelected ? generateUrlVariables(
          { [UrlSearchParams.REPORT_ID.toString()]: reportSelected?._id }
        ): '');
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <Space direction="vertical" className={styles.reportContainer}>
            <Space className={styles.reportHeader}>
                {
                    selectedReport && (
                        <div className={styles.reportDetailsContainer}>
                            <div className={styles.reportName}>
                                {
                                    selectedReport?.details.name
                                }
                            </div>
                            {
                                selectedReport?.details.description && (
                                    <div className={styles.reportDescription}>
                                        {
                                            selectedReport?.details.description
                                        }
                                    </div>
                                )
                            }

                        </div>
                    )
                }


                <div className={styles.reportControls}>
                    {
                        selectedReport && reportUIGridLayout?.current && (
                            <div className={styles.reportButtons}>
                                <ReportEditButtons
                                    editMode={editMode}
                                    selectedReport={selectedReport}
                                    handleEditReportInitiateClick={() => {
                                        setEditMode(true)
                                    }}
                                    newReportChartUIGridConfigsFetcher={
                                        () =>
                                            convertGridLayoutToReportChartUIGridConfig(
                                                // @ts-ignore
                                                reportUIGridLayout.current
                                            )
                                    }
                                    handlePostEditReportConfirmation={() => {
                                        setEditMode(false)
                                    }}
                                    handleEditReportDiscardClick={() => {
                                        reportUIGridLayout.current =
                                            selectedReport
                                                ? convertReportChartUIGridConfigToGridLayout(selectedReport.chartUIGridConfigs)
                                                : null;
                                        setEditMode(false)
                                    }}
                                />

                                <ReportDeleteButtons
                                    selectedReport={selectedReport}
                                    handlePostReportDeleteSuccess={() => {
                                        resetAllStates()
                                        setRefreshData(true);
                                    }}
                                />
                            </div>
                        )
                    }
                    <div className={styles.reportSelector}>

                        <ReportSelector
                            entityType={entityType}
                            entityId={entityId}
                            selectedReport={selectedReport}
                            handleReportSelected={handleReportSelect}
                            refreshData={refreshData}
                            handleRefreshCallBack={(newRefreshValue) => setRefreshData(newRefreshValue) }
                        />

                        {
                            selectedReport && (
                                <div className={styles.reportDurationRangePicker}>
                                    <GenericDateRangePicker
                                        label={reportContent.SELECT_REPORT_DURATION}
                                        defaultStartDate={reportStartDate}
                                        defaultEndDate={reportEndDate}
                                        handleRangeChange={(startDate: Moment | null, endDate : Moment | null) => {
                                            setReportStartDate(startDate);
                                            setReportEndDate(endDate);
                                        }}
                                        rangePresets={getDefaultDateRangePresets()}
                                        placeholders={[reportContent.REPORT_START_DATE, reportContent.REPORT_END_DATE]}
                                        dateFormat={DEFAULT_VERBOSE_DATE_FORMAT}
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
            </Space>

            <Divider
                className={styles.reportHeaderBodyDivider}
            />

            {
                selectedReport && chartsInReport && reportUIGridLayout.current ? (<>

                    {
                        editMode && (
                            <AddChart
                                selectedReport={selectedReport}
                                handleOnChartSuccessfulCreate={
                                    (createdChart : ChartResponse) => {

                                        reportUIGridLayout.current =
                                            selectedReport
                                                ? convertReportChartUIGridConfigToGridLayout(
                                                    getUpdatedUIGridConfigForCreatedChart(createdChart, selectedReport)
                                                )
                                                : null;

                                        setChartsInReport([
                                            ...chartsInReport,
                                            createdChart
                                        ])

                                        forceUpdate()
                                    }
                                }
                                entityType={entityType}
                                entityId={entityId}
                            />
                        )
                    }

                    {
                        reportStartDate != null && reportEndDate != null ? (
                            <div
                                className={styles.reportResizableGridLayout}
                            >
                                <ResizeableGridLayout
                                    layoutFetcher={
                                        () =>
                                            enhanceGridLayoutUIConfig(
                                                chartsInReport,
                                                selectedReport,
                                                // @ts-ignore
                                                reportUIGridLayout.current
                                            )
                                    }
                                    handleLayoutChange={(newLayout : Layout[]) => reportUIGridLayout.current = newLayout}
                                >
                                    {
                                        chartsInReport
                                            .filter(
                                                chartInReport =>
                                                    reportUIGridLayout.current?.some(
                                                        layout => layout.i === chartInReport._id
                                                    )
                                            )
                                            .map(
                                                chartInReport =>
                                                    <div
                                                        key={chartInReport._id}
                                                    >
                                                        {
                                                            editMode && (
                                                                <Tooltip title={chartContent.DELETE_CHART}>
                                                                    <Button
                                                                        className={styles.deleteChartButton}
                                                                        type="default"
                                                                        danger
                                                                        shape="circle"
                                                                        icon={<DeleteOutlined style={{ color: '#ff4d4f' }}  />}
                                                                        onClick={() => {
                                                                            reportUIGridLayout.current =
                                                                                reportUIGridLayout.current?.filter(
                                                                                    layout => layout.i !== chartInReport._id
                                                                                ) || null;
                                                                            forceUpdate()
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            )
                                                        }

                                                        <ChartComponent
                                                            reportResponse={selectedReport}
                                                            chartResponse={chartInReport}
                                                            reportStartDate={reportStartDate}
                                                            reportEndDate={reportEndDate}
                                                            editMode={editMode}
                                                        />
                                                    </div>
                                            )
                                    }
                                </ResizeableGridLayout>
                            </div>
                        ) : (
                            <div className={styles.emptyTablePlaceholderContainer}>
                                <EmptyDataPlaceholderGraphic
                                    text={REPORT_DURATION_NOT_SELECTED}
                                />
                            </div>
                        )
                    }
                </>) : (<>
                    <div className={styles.emptyTablePlaceholderContainer}>
                        <EmptyDataPlaceholderGraphic
                            text={REPORT_NOT_SELECTED}
                        />
                    </div>
                </>)

            }
        </Space>
    );
};

