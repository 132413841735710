import { ActionResultTypes } from "@eazy2biz/common-util";
import { DropdownMenuItem } from "@eazy2biz-ui/common-components";
import { resolveFeatureFlag } from "../../../../../../../../../shared/common-package";

export interface ActionResultConfig extends DropdownMenuItem{
    data: {
        type: ActionResultTypes;
    }
};

export const getActionConfigForType = (type: ActionResultTypes): ActionResultConfig => {
    switch (type) {
        case ActionResultTypes.EMAIL:
            return {
                id: 2,
                data : {
                    type: ActionResultTypes.EMAIL
                },
                label: "Send Email"
            };
        case ActionResultTypes.WHATSAPP_MESSAGE:
            return {
                id: 3,
                data : {
                    type: ActionResultTypes.WHATSAPP_MESSAGE
                },
                label: "Send Whatsapp Message"
            };
        case ActionResultTypes.PUSH_NOTIFICATION:
            return {
                id: 4,
                data : {
                    type: ActionResultTypes.PUSH_NOTIFICATION
                },
                label: "Mobile Notification"
            };
        case ActionResultTypes.DATA_MANIPULATE:
            return {
                id: 1,
                data : {
                    type: ActionResultTypes.DATA_MANIPULATE
                },
                label: "Data Manipulate"
            };
        case ActionResultTypes.MESSAGE:
            return {
                id: 5,
                data : {
                    type: ActionResultTypes.MESSAGE
                },
                label: "Send Text Message"
            };
        case ActionResultTypes.SAVE_TABLE_RECORD:
            return {
                id: 6,
                data : {
                    type: ActionResultTypes.SAVE_TABLE_RECORD
                },
                label: "Insert Record in table"
            };
        case ActionResultTypes.TRIGGER_WORKFLOW:
            return {
                id: 7,
                data : {
                    type: ActionResultTypes.TRIGGER_WORKFLOW
                },
                label: "Create item in workflow"
            };
        default: return {
            id: 2,
                data : {
                type: ActionResultTypes.EMAIL
            },
            label: "Send Email"
        };
    }
};

export const EntryActionMenuList: ActionResultConfig[] = [
    getActionConfigForType(ActionResultTypes.EMAIL),
    getActionConfigForType(ActionResultTypes.PUSH_NOTIFICATION),
    ...(resolveFeatureFlag('WHATSAPP_ACTION')? [getActionConfigForType(ActionResultTypes.WHATSAPP_MESSAGE)]: []),
    ...(resolveFeatureFlag('MESSAGE_ACTION')? [getActionConfigForType(ActionResultTypes.MESSAGE)]: []),
    getActionConfigForType(ActionResultTypes.TRIGGER_WORKFLOW),
    getActionConfigForType(ActionResultTypes.SAVE_TABLE_RECORD),
];

export const ExitActionMenuList: ActionResultConfig[] = [
    ...EntryActionMenuList
];

export const SLABreachActionMenuList: ActionResultConfig[] = [
    ...EntryActionMenuList
];
