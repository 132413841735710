import {Button, Image} from "antd";
import {SignInWithGoogleText} from "../../../contents/DisplayContent";
import GoogleIconSVG from "./GoogleIcon.svg";
import styles from "./SignInWithGoogle.module.css";

const SignInWithGoogle = () => {
    return (
        <Button icon={<GoogleIcon />} className={styles.googleSignInButton}>
            {SignInWithGoogleText}
        </Button>
    );
}

const GoogleIcon = () => {
    return (
        <Image className={styles.googleIcon} src={GoogleIconSVG}/>
    );
}

export default SignInWithGoogle;