import { FeatureFlag } from "@eazy2biz/common-package-ui";
import flagsMap from "./config/Flags";

/**
 * Resolves Feature Flag.
 * @param flagName
 * @param defaultVal
 */
export const resolveFeatureFlag = (flagName: string, defaultVal: boolean = false) => {
    // @ts-ignore
    const ff: FeatureFlag | undefined = flagsMap[flagName];
    return (!ff)? defaultVal : ff.active;
}
