import {CloseButton, ModalTitle} from "../../../index";
import React, {ReactElement} from "react";
import { Modal } from "react-bootstrap";
import styles from "./GenericModal.module.css";
import classNames from "classnames";

/**
 * Generic Modal Component
 * @param props
 * @constructor
 */
export const GenericModal = (props: PropTypes) => {
    const contentClassNames =
        classNames(
            styles.modalContent, {
                [styles.modalContentScroll]: !props.contentScrollDisable,
            });

    return (
        <Modal
          className={props.modalClassName}
          show={props.show}
          onHide={props.onClose}
          contentClassName={classNames(styles.genericModalContentWrapper, props.contentWrapperClassName)}
          backdropClassName={props.backdropClassName}
          dialogClassName={classNames(styles.genericModal, props.dialogClassName)}>
            <Modal.Header style={{ display: 'inline' }} className={classNames(styles.modalHeader, {[styles.noHeader]: props.hideHeader})}>
                <div className={styles.modalTitle}>
                    <div className={styles.leftHeader}>
                        <ModalTitle title={props.title || ''} />
                        {props.leftHeader && props.leftHeader()}
                    </div>
                    <div className={styles.rightHeader}>
                        {props.rightHeader && props.rightHeader()}
                        <CloseButton onClose={props.onClose} />
                    </div>
                </div>
                {props.subHeader && <div>{props.subHeader()}</div>}
            </Modal.Header>
            <Modal.Body className={styles.modalBody}>
                <div className={contentClassNames}>
                    {props.bodyContent && props.bodyContent()}
                </div>
            </Modal.Body>
            {   props.footerContent &&
                <Modal.Footer className={styles.footerCtn}>
                    {props.footerContent()}
                </Modal.Footer>
            }
        </Modal>
    );
};

type PropTypes = {
    show: boolean;
    hideHeader?: boolean;
    bodyContent?: () => ReactElement<any, any> | null | string;
    footerContent?: () => ReactElement<any, any> | null | string;
    title?: string;
    subHeader?: () => ReactElement<any, any> | null | string;
    leftHeader?: () => ReactElement<any, any> | null | string;
    rightHeader?: () => ReactElement<any, any> | null | string;
    onClose: () => void;
    dialogClassName?: string;
    contentWrapperClassName?: string;
    backdropClassName?: string;
    modalClassName?: string;
    contentScrollDisable?: boolean;
};
