import React, { ChangeEventHandler, useEffect, useState } from "react";
import { UploadIcon, FileIcon, TrashIcon } from '@radix-ui/react-icons';
import { Upload } from 'antd';
import {ItemRender, UploadFile} from "antd/lib/upload/interface";
import {UploadRequestOption as RcCustomRequestOptions} from "rc-upload/lib/interface";
import {clone} from "lodash";
import Toast from "../Toast/Toast";
import {UPLOAD_FILE_STRINGS} from "../../../contents/DisplayContent";
import { String } from 'typescript-string-operations';
import classNames from "classnames";
import styles from './UploadField.module.css';
import {
    deleteAttachmentById,
    downloadAttachment, getFilteredAttachmentsByIds,
    uploadAttachment
} from "../../services/AttachmentService";
import {RcFile} from "antd/es/upload";
import {AttachmentResponse} from "@eazy2biz/common-util";
import {saveAs} from 'file-saver';

/**
 * Generic Upload Field.
 * @param props
 * @constructor
 */
export const UploadField = (props: PropTypes) => {

    const {
        maxCount = 10,
        onChange,
        disabled,
        defaultValue = [],
        readOnly,
        className
    } = props;


    const [fileList, setFilesList] = useState<AttachmentResponse[]>([]);

    useEffect(() => {
        defaultValue.length && getFilteredAttachmentsByIds(defaultValue)
          .then((response) => setFilesList(response))
          .catch((e) => Toast.error(e, UPLOAD_FILE_STRINGS.ATTACHMENT_FETCH_ERROR))
    }, []);

    const convertFieldValuesToUploadFiles = (values: AttachmentResponse[]): Array<UploadFile> => {
        return values.map((attachment: AttachmentResponse) => ({
            uid: attachment._id.toString(),
            status: 'done',
            name: attachment.details.name,
        }));
    };

    const handleFilesChanged = (changedFileList: AttachmentResponse[]) => {
        setFilesList(changedFileList);
        onChange && onChange({
            target: {
                // @ts-ignore
                value: changedFileList.map((attachment) => attachment._id),
            }
        });
    };

    const handleFileUpload = (options: RcCustomRequestOptions) => {
        if (readOnly) {
            return;
        }

        if (fileList.length === maxCount) {
            Toast.errorString(String.Format(UPLOAD_FILE_STRINGS.MAX_UPLOAD_COUNT_ERROR, maxCount));
            return;
        }

        try {
            if (!props.skipUpload) {
                uploadAttachment(options.file as RcFile).then((response) => {
                    const fileListCopy = clone(fileList);
                    fileListCopy.push(response);
                    handleFilesChanged(fileListCopy);
                }).catch((e) => Toast.error(e, UPLOAD_FILE_STRINGS.DOCUMENT_UPLOAD_ERROR));
            } else {
                props.onChange && props.onChange({
                    target: {
                        // @ts-ignore
                        value: options.file,
                    }
                });
            }
        } catch (e: any) {
            Toast.error(e, UPLOAD_FILE_STRINGS.DOCUMENT_UPLOAD_ERROR);
        }

    };

    const handleOnFileRemove = (file: UploadFile) => {
        if (!readOnly) {
            deleteAttachmentById(file.uid).then(() => {
                const fileListCopy = clone(fileList).filter((attachment) => file.uid !== attachment._id);
                handleFilesChanged(fileListCopy);
            }).catch(() => Toast.errorString(UPLOAD_FILE_STRINGS.DOCUMENT_DELETE_ERROR));
        }

    };

    const handleDownload = (file: UploadFile) => {
        Toast.info(UPLOAD_FILE_STRINGS.DOWNLOADING_FILE);
        downloadAttachment(file.uid).then((response) => {
            saveAs(response, file.name);
        }).catch((e) => {
            Toast.error(e, UPLOAD_FILE_STRINGS.DOCUMENT_DOWNLOAD_ERROR);
        })
    };

    const renderFileItem: ItemRender = (
        originNode: React.ReactElement,
        file: UploadFile,
        fileList: Array<UploadFile>,
        actions: {
            download: () => void;
            preview: () => void;
            remove: () => void;
        }) => {
        return (
            <div key={file.uid} className={styles.fileItemWrapper}>
                <FileIcon color={'grey'} onClick={actions.preview} className={styles.icon}/>
                <span className={styles.fileItemTitle}>{file.name}</span>
                {!readOnly && <TrashIcon color={'grey'} onClick={actions.remove} className={styles.icon}/>}
            </div>
        );
    }

    return (
      <>
        {props.label && <p className={styles.label}>{props.label}</p>}
        <Upload.Dragger
            className={classNames(className, styles.uploadFieldWrapper)}
            fileList={convertFieldValuesToUploadFiles(fileList)}
            maxCount={maxCount}
            customRequest={handleFileUpload}
            onRemove={handleOnFileRemove}
            onPreview={handleDownload}
            disabled={disabled || readOnly}
            itemRender={renderFileItem}
            multiple>
            <div className={classNames(styles.uploadAreaWrapper)}>
                <UploadIcon className={styles.uploadIcon} color={'grey'}/>
                <span className={styles.uploadText}>{UPLOAD_FILE_STRINGS.UPLOAD_FILE_TEXT}</span>
            </div>
        </Upload.Dragger>
      </>
    );
};

type PropTypes = {
    label?: string;
    maxCount?: number;
    onChange?: ChangeEventHandler;
    defaultValue?: string[];
    disabled?: boolean;
    readOnly?: boolean;
    className?: string;
    skipUpload?: boolean;
};
