import {PLACEHOLDER_OPTIONS} from "../../../../contents/DisplayContent";
import styles from "./DynamicPlaceholder.module.css";
import { ActivityLogIcon, FileTextIcon, GridIcon, ListBulletIcon, PersonIcon } from "@radix-ui/react-icons";
import {useState} from "react";
import {FieldPlaceholder} from "./FieldPlaceholder/FieldPlaceholder";
import {UserPlaceholder} from "./UserPlaceholder/UserPlaceholder";
import {PlaceholderOption} from '../entity/PlaceholderOption';
import CloseButton from "../../CloseButton/CloseButton";
import {DynamicPlaceholderConfig} from "../config/DynamicPlaceholderConfig";
import {UserPlaceholderEntity} from "../entity/UserPlaceholderEntity";
import {PlaceholderSearchComponent} from "../components/placeholderSearchComponent/PlaceholderSearchComponent";
import {PlaceholderOptionComponent} from "../components/placeholderOptionComponent/PlaceholderOptionComponent";
import {FieldPlaceholder as Placeholder, FieldPlaceholderType} from "@eazy2biz/common-util";
import { TablePlaceholder } from "./TablePlaceholder/TablePlaceholder";
import { ItemSystemPlaceholder } from "./ItemSystemPlaceholder/ItemSystemPlaceholder";
import { SystemPlaceholder } from "./SystemPlaceholder/SystemPlaceholder";
import { ItemStageSystemPlaceholder } from "./ItemStageSystemPlaceholder/ItemStageSystemPlaceholder";

export const DynamicPlaceholder = (props: DynamicPlaceholderProps): JSX.Element | null => {
    const { callback, fieldPlaceHolderList, userPlaceholderList } = props;
    const [selectedPlaceholderType, setSelectedPlaceholderType] = useState<FieldPlaceholderType | null>(null);

    const resetPlaceholder = () => {
        setSelectedPlaceholderType(null);
    };

    const renderCardFormList = () => {
        return <FieldPlaceholder config={props.config} goBack={resetPlaceholder} callback={callback} options={fieldPlaceHolderList} />;
    };

    const renderUserList = () => {
        return <UserPlaceholder usersList={userPlaceholderList} goBack={resetPlaceholder} callback={callback} config={props.config}/>;
    };

    const renderDatabaseList = () => {
        return <TablePlaceholder config={props.config} goBack={resetPlaceholder} callback={callback} />;
    };

    const renderItemSystemList = () => {
        return <ItemSystemPlaceholder config={props.config} goBack={resetPlaceholder} callback={callback} />;
    };

    const renderSystemList = () => {
        return <SystemPlaceholder config={props.config} goBack={resetPlaceholder} callback={callback} />;
    };

    const renderItemStageSystemList = () => {
        return <ItemStageSystemPlaceholder config={props.config} goBack={resetPlaceholder} callback={callback} usersList={props.userPlaceholderList} />;
    };

    const renderFieldPlaceholder = () => {
        if (selectedPlaceholderType === null) {
            return null;
        }

        switch (selectedPlaceholderType) {
            case FieldPlaceholderType.CARD_FORM: return renderCardFormList();
            case FieldPlaceholderType.USER: return renderUserList();
            case FieldPlaceholderType.DATABASE: return renderDatabaseList();
            case FieldPlaceholderType.CARD_SYSTEM: return renderItemSystemList();
            case FieldPlaceholderType.CARD_STAGE_SYSTEM: return renderItemStageSystemList();
            case FieldPlaceholderType.SYSTEM: return renderSystemList();
            default: return null;
        }
    };

    const renderPlaceholderTypes = () => {
        if (selectedPlaceholderType) {
            return null;
        }

        return (
            <>
                <div className={styles.placeholderHeader}>
                    <PlaceholderSearchComponent />
                    <div className={styles.placeholderCloseButton}>
                        <CloseButton onClose={props.onClose}/>
                    </div>
                </div>
                <div>
                    { props.config.usersEnabled &&
                        <PlaceholderOptionComponent
                            optionIcon={<PersonIcon />}
                            onClick={() => setSelectedPlaceholderType(FieldPlaceholderType.USER)}
                            optionName={PLACEHOLDER_OPTIONS.USERS} />
                    }

                    { props.config.formFieldsEnabled &&
                        <PlaceholderOptionComponent
                            optionIcon={<FileTextIcon />}
                            onClick={() => setSelectedPlaceholderType(FieldPlaceholderType.CARD_FORM)}
                            optionName={PLACEHOLDER_OPTIONS.FORM} />
                    }

                    { props.config.itemSystemFieldsEnabled &&
                        <PlaceholderOptionComponent
                            optionIcon={<ListBulletIcon />}
                            onClick={() => setSelectedPlaceholderType(FieldPlaceholderType.CARD_SYSTEM)}
                            optionName={PLACEHOLDER_OPTIONS.CARD_SYSTEM} />
                    }

                    { props.config.itemStageSystemFieldsEnabled && props.config.stages.length > 0 &&
                        <PlaceholderOptionComponent
                            optionIcon={<ActivityLogIcon />}
                            onClick={() => setSelectedPlaceholderType(FieldPlaceholderType.CARD_STAGE_SYSTEM)}
                            optionName={PLACEHOLDER_OPTIONS.CARD_STAGE_SYSTEM} />
                    }

                    { props.config.databaseFieldsEnabled &&
                        <PlaceholderOptionComponent
                            optionIcon={<GridIcon />}
                            onClick={() => setSelectedPlaceholderType(FieldPlaceholderType.DATABASE)}
                            optionName={PLACEHOLDER_OPTIONS.DATABASE} />
                    }

                    { props.config.systemFieldsEnabled &&
                        <PlaceholderOptionComponent
                            optionIcon={<ActivityLogIcon />}
                            onClick={() => setSelectedPlaceholderType(FieldPlaceholderType.SYSTEM)}
                            optionName={PLACEHOLDER_OPTIONS.SYSTEM} />
                    }

                </div>
            </>
        );
    };

    return (
        <div className={styles.placeholderContainer}>
            {renderPlaceholderTypes()}
            {renderFieldPlaceholder()}
        </div>
    );
};

type DynamicPlaceholderProps = {
    userPlaceholderList: UserPlaceholderEntity[];
    fieldPlaceHolderList: PlaceholderOption[];
    config: DynamicPlaceholderConfig;
    callback: (placeholderString: Placeholder) => void;
    onClose: () => void;
}
