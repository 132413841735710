import {Handle, Position} from 'react-flow-renderer';
import {Pencil2Icon} from "@radix-ui/react-icons";
import {StageData} from "../../../entity/workflowBuilder/StageData";
import styles from './FormNode.module.css';
import classNames from "classnames";
import {StageTypes} from "@eazy2biz/common-util";
import {NodeProps} from "./GenericStageNode";

const FormNode = (props: NodeProps) => {
    const nodeData = props.data as StageData;

    const handleNodeClick = () => {
        nodeData.props.onClick &&
        nodeData.props.onClick(nodeData.stageConfiguration._id);
    };

    const handleSecondaryClick = () => {
        nodeData.props.onSecondaryClick && nodeData.props.onSecondaryClick(nodeData.stageConfiguration._id);
    };

    const nodeWrapperClasses = classNames(
        styles.canvasFormNode,
        {
            [styles.canvasFormNodeSelected_Highlighted]: nodeData.props.isHighlighted || nodeData.props.isSelected,
            [styles.canvasApprovalNode]: nodeData.stageConfiguration.type === StageTypes.APPROVAL_STAGE,
            [styles.canvasConditionalNode]: nodeData.stageConfiguration.type === StageTypes.CONDITIONAL_STAGE
        }
    );

    const nodeDividerClasses = classNames(
        styles.canvasFormNodeDivider,
        {
            [styles.canvasNodeHighlighted]: nodeData.props.isHighlighted,
            [styles.canvasNodeSelected]: nodeData.props.isSelected
        }
    );

    const innerWrapperClasses = classNames(
        styles.innerNode,
        {
            [styles.canvasNodeHighlighted]: nodeData.props.isHighlighted,
            [styles.canvasNodeSelected]: nodeData.props.isSelected,
            [styles.canvasApprovalNode]: nodeData.stageConfiguration.type === StageTypes.APPROVAL_STAGE,
            [styles.canvasConditionalNode]: nodeData.stageConfiguration.type === StageTypes.CONDITIONAL_STAGE
        }
    );

    return (
        <div className={nodeWrapperClasses}>
            <Handle
                type="target"
                position={Position.Top}
                id="a"
                style={{ background: '#555' }}
                isConnectable={true}
            />
            <div className={innerWrapperClasses}>
                <div onClick={handleNodeClick} className={styles.canvasFormNodeLabel}>
                    {nodeData.details.label}
                </div>
                {
                    nodeData.stageConfiguration.type !== StageTypes.APPROVAL_STAGE &&
                    <>
                        <div className={nodeDividerClasses}/>
                        <div
                            className={styles.canvasFormNodeEditIcon}
                            onClick={handleSecondaryClick}>
                            <Pencil2Icon/>
                        </div>
                    </>
                }
            </div>
            <Handle
                type="source"
                position={Position.Bottom}
                id="a"
                style={{ background: '#555' }}
                isConnectable={true}
            />
        </div>
    );
};

export default FormNode;