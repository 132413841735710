import {FieldPlaceholderType} from "@eazy2biz/common-util";
import {PLACEHOLDER_OPTIONS} from "../../../../../contents/DisplayContent";
import {PersonIcon} from "@radix-ui/react-icons";
import {PlaceholderOption} from "../../entity/PlaceholderOption";
import {UserPlaceholderEntity} from "../../entity/UserPlaceholderEntity";
import {transformUsersListToOptions, transformUserToOptionList} from "../../helpers/UserPlaceholderHelper";
import {BasePlaceholderMenu, BaseProps, BaseState} from "../BasePlaceholderMenu/BasePlaceholderMenu";

export class UserPlaceholder extends BasePlaceholderMenu<IUserPlaceholderProps, BaseState> {

    getIcon(): JSX.Element {
        return <PersonIcon />;
    }

    getPlaceholderHeaderString(): string {
        return PLACEHOLDER_OPTIONS.USERS;
    }

    getPlaceholderList(): PlaceholderOption[] {
        return transformUsersListToOptions(this.props.usersList);
    }

    transformEntityToOption(key: string): PlaceholderOption[] {
        return transformUserToOptionList(this.props.usersList, this.props.config.userProperties, key);
    }

    getPlaceholderType(): FieldPlaceholderType {
        return FieldPlaceholderType.USER;
    }

}

interface IUserPlaceholderProps extends BaseProps{
    usersList: UserPlaceholderEntity[];
}
