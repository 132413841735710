import * as React from 'react';
import {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import {CREATE_NEW_TEMPLATE, TEMPLATE_DASHBOARD_HEADER} from '../../contents/DisplayContent';
import styles from './TemplateManagementDashboard.module.css';
import {BackNavigationLink, GenericButton, GenericHeader} from "@eazy2biz-ui/common-components";
import {Tabs} from 'antd';
import {TemplatesList as WhatsappTemplatesList} from './whatsappTemplates/templatesList/WhatsappTemplatesList';
import {TemplatesList as PDFTemplatesList} from './pdfTemplates/templateList/PDFTemplatesList';
import { resolveFeatureFlag, RoutesEnum } from "@eazy2biz-ui/common-package";
import { useHistory, useParams } from "react-router-dom";
import {UserActions, UserAuthorizationService} from "@eazy2biz/common-package-ui";
import {SecurityGroupFeatures} from "@eazy2biz/common-util";

enum TemplateTabKeys {
  WHATSAPP_TEMPLATES = 'whatsapp',
  PDF_TEMPLATES = 'pdf'
}

export const TemplateManagementDashboard = (): JSX.Element => {
  // @ts-ignore
  const {tab: defaultTab } = useParams();

  const { TabPane } = Tabs;
  const history = useHistory();
  const [activeTabKey, setActiveTabKey] = React.useState<string>(defaultTab || TemplateTabKeys.WHATSAPP_TEMPLATES.toString());
  const [isCreateTemplateEnabled, setCreateTemplateEnabled] = useState(false);

  useEffect(() => {
    // TODO: Fix this to template actions.
    UserAuthorizationService.getInstance().isActionAllowed(UserActions.CREATE, SecurityGroupFeatures.USERS)
        .then(enabled => setCreateTemplateEnabled(enabled));

  }, []);

  const createNewTemplate = () => {
    switch (activeTabKey) {
      case TemplateTabKeys.WHATSAPP_TEMPLATES:
        history.push(RoutesEnum.CREATE_WHATSAPP_TEMPLATE);
        return;
      case TemplateTabKeys.PDF_TEMPLATES:
        history.push(RoutesEnum.CREATE_PDF_TEMPLATE);
        return;
      default:
        return;
    }
  };

  const getButtonText = (): string => {
    switch (activeTabKey) {
      case TemplateTabKeys.WHATSAPP_TEMPLATES:
        return CREATE_NEW_TEMPLATE.WHATSAPP;
      case TemplateTabKeys.PDF_TEMPLATES:
        return CREATE_NEW_TEMPLATE.PDF;
      default:
        return '';
    }
  };

  const renderSectionButtons = () => {
    if (!isCreateTemplateEnabled) {
      return null;
    }

    return <GenericButton onClick={createNewTemplate} buttonText={getButtonText()} />;
  };

  const renderSubHeader = (): JSX.Element => {
    return <BackNavigationLink />
  };

  const handleTabChange = (activeKey: string) => {
    setActiveTabKey(activeKey);
    const path = RoutesEnum.TEMPLATE_MANAGEMENT_HOMEPAGE_TAB
      .replace(':tab', activeKey);
    history.replace(path);
  };

  return (
    <Container className={styles.container}>
      <GenericHeader header={TEMPLATE_DASHBOARD_HEADER} subHeader={renderSubHeader} sectionButtons={renderSectionButtons} />
      <div className={styles.templatesSection}>
        <Tabs activeKey={activeTabKey} onChange={handleTabChange} >
          <TabPane tab="Whatsapp Templates" key={TemplateTabKeys.WHATSAPP_TEMPLATES.toString()} >
            <WhatsappTemplatesList />
          </TabPane>
          {resolveFeatureFlag('PDF_TEMPLATE') && <TabPane tab="PDF Templates" key={TemplateTabKeys.PDF_TEMPLATES.toString()}>
            <PDFTemplatesList />
          </TabPane>}
        </Tabs>
      </div>
    </Container>
  );
}
