import { ColumnHeader } from '@eazy2biz-ui/common-components';


export enum ItemHeaders {
  NAME = 'name',
  WORKFLOW_NAME = 'workflowName',
  STAGE_NAME = 'stageName',
  DUE_ON = 'dueOn',
  CREATED_ON = 'createdOn',
  ASSIGNEE = 'assignee',
  CREATED_BY = 'createdBy',
  UPDATED_ON = 'updatedOn',
  UPDATED_BY = 'updatedBy'
}

export const getColumnsForItems = (): ColumnHeader[] => {
  const columns = [
    {
      Header: 'Item Name',
      accessor: ItemHeaders.NAME
    },
    {
      Header: 'Current Stage',
      accessor: ItemHeaders.STAGE_NAME
    },
    {
      Header: 'Due On',
      accessor: ItemHeaders.DUE_ON
    },
    {
      Header: 'Assigned To',
      accessor: ItemHeaders.ASSIGNEE
    },
    {
      Header: 'Created On',
      accessor: ItemHeaders.CREATED_ON
    },
    {
      Header: 'Created By',
      accessor: ItemHeaders.CREATED_BY
    },
    {
      Header: 'Last Updated On',
      accessor: ItemHeaders.UPDATED_ON
    },
    {
      Header: 'Last Updated By',
      accessor: ItemHeaders.UPDATED_BY
    }
  ];
  return columns;
};


