import {ColumnHeader} from "@eazy2biz-ui/common-components";

export enum WhatsappTemplatesListHeaders {
    TEMPLATE_NAME = 'templateName',
    STATUS = 'status',
    LAST_UPDATED = 'lastUpdated'
};

export const getColumnsForWhatsappTemplatesList = (): ColumnHeader[] => {
    const columns: ColumnHeader[] = [
        {
          Header: 'Template name',
          accessor: WhatsappTemplatesListHeaders.TEMPLATE_NAME
        },
        {
          Header: 'Status',
          accessor: WhatsappTemplatesListHeaders.STATUS
        },
        {
            Header: 'Last updated',
            accessor: WhatsappTemplatesListHeaders.LAST_UPDATED
        }
    ];

    return columns;
};
