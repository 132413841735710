import GenericTag from "../GenericTag/GenericTag";
import styles from './GenericListOrderComponent.module.css';
import classNames from "classnames";
import CloseButton from "../CloseButton/CloseButton";

/**
 * Generic List Order Component
 * @param props
 * @constructor
 * @author Raghav Agarwal
 */
export const GenericListOrderComponent = (props: PropTypes) => {
    const {title, subTitle, deletable, onDelete, tags = [], onSelect, selected} = props;

    const handleItemClick = (event: any) => {
        event.stopPropagation();
        onSelect && onSelect();
    };

    const renderTags = () => {
        return (
            <div className={styles.tags}>
                {tags.map((value) => {
                    return (
                        <GenericTag title={value} />
                    );
                })}
            </div>
        );
    };

    return (
        <div
            className={classNames(styles.itemWrapper, {[styles.itemSelected]: selected})}
            onClick={handleItemClick} >
            <div className={styles.itemLeftWrapper}>
                <div className={styles.titleWrapper}>
                    <span className={styles.title}>{title}</span>
                    {renderTags()}
                </div>
                <div className={styles.subTitle}>{subTitle}</div>
            </div>
            <div className={styles.rightActionsWrapper}>
                {props.renderRightActions && props.renderRightActions()}
                {deletable && onDelete && <CloseButton className={styles.closeButton} size={'small'} onClose={onDelete}/>}
            </div>
        </div>

    );
};

type PropTypes = {
    title: string;
    subTitle?: string;
    tags?: string[];
    selected?: boolean;
    onSelect?: () => void;
    deletable?: boolean;
    onDelete?: () => void;
    renderRightActions?: () => any;
};
