/* SignUp form content */
export const SignUpHeaderText = "Get 30 Days FREE Trial";
export const SignUpSubHeaderText = "No credit card required.";
export const NamePlaceholder = "Your name";
export const EmailPlaceholder = "eg. johnsmith@xyz.com";
export const countryCodePlaceholder = "91";
export const phoneNumberPlaceholder = "1234567890";
export const PasswordPlaceholder = "Create your password";
export const AlreadyMember = "Already a member?";
export const SignInLinkText = "Log In";
export const SignUpPolicy = "By signing up you agree to our ";
export const TERMS = "T&C";
export const AND = " and ";
export const PRIVACY_POLICY = "Privacy Policy";
export const VerifyOTP = "Verify OTP";
export const ConfirmSignUpTitle = 'Confirm Sign Up';

/* SignIn form content */
export const NewMember = "Don't have an account yet?";
export const SignUpLinkText = "Create free account";
export const SignInHeaderText = "Log in to your account";
export const ChangePassword = {
    ForgotPassword: 'Forgot your password ? ',
    ResetPasswordHere: 'Reset from here.',
    HeaderText: "Change your password",
    SignIn: 'Log in to your account',
    ChangePasswordButton: 'Change Password',
    NewPassword: 'Your new password',
    ReEnterNewPassword: 'Re-enter your password',
    Error: {
        SEND_OTP: 'Error sending OTP, please try again after sometime.',
        CHANGE_PASS: 'Error resetting your password.',
    },
    Success: {
        SEND_OTP: 'OTP sent to you email, please check and enter passcode.',
        CHANGE_PASS: 'Reset password successful',
    }
};
export const EmailPlaceholderSignIn = "Email";
export const PasswordPlaceholderSignIn = "Your password";

export const SignInWithGoogleText = "Sign in with Google";

export const SUCCESS_STRINGS = {
    SIGN_IN: 'Sign in successful!',
    SIGN_UP: 'Sign up successful!',
    CONFIRM_SIGN_UP: 'User sign up confirmed!',
    NEW_COMPANY: 'Created new company!',
    COMPANY_SIGN_IN: 'Signed into company',
    ACCEPT_INVITE: 'Invite accepted',
    REJECT_INVITE: 'Invite rejected'
}

export const ERROR_STRINGS = {
    SIGN_IN: 'Sign in Unsuccessful!',
    SIGN_UP: 'Sign up Unsuccessful!',
    CONFIRM_SIGN_UP: 'Failed to confirm user sign up',
    NEW_COMPANY: 'Failed to create a new company',
    COMPANY_SIGN_IN: 'Failed to sign into company',
    ACCEPT_INVITE: 'Invite couldn\'t be accepted',
    REJECT_INVITE: 'Invite couldn\'t be rejected',
    INVALID_INVITE: 'Invite is invalid',
    ERROR_LOADING_TRY_AGAIN: 'Error loading your details. Try Refreshing or please try again later.',
    ERROR_FETCHING_COMPANY_DETAILS: 'Error loading company details. Try Refreshing or please try again later.',
    WRONG_SIGN_IN_INVITE: 'You are logged in with different account, logout and try again'
};

export const ProfileDrawerStrings = {
    USER_PROFILE: "User Profile",
    PERSONAL: "Personal",
    COMPANY: "Company",
    ERROR_CONTACT_DETAILS: "Error Loading Contact Details"
};

export const SIGN_IN_STRINGS = {
    FETCHING_COMPANY: 'Fetching company details',
    LOGGED_IN_COMPANY: 'Successfully logged into the company'
};

export const PASSWORD_ERROR_STRING = {
    LENGTH_ERROR: 'Password needs to be of at-least 8 characters!',
    CONFIRM_PASS_ERROR: 'Passwords do not match!',
};

