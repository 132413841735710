import {
    AddLocalUserToUserGroupRequest,
    AddUserGroupToUserGroupRequest,
    RemoveLocalUserFromUserGroupRequest,
    RemoveUserGroupFromUserGroupRequest,
    UserGroupCreateRequest,
    UserGroupType
} from "@eazy2biz/common-util";
import {StrictBuilder} from "builder-pattern";

export const getAddLocalUserToUserGroupRequest = (
    localUserId: string,
    userGroupId: string
): AddLocalUserToUserGroupRequest => {
    return StrictBuilder<AddLocalUserToUserGroupRequest>()
        .localUserId(localUserId)
        .userGroupId(userGroupId)
        .build()
};

export const getRemoveLocalUserFromUserGroupRequest = (
    localUserId: string,
    userGroupId: string
): RemoveLocalUserFromUserGroupRequest => {
    return StrictBuilder<RemoveLocalUserFromUserGroupRequest>()
        .localUserId(localUserId)
        .userGroupId(userGroupId)
        .build()
};

export const getAddUserGroupToUserGroupRequest = (
    userGroupToAddId : string,
    userGroupId : string
): AddUserGroupToUserGroupRequest => {
    return StrictBuilder<AddUserGroupToUserGroupRequest>()
        .userGroupToAddId(userGroupToAddId)
        .userGroupId(userGroupId)
        .build()
};

export const getRemoveUserGroupFromUserGroupRequest = (
    userGroupToRemoveId: string,
    userGroupId: string
): RemoveUserGroupFromUserGroupRequest => {
    return StrictBuilder<RemoveUserGroupFromUserGroupRequest>()
        .userGroupToRemoveId(userGroupToRemoveId)
        .userGroupId(userGroupId)
        .build()
};

export const getUserGroupCreateRequest = (
    name: string,
    description: string,
    userGroupType: UserGroupType,
): UserGroupCreateRequest => {
    return StrictBuilder<UserGroupCreateRequest>()
        .name(name)
        .description(description)
        .userGroupType(userGroupType)
        .build()
};
