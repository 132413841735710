import {useHistory} from "react-router-dom";
import styles from './BackNavigationLink.module.css';

/**
 * Back Navigation Link for UI Page.
 * @constructor
 */
export const BackNavigationLink = () => {
    const history = useHistory();
    return(
      <a className={styles.backNavigationLink} onClick={() => history.goBack()}>{"< Back"}</a>
    );
};
