import {
    CookieKeys,
    getCookieService,
    RoutesEnum,
    LocationStateKeys, UIApplicationInit
} from "@eazy2biz-ui/common-package";
import {GlobalUserCompanyMembership, SignInResponse} from "@eazy2biz/common-util";
import {useHistory, useLocation} from "react-router-dom";
import SignInForm from "./SignInForm";
import {useEffect, useState} from "react";
import CompanySelectionModal from "../company/CompanySelectionModal";
import {handleCompanySignIn} from "../../helpers/AuthHelper";
import {CompanySignUpComponent} from "../company/CompanySignUpComponent";
import {Spinner, Toast} from "@eazy2biz-ui/common-components";
import { get } from "lodash";
import SignUpForm from "./SignUpForm";
import ConfirmUserSignUp from "./ConfirmUserSignUp";
import {signIn} from "../../services/GlobalUserService";
import { SIGN_IN_STRINGS } from "../../../contents/DisplayContent";

const UserSignInComponent: (props: PropsType) => JSX.Element = (props: PropsType): JSX.Element => {
    const [signInStage, setSignInStage] = useState(SignInStages.USER_SIGN_IN);
    const [companyMemberships, setCompanyMemberships] = useState([] as GlobalUserCompanyMembership[]);
    const [showSpinner, setShowSpinner] = useState(false);
    const [newUserEmail, setNewUserEmail] = useState('');
    const [newUserPwd, setNewUserPwd] = useState('');

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (
            getCookieService().getFromCookies(CookieKeys.ACCESS_TOKEN) &&
            getCookieService().getFromCookies(CookieKeys.COMPANY) &&
            !get(location.state, LocationStateKeys.SIGN_OUT_FLAG, false)
        ) {
            history.replace(RoutesEnum.APP_DASHBOARD);
        }
    }, []);

    /**
     * Handles successful user sign in.
     */
    const handleUserSignInSuccess = (response: SignInResponse) => {

        UIApplicationInit.getInstance().postGlobalUserSignIn(response.user, response.idToken, response.accessToken, response.accessToken)
            .then(() => {
                const companyMemberships: GlobalUserCompanyMembership[] = response.user.companyMemberships;
                if (redirectToInvitesUrl()) {
                    return;
                }

                if (companyMemberships.length === 0) {
                    setSignInStage(SignInStages.COMPANY_CREATION);
                } else if (companyMemberships.length === 1) {
                    handleCompanySelect(companyMemberships[0]);
                } else {
                    setCompanyMemberships(companyMemberships);
                    setSignInStage(SignInStages.COMPANY_SELECTION);
                }
            });
    };

    const redirectToInvitesUrl = () => {
        const redirectUrl: string | undefined = get(location.state, LocationStateKeys.REDIRECT_URL);

        if (redirectUrl && redirectUrl.startsWith('/inviteConsent/')) {
            history.replace(redirectUrl);
            return true;
        }

        return false;
    };

    const redirectToApp = () => {
        setShowSpinner(true);
        setTimeout(() => {
            history.replace(get(location.state, LocationStateKeys.REDIRECT_URL, RoutesEnum.APP_DASHBOARD));
            Toast.success(SIGN_IN_STRINGS.LOGGED_IN_COMPANY);
        }, 1000);
    };

    const handleCompanySelect = (globalUserCompanyMembership: GlobalUserCompanyMembership): void => {
        Toast.load(SIGN_IN_STRINGS.FETCHING_COMPANY);
        handleCompanySignIn(globalUserCompanyMembership).then(redirectToApp);
    };

    const handleClose = () => {
        props.onSignOut();
    };

    const handleUserSignUp = (email: string, pwd: string) => {
        setNewUserEmail(email);
        setNewUserPwd(pwd);
        setSignInStage(SignInStages.USER_CONFIRM_SIGN_UP);
    };

    const handleUserSignUpConfirmed = () => {
        signIn(newUserEmail, newUserPwd).then(handleUserSignInSuccess).catch(() => Toast.errorString());
    };

    const renderSignInForm = () => {
        return (<SignInForm {...props} onClose={handleClose} onSuccessSignIn={handleUserSignInSuccess} navigateToSignUpPage={() => setSignInStage(SignInStages.USER_SIGN_UP)}/>);
    };

    const renderCompanySelectionForm = () => {
        return (<CompanySelectionModal onClose={handleClose} onCompanySelect={handleCompanySelect} companyMemberships={companyMemberships} />);
    };

    const renderCompanyCreationModal = () => {
        return (<CompanySignUpComponent onClose={handleClose} onCompanySelect={handleCompanySelect}/>)
    };

    const renderUserSignUpForm = () => {
        return <SignUpForm onClose={handleClose} onSignUpSuccess={handleUserSignUp}/>
    };

    const renderConfirmUserSignUpForm = () => {
        return <ConfirmUserSignUp onUserSignUpConfirmed={handleUserSignUpConfirmed} email={newUserEmail} onClose={handleClose}/>
    };

    if (showSpinner) {
        return (<Spinner/>);
    }

    switch (signInStage) {
        case SignInStages.COMPANY_CREATION:
            return renderCompanyCreationModal();
        case SignInStages.COMPANY_SELECTION:
            return renderCompanySelectionForm();
        case SignInStages.USER_SIGN_UP:
            return renderUserSignUpForm();
        case SignInStages.USER_CONFIRM_SIGN_UP:
            return renderConfirmUserSignUpForm();
        default:
            return renderSignInForm();
    }
}

type PropsType = {
    onSignOut: () => void;
};

export default UserSignInComponent;

enum SignInStages {
  USER_SIGN_IN,
  COMPANY_SELECTION,
  COMPANY_CREATION,
  USER_SIGN_UP,
  USER_CONFIRM_SIGN_UP
};
