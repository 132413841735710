import {Field, FieldPlaceholder, FieldPlaceholderType, FieldTypes} from "@eazy2biz/common-util";
import { getHTMLInputTypeFromFieldType, getOperatorMenuForType } from "@eazy2biz-ui/common-package";

export const getFieldTypeForPlaceholderField = (fields: Field[], fieldPlaceholder?: FieldPlaceholder): FieldTypes | undefined => {
    const field: Field | undefined = getFieldForPlaceholderField(fields, fieldPlaceholder);
    if (field) {
        return field.type;
    }

    return;
};

export const getFieldForPlaceholderField = (fields: Field[], fieldPlaceholder?: FieldPlaceholder): Field | undefined => {
    if (fieldPlaceholder && fieldPlaceholder.placeholderType === FieldPlaceholderType.CARD_FORM) {
        return fields.find(formField => formField._id === fieldPlaceholder.key);
    }

    return;
}

export const getOperatorMenuForPlaceholderField = (fields: Field[], fieldPlaceholder?: FieldPlaceholder) => {
    const inputType = getHTMLInputTypeFromFieldType(getFieldTypeForPlaceholderField(fields, fieldPlaceholder));
    return getOperatorMenuForType(inputType);
};


