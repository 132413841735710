import {ActivityResponse, EntityType} from "@eazy2biz/common-util";
import {useState} from "react";
import styles from './CommentsComponent.module.css';
import {
    EmptyDataPlaceholderGraphic,
    GenericButton,
    InputFieldWithPlaceholder, PlaceholderTextComponent, SinglePlaceholderConfig,
    Toast,
    UserProfileAvatar
} from "../../../index";
import {COMMENTS_STRING} from "../../../contents/DisplayContent";
import {DotFilledIcon, PaperPlaneIcon} from "@radix-ui/react-icons";
import {getBaseUserPlaceholder, getUserNameById, timeDifferenceString} from "@eazy2biz/common-package-ui";
import {addComment, getAllComments} from "../../services/ActivityService";

/**
 * Time ordered list of Comments.
 * @param props
 * @constructor
 */
export const CommentsComponent = (props: PropTypes) => {
    const {entityClassId = '', entityType, entityId} = props;
    const [activities, setActivities] = useState<Array<ActivityResponse>>([]);
    const [draftComment, setDraftComment] = useState('');

    const fetchComments = () => {
        getAllComments(entityId, entityClassId, entityType).then((response: ActivityResponse[]) => {
            setActivities(response);
        }).catch(() => Toast.errorString(COMMENTS_STRING.ERROR_FETCH_COMMENTS));
    };


    useState(() => {
        fetchComments();
    });

    const onAddComment = () => {
        addComment(draftComment, entityId, entityClassId, entityType).then(() => {
            fetchComments();
        }).then(() => {
            setDraftComment('');
            Toast.success(COMMENTS_STRING.COMMENT_ADDED_SUCCESS);
        }).catch(() => Toast.errorString(COMMENTS_STRING.ERROR_ADD_COMMENT));
    };

    const handleCommentChange = (e: any) => {
        setDraftComment(e.target.value);
    };

    const renderComment = (activity: ActivityResponse) => {
        return (
            <div className={styles.itemCommentWrapper} key={activity._id}>
                <UserProfileAvatar/>
                <div className={styles.commentDetailsWrapper}>
                    <PlaceholderTextComponent className={styles.commentText} id={''} value={activity.message} />
                    <div className={styles.commentSecondLineWrapper}>
                        <p>{getUserNameById(activity.metaData.createdBy)}</p>
                        <div className={styles.commentTimeWrapper}>
                            <DotFilledIcon />
                            <p>{timeDifferenceString(new Date(activity.metaData.createdDate))}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderAddComment = () => {
        return(
            <div className={styles.addCommentsWrapper}>
                <InputFieldWithPlaceholder
                    id='comment'
                    label={COMMENTS_STRING.ADD_COMMENT}
                    type='text'
                    onChange={handleCommentChange}
                    value={draftComment}
                    className={styles.addCommentInput}
                    singlePlaceholderConfig={{basePlaceholder: getBaseUserPlaceholder()} as SinglePlaceholderConfig}/>
                <GenericButton className={styles.addCommentButton} onClick={onAddComment} CustomIcon={PaperPlaneIcon} />
            </div>
        );
    };

    return (
        <>
            {renderAddComment()}
            <div className={styles.commentsListWrapper}>
                {activities.map(renderComment)}
                {activities.length === 0 && <EmptyDataPlaceholderGraphic size={'s'}/>}
            </div>
        </>
    );
};

type PropTypes = {
    entityType: EntityType;
    entityClassId?: string;
    entityId: string;
};
