import {useEffect, useState} from "react";
import styles from "./SignUpPinInput.module.css";
import OtpInput from "react-otp-input";

interface ISignUpPinInputProps {
    length: number;
    setPasscode: (passcode: string) => void;
}

const pinInputStyle = {
    width: '32px',
    height: '32px',
    borderRadius: '4px',
    borderColor: 'gray',
    margin: '5px',
    fontFamily: 'Lato',
    borderStyle: 'solid'
};

const SignUpPinInput = (props: ISignUpPinInputProps) => {
    const { length, setPasscode } = props;
    const [pin, setPin] = useState<string>('');

    useEffect(() => {
        setPasscode(pin);
    }, [pin])

    return (
        <div>
            <OtpInput
                containerStyle={styles.pinBox}
                inputStyle={pinInputStyle}
                value={pin}
                onChange={setPin}
                numInputs={length}
            />
        </div>
    );
}

export default SignUpPinInput;