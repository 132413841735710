import {CustomerTableHeader} from "@eazy2biz/common-util";
import {TableHeaderFormField} from "./tableHeaderFormField/TableHeaderFormField";
import {isTableHeaderRequired} from "../../helpers/TableHeaderConifgurationsHelper";
import styles from './TableRecordFormComponent.module.css';

/**
 * Table record form component.
 * @param props
 * @constructor
 */
export const TableRecordFormComponent = (props: PropTypes) => {
    const getData = (id: string) => props.contentMap[id];

    const renderHeaderField = (tableHeader: CustomerTableHeader) => {
        return(
            <TableHeaderFormField
                key={tableHeader._id}
                header={tableHeader}
                defaultValue={getData(tableHeader._id)}
                onChange={props.onValueUpdate}
                isRequired={isTableHeaderRequired(tableHeader)}
            />
        );
    };

    return (
      <div className={styles.recordFormWrapper}>
          {props.tableHeaders.map(renderHeaderField)}
      </div>
    );
};

type PropTypes = {
    contentMap: {[key: string]: any};
    tableHeaders: CustomerTableHeader[];
    onValueUpdate: (headerId: string, value: any) => void;
};