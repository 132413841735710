import { useEffect, useState } from 'react';
import { CustomerTableRecordResponse, CustomerTableResponse } from '@eazy2biz/common-util';
import { getFilteredRecordsForTable } from "../../../services/CustomerTableService";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE, FilterComponentProps,
  GenericTable,
  getArrayPage,
  RowData,
  Spinner,
  Toast
} from '@eazy2biz-ui/common-components';
import { getColumnsForTable, getRowsFromRecords } from '../../../helpers/CustomerTableHelper';
import { RecordFormModal } from '../../recordForm/RecordFormModal';
import { CustomerTableFilterConfig, CustomerTableFilterMenu } from "./CustomerTableFilterMenu/CustomerTableFilterMenu";
import { getAppliedFilterConfigFromRecordsFilters } from "../../../helpers/RecordsTableHelper";

export const RecordsTable = (props: PropTypes) => {
  const { customerTable, refreshTable, setRefreshTable } = props;

  const tableColumns = getColumnsForTable(customerTable);

  const [loadingRecords, setLoadingRecords] = useState(true);
  const [allRecords, setAllRecords] = useState<CustomerTableRecordResponse[]>([]);
  const [tableData, setTableData] = useState<Array<RowData>>([]);
  const [selectedRecordId, setSelectedRecordId] = useState<string>();
  const [openRecordForm, setOpenRecordForm] = useState(false);
  const [filterConfig, setFilterConfig] = useState(new CustomerTableFilterConfig());

  useEffect(() => {
    getAllRecords(filterConfig);
  }, [customerTable, refreshTable]);

  useEffect(() => {
    refreshTable && getAllRecords(filterConfig);
  }, [refreshTable]);

  const getAllRecords = (filter: CustomerTableFilterConfig) => {
    setLoadingRecords(true);
    !refreshTable && setAllRecords([]);
    refreshTable && setRefreshTable && setRefreshTable(false);

    getFilteredRecordsForTable(customerTable._id.toString(), filter)
      .then((records) => {
        setAllRecords(records);
        setTableDataFromRecords(records, DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, filter);
      })
      .catch(() => Toast.errorString())
      .finally(() => {
        setLoadingRecords(false);
      });
  };

  const handlePageChange = (page: number, pageSize: number) => {
    setTableDataFromRecords(allRecords, page, pageSize, filterConfig);
  };

  const setTableDataFromRecords = (records: CustomerTableRecordResponse[], page: number, pageSize: number, filter: CustomerTableFilterConfig) => {
    setTableData(getRowsFromRecords(getArrayPage(records, pageSize, page), customerTable));
  };

  const onDefaultActionClick = (id: string) => {
    setSelectedRecordId(id);
    setOpenRecordForm(true);
  };

  const handleEdit = () => {
    setOpenRecordForm(false);
    setRefreshTable && setRefreshTable(!refreshTable);
  };

  const toggleEditRecord = () => {
    setOpenRecordForm(false);
  };

  const handleFilterChange = (filter: CustomerTableFilterConfig) => {
    setFilterConfig(filter);
    getAllRecords(filter);
  };

  const handleClearFilters = () => {
    handleFilterChange(new CustomerTableFilterConfig());
  };

  if (loadingRecords) {
    return <Spinner />;
  }

  const renderEditRecordForm = () => {
    if (!setSelectedRecordId || !setOpenRecordForm) return;

    const selectedRecord = allRecords.find((record) => record._id === selectedRecordId);
    if (selectedRecord && openRecordForm) {
      return (
        <RecordFormModal
          tableId={selectedRecord.tableId}
          record={selectedRecord}
          onSubmit={handleEdit}
          onClose={toggleEditRecord}
          editMode
        />
      );
    }
  };

  const renderTableFilter = (filterProps: FilterComponentProps) => {
    return (
      <CustomerTableFilterMenu
        customerTable={customerTable}
        filterConfig={filterConfig}
        onFilterChange={handleFilterChange}
        onClose={filterProps.onClose}
      />
    );
  };

  return (
    <div>
      <GenericTable
        columns={tableColumns}
        data={tableData}
        paginationEnabled
        totalItems={allRecords.length}
        onPageChange={handlePageChange}
        downloadEnabled
        onDefaultActionClick={onDefaultActionClick}
        defaultActionEnabled
        showFiltersOption
        onClearFilters={handleClearFilters}
        getFilterComponent={renderTableFilter}
        appliedFilterConfig={getAppliedFilterConfigFromRecordsFilters(filterConfig, handleFilterChange)}
      />
      {renderEditRecordForm()}
    </div>
  );
};

type PropTypes = {
  customerTable: CustomerTableResponse;
  refreshTable?: boolean;
  setRefreshTable?: (value: boolean) => void;
};
