import {ActionResult, ActionResultTypes, EmailDetails} from "@eazy2biz/common-util";
import {DEFAULT_COMMUNICATION_EMAIL} from "@eazy2biz/common-package-ui";
import {getEnvironmentConfig} from "@eazy2biz-ui/common-package";

export const getEmailAction = (): ActionResult<EmailDetails> => {
    return {
        type: ActionResultTypes.EMAIL,
        resultDetails: {
            body: "",
            enabled: true,
            from: getEnvironmentConfig().communicationEmail || DEFAULT_COMMUNICATION_EMAIL,
            subject: "",
            to: [],
            cc: [],
            bcc: []
        }
    };
}
