import {GenericTagTypes} from "../GenericTag/GenericTagTypes";
import GenericTag from "../GenericTag/GenericTag";
import React from "react";
import styles from "./GenericDropdownRow.module.css";
import classNames from "classnames";

export const GenericDropdownRow = (props: PropTypes) => {
    return (
        <div key={props.id + '-result-row'} className={classNames(styles.resultRow, props.className)}>
            {props.renderIcon && <span className={styles.icon}>{props.renderIcon()}</span>}
            <span>{props.value}</span>
            {
                props.tagTitle &&
                    <GenericTag
                        title={props.tagTitle}
                        type={props.tagType || GenericTagTypes.DARK}
                        contentClassName={styles.tagContent}
                    />
            }
        </div>
    );
};

type PropTypes = {
    id: string;
    value: string;
    tagTitle?: string;
    tagType?: GenericTagTypes;
    renderIcon?: () => React.ReactNode;
    className?: string;
};
