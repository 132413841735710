import * as React from 'react';
import { GenericTable } from "@eazy2biz-ui/common-components"
import { TemplateResponse } from "@eazy2biz/common-util";
import { getColumnsForWhatsappTemplatesList } from "../../../config/whatsapp/WhatsappTemplatesListConfig";
import { getTableDataForWhatsappTemplatesList } from "../../../helpers/whatsapp/WhatsappTemplatesListHelper";
import { getAllWhatsappTemplates } from "../../../services/whatsapp/WhatsappTemplateService";
import { RoutesEnum } from "@eazy2biz-ui/common-package";
import { useHistory } from "react-router-dom";

export const TemplatesList = (): JSX.Element => {
  const columns = getColumnsForWhatsappTemplatesList();
  const [tableData, setTableData] = React.useState<any[]>([]);

  const history = useHistory();

  React.useEffect(() => {
    getAllWhatsappTemplates().then((response: TemplateResponse[]) => {
      setTableData(
        getTableDataForWhatsappTemplatesList(response)
      );
    });
  }, []);

  const handleTemplateSelect = (templateId: string) => {
    history.push(RoutesEnum.UPDATE_WHATSAPP_TEMPLATE.replace(':templateId', templateId));
  };

  return (
    <GenericTable
      columns={columns}
      data={tableData}
      loading={false}
      totalItems={tableData.length}
      defaultActionEnabled
      onDefaultActionClick={handleTemplateSelect}
    />
  );
};
