import {ReactElement, useEffect, useState} from 'react';
import {getAllFilteredTodosForCurrentUser} from '../../../services/UserTodoService';
import {
    GenericTable,
    Toast,
    getArrayPage,
    DEFAULT_PAGE_NUMBER,
    DEFAULT_PAGE_SIZE
} from "@eazy2biz-ui/common-components";
import {getColumnsForTodos} from '../../../config/TodoTableConfig';
import {getAppliedFilterConfigFromTodoFilters, getTableDataForTodos} from "../../../helpers/TodoTableHelper";
import {UserTodoExecutableActionResponse} from "@eazy2biz/common-util";
import {TodoFilterConfig, TodoFilterMenu} from "./TodoFilterMenu/TodoFilterMenu";
import {GenericTodoCompleteModal} from "../GenericTodoCompleteModal/GenericTodoCompleteModal";

const TodoTable = (props: PropType) => {
  const [todos, setTodos] = useState<UserTodoExecutableActionResponse[]>(new Array());
  const [openTodoForm, setOpenTodoForm] = useState(false);
  const [selectedTodoId, setSelectedTodoId] = useState<string>();
  const [tableData, setTableData] = useState(new Array());
  const [filterConfig, setFilterConfig] = useState<TodoFilterConfig>(new TodoFilterConfig());

  const isWorkflowTable = !!props.workflowId;
  const columns = getColumnsForTodos(isWorkflowTable);

  useEffect(() => {
    if (todos.length === 0 || props.refreshTable) {
        setTimeout(() => handleFilterChange(filterConfig), props.refreshTable? 3000: 0);
    }
  }, [props.refreshTable]);

    const handlePageChange = (page: number, pageSize: number) => {
        updateTableData(getArrayPage(todos, pageSize, page));
    };

    const updateTableData = (todoItems: UserTodoExecutableActionResponse[]) => {
        setTableData(getTableDataForTodos(todoItems, isWorkflowTable));
    };

    const clearFilters = () => {
        handleFilterChange(TodoFilterConfig.getClearFilter());
    };

  const onDefaultActionClick = (id: string) => {
    setSelectedTodoId(id);
    setOpenTodoForm(true);
  };

  const handleSubmit = () => {
      !props.onRefreshCallback && handleFilterChange(filterConfig);
      props.onRefreshCallback && props.onRefreshCallback(true);
      setOpenTodoForm(false);
  };

  const handleFilterChange = (config: TodoFilterConfig) => {
      setFilterConfig(config);
      getAllFilteredTodosForCurrentUser(props.workflowId, config)
          .then((response: UserTodoExecutableActionResponse[] = []) => {
              setTodos(response);
              updateTableData(getArrayPage(response, DEFAULT_PAGE_SIZE, DEFAULT_PAGE_NUMBER));
          }).catch(() => Toast.errorString())
          .finally(() => {
              props.onRefreshCallback && props.onRefreshCallback(false);
          });
  };

  const renderTodoCompleteForm = (): ReactElement | undefined => {

    if (!openTodoForm || !selectedTodoId) {
      return;
    }

    const selectedTodo = todos.find((todo => todo._id === selectedTodoId));

    if(selectedTodo) {
      return (
          <GenericTodoCompleteModal
              todo={selectedTodo}
              onClose={() => {
                setOpenTodoForm(false);
                setSelectedTodoId(undefined);
              }}
              onSubmit={handleSubmit}
          />
      );
    }
  };

  const renderTodoFilter = () => {
    return(
        <TodoFilterMenu
            filterConfig={filterConfig}
            onFilterChange={handleFilterChange}/>
    );
  };

  return (
    <div>
      <GenericTable
        columns={columns}
        data={tableData}
        paginationEnabled
        onPageChange={handlePageChange}
        totalItems={todos.length}
        showFiltersOption
        getFilterComponent={renderTodoFilter}
        onClearFilters={clearFilters}
        onDefaultActionClick={onDefaultActionClick}
        showSettings
        appliedFilterConfig={getAppliedFilterConfigFromTodoFilters(filterConfig, handleFilterChange)}
        defaultActionEnabled={true}/>
      {renderTodoCompleteForm()}
    </div>
  );
};

type PropType = {
  workflowId?: string;
  refreshTable?: boolean;
  onRefreshCallback?: (value: boolean) => void;
};

export default TodoTable;
