import {List} from "antd";
import styles from "./PlaceholderOptionsList.module.css";
import {ArrowLeftIcon} from "@radix-ui/react-icons";
import { PlaceholderOption } from "../entity/PlaceholderOption";

interface IPlaceholderOptionsListProps {
    placeholderOptions: PlaceholderOption[],
    onPick: (key: string, name?: string) => void;
    goBack: () => void;
    icon?: JSX.Element;
    headerTitle: string;
}

interface IOptionsListHeaderProps {
    header: string;
    goBack: () => void;
}
const OptionsListHeader = (props: IOptionsListHeaderProps) => {
    const { header, goBack } = props;
    return (
        <div className={styles.header}>
            <span onClick={goBack} className={styles.backButton}><ArrowLeftIcon /></span>
            <span className={styles.headerText}>{header}</span>
        </div>
    );
}

export const PlaceholderOptionsList = (props: IPlaceholderOptionsListProps) => {
    const { placeholderOptions, onPick, headerTitle, goBack, icon } = props;
    return (
        <>
            <OptionsListHeader header={headerTitle} goBack={goBack}/>
            <List
                dataSource={placeholderOptions}
                renderItem={(item: PlaceholderOption) => (
                    <List.Item className={styles.placeholderOption} onClick={() => onPick(item.key, item.name)}>
                        { icon ? <span className={styles.placeholderOptionIcon}>{icon}</span> : null }
                        <span>{item.name}</span>
                    </List.Item>
                )}
            />
        </>

    );
}
