import "react-js-cron/dist/styles.css";
import Cron, { PeriodType } from "react-js-cron";
import styles from "./GenericCronField.module.css";
import Toast, { ToastLength } from "../Toast/Toast";
import { allowedCronMinuteValue, validateCron } from "@eazy2biz/common-package-ui";
import { CRON_FIELD } from "../../../contents/DisplayContent";

/**
 * Generic Cron Picker
 * @param props
 * @constructor
 */
export const GenericCronField = (props: PropTypes) => {

  const allowedPeriods: PeriodType[] = ['year' , 'month' , 'week' , 'day' , 'hour'];

  const handleValueChange = (cronValue: string) => {
    if (validateCron(cronValue)) {
      props.onChange(cronValue);
    } else {
      Toast.errorString(CRON_FIELD.INVALID_CRON + JSON.stringify(allowedCronMinuteValue), ToastLength.SHORT);
    }
  };

  return (
    <div className={styles.cronFieldWrapper}>
      <p className={styles.cronFieldLabel}>{props.label}</p>
      <Cron
        className={styles.cronField}
        value={props.value}
        disabled={props.disabled}
        allowedPeriods={allowedPeriods}
        clockFormat={'12-hour-clock'}
        setValue={handleValueChange} />
    </div>
  );
};

type PropTypes = {
  label: string;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
};
