import {MixerHorizontalIcon} from "@radix-ui/react-icons";
import {FILTER_ACTION_TEXT} from "../../../../contents/DisplayContent";
import React, {useState} from "react";
import {Popover} from "antd";
import styles from './FilterOption.module.css';
import CloseButton from "../../CloseButton/CloseButton";

export const FiltersOption = (props: PropTypes): JSX.Element => {

    const [filterMenuVisible, setFilterMenuVisible] = useState(false);

    const getHeader = () => {
        return (
            <div className={styles.filterHeaderWrapper}>
                <span>Filter</span>
                <div className={styles.filterHeaderWrapper}>
                    { props.onClearFilters && <span className={styles.clearFilterButton} onClick={props.onClearFilters}>Clear</span> }
                    <CloseButton size='small' onClose={() => setFilterMenuVisible(false)}/>
                </div>
            </div>
        );
    };

    const getMenuContent = () => {
        return (
            <div className={styles.filterMenuContentWrapper}>
                {props.getFilterElement({
                    onClose: () => setFilterMenuVisible(false)
                })}
            </div>
        );
    };

    return (
        <Popover
            content={getMenuContent()}
            title={getHeader()}
            trigger="click"
            placement={'left'}
            visible={filterMenuVisible}
            onVisibleChange={setFilterMenuVisible}
        >
            <div className={styles.tableMenuOption}>
                <span className={styles.tableMenuOptionIcon}>
                    <MixerHorizontalIcon />
                </span>
                <span className={styles.filterButtonText}>{FILTER_ACTION_TEXT}</span>
            </div>
        </Popover>
    );
};

type PropTypes = {
    getFilterElement: (filterProps: FilterComponentProps) => JSX.Element;
    onClearFilters?: () => void;
};

export type FilterComponentProps = {
    onClose: () => void;
};
