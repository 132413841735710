import {RcFile} from "antd/lib/upload";
import { CreateAttachmentRequest, EntitySearch, FileTypes, SearchTypes } from "@eazy2biz/common-util";
import {ErrorTypes, GenericException} from "@eazy2biz/common-package-ui";
import {UPLOAD_FILE_STRINGS} from "../../../contents/DisplayContent";
import { idPath } from "../constants/EntityConstants";

export const getAttachmentTypeFromFileType = (type: string): FileTypes => {
    switch (type) {
        case 'image/jpeg':
        case 'image/jpg': return FileTypes.JPG;
        case 'image/png': return FileTypes.PNG;
        case 'application/pdf': return FileTypes.PDF
        default:
            throw new GenericException(ErrorTypes.API_ERROR, 'Invalid file type', UPLOAD_FILE_STRINGS.INVALID_FILE_TYPE);
    }
};

export const getAttachmentRequestFromFile = (file: RcFile): CreateAttachmentRequest => {
    return {
        fileDescription: file.uid,
        fileName: file.name,
        fileType: getAttachmentTypeFromFileType(file.type),
    };
};

export const getIdFiltersForAttachments = (ids: string[]): EntitySearch => {
    return new EntitySearch([
        {
            field: idPath,
            type: SearchTypes.VALUE_IN_ARRAY,
            value: ids
        }
    ]);
};
