import { GenericRadioSelect, InputField } from '@eazy2biz-ui/common-components';
import { WhatsappTemplateTypes } from '@eazy2biz/common-util';
import * as React from 'react';
import { TEMPLATE_SETTING_STRINGS } from '../../../../../contents/DisplayContent';
import { WhatsappTemplateConfigurationType } from '../../../../helpers/whatsapp/CreateWhatsappTemplateHelper';
import { getTemplateCategoryOptions } from '../../../../helpers/whatsapp/WhatsappTemplateSettingsHelper';
import styles from './WhatsappTemplateSettings.module.css';
import {resolveFeatureFlag} from "@eazy2biz-ui/common-package";

const EMPTY_FUNCTION = () => {};

export const WhatsappTemplateSettings = (props: WhatsappTemplateSettingsPropsType): JSX.Element => {
  const handleWhatsappMessageTemplateTypeSelection = (option: WhatsappTemplateTypes) => {
    props.updateConfiguration({
      ...props.configuration,
      templateType: option
    })
  };

  const handleWhatsappMessageTemplateNameChange = (e: any) => {
    props.updateConfiguration({
      ...props.configuration,
      name: e.target.value
    });
  };


  return (
    <div className={styles.settingsContainer}>
      <div className={styles.settingsBlock}>
        <div className={styles.settingsHeader}>{TEMPLATE_SETTING_STRINGS.CATEGORY_HEADER}</div>
        <div className={styles.settingsSubHeader}>{TEMPLATE_SETTING_STRINGS.CATEGORY_SUB_HEADER}</div>
        <div className={styles.settingsBody}>
          <GenericRadioSelect
            defaultOption={props.configuration.templateType}
            options={getTemplateCategoryOptions()}
            disabled={props.readOnly}
            onChange={handleWhatsappMessageTemplateTypeSelection}
            className={styles.whatsappTemplateCategoryRadioButton}
          />
        </div>
      </div>
      <div className={styles.settingsBlock}>
        <div className={styles.settingsHeader}>{TEMPLATE_SETTING_STRINGS.NAME_HEADER}</div>
        <div className={styles.settingsSubHeader}>{TEMPLATE_SETTING_STRINGS.NAME_SUB_HEADER}</div>
        <div className={styles.settingsBody}>
          <InputField
          id={''}
          value={props.configuration.name}
          initialValue={props.configuration.name}
          label={''}
          required
          type={'text'}
          disabled={props.readOnly}
          placeholder={TEMPLATE_SETTING_STRINGS.NAME_INPUT_PLACEHOLDER}
          onChange={handleWhatsappMessageTemplateNameChange} />
        </div>
      </div>
      {
        resolveFeatureFlag('WHATSAPP_TEMPLATE_LANGUAGE') &&
          <div className={styles.settingsBlock}>
            <div className={styles.settingsHeader}>{TEMPLATE_SETTING_STRINGS.LANGUAGES_HEADER}</div>
            <div className={styles.settingsSubHeader}>{TEMPLATE_SETTING_STRINGS.LANGUAGES_SUB_HEADER}</div>
            <div className={styles.settingsBody}>
              <InputField
                disabled
                id={''}
                label={''}
                required={false}
                type={'text'}
                value="ENGLISH"
                initialValue="ENGLISH"
                onChange={EMPTY_FUNCTION} />
            </div>
          </div>
      }
    </div>
  );
};

type WhatsappTemplateSettingsPropsType = {
  configuration: WhatsappTemplateConfigurationType,
  updateConfiguration: (configuration: WhatsappTemplateConfigurationType) => void;
  readOnly?: boolean;
};
