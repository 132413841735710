import {
    AggregationResult, AreaChartDetails, BarChartDetails,
    BaseChartDetails,
    ChartType,
    DateFieldAggregationType,
    LineChartDetails, PieChartDetails
} from "@eazy2biz/common-util";

import LineChartIcon from '../../assets/line-chart.svg';
import AreaChartIcon from '../../assets/area-chart.svg';
import BarChartIcon from '../../assets/bar-chart.svg';
import PieChartIcon from '../../assets/pie-chart.svg';
import TableChartIcon from '../../assets/table-chart.svg';
import {
    getPrettyDayOfMonth,
    getPrettyDayOfWeek, getPrettyDayOfYear,
    getPrettyHour, getPrettyMonth, getPrettyQuarter, getPrettyWeek, getPrettyYear
} from "@eazy2biz-ui/common-package";


export const transformChartData = (
    chartData: AggregationResult[],
    chartType : ChartType,
    chartDetails : BaseChartDetails<ChartType>
) : any => {
    return chartData.map(data => ({
        aggregationMetaData: data.aggregationMetaData,
        aggregationValue : data.aggregationValue,
        ...(data.groupingField) && {
            groupingFieldName : data.groupingField?.fieldName,
            groupingFieldLabel : data.groupingField?.fieldLabel,
            groupingFieldValue :
                data.groupingField?.fieldValue !== undefined
                    ? getTransformedGroupingFieldValue(data.groupingField?.fieldValue, chartType, chartDetails)
                    : data.groupingField?.fieldValue,
        },
        ...(data.subGroupingField) && {
            subGroupingFieldName : data.subGroupingField?.fieldName,
            subGroupingFieldLabel : data.subGroupingField?.fieldLabel,
            subGroupingFieldValue :
                data.subGroupingField?.fieldValue !== undefined
                    ? getTransformedSubGroupingFieldValue(data.subGroupingField?.fieldValue, chartType, chartDetails)
                    : data.subGroupingField?.fieldValue
        }
    }));
}

const getTransformedGroupingFieldValue = (
    groupingFieldValue : string,
    chartType : ChartType,
    chartDetails : BaseChartDetails<ChartType>
) : string => {
    switch (chartType) {
        case ChartType.LINE:
            const lineChartDetails : LineChartDetails = chartDetails as LineChartDetails;
            return getPrettyValueForDateFieldAggregationField(groupingFieldValue, lineChartDetails.dateGroupingFieldAggregationType);
        case ChartType.AREA:
            const areaChartDetails : AreaChartDetails = chartDetails as AreaChartDetails;
            return getPrettyValueForDateFieldAggregationField(groupingFieldValue, areaChartDetails.dateGroupingFieldAggregationType);
        case ChartType.BAR:
            const barChartDetails : BarChartDetails = chartDetails as BarChartDetails;
            return barChartDetails.dateGroupingFieldAggregationType !== undefined
                ? getPrettyValueForDateFieldAggregationField(groupingFieldValue, barChartDetails.dateGroupingFieldAggregationType)
                : groupingFieldValue;
        case ChartType.PIE:
            const pieChartDetails : PieChartDetails = chartDetails as PieChartDetails;
            return pieChartDetails.dateGroupingFieldAggregationType !== undefined
                ? getPrettyValueForDateFieldAggregationField(groupingFieldValue, pieChartDetails.dateGroupingFieldAggregationType)
                : groupingFieldValue;
    }

    return groupingFieldValue;
}

const getTransformedSubGroupingFieldValue = (
    subGroupingFieldValue : string,
    chartType : ChartType,
    chartDetails : BaseChartDetails<ChartType>
) : string => {
    switch (chartType) {
        case ChartType.LINE:
            const lineChartDetails : LineChartDetails = chartDetails as LineChartDetails;
            return lineChartDetails.subGrouping?.dateFieldAggregationType !== undefined
                ? getPrettyValueForDateFieldAggregationField(subGroupingFieldValue, lineChartDetails.subGrouping?.dateFieldAggregationType)
                : subGroupingFieldValue;
        case ChartType.AREA:
            const areaChartDetails : AreaChartDetails = chartDetails as AreaChartDetails;
            return areaChartDetails.subGrouping?.dateFieldAggregationType !== undefined
                ? getPrettyValueForDateFieldAggregationField(subGroupingFieldValue, areaChartDetails.subGrouping?.dateFieldAggregationType)
                : subGroupingFieldValue;
        case ChartType.BAR:
            const barChartDetails : BarChartDetails = chartDetails as BarChartDetails;
            return barChartDetails.subGrouping?.dateFieldAggregationType !== undefined
                ? getPrettyValueForDateFieldAggregationField(subGroupingFieldValue, barChartDetails.subGrouping?.dateFieldAggregationType)
                : subGroupingFieldValue;
    }

    return subGroupingFieldValue;
}

const getPrettyValueForDateFieldAggregationField = (
    fieldValue : string, dateFieldAggregationType : DateFieldAggregationType
) => {
    switch (dateFieldAggregationType) {
        case DateFieldAggregationType.HOUR:
            return getPrettyHour(parseFloat(fieldValue));
        case DateFieldAggregationType.DAY_OF_WEEK:
            return getPrettyDayOfWeek(parseFloat(fieldValue));
        case DateFieldAggregationType.DAY_OF_MONTH:
            return getPrettyDayOfMonth(parseFloat(fieldValue));
        case DateFieldAggregationType.DAY_OF_YEAR:
            return getPrettyDayOfYear(parseFloat(fieldValue));
        case DateFieldAggregationType.WEEK:
            return getPrettyWeek(parseFloat(fieldValue));
        case DateFieldAggregationType.MONTH:
            return getPrettyMonth(parseFloat(fieldValue));
        case DateFieldAggregationType.QUARTER:
            return getPrettyQuarter(parseFloat(fieldValue));
        case DateFieldAggregationType.YEAR:
            return getPrettyYear(parseFloat(fieldValue));
    }
}


export const getImageForChartType = (chartType : ChartType) : string => {
    switch (chartType) {
        case ChartType.LINE:
            return LineChartIcon;
        case ChartType.AREA:
            return AreaChartIcon;
        case ChartType.BAR:
            return BarChartIcon;
        case ChartType.PIE:
            return PieChartIcon;
        case ChartType.TABLE:
            return TableChartIcon;
    }
}
