import { FeatureTour, isTourAllowed } from "./FeatureTour";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { TourType } from "./entity/TourType";
import { cloneDeep } from "lodash";

/**
 * Alert modal provider.
 * @param props
 * @constructor
 */
export const FeatureTourProvider = (props: any) => {
  const [tourType, setTourType] = useState<TourType | undefined>(undefined);
  const [showTour, setShowTour] = useState<boolean>(false);
  const [refs, setRefs] = useState<Map<number, any>>(new Map());
  const [tourSession, setTourSession] = useState<Map<TourType | undefined, boolean>>(new Map());
  const ref0 = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  useEffect(() => {
    const map = new Map();
    map.set(0, ref0);
    map.set(1, ref1);
    map.set(2, ref2);
    map.set(3, ref3);
    setRefs(map);
  }, []);
  const triggerTour = (type: TourType) => {
    if (tourType && tourType === type && !tourSession.has(tourType)) {
      setShowTour(true);
    }
  };

  const hideTour = () => {
    if (tourType) {
      const updatedSession = cloneDeep(tourSession);
      updatedSession.set(tourType, true);
      setTourSession(updatedSession);
    }
    setShowTour(false);
    updateTourType(undefined);
  };

  const updateTourType = (type?: TourType) => {
    if (!tourSession.has(type) && (!tourType || isTourAllowed(true, tourType))) {
      setTourType(type);
    }
  };

  const getRef = (idx: number, type: TourType) => {
    if (tourType && type !== tourType) {
      return null;
    }

    updateTourType(type);

    if (tourSession.has(type)) {
      return null;
    }

    return refs.get(idx);
  };

  const value: FeatureTourContext = {
    showTour,
    tourType,
    triggerTour,
    hideTour,
    getRef,
    updateTourType
  };

  return (
    <FeatureTourContext.Provider value={value}>
      {props.children}
      <FeatureTour />
    </FeatureTourContext.Provider>
  );
};


type FeatureTourContext = {
  tourType?: TourType,
  showTour?: boolean;
  triggerTour: (type: TourType) => void;
  hideTour: () => void;
  getRef: (idx: number, tourType: TourType) => any;
  updateTourType: (tourType?: TourType) => void;
};

const initialState: FeatureTourContext = {
  triggerTour: () => {},
  hideTour: () => {},
  getRef: () => {},
  updateTourType: () => {},
};

const FeatureTourContext = createContext(initialState);
export const useFeatureTour = () => useContext(FeatureTourContext);
