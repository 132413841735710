import React, {useState} from "react";
import generatePicker from "antd/lib/date-picker/generatePicker";
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import "react-datepicker/dist/react-datepicker.css";
import {DEFAULT_DATE_FORMAT} from "@eazy2biz/common-package-ui";
import {InputProps as DefaultInputPropTypes} from "antd/lib/input/Input";
import classNames from "classnames";
import styles from './GenericDatePicker.module.css';
import moment, { Moment } from "moment";
import { CloseCircleOutlined } from "@ant-design/icons";

/**
 * Generic Date Picker
 * @param props
 * @constructor
 */
export const GenericDatePicker = (props: PropTypes) => {

    const DatePicker = generatePicker<Moment>(momentGenerateConfig)

    const getDefaultDateMomentValue = (): Moment | null  => {
        if (props.value || props.defaultValue) {
            // @ts-ignore
            return moment(props.value || props.defaultValue);
        }
        return null;
    };

    const [dateMoment, setDateMoment] = useState<Moment | null>(getDefaultDateMomentValue());

    const handleOnChange = (date: Moment | null) => {
        setDateMoment(date);
        // @ts-ignore
        props.onChange && props.onChange({target: {value: date?.toDate() || undefined}});
    };

    return (
            <DatePicker
                onChange={handleOnChange}
                className={classNames(props.className, styles.datePicker)}
                disabled={props.disabled || props.readOnly}
                inputReadOnly={props.readOnly}
                placeholder={props.placeholder}
                value={dateMoment}
                format={DEFAULT_DATE_FORMAT}
                clearIcon={<CloseCircleOutlined/>}
                popupStyle={{zIndex:1100}}
            />
    );
};

interface PropTypes extends DefaultInputPropTypes {};
