import {
    AreaChartDetails,
    BaseChartDetails, ChartType,
    ReportEntityType
} from "@eazy2biz/common-util";

import {ConfigureWorkflowAreaChartDetails} from "./workflow/ConfigureWorkflowAreaChartDetails";
import {useEffect, useState} from "react";
import {Builder} from "builder-pattern";

import styles from './ConfigureAreaChartDetails.module.css'

export const ConfigureAreaChartDetails = (
    props : {
        entityType: ReportEntityType;
        entityId: string;
        entity: any;
        showChartConfigureFormFieldErrors: boolean;
        handleChartDetailsChange: (newChartDetails : BaseChartDetails<ChartType>) => void;
    }
) : JSX.Element => {

    const {
        entityType,
        entityId,
        entity,
        showChartConfigureFormFieldErrors,
        handleChartDetailsChange,
    } = props;

    const [areaChartDetails , setAreaChartDetails] = useState<AreaChartDetails>(Builder<AreaChartDetails>().build())

    const [subGroupingEnabled , setSubGroupingEnabled] = useState<boolean>(false)

    useEffect(() => {
        handleChartDetailsChange(areaChartDetails);
    }, [areaChartDetails])

    const getAreaChartComponent = () => {
        switch (entityType) {
            case ReportEntityType.WORKFLOW:
                return (
                    <ConfigureWorkflowAreaChartDetails
                        entityType={entityType}
                        entityId={entityId}
                        workflowResponse={entity}
                        showChartConfigureFormFieldErrors={showChartConfigureFormFieldErrors}

                        areaChartDetails={areaChartDetails}
                        setAreaChartDetails={setAreaChartDetails}
                        subGroupingEnabled={subGroupingEnabled}
                        setSubGroupingEnabled={setSubGroupingEnabled}
                    />
                )
        }
    }

    return (
        <>
            {
                getAreaChartComponent()
            }
        </>
    )
}
