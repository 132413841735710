import {useEffect, useState} from "react";
import {getRecordsCount} from "../../../services/CustomerTableService";
import {CUSTOMER_TABLE_HOMEPAGE_STRINGS} from "../../../../contents/DisplayContent";

export const CustomerTableCardSubTitle = (props: PropTypes) => {

    const [recordsCount, setRecordsCount] = useState(0);

    useEffect(() => {
        getRecordsCount(props.customerTableId.toString())
            .then((count) => {
                setRecordsCount(count);
            });
    }, []);

    return (<span>{recordsCount + CUSTOMER_TABLE_HOMEPAGE_STRINGS.CARD_RECORDS}</span>)
};

type PropTypes = {
    customerTableId: string | number;
};
