import { ActionResult, ActionResultTypes, SaveTableRecordDetails } from "@eazy2biz/common-util";

export const getCreateTableRecordDetails = (): ActionResult<SaveTableRecordDetails> => {
    return {
        type: ActionResultTypes.SAVE_TABLE_RECORD,
        resultDetails: {
            tableId: '',
            recordValues: {},
        }
    };
};
