import * as React from 'react';
import type { RadioChangeEvent } from 'antd';
import { Radio, Space } from 'antd';
import styles from './GenericRadioSelect.module.css';
import classNames from "classnames";

export const GenericRadioSelect = (props: PropsType): JSX.Element => {
  const [value, setValue] = React.useState<any>(props.defaultOption);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
    props.onChange(e.target.value);
  };

  return (
    <Radio.Group onChange={onChange} value={value} disabled={props.disabled}>
      <div className={classNames(props.className)}>
        {
          props.options.map((option: RadioSelectOptionType, idx: number) => {
            return (
              <Radio value={option.value}>
                <div className={styles.header}>{option.header}</div>
                {
                  option.subHeader && <div className={styles.subHeader}>{option.subHeader}</div>
                }
              </Radio>
            );
          })
        }
      </div>
    </Radio.Group>
  )
};

export type RadioSelectOptionType = {
  value: string;
  header: string;
  subHeader?: string;
  icon?: React.ReactElement;
}

type PropsType = {
  options: RadioSelectOptionType[];
  onChange: (option: any) => void;
  defaultOption?: any;
  disabled?: boolean;
  className?: string;
};
