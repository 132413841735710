import {RestServices} from "@eazy2biz/common-package-ui";
import {RcFile} from "antd/es/upload";
import { getAttachmentRequestFromFile, getIdFiltersForAttachments } from "./helpers/AttachmentServiceHelper";

const ATTACHMENT_SERVICE = () => RestServices.getInstance().getAttachmentService();

export const getFilteredAttachmentsByIds = (ids: string[]) => {
    return ATTACHMENT_SERVICE().filterAttachments(getIdFiltersForAttachments(ids));
};

export const uploadAttachment = (fileToUpload: RcFile) => {
    return ATTACHMENT_SERVICE().createAttachment(fileToUpload, getAttachmentRequestFromFile(fileToUpload));
};

export const downloadAttachment = (id: string) => {
    return ATTACHMENT_SERVICE().downloadAttachmentById(id);
};

export const deleteAttachmentById = (id: string) => {
    return ATTACHMENT_SERVICE().deleteAttachmentById(id);
};
