import {
  CustomerTableHeader,
  CustomerTableRecordAddRequest,
  CustomerTableRecordUpdateAttributes,
  CustomerTableResponse, EntitySearch, FieldSearch, SearchTypes
} from "@eazy2biz/common-util";
import { ErrorTypes, GenericException } from '@eazy2biz/common-package-ui';
import { createTable, updateTable } from '../services/CustomerTableService';
import {
  CustomerTableFilterConfig
} from "../components/tableLanding/recordsTable/CustomerTableFilterMenu/CustomerTableFilterMenu";
import { contentMapPath, createdDatePath } from "../constants/CustomerTableRecordsFilters";
import { getSearchTypeFromOperator } from "@eazy2biz-ui/common-package";

export const getCreateRecordRequest = (
  tableId: string,
  contentMap: {
    [key: string]: any;
  }
): CustomerTableRecordAddRequest => {
  return {
    tableId,
    headerToContentMap: contentMap,
    tags: []
  };
};

export const getUpdateRecordRequest = (contentMap: {
  [key: string]: any;
}): CustomerTableRecordUpdateAttributes => {
  return {
    headerToContentMap: contentMap,
    tags: []
  };
};

const validateTableName = (tableName: string) => {
  if (
    !tableName ||
    tableName === '' ||
    tableName.includes('Untitled') ||
    tableName.includes('untitled')
  ) {
    throw new GenericException(
      ErrorTypes.VALIDATION_ERROR,
      'Please provide a valid table name.',
      'Please provide a valid table name.'
    );
  }

  return Promise.resolve();
};

export const validateAndCreateTableRequest = async (
  title: string,
  tableHeaders: CustomerTableHeader[],
  titleHeaderId: string
) => {
  await validateTableName(title);
  return createTable({
    name: title,
    description: title,
    tags: [],
    tableHeaders,
    titleTableHeaderId: titleHeaderId
  });
};

export const validateAndUpdateTableRequest = async (
  title: string,
  customerTable: CustomerTableResponse
) => {
  await validateTableName(title);
  return updateTable(
    customerTable._id,
    {
      ...customerTable.details,
      name: title
    }
  )
};

export const getRecordsFilterFromConfig = (filterConfig: CustomerTableFilterConfig): EntitySearch => {
  const searchFilters: FieldSearch[] = [];

  filterConfig.date && searchFilters.push({
    field: createdDatePath,
    type: SearchTypes.VALUE_LESS_THAN,
    value: filterConfig.date
  });

  filterConfig.advancedFilters.forEach((filter) => {
    searchFilters.push({
      field: contentMapPath + filter.firstFieldId,
      type: getSearchTypeFromOperator(filter.operator),
      value: filter.value
    });
  });

  return new EntitySearch(searchFilters);
};
