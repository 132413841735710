import { memo } from "react";
import { Handle, Position } from "react-flow-renderer";
import { Pencil2Icon } from "@radix-ui/react-icons";
import { StageData } from "../../../entity/workflowBuilder/StageData";
import styles from "./StartNode.module.css";
import classNames from "classnames";
import FormStageIcon from "../../../../assets/formStage.svg";
import { NodeProps } from "./GenericStageNode";
import { TourType, useFeatureTour } from "@eazy2biz-ui/common-components";

const StartNode = memo((props: NodeProps) => {
    const nodeData = props.data as StageData;

    const {getRef, triggerTour, hideTour} = useFeatureTour();

    const handleNodeClick = () => {
        hideTour();
        nodeData.props.onClick && nodeData.props.onClick(nodeData.stageConfiguration._id);
    };

    const handleSecondaryClick = () => {
        nodeData.props.onSecondaryClick && nodeData.props.onSecondaryClick(nodeData.stageConfiguration._id);
    };

    const nodeWrapperClasses = classNames(
        styles.canvasStartNode,
        {
            [styles.canvasNodeHighlighted]: nodeData.props.isHighlighted,
            [styles.canvasNodeSelected]: nodeData.props.isSelected
        }
    );

    const nodeDividerClasses = classNames(
        styles.canvasStartNodeDivider,
        {
            [styles.canvasNodeHighlighted]: nodeData.props.isHighlighted,
            [styles.canvasNodeSelected]: nodeData.props.isSelected
        }
    );

    return (
        <div className={nodeWrapperClasses} onMouseEnter={() => !nodeData.props.isReadOnly && triggerTour(TourType.WORKFLOW_BUILD_START)}>
            <div onClick={handleNodeClick} className={styles.canvasStartNodeLabel} ref={getRef(0, TourType.WORKFLOW_BUILD_START)}>
                {
                    nodeData.props.isReadOnly && <img className={styles.stageIcon} src={FormStageIcon}/>
                }
                {nodeData.details.label}
            </div>
            {   !nodeData.props.isReadOnly &&
                <>
                    <div className={nodeDividerClasses}/>
                    <div className={styles.canvasStartNodeEditIcon} onClick={handleSecondaryClick} ref={getRef(1, TourType.WORKFLOW_BUILD_START)}>
                        <Pencil2Icon/>
                    </div>
                </>
            }
            <Handle
                type="source"
                position={Position.Bottom}
                id="a"
                style={{ background: '#555' }}
                isConnectable
            />
        </div>
    );
});

export default StartNode;
