import {
  CustomerTableHeader,
  CustomerTableHeaderTypes, CustomerTableHeaderValidationType,
} from "@eazy2biz/common-util";
import { HTMLInputTypeAttribute } from "react";
import { MenuData } from "@eazy2biz-ui/common-components";

export const getHTMLInputTypeFromTableHeaderType = (fieldType: CustomerTableHeaderTypes | undefined): HTMLInputTypeAttribute => {
  switch (fieldType) {
    case CustomerTableHeaderTypes.NUMBER:
    case CustomerTableHeaderTypes.PHONE_NUMBER:
      return 'number';
    case CustomerTableHeaderTypes.DATE: return 'date';
    case CustomerTableHeaderTypes.EMAIL: return 'email';
    default: return 'text';
  }
};

export const getInputResponseTypeFromHeaderType = (fieldType: CustomerTableHeaderTypes | undefined): HTMLInputTypeAttribute => {
  switch (fieldType) {
    case CustomerTableHeaderTypes.NUMBER:
      return 'number';
    default: return 'text';
  }
};

export const getDropdownMenuForCustomerTableHeader = (field: CustomerTableHeader): MenuData[] => {
  const dropdownValidation =
    field.validations.find((validation) => validation.headerValidationType = CustomerTableHeaderValidationType.VALUE_IN_ARRAY);

  return (dropdownValidation?.validationValue || [])
    .map(
      (value: string) => ({
        _id: value, name: value
      })
    );
};
