import { getEdgeCenter, getMarkerEnd, getSmoothStepPath } from "react-flow-renderer";
import { Dropdown, Menu } from "antd";
import FormStageIcon from "../../../../assets/formStage.svg";
import ApprovalStageIcon from "../../../../assets/ApprovalStage.svg";
import ConditionStageIcon from "../../../../assets/ConditionStage.svg";
import ConnectStageIcon from "../../../../assets/connectStage.svg";
import { EdgeData } from "../../../entity/workflowBuilder/EdgeData";
import styles from "./EdgeWithAddButton.module.css";
import { StageTypes } from "@eazy2biz/common-util";
import { EdgeProps } from "./EdgeProps";
import { TourType, useFeatureTour } from "@eazy2biz-ui/common-components";

const foreignObjectSize = 20;

export default function EdgeWithAddButton(props: EdgeProps) {

    const {
        id,
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
        style = {},
        data,
        arrowHeadType,
        markerEndId,
    } = props;

    const edgePath = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });

    const {getRef} = useFeatureTour();

    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={edgeCenterX - foreignObjectSize / 2}
                y={edgeCenterY - foreignObjectSize / 2 - 4 }>
                <body>
                {renderButton(id, data, getRef(2, TourType.WORKFLOW_BUILD_START))}
                </body>
            </foreignObject>
        </>
    );
}

const renderButton = (id: string, data: EdgeData, ref: any) => {
    return (
        <Dropdown overlay={menu(id, data.onClick, data.onEdgeConnectionUpdate)} trigger={['hover']} overlayStyle={{zIndex:10000}}>
            <div
                ref={ref}
                onClick={e => e.preventDefault()}
                className={styles.addStageButton}>
                +
            </div>
        </Dropdown>
    );
}

const menu = (id: string, handleClick: any, handleConnectionUpdate: any) => (
    <Menu>
        <Menu.Item onClick={(e) => handleClick(e.domEvent, id, StageTypes.FORM_STAGE)}>
            <img className={styles.stageIcon} src={FormStageIcon}/>
            <span>Form</span>
        </Menu.Item>
        <Menu.Item onClick={(e) => handleClick(e.domEvent, id, StageTypes.APPROVAL_STAGE)}>
            <img className={styles.stageIcon} src={ApprovalStageIcon}/>
            <span>Approve / Reject</span>
        </Menu.Item>
        <Menu.Item onClick={(e) => handleClick(e.domEvent, id, StageTypes.CONDITIONAL_STAGE)}>
            <img className={styles.stageIcon} src={ConditionStageIcon}/>
            <span>Conditionals</span>
        </Menu.Item>
        <Menu.Item onClick={(e) => handleConnectionUpdate(e.domEvent, id)}>
          <img className={styles.stageIcon} src={ConnectStageIcon}/>
          <span>Connect to stage</span>
        </Menu.Item>
    </Menu>
);
