import { CustomerTableHeader, Field, Operators, SearchTypes } from "@eazy2biz/common-util";
import {getHTMLInputTypeFromFieldType} from "../form/FieldHelper";
import { getHTMLInputTypeFromTableHeaderType } from "../customerTable/TableHeaderHelper";

export const getOperatorMenuForField = (fieldId: string, fields: Field[]): string => {
    const field = fields.find((f) => f._id === fieldId);
    return getHTMLInputTypeFromFieldType(field?.type);
};

export const getOperatorMenuForTableHeader = (headerId: string, headers: CustomerTableHeader[]): string => {
    const header = headers.find((f) => f._id === headerId);
    return getHTMLInputTypeFromTableHeaderType(header?.headerType);
};

export const getSearchTypeFromOperator = (operator: Operators): SearchTypes => {
    switch (operator) {
        case Operators.LESS_THAN:
            return SearchTypes.VALUE_LESS_THAN
        case Operators.LESS_THAN_EQUAL:
            return SearchTypes.VALUE_LESS_THAN_EQUAL_TO
        case Operators.GREATER_THAN:
            return SearchTypes.VALUE_GREATER_THAN
        case Operators.GREATER_THAN_EQUAL:
            return SearchTypes.VALUE_GREATER_THAN_EQUAL_TO
        case Operators.NOT_EQUAL:
            return SearchTypes.VALUE_NOT_EQUAL_TO
        default:
            return SearchTypes.VALUE_EQUAL;
    }
};
