import {WorkflowResponse} from "@eazy2biz/common-util";
import React from "react";

import {AdvancedFilterComponent, AdvancedFilterConfig} from "@eazy2biz-ui/common-components";

import {getOperatorMenuForField} from "@eazy2biz-ui/common-package";


export const ConfigureChartWorkflowFilters = (
    props : {
        entityId: string;
        workflowResponse: WorkflowResponse;

        selectedFilters : AdvancedFilterConfig[]
        setSelectedFilters: (filters : AdvancedFilterConfig[]) => void;

    }
) : JSX.Element => {

    const {
        entityId,
        workflowResponse,

        selectedFilters,
        setSelectedFilters
    } = props;


    const getFilterEligibleWorkflowFields = workflowResponse.fields;

    return (
        <>
            <AdvancedFilterComponent
                firstFieldMenuData={
                    getFilterEligibleWorkflowFields
                }
                getFieldType={(f) => getOperatorMenuForField(f.firstFieldId, getFilterEligibleWorkflowFields)}
                filters={selectedFilters}
                onChange={(filters: AdvancedFilterConfig[]) => setSelectedFilters(filters)}
                applyFilterButtonVisible={false}
            />
        </>
    )
}
