import {TodoStatus, TodoTypes, UserTodoExecutableActionResponse} from "@eazy2biz/common-util";
import {getColumnsForTodos, TodoHeaders} from "../config/TodoTableConfig";
import {
    AppliedFilterConfig, AppliedFilterItemConfig,
    FAINT_ERROR_ROW_COLOUR,
    generateTableDataFromItems,
    RowMetaData
} from "@eazy2biz-ui/common-components";
import {
    getStageNameByIdAndWorkflowId,
    getUserNameById,
    getWorkflowNameById,
    getFormattedDate,
    getUserStringByIdArray, humanizeSnakeCase
} from "@eazy2biz/common-package-ui";
import {clone, get} from "lodash";
import {TodoFilterConfig} from "../components/common/TodoTable/TodoFilterMenu/TodoFilterMenu";
import {TODO_FILTER_MENU} from "../../contents/DisplayContent";

const resolveCreatedByForTodo = (todo: UserTodoExecutableActionResponse) => {
    const lastUpdatedBy = getUserNameById(todo.metaData.lastUpdatedBy);
    if (todo.type !== TodoTypes.DEFAULT) {
        return getWorkflowNameById(lastUpdatedBy);
    }

    return getUserNameById(lastUpdatedBy);
};

const getValueForTodo = (todo: UserTodoExecutableActionResponse, key: string) => {
    const workflowId = get(todo, "actionIdentifier.workflowId", "");
    switch (key) {
        case TodoHeaders.TODO_NUMBER:
            return "#" + todo.todoNumber;
        case TodoHeaders.NAME:
            return todo.details.name
        case TodoHeaders.WORKFLOW_NAME:
            return getWorkflowNameById(workflowId, "");
        case TodoHeaders.STAGE_NAME:
            return getStageNameByIdAndWorkflowId(workflowId, get(todo, "actionIdentifier.stageId", ""));
        case TodoHeaders.DUE_ON:
            return getFormattedDate(todo.dueDate);
        case TodoHeaders.CREATED_ON:
            return getFormattedDate(todo.metaData.createdDate);
        case TodoHeaders.ASSIGNEE:
            return getUserStringByIdArray(todo.assigneeUserId, ', ', todo.assigneeUserGroupId);
        case TodoHeaders.CREATED_BY:
            return resolveCreatedByForTodo(todo);
        case TodoHeaders.STATUS:
            return humanizeSnakeCase(todo.status.toLocaleLowerCase());
        default:
            return '';
    }
};

const getRowMetaDataForTodo = (todo: UserTodoExecutableActionResponse): RowMetaData => {
    return {
        id: todo._id || '',
        highlightColour: new Date(todo.dueDate) < new Date() && todo.status === TodoStatus.PENDING? FAINT_ERROR_ROW_COLOUR : undefined
    }
};

export const getTableDataForTodos = (todos: UserTodoExecutableActionResponse[], isWorkflowTable: boolean) => {
    return generateTableDataFromItems(todos, getColumnsForTodos(isWorkflowTable), getValueForTodo, getRowMetaDataForTodo);
};

export const getAppliedFilterConfigFromTodoFilters = (filterConfig: TodoFilterConfig, setFilter: Function): AppliedFilterConfig => {
    const appliedFilters: AppliedFilterItemConfig[] = [];

    const filterConfigCopy = clone(filterConfig);

    filterConfig.pendingOnly && appliedFilters.push({
        title: TODO_FILTER_MENU.PENDING_ONLY,
        onRemove: () => {
            filterConfigCopy.pendingOnly = false;
            setFilter(filterConfigCopy);
        }
    });

    filterConfig.assigneeUserId && appliedFilters.push({
        title: getUserNameById(filterConfig.assigneeUserId)+ ' Only',
        onRemove: () => {
            filterConfigCopy.assigneeUserId = undefined;
            setFilter(filterConfigCopy);
        }
    });

    return {
      filters: appliedFilters,
    };
};
