import { ErrorTypes, GenericException, RestServices } from "@eazy2biz/common-package-ui";
import {
  TemplateCategory,
  TemplateParameter,
  TemplateResponse,
  WhatsappMessageTemplateAdditionalInfo,
  WhatsappMessageTemplateDetails
} from "@eazy2biz/common-util";
import { WhatsappTemplateConfigurationType } from "../../helpers/whatsapp/CreateWhatsappTemplateHelper";
import { getWhatsappTemplateCreateRequest } from "../../helpers/whatsapp/WhatsappTemplateServiceHelper";
import { get } from "lodash";

const TEMPLATE_SERVICE = () => RestServices.getInstance().getTemplateService();

export const getAllWhatsappTemplates = (): Promise<TemplateResponse[]> => {
  return TEMPLATE_SERVICE().getAllTemplates(TemplateCategory.WHATSAPP_MESSAGE);
}

type WhatsappTemplateConfigurationDetailsType = {
  templateName: string;
  templateDetails: WhatsappMessageTemplateDetails;
  templateParameters: TemplateParameter[];
  templateAdditionalInfo: WhatsappMessageTemplateAdditionalInfo;
}

const getTemplateConfigurationDetails = (
  whatsappTemplateConfiguration: WhatsappTemplateConfigurationType
): WhatsappTemplateConfigurationDetailsType => {
  return {
    templateName: whatsappTemplateConfiguration.name,
    templateDetails: {
      header: whatsappTemplateConfiguration.header,
      body: whatsappTemplateConfiguration.body,
      footer: whatsappTemplateConfiguration.footer,
      language: whatsappTemplateConfiguration.language,
      whatsappTemplateType: whatsappTemplateConfiguration.templateType
    },
    templateAdditionalInfo: {
      whatsappBusinessTemplateId: 0,
      whatsappBusinessTemplateName: '',
    },
    templateParameters: whatsappTemplateConfiguration.templateParameters
  }
};

const validateCreateTemplateConfiguration = (
  whatsappTemplateConfiguration: WhatsappTemplateConfigurationType
) => {
  whatsappTemplateConfiguration.templateParameters.forEach((parameter) => {
    if (get(parameter, 'details.example', '') === '') {
      throw new GenericException(ErrorTypes.VALIDATION_ERROR, 'Parameter example cannot be empty', 'Please enter example variables.');
    }
  });

  return Promise.resolve();
};

export const createNewWhatsappTemplate = async (
  whatsappTemplateConfiguration: WhatsappTemplateConfigurationType
): Promise<TemplateResponse> => {
  await validateCreateTemplateConfiguration(whatsappTemplateConfiguration);
  const {
    templateName,
    templateDetails,
    templateParameters,
    templateAdditionalInfo
  } = getTemplateConfigurationDetails(whatsappTemplateConfiguration);

  return TEMPLATE_SERVICE().createTemplate(
    TemplateCategory.WHATSAPP_MESSAGE,
    getWhatsappTemplateCreateRequest(
      templateName,
      templateDetails,
      templateParameters,
      templateAdditionalInfo
    )
  )
};

export const getWhatsappTemplateId = (templateId: string) => {
  return TEMPLATE_SERVICE().getTemplateById(TemplateCategory.WHATSAPP_MESSAGE, templateId);
};
