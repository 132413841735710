import React, { useEffect, useState } from 'react';
import { CustomerTableRecordResponse, CustomerTableResponse } from '@eazy2biz/common-util';
import { GenericButton, GenericModal, Toast } from '@eazy2biz-ui/common-components';
import {
  createRecord,
  getCustomerTableById,
  updateRecord
} from '../../services/CustomerTableService';
import styles from './RecordFormModal.module.css';
import { RECORD_FORM } from '../../../contents/DisplayContent';
import { TableRecordFormComponent } from './TableRecordFormComponent';
import { cloneDeep, set } from 'lodash';

/**
 * Customer Table Record Form.
 * @param props
 * @constructor
 */
export const RecordFormModal = (props: PropTypes) => {
  const { tableId, record, editMode = false } = props;
  const [customerTable, setCustomerTable] = useState<CustomerTableResponse>();
  const [createRecordProgress, setCreateRecordProgress] = useState<boolean>(false);
  const [contentMap, setContentMap] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    getCustomerTableById(tableId)
      .then((response: CustomerTableResponse) => {
        setCustomerTable(response);
      })
      .catch(() => {
        handleError();
      });
  }, []);

  useEffect(() => {
    const newContentMap = {};
    record && record.tableHeaderContents.forEach((col) =>
      set(newContentMap, col.header._id, col.content)
    );
    setContentMap(newContentMap);
  }, [record]);

  const handleError = (messageString?: string) => {
    Toast.errorString(messageString);
  };

  const handleCreateItem = () => {
    Toast.load(RECORD_FORM.CREATE_RECORD_PROGRESS);
    setCreateRecordProgress(true);
    createRecord(tableId, contentMap)
      .then(() => {
        Toast.success(RECORD_FORM.CREATE_RECORD_SUCCESS);
        props.onSubmit && props.onSubmit();
        setCreateRecordProgress(false);
      })
      .catch(() => {
        Toast.errorString(RECORD_FORM.CREATE_RECORD_FAILURE);
        setCreateRecordProgress(false);
      });
  };

  const handleEditItem = () => {
    if(!record) return
    setCreateRecordProgress(true);
    updateRecord(contentMap, record._id, tableId)
      .then(() => {
        Toast.success(RECORD_FORM.EDIT_RECORD_SUCCESS);
        props.onSubmit && props.onSubmit();
      })
      .catch(() => {
        Toast.errorString(RECORD_FORM.EDIT_RECORD_FAILURE);
      })
      .finally(() => {
        setCreateRecordProgress(false);
      });
  };

  const handleValueUpdate = (headerId: string, content: any) => {
    const updatedContentMap = cloneDeep(contentMap);
    updatedContentMap[headerId] = content;
    setContentMap(updatedContentMap);
  };

  const renderRecordForm = () => {
    return(
      <TableRecordFormComponent
        contentMap={contentMap}
        tableHeaders={customerTable?.tableHeaders || []}
        onValueUpdate={handleValueUpdate}
      />)
  };

  const renderSubHeader = () => {
    return customerTable?.details.description || '';
  };

  const renderModelBody = () => {
    return <div className={styles.formContent}>{renderRecordForm()}</div>;
  };

  const renderFooter = () => {
    return (
      <div className={styles.footerCtn}>
        <GenericButton
          isLoading={createRecordProgress}
          onClick={editMode ? handleEditItem : handleCreateItem}
          buttonText={editMode ? RECORD_FORM.UPDATE : RECORD_FORM.SUBMIT}
        />
      </div>
    );
  };

  const getTitle = () => {
    return (editMode ? 'Edit Record - ' : RECORD_FORM.TITLE) + customerTable?.details.name;
  };

  return (
    <GenericModal
      show
      title={getTitle()}
      subHeader={renderSubHeader}
      bodyContent={renderModelBody}
      footerContent={renderFooter}
      onClose={props.onClose}
      dialogClassName={styles.modal}
    />
  );
};

type PropTypes = {
  tableId: string;
  record?: CustomerTableRecordResponse;
  onSubmit: () => void;
  onClose: () => void;
  editMode?: boolean;
};
