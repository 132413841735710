import { FormEntity, getCurrentCompanyId, getCurrentUserId } from "@eazy2biz/common-package-ui";
import { Details, TemplateResponse, WorkflowResponse } from "@eazy2biz/common-util";
import {Elements} from "react-flow-renderer/dist/types";
import {get} from "lodash";

interface IWorkflowState {
  workflowId: string | undefined;
  workflowDetail: Details;
  stages: Elements;
  edges: Elements;
  form: FormEntity;
}

const WORKFLOW_DRAFT_KEY = 'workflowState';

export const getDraftWorkflowStateKey = (): string => {
  return `${WORKFLOW_DRAFT_KEY}/company/${getCurrentCompanyId()}/user/${getCurrentUserId()}`;
};

export const getWorkflowStateJSONForDraft = (props: IWorkflowState): string => {
    const { workflowId, workflowDetail, stages, edges, form } = props;
    const workflowState: IWorkflowState = {
        workflowId,
        workflowDetail,
        stages,
        edges,
        form
    };
    return JSON.stringify(workflowState);
};

export const getDraftWorkflowState = (workflowStateJSON: string | null) => {
    const workflowStage: IWorkflowState | null = workflowStateJSON ? JSON.parse(workflowStateJSON) : null;

  return workflowStage;
};

export const getWorkflowDraftJSONFromBlueprint = (workflow: WorkflowResponse, workflowEditMode: boolean = false): string => {
    if (!workflowEditMode) {
        workflow.details.name = 'Untitled Workflow';
    }

    return getWorkflowStateJSONForDraft({
        workflowId: (workflowEditMode)? workflow._id : undefined,
        workflowDetail: workflow.details,
        stages: get(workflow, "workflowBlueprint.stages", []),
        edges: get(workflow, "workflowBlueprint.edges", []),
        form: {
            formFields: workflow.fields,
        }
    });
};

export const getWorkflowDraftJSONFromTemplate = (template: TemplateResponse): string => {
  template.details.name = 'Untitled Workflow';

  return getWorkflowStateJSONForDraft({
    workflowId: undefined,
    workflowDetail: template.details,
    stages: get(template.templateDetails, "workflowBlueprint.stages", []),
    edges: get(template.templateDetails, "workflowBlueprint.edges", []),
    form: {
      formFields: get(template.templateDetails, "fields", []),
    }
  });
};
