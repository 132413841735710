import {Form} from 'antd';
import {
    FieldTypes, MessageDetail, TemplateResponse,
} from "@eazy2biz/common-util";
import {ChangeEventHandler, useEffect, useState} from "react";
import {cloneDeep, get, set} from "lodash";
import {
    DropdownSelect,
    DynamicPlaceholderConfig,
    InputFieldWithPlaceholder, MenuData, Spinner, TextVariableInput,
    Toast, VariableField
} from "@eazy2biz-ui/common-components";
import {ActionComponentProps} from "../interfaces/ActionComponentProps";
import {
    getAllApprovedMessageTemplates,
} from "../../../../../../services/TemplateService";
import styles from '../actionForm.module.css';

const validateMessages = {
    required: '${label} is required!'
};

/**
 * Message Action Component.
 * @param props
 * @constructor
 */
export const MessageActionComponent = (props: ActionComponentProps) => {

    const messageActionDetail = props.actionResult.resultDetails as MessageDetail;

    const convertTemplateVariablesToVariableFields = (values: object): VariableField[] => {
        return Object.keys(values).map((id) => ({
            id,
            name: id,
            value: get(values, id, ''),
        }));
    };

    const convertVariableFieldsToObject = (values: VariableField[]): object => {
        const variablesObject = {};
        values.map((variablesField) => {
            set(variablesObject, variablesField.name, variablesField.value);
        });

        return variablesObject;
    };

    const [messageTemplates, setMessageTemplates] = useState<TemplateResponse[]>([]);
    const [loading, setLoading] = useState(true);

    const [toPhoneNumbers, setToPhoneNumbers] = useState(messageActionDetail.toPhoneNumbers || []);
    const [selectedTemplate, setSelectedTemplate] = useState<TemplateResponse>();
    const [templateVariables, setTemplateVariables] = useState<VariableField[]>([]);
    // TODO: handle MESSAGE ACTION here.
        // convertTemplateVariablesToVariableFields(messageActionDetail.templateVariableMapping));

    useEffect(() => {
        getAllApprovedMessageTemplates()
            .then((templates = []) => {
                setMessageTemplates(templates);
                setSelectedTemplate(templates.find((template) => template._id === messageActionDetail.messageTemplateId));
            })
            .catch(() => Toast.errorString())
            .finally(() => setLoading(false));
    }, []);

    const placeholderConfig = new DynamicPlaceholderConfig(true, true);
    placeholderConfig.setFormFields(props.fields);

    const toMobilePlaceholderConfig = new DynamicPlaceholderConfig(true, true);
    toMobilePlaceholderConfig.setFormFields(props.fields);
    toMobilePlaceholderConfig.setFieldFilterComparator((field) => [FieldTypes.PHONE_NUMBER, FieldTypes.EMPLOYEE, FieldTypes.DATABASE].includes(field.type));

    const handleUpdateTo: ChangeEventHandler<any> = (event) => {
        setToPhoneNumbers([event.target.value]);
    };

    const handleTemplateIdChange = (id: string) => {
        const updatedTemplate = messageTemplates.find((template) => template._id === id);
        updatedTemplate && setSelectedTemplate(updatedTemplate);
        updatedTemplate && setTemplateVariables(
            updatedTemplate.parameters.map((parameter) => ({
                id: parameter.name,
                name: parameter.name,
                value: '',
            }))
        );
    };

    const handleTemplatesVariables = (variableFields: VariableField[]) => {
        setTemplateVariables(variableFields);
    };

    const handleSave = (e: any) => {
        e?.stopPropagation();
        const updatedMessageActionDetail: MessageDetail = {
            toPhoneNumbers: toPhoneNumbers,
            messageTemplateId: selectedTemplate?._id || '',
            // templateVariableMapping: convertVariableFieldsToObject(templateVariables),
            // TODO: handle MESSAGE ACTION here.
            enabled: true,
        };
        const updatedAction = cloneDeep(props.actionResult);
        updatedAction.resultDetails = updatedMessageActionDetail;
        props.onActionResultUpdate(updatedAction);
    };

    const getTemplateMenuData = (): MenuData[] => {
        return messageTemplates.map((template) => ({
            _id: template._id,
            name: template.details.name
        }));
    };

    if (loading) {
        return <Spinner />
    }

    return(
        <>
            <Form validateMessages={validateMessages}>
                <Form.Item name={['to']} rules={[{ required: true }]} className={styles.actionDetailsPlaceholderItem}>
                    <InputFieldWithPlaceholder
                        config={placeholderConfig}
                        id={'to'}
                        label={'To:'}
                        type={'text'}
                        onChange={handleUpdateTo}
                        initialValue={toPhoneNumbers[0]}
                        onBlur={handleSave}
                        required={true}
                    />
                </Form.Item>
                <Form.Item name={['templateId']} rules={[{ required: true } ]} className={styles.actionDetailsFormItem}>
                    <DropdownSelect
                        label={'Template Id:'}
                        menuData={getTemplateMenuData()}
                        selectedItem={selectedTemplate?._id || ''}
                        onItemSelect={handleTemplateIdChange}
                        onBlur={handleSave}
                    />
                </Form.Item>
                <Form.Item name={['templateVariables']} className={styles.actionDetailsFormItem}>
                    <TextVariableInput
                        key={'MESSAGE_ACTION'}
                        variableFields={templateVariables}
                        setVariableFields={handleTemplatesVariables}
                        onItemBlur={handleSave}
                    />
                </Form.Item>
            </Form>
        </>
    );
};
