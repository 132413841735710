import React, {useEffect, useState} from "react";
import {DropdownSelect, MenuData, Toast} from "../../../index";
import styles from './CustomerTableRecordSelect.module.css';
import classNames from "classnames";
import {CustomerTableRecordResponse, CustomerTableResponse} from "@eazy2biz/common-util";
import {getAllRecordsForTable, getCustomerTableById} from "../../services/CustomerTableService";

/**
 * Assignee Select Component
 * @param props
 * @constructor
 */
export const CustomerTableRecordSelect = (props: PropTypes) => {
    const {required = true, disabled} = props;

    const [table, setTable] = useState<CustomerTableResponse>();
    const [recordMenuList, setRecordMenuList] = useState<MenuData[]>([]);
    const [recordList, setRecordList] = useState<CustomerTableRecordResponse[]>([]);

    const convertRecordListToData = (records: CustomerTableRecordResponse[]): MenuData[] => {
        return records.map((record) => {
            const recordData =
                record.tableHeaderContents.find((content) => content.header._id === table?.titleTableHeaderId);
            return ({
                _id: record._id.toString(),
                name: props.getRecordLabel ? props.getRecordLabel(record) : recordData?.content?.toString(),
                tagTitle: recordData?.content?.toString(),
            });
        });
    };

    useEffect( () => {
        getCustomerTableById(props.tableId).then((response) => setTable(response));
        getAllRecordsForTable(props.tableId)
            .then((response) => {
                setRecordList(response);
            }).catch(() => {
            Toast.errorString();
        });
    }, []);

    useEffect(() => {
        recordList.length && table && setRecordMenuList(convertRecordListToData(recordList));
    }, [recordList, table]);

    const handleRecordChange = (recordId?: string) => {
        props.onRecordSelect(recordList.find((record) => record._id === recordId));
    };

    const renderRecordDropdown = () => {
        return (
            <DropdownSelect
                placeholder={"Select Record"}
                onItemSelect={handleRecordChange}
                menuData={recordMenuList}
                disabled={disabled}
                selectedItem={props.selectedRecordId || ''}
                className={styles.recordDropdownSelect}
                rowClassName={styles.recordDropdownRow}
            />
        );
    };

    const renderLabel = () => {
        return(<span className={classNames(styles.tableTitle, {[styles.tableRequired]: required})}>{props.label}</span>);
    };

    return (
        <>
            {props.label && renderLabel()}
            <div className={styles.tableDropdown}>
                {renderRecordDropdown()}
            </div>
        </>
    );

};

type PropTypes = {
    label?: string;
    tableId: string;
    onRecordSelect: (record?: CustomerTableRecordResponse) => void;
    selectedRecordId?: string;
    required?: boolean;
    disabled?: boolean;
    getRecordLabel?: (record: CustomerTableRecordResponse) => string;
};
