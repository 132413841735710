import {validateWorkflow, WorkflowCreateRequest} from "@eazy2biz/common-util";
import { GenericException, ErrorTypes } from "@eazy2biz/common-package-ui";

export const validateCreateWorkflowPayload = (createRequest: WorkflowCreateRequest) => {
    try {
        validateWorkflow(createRequest);
    } catch (e: any) {
        throw new GenericException(ErrorTypes.VALIDATION_ERROR, e.message, e.message, e);
    }
}
