import {AppliedFilterConfig, AppliedFilterItemConfig} from "../interfaces/AppliedFilterConfig";
import styles from './AppliedFilterComponent.module.css';
import GenericTag from "../../GenericTag/GenericTag";
import {GenericTagTypes} from "../../GenericTag/GenericTagTypes";

const MAX_FILTER_TAGS = 4;

/**
 * *
 * @param props
 * @constructor
 */
export const AppliedFilterComponent = (props: PropTypes) => {
    const {appliedFilterConfig} = props;
    if (!appliedFilterConfig || !props.onClearFilters) {
        return null;
    }

    const renderCustomFilterTags = (filterItemConfig: AppliedFilterItemConfig, index: number) => {
        if (index >= MAX_FILTER_TAGS) {
            return null;
        }

        return (
            <GenericTag
                key={index}
                title={filterItemConfig.title}
                onRemove={filterItemConfig.onRemove}
                type={GenericTagTypes.PENDING}
            />
        );
    };

    return (
        <div className={styles.appliedFiltersWrapper}>
            {appliedFilterConfig.filters.length > 1 && <GenericTag title={"Clear all filters"} onRemove={props.onClearFilters}/>}
            {appliedFilterConfig.filters.map(renderCustomFilterTags)}
        </div>
    );
};

type PropTypes = {
    appliedFilterConfig?: AppliedFilterConfig;
    onClearFilters?: () => void;
};
