import { BaseApplicationInit, TokenHelper } from "@eazy2biz/common-package-ui";
import { getCookieService, getEnvironmentConfig } from "./ApplicationContext";
import {
    getAuthTokens,
    handleTokenExpire,
    hasTokenExpired,
    refreshTokenCallback
} from "../Authorization/AuthTokenHelper";
import { GlobalUserCompanyMembership, GlobalUserResponse } from "@eazy2biz/common-util";
import { CookieKeys } from "../cookies/CookieKeys";

/**
 * Core UI Application init.
 */
export class UIApplicationInit extends BaseApplicationInit {
    private static INSTANCE: UIApplicationInit;

    private constructor() {
        super(getEnvironmentConfig(), new TokenHelper(hasTokenExpired, refreshTokenCallback, handleTokenExpire, getAuthTokens));
    }

    public static getInstance = (): UIApplicationInit => {
        if (!this.INSTANCE) {
            this.INSTANCE = new UIApplicationInit();
        }

        return this.INSTANCE;
    };

    postCompanySignIn = (globalUserCompanyMembership: GlobalUserCompanyMembership) => {
        getCookieService().setToCookies(CookieKeys.COMPANY_ID, globalUserCompanyMembership.companyId);
        getCookieService().setToCookies(CookieKeys.LOCAL_USER_ID, globalUserCompanyMembership.localUserId);

        return this.appInitPostCompanySelection(
            globalUserCompanyMembership.localUserId,
            globalUserCompanyMembership.companyId
        );
    }

    postCompanySignInFromSession = () => {
        return this.appInitPostCompanySelection(
            getCookieService().getFromCookies(CookieKeys.LOCAL_USER_ID),
            getCookieService().getFromCookies(CookieKeys.COMPANY_ID)
        );
    };

    postGlobalUserSignIn = (globalUser: GlobalUserResponse, idToken: string, accessToken: string, refreshToken: string) => {
        getCookieService().setToCookies(CookieKeys.ID_TOKEN, idToken);
        getCookieService().setToCookies(CookieKeys.REFRESH_TOKEN, refreshToken);
        getCookieService().setToCookies(CookieKeys.ACCESS_TOKEN, accessToken, {maxAge: getEnvironmentConfig().cacheExpiry.softExpiry});

        return this.appInitPostGlobalUserSign(idToken, accessToken, globalUser, refreshToken);
    };

    postGlobalUserSignedInFromSession = () => {
        return this.appInitPostGlobalUserSign(
            getCookieService().getFromCookies(CookieKeys.ID_TOKEN),
            getCookieService().getFromCookies(CookieKeys.ACCESS_TOKEN),
            undefined,
            getCookieService().getFromCookies(CookieKeys.REFRESH_TOKEN)
        );
    };

    postSignOutAppInit = () => {
        getCookieService().removeFromCookies(CookieKeys.ID_TOKEN);
        getCookieService().removeAllCookies();
        return this.postSignOut();
    };

}
