import {EntitySearch, FieldSearch, ReportEntityType} from "@eazy2biz/common-util";
import React, {useState} from "react";
import {ConfigureChartWorkflowFilters} from "./workflow/ConfigureChartWorkflowFilters";

import styles from './ConfigureChartFilters.module.css'
import {AdvancedFilterConfig, ToggleButton} from "@eazy2biz-ui/common-components";
import {getCardFiltersFromAdvancedFilters} from "@eazy2biz-ui/workflow-ui/src/js/services/helpers/CardServiceHelper";

export const ConfigureChartFilters = (
    props : {
        entityType: ReportEntityType;
        entityId: string;
        entity: any;
        handleChartFiltersChange: (filters : EntitySearch) => void;
    }
) : JSX.Element => {

    const {
        entityType,
        entityId,
        entity,
        handleChartFiltersChange
    } = props;

    const [selectedFilters, setSelectedFilters] = useState<AdvancedFilterConfig[]>([]);

    const [filterConfigurationVisible, setFilterConfigurationVisible] = useState<boolean>(false);

    const handleFilterChange = (fieldSearch : FieldSearch[]) => {
        handleChartFiltersChange(
            new EntitySearch(fieldSearch)
        )
    }

    const getChartFiltersComponent = () : React.ReactNode => {
        switch (entityType) {
            case ReportEntityType.WORKFLOW:
                return (
                    <ConfigureChartWorkflowFilters
                        entityId={entityId}
                        workflowResponse={entity}

                        selectedFilters={selectedFilters}
                        setSelectedFilters={(filters : AdvancedFilterConfig[]) => {
                            setSelectedFilters(filters)
                            handleFilterChange(
                                getCardFiltersFromAdvancedFilters(filters)
                            )
                        }}
                    />
                );
        }
    }

    return (
        <div className={styles.configureFiltersBox}>

            <ToggleButton
                label="Add Filters"
                value={filterConfigurationVisible}
                wrapperClassName={styles.addFiltersToggleWrapper}
                className={styles.addFiltersToggle}
                onToggle={
                    () => {
                        const newFilterConfiguration = !filterConfigurationVisible
                        setFilterConfigurationVisible(newFilterConfiguration)

                        if(!newFilterConfiguration) {
                            handleFilterChange([])
                        }
                    }
                }
            />
            {
                filterConfigurationVisible && (
                    <div className={styles.chartFiltersComponent}>
                        {
                            getChartFiltersComponent()
                        }
                    </div>
                )
            }

        </div>
    )
}
