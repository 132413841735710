import { Form } from "antd";
import {
    EmailAttachment,
    EmailAttachmentType,
    EmailDetails,
    FieldTypes,
    PersistedEmailAttachment
} from "@eazy2biz/common-util";
import { ChangeEventHandler, useState } from "react";
import { resolveFeatureFlag } from "@eazy2biz-ui/common-package";
import { cloneDeep } from "lodash";
import { DynamicPlaceholderConfig, InputField, InputFieldWithPlaceholder } from "@eazy2biz-ui/common-components";
import styles from "../actionForm.module.css";
import { ActionComponentProps } from "../interfaces/ActionComponentProps";
import { convertArrayToSeparatedString, convertSeparatedStringToArray } from "@eazy2biz/common-package-ui";

const SEPARATOR = ';';

const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};

export const SendEmail = (props: ActionComponentProps) => {

    const emailDetail = props.actionResult.resultDetails as EmailDetails;

    const [to, setTo] = useState(emailDetail.to);
    const [from, setFrom] = useState(emailDetail.from);
    const [cc, setCC] = useState(emailDetail.cc);
    const [bcc, setBCC] = useState(emailDetail.bcc);
    const [subject, setSubject] = useState(emailDetail.subject);
    const [body, setBody] = useState(emailDetail.body);
    const [attachments, setAttachments] = useState<EmailAttachment[]>(emailDetail.attachments || []);

    const placeholderConfig = new DynamicPlaceholderConfig(true, true);
    placeholderConfig.setFormFields(props.fields);
    placeholderConfig.databaseFieldsEnabled = true;
    placeholderConfig.stages = props.stages;

    const toEmailPlaceholderConfig = new DynamicPlaceholderConfig(true, true);
    toEmailPlaceholderConfig.setFormFields(props.fields);
    toEmailPlaceholderConfig.setUserProperties({email: 'email'});
    toEmailPlaceholderConfig.setFieldFilterComparator((field) => [FieldTypes.EMAIL, FieldTypes.DATABASE, FieldTypes.EMPLOYEE].includes(field.type));

    const attachmentPlaceholderConfig = new DynamicPlaceholderConfig(false, true);
    attachmentPlaceholderConfig.setFormFields(props.fields);
    attachmentPlaceholderConfig.setFieldFilterComparator((field) => field.type === FieldTypes.FILE);

    const handleUpdateFrom: ChangeEventHandler<any> = (event) => {
        setFrom(event.target.value);
    };

    const getEmailValueArray = (value: any): string[] => {
        return value && value !== "" ? convertSeparatedStringToArray(value, SEPARATOR) : [];
    };

    const handleUpdateTo: ChangeEventHandler<any> = (event) => {
        setTo(getEmailValueArray(event.target.value));
    };

    const handleUpdateCC: ChangeEventHandler<any> = (event) => {
        setCC(getEmailValueArray(event.target.value));
    };

    const handleUpdateBCC: ChangeEventHandler<any> = (event) => {
        setBCC(getEmailValueArray(event.target.value));
    };

    const handleUpdateSubject: ChangeEventHandler<any> = (event) => {
        setSubject(event.target.value);
    };

    const handleUpdateBody: ChangeEventHandler<any> = (event) => {
        setBody(event.target.value);
    };

    const handleUpdateAttachments: ChangeEventHandler<any> = (event) => {
        // @ts-ignore
        setAttachments(convertSeparatedStringToArray(event.target.value, SEPARATOR).map(value => ({
            attachmentType: EmailAttachmentType.PERSISTED,
              attachmentDetails: {
                attachmentIds: value,
            }
        })));
    };

    const getPlaceholderTextFromAttachment = (attachments: EmailAttachment[]): string => {
        // @ts-ignore
        return attachments.length ? convertArrayToSeparatedString(attachments.map((attachment) => (attachment.attachmentDetails as PersistedEmailAttachment).attachmentIds), SEPARATOR) : '';
    };

    const handleSave = (e: any) => {
        e.stopPropagation();
        const updatedEmailDetail: EmailDetails = {
            to,
            cc,
            bcc,
            from,
            subject,
            body,
            attachments,
            enabled: true
        };
        const updatedAction = cloneDeep(props.actionResult);
        updatedAction.resultDetails = updatedEmailDetail;
        props.onActionResultUpdate(updatedAction);
    };

    return(
        <>
            <Form validateMessages={validateMessages}>
                {
                    resolveFeatureFlag('EDITABLE_EMAIL_ACTION_FROM') &&
                    <Form.Item name={['from', 'email']} rules={[{ required: true, type: 'email' }]} className={styles.actionDetailsFormItem}>
                        <InputField id={'from'} label={'From:'} type={'email'} onChange={handleUpdateFrom} initialValue={from} onBlur={handleSave}/>
                    </Form.Item>
                }
                <Form.Item name={['to', 'email']} rules={[{ required: true } ]} className={styles.emailToFormItem}>
                    <InputFieldWithPlaceholder
                        config={toEmailPlaceholderConfig}
                        id={'to'}
                        label={'To:'}
                        type={'email'}
                        onChange={handleUpdateTo}
                        initialValue={convertArrayToSeparatedString(to, SEPARATOR)}
                        onBlur={handleSave} />
                    <p className={styles.multipleValueNote}>Enter `;` separated emails</p>
                </Form.Item>
                <Form.Item name={['cc', 'email']} rules={[{ type: 'email' }]} className={styles.actionDetailsFormItem}>
                    <InputFieldWithPlaceholder
                      config={toEmailPlaceholderConfig}
                      id={'cc'}
                      label={'CC:'}
                      type={'email'}
                      onChange={handleUpdateCC}
                      initialValue={convertArrayToSeparatedString(cc, SEPARATOR)}
                      required={false}
                      onBlur={handleSave} />
                    <p className={styles.multipleValueNote}>Enter `;` separated emails</p>
                </Form.Item>
                <Form.Item name={['bcc', 'email']} rules={[{ type: 'email' }]} className={styles.actionDetailsFormItem}>
                    <InputFieldWithPlaceholder
                      config={toEmailPlaceholderConfig}
                      id={'bcc'}
                      label={'BCC:'}
                      type={'email'}
                      onChange={handleUpdateBCC}
                      initialValue={convertArrayToSeparatedString(bcc, SEPARATOR)}
                      required={false}
                      onBlur={handleSave} />
                    <p className={styles.multipleValueNote}>Enter `;` separated emails</p>
                </Form.Item>
                <Form.Item name={['user', 'subject']} className={styles.actionDetailsFormItem}>
                    <InputFieldWithPlaceholder
                        config={placeholderConfig}
                        id={'subject'}
                        label={'Subject:'}
                        type={'text'}
                        onChange={handleUpdateSubject}
                        initialValue={subject}
                        onBlur={handleSave}
                        required={false}
                    />
                </Form.Item>
                <Form.Item name={['user', 'message']} className={styles.actionDetailsFormItem}>
                    <InputFieldWithPlaceholder
                        config={placeholderConfig}
                        id={'message'}
                        label={'Message:'}
                        type={'textarea'}
                        onChange={handleUpdateBody}
                        initialValue={body}
                        onBlur={handleSave}
                        required={false}
                    />
                </Form.Item>
                <Form.Item name={['attachments', 'attachments']} className={styles.actionDetailsFormItem}>
                    <InputFieldWithPlaceholder
                      config={attachmentPlaceholderConfig}
                      id={'attachments'}
                      label={'Attachments:'}
                      type={'textarea'}
                      onChange={handleUpdateAttachments}
                      initialValue={getPlaceholderTextFromAttachment(attachments)}
                      onBlur={handleSave}
                      required={false}
                    />
                    <p className={styles.multipleValueNote}>Enter `;` separated values</p>
                </Form.Item>
            </Form>
        </>
    );
}
