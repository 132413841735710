import React from "react";
import {
    addLocalUserToUserGroup,
    addUserGroupToUserGroup,
    removeLocalUserFromUserGroup,
    removeUserGroupFromUserGroup
} from "../services/UserGroupService";
import {Drawer} from "antd";
import {Cross2Icon, PlusIcon} from "@radix-ui/react-icons";
import {
    ADD_BUTTON_TEXT,
    ADD_USER_BUTTON_TEXT,
    CANCEL_BUTTON_TEXT,
    ERROR_STRINGS,
    MANAGE_MEMBERSHIP_TITLE,
    SUCCESS_STRINGS
} from "../../contents/DisplayContent";
import Title from "antd/lib/typography/Title";
import {
    AssigneeSelect,
    CloseButton,
    GenericButton,
    GenericButtonTypes,
    GenericTable,
    Toast
} from "@eazy2biz-ui/common-components";
import styles from "./UserAndUserGroup.module.css";
import {getColumnsForUserGroupMembership, getTableDataForUserGroupsMembership} from "../helpers/UserGroupsHelper";
import {
    CachedStore,
    ErrorTypes,
    GenericException,
    StoreEntityTypes,
    UserAuthorizationService, UserGroupActions
} from "@eazy2biz/common-package-ui";
import {LocalUserResponse, SecurityGroupFeatures, UserGroupResponse} from "@eazy2biz/common-util";

interface ManageMembershipDrawerProps {
    onClose: () => void;
    visible: boolean;
    userGroup: UserGroupResponse;
}

export enum UserGroupMemberType {
    LOCAL_USER = "User",
    USER_GROUP = "User Group"
}

export interface UserGroupMember {
    memberDetails: LocalUserResponse | UserGroupResponse;
    type: UserGroupMemberType;
}

export const ManageMembershipDrawerComponent = (props: ManageMembershipDrawerProps) => {
    const { visible, onClose, userGroup: userGroupOpened } = props;
    const [userGroup, updateUserGroup] = React.useState<UserGroupResponse>(userGroupOpened);
    const [showSearchBar, toggleSearchBarState] = React.useState<boolean>(false);
    const [localUserId, setLocalUserId] = React.useState<string>();
    const [userGroupId, setUserGroupId] = React.useState<string>();
    const [localUserMembers, setLocalUserMembers] = React.useState<LocalUserResponse[]>([]);
    const [userGroupMembers, setUserGroupMembers] = React.useState<UserGroupResponse[]>([]);
    const [membershipType, setMembershipType] = React.useState<string>();
    const [data, setData] = React.useState<UserGroupMember[]>();
    const [refresh, setRefresh] = React.useState<boolean>(false);
    const hasPermissionToEditUGs = React.useRef<boolean>(false);

    React.useEffect(() => {
        updateUserGroup(userGroupOpened);
        setRefresh(true);
    }, [userGroupOpened]);

    React.useEffect(()=>{
        const userAuthorizationService = UserAuthorizationService.getInstance();
        // TODO: FIX SecurityGroupFeatures
        userAuthorizationService.isActionAllowed(UserGroupActions.EDIT, SecurityGroupFeatures.USERS)
            .then(hasPermission => {
                hasPermissionToEditUGs.current = hasPermission;
            });
    }, []);

    React.useEffect(() => {
        if (refresh) {
            CachedStore.getEntityFromListById<UserGroupResponse>(StoreEntityTypes.USER_GROUPS, userGroup._id, refresh)
                .then((userGroupResponse : UserGroupResponse) => {
                    updateUserGroup(userGroupResponse);
                    setRefresh(false);
                });
        }
    }, [refresh]);

    const fetchLocalUsersDetails = async (localUsersIds: string[]): Promise<LocalUserResponse[]> => {
        return (
            await Promise.all(
                localUsersIds
                    .map((id: string) =>
                        CachedStore.getEntityFromListById<LocalUserResponse>(StoreEntityTypes.LOCAL_USERS, id)
                    )
            )
        )
    }

    const fetchUserGroupsDetails = async (userGroupIds: string[]): Promise<UserGroupResponse[]> => {
        return (
            await Promise.all(
                userGroupIds
                    .map((id: string) =>
                        CachedStore.getEntityFromListById<UserGroupResponse>(StoreEntityTypes.USER_GROUPS, id)
                    )
            )
        )
    }


    React.useEffect(() => {
        let members: UserGroupMember[] = [];

        if (localUserMembers) {
            members = members.concat(
                localUserMembers.map(localUserResponse => {
                    return {
                        memberDetails: localUserResponse,
                        type: UserGroupMemberType.LOCAL_USER
                    }
                }));
        }

        if (userGroupMembers) {
            members = members.concat(
                userGroupMembers.map(userGroupMembers => {
                    return {
                        memberDetails: userGroupMembers,
                        type: UserGroupMemberType.USER_GROUP
                    }
                }));
        }
        setData(members);
    }, [userGroupMembers, localUserMembers]);

    React.useEffect(() => {
        const { localUsers, childrenUserGroups } = userGroup;
        fetchLocalUsersDetails(localUsers).then((localUsersDetails) => setLocalUserMembers(localUsersDetails));
        fetchUserGroupsDetails(childrenUserGroups).then((userGroupsDetails) => setUserGroupMembers(userGroupsDetails));
    }, [userGroup]);

    const filterUser = (localUser: LocalUserResponse) => {
        return !userGroup.localUsers.includes(localUser._id);
    };

    const filterUserGroup = (group: UserGroupResponse) => {
        return !userGroup.childrenUserGroups.includes(group._id) && userGroup._id !== group._id;
    };

    const removeMembership = (id: string) => {
        if (data) {
            const member: UserGroupMember | undefined = data.find((userGroupMember : UserGroupMember) => userGroupMember.memberDetails._id === id);
            if (member) {
                if (member.type === UserGroupMemberType.LOCAL_USER) {
                    removeLocalUserFromUserGroup(member.memberDetails._id, userGroup._id).then((response) => {
                        setRefresh(true);
                        Toast.success(SUCCESS_STRINGS.DELETE_MEMBER_SUCCESS);
                    }).catch(() => {
                        Toast.error(new GenericException(ErrorTypes.API_ERROR, '', ERROR_STRINGS.DELETE_MEMBER_FAILURE), ERROR_STRINGS.DELETE_MEMBER_FAILURE);
                    });
                } else if (member.type === UserGroupMemberType.USER_GROUP) {
                    removeUserGroupFromUserGroup(member.memberDetails._id, userGroup._id).then((response) => {
                        setRefresh(true);
                        Toast.success(SUCCESS_STRINGS.DELETE_MEMBER_SUCCESS);
                    }).catch(() => {
                        Toast.error(new GenericException(ErrorTypes.API_ERROR, '', ERROR_STRINGS.DELETE_MEMBER_FAILURE), ERROR_STRINGS.DELETE_MEMBER_FAILURE);
                    });
                }
            }
        }
    }

    const addMembership = () => {
        if (membershipType === UserGroupMemberType.LOCAL_USER) {
            if (localUserId && userGroup) {
                addLocalUserToUserGroup(localUserId, userGroup._id).then((response) => {
                    setRefresh(true);
                    Toast.success(SUCCESS_STRINGS.ADD_NEW_MEMBER_SUCCESS);
                }).catch(() => {
                    Toast.error(new GenericException(ErrorTypes.API_ERROR, '', ERROR_STRINGS.ADD_NEW_MEMBER_FAILURE), ERROR_STRINGS.ADD_NEW_MEMBER_FAILURE);
                });
            }
        } else if (membershipType === UserGroupMemberType.USER_GROUP) {
            if (userGroupId && userGroup) {
                addUserGroupToUserGroup(userGroupId, userGroup._id).then((response) => {
                    setRefresh(true);
                    Toast.success(SUCCESS_STRINGS.ADD_NEW_MEMBER_SUCCESS);
                }).catch(() => {
                    Toast.error(new GenericException(ErrorTypes.API_ERROR, '', ERROR_STRINGS.ADD_NEW_MEMBER_FAILURE), ERROR_STRINGS.ADD_NEW_MEMBER_FAILURE);
                });
            }
        }
    }

    const handleSelection = (ids: string[], userGroupIds?: string[]) => {
        if (ids.length) {
            setLocalUserId(ids[0]);
            setMembershipType(UserGroupMemberType.LOCAL_USER);
        } else if (userGroupIds?.length) {
            setUserGroupId(userGroupIds[0]);
            setMembershipType(UserGroupMemberType.USER_GROUP);
        }
    };

    return (
        <Drawer
            width={600}
            closeIcon={<CloseButton onClose={() => {}}/>}
            title={MANAGE_MEMBERSHIP_TITLE}
            placement="right"
            onClose={onClose}
            visible={visible}>
            <Title level={5}>Manage membership for "{userGroup?.details?.name}"</Title>
            { !showSearchBar && hasPermissionToEditUGs.current  && <GenericButton onClick={() => toggleSearchBarState(true)} buttonText={ADD_USER_BUTTON_TEXT} />}
            { showSearchBar ?
                <div className={styles.searchBar}>
                    <AssigneeSelect
                        className={styles.userSelectDropdownWrapper}
                        onAssigneesSelect={handleSelection}
                        selectedAssignees={[]}
                        userGroups
                        label={'Select User / User Group'}
                        filterUser={filterUser}
                        filterUserGroup={filterUserGroup}
                        multiSelect={false} />
                    <GenericButton onClick={addMembership} buttonText={ADD_BUTTON_TEXT} CustomIcon={PlusIcon} />
                    <GenericButton
                        type={GenericButtonTypes.SECONDARY}
                        onClick={() => toggleSearchBarState(false)}
                        buttonText={CANCEL_BUTTON_TEXT}
                        CustomIcon={Cross2Icon} />
                </div>
                : null
            }
            <GenericTable
                onRowDeleteClick={hasPermissionToEditUGs.current? removeMembership : undefined}
                columns={getColumnsForUserGroupMembership()}
                data={getTableDataForUserGroupsMembership(data ?? [])} />
        </Drawer>
    )
}
