import {
    GenericButton,
    GenericImageButton,
    GenericModal,
    ModalSubHeaderComponent,
} from "@eazy2biz-ui/common-components";
import styles from "./AddChartModal.module.css";
import React, {useState} from "react";
import {
    ChartCreateRequest,
    ChartResponse,
    ChartType,
    EntityDefinitionTypes,
    EntitySearch,
    ReportEntityType,
    ReportResponse
} from "@eazy2biz/common-util";
import {chartContent, displayNameForChart} from "../../../../contents/DisplayContent";
import {getImageForChartType} from "../../../helpers/ChartHelper";
import {ConfigureChart} from "./ConfigureChart";
import {
    checkValidCreateChartForm,
    convertCreateChartFormToChartPreviewForm,
    CreateChartForm,
    PreviewChartForm
} from "./CreateChartHelper";
import {createChart} from "../../../services/ChartsService";
import {StrictBuilder} from "builder-pattern";

export const AddChartModal = (
    props: {
        selectedReport : ReportResponse,
        handleChartModalClosure : () => void,
        handleOnChartSuccessfulCreate: (createdChart : ChartResponse) => void
        entityType: ReportEntityType;
        entityId: string;
    }
): JSX.Element => {

    const {
        selectedReport,
        handleChartModalClosure,
        handleOnChartSuccessfulCreate,
        entityType,
        entityId
    } = props;

    const [chartCreateStage, setChartCreateState] = useState<number>(0);

    const [selectedChartType, setSelectedChartType] = useState<ChartType | null>(null);

    const [showChartConfigurationWizard, setShowChartConfigurationWizard] = useState<boolean>(false);

    const [createChartInProgress, setCreateChartInProgress] = useState<boolean>(false);

    const [showFieldErrors, setShowFieldErrors] = useState(false)

    const handleOnConfigureChartButton = () => {
        setShowChartConfigurationWizard(true);
    }

    const [createChartForm, setCreateChartForm] = useState<CreateChartForm>({
        chartDurationSameAsReportDuration: true,
        defaultChartDurationInDays : 0,
        defaultChartDurationInMonths : 0,
        filters: new EntitySearch([]),
    })

    const handleOnCreateChartButton = () => {

        if(selectedChartType && checkValidCreateChartForm(createChartForm, selectedChartType, selectedReport)) {
            setCreateChartInProgress(true)

            const previewChartForm : PreviewChartForm =
                convertCreateChartFormToChartPreviewForm(createChartForm, selectedChartType, selectedReport);

            createChart(
                StrictBuilder<ChartCreateRequest>()
                    .name(previewChartForm.name)
                    .description(previewChartForm.description)
                    .reportId(selectedReport._id)
                    .definitionType(EntityDefinitionTypes.USER_DEFINED)
                    .defaultChartDuration(previewChartForm.defaultChartDuration)
                    .durationDateField(previewChartForm.durationDateField)
                    .type(selectedChartType)
                    .filters(previewChartForm.filters)
                    .chartDetails(previewChartForm.chartDetails)
                    .tags([])
                    .build()
            )
                .then(
                    (chartResponse : ChartResponse) => {
                        setCreateChartInProgress(false)
                        handleOnChartSuccessfulCreate(chartResponse)
                    }
                )
        }
        else {
            setShowFieldErrors(true);
        }
    }

    const chartSelectContent = () => {
        return (
            <div className={styles.chartTypeList}>
                {
                    (
                        [
                            ChartType.LINE,
                            ChartType.AREA,
                            ChartType.BAR,
                            ChartType.PIE,
                            ChartType.TABLE,
                        ] as ChartType[]
                    ).map((chartType : ChartType) => (
                        <GenericImageButton
                            onClick={
                                () =>
                                    setSelectedChartType(
                                        selectedChartType && selectedChartType === chartType
                                            ? null
                                            : chartType
                                    )
                            }
                            buttonText={`${displayNameForChart[chartType]} Chart`}
                            buttonImageSrc={getImageForChartType(chartType)}
                            className={styles.chartTypeBox}
                            buttonTextClassName={styles.chartTypeButtonText}
                            showSelected={chartType === selectedChartType}
                        />
                    ))
                }
            </div>
        );
    }

    const chartConfigureWizardContent = (selectedChartType : ChartType) => {
        return (
            <ConfigureChart
                selectedReport={selectedReport}
                chartType={selectedChartType}
                entityType={entityType}
                entityId={entityId}
                showChartConfigureFormFieldErrors={showFieldErrors}
                createChartForm={createChartForm}
                setCreateChartForm={setCreateChartForm}
            />
        );
    }

    return (
        <GenericModal
            dialogClassName={!showChartConfigurationWizard ? styles.selectChartDialog : styles.configureChartDialog}
            show
            title={chartContent.CREATE_CHART}
            subHeader={
                () =>
                    <ModalSubHeaderComponent
                        title={
                            !showChartConfigurationWizard
                                ? chartContent.SELECT_CHART_TYPE
                                : `Configure new ${selectedChartType ? displayNameForChart[selectedChartType] : ""} chart`
                        }
                    />
            }
            bodyContent={
                () =>
                    showChartConfigurationWizard && selectedChartType
                        ? chartConfigureWizardContent(selectedChartType)
                        : chartSelectContent()
            }
            footerContent={
                () => (
                    <div className={styles.footerCtn}>

                        {
                            !showChartConfigurationWizard
                                ? (
                                    <GenericButton
                                        onClick={() => handleOnConfigureChartButton()}
                                        buttonText={chartContent.CONFIGURE_CHART}
                                        disabled={selectedChartType === null}
                                    />
                                )
                                : (
                                    <GenericButton
                                        isLoading={createChartInProgress}
                                        onClick={() => handleOnCreateChartButton()}
                                        buttonText={chartContent.CREATE_CHART}
                                    />
                                )
                        }
                    </div>
                )
            }
            onClose={() => {
                handleChartModalClosure()
            }}
        />
    )
}
