import {
    AdvancedFilterComponent,
    AdvancedFilterConfig, GenericButton, GenericButtonTypes,
    InputField, InputFieldType,
    ToggleButton
} from "@eazy2biz-ui/common-components";
import {cloneDeep} from "lodash";
import { TABLE_FILTER_MENU } from "../../../../../contents/DisplayContent";
import { useState } from "react";
import { getOperatorMenuForTableHeader } from "@eazy2biz-ui/common-package";
import { CustomerTableResponse } from "@eazy2biz/common-util";
import { Badge, Divider } from "antd";
import classNames from "classnames";
import { ITEM_FILTER_MENU } from "@eazy2biz-ui/workflow-ui/src/contents/DisplayContent";
import { MixerHorizontalIcon } from "@radix-ui/react-icons";
import styles from './CustomerTableFilterMenu.module.css';

/**
 * Customer Table Filter Menu
 * @param props
 * @constructor
 */
export const CustomerTableFilterMenu = (props: PropTypes) => {
    const {filterConfig} = props;

    const [advancedFilterVisible, setAdvancedFilterVisible] = useState(false);

    const onCreatedDateChange = (e: any) => {
        const filter = cloneDeep(filterConfig);
        filter.date = e.target.value;
        props.onFilterChange(filter);
    };

    const onToggleActiveOnly = () => {
        const filter = cloneDeep(filterConfig);
        filter.archivedOnly = !filter.archivedOnly;
        props.onFilterChange(filter);
    };

    const handleAdvancedFilterChange = (filters: AdvancedFilterConfig[]) => {
        const filter = cloneDeep(filterConfig);
        filter.advancedFilters = filters;
        props.onFilterChange(filter);
        handleAdvancedFilterToggle();
        props.onClose();
    };

    const handleAdvancedFilterToggle = () => {
        setAdvancedFilterVisible(!advancedFilterVisible);
    };

    const renderAdvancedFilters = () => {
        return (
          <AdvancedFilterComponent
            type={InputFieldType.CUSTOMER_TABLE_HEADER}
            toggleAdvancedFilter={handleAdvancedFilterToggle}
            firstFieldMenuData={props.customerTable.tableHeaders}
            filters={filterConfig.advancedFilters}
            onChange={handleAdvancedFilterChange}
            getFieldType={(f) => getOperatorMenuForTableHeader(f.firstFieldId, props.customerTable.tableHeaders)}
            applyFilterButtonVisible
          />
        );
    };

    if (advancedFilterVisible) {
        return renderAdvancedFilters();
    }

    return(
        <div>
            <InputField
                label={TABLE_FILTER_MENU.CREATED_DATE_BEFORE}
                onChange={onCreatedDateChange}
                id='dueBefore'
                required={false}
                type='date'/>
            <ToggleButton value={filterConfig.archivedOnly} onToggle={onToggleActiveOnly} label={TABLE_FILTER_MENU.ARCHIVED_ONLY}/>
            <Divider />
            <GenericButton
              className={classNames(styles.advancedFilterButton)}
              buttonText={
                  <Badge
                    offset={[2, 3]}
                    dot
                    count={filterConfig.advancedFilters.length}
                    status={'default'}>
                      <div>{ITEM_FILTER_MENU.ADVANCED_FILTER}</div>
                  </Badge>
              }
              type={GenericButtonTypes.SECONDARY}
              CustomIcon={MixerHorizontalIcon}
              onClick={handleAdvancedFilterToggle} />
        </div>
    );
};

type PropTypes = {
    customerTable: CustomerTableResponse;
    onFilterChange: (config: CustomerTableFilterConfig) => void;
    filterConfig: CustomerTableFilterConfig;
    onClose: () => void;
};

export class CustomerTableFilterConfig {
    date?: Date;
    archivedOnly: boolean = false;
    advancedFilters: AdvancedFilterConfig[] = [];
}
