import { CardSystemFields, FieldPlaceholder as Placeholder, FieldPlaceholderType } from "@eazy2biz/common-util";
import {PLACEHOLDER_OPTIONS} from "../../../../../contents/DisplayContent";
import {PlaceholderOption} from "../../entity/PlaceholderOption";
import { PlaceholderOptionsList } from "../PlaceholderOptionsList";
import { DynamicPlaceholderConfig } from "../../config/DynamicPlaceholderConfig";
import { humanizeSnakeCase } from "@eazy2biz/common-package-ui";
import { get } from "lodash";

/**
 * Item System Placeholder menu.
 * @param props
 * @constructor
 */
export const ItemSystemPlaceholder = (props: PropTypes) =>  {

    const options: PlaceholderOption[] = Object.keys(CardSystemFields).map(key => ({
            key: get(CardSystemFields, key, key),
            name: humanizeSnakeCase(key.toLocaleLowerCase())
        }));

    const handleOnPick = (key: string) => {
        const placeholder: Placeholder = new Placeholder();
        placeholder.placeholderType = FieldPlaceholderType.CARD_SYSTEM;
        placeholder.key = key;
        placeholder.entityId = '';
        placeholder.entityClassId = '';
        placeholder.subKey = '';
        props.callback(placeholder);
    };

    return (
      <PlaceholderOptionsList
          goBack={props.goBack}
          headerTitle={PLACEHOLDER_OPTIONS.CARD_SYSTEM.toString()}
          placeholderOptions={options}
          onPick={handleOnPick}
      />
    );
}

type PropTypes = {
    goBack: () => void;
    callback: (placeholderString: Placeholder) => void;
    config: DynamicPlaceholderConfig;
};
