import {ActionResult, ActionResultTypes, StageMovementDetail} from "@eazy2biz/common-util";

/**
 * Generates STAGE_MOVEMENT Action Result.
 * @param args
 * @returns actionResult of StageMovementDetail
 */
export const getStageMovementAction = (args: any): ActionResult<StageMovementDetail> => {
    return {
        type: ActionResultTypes.STAGE_MOVEMENT,
        resultDetails: {
            nextStage: args.branchStageId
        }
    };
};