import { GenericListInput } from "@eazy2biz-ui/common-components";
import {FormElementConfig} from "../../../../entity/formBuilder/FormElementConfig";
import { cloneDeep, get, set } from "lodash";
import { Field } from "@eazy2biz/common-util";
import { TableInputColumn } from "@eazy2biz/common-package-ui";

/**
 * Custom Configurations fot table field type.
 * @param props
 * @constructor
 */
export const TableFieldConfigurations = (props: PropTypes) => {

    const defaultValues = getColumnsFromFormField(props.field.element);

    const handleDropdownValuesChange = (values: string[]) => {
        let updatedField: FormElementConfig = cloneDeep(props.field);
        set(
          updatedField.element,
          'fieldConfigurations.otherConfigurations.tableInput.columns',
          values.map(convertIdToColumn)
        );
        props.onFieldUpdate(updatedField);
    };

    return (
      <GenericListInput id='dropdownValues' onChange={handleDropdownValuesChange} defaultValues={defaultValues}/>
    );
};

type PropTypes = {
    field: FormElementConfig;
    onFieldUpdate: (updatedField: FormElementConfig) => void;
};

const convertIdToColumn = (id: string): TableInputColumn => {
  return new TableInputColumn(id);
};

const getColumnsFromFormField = (field: Field): string[] => {
  return get(field, 'fieldConfigurations.otherConfigurations.tableInput.columns', []).map((column: any) => get(column, 'id'));
};
