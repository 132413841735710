import {getTodoCreateAction} from "./actionResults/TodoCreateHelper";
import {getStageMovementAction} from "./actionResults/StageMovementHelper";
import {
    Action,
    ActionResult,
    ActionResultTypes,
    BaseActionResultDetails,
    ConditionExpression,
    EntityDefinitionTypes,
    Stage
} from "@eazy2biz/common-util";
import {getEmailAction} from "./actionResults/EmailActionHelper";
import {cloneDeep} from "lodash";
import {getTriggerStageCompletionAction} from "./actionResults/TriggerStageCompletionHelper";
import {getWhatsappMessageAction} from "./actionResults/WhatsappMessageHelper";
import {getPushNotificationAction} from "./actionResults/PushNotificationActionHelper";
import { getMessageAction } from "./actionResults/MessageHelper";
import { getTriggerWorkflowActionDetails } from "./actionResults/TriggerWorkflowHelper";
import { getCreateTableRecordDetails } from "./actionResults/CreateRecordHelper";

/**
 * Generates System Action
 * @param stageEntity
 * @param actionResultType
 * @param conditionExpression
 * @param args
 * @return Action
 */
export const getSystemAction = (
    stageEntity: Stage,
    actionResultType: ActionResultTypes,
    conditionExpression?: ConditionExpression,
    args: any = {}
): Action => {

    const actionResults: ActionResult<any>[] = [];
    switch (actionResultType) {
        case ActionResultTypes.TODO_CREATE:
            actionResults.push(getTodoCreateAction(stageEntity));
            break;
        case ActionResultTypes.STAGE_MOVEMENT:
            actionResults.push(getStageMovementAction(args));
            break;
        case ActionResultTypes.TRIGGER_STAGE_COMPLETE:
            actionResults.push(getTriggerStageCompletionAction());
            break;

    }

    return {
        actionResults,
        conditions: conditionExpression,
        type: EntityDefinitionTypes.SYSTEM_DEFINED
    };
}

export const getDefaultAction = (actionResultType: ActionResultTypes): Action => {

    const actionResults: ActionResult<BaseActionResultDetails>[] = [];
    switch (actionResultType) {
        case ActionResultTypes.EMAIL:
            actionResults.push(getEmailAction()); break;
        case ActionResultTypes.WHATSAPP_MESSAGE:
            actionResults.push(getWhatsappMessageAction()); break;
        case ActionResultTypes.PUSH_NOTIFICATION:
            actionResults.push(getPushNotificationAction()); break;
        case ActionResultTypes.MESSAGE:
            actionResults.push(getMessageAction()); break;
        case ActionResultTypes.TRIGGER_WORKFLOW:
            actionResults.push(getTriggerWorkflowActionDetails()); break;
        case ActionResultTypes.SAVE_TABLE_RECORD:
            actionResults.push(getCreateTableRecordDetails()); break;
    }

    return {
        actionResults,
        type: EntityDefinitionTypes.USER_DEFINED
    };
}

export const updateActionResultInList = (actions: Action[], actionIndex: number, actionResult: ActionResult<BaseActionResultDetails>): Action[] => {
    const clonedActions: Action[] = cloneDeep(actions);
    clonedActions[actionIndex].actionResults[0] = actionResult;
    return clonedActions;
};

export const updateConditionForActionInList = (actions: Action[], actionIndex: number, condition?: ConditionExpression): Action[] => {
    const clonedActions: Action[] = cloneDeep(actions);
    clonedActions[actionIndex].conditions = condition;
    return clonedActions;
}
