import { CustomerTableHeader, Field, Operators } from "@eazy2biz/common-util";
import { CaretLeftIcon, TrashIcon } from "@radix-ui/react-icons";
import uuid from "react-uuid";
import styles from "./AdvancedFilterComponent.module.css";
import GenericButton from "../../genericComponents/GenericButton/GenericButton";
import { GenericButtonTypes } from "../../genericComponents/GenericButton/GenericButtonTypes";
import { useState } from "react";
import { Divider } from "antd";
import { cloneDeep } from "lodash";
import { ADVANCED_FILTER_STRINGS } from "../../../contents/DisplayContent";
import { DropdownSelect } from "../../genericComponents/select/DropdownSelect";
import { getOperatorMenuForType, OperatorMenu } from "@eazy2biz-ui/common-package";
import { InputByFieldType, InputByHeaderType } from "../InputByFieldType/InputByFieldType";
import { isEmpty } from "@eazy2biz/common-package-ui";

/**
 * Generic Advanced Filter Component
 * @param props
 * @constructor
 */
export const AdvancedFilterComponent = (props: PropTypes) => {

  const {
    applyFilterButtonVisible,
    type = InputFieldType.FIELD
  } = props;

  const [filters, setFilters] = useState(props.filters);

  const handleApplyFilters = (updatedFilters: AdvancedFilterConfig[]) => {
    props.onChange(
      updatedFilters.filter(
        (filter) => !isEmpty(filter.operator) && !isEmpty(filter.firstFieldId) && !isEmpty(filter.value)));
  };

  const addNewFilter = () => {
    const updateFilters = cloneDeep(filters);
    updateFilters.push(new AdvancedFilterConfig());
    setFilters(updateFilters);
  };

  const handleRemoveFilter = (id: string) => {
    const updateFilters = cloneDeep(filters);
    const filtersAfterRemovingCurrentFilter = updateFilters.filter(filter => filter.id !== id);
    setFilters(filtersAfterRemovingCurrentFilter);

    if(!applyFilterButtonVisible) {
      handleApplyFilters(filtersAfterRemovingCurrentFilter);
    }
  };

  const handleClearFilters = () => {
    setFilters([]);
    handleApplyFilters([]);
  };

  const handleFieldIdUpdate = (filter: AdvancedFilterConfig, fieldId: string) => {
    const updateFilters = cloneDeep(filters);

    const newFilters = updateFilters.map((ftr) => {
      if (filter.id === ftr.id) {
        ftr.firstFieldId = fieldId;
      }
      return ftr;
    });

    setFilters(newFilters);

    if(!applyFilterButtonVisible) {
      handleApplyFilters(newFilters);
    }
  };

  const handleOperatorUpdate = (filter: AdvancedFilterConfig, itemId: string) => {
    const updateFilters = cloneDeep(filters);
    const operator: { _id: Operators, name: string } | undefined = OperatorMenu.find((operator) => operator._id === itemId);

    const newFilters = updateFilters.map((ftr) => {
      if (filter.id === ftr.id) {
        ftr.operator = operator?._id || Operators.EQUAL;
      }
      return ftr;
    });

    setFilters(newFilters);

    if(!applyFilterButtonVisible) {
      handleApplyFilters(newFilters);
    }
  };

  const handleValueUpdate = (filter: AdvancedFilterConfig, value: any) => {
    const updateFilters = cloneDeep(filters);

    const newFilters = updateFilters.map((ftr) => {
      if (filter.id === ftr.id) {
        ftr.value = value;
      }
      return ftr;
    });

    setFilters(newFilters);

    if(!applyFilterButtonVisible) {
      handleApplyFilters(newFilters);
    }
  };

  const renderSecondField = (filter: AdvancedFilterConfig) => {
    const inputProps = {
      id: filter.id + '_secondField',
      key: filter.id + '_secondField',
      label: ADVANCED_FILTER_STRINGS.SECOND_FIELD,
      initialValue: filter.value,
      onChange: (e: any) => handleValueUpdate(filter, e.target.value),
    };

    if (type === InputFieldType.FIELD) {
      return (
        <InputByFieldType
          {...inputProps}
          field={(props.firstFieldMenuData as Field[]).find((field: Field) => field._id === filter.firstFieldId)}
        />
      );
    }

    return (
      <InputByHeaderType
        {...inputProps}
        header={(props.firstFieldMenuData as CustomerTableHeader[]).find((header) => header._id === filter.firstFieldId)}
      />
    );
  };

  const renderFilterExpression = (filter: AdvancedFilterConfig) => {
    return (
      <div key={filter.id} className={styles.filterRow}>
        <div className={styles.firstFieldWrapper}>
          <DropdownSelect
            key={`field1-${filter.id}`}
            label={ADVANCED_FILTER_STRINGS.FIELD}
            menuData={props.firstFieldMenuData}
            onItemSelect={(id) => handleFieldIdUpdate(filter, id)}
            placeholder={ADVANCED_FILTER_STRINGS.FIELD}
            selectedItem={filter.firstFieldId}
            />
        </div>
        <div className={styles.operatorWrapper}>
          <DropdownSelect
            key={`condition-operator-${filter.id}`}
            label={ADVANCED_FILTER_STRINGS.OPERATOR}
            placeholder={ADVANCED_FILTER_STRINGS.OPERATOR_FIELD_PLACEHOLDER}
            onItemSelect={(op) => handleOperatorUpdate(filter, op)}
            menuData={getOperatorMenuForType(props.getFieldType(filter))}
            selectedItem={filter.operator}
          />
        </div>
        <div className={styles.secondFieldWrapper}>
          {renderSecondField(filter)}
        </div>
        <div className={styles.deleteIcon} onClick={() => handleRemoveFilter(filter.id)}>
          <TrashIcon />
        </div>
      </div>
    );
  };

  return (
    <div>

      <span className={styles.headerWrapper}>
        <span className={styles.clearButton} onClick={handleClearFilters}>Clear</span>
        {
            props.toggleAdvancedFilter && (
                <CaretLeftIcon height={20} width={20} className={styles.backButton} onClick={props.toggleAdvancedFilter}/>
            )
        }

      </span>

      {filters.map(renderFilterExpression)}

      <span className={styles.addButton} onClick={addNewFilter}>+ Add Filter</span>

      {
          applyFilterButtonVisible && (
            <>
              <Divider />
              <span className={styles.applyButtonWrapper}>
                <GenericButton
                    buttonText={'Apply Filters'}
                    type={GenericButtonTypes.SECONDARY} onClick={() => handleApplyFilters(filters)}
                />
              </span>
            </>
          )
      }

    </div>
  );
};

type PropTypes = {
  type?: InputFieldType;
  firstFieldMenuData: Field[] | CustomerTableHeader[];
  getFieldType: (filterConfig: AdvancedFilterConfig) => string;
  filters: AdvancedFilterConfig[];
  onChange: (filters: AdvancedFilterConfig[]) => void;
  toggleAdvancedFilter?: () => void;
  applyFilterButtonVisible : boolean;
};

export class AdvancedFilterConfig {
  id: string = uuid();
  firstFieldId: string = '';
  operator: Operators = Operators.EQUAL;
  value: string = '';
}

export enum InputFieldType {
  FIELD = 'FIELD',
  CUSTOMER_TABLE_HEADER = 'CUSTOMER_TABLE_HEADER'
}
