import { StageFormComponent, Toast } from "@eazy2biz-ui/common-components";
import React, { useEffect, useState } from "react";
import {
  ActionResult,
  ActionResultTypes,
  BaseActionResultDetails,
  StageFormEntity, TriggerWorkflowDetails,
  WorkflowResponse
} from "@eazy2biz/common-util";
import { getStageForm } from "../../../../services/WorkflowService";
import { StrictBuilder } from "builder-pattern";
import { get } from "lodash";
import { FormFieldInput } from "@eazy2biz/common-package-ui";
import { convertToFieldsPayload } from "@eazy2biz-ui/common-package";

/**
 * Create Workflow Trigger Details Component
 * @param props
 * @constructor
 */
export const CreateWorkflowTriggerDetails = (props: PropTypes) => {

  const getInitialFormValues = (): FormFieldInput[] => {
    const formValues = get(props.taskDetails, 'resultDetails.formValues', {});
    return Object.keys(formValues).map((key) => ({
      id: key,
      value: formValues[key]
    }));
  };

  const [stageForm, setStageForm] = useState<StageFormEntity>();
  const [fieldsMap, setFieldMap] = React.useState<FormFieldInput[]>(getInitialFormValues());

  useEffect(() => {
      getStageForm(props.workflow._id)
          .then((res : StageFormEntity) => {
              setStageForm(res);
          })
          .catch(() => {
            Toast.errorString();
          });
  }, []);

  const getResultDetails = (fieldMap: FormFieldInput[]): TriggerWorkflowDetails => {
    return StrictBuilder<TriggerWorkflowDetails>()
      .workflowId(props.workflow._id)
      .formValues(convertToFieldsPayload(fieldMap))
      .build();
  };

  const getActionTaskDetails = (resultDetails: BaseActionResultDetails): ActionResult<BaseActionResultDetails> => {
    return StrictBuilder<ActionResult<BaseActionResultDetails>>()
      .type(ActionResultTypes.TRIGGER_WORKFLOW)
      .resultDetails(resultDetails)
      .build();
  };

  const handleFormUpdate = (fieldMap: Array<any>) => {
    setFieldMap(fieldMap);
    props.setTaskDetails(getActionTaskDetails(getResultDetails(fieldMap)));
  };

  return (
    <StageFormComponent
      stageForm={stageForm}
      fieldsMap={fieldsMap}
      setFieldMap={handleFormUpdate}
      onSubmit={() => {}}
      disableForm={props.readOnly}
    />
  );
};

type PropTypes = {
  workflow: WorkflowResponse;
  taskDetails: any;
  setTaskDetails: (taskDetails: any) => void;
  readOnly: boolean;
};
