import {ColumnHeader} from "@eazy2biz-ui/common-components";

export enum PDFTemplatesListHeaders {
  TEMPLATE_NAME = 'templateName',
  STATUS = 'status',
  LAST_UPDATED = 'lastUpdated'
};

export const getColumnsForPDFTemplatesList = (): ColumnHeader[] => {
  const columns: ColumnHeader[] = [
    {
      Header: 'Template name',
      accessor: PDFTemplatesListHeaders.TEMPLATE_NAME
    },
    {
      Header: 'Status',
      accessor: PDFTemplatesListHeaders.STATUS
    },
    {
      Header: 'Last updated',
      accessor: PDFTemplatesListHeaders.LAST_UPDATED
    }
  ];

  return columns;
};
