import { RestServices } from "@eazy2biz/common-package-ui";
import { TemplateCategory, TemplateResponse } from "@eazy2biz/common-util";
import {getApprovedTemplateFilters} from "./helpers/TemplateHelper";

const TEMPLATE_SERVICE = () => RestServices.getInstance().getTemplateService();

export const getAllApprovedWhatsappTemplates = (): Promise<TemplateResponse[]> => {
  return TEMPLATE_SERVICE().filterTemplates(TemplateCategory.WHATSAPP_MESSAGE, getApprovedTemplateFilters());
};

export const getAllApprovedMessageTemplates = (): Promise<TemplateResponse[]> => {
  return TEMPLATE_SERVICE().filterTemplates(TemplateCategory.MESSAGE, getApprovedTemplateFilters());
};
