import moment from "moment";

export const getPrettyHour = (hourValue : number) : string => {
    const dateMoment = moment();
    dateMoment.set('hour', hourValue);
    return dateMoment.format('h A');
}

export const getPrettyDayOfWeek = (dayOfWeekValue : number) : string => {
    const dateMoment = moment();
    dateMoment.set('weekday', dayOfWeekValue);
    return dateMoment.format('dddd');
}

export const getPrettyDayOfMonth = (dayOfMonthValue : number) : string => {
    return `${dayOfMonthValue}`;
}

export const getPrettyDayOfYear = (dayOfYearValue : number) : string => {
    return `${dayOfYearValue}`;
}

export const getPrettyWeek = (weekValue : number) : string => {
    return `${weekValue}`;
}

export const getPrettyMonth = (monthIndex : number) : string => {
    const dateMoment = moment();
    dateMoment.set('month', monthIndex);
    return dateMoment.format('MMMM');
}

export const getPrettyQuarter = (quarterValue : number) : string => {
    return `Q${quarterValue + 1}`;
}

export const getPrettyYear = (yearValue : number) : string => {
    return `${yearValue}`;
}
