import styles from './card.module.css';
import classNames from 'classnames';

export const Card = (props: CardPropTypes) => {
  const {
    cardTitle,
    cardSubTitle,
    cardText,
    cardFooter,
    className,
    onCardTitleClick,
    onCardBodyClick,
    selected
  } = props;
  return (
    <>
      <div className={classNames(styles.card, className, {[styles.cardSelected]: selected})}>
        <div className={classNames(styles.cardBody, {[styles.cardBodyFullHeight]: !cardFooter, [styles.cardBodySelected]: selected})} onClick={onCardBodyClick}>
          <div className={styles.cardLabels}>
            {/* TODO: replace with actual tags and assigned by*/}
            {/*<span className={styles.cardTag}>#Marketing</span>*/}
            {/*<span className={styles.cardAssignedBy}>RB</span>*/}
          </div>
          <h5 className={styles.cardTitle} onClick={onCardTitleClick}>
            {cardTitle}
          </h5>
          {cardSubTitle && <h6 className={classNames("text-muted", styles.subTitle)}>{cardSubTitle}</h6>}
          { cardText && <div className={styles.cardText}>{cardText}</div>}
        </div>
        { cardFooter && <div className={styles.cardFooter}>{cardFooter}</div> }
      </div>
    </>
  );
};

export type CardPropTypes = {
  cardTitle?: any;
  cardSubTitle?: any;
  cardText?: any;
  cardLink?: any;
  cardFooter?: any;
  className?: any;
  onCardTitleClick?: () => void;
  onCardBodyClick?: () => void;
  selected?: boolean;
};
