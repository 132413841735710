import { Form } from "antd";
import {
    CustomerTableResponse,
    SaveTableRecordDetails,
} from "@eazy2biz/common-util";
import { useEffect, useState } from "react";
import { cloneDeep, get, set } from "lodash";
import {
    CardFormFieldSelect,
    DropdownSelect,
    MenuData,
    Spinner,
    TextVariableInput,
    Toast,
    VariableField
} from "@eazy2biz-ui/common-components";
import { ActionComponentProps } from "../interfaces/ActionComponentProps";
import styles from "../actionForm.module.css";
import { getAllCustomerTables } from "../../../../../../services/CustomerTableService";

const validateMessages = {
    required: '${label} is required!'
};

/**
 * Create record in a customer table Action Component.
 * @param props
 * @constructor
 */
export const SaveTableRecordActionComponent = (props: ActionComponentProps) => {

    const saveTableRecordDetail = props.actionResult.resultDetails as SaveTableRecordDetails;

    const convertVariableFieldsToObject = (values: VariableField[]): object => {
        const variablesObject = {};
        values.map((variablesField) => {
            set(variablesObject, variablesField.id, variablesField.value);
        });

        return variablesObject;
    };

    const [tables, setTables] = useState<CustomerTableResponse[]>([]);
    const [loading, setLoading] = useState(true);

    const [selectedTableId, setSelectedTableId] = useState<string>(saveTableRecordDetail.tableId || '');
    const [recordValues, setRecordValues] = useState<VariableField[]>([]);

    useEffect(() => {
        getAllCustomerTables()
          .then((response) => {
            setTables(response || []);
              updateSelectedTable(saveTableRecordDetail.tableId || '', response || []);
          })
          .catch(() => Toast.errorString())
          .finally(() => setLoading(false));
    }, []);

    const handleTableChange = (id: string) => {
        updateSelectedTable(id, tables);
    };

    const updateSelectedTable = (id: string, customerTables: CustomerTableResponse[]) => {
        setSelectedTableId(id);
        setRecordValues([]);
        const selectedTable = customerTables.find((table) => table._id === id);

        setRecordValues(
          selectedTable? selectedTable.tableHeaders.map((tableColumn) => ({
              id: tableColumn._id,
              name: tableColumn.name,
              value: get(saveTableRecordDetail.recordValues, tableColumn._id, ''),
          })) : []);
    };

    const handleRecordValuesUpdate = (variableFields: VariableField[]) => {
        setRecordValues(variableFields);
        const updatedSaveTableRecordDetail: SaveTableRecordDetails = {
            tableId: selectedTableId,
            recordValues: convertVariableFieldsToObject(variableFields),
        };
        const updatedAction = cloneDeep(props.actionResult);
        updatedAction.resultDetails = updatedSaveTableRecordDetail;
        props.onActionResultUpdate(updatedAction);
    };

    const handleSave = (e: any) => {
        e?.stopPropagation();
        const updatedSaveTableRecordDetail: SaveTableRecordDetails = {
            tableId: selectedTableId,
            recordValues: convertVariableFieldsToObject(recordValues),
        };
        const updatedAction = cloneDeep(props.actionResult);
        updatedAction.resultDetails = updatedSaveTableRecordDetail;
        props.onActionResultUpdate(updatedAction);
    };

    const getTemplateMenuData = (): MenuData[] => {
        return tables.map((table) => ({
            _id: table._id.toString(),
            name: table.details.name
        }));
    };

    const renderVariableFieldDropdown = (variableField: VariableField, onChange: (e: any) => void) => {
        return (
          <CardFormFieldSelect
            key={variableField.id}
            label={variableField.name}
            fields={props.fields}
            initialValue={variableField.value}
            onChange={onChange}
          />
        );
    };

    if (loading) {
        return <Spinner />;
    }

    return(
        <>
            <Form validateMessages={validateMessages}>
                <Form.Item name={['tableId']} rules={[{ required: true } ]} className={styles.actionDetailsFormItem}>
                    <DropdownSelect
                        label={'Table:'}
                        menuData={getTemplateMenuData()}
                        selectedItem={selectedTableId || ''}
                        onItemSelect={handleTableChange}
                        onBlur={handleSave}
                    />
                </Form.Item>
                <Form.Item name={['recordValues']} className={styles.actionDetailsFormItem}>
                    <TextVariableInput
                        key={'SAVE_RECORD'}
                        variableFields={recordValues}
                        setVariableFields={handleRecordValuesUpdate}
                        onItemBlur={handleSave}
                        renderInputField={renderVariableFieldDropdown}
                    />
                </Form.Item>
            </Form>
        </>
    );
};
