import {
    StageFormEntity,
    WorkflowCreateRequest,
    WorkflowResponse,
    WorkflowUpdateAttributes
} from "@eazy2biz/common-util";
import {RestServices} from "@eazy2biz/common-package-ui";
import {getGetStageFormRequest} from "./helpers/workflow/WorkflowHelper";

const WORKFLOW_SERVICE = () => RestServices.getInstance().getWorkflowService();

export const createWorkflow = (data: WorkflowCreateRequest) => {
    return WORKFLOW_SERVICE().createWorkflow(data);
};

export const patchWorkflowById = (workflowId: string, workflowUpdateAttributes: WorkflowUpdateAttributes) => {
    return WORKFLOW_SERVICE().patchWorkflowById(workflowId, workflowUpdateAttributes);
};

export const getAllEnabledWorkflows = () : Promise<WorkflowResponse[]> => {
    return WORKFLOW_SERVICE().getAllEnabledWorkflowsForCompany();
};

export const getStageForm = (workflowId: string, stageId?: string) : Promise<StageFormEntity> => {
    return WORKFLOW_SERVICE()
        .getStageFormForWorkflowStage(
            getGetStageFormRequest(workflowId, stageId)
        );
};

export const disableWorkflowById = (workflowId: string) => {
    return WORKFLOW_SERVICE().patchWorkflowById(workflowId, {enabled: false, tags: []});
};
