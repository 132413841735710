import {
    GenericButton,
    GenericModal,
    InputField,
    Toast,
    AssigneeSelect,
    ModalSubHeaderComponent
} from "@eazy2biz-ui/common-components";
import React, {useEffect, useState} from "react";
import {CREATE_TODO, TODO_COMPLETE_FORM} from "../../../../contents/DisplayContent";
import {createTodo} from "../../../services/UserTodoService";
import {GenericException, getCurrentUserId} from "@eazy2biz/common-package-ui";


/**
 * Create To do Modal.
 * @param props
 * @constructor
 */
export const CreateTodoModal = (props: PropTypes) => {

    const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(true);

    const [assignee, setAssignee] = useState([getCurrentUserId()]);
    const [assigneeUGs, setAssigneeUGs] = useState<string[]>([]);
    const [todoTitle, setTodoTitle] = useState('');
    const [todoDescription, setTodoDescription] = useState('');
    const [todoDueInDays, setTodoDueInDays] = useState(1);
    const [createTodoProgress, setCreateTodoProgress] = useState<boolean>(false);

    useEffect(() => {
        if (!!assignee && !!todoTitle && !!todoDueInDays) {
            setDisableSubmitButton(false);
        } else {
            setDisableSubmitButton(true);
        }
    }, [assignee, todoTitle, todoDueInDays]);

    const handleCreateTodo = () => {
        Toast.load(CREATE_TODO.PROGRESS);
        setCreateTodoProgress(true)
        createTodo(
            todoTitle,
            todoDescription,
            assigneeUGs,
            assignee,
            todoDueInDays
        )
            .then(() => {
                Toast.success(CREATE_TODO.CREATE_TODO_SUCCESS);
                props.onClose();
                setCreateTodoProgress(false);
                props.onRefreshCallback && props.onRefreshCallback(true);
            })
            .catch(
                (err: GenericException) => {
                    setCreateTodoProgress(false);
                    Toast.error(err, CREATE_TODO.CREATE_TODO_FAILURE);
                });
    };

    const handleAssigneeChange = (users: string[], userGroups: string[] = []) => {
        setAssignee(users);
        setAssigneeUGs(userGroups);
    };

    const getModalSubTitle = () => {
        return (
            <ModalSubHeaderComponent
                title={TODO_COMPLETE_FORM.MY_TODO_TITLE}
            />
        );
    };

    const renderModelBody = () => {
        return (
            <>
                <InputField id="title" label="Todo Title" initialValue={todoTitle} type="text" onChange={(e) => setTodoTitle(e.target.value)} />
                <InputField id="description" label="Description" initialValue={todoDescription} required={false} type="textArea" onChange={(e) => setTodoDescription(e.target.value)} />
                <InputField id="date" label="Due in Days" type="number" initialValue={todoDueInDays.toString()} onChange={(e) => setTodoDueInDays(parseInt(e.target.value))} />
                <AssigneeSelect  onAssigneesSelect={handleAssigneeChange}  selectedAssignees={assignee} userGroups={true}/>
            </>
        );
    };

    const renderFooter = () => {
        return (
            <GenericButton
                isLoading={createTodoProgress}
                onClick={handleCreateTodo}
                buttonText={CREATE_TODO.CREATE_TODO_TITLE}
                disabled={disableSubmitButton}
            />
        );
    };

    return (
        <GenericModal
            show={props.show}
            title={CREATE_TODO.CREATE_TODO_TITLE}
            subHeader={getModalSubTitle}
            bodyContent={renderModelBody}
            footerContent={renderFooter}
            onClose={props.onClose}
        />
    );
};

type PropTypes = {
    show: boolean;
    onRefreshCallback?: (value: boolean) => void;
    onClose: () => void;
}

