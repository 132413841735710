import { DropdownWrapper, InfoWrapper, TourType, useFeatureTour } from "@eazy2biz-ui/common-components";
import { ActionCollapsiblePanel } from "./ActionCollapsiblePanel";
import PlusFillIcon from "../../../../../assets/PlusFill.svg";
import styles from "./ActionConfigurations.module.css";
import {
    ActionResultConfig,
    EntryActionMenuList,
    ExitActionMenuList,
    SLABreachActionMenuList
} from "./configs/ActionConfig";
import { Action, ActionTypes, Field, Stage, StageActions, StageTypes } from "@eazy2biz/common-util";
import { getDefaultAction } from "../../../../helpers/workflowBuilderHelpers/runtimeHelpers/actions/ActionHelper";
import { cloneDeep } from "lodash";
import { STAGE_ACTION_CONFIGURATIONS } from "../../../../../contents/DisplayContent";
import { ENTRY_ACTIONS, EXIT_ACTIONS, SLA_ACTIONS } from "@eazy2biz-ui/common-package";

export const ActionConfigurationsComponent = (props: PropTypes) => {
    const {getRef} = useFeatureTour();

    const handleEntryActionClick = (_event: any, actionId: any) => {
        const itemConfig: ActionResultConfig | undefined = EntryActionMenuList.find((item) => item.id === actionId);
        if (itemConfig) {
            handleUpdateActionList([...props.stageActions.stage_entry, getDefaultAction(itemConfig.data.type)], ActionTypes.STAGE_ENTRY);
        }
    };

    const handleExitActionClick = (_event: any, actionId: any) => {
        const itemConfig: ActionResultConfig | undefined = ExitActionMenuList.find((item) => item.id === actionId);
        if (itemConfig) {
            handleUpdateActionList([...props.stageActions.stage_exit, getDefaultAction(itemConfig.data.type)], ActionTypes.STAGE_EXIT);
        }
    };

    const handleSlaBreachActionClick = (_event: any, actionId: any) => {
        const itemConfig: ActionResultConfig | undefined = SLABreachActionMenuList.find((item) => item.id === actionId);
        if (itemConfig) {
            handleUpdateActionList([...props.stageActions[ActionTypes.SLA_BREACH], getDefaultAction(itemConfig.data.type)], ActionTypes.SLA_BREACH);
        }
    };

    const handleUpdateActionList = (list: Action[], actionType: ActionTypes) => {
        const clonedStageActions: StageActions = cloneDeep(props.stageActions);
        // @ts-ignore
        clonedStageActions[actionType.toString()] = list;
        props.updateStageActions(clonedStageActions);
    };

    const renderEntryActions = () => {
        if (props.stageType === StageTypes.ENTRY_STAGE) {
            return null;
        }

        return(
            <div className={styles.otherFieldsHeader}>
                <div className={styles.actionTitle}>
                    <span className={styles.otherFieldsTitle}><InfoWrapper infoContent={ENTRY_ACTIONS}>{STAGE_ACTION_CONFIGURATIONS.STAGE_ENTRY}</InfoWrapper></span>
                    <DropdownWrapper menuData={EntryActionMenuList} children={<span><img src={PlusFillIcon}/></span>} onClick={handleEntryActionClick}/>
                </div>
                <ActionCollapsiblePanel
                    fields={props.fields.filter((field) => field.ownerId !== props.currentStageId)}
                    stages={props.stages}
                    selectedActionList={props.stageActions.stage_entry}
                    updateActionList={(list) => handleUpdateActionList(list, ActionTypes.STAGE_ENTRY)}/>
            </div>
        );
    };

    const renderExitActions = () => {
        return(
            <div className={styles.otherFieldsHeader}>
                <div className={styles.actionTitle}>
                    <span className={styles.otherFieldsTitle}><InfoWrapper infoContent={EXIT_ACTIONS}>{STAGE_ACTION_CONFIGURATIONS.STAGE_EXIT}</InfoWrapper></span>
                    <DropdownWrapper
                        menuData={ExitActionMenuList} children={
                            <span className={styles.addActionButton} ><img src={PlusFillIcon}/></span>
                        }
                        onClick={handleExitActionClick} />
                </div>
                <ActionCollapsiblePanel
                    fields={props.fields}
                    stages={props.stages}
                    selectedActionList={props.stageActions.stage_exit}
                    updateActionList={(list) => handleUpdateActionList(list, ActionTypes.STAGE_EXIT)}/>
            </div>
        );
    };

    const renderSlaBreachActions = () => {
        if (props.stageType === StageTypes.ENTRY_STAGE) {
            return null;
        }
        return(
          <div className={styles.otherFieldsHeader}>
              <div className={styles.actionTitle}>
                  <span className={styles.otherFieldsTitle}><InfoWrapper infoContent={SLA_ACTIONS}>{STAGE_ACTION_CONFIGURATIONS.SLA_BREACH}</InfoWrapper></span>
                  <DropdownWrapper
                    menuData={SLABreachActionMenuList} children={
                      <span className={styles.addActionButton} ><img src={PlusFillIcon}/></span>
                  }
                    onClick={handleSlaBreachActionClick} />
              </div>
              <ActionCollapsiblePanel
                stages={props.stages}
                fields={props.fields}
                selectedActionList={props.stageActions.sla_breach || []}
                updateActionList={(list) => handleUpdateActionList(list, ActionTypes.SLA_BREACH)}/>
          </div>
        );
    };

    return(
        <div ref={getRef(2, TourType.STAGE_CONFIGURATIONS)}>
            {renderEntryActions()}
            {renderExitActions()}
            {renderSlaBreachActions()}
        </div>
    );
}

type PropTypes = {
    currentStageId: string;
    fields: Field[];
    stages: Stage[];
    stageActions: StageActions;
    updateStageActions: (stageActions: StageActions) => void;
    stageType: StageTypes;
};
