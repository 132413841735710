import { RestServices } from '@eazy2biz/common-package-ui';
import {
  CustomerTableCreateRequest,
  CustomerTableUpdateAttributes,
} from "@eazy2biz/common-util";
import {
  getCreateRecordRequest, getRecordsFilterFromConfig,
  getUpdateRecordRequest
} from "../helpers/CustomerTableServiceHelper";
import {
  CustomerTableFilterConfig
} from "../components/tableLanding/recordsTable/CustomerTableFilterMenu/CustomerTableFilterMenu";

const CUSTOMER_TABLE_SERVICE = () => RestServices.getInstance().getCustomerTableService();

export const getCustomerTableById = (id: string) => {
  return CUSTOMER_TABLE_SERVICE().getCustomerTableById(id);
};

export const getAllCustomerTables = () => {
  return CUSTOMER_TABLE_SERVICE().getAllCustomerTablesForCompany();
};

export const getFilteredRecordsForTable = (tableId: string, filterConfig: CustomerTableFilterConfig) => {
  return CUSTOMER_TABLE_SERVICE().filterCustomerTableRecordsForCustomerTable(getRecordsFilterFromConfig(filterConfig), tableId);
};

export const getRecordsCount = (tableId: string) => {
  return CUSTOMER_TABLE_SERVICE().getAllCustomerTableRecordsCountForCustomerTable(tableId);
};

export const createTable = (customerTable: CustomerTableCreateRequest) => {
  return CUSTOMER_TABLE_SERVICE().createCustomerTable(customerTable);
};

export const updateTable = (tableId: string, customerTableDetails: CustomerTableUpdateAttributes) => {
  return CUSTOMER_TABLE_SERVICE().patchCustomerTableById(tableId, customerTableDetails);
}

export const updateTableHeaders = (tableId: string, customerTableHeaderId: string ) => {};

export const getRecord = (customerTableRecordId: string, tableId: string) => {
  return CUSTOMER_TABLE_SERVICE().getCustomerTableRecordsByIdForCustomerTable(
    customerTableRecordId,
    tableId
  );
};

export const createRecord = (
  tableId: string,
  contentMap: {
    [key: string]: any;
  }
) => {
  return (
    CUSTOMER_TABLE_SERVICE()
      // @ts-ignore
      .addCustomerTableRecordForCustomerTable(getCreateRecordRequest(tableId, contentMap), tableId)
  );
};

export const updateRecord = (
  contentMap: { [key: string]: any },
  customerTableRecordId: string,
  tableId: string
) => {
  return CUSTOMER_TABLE_SERVICE().updateCustomerTableRecordByIdForCustomerTable(
    getUpdateRecordRequest(contentMap),
    customerTableRecordId,
    tableId
  );
};
