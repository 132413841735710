import {get} from "lodash";
import {ColumnHeader, RowData, RowMetaData, generateTableDataFromItems} from "@eazy2biz-ui/common-components";
import {LocalUserResponse} from "@eazy2biz/common-util";
import {getUserGroupNamesByIds, getFormattedDate} from "@eazy2biz/common-package-ui";

enum LocalUserHeaders {
    NAME = 'name',
    EMAIL = 'email',
    USER_GROUPS = 'userGroups',
    JOINED_ON = 'createdDate'
};

const getValueForUser = (user: LocalUserResponse, key: string) => {
    switch (key) {
        case LocalUserHeaders.NAME:
            return get(user, "name", "");
        case LocalUserHeaders.EMAIL:
            return get(user, "email", "");
        case LocalUserHeaders.USER_GROUPS:
            return getUserGroupNamesByIds(user.directMembershipUserGroups);
        case LocalUserHeaders.JOINED_ON:
            return getFormattedDate(user.dateOfJoining);
        default:
            return "";
    }
};

const getMetaDataForUser = (user: LocalUserResponse): RowMetaData => {
    return {
        id: user._id
    };
};

export const getColumnsForUsersList = (): ColumnHeader[] => {
    const columns = [
        {
            Header: 'Name',
            accessor: LocalUserHeaders.NAME,
        },
        {
            Header: 'Email',
            accessor: LocalUserHeaders.EMAIL,
        },
        {
            Header: 'User Groups',
            accessor: LocalUserHeaders.USER_GROUPS,
        },
        {
            Header: 'Joined On',
            accessor: LocalUserHeaders.JOINED_ON

        }
    ];
    return columns;
};

export const getTableDataForUsersList = (users: LocalUserResponse[]): RowData[] => {
    return generateTableDataFromItems(users, getColumnsForUsersList(), getValueForUser, getMetaDataForUser)
}
