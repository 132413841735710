import React, { useEffect, useState } from 'react';
import styles from './FormPlayground.module.css';
import FormBuilderField from './formBuilderField/FormBuilderField';
import { FormElementConfig } from '../../../entity/formBuilder/FormElementConfig';
import { Elements } from 'react-flow-renderer/dist/types';
import { getStageLabelById } from '../../../helpers/workflowBuilderHelpers/runtimeHelpers/StageConfigurationHelper';
import { Collapse } from 'antd';
import { AccessTypes, FieldAccessMap } from '@eazy2biz/common-util';
import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import { cloneDeep } from 'lodash';
import classNames from 'classnames';

const FormPlayground = (props: PropTypes): React.ReactElement => {
  const { currentStageId, onFieldVisibilityChangeForStage } = props;
  const [stageVisibilityMap, setStageVisibilityMap] = useState<FieldAccessMap>({});

  useEffect(() => {
    const stageVisibilityMapClone = cloneDeep(stageVisibilityMap);
    props.fields.forEach((field) => {
      stageVisibilityMapClone[field.element.ownerId] = field.visibility;
    });
    setStageVisibilityMap(stageVisibilityMapClone);
  }, []);

  const handleFieldVisibilityForStage = (visibility: AccessTypes, stageId: string, e: any) => {
    e.stopPropagation();
    const stageVisibilityMapClone = cloneDeep(stageVisibilityMap);
    stageVisibilityMapClone[stageId] = visibility;
    setStageVisibilityMap(stageVisibilityMapClone);
    onFieldVisibilityChangeForStage(visibility, stageId);
  };

  const getAccordionIcon = (panelProps: any): React.ReactNode => {
    return panelProps.isActive ? <ChevronUpIcon /> : <ChevronDownIcon />;
  };

  const checkVisibility = (stageId: string, accessType: AccessTypes): boolean => {
    return stageVisibilityMap[stageId] === accessType;
  };

  const getStageVisibilityClassName = (stageId: string, accessType: AccessTypes) => {
    return classNames(styles.fieldVisibilityOptionUnselected, {
      [styles.fieldVisibilityOptionSelected]: checkVisibility(stageId, accessType)
    });
  };

  const renderItems = (fieldList: FormElementConfig[]) => {
    return fieldList.map((item) => {
      return (
        <FormBuilderField
          itemConfig={item}
          workflowEditMode={props.workflowEditMode}
          onFieldSelect={props.onFieldSelect}
          onFieldDelete={props.onFieldDelete}
          isSelected={item.element._id === props.selectedFieldId}
          currentStageId={props.currentStageId}
          disabled={checkVisibility(item.element.ownerId, AccessTypes.RO)}
        />
      );
    });
  };

  const renderFormName = (stageId: string) => {
    return <p>{getStageLabelById(props.stages, stageId)}</p>;
  };

  const renderFieldVisibilitySelect = (stageId: string) => {
    const isCurrentStage = stageId === currentStageId ? true : false;
    if (isCurrentStage) {
      return null;
    }
    return (
      <div id={`${stageId}_fieldVisibility`} className={styles.fieldVisibility}>
        <span
          id={`${stageId}_edit`}
          className={getStageVisibilityClassName(stageId, AccessTypes.RW)}
          onClick={(e) => handleFieldVisibilityForStage(AccessTypes.RW, stageId, e)}>
          Edit
        </span>
        <span
          id={`${stageId}_readOnly`}
          className={getStageVisibilityClassName(stageId, AccessTypes.RO)}
          onClick={(e) => handleFieldVisibilityForStage(AccessTypes.RO, stageId, e)}>
          Read Only
        </span>
        <span
          id={`${stageId}_hidden`}
          className={getStageVisibilityClassName(stageId, AccessTypes.HIDDEN)}
          onClick={(e) => handleFieldVisibilityForStage(AccessTypes.HIDDEN, stageId, e)}>
          Hidden
        </span>
      </div>
    );
  };

  const renderFormHeader = (stageId: string) => {
    return (
      <div className={styles.formPlaygroundHeader}>
        {renderFormName(stageId)}
        {renderFieldVisibilitySelect(stageId)}
      </div>
    );
  };

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={currentStageId}
      expandIconPosition={'right'}
      expandIcon={getAccordionIcon}>
      {props.stageIdsList.map((stageId) => {
        return (
          <Collapse.Panel
            id={stageId}
            header={renderFormHeader(stageId)}
            key={stageId}
            collapsible={stageId === currentStageId ? 'disabled' : undefined}
            className={styles.formPlaygroundPanel}>
            <div
              onClick={() => props.onFieldSelect()}
              className={classNames({
                [styles.formPlaygroundPanelBodyBlur]: checkVisibility(
                  stageId,
                  AccessTypes.HIDDEN
                )
              })}>
              <div className={styles.formPlaygroundBody}>
                {renderItems(props.fields.filter((item) => item.element.ownerId === stageId))}
              </div>
            </div>
          </Collapse.Panel>
        );
      })}
    </Collapse>
  );
};

type PropTypes = {
  currentStageId: string;
  stages: Elements;
  fields: FormElementConfig[];
  workflowEditMode: boolean;
  stageIdsList: string[];
  onFieldSelect: (fieldLabel?: string, fieldId?: string) => void;
  onFieldDelete: (fieldId?: string) => void;
  selectedFieldId?: string;
  onFieldVisibilityChangeForStage: (visibility: AccessTypes, stageId: string) => void;
};

export default FormPlayground;
