import {
    AggregatorType, BarChartType,
    ChartGrouping,
    createdDatePath,
    DateFieldAggregationType,
    FieldTypes,
    lastUpdatedDatePath, PieChartDetails,
    ReportEntityType,
    WorkflowResponse
} from "@eazy2biz/common-util";

import {DropdownSelect, InputField} from "@eazy2biz-ui/common-components";

import React from "react";
import {
    displayNameForAggregatorType,
    displayNameForDateFieldAggregationType
} from "../../../../../../../../contents/DisplayContent";
import {cloneDeep} from "lodash";

import styles from './ConfigureWorkflowPieChartDetails.module.css'

export const ConfigureWorkflowPieChartDetails = (
    props : {
        entityType: ReportEntityType;
        entityId: string;
        workflowResponse: WorkflowResponse;
        showChartConfigureFormFieldErrors: boolean;

        pieChartDetails : PieChartDetails,
        setPieChartDetails : (pieChartDetails : PieChartDetails) => void;
    }
) : JSX.Element => {

    const {
        entityType,
        entityId,
        workflowResponse,
        showChartConfigureFormFieldErrors,
        pieChartDetails,
        setPieChartDetails,
    } = props;

    const checkIfDateAggregationRequired = (fieldId : string | undefined) => {
        return fieldId
            && (
                fieldId === createdDatePath
                || fieldId === lastUpdatedDatePath
                || workflowResponse.fields.find(
                    workflowField =>
                        fieldId === workflowField._id
                        && workflowField.type === FieldTypes.DATE
                )
            );
    }

    return (
        <>
            <div className={styles.xAxisConfigurationBox}>
                <div className={styles.configureXAxisText}> Configure Grouping Field </div>

                <div className={styles.xAxisConfiguration}>
                    <div className={styles.configureXAxisLabel}>
                        <InputField
                            id="xAxisFieldLabel"
                            label="Label"
                            highlightFieldError={showChartConfigureFormFieldErrors}
                            isInValid={!pieChartDetails.groupingLabel}
                            initialValue={pieChartDetails.groupingLabel || ''}
                            type="text"
                            onChange={(e) => {
                                pieChartDetails.groupingLabel = e.target.value;
                                setPieChartDetails(cloneDeep(pieChartDetails));
                            }}
                            required={true}
                        />
                    </div>

                    <div className={styles.configureXAxisFieldAndAggregator}>
                        <div
                            className={styles.configureXAxisField}
                            style={{width: checkIfDateAggregationRequired(pieChartDetails.groupingField) ? '50%' : '100%'}}
                        >
                            <DropdownSelect
                                placeholder={'Workflow Field'}
                                label={'Workflow Field'}
                                selectedItem={pieChartDetails.groupingField}
                                onItemSelect={
                                    (fieldId) => {
                                        pieChartDetails.groupingField = fieldId;
                                        setPieChartDetails(cloneDeep(pieChartDetails));
                                    }
                                }
                                loading={workflowResponse?.fields === null}
                                isErrored={
                                    showChartConfigureFormFieldErrors &&
                                    (
                                        pieChartDetails.groupingField === null
                                        || pieChartDetails.groupingField === undefined
                                    )
                                }
                                menuData={
                                    [
                                        {
                                            _id: createdDatePath,
                                            name: 'Created Date'
                                        },
                                        {
                                            _id: lastUpdatedDatePath,
                                            name: 'Last Modified Date'
                                        },
                                        ...workflowResponse.fields?.map(
                                            (field) => ({
                                                _id: `fieldsMap.${field._id}`,
                                                name: field.name
                                            })
                                        )
                                    ]
                                }
                            />
                        </div>
                        {
                            checkIfDateAggregationRequired(pieChartDetails.groupingField) && (
                                <div className={styles.configureXAxisAggregator}>
                                    <DropdownSelect
                                        placeholder={'Combine data for every'}
                                        label={'Combine data for every'}
                                        selectedItem={pieChartDetails.dateGroupingFieldAggregationType}
                                        onItemSelect={
                                            (dateGroupingFieldAggregationType) => {
                                                pieChartDetails.dateGroupingFieldAggregationType =
                                                    dateGroupingFieldAggregationType as DateFieldAggregationType;
                                                setPieChartDetails(cloneDeep(pieChartDetails));
                                            }
                                        }
                                        isErrored={
                                            showChartConfigureFormFieldErrors &&
                                            (
                                                pieChartDetails.dateGroupingFieldAggregationType === null
                                                || pieChartDetails.dateGroupingFieldAggregationType === undefined
                                            )
                                        }
                                        menuData={
                                            Object.keys(DateFieldAggregationType)
                                                .map(
                                                    (dateAggregationTypeString : string) => DateFieldAggregationType[dateAggregationTypeString as keyof typeof DateFieldAggregationType]
                                                )
                                                .map(
                                                    (dateFieldAggregationType : DateFieldAggregationType) => ({
                                                        _id: dateFieldAggregationType,
                                                        name: displayNameForDateFieldAggregationType[dateFieldAggregationType]
                                                    })
                                                )
                                        }
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

            <div className={styles.yAxisConfigurationBox}>
                <div className={styles.configureYAxisText}> Configure Aggregation Field </div>

                <div className={styles.yAxisConfiguration}>
                    <div className={styles.configureYAxisLabel}>
                        <InputField
                            id="yAxisFieldLabel"
                            label="Label"
                            highlightFieldError={showChartConfigureFormFieldErrors}
                            isInValid={!pieChartDetails.aggregationFieldLabel}
                            initialValue={pieChartDetails.aggregationFieldLabel || ''}
                            type="text"
                            onChange={(e) => {
                                pieChartDetails.aggregationFieldLabel = e.target.value;
                                setPieChartDetails(cloneDeep(pieChartDetails));
                            }}
                            required={true}
                        />
                    </div>

                    <div className={styles.configureYAxisFieldAndAggregator}>
                        <div className={styles.configureYAxisField}>
                            <DropdownSelect
                                placeholder={'Workflow Field'}
                                label={'Workflow Field'}
                                selectedItem={pieChartDetails.aggregationField}
                                onItemSelect={
                                    (fieldId) => {
                                        pieChartDetails.aggregationField = fieldId;
                                        setPieChartDetails(cloneDeep(pieChartDetails));
                                    }
                                }
                                loading={workflowResponse?.fields === null}
                                isErrored={
                                    showChartConfigureFormFieldErrors &&
                                    (
                                        pieChartDetails.aggregationField === null
                                        || pieChartDetails.aggregationField === undefined
                                    )
                                }
                                menuData={
                                    workflowResponse.fields?.filter(
                                        field => field.type === FieldTypes.NUMBER
                                    ).map(
                                        (field) => ({
                                            _id: `fieldsMap.${field._id}`,
                                            name: field.name
                                        })
                                    )
                                }
                            />
                        </div>
                        <div className={styles.configureYAxisAggregator}>
                            <DropdownSelect
                                placeholder={'Aggregator'}
                                label={'Aggregator'}
                                selectedItem={pieChartDetails.aggregatorType}
                                onItemSelect={
                                    (aggregator) => {
                                        pieChartDetails.aggregatorType = aggregator as AggregatorType;
                                        setPieChartDetails(cloneDeep(pieChartDetails));
                                    }
                                }
                                isErrored={
                                    showChartConfigureFormFieldErrors &&
                                    (
                                        pieChartDetails.aggregatorType === null
                                        || pieChartDetails.aggregatorType === undefined
                                    )
                                }
                                menuData={
                                    Object.keys(AggregatorType)
                                        .map(
                                            (aggregatorTypeString : string) => AggregatorType[aggregatorTypeString as keyof typeof AggregatorType]
                                        )
                                        .map(
                                            (aggregatorType : AggregatorType) => ({
                                                _id: aggregatorType,
                                                name: displayNameForAggregatorType[aggregatorType]
                                            })
                                        )
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
