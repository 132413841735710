import {Col, Divider, Drawer, Row} from "antd";
import React from "react";
import {ProfileDrawerStrings} from '../../../contents/DisplayContent';
import styles from './ProfileDrawer.module.css';
import {
    getCurrentCompany,
    getCurrentUserEmail,
    getCurrentUserName,
} from "@eazy2biz/common-package-ui";
import classNames from "classnames";
import {GenericTag, Toast, UserProfileAvatar} from "@eazy2biz-ui/common-components";
import {GlobalUserResponse} from "@eazy2biz/common-util";
import {getGlobalUserByIdToken} from "@eazy2biz-ui/identities-ui/src/js/services/GlobalUserService";

const DescriptionItem = ({ title, content }: { title: string, content: JSX.Element | string }) => (
    <div className={styles.descriptionItem}>
        <p className={classNames("site-description-item-profile-p-label", styles.rowLabel)}>{title}:</p>
        {content}
    </div>
);

export const ProfileDrawer = (props: NavigationDrawerProps) => {
    const { isVisible, onDrawerClose } = props;
    const currentUserEmail = getCurrentUserEmail();
    const [phoneNumber, setPhoneNumber] = React.useState<string>('');
    const company = getCurrentCompany();

    React.useEffect(() => {
       getGlobalUserPhoneNumber();
    }, [])

    const getGlobalUserPhoneNumber = () => {
        getGlobalUserByIdToken()
        .then((res : GlobalUserResponse ) => {
            if(res.phoneNumber)
            setPhoneNumber("+" + res.phoneNumber.countryCode + " " + res.phoneNumber.phoneNumber);
        })
        .catch(()=>{
            Toast.errorString(ProfileDrawerStrings.ERROR_CONTACT_DETAILS);
        })
    }

    return (
        <Drawer
            width={640}
            placement="right"
            closable={false}
            onClose={onDrawerClose}
            visible={isVisible}
        >
            <div className={styles.profileDrawer}>
                <div className={styles.profileIconWrapper}>
                    <UserProfileAvatar size={100} />
                </div>
                <div className={styles.rowTitle} style={{ marginBottom: 24 }}>
                    {ProfileDrawerStrings.USER_PROFILE}
                </div>
                <Row className={styles.profileRow}>
                    <Col span={12}>
                        <DescriptionItem title="Full Name" content={getCurrentUserName()} />
                    </Col>
                    <Col span={12}>
                        <DescriptionItem title="Account" content={currentUserEmail} />
                    </Col>
                </Row>
                <Divider />
                <div className={styles.rowTitle}>
                    {ProfileDrawerStrings.COMPANY}
                    {
                        company?.planDetails?.subscriptionPlanType &&
                        <GenericTag title={company.planDetails.subscriptionPlanType.toString() + ' PLAN'} />
                    }
                </div>
                <Row className={styles.profileRow}>
                    <Col span={12}>
                        <DescriptionItem title="Company ID" content={company?._id?.toString() || ''} />
                    </Col>
                </Row>
                <Row className={styles.profileRow}>
                    <Col span={12}>
                        <DescriptionItem title="Name" content={company?.details.name} />
                    </Col>
                    <Col span={12}>
                        <DescriptionItem title="Membership" content="Owner" />
                    </Col>
                </Row>
                <Divider />
                <div className={styles.rowTitle}>Contacts</div>
                <Row className={styles.profileRow}>
                    <Col span={12}>
                        <DescriptionItem title="Email" content={currentUserEmail} />
                    </Col>
                    <Col span={12}>
                        <DescriptionItem title="Phone Number" content={phoneNumber} />
                    </Col>
                </Row>
            </div>
        </Drawer>
    );
};

type NavigationDrawerProps = {
    isVisible: boolean;
    onDrawerClose: () => void;
}
