import styles from "./PlcaeholderOptionComponent.module.css";

/**
 * Placeholder List Item
 * @param props
 * @constructor
 */
export const PlaceholderOptionComponent = (props: IPlaceholderOptionProps) => {
    const { onClick, optionName, optionIcon } = props;
    return (
        <div className={styles.placeholderOption} onClick={onClick}>
            <span className={styles.placeholderOptionIcon}>{optionIcon}</span>
            <span className={styles.placeholderOptionText}>{optionName}</span>
        </div>
    );
};

interface IPlaceholderOptionProps {
    onClick: (event: any) => void;
    optionName: string;
    optionIcon: JSX.Element;
}
