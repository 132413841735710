import {ActivityResponse, CardResponse} from "@eazy2biz/common-util";
import {useState} from "react";
import styles from './ActivityListComponent.module.css';
import {getAllActivity} from "../../../../services/ActivityService";
import {EmptyDataPlaceholderGraphic, Toast} from "@eazy2biz-ui/common-components";
import {ITEM_DETAILS_STRINGS} from "../../../../../contents/DisplayContent";
import { DotFilledIcon } from '@radix-ui/react-icons';
import {timeDifferenceString} from "@eazy2biz/common-package-ui";

/**
 * Time ordered list of Item Activities.
 * @param props
 * @constructor
 */
export const ActivityListComponent = (props: PropTypes) => {
    const [activities, setActivities] = useState<Array<ActivityResponse>>([]);

    useState(() => {
        getAllActivity(props.cardData._id, props.cardData.workflowId).then((response: ActivityResponse[]) => {
            setActivities(response);
        }).catch(() => Toast.errorString(ITEM_DETAILS_STRINGS.ERROR_FETCH_ACTIVITIES));
    });

    const renderActivityItem = (activity: ActivityResponse, idx: number) => {
        return (
            <div className={styles.auditWrapper} key={activity._id}>
                <div className={styles.activityStatus}>
                    <DotFilledIcon color={idx === 0 ? 'green' : 'black'} />
                </div>
                <div className={styles.auditDataWrapper}>
                    <div className={styles.auditTitle} >{activity.message}</div>
                    <p className={styles.auditTime} >{timeDifferenceString(new Date(activity.metaData.createdDate))}</p>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.auditListWrapper}>
            {activities.map(renderActivityItem)}
            {activities.length === 0 && <EmptyDataPlaceholderGraphic size={'s'} />}
        </div>
    );
};

type PropTypes = {
    cardData: CardResponse;
};
