import styles from "./ConfigurePdfTemplate.module.css";
import {
  BackNavigationLink, EmptyDataPlaceholderGraphic,
  GenericButton,
  GenericHeader,
  GenericTag,
  InputField, Spinner,
  TextVariableInput,
  Toast,
  UploadField,
  VariableField
} from "@eazy2biz-ui/common-components";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  CREATE_NEW_TEMPLATE,
  CREATE_TEMPLATE_STRINGS,
  TEMPLATE_SETTING_STRINGS
} from "../../../../contents/DisplayContent";
import { useHistory, useParams } from "react-router-dom";
import { TemplateResponse } from "@eazy2biz/common-util";
import { getTagTypeForTemplateStatus } from "../../../helpers/TemplateHelper";
import {
  getDefaultPdfTemplateConfiguration,
  PdfTemplateConfigurationType
} from "../../../helpers/pdf/PdfConfigurationHelper";
import { getPdfTemplateById, savePdfTemplate } from "../../../services/pdf/PDFTemplateService";
import { cloneDeep, set } from "lodash";
import { RcFile } from "antd/es/upload";
import { RoutesEnum } from "@eazy2biz-ui/common-package";
import { getCreatePdfTemplatePayload } from "../../../helpers/pdf/PdfServiceHelper";
import * as handlebars from "handlebars";

const HTML_FILE_TYPE = 'text/html';
const OPEN_HANDLE = '{{';
const CLOSE_HANDLE = '}}';
const EMPTY = '';
const SPACE = ' ';
const REGEX = /(\{\{.*?\}\})/g;

export const ConfigurePdfTemplate = () => {

  const history = useHistory();
  // @ts-ignore
  let { templateId } = useParams();

  const [pdfTemplateConfiguration, updatePdfTemplateConfiguration] =
    useState<PdfTemplateConfigurationType>(getDefaultPdfTemplateConfiguration());
  const [pdfTemplate, setPdfTemplate] = useState<TemplateResponse>();
  const [templateActionProgress, setTemplateActionProgress] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [variableFields, setVariableFields] = React.useState<VariableField[]>([]);
  const [htmlContent, setHtmlContent] = React.useState<string>();
  const [valuesInValidated, setValuesInValidated] = React.useState(true);

  useEffect(() => {
    if (templateId) {
      getPdfTemplateById(templateId)
        .then(res => {
          const pdfConfig: PdfTemplateConfigurationType = getDefaultPdfTemplateConfiguration(res);
          updatePdfTemplateConfiguration(pdfConfig);
          setPdfTemplate(res);
          setReadOnly(true);
          processFile(pdfConfig.content, false);
        })
        .catch((e) => {
          Toast.error(e, CREATE_TEMPLATE_STRINGS.TEMPLATE_NOT_FOUND);
        });
    }
  }, []);

  useEffect(() => {
    setValuesInValidated(false);
  }, [pdfTemplateConfiguration, variableFields]);

  const handleNameChange = (e: any) => {
    const templateConfigurations = cloneDeep(pdfTemplateConfiguration);

    templateConfigurations.name = e.target.value;

    updatePdfTemplateConfiguration(templateConfigurations);
  };

  const handleFileChange = async (e: any) => {
    const file: RcFile = e.target.value;
    if (file.type !== HTML_FILE_TYPE) {
      Toast.errorString(CREATE_TEMPLATE_STRINGS.PDF.NON_HTML_FILE_ERROR);
      return;
    }

    try {
      processFile(await file.text());
    } catch (e) {
      Toast.errorString(CREATE_TEMPLATE_STRINGS.PDF.PARSING_ERROR);
    }
  };

  const processFile = (fileText: string, updateTemplateConfig = true) => {
    const variables: VariableField[] = (fileText.match(REGEX) || [])
      .map((str) => {
        // Removing spaces from variables.
        fileText = fileText.replace(str, str.replace(SPACE, EMPTY));
        return str.replace(SPACE, EMPTY);
      })
      .map((str: string) => str.replace(OPEN_HANDLE, EMPTY).replace(CLOSE_HANDLE, EMPTY))
      .map(name => ({
        id: name,
        name,
        value: name,
      }));

    setHtmlContent(fileText);

    if (updateTemplateConfig) {
      const templateConfigurations = cloneDeep(pdfTemplateConfiguration);
      templateConfigurations.content = fileText;
      updatePdfTemplateConfiguration(templateConfigurations);
    }
    setVariableFields(variables);
  }

  const getFormattedHtmlContent = () => {
    let formattedContent = htmlContent || EMPTY;

    try {
      const template = handlebars.compile(formattedContent);
      const valuesMap = {};
      variableFields.forEach((variable) => set(valuesMap, variable.id, variable.value));
      return template(valuesMap);
    } catch (e) {
      Toast.errorString('Error parsing document, please ensure variables do not have special characters including spaces.');
    }

    return '';
  };

  const handleSavePdfTemplate = () => {
    setTemplateActionProgress(true);
    Toast.info(CREATE_TEMPLATE_STRINGS.TEMPLATE_CREATION_PROGRESS.PDF);
    savePdfTemplate(getCreatePdfTemplatePayload(pdfTemplateConfiguration, variableFields))
      .then(() => {
        Toast.success(CREATE_TEMPLATE_STRINGS.TEMPLATE_CREATION_SUCCESS.PDF);
        history.replace(RoutesEnum.TEMPLATE_MANAGEMENT_HOMEPAGE_TAB.replace(':tab', 'pdf'));
      })
      .catch(e => Toast.error(e, CREATE_TEMPLATE_STRINGS.TEMPLATE_CREATION_FAILURE.PDF))
      .finally(() => setTemplateActionProgress(false));
  };

  const handleUpdatePdfTemplate = () => {};

  const renderSubHeader = (): JSX.Element => {
    return <BackNavigationLink />
  };

  const renderHeader = (): JSX.Element => {
    return (
      <div className={styles.header}>
        {templateId && readOnly ? pdfTemplateConfiguration.name : CREATE_NEW_TEMPLATE.PDF}
        {templateId && readOnly && <GenericTag title={'Edit'} type={getTagTypeForTemplateStatus(pdfTemplate?.status)} />}
      </div>
    )
  };

  const renderPdfDetailsForm = () => {
    return (
      <div className={styles.templateDetailsForm}>
        <span className={styles.sectionHeader}>Pdf details</span>
        <InputField
          id={'name'}
          type={'text'}
          label={TEMPLATE_SETTING_STRINGS.NAME_HEADER}
          required
          disabled={readOnly}
          placeholder={TEMPLATE_SETTING_STRINGS.NAME_HEADER}
          initialValue={pdfTemplateConfiguration.name}
          onChange={handleNameChange} />
        <UploadField
          maxCount={1}
          label={CREATE_TEMPLATE_STRINGS.PDF.FILE_INPUT_LABEL}
          readOnly={readOnly}
          skipUpload
          onChange={handleFileChange}
        />
      </div>
    );
  };

  const renderVariablesForm = () => {
    return (
      <div className={styles.templateDetailsForm}>
        <span className={styles.sectionHeader}>Variables</span>
        <TextVariableInput
          key={'pdf'}
          hideHeader
          disabled={readOnly}
          variableFields={variableFields}
          setVariableFields={setVariableFields}
        />
        <div className={styles.actionButtons}>
          <GenericButton
            buttonText={templateId ? CREATE_TEMPLATE_STRINGS.UPDATE : CREATE_TEMPLATE_STRINGS.CREATE}
            disabled={readOnly || valuesInValidated}
            isLoading={templateActionProgress}
            onClick={templateId ? handleUpdatePdfTemplate : handleSavePdfTemplate} />
        </div>
      </div>
    );
  };

  const renderPreview = () => {
    return (
      <div className={styles.previewWrapper}>
        {!htmlContent && <EmptyDataPlaceholderGraphic text={'No Preview'}/>}
        {htmlContent && <div className={styles.htmlPage} dangerouslySetInnerHTML={{__html: getFormattedHtmlContent()}}/>}
      </div>
    );
  };

  const renderHelpSteps = () => {
    return (
      <div className={styles.pdfGeneratorSteps}>
        <p>Steps to generate HTML from doc:</p>
        <div className={styles.stepsList}>
          <p>1. Create your template on word or google docs.</p>
          <p>{`2. Use variables in {{variableName}}`}</p>
          <p>3. Export your template as PDF.</p>
          <p>4. Convert your PDF to HTML from <a href={'https://pdf.io/pdf2html/'} target={'_blank'}>https://pdf.io/pdf2html/</a></p>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <GenericHeader
        header={renderHeader()}
        subHeader={renderSubHeader} />
      <div className={styles.templateConfigurationWrapper}>
        <div className={styles.pdfConfigureWrapper}>
          {
            templateId && pdfTemplate === undefined ? <Spinner /> :
            <>
              {renderPdfDetailsForm()}
              {renderVariablesForm()}
            </>
          }
        </div>
        {renderPreview()}
      </div>
      {renderHelpSteps()}
    </div>
  );
};
