import {getSystemAction} from "../../../helpers/workflowBuilderHelpers/runtimeHelpers/actions/ActionHelper";
import {getSystemConditionsForApprovalStage} from "../../../helpers/workflowBuilderHelpers/runtimeHelpers/conditions/ConditionHelper";
import {Action, ActionResultTypes, Field, Stage, StageTypes} from "@eazy2biz/common-util";
import {StageOtherConfigurations} from "../../../entity/workflowBuilder/StageData";

/**
 * Get System Entry Actions for stage.
 * @param stageEntity
 * @returns Action[]
 */
const getSystemEntryActions = (stageEntity: Stage): Action[] => {
    switch (stageEntity.type) {
        case StageTypes.APPROVAL_STAGE:
        case StageTypes.FORM_STAGE: return [getSystemAction(stageEntity, ActionResultTypes.TODO_CREATE)];
        case StageTypes.CONDITIONAL_STAGE: return [getSystemAction(stageEntity, ActionResultTypes.TRIGGER_STAGE_COMPLETE)];
        default:
            return [];
    }
}

/**
 * Get System Exit Actions for stage.
 * @param stageEntity
 * @param fields Form Fields
 * @param stageOtherConfig
 * @param branchStageId
 * @returns Action[]
 */
const getSystemExitActions = (stageEntity: Stage, fields: Field[], stageOtherConfig: StageOtherConfigurations, branchStageId?: string): Action[] => {
    switch (stageEntity.type) {
        case StageTypes.APPROVAL_STAGE:
            return [getSystemAction(stageEntity, ActionResultTypes.STAGE_MOVEMENT, getSystemConditionsForApprovalStage(stageEntity._id, fields), {branchStageId})];
        case StageTypes.CONDITIONAL_STAGE:
            return [getSystemAction(stageEntity, ActionResultTypes.STAGE_MOVEMENT, stageOtherConfig.condition, {branchStageId})];
        default:
            return [];
    }
}

/**
 * Transform actions for stage.
 * 1) Adds system actions based on stage.
 * @param stageEntity
 * @param fields Form Fields
 * @param stageOtherConfig
 * @param branchStageId
 * @returns Action[]
 */
export const transformActionsToPayload = (stageEntity: Stage, fields: Field[], stageOtherConfig: StageOtherConfigurations, branchStageId?: string): Stage => {
    stageEntity.actions.stage_entry.push(...getSystemEntryActions(stageEntity));
    stageEntity.actions.stage_exit.push(...getSystemExitActions(stageEntity, fields, stageOtherConfig, branchStageId));

    return stageEntity;
}
