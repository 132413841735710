import {InputField, Toast} from "@eazy2biz-ui/common-components";
import {ReactElement, useState} from "react";
import {signIn} from "../../services/GlobalUserService";
import {emailField, passwordField} from "../../constants/keys";
import styles from "./SignInForm.module.css";
import {
  ChangePassword,
  EmailPlaceholderSignIn, ERROR_STRINGS,
  NewMember, PasswordPlaceholderSignIn, SignInHeaderText,
  SignUpLinkText, SUCCESS_STRINGS
} from "../../../contents/DisplayContent";
import SignInWithGoogle from "./SignInWithGoogle";
import {useHistory} from "react-router-dom";
import {EnvelopeClosedIcon, LockClosedIcon} from "@radix-ui/react-icons";
import { resolveFeatureFlag, RoutesEnum } from "@eazy2biz-ui/common-package";
import {SignInResponse} from "@eazy2biz/common-util";
import {IdentitiesWrapperModal} from "../common/identitiesWrapperModal/IdentitiesWrapperModal";
import {IdentityGenericButton} from "../common/identityGenericButton/IdentityGenericButton";
import {GenericException} from "@eazy2biz/common-package-ui";

const SignInForm: (props: PropsType) => JSX.Element = (props: PropsType): JSX.Element => {
    const [email, setEmail] = useState('');
    const [pwd, setPwd] = useState('');
    const [signInPending, setSignInPending] = useState<boolean>(false);

    const history = useHistory();

    const handleFieldsChange = (e: any) => {
        if (e.target.id === emailField) {
            setEmail(e.target.value);
        } else if (e.target.id === passwordField) {
            setPwd(e.target.value);
        }
    };

    /**
     * Handles successful user sign in.
     */
    const handleSubmit = () => {
        setSignInPending(true);
        signIn(email, pwd)
            .then((response: SignInResponse) => {
                props.onSuccessSignIn(response);
                Toast.success(SUCCESS_STRINGS.SIGN_IN);
            })
            .catch((error: GenericException) => Toast.error(error, ERROR_STRINGS.SIGN_IN))
            .finally(() => {
                setSignInPending(false);
            });
    };

    const onClose = () => {
        history.replace(RoutesEnum.APP_DASHBOARD);
    };

  const onResetPassword = () => {
    history.replace(RoutesEnum.CHANGE_PASSWORD);
  };

    const renderSignInFields = () => {
        return (
            <>
                <InputField
                    id={emailField}
                    bordered={true}
                    label={'Email Id'}
                    placeholder={EmailPlaceholderSignIn}
                    className={styles.signInFieldInput}
                    type={"email"}
                    prefix={<EnvelopeClosedIcon className={styles.signInFieldIcon} />}
                    onChange={handleFieldsChange}
                />

                <InputField
                    id={passwordField}
                    bordered={true}
                    label={'Password'}
                    placeholder={PasswordPlaceholderSignIn}
                    className={styles.signInFieldInput}
                    type={"password"}
                    prefix={<LockClosedIcon className={styles.signInFieldIcon} />}
                    onChange={handleFieldsChange}
                />
            </>
        );
    };

    const renderBody = (): ReactElement => {
        return (
            <>
                {
                    resolveFeatureFlag('GOOGLE_SIGN_IN') &&
                        <>
                            <SignInWithGoogle />
                            <div className={styles.signInOptionsDivider}>or</div>
                        </>
                }

                <div>
                    <form>
                        {renderSignInFields()}
                    </form>
                </div>
                <>
                    <IdentityGenericButton isLoading={signInPending} onClick={handleSubmit} buttonText="Log In"/>
                    <div className={styles.footerText}>
                      {ChangePassword.ForgotPassword}
                      <span className={styles.signUpLink} onClick={onResetPassword}>{ChangePassword.ResetPasswordHere}</span>
                    </div>
                    <div className={styles.footerText}>{NewMember} <span className={styles.signUpLink} onClick={props.navigateToSignUpPage}>{SignUpLinkText}</span></div>
                </>
            </>
        );
    };

    return (
      <IdentitiesWrapperModal
          title={SignInHeaderText}
          onClose={onClose}
          getBody={renderBody}
      />
    );
}

type PropsType = {
    onSuccessSignIn: (response: SignInResponse) => void;
    onClose: () => void;
    navigateToSignUpPage: () => void;
};

export default SignInForm;
