import {memo} from 'react';
import {Handle, Position} from 'react-flow-renderer';
import {StageData} from "../../../entity/workflowBuilder/StageData";
import { LabelTypes } from "../types/LabelTypes";

type NodeProps = {
    data: StageData;
};


const getLabelColour = (labelType: LabelTypes) => {
  switch (labelType) {
    case LabelTypes.APPROVED:
    case LabelTypes.TRUE:
      return 'green';
    default:
      return 'red';
  }
};

const ReadOnlyLabel = memo((props: NodeProps) => {
    const nodeData = props.data as StageData;
    return (
        <div
            style={{
                width: "210px",
                height: "20px",
                textAlign: "center",
                color: getLabelColour(nodeData.details.type as LabelTypes),
                fontSize: "10px"
            }}>
            <Handle
                type="target"
                position={Position.Top}
                id="a"
                style={{ background: 'transparent' }}
                isConnectable
            />
                {nodeData.details.label}
            <Handle
                type="source"
                position={Position.Bottom}
                id="b"
                style={{ background: 'transparent' }}
                isConnectable
            />
        </div>
    );
});

export default ReadOnlyLabel;
