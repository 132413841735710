import {ClockIcon, MixIcon, PersonIcon, TokensIcon, LayersIcon} from "@radix-ui/react-icons";
import {getFormattedDate} from "@eazy2biz/common-package-ui";
import {AssigneeNameTag} from "../AssigneeNameTag/AssigneeNameTag";
import styles from './ModalSubHeaderComponent.module.css';
import {MODAL_SUB_HEADER} from "../../../contents/DisplayContent";

/**
 * Generic Modal Sub header.
 * @param props
 * @constructor
 */
export const ModalSubHeaderComponent = (props: PropTypes) => {
    const {number, title, name, dueOn, assignees = [], createdBy, createdOn, assigneeUserGroupIds = []} = props;

    const renderNumber = () => {
        return number ? (
            <div className={styles.modalSubtitleContent}>
                <LayersIcon/>
                &nbsp;{'#' + number}
            </div>
        ) : null;
    };

    const renderTitle = () => {
        return title ? (
            <div className={styles.modalSubtitleContent}>
                <TokensIcon/>
                &nbsp;{title}
            </div>
        ) : null;
    };

    const renderName = () => {
        return name ? (
            <div className={styles.modalSubtitleContent}>
                <MixIcon/>
                &nbsp;{name}
            </div>
        ) : null;
    };

    const renderDueOn = () => {
        return dueOn ? (
            <div className={styles.modalSubtitleContent}>
                <ClockIcon/>
                &nbsp;{MODAL_SUB_HEADER.DUE_ON + " " + getFormattedDate(dueOn)}
            </div>
        ) : null;
    };


    const renderAssignees = () => {
        return (assignees.length > 0) || (assigneeUserGroupIds.length > 0) ? (
            <div className={styles.modalSubtitleContent}>
                <PersonIcon/>
                &nbsp;{MODAL_SUB_HEADER.ASSIGNED + " "}
                <span>
                    &nbsp;
                    <AssigneeNameTag ids={assignees} userGroupIds={assigneeUserGroupIds}/>
                </span>
            </div>
        ) : null;
    };

    const renderCreatedBy = () => {
        return createdBy ? (
            <div className={styles.modalSubtitleContent}>
                <PersonIcon/>
                &nbsp;{MODAL_SUB_HEADER.CREATED + " "}
                <span>
                    &nbsp;
                    <AssigneeNameTag ids={[createdBy]} />
                </span>
            </div>
        ) : null;
    };

    const renderCreatedOn = () => {
        return createdOn ? (
            <div className={styles.modalSubtitleContent}>
                <ClockIcon/>
                &nbsp;{MODAL_SUB_HEADER.CREATED_ON + " " + getFormattedDate(createdOn)}
            </div>
        ) : null;
    };

    return (
        <>
            <div className={styles.modalSubtitleRow}>
                {renderNumber()}
                {renderTitle()}
                {renderName()}
                {renderDueOn()}
                {renderAssignees()}
            </div>
            <div className={styles.modalSubtitleRow}>
                {renderCreatedBy()}
                {renderCreatedOn()}
            </div>
        </>
    );
};

type PropTypes = {
    number?: number | string;
    title?: string;
    name?: string;
    dueOn?: Date;
    assignees?: string[];
    assigneeUserGroupIds?: string[];
    createdBy?: string;
    createdOn?: Date;
}
