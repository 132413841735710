import * as React from 'react';
import { GenericTable } from "@eazy2biz-ui/common-components"
import { TemplateResponse } from "@eazy2biz/common-util";
import { getColumnsForPDFTemplatesList } from "../../../config/pdf/PDFTemplatesListConfig";
import { getTableDataForPDFTemplatesList } from "../../../helpers/pdf/PDFTemplateListHelper";
import { getAllPDFTemplates } from "../../../services/pdf/PDFTemplateService";
import { RoutesEnum } from "@eazy2biz-ui/common-package";
import { useHistory } from "react-router-dom";

export const TemplatesList = (): JSX.Element => {
  const columns = getColumnsForPDFTemplatesList();
  const [tableData, setTableData] = React.useState<any[]>([]);

  const history = useHistory();

  React.useEffect(() => {
    getAllPDFTemplates().then((response: TemplateResponse[]) => {
      setTableData(
        getTableDataForPDFTemplatesList(response)
      );
    });
  }, []);

  const handleTemplateSelect = (templateId: string) => {
    history.push(RoutesEnum.UPDATE_PDF_TEMPLATE.replace(':templateId', templateId));
  };

  return (
    <GenericTable
      columns={columns}
      data={tableData}
      loading={false}
      totalItems={tableData.length}
      defaultActionEnabled
      onDefaultActionClick={handleTemplateSelect}
    />
  );
};
