import styles from './AssigneeNameTag.module.css';
import {getUserGroupNameById, getUserNameById} from "@eazy2biz/common-package-ui";
import classNames from "classnames";

/**
 * Generic Assignee Tag Component to display assignee tags for users and user groups.
 * @param props
 * @constructor
 */
export const AssigneeNameTag = (props: PropTypes) => {
    const {ids, userGroupIds = []} = props;

    const renderNameTag = (name: string, isUserGroupTag?: boolean) => {
        if (name !== '') {
            return (<span key={name} className={classNames(styles.nameTag, {[styles.groupNameTag]: isUserGroupTag})}>{'@' + name}</span>)
        }
    };

    const renderUserNameTag = (id: string) => {
        return renderNameTag(getUserNameById(id, ''));
    };

    const renderUserGroupNameTag = (id: string) => {
        getUserGroupNameById(id, '')
        return renderNameTag(getUserGroupNameById(id, ''), true);
    };

    return (
        <>
            {ids.map(renderUserNameTag)}
            {userGroupIds.map(renderUserGroupNameTag)}
        </>
    );
};

type PropTypes = {
    ids: string[];
    userGroupIds?: string[];
};
