import {RestServices} from "@eazy2biz/common-package-ui";
import {
    ChartCreateRequest,
    ChartResponse, ChartSimulateRequest, ChartUpdateAttributes,
    EntitySearch, GetChartDataRequest,
} from "@eazy2biz/common-util";


const CHART_SERVICE = () => RestServices.getInstance().getChartService();

export const getAllChartsForReport = (reportId: string) : Promise<ChartResponse[]> => {
    return CHART_SERVICE().getAllChartsForReport(reportId);
}
export const getChartsCountForReport = (reportId: string) : Promise<number> => {
    return CHART_SERVICE().getChartsCountForReport(reportId);
}
export const getFilteredChartsForReport = (entitySearch: EntitySearch, reportId: string) : Promise<ChartResponse[]> => {
    return CHART_SERVICE().getFilteredChartsForReport(entitySearch, reportId);
}
export const getFilteredChartsForReportCount = (entitySearch: EntitySearch, reportId: string) : Promise<number> => {
    return CHART_SERVICE().getFilteredChartsForReportCount(entitySearch, reportId);
}
export const simulateChartForReport = (chartSimulateRequest: ChartSimulateRequest) : Promise<any> => {
    return CHART_SERVICE().simulateChartForReport(chartSimulateRequest);
}
export const createChart = (chartCreateRequest: ChartCreateRequest) : Promise<ChartResponse> => {
    return CHART_SERVICE().createChart(chartCreateRequest);
}
export const getChartByIdForReport = (chartId: string, reportId: string) : Promise<ChartResponse> => {
    return CHART_SERVICE().getChartByIdForReport(chartId, reportId);
}
export const getChartDataByIdForReport = (getChartDataRequest: GetChartDataRequest, chartId: string, reportId: string) : Promise<any[]> => {
    return CHART_SERVICE().getChartDataByIdForReport(getChartDataRequest, chartId, reportId);
}
export const patchChartByIdForReport = (chartId: string, reportId: string, chartUpdateAttributes: ChartUpdateAttributes) : Promise<ChartResponse> => {
    return CHART_SERVICE().patchChartByIdForReport(chartId, reportId, chartUpdateAttributes);
}
export const deleteChartsByIdForReport = (chartId: string, reportId: string) : Promise<ChartResponse> => {
    return CHART_SERVICE().deleteChartsByIdForReport(chartId, reportId);
}
