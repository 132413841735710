import {Pagination} from "antd";
import React from "react";
import styles from './PaginationComponent.module.css';
import {DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE} from "../Table";

export const PaginationComponent = (props: PropTypes) => {

    return (
        <Pagination
            className={styles.paginationWrapper}
            size="small"
            defaultPageSize={DEFAULT_PAGE_SIZE}
            defaultCurrent={DEFAULT_PAGE_NUMBER}
            onChange={props.onPageChange}
            total={props.total}
            showSizeChanger />
    );
};

type PropTypes = {
    total: number;
    onPageChange: (page: number, pageSize: number) => void;
};