import {Component} from "react";
import {Toast} from "@eazy2biz-ui/common-components";
import {companyNameField, descriptionField, emailField} from "../../constants/keys";
import CompanyDetails from "./CompanyDetails";
import {signInToTheCompany} from "../../helpers/AuthHelper";
import {ERROR_STRINGS, SUCCESS_STRINGS} from "../../../contents/DisplayContent";
import {IdentitiesWrapperModal} from "../common/identitiesWrapperModal/IdentitiesWrapperModal";
import {IdentityGenericButton} from "../common/identityGenericButton/IdentityGenericButton";
import {CompanyResponse, GlobalUserCompanyMembership} from "@eazy2biz/common-util";
import {createCompany} from "../../services/CompanyService";

/**
 * New Company Creation Modal.
 */
export class CompanySignUpComponent extends Component<PropTypes, StateType>{

    constructor(props: PropTypes) {
        super(props);
        this.state = {
            companyDetails: {},
            creatingNewCompany: false
        };
    }

    handleFieldsChange = (e: any) => {
        this.setState({
            companyDetails: {
                ...this.state.companyDetails,
                [e.target.id]: e.target.value
            }
        });
    };

    /**
     * Handles create Company
     */
     handleSubmit = () => {
         this.setState({creatingNewCompany: true});
        createCompany(
            this.state.companyDetails[companyNameField],
            this.state.companyDetails[descriptionField],
            this.state.companyDetails[emailField]
        ).then((company : CompanyResponse) => {
            Toast.success(SUCCESS_STRINGS.NEW_COMPANY);
            this.handleCompanySignIn(company);
        }).catch(() => {
            Toast.errorString(ERROR_STRINGS.NEW_COMPANY);
        }).finally(() => this.setState({creatingNewCompany: false}));
    };

    /**
     * Signs into the newly created company.
     * @param company
     */
    handleCompanySignIn = (company: CompanyResponse) => {
         signInToTheCompany(company).then((membership : GlobalUserCompanyMembership | undefined) => {
             if (membership) {
                 Toast.success(SUCCESS_STRINGS.COMPANY_SIGN_IN);
                 this.props.onCompanySelect(membership);
             } else {
                 this.handleError();
             }
         }).catch(this.handleError)
             .finally(() => this.setState({creatingNewCompany: false}));
     };


     handleError = () => {
         Toast.errorString(ERROR_STRINGS.COMPANY_SIGN_IN);
     };

    renderCompanyDetails = () => {
        return(
            <>
                <CompanyDetails handleFieldsChange={this.handleFieldsChange}/>
                <IdentityGenericButton isLoading={this.state.creatingNewCompany} onClick={this.handleSubmit} buttonText="Create Company"/>
            </>
        );
    }

    render() {
        return (
            <IdentitiesWrapperModal
                title={'Create Company'}
                onClose={this.props.onClose}
                getBody={this.renderCompanyDetails}
            />
        );
    }
}

type PropTypes = {
    onClose: () => void;
    onCompanySelect: (companyMembership: GlobalUserCompanyMembership) => void;
};

type StateType = {
    companyDetails: any;
    creatingNewCompany: boolean;
};
