import {useEffect, useState} from "react";
import {getAllCustomerTables} from "../../services/CustomerTableService";
import {CardList, CardPropTypes, Toast} from "@eazy2biz-ui/common-components";
import {CustomerTableResponse, SecurityGroupFeatures} from "@eazy2biz/common-util";
import {UserActions, UserAuthorizationService} from "@eazy2biz/common-package-ui";
import {CustomerTableCardSubTitle} from "./customerTableCardSubTitle/CustomerTableCardSubTitle";
import {CustomerTableColumnsBuilder} from "../customerTableColumnsBuilder/CustomerTableColumnsBuilder";

/**
 * List of customer table cards
 * @constructor
 */
export const CustomerTableList = (props: PropTypes) => {
    const [customerTables, setCustomerTables] = useState<CustomerTableResponse[]>([]);
    const [createTableAllowed, setCreateTableAllowed] = useState(false);
    const [showCreateTableBuilder, setShowCreateTableBuilder] = useState(false);
    const [refreshTableList, setRefreshTableList] = useState(true);

    useEffect(() => {
        // TODO: FIX SecurityGroupFeatures
        UserAuthorizationService.getInstance().isActionAllowed(UserActions.CREATE, SecurityGroupFeatures.USERS)
            .then((allowed) => setCreateTableAllowed(allowed));
    }, []);

    useEffect(() => {
        refreshTableList && getAllCustomerTables().then((response) => {
            setCustomerTables(response);
            response.length > 0 && props.setSelectedTable(response[0]);
        }).catch(() => Toast.errorString());
        refreshTableList && setRefreshTableList(false);
    }, [refreshTableList]);

    const handleCardOnClick = (table: CustomerTableResponse) => {
        props.setSelectedTable(table);
    };

    const toggleCreateTableBuilder = () => {
        setShowCreateTableBuilder(!showCreateTableBuilder);
    };

    const getTableCardProps = (): CardPropTypes[] => {
        return customerTables.map((customerTable) => (
            {
                key: customerTable._id,
                cardTitle: customerTable.details.name,
                cardSubTitle: (<CustomerTableCardSubTitle customerTableId={customerTable._id} />),
                onCardBodyClick: () => handleCardOnClick(customerTable),
                selected: customerTable._id === (props.selectedTable && props.selectedTable._id)
            }
        ));
    };

    const handleTableCreateSuccess = () => {
        setRefreshTableList(true);
        toggleCreateTableBuilder();
    };

    return (
        <>
            <CardList
                cardProps={getTableCardProps()}
                createCustomDisabled={!createTableAllowed}
                onCreateCustom={toggleCreateTableBuilder} />
            {showCreateTableBuilder && <CustomerTableColumnsBuilder onSuccess={handleTableCreateSuccess} onToggleFormBuilder={toggleCreateTableBuilder}/>}
        </>
    );
};

type PropTypes = {
    selectedTable?: CustomerTableResponse,
    setSelectedTable: (table: CustomerTableResponse) => void;
};
