import {UserPlaceholderEntity} from "../entity/UserPlaceholderEntity";
import {PlaceholderOption} from "../entity/PlaceholderOption";
import {get} from "lodash";
import Toast from "../../Toast/Toast";
import {PLACEHOLDER_SELECTION_ERROR} from "../../../../contents/DisplayContent";

export const transformUserToOptionList = (users: UserPlaceholderEntity[], userPlaceholderProperties: object, key: string): PlaceholderOption[] => {
    try {
        const user: UserPlaceholderEntity = users.filter((user: UserPlaceholderEntity) => user._id === key)[0];
        return Object.keys(userPlaceholderProperties).map((property) => {
            return {
                name: get(user, property),
                key: property
            } as PlaceholderOption;
        });
    } catch {
        Toast.errorString(PLACEHOLDER_SELECTION_ERROR);
    }

    return [];
}

export const transformUsersListToOptions = (users: UserPlaceholderEntity[]): PlaceholderOption[] => {
    return users.map((user: UserPlaceholderEntity) => {
        return {
            key: user._id,
            name: user.name
        } as PlaceholderOption;
    });

    return [];
}
