import {
    CardResponse,
    Field,
    FieldTypes,
    Stage,
    StageTypes,
    WorkflowResponse
} from "@eazy2biz/common-util";
import styles from './ItemDataComponent.module.css';
import {ApprovalFieldDisplay, CardFormFieldComponent} from "../../../index";
import React, {useEffect} from "react";
import {FormFieldInput, getAllStagesFromStart} from "@eazy2biz/common-package-ui";
import { StageHeaderComponent } from "./stageHeaderComponent/StageHeaderComponent";

/**
 * To display Stages data.
 * @param props
 * @constructor
 */
export const ItemDataComponent = (props: PropTypes) => {
    const {stageId, workflow, cardData, editMode} = props;

    if (!stageId || !workflow) {
        return null;
    }

    const [stageArray, setStageArray] = React.useState<Stage[]>([]);


    useEffect(() => {
        setStageArray(getAllStagesFromStart(workflow, cardData));
    }, []);

    const getData = (fieldId: string): string => {
        if (cardData.fieldsMap) {
            // @ts-ignore
            return cardData.fieldsMap[fieldId] || '';
        }

        return '';
    };

    const onChange = (id : string, value : any) => {
        const fieldsMapCopy: FormFieldInput[] = props.fieldsMap ?? [];
        const field = fieldsMapCopy.find((value: FormFieldInput) => value.id === id);
        if (field) {
          field.value = value;
        } else {
            fieldsMapCopy.push({id, value});
        }
        props.setFieldsMap && props.setFieldsMap(fieldsMapCopy);
    }


    const renderField = (field: Field) => {
        if (field.type === FieldTypes.APPROVAL) {
            return <ApprovalFieldDisplay field={field} value={getData(field._id)}/>
        }

        return (
            <CardFormFieldComponent
                key={field._id}
                field={field}
                defaultValue={getData(field._id)}
                isReadOnly={!editMode}
                onChange={onChange}
            />
        );
    };


    const renderFieldsForStage = (stage: Stage) => {
        if (stage._id !== stageId && stage.type !== StageTypes.EXIT_STAGE) {
            const stageFields = workflow.fields.filter((field) => field.ownerId === stage._id);

            if (stageFields.length !== 0) {
                return (
                    <div className={styles.itemDataStageWrapper} key={stage._id}>
                        <StageHeaderComponent stage={stage} cardData={cardData} />
                        <div className={styles.stageFieldsWrapper}>
                            {stageFields.map(renderField)}
                        </div>
                    </div>
                );
            }
        }

        return null;
    }

    return(
        <div>
            {stageArray.map(renderFieldsForStage)}
        </div>
    );
}

type PropTypes = {
    workflow?: WorkflowResponse;
    cardData: CardResponse;
    stageId?: string;
    editMode?: boolean;
    fieldsMap?: FormFieldInput[];
    setFieldsMap?: (fieldsMap: Array<any>) => void;
};
