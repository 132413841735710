import {
    AggregatorType,
    BaseChartDetails, ChartType,
    DateFieldAggregationType,
    LineChartDetails,
    ReportEntityType
} from "@eazy2biz/common-util";

import styles from './ConfigureLineChartDetails.module.css'
import {ConfigureWorkflowLineChartDetails} from "./workflow/ConfigureWorkflowLineChartDetails";
import {useEffect, useState} from "react";
import {Builder} from "builder-pattern";

export const ConfigureLineChartDetails = (
    props : {
        entityType: ReportEntityType;
        entityId: string;
        entity: any;
        showChartConfigureFormFieldErrors: boolean;
        handleChartDetailsChange: (newChartDetails : BaseChartDetails<ChartType>) => void;
    }
) : JSX.Element => {

    const {
        entityType,
        entityId,
        entity,
        showChartConfigureFormFieldErrors,
        handleChartDetailsChange,
    } = props;

    const [lineChartDetails , setLineChartDetails] = useState<LineChartDetails>(Builder<LineChartDetails>().build())

    const [subGroupingEnabled , setSubGroupingEnabled] = useState<boolean>(false)

    useEffect(() => {
        handleChartDetailsChange(lineChartDetails);
    }, [lineChartDetails])

    const getLineChartComponent = () => {
        switch (entityType) {
            case ReportEntityType.WORKFLOW:
                return (
                    <ConfigureWorkflowLineChartDetails
                        entityType={entityType}
                        entityId={entityId}
                        workflowResponse={entity}
                        showChartConfigureFormFieldErrors={showChartConfigureFormFieldErrors}

                        lineChartDetails={lineChartDetails}
                        setLineChartDetails={setLineChartDetails}
                        subGroupingEnabled={subGroupingEnabled}
                        setSubGroupingEnabled={setSubGroupingEnabled}
                    />
                )
        }
    }

    return (
        <>
            {
                getLineChartComponent()
            }
        </>
    )
}
