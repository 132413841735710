import {Avatar} from "antd";
import React from "react";
import { getCurrentUserName } from "@eazy2biz/common-package-ui";

/**
 * Round User Profile Avatar
 * @param props
 * @constructor
 */
export const UserProfileAvatar = (props: PropTypes) => {
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
  let initials = [...getCurrentUserName().matchAll(rgx)] || [];
  initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase();

  return <Avatar size={props.size} >{initials}</Avatar>;
};

type PropTypes = {
  size?: number;
};
