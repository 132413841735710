import {ALERT_MODAL} from "../../../contents/DisplayContent";
import {useAlertModal} from "./AlertModalProvider";
import {BaseAlertModal} from "./BaseAlertModal";
import {AlertModalTypes} from "./AlertModalTypes";

/**
 * Generic Alert modal
 * @param props
 * @constructor
 */
export const AlertModal = () => {
  const {alertModalConfig, setAlertModalConfig} = useAlertModal();

  if (!alertModalConfig) {
    return null;
  }

  const {
    type = AlertModalTypes.ALERT,
    title = ALERT_MODAL.TITLE,
    message,
    onCancel,
    showCancelButton,
    onConfirm,
    cancelButtonText = ALERT_MODAL.CANCEL,
    confirmButtonText = ALERT_MODAL.CONFIRM,
  } = alertModalConfig;

  return (
      <BaseAlertModal
          type={type}
          title={title}
          message={message}
          showCancelButton={showCancelButton}
          cancelButtonText={cancelButtonText}
          onCancel={() => {
            setAlertModalConfig();
            onCancel && onCancel();
          }}
          confirmButtonText={confirmButtonText}
          onConfirm={() => {
            setAlertModalConfig();
            onConfirm && onConfirm();
          }}
      />
  );
};

