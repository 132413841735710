import { generateTableDataFromItems, RowMetaData } from "@eazy2biz-ui/common-components";
import { getFormattedDate, humanizeSnakeCase } from "@eazy2biz/common-package-ui";
import { TemplateResponse } from "@eazy2biz/common-util";
import { getColumnsForWhatsappTemplatesList, WhatsappTemplatesListHeaders } from "../../config/whatsapp/WhatsappTemplatesListConfig";

const getRowMetaDataForTemplate = (template: TemplateResponse): RowMetaData => {
  return {
      id: template._id ?? ''
  };
};

const getValueForTemplate = (template: TemplateResponse, key: string) => {
  switch (key) {
      case WhatsappTemplatesListHeaders.TEMPLATE_NAME:
        return template.details.name;
      case WhatsappTemplatesListHeaders.LAST_UPDATED:
        return getFormattedDate(template.metaData.lastUpdatedDate);
      case WhatsappTemplatesListHeaders.STATUS:
        return humanizeSnakeCase(template.status.toLocaleLowerCase());
      default:
        return "";
  }
};

export const getTableDataForWhatsappTemplatesList = (templates: TemplateResponse[]) => {
  return generateTableDataFromItems(
    templates,
    getColumnsForWhatsappTemplatesList(),
    getValueForTemplate,
    getRowMetaDataForTemplate
  );
};
