import { RadioSelectOptionType } from "@eazy2biz-ui/common-components";
import { WhatsappTemplateTypes } from "@eazy2biz/common-util";
import { TEMPLATE_SETTING_STRINGS } from "../../../contents/DisplayContent";

export const getTemplateCategoryOptions = (): RadioSelectOptionType[] => {
  return [
    {
      header: TEMPLATE_SETTING_STRINGS.CATEGORY_STRINGS.UTILITY_OPTION_HEADER,
      value: WhatsappTemplateTypes.UTILITY,
      subHeader: TEMPLATE_SETTING_STRINGS.CATEGORY_STRINGS.UTILITY_OPTION_SUB_HEADER
    },
    {
      header: TEMPLATE_SETTING_STRINGS.CATEGORY_STRINGS.MARKETING_OPTION_HEADER,
      value: WhatsappTemplateTypes.MARKETING,
      subHeader: TEMPLATE_SETTING_STRINGS.CATEGORY_STRINGS.MARKETING_OPTION_SUB_HEADER
    },
    // {
    //   header: TEMPLATE_SETTING_STRINGS.CATEGORY_STRINGS.OTP_OPTION_HEADER,
    //   value: WhatsappTemplateTypes.OTP,
    //   subHeader: TEMPLATE_SETTING_STRINGS.CATEGORY_STRINGS.OTP_OPTION_SUB_HEADER
    // },
  ]
}
