import { ClockIcon, PersonIcon } from '@radix-ui/react-icons';
import { CardResponse, CardStageSystemFields, Stage } from '@eazy2biz/common-util';
import { getFormattedDateTime } from '@eazy2biz/common-package-ui';
import { get } from 'lodash';
import styles from './StageHeaderComponent.module.css';
import { AssigneeNameTag } from '../../AssigneeNameTag/AssigneeNameTag';
import { CARD_STAGE_STRING } from '../../../../contents/DisplayContent';
/**
 * Generic Stage header component
 * @param stage
 * @constructor
 */
export const StageHeaderComponent = (props: PropTypes) => {
  const { stage } = props;

  const stageSystemFields = get(props.cardData.stageSystemFieldsMap, stage._id, {});

  const renderStageCompletedBy = (completedById: string) => {
    return (
      <div className={styles.stageSubtitleContent}>
        <PersonIcon />
        &nbsp;{CARD_STAGE_STRING.ADDED_BY}
        <span>
          &nbsp;
          <AssigneeNameTag ids={[completedById]} />
        </span>
      </div>
    );
  };

  const renderStageCompletedOn = (completedOn: any) => {
    return (
      <div className={styles.stageSubtitleContent}>
        <ClockIcon />
        &nbsp;{CARD_STAGE_STRING.ADDED_TIME}
        {getFormattedDateTime(completedOn)}
      </div>
    );
  };

  return (
    <div className={styles.stageViewContainer}>
      <p className={styles.stageNameLabel}>{stage.details.name}</p>
      <div className={styles.stageView}>
        {renderStageCompletedBy(stageSystemFields[CardStageSystemFields.ADDED_BY])}
        {renderStageCompletedOn(stageSystemFields[CardStageSystemFields.ADD_TIME])}
      </div>
    </div>
  );
};

type PropTypes = {
  stage: Stage;
  cardData: CardResponse;
};
