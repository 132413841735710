import {DropdownMultiSelect} from "../../genericComponents/select/DropdownMultiSelect";
import React, {useEffect, useState} from "react";
import {CachedStore, StoreEntityTypes} from "@eazy2biz/common-package-ui";
import {DropdownSelect, MenuData, Toast} from "../../../index";
import styles from './AssigneeSelect.module.css';
import classNames from "classnames";
import {LocalUserResponse, UserGroupResponse} from "@eazy2biz/common-util";
import {
    UserOutlined,
    UsergroupAddOutlined
} from '@ant-design/icons';

/**
 * Assignee Select Component
 * @param props
 * @constructor
 */
export const AssigneeSelect = (props: PropTypes) => {
    const {multiSelect = true, required = true, disabled, localUser = true, userGroups, selectedAssigneeUserGroupIds = []} = props;

    const [userList, setUserList] = useState<MenuData[]>([]);
    const [userGroupList, setUserGroupList] = useState<MenuData[]>([]);

    const convertLocalUserListToData = (localUsers: LocalUserResponse[]): MenuData[] => {
        if (props.filterUser) {
            localUsers = localUsers.filter(props.filterUser);
        }

        return localUsers.map((localUser) => ({
            _id: localUser._id,
            name: localUser.name,
            renderIcon: () => <UserOutlined className={props.iconClassName}/>,
        }));
    };

    const convertUserGroupListToData = (userGroups: UserGroupResponse[]): MenuData[] => {
        if (props.filterUserGroup) {
            userGroups = userGroups.filter(props.filterUserGroup);
        }

        return userGroups.map((userGroup) => ({
            _id: userGroup._id,
            name: userGroup.details.name,
            renderIcon: () => <UsergroupAddOutlined />,
        }));
    };

    useEffect(() => {
        if(localUser){
            CachedStore.getStoreList(StoreEntityTypes.LOCAL_USERS)
            .then((response: LocalUserResponse[]) => {
                setUserList(convertLocalUserListToData(response || []));
            })
            .catch(() => {
                Toast.errorString();
            });
        }

        if(userGroups){
            CachedStore.getStoreList(StoreEntityTypes.USER_GROUPS)
                .then((userGroupResponseList = []) => {
                    setUserGroupList(convertUserGroupListToData(userGroupResponseList));
            });

        }

    }, []);

    const getAssigneeList = () => [...userList, ...userGroupList];

    const getInitialAssigneeList = () => [...props.selectedAssignees, ...selectedAssigneeUserGroupIds];

    const handleAssigneeSelect = (ids: string[]) => {
        const userIds = ids.filter((id) => userList.map(data => data._id).includes(id));
        const userGroupIds = ids.filter((id) => userGroupList.map(data => data._id).includes(id));
        props.onAssigneesSelect(userIds, userGroupIds);
    }

    const renderDropdown = () => {
        if (multiSelect) {
            return (
                <DropdownMultiSelect
                    className={props.dropdownClassName}
                    placeholder={"Select Employee or Employee group"}
                    onItemSelect={handleAssigneeSelect}
                    menuData={getAssigneeList()}
                    selectedItem={getInitialAssigneeList()}
                    disabled={disabled}
                />
            );
        } else {
            return (
                <DropdownSelect
                    className={props.dropdownClassName}
                    placeholder={"Select Employee or Employee group"}
                    onItemSelect={(val) => handleAssigneeSelect([val])}
                    menuData={getAssigneeList()}
                    disabled={disabled}
                    selectedItem={getInitialAssigneeList()[0] || ''}
                />
            );
        }
    };

    return (
        <div className={props.className}>
            {!props.hideLabel &&
              <span className={classNames(styles.assigneeTitle, {[styles.assigneeRequired]: required})}>{props.label || "Assign To"}</span>}
            <div className={styles.assigneeDropdown}>
                {renderDropdown()}
            </div>
        </div>
    )

};

type PropTypes = {
    label?: string;
    hideLabel?: boolean;
    multiSelect?: boolean;
    onAssigneesSelect: (ids: string[], userGroupIds?: string[]) => void;
    selectedAssignees: string[];
    selectedAssigneeUserGroupIds?: string[];
    required?: boolean;
    disabled?: boolean;
    localUser?: boolean;
    userGroups?: boolean;
    className?: string;
    dropdownClassName?: string;
    iconClassName?: string;
    filterUser?: (localUser: LocalUserResponse) => boolean;
    filterUserGroup?: (userGroup: UserGroupResponse) => boolean;
};
