import { InputProps as DefaultInputPropTypes } from "antd/lib/input/Input";
import { Field } from "@eazy2biz/common-util";
import { TableInputField } from "../../../TableInputField/TableInputField";
import { get } from "lodash";
import { TableInputColumn } from "@eazy2biz/common-package-ui";

export const CardFormTableInputField = (props: PropTypes) => {
  return <TableInputField {...props} columns={getColumnsFromFormField(props.field)} />;
};

interface PropTypes extends DefaultInputPropTypes {
  field: Field;
}

const getColumnsFromFormField = (field: Field): TableInputColumn[] => {
  return get(field, 'fieldConfigurations.otherConfigurations.tableInput.columns', []);
};
