import {
    CardStageSystemFields,
    FieldPlaceholder,
    FieldPlaceholderType
} from "@eazy2biz/common-util";
import {PlaceholderOption} from "../../entity/PlaceholderOption";
import {BaseProps} from "../BasePlaceholderMenu/BasePlaceholderMenu";
import { humanizeSnakeCase } from "@eazy2biz/common-package-ui";
import { useState } from "react";
import { PlaceholderOptionsList } from "../PlaceholderOptionsList";
import { UserPlaceholderEntity } from "../../entity/UserPlaceholderEntity";

interface IUserPlaceholderProps extends BaseProps{
    usersList: UserPlaceholderEntity[];
}

export const ItemStageSystemPlaceholder = (props: IUserPlaceholderProps): JSX.Element => {
    const { callback, goBack } = props;
    const [currentLevel, setCurrentLevel] = useState<ItemStageSystemPlaceholderLevel>(ItemStageSystemPlaceholderLevel.STAGE);
    const [selectedStageId, setSelectedStageId] = useState<string>();
    const [selectedValue, setSelectedValue] = useState<string>();

    const getPlaceholderListForStages = (): PlaceholderOption[] => {
        return props.config.stages.map((stage) => ({
            key: stage._id,
            name: stage.details.name,
        }));
    };

    const getPlaceholderOptionsForValue = (): PlaceholderOption[] => {
        return Object.keys(CardStageSystemFields).map(key => ({
            key: key,
            name: humanizeSnakeCase(key.toLocaleLowerCase())
        }));
    };

    const getPlaceholderType = (): FieldPlaceholderType => {
        return FieldPlaceholderType.CARD_STAGE_SYSTEM;
    };

    const handleOnPick = (value: string) => {
        switch (currentLevel) {
            case ItemStageSystemPlaceholderLevel.STAGE:
                setSelectedStageId(value);
                setCurrentLevel(ItemStageSystemPlaceholderLevel.VALUE);
                break;
            case ItemStageSystemPlaceholderLevel.VALUE:
                if (value === CardStageSystemFields.ADD_TIME.toString()) {
                    handleAddPlaceholder(selectedStageId, value);
                } else {
                    setSelectedValue(value);
                    setCurrentLevel(ItemStageSystemPlaceholderLevel.KEY);
                }
                break;
            default:
                handleAddPlaceholder(selectedStageId, selectedValue, value);
        }
    };

    const handleAddPlaceholder = (classId: string = '', entityId: string = '', key: string = '') => {
        const placeholder: FieldPlaceholder = new FieldPlaceholder();
        placeholder.placeholderType = getPlaceholderType();
        placeholder.entityClassId = classId;
        placeholder.entityId = entityId;
        placeholder.key = key;
        placeholder.subKey = '';
        callback(placeholder);
    };

    const handleGoBack = () => {
        switch (currentLevel) {
            case ItemStageSystemPlaceholderLevel.STAGE:
                goBack();
                break;
            case ItemStageSystemPlaceholderLevel.VALUE:
                setCurrentLevel(ItemStageSystemPlaceholderLevel.STAGE);
                setSelectedStageId(undefined);
                break;
            default:
                setCurrentLevel(ItemStageSystemPlaceholderLevel.VALUE);
                setSelectedValue(undefined);
        }
    };

    const getPlaceholderOptions = (): PlaceholderOption[] => {
        switch (currentLevel) {
            case ItemStageSystemPlaceholderLevel.STAGE: return getPlaceholderListForStages();
            case ItemStageSystemPlaceholderLevel.VALUE: return getPlaceholderOptionsForValue();
            default:
                return Object.keys(props.config.userProperties).map((key) => ({
                    key,
                  name: key,
                }));
        }
    };

    return <PlaceholderOptionsList goBack={handleGoBack} headerTitle={currentLevel.toString()} placeholderOptions={getPlaceholderOptions()} onPick={handleOnPick} />;
};

enum ItemStageSystemPlaceholderLevel {
    STAGE = 'Stage',
    VALUE = 'Value',
    KEY = 'Key'
}

