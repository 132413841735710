import styles from './ItemActivityDetailsComponent.module.css';
import {CardResponse, EntityType} from "@eazy2biz/common-util";
import {Tabs} from "antd";
import {ITEM_DETAILS_STRINGS} from "../../../../contents/DisplayContent";
import {ActivityListComponent} from "./activityListComponent/ActivityListComponent";
import {CommentsComponent} from "@eazy2biz-ui/common-components";

/**
 * Left Item Activity Details Component
 * @constructor
 */
export const ItemActivityDetailsComponent = (props: PropTypes) => {
    return (
        <div className={styles.itemActivityWrapper}>
            <Tabs defaultActiveKey="1" className={styles.itemActivityTabs}>
                <Tabs.TabPane tab={ITEM_DETAILS_STRINGS.ACTIVITY} key="1" className={styles.itemActivityTabs}>
                    <ActivityListComponent {...props} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={ITEM_DETAILS_STRINGS.COMMENTS} key="2" className={styles.itemActivityTabs}>
                    <CommentsComponent
                        entityId={props.cardData._id}
                        entityClassId={props.cardData.workflowId}
                        entityType={EntityType.CARD}
                    />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};

type PropTypes = {
    cardData: CardResponse;
};
