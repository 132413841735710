import { Field } from "@eazy2biz/common-util";
import { DynamicPlaceholderConfig, InputFieldWithPlaceholder } from "../../genericComponents/DynamicPlaceholder";

/**
 * Card Form Fields Select dropdown component.
 * @param props
 * @constructor
 */
export const CardFormFieldSelect = (props: PropTypes) => {

  const dynamicPlaceholderConfig = new DynamicPlaceholderConfig(true, true);
  dynamicPlaceholderConfig.setFormFields(props.fields);

  return (
    <InputFieldWithPlaceholder
      id={props.key?.toString()}
      label={props.label}
      type={'text'}
      onChange={props.onChange}
      config={dynamicPlaceholderConfig}
      initialValue={props.initialValue}
    />
  );
};

type PropTypes = {
  key: string | number;
  fields: Field[];
  onChange: (value?: string) => void;
  initialValue?: string;
  label?: string;
};
