import {InputField, Toast} from "@eazy2biz-ui/common-components";
import {ReactElement, useState} from "react";
import { confirmPasswordField, emailField, passwordField } from "../../../constants/keys";
import styles from "./ChangePasswordComponent.module.css";
import {
  ChangePassword,
  EmailPlaceholderSignIn, PASSWORD_ERROR_STRING
} from "../../../../contents/DisplayContent";
import {useHistory} from "react-router-dom";
import {EnvelopeClosedIcon, LockClosedIcon} from "@radix-ui/react-icons";
import {IdentitiesWrapperModal} from "../../common/identitiesWrapperModal/IdentitiesWrapperModal";
import {IdentityGenericButton} from "../../common/identityGenericButton/IdentityGenericButton";
import { RoutesEnum } from "@eazy2biz-ui/common-package";
import SignUpPinInput from "../SignUpPinInput";
import { isValidPassword } from "../../../helpers/PasswordHelper";
import { changePassword, initiateChangePassword } from "../../../services/GlobalUserService";

/**
 * Change Password Component.
 */
export const ChangePasswordComponent: () => JSX.Element = (): JSX.Element => {
    const [email, setEmail] = useState('');
    const [pwd, setPwd] = useState('');
    const [confirmPwd, setConfirmPwd] = useState('');
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const history = useHistory();

    const handleFieldsChange = (e: any) => {
        switch (e.target.id) {
          case emailField: setEmail(e.target.value); break;
          case passwordField: setPwd(e.target.value); break;
          case confirmPasswordField: setConfirmPwd(e.target.value); break;
        }
    };

    const handleSubmit = () => {
        setLoading(true);
        if (!otpSent) {
          initiateChangePassword(email)
            .then(() => {
              setOtpSent(true);
              Toast.success(ChangePassword.Success.SEND_OTP);
            })
            .catch((e) => Toast.error(e, ChangePassword.Error.SEND_OTP))
            .finally(() => setLoading(false));
        } else {
          changePassword({
            emailAddress: email,
            forgotPasswordCode: otp,
            newPassword: pwd,
          })
            .then(() => {
              Toast.success(ChangePassword.Success.CHANGE_PASS);
              onClose();
            })
            .catch((e) => Toast.error(e, ChangePassword.Error.CHANGE_PASS))
            .finally(() => setLoading(false));
        }
    };

    const onClose = () => {
        history.replace(RoutesEnum.USER_SIGN_IN);
    };

    const renderChangePasswordForm = () => {
        return (
            <>
                <InputField
                    id={emailField}
                    bordered
                    label={'Email Id'}
                    placeholder={EmailPlaceholderSignIn}
                    type={"email"}
                    prefix={<EnvelopeClosedIcon className={styles.changePasswordFieldIcon} />}
                    onChange={handleFieldsChange}
                />
                {otpSent &&
                  <>
                    <InputField
                      id={passwordField}
                      bordered
                      label={'Password'}
                      placeholder={ChangePassword.NewPassword}
                      type={"password"}
                      prefix={<LockClosedIcon className={styles.changePasswordFieldIcon} />}
                      onChange={handleFieldsChange}
                      isInValid={isValidPassword(pwd)}
                      inValidText={PASSWORD_ERROR_STRING.LENGTH_ERROR}
                    />
                    <InputField
                    id={confirmPasswordField}
                    bordered
                    label={'Confirm Password'}
                    placeholder={ChangePassword.ReEnterNewPassword}
                    type={"password"}
                    prefix={<LockClosedIcon className={styles.changePasswordFieldIcon} />}
                    onChange={handleFieldsChange}
                    isInValid={confirmPwd !== pwd && confirmPwd !== ''}
                    inValidText={PASSWORD_ERROR_STRING.CONFIRM_PASS_ERROR}
                    />
                    <SignUpPinInput length={6} setPasscode={setOtp} />
                  </>
                }
            </>
        );
    };

    const renderBody = (): ReactElement => {
        return (
            <>
                <div>
                    <form>
                        {renderChangePasswordForm()}
                    </form>
                </div>
                <div>
                    <IdentityGenericButton isLoading={loading} onClick={handleSubmit} buttonText={ChangePassword.ChangePasswordButton}/>
                  <div className={styles.footerText}><span className={styles.signInLink} onClick={onClose}>{ChangePassword.SignIn}</span></div>
                </div>
            </>
        );
    };

    return (
      <IdentitiesWrapperModal
          title={ChangePassword.HeaderText}
          onClose={onClose}
          getBody={renderBody}
      />
    );
}
