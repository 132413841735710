import {RestServices } from "@eazy2biz/common-package-ui";
import {
    EntityDefinitionTypes,
    SchedulableTriggerCategory, SchedulableTriggerCreateRequest,
    SchedulableTriggerResponse,
    ScheduledTaskType
} from "@eazy2biz/common-util";
import { getFilterPayloadForTrigger } from "./helpers/ScheduledTriggerServiceHelper";

const SCHEDULED_TRIGGERS_SERVICE = () => RestServices.getInstance().getScheduledTriggerService();

export const getAllScheduledTriggersForTypeAndCategoryAndDefinition = (
  schedulableTriggerCategory?: SchedulableTriggerCategory,
  schedulableTriggerType?: EntityDefinitionTypes,
  scheduledTaskType?: ScheduledTaskType,
  referenceId?: string,
) : Promise<SchedulableTriggerResponse[]> => {
    return SCHEDULED_TRIGGERS_SERVICE().getFilteredScheduleTriggersForCompany(
      getFilterPayloadForTrigger(schedulableTriggerCategory, schedulableTriggerType, scheduledTaskType, referenceId));
};

export const createScheduledTrigger = (request: SchedulableTriggerCreateRequest) => {
    return SCHEDULED_TRIGGERS_SERVICE().createScheduleTrigger(request);
};

export const deleteScheduledTrigger  = (triggerId: string) => {
    return SCHEDULED_TRIGGERS_SERVICE().deleteScheduleTriggerById(triggerId);
};
