import {getInviteUserRequest} from "./helpers/InviteServiceHelper";
import {RestServices} from "@eazy2biz/common-package-ui";
import {UserInviteResponse, SecurityGroupIdentifier} from "@eazy2biz/common-util";

const INVITES_SERVICE = () => RestServices.getInstance().getUserInvitesService();
/**
 * Invites a new user to the company
 * @param name
 * @param email email address
 * @param otherInfo   
 */
export const inviteUser = (name: string, email: string, userGroupsToJoin: string[], securityGroupsToJoin : SecurityGroupIdentifier[], otherInfo: any) : Promise<UserInviteResponse> => {
    return INVITES_SERVICE().inviteUser(getInviteUserRequest(name, email, userGroupsToJoin, securityGroupsToJoin, otherInfo));
}

/**
 * Lists all pending invites
 */
export const getAllUserInvitesForCompany = () : Promise<UserInviteResponse[]> => {
    return INVITES_SERVICE().getAllUserInvitesForCompany();
}

