import {InputField} from "@eazy2biz-ui/common-components";

export const HeaderContent = (props: PropsType): JSX.Element => {
  const handleOnChange = (event: any) => {
    props.onChange(event.target.value);
  };

  return (
    <InputField
      disabled={props.readOnly}
      onChange={handleOnChange}
      id={'header'}
      value={props.value}
      label={''}
      required={false}
      type={'text'}/>
  );
};

type PropsType = {
  value: string,
  onChange: (header: string) => void;
  readOnly?: boolean;
};
