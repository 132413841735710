import {CardResponse, TableChartColumn} from '@eazy2biz/common-util';
import {
    ColumnHeader,
    ColumnReportHeader,
    generateTableDataFromItems,
    RowMetaData
} from '@eazy2biz-ui/common-components';
import {get} from 'lodash';
import { ItemHeaders } from "@eazy2biz-ui/workflow-ui";

export const getTableRowDataForCardItems = (columns : ColumnHeader[], cardResponses: CardResponse[]) => {
  return generateTableDataFromItems(
    cardResponses,
    columns,
    getValueForTableChartCell,
    getMetaDataForItem
  );
};

const getValueForTableChartCell = (item: CardResponse, key: string) => {
  if (key === ItemHeaders.NAME) {
    return item.details.name;
  } else {
    return get(item.fieldsMap, key, "");
  }
};

const getMetaDataForItem = (item: CardResponse): RowMetaData => {
  return {
    id: item._id
  };
};


export const getColumnsForTableChart = (tableChartColumns: TableChartColumn[]): ColumnHeader[] => {
  const columns = tableChartColumns
    .map((tableChartColumn) => ({
      Header: tableChartColumn.columnName,
      accessor: tableChartColumn.columnDataReferenceId
    }))
  columns.unshift({
    Header: 'Card No.',
    accessor: ItemHeaders.NAME
  });
  return columns;
};

//we have to give header in CSvlink in header as label,key pair
export const getColumnsForReportDownload = (tableChartColumns: TableChartColumn[]): ColumnReportHeader[]  => {
  const columns = tableChartColumns
    .map((tableColumn) => ({
      label: tableColumn.columnName,
      key: tableColumn.columnDataReferenceId
    }))
  columns.unshift({
      label: 'CardNo',
      key: ItemHeaders.NAME
  });
  return columns;
};

