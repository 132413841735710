import { CustomerTableHeaderTypes, CustomerTableRecordResponse, CustomerTableResponse } from "@eazy2biz/common-util";
import { ColumnHeader, generateTableDataFromItems, RowData, RowMetaData } from "@eazy2biz-ui/common-components";
import { get } from "lodash";
import { getFormattedDate, getFormattedDateTime, getUserNameById } from "@eazy2biz/common-package-ui";

/**
 * Dynamic column header for customer table.
 * @param table
 */
export const getColumnsForTable = (table: CustomerTableResponse): ColumnHeader[] => {
    const columnHeaders = table.tableHeaders
      .filter(header => header.headerType !== CustomerTableHeaderTypes.FILE)
      .map((tableHeader) => {
        return {
            Header: tableHeader.name,
            accessor: tableHeader._id,
        }
    });

    columnHeaders.push(
        {
            Header: 'Created On',
            accessor: 'createdDate'
        }
    );

    columnHeaders.push(
        {
            Header: 'Created By',
            accessor: 'createdBy'
        }
    );

    return columnHeaders;
};

/**
 * Converts records to rows.
 * @param records
 * @param table
 */
export const getRowsFromRecords = (records: CustomerTableRecordResponse[], table: CustomerTableResponse): RowData[] => {
    return generateTableDataFromItems(records, getColumnsForTable(table), getValueForRecord, getMetaDataForRecord);
};

const getMetaDataForRecord = (record: CustomerTableRecordResponse): RowMetaData => {
    return {
        id: record._id.toString()
    };
};

const getValueForRecord = (record: CustomerTableRecordResponse, key: string) => {

    switch (key) {
        case 'createdDate': return getFormattedDate(get(record, 'metaData.createdDate', ''));
        case 'createdBy': return getUserNameById(get(record, 'metaData.createdBy', ''));
        default:
            const element = record.tableHeaderContents.find((recordElement) => recordElement.header._id === key);
            const content = get(element, 'content', '');

            switch (element?.header.headerType) {
                case CustomerTableHeaderTypes.DATE:
                    return getFormattedDate(content);
                default: return content;
            }
    }
};
