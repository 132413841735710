import React from "react";
import "./ApprovalField.css";
import GenericButton from "../GenericButton/GenericButton";
import { GenericButtonTypes } from "../GenericButton/GenericButtonTypes";

export const ApprovalField = (props:PropType) => {
  return (
        <div className='approval-ctn'>
            <div className='approval-btn-ctn'>
                <GenericButton
                    type={GenericButtonTypes.REJECT}
                    buttonText={props.rejectButtonText || 'Reject'}
                    className='reject'
                    disabled={props.disabled}
                    onClick={props.handleRejection} />
            </div>
            <div className='approval-btn-ctn'>
                <GenericButton
                    type={GenericButtonTypes.SUCCESS}
                    buttonText={props.approveButtonText || 'Accept'}
                    className='success'
                    disabled={props.disabled}
                    onClick={props.handleApproval} />
            </div>
        </div>
  )
};

type PropType = {
   handleApproval: () => void;
   handleRejection: () => void;
   disabled?: boolean;
   rejectButtonText?: string;
   approveButtonText?: string;
}
