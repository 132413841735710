import {Elements, FlowElement} from "react-flow-renderer";
import {Edge, Node} from "react-flow-renderer/dist/types";
import {StageData} from "../../../entity/workflowBuilder/StageData";
import {cloneDeep} from "lodash";
import {EdgeData} from "../../../entity/workflowBuilder/EdgeData";
import {LabelTypes} from "../../../components/canvas/types/LabelTypes";
import {
    findElementById,
    generateNewEdge,
    generateNewLabel,
    generateNewNode, getEdgeColor,
} from "./WorkflowBuilderFlowHelper";
import {updateTargetStageId} from "./StageConfigurationHelper";
import {StageTypes} from "@eazy2biz/common-util";

/**
 * Add stage elements for new stage creation
 * @param edgeId
 * @param stages
 * @param edges
 * @param stageType
 * @constructor
 */
export const AddStageElements = (
    edgeId: string,
    stages: Elements<StageData>,
    edges: Elements<EdgeData>,
    stageType: StageTypes
): ADDED_ELEMENTS_RESPONSE => {

    let edgesCopy: Elements<EdgeData> = cloneDeep(edges);
    const edgeCopy: Edge<EdgeData> = findElementById(edgesCopy, edgeId) as Edge<EdgeData>;

    let stagesCopy: Elements<StageData> = cloneDeep(stages);

    const target: FlowElement<StageData> = findElementById(stagesCopy, edgeCopy.target);

    const newStage: Node<StageData> = generateNewNode(stageType, target.id);
    const newStageId: string = newStage.id;
    // Add the new stage
    stagesCopy.push(newStage);

    // Update the source edge
    edgeCopy.target = newStageId;

    if (stageType === StageTypes.FORM_STAGE) {
        edgesCopy = modifyEdgesForFormStage(edgesCopy, newStageId, target.id);
    } else {
        const modifiedValues: STAGES_AND_EDGES =
            modifyLabelsAndEdgesForBranchStage(stagesCopy, edgesCopy, newStage, target, stageType);
        edgesCopy = modifiedValues.edges;
        stagesCopy = modifiedValues.stages;
    }

    return {
        stages: stagesCopy,
        edges: edgesCopy,
        addedStageId: newStageId
    };
};

/**
 * Add edges for a new form stage.
 * @param edges
 * @param newStageId
 * @param targetId
 */
const modifyEdgesForFormStage = (edges: Elements<EdgeData>, newStageId: string, targetId: string): Elements<EdgeData> => {

    // Add the new edge to target
    edges.push(
        generateNewEdge(newStageId, targetId)
    );

    return edges;
}

/**
 * Adds labels and new edges for branch stages.
 * @param labels
 * @param edges
 * @param sourceStage
 * @param target
 * @param stageType
 */
const modifyLabelsAndEdgesForBranchStage = (
    stages: Elements<StageData>,
    edges: Elements<EdgeData>,
    sourceStage: Node<StageData>,
    target: FlowElement<StageData>,
    stageType: StageTypes): STAGES_AND_EDGES => {

    const positiveLabel: Node<StageData> = generateNewLabel(
        stageType === StageTypes.APPROVAL_STAGE ? LabelTypes.APPROVED: LabelTypes.TRUE, target.id
    );

    // @ts-ignore
    sourceStage.data.stageConfiguration = updateTargetStageId(sourceStage.data.stageConfiguration, positiveLabel.id);

    const negativeLabel: Node<StageData> = generateNewLabel(
        stageType === StageTypes.APPROVAL_STAGE ? LabelTypes.REJECTED: LabelTypes.FALSE, target.id
    );

    edges.push(
        generateNewEdge(sourceStage.id, positiveLabel.id, 'PLAIN_EDGE', {stroke: getEdgeColor(true)}),
        generateNewEdge(sourceStage.id, negativeLabel.id, 'PLAIN_EDGE', {stroke: getEdgeColor(false)}),
        generateNewEdge(positiveLabel.id, target.id, 'ADD_BUTTON_EDGE', {stroke: getEdgeColor(true)}),
    );

    const isTargetEnd: boolean = target.data?.stageConfiguration.type === StageTypes.EXIT_STAGE;

    // Duplicating the End Stage.
    if (isTargetEnd) {
        const newEndNode: FlowElement<StageData> = cloneDeep(target);
        newEndNode.id = "END_NODE:" + Math.random();
        stages.push(newEndNode);

        // @ts-ignore
        negativeLabel.data.stageConfiguration = updateTargetStageId(negativeLabel.data.stageConfiguration, newEndNode.id);

        // Add the new edge
        edges.push(
            generateNewEdge(negativeLabel.id, newEndNode.id, 'ADD_BUTTON_EDGE', {stroke: getEdgeColor(false)}),
        );

    } else {
        // Add the new edge
        edges.push(
            generateNewEdge(negativeLabel.id, target.id, 'ADD_BUTTON_EDGE', {stroke: getEdgeColor(false)})
        );
    }

    // Push the newly added labels.
    stages.push(positiveLabel, negativeLabel);

    return {
        edges,
        stages
    };
}

type STAGES_AND_EDGES = {
    stages: Elements;
    edges: Elements;
};

export type ADDED_ELEMENTS_RESPONSE = {
    stages: Elements;
    edges: Elements;
    addedStageId: string;
};
