import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import RoutesConfig from "../RoutesConfig";
import {RouteConfig} from "../RoutesConfig";
import CustomCookiesProvider from "./components/cookies/CustomCookiesProvider";

import "./App.css";
import {UIApplicationInit} from "@eazy2biz-ui/common-package";
import {useEffect} from "react";

const App = (Props: any) => {
  useEffect(() => {
    UIApplicationInit.getInstance();
  }, []);
  const renderComponent = (component: any, props: any) => {
    return (
        <CustomCookiesProvider
            component={component}
            {...props} />
    );
  };

  const getRoutes = () => {
    return RoutesConfig.map((route: RouteConfig) => {
      const renderFunction = (Props: any) =>
          renderComponent(
              route.component,
              {...Props, isWebPage: route.isWebPage, disableDefaultLayout: route.disableDefaultLayout || false});
      return (
        <Route
            key={route.url}
            exact
            path= {route.url}
            render={renderFunction}
        />
      );
    });
  };


  return (
    <Router>
      <div className="App">
        <Switch>
            {getRoutes()}
        </Switch>
      </div>
    </Router>
  );
}

export default App;
