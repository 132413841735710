import styles from './GenericWhatsappMessage.module.css';
import classNames from 'classnames';
import {GenericWhatsappMessagePreviewTypes} from './GenericWhatsappMessagePreviewTypes';
import {GENERIC_MESSAGE} from '../../../contents/DisplayContent';
import {getFormattedTime} from '../../helpers/Previews/GenericWhatsappMessagePreview';

export const GenericWhatsappMessagePreview = (props: PropsType) => {
    const {msg} = props;
    let newDate = new Date()

    return (
        <div className={styles.container}>
            {
                msg.map((msgItem: MessageDisplayType) => {
                    return (
                        <div className={classNames(styles.message, {
                            [styles.messageRight]: GenericWhatsappMessagePreviewTypes.RIGHT == msgItem.alignment,
                            [styles.messageLeft]: GenericWhatsappMessagePreviewTypes.LEFT == msgItem.alignment || msgItem.alignment == undefined
                        })}>
                            <div className={styles.header}>
                                {msgItem.header}
                            </div>
                            <div className={styles.paragraph}>
                                {msgItem.body}
                            </div>
                            <div className={styles.footer}>
                                <div>{GENERIC_MESSAGE.FOOTER_TEXT}</div>
                                <div>{getFormattedTime(newDate)}</div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export type MessageDisplayType = {
    header: string;
    body: string;
    alignment?: GenericWhatsappMessagePreviewTypes;
}

type PropsType = {
    msg: MessageDisplayType[];
}
