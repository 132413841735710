import React from 'react';
import FormPlayground from '../formPlayground/FormPlayground';
import { StageData } from '../../../entity/workflowBuilder/StageData';
import {
  getFieldConfigById,
  getItemFromPaletteConfig,
  getItemsFromFields,
  transformBuilderForSave,
  updateField,
  deleteField
} from '../../../helpers/formBuilderHelpers/runtimeHelpers/FormBuilderHelper';
import { FormElementConfig } from '../../../entity/formBuilder/FormElementConfig';
import FieldConfigurations from '../fieldConfigurations/FieldConfigurations';
import {GenericFormBuilder, PaletteConfig, Toast} from '@eazy2biz-ui/common-components';
import { Elements, Node } from 'react-flow-renderer/dist/types';
import { AccessTypes, FieldAccessMap } from '@eazy2biz/common-util';
import { FormEntity } from '@eazy2biz/common-package-ui';
import { cleaningFormFieldAccessMapForDeletedFields } from '../../../helpers/workflowBuilderHelpers/runtimeHelpers/WorkflowBuilderStageHelper';

/**
 * Workflow Stage Form Builder
 */
class FormBuilder extends React.Component<PropTypes, StateType> {
  constructor(props: PropTypes) {
    super(props);
    this.state = {
      formConfigurationsVisible: false,
      formList: getItemsFromFields(
        props.form.formFields,
        props.stageData.stageConfiguration.fieldAccessMap
      )
    };
  }

  /**
   * Field Selection/ Deselection
   * @param fieldId
   */
  handleOnFieldSelect = (fieldId?: string) => {
    return this.setState({
      formConfigurationsVisible: fieldId !== undefined,
      selectedFieldId: fieldId
    });
  };

  /**
   * Adding element to the form.
   * @param id pallete Item id.
   */
  addFormElement = (id: string) => {
    const field = getItemFromPaletteConfig(id, this.props.stageData.id);
    this.setState(
      {
        formList: [...this.state.formList, field]
      },
      () => {
        this.handleOnFieldSelect(field.element._id);
      }
    );
  };

  /**
   * Updates the field Configurations.
   * @param updatedField
   */
  handleFieldUpdate = (updatedField: FormElementConfig) => {
    this.setState({
      formList: updateField(updatedField, this.state.formList)
    });
  };

  handleFieldDeletion = (fieldId?: string) => {
    try {
      if (fieldId) {
        cleaningFormFieldAccessMapForDeletedFields(this.props.stages as Node<StageData>[], [
          fieldId
        ]);
        this.handleOnFieldSelect();
        this.setState({
          formList: deleteField(this.state.formList, fieldId)
        });
      }
    } catch (e: any) {
      Toast.error(e);
    }
  };

  /**
   * Handles Save Form
   */
  handleSaveForm = () => {
    const { form, fieldAccessMap } = transformBuilderForSave(this.state.formList);
    this.props.onFormUpdate(form, fieldAccessMap);
  };

  handleFieldVisibilityChange = (visibility: AccessTypes, stageId: string) => {
    let updatedFormList = this.state.formList;
    updatedFormList.map((item: FormElementConfig) => {
      if (item.element.ownerId === stageId) {
        item.visibility = visibility;
      }
    });
    this.setState({
      formList: updatedFormList
    });
  };

  renderFieldConfigurations = () => {
    const { formList, selectedFieldId } = this.state;
    if (!selectedFieldId) {
      return null;
    }

    return (
      <FieldConfigurations
        field={getFieldConfigById(formList, selectedFieldId)}
        onFieldUpdate={this.handleFieldUpdate}
        onClose={() => this.handleOnFieldSelect()}
      />
    );
  };

  renderFormPlayground = () => {
    return (
      <FormPlayground
        currentStageId={this.props.stageData.id}
        stages={this.props.stages}
        fields={this.state.formList}
        workflowEditMode={this.props.workflowEditMode}
        stageIdsList={this.props.stageIdsList}
        onFieldSelect={this.handleOnFieldSelect}
        onFieldDelete={this.handleFieldDeletion}
        selectedFieldId={this.state.selectedFieldId}
        onFieldVisibilityChangeForStage={this.handleFieldVisibilityChange}
      />
    );
  };

  getTitle = () => {
    const { stageData } = this.props;
    return 'Form Builder - ' + stageData.stageConfiguration.details.name;
  }

  render() {
    const { onToggleFormBuilder } = this.props;

    return (
        <GenericFormBuilder
            onToggleFormBuilder={onToggleFormBuilder}
            title={this.getTitle()}
            onSaveForm={this.handleSaveForm}
            renderFormView={this.renderFormPlayground}
            paletteItems={PaletteConfig}
            formConfigurationsVisible={this.state.formConfigurationsVisible}
            renderLeftConfigurations={this.renderFieldConfigurations}
            onElementDrop={this.addFormElement}
            onBodyClick={() => this.handleOnFieldSelect()} />
    );
  }
}

type PropTypes = {
  form: FormEntity;
  workflowEditMode: boolean;
  stages: Elements;
  stageIdsList: string[];
  stageData: StageData;
  onToggleFormBuilder: () => void;
  onFormUpdate: (form: FormEntity, fieldAccessMap: FieldAccessMap) => void;
};

type StateType = {
  formList: FormElementConfig[];
  selectedFieldId?: string;
  formConfigurationsVisible: boolean;
};

export default FormBuilder;
