import {ReportResponse} from "@eazy2biz/common-util";
import {AlertModalTypes, BaseAlertModal, GenericButton, GenericButtonTypes} from "@eazy2biz-ui/common-components";
import styles from "./ReportDeleteButtons.module.css";
import {TrashIcon} from "@radix-ui/react-icons";
import {reportContent} from "../../../contents/DisplayContent";
import React, {useState} from "react";
import {deleteReportById} from "../../services/ReportService";

export const ReportDeleteButtons = (
    props: {
        selectedReport: ReportResponse,
        handlePostReportDeleteSuccess : () => void,
    }

): JSX.Element => {

    const {
        selectedReport,
        handlePostReportDeleteSuccess,
    } = props;

    const [showDeleteConfirmationAlertModal, setShowDeleteConfirmationAlertModal] = useState(false);

    const [isDeleteReportLoading, setIsDeleteReportLoading] = useState(false);

    const handleDeleteReport = () => {
        setIsDeleteReportLoading(true);
        deleteReportById(
            selectedReport._id
        ).then(() => {
            setIsDeleteReportLoading(false);
            handlePostReportDeleteSuccess();
        })
    }

    return (
       <>
           <GenericButton
               isLoading={isDeleteReportLoading}
               className={styles.reportDeleteButton}
               type={GenericButtonTypes.SECONDARY}
               CustomIcon={TrashIcon}
               buttonText={reportContent.DELETE_REPORT}
               onClick={() => setShowDeleteConfirmationAlertModal(true)}
           />

           {
               showDeleteConfirmationAlertModal && (
                   <BaseAlertModal
                       showCancelButton={true}
                       onCancel={() => setShowDeleteConfirmationAlertModal(false)}
                       onConfirm={() => {
                           setShowDeleteConfirmationAlertModal(false)
                           handleDeleteReport()
                       }}
                       type={AlertModalTypes.ALERT}
                       title={reportContent.DELETE_REPORT_ALERT_MESSAGE}
                   />
               )
           }

       </>
    )
}
