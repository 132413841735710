import moment, {Moment} from "moment";
import {CHART_DATA_NOT_FOUND, chartContent} from "../../../../contents/DisplayContent";
import {DEFAULT_VERBOSE_DATE_FORMAT} from "@eazy2biz/common-package-ui";
import {Skeleton} from "antd";
import {EmptyDataPlaceholderGraphic, GenericDateRangePicker,
    getDefaultDateRangePresets} from "@eazy2biz-ui/common-components";
import React, {useEffect, useState} from "react";

import styles from './ChartPreview.module.css'
import {
    AggregationResult, AreaChartDetails, BarChartDetails,
    CardResponse, ChartSimulateRequest,
    ChartType,
    LineChartDetails, PieChartDetails, ReportResponse, TableChartDetails
} from "@eazy2biz/common-util";
import {LineChartComponent} from "../LineChartComponent";
import {AreaChartComponent} from "../AreaChartComponent";
import {BarChartComponent} from "../BarChartComponent";
import {PieChartComponent} from "../PieChartComponent";
import {TableChartComponent} from "../TableChartComponent";
import {simulateChartForReport} from "../../../services/ChartsService";
import {StrictBuilder} from "builder-pattern";
import {PreviewChartForm} from "./CreateChartHelper";

export const ChartPreview = (
    props : {
        selectedReport: ReportResponse;
        chartType : ChartType;
        previewChartForm: PreviewChartForm
    }
) : JSX.Element => {

    const {
        selectedReport,
        chartType,
        previewChartForm
    } = props;

    const [chartData, setChartData] = useState<any[] | null>(null);

    const chartStartDate = moment().subtract(moment.duration(previewChartForm.defaultChartDuration)).startOf('day');
    const chartEndDate = moment().endOf('day');




    useEffect(() => {
        setChartData(null);
        if(chartStartDate && chartEndDate) {
            simulateChartForReport(
                StrictBuilder<ChartSimulateRequest>()
                    .reportId(selectedReport._id)
                    .durationDateField(previewChartForm.durationDateField)
                    .type(chartType)
                    .filters(previewChartForm.filters)
                    .chartDetails(previewChartForm.chartDetails)
                    .simulationStartDate(chartStartDate.toDate())
                    .simulationEndDate(chartEndDate.toDate())
                    .build(),
            ).then(
                chartData => setChartData(chartData)
            )
        }
    }, [
        previewChartForm.defaultChartDuration,
        previewChartForm.durationDateField,
        previewChartForm.chartType,
        previewChartForm.filters,
        previewChartForm.chartDetails,
    ])

    const getChartComponent = (chartStartDate: Moment, chartEndDate: Moment) => {
        switch (chartType) {
            case ChartType.LINE:
                return (
                    <LineChartComponent
                        reportResponse={selectedReport}
                        lineChartDetails={previewChartForm.chartDetails as LineChartDetails}
                        chartStartDate={chartStartDate}
                        chartEndDate={chartEndDate}
                        chartData={chartData as unknown as AggregationResult[]}
                    />
                );
            case ChartType.AREA:
                return (
                    <AreaChartComponent
                        reportResponse={selectedReport}
                        areaChartDetails={previewChartForm.chartDetails as AreaChartDetails}
                        chartStartDate={chartStartDate}
                        chartEndDate={chartEndDate}
                        chartData={chartData as unknown as AggregationResult[]}
                    />
                );
            case ChartType.BAR:
                return (
                    <BarChartComponent
                        reportResponse={selectedReport}
                        barChartDetails={previewChartForm.chartDetails as BarChartDetails}
                        chartStartDate={chartStartDate}
                        chartEndDate={chartEndDate}
                        chartData={chartData as unknown as AggregationResult[]}
                    />
                );
            case ChartType.PIE:
                return (
                    <PieChartComponent
                        reportResponse={selectedReport}
                        pieChartDetails={previewChartForm.chartDetails as PieChartDetails}
                        chartStartDate={chartStartDate}
                        chartEndDate={chartEndDate}
                        chartData={chartData as unknown as AggregationResult[]}
                    />
                );
            case ChartType.TABLE:
                return (
                    <TableChartComponent
                        reportResponse={selectedReport}
                        tableName={previewChartForm.name}
                        tableChartDetails={previewChartForm.chartDetails as TableChartDetails}
                        chartStartDate={chartStartDate}
                        chartEndDate={chartEndDate}
                        tableChartData={chartData as unknown as CardResponse[]}
                        simulation
                    />
                );
        }
    }

    return (
        <>
            <div className={styles.chartHeader}>
                <div className={styles.chartDetailsContainer}>
                    <div className={styles.chartName}>
                        {
                            previewChartForm.name
                        }
                    </div>
                    {
                        previewChartForm.description && (
                            <div className={styles.chartDescription}>
                                {
                                    previewChartForm.description
                                }
                            </div>
                        )
                    }
                </div>
                <div className={styles.dateRangePickerContainer}>
                    <GenericDateRangePicker
                        defaultStartDate={chartStartDate}
                        defaultEndDate={chartEndDate}
                        handleRangeChange={(startDate: Moment | null, endDate : Moment | null) => {}}
                        disabled={true}
                        rangePresets={getDefaultDateRangePresets()}
                        placeholders={[chartContent.CHART_START_DATE, chartContent.CHART_END_DATE]}
                        dateFormat={DEFAULT_VERBOSE_DATE_FORMAT}
                    />
                </div>
            </div>

            <Skeleton
                active loading={chartData === undefined || chartData === null}>
                <div className={styles.chartContainer}>
                    {
                        chartData?.length !== 0 ? (
                            getChartComponent(chartStartDate, chartEndDate)
                        ) : (
                            <div className={styles.emptyTablePlaceholderContainer}>
                                <EmptyDataPlaceholderGraphic
                                    text={CHART_DATA_NOT_FOUND}
                                />
                            </div>
                        )
                    }
                </div>
            </Skeleton>
        </>
    );
}
