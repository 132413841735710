import HomePage from "./js/components/HomePage/HomePage";
import ErrorPage from "./js/components/ErrorPage/ErrorPage";
import {
    ConfirmUserSignUpForm,
    CompanyInviteConsentForm,
    UserSignInComponent, ChangePasswordComponent, SwitchCompanyForm
} from "@eazy2biz-ui/identities-ui";
import {UsersComponent, InvitesComponent, UserGroupsComponent, DepartmentsComponent} from "@eazy2biz-ui/user-management-ui";
import {WorkflowHomepage, WorkflowBuilder, ItemDetailsComponent} from "@eazy2biz-ui/workflow-ui";
import {RoutesEnum} from "@eazy2biz-ui/common-package";
import { ConfigurePdfTemplate, CreateWhatsappTemplate, TemplateManagementDashboard } from "@eazy2biz-ui/templates-ui";
import {CustomerTableHomepage} from "@eazy2biz-ui/customer-table-ui";

export type RouteConfig = {
    url: string;
    component: any;
    isWebPage?: boolean;
    disableDefaultLayout?: boolean;
}

const RoutesConfig: RouteConfig[] = [
    {
        url: RoutesEnum.APP_DASHBOARD,
        component: HomePage
    },
    {
        url: RoutesEnum.USERS,
        component: UsersComponent
    },
    {
        url: RoutesEnum.INVITES,
        component: InvitesComponent
    },
    {
        url: RoutesEnum.USER_GROUPS,
        component: UserGroupsComponent
    },
    {
        url: RoutesEnum.DEPARTMENTS,
        component: DepartmentsComponent
    },
    {
        url: RoutesEnum.SWITCH_COMPANY,
        component: SwitchCompanyForm,
        isWebPage: false,
        disableDefaultLayout: true
    },
    {
        url: RoutesEnum.USER_SIGN_IN,
        component: UserSignInComponent,
        isWebPage: true,
        disableDefaultLayout: true
    },
    {
        url: RoutesEnum.CHANGE_PASSWORD,
        component: ChangePasswordComponent,
        isWebPage: true,
        disableDefaultLayout: true
    },
    {
        url: RoutesEnum.CONFIRM_USER_SIGN_UP,
        component: ConfirmUserSignUpForm,
        isWebPage: true,
        disableDefaultLayout: true
    },
    {
        url: RoutesEnum.USER_INVITATION,
        component: CompanyInviteConsentForm,
        isWebPage: false,
        disableDefaultLayout: true
    },
    {
        url: RoutesEnum.WORKFLOW_BUILDER,
        component: WorkflowBuilder,
        disableDefaultLayout: true
    },
    {
        url: RoutesEnum.INTERNAL_ITEM_DETAILS,
        component: ItemDetailsComponent,
        isWebPage: false,
        disableDefaultLayout: true
    },
    {
        url: RoutesEnum.WORKFLOW_HOMEPAGE,
        component: WorkflowHomepage
    },
    {
        url: RoutesEnum.WORKFLOW_HOMEPAGE_TAB,
        component: WorkflowHomepage
    },
    {
        url: RoutesEnum.CREATE_WHATSAPP_TEMPLATE,
        component: CreateWhatsappTemplate
    },
    {
        url: RoutesEnum.UPDATE_WHATSAPP_TEMPLATE,
        component: CreateWhatsappTemplate
    },
    {
        url: RoutesEnum.CREATE_PDF_TEMPLATE,
        component: ConfigurePdfTemplate
    },
    {
        url: RoutesEnum.UPDATE_PDF_TEMPLATE,
        component: ConfigurePdfTemplate
    },
    {
        url: RoutesEnum.TEMPLATE_MANAGEMENT_HOMEPAGE,
        component: TemplateManagementDashboard
    },
    {
        url: RoutesEnum.TEMPLATE_MANAGEMENT_HOMEPAGE_TAB,
        component: TemplateManagementDashboard
    },
    {
        url: RoutesEnum.CUSTOMER_TABLE,
        component: CustomerTableHomepage,
    },
    {
        url: RoutesEnum.ERROR_PAGE,
        component: ErrorPage,
        isWebPage: true,
        disableDefaultLayout: true
    }
];

export default RoutesConfig;
