import { initialStages } from './InitalStages';
import { WorkflowBuilderStateType } from '../WorkflowBuilder';

const initialState = (): WorkflowBuilderStateType => ({
  workflowDetail: {
    name: 'Untitled Workflow',
    description: '',
    companyId: 0
  },
  workflowId: undefined,
  isDraft: false,
  form: {
    formFields: []
  },
  stages: initialStages(),
  edges: [],
  leftDrawerVisible: false,
  formBuilderVisible: false,
  workflowPublishInProgress: false,
  workflowEditMode: false
});

export default initialState;
