import { Edge, Node } from "react-flow-renderer/dist/types";
import { StageData } from "../../../entity/workflowBuilder/StageData";
import { CSSProperties } from "react";
import { EdgeData } from "../../../entity/workflowBuilder/EdgeData";
import { LabelTypes } from "../../../components/canvas/types/LabelTypes";
import {
  getDefaultStageProps,
  getNewStageConfiguration,
  getNewStageOtherConfiguration
} from "./StageConfigurationHelper";
import { FlowElement } from "react-flow-renderer/nocss";
import { ArrowHeadType, Elements } from "react-flow-renderer";
import { cloneDeep, set } from "lodash";
import {StageTypes} from "@eazy2biz/common-util";

const defaultStageLabel = "Untitled Form";

const getRandomNumber = () => {
  return Math.floor(Math.random() * (1000)) + 1;
};

/**
 * Creates a new Stage
 * @param stageType
 * @param targetStageId
 */
export const generateNewNode = (
  stageType: StageTypes,
  targetStageId: string
): Node<StageData> => {
  const id: string = `STAGE_${stageType}_${getRandomNumber()}`;
  return {
    id,
    type: "FORM_NODE",
    data: {
      id,
      details: {
        label: getStageLabelFromStageType(stageType),
        type: stageType,
        isLabel: false,
      },
      stageConfiguration: getNewStageConfiguration(
        id,
        getStageLabelFromStageType(stageType),
        stageType,
        targetStageId
      ),
      otherConfigurations: getNewStageOtherConfiguration(),
      props: getDefaultStageProps()
    },
    position: dummyPosition,
  };
};

/**
 * Creates a new Edge
 * @param source
 * @param target
 * @param edgeType
 * @param style
 */
export const generateNewEdge = (
  source: string,
  target: string,
  edgeType: string = "ADD_BUTTON_EDGE",
  style: CSSProperties = {}
): Edge<EdgeData> => {
  return {
    id: `EDGE_${source}_${target}`,
    source,
    target,
    type: edgeType,
    // @ts-ignore
    arrowHeadType: ArrowHeadType.ArrowClosed,
    data: {},
    style: {
      stroke: getEdgeColor(),
      strokeWidth: "1px",
      strokeDasharray: '10,5',
      ...style,
    },
  };
};

/**
 * Creates a new Edge
 * @param location
 * @param position
 * @param labelType
 */
export const generateNewLabel = (
  labelType: LabelTypes,
  targetStageId: string
): Node<StageData> => {
  const id: string = `LABEL_${getRandomNumber()}`;
  return {
    id,
    type: "READ_ONLY_LABEL",
    data: {
      id,
      details: {
        label: labelType.toString(),
        type: labelType,
        isLabel: true,
      },
      stageConfiguration: getNewStageConfiguration(
        id,
        labelType.toString(),
        undefined,
        targetStageId
      ),
      otherConfigurations: getNewStageOtherConfiguration(),
      props: getDefaultStageProps()
    },
    position: dummyPosition,
  };
};

export const getEdgeColor = (positiveColour?: boolean) => {
  if (positiveColour === undefined) {
    return 'grey';
  }

  if (positiveColour) {
    return 'green';
  }

  return 'red';
};

const dummyPosition = {
  x: 0,
  y: 0,
};

export const getFilteredElementsMap = (
  elements: Elements<StageData>,
  type: Array<StageTypes | LabelTypes>
): Map<string, FlowElement<StageData>> => {
  const elementsMap: Map<string, FlowElement<StageData>> = new Map<
    string,
    FlowElement<StageData>
  >();
  elements.forEach((element) => {
    if (
      element.data !== undefined &&
      type.includes(element.data.details.type)
    ) {
      elementsMap.set(element.id, element);
    }
  });
  return elementsMap;
};

export const getStartStageFromStages = (
  elements: Elements<StageData>
): FlowElement<StageData> => {
  const startStages: IterableIterator<FlowElement<StageData>> =
    getFilteredElementsMap(elements, [StageTypes.ENTRY_STAGE]).values();
  return startStages.next().value;
};

export const getEdgesFromSource = (edges: Edge[], sourceId: string): Edge[] => {
  return edges.filter((edge) => edge.source === sourceId);
};

export const getEdgesForTarget = (edges: Edge[], targetId: string): Edge[] => {
  return edges.filter((edge) => edge.target === targetId);
};

export const findElementById = (
  elements: FlowElement[],
  id: string
): FlowElement => {
  const value: FlowElement | undefined = elements.find(
    (element) => element.id === id
  );
  if (!value) {
    throw new Error("No values found for element");
  }
  return value;
};

/**
 * Updates the value by id for the provided path.
 * @param elements
 * @param id
 * @param value
 * @param path
 */
export const updateElementById = <T>(
  elements: FlowElement[],
  id: string,
  value: T,
  path?: string
): FlowElement[] => {
  const updatedElements: FlowElement[] = [];

  elements.forEach((element) => {
    let updatedElement = cloneDeep(element);
    if (element.id === id) {
      if (path) {
        set(updatedElement, path, value)
      } else {
        // @ts-ignore
        updatedElement = value;
      }
    }
    updatedElements.push(updatedElement);
  });

  return updatedElements;
};

export const getStageLabelFromStageType = (stageType: StageTypes) => {
  switch (stageType) {
    case "FORM_STAGE":
      return "New Form";
    case "APPROVAL_STAGE":
      return "New Approval Stage";
    case "CONDITION_STAGE":
      return "New Condition Stage";
    default:
      return defaultStageLabel;
  }
};
