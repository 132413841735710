import {
    descriptionPath, getSupportedFieldsForWorkflowItemDataReport, namePath,
    ReportEntityType, TableChartColumn, TableChartDetails,
    WorkflowResponse
} from "@eazy2biz/common-util";

import React, {useEffect, useState} from "react";

import styles from './ConfigureWorkflowTableChartDetails.module.css'
import {cloneDeep} from "lodash";
import {DraggableListComponent, DragListItemConfig, InputField} from "@eazy2biz-ui/common-components";
import {Tag} from "antd";
import {StrictBuilder} from "builder-pattern";

export const ConfigureWorkflowTableChartDetails = (
    props : {
        entityType: ReportEntityType;
        entityId: string;
        workflowResponse: WorkflowResponse;
        showChartConfigureFormFieldErrors: boolean;

        tableChartDetails : TableChartDetails,
        setTableChartDetails : (tableChartDetails : TableChartDetails) => void;
    }
) : JSX.Element => {

    const {
        entityType,
        entityId,
        workflowResponse,
        showChartConfigureFormFieldErrors,
        tableChartDetails,
        setTableChartDetails,
    } = props;

    const [workflowTableColumnDraggableItems, setWorkflowTableColumnDraggableItems] = useState<DragListItemConfig[]>(
        [
            ...getSupportedFieldsForWorkflowItemDataReport(workflowResponse.fields)
                .map(
                    (field) => ({
                        id: field._id,
                        name: field.name,
                        selected: true,
                        draggable: true,
                        item: field,
                    })
                )
        ]
    );

    useEffect(() => {

        tableChartDetails.tableColumns =
            workflowTableColumnDraggableItems
                .filter(workflowTableColumnDraggableItem => workflowTableColumnDraggableItem.selected)
                .map(
                    workflowTableColumnDraggableItem =>
                        StrictBuilder<TableChartColumn>()
                            .columnName(workflowTableColumnDraggableItem.name)
                            .columnDataReferenceId(workflowTableColumnDraggableItem.id)
                            .build()
                );

        setTableChartDetails(cloneDeep(tableChartDetails))

    }, [workflowTableColumnDraggableItems])

    return (
        <>
            <div className={styles.tableColumnConfigurationBox}>
                <div className={styles.configureTableColumns}> Configure Table Columns </div>
                <DraggableListComponent
                    listItems={workflowTableColumnDraggableItems}
                    setListItems={setWorkflowTableColumnDraggableItems}
                    listItemWrapperClassName={styles.tableRowConfigDraggableListItemWrapper}
                    customItemRender={(item) => (
                        <div className={styles.tableRowConfigDraggableListItem}>
                            <InputField
                                id={item.id}
                                highlightFieldError={showChartConfigureFormFieldErrors}
                                isInValid={!item.name}
                                initialValue={item.name || ''}
                                type="text"
                                onChange={(e) => {
                                    item.name = e.target.value;
                                    setWorkflowTableColumnDraggableItems(
                                        workflowTableColumnDraggableItems.map(
                                            listItem =>
                                                listItem.id === item.id ? item : listItem
                                        )
                                    )
                                }}
                                required={true}
                            />

                            <Tag className={styles.tableRowConfigDraggableListItemNameTag} color="#1677FF">
                                {
                                    workflowResponse.fields.find(field => field._id === item.id)?.name || ''
                                }
                            </Tag>
                        </div>
                    )}
                />
            </div>
        </>
    )
}
