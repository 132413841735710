import {
    Condition,
    ConditionExpression,
    ExpressionType,
    Field,
    FieldPlaceholder,
    FieldPlaceholderType,
    LogicalOperator,
    Operators,
    ApprovalValues
} from "@eazy2biz/common-util";
import {getApprovalFieldIdForStage} from "../../../formBuilderHelpers/FormFieldHelper";

/**
 * Returns System conditions required for approval stage exit stage movement.
 * @param stageId
 * @param formFields
 */
export const getSystemConditionsForApprovalStage = (stageId: string, formFields: Field[]): ConditionExpression => {

    const approvalFieldId = getApprovalFieldIdForStage(formFields, stageId);

    const placeholder: FieldPlaceholder = new FieldPlaceholder();
    placeholder.placeholderType = FieldPlaceholderType.CARD_FORM;
    placeholder.key = approvalFieldId;
    placeholder.entityId = '';
    placeholder.entityClassId = '';

    return new ConditionExpression(
        LogicalOperator.AND,
        ExpressionType.SUB,
        [new Condition(placeholder.convertToString(), Operators.EQUAL, ApprovalValues.APPROVED)]
    );
};

export const getDefaultConditionExpression = (): ConditionExpression => {
    const initialCondition = new ConditionExpression();
    initialCondition.type = ExpressionType.SUB;
    initialCondition.logicalOp = LogicalOperator.AND;
    initialCondition.subExpressions = [getDefaultCondition()];

    return initialCondition;
};

export const getDefaultCondition = () => {
  const condition = new Condition();
  condition.op = Operators.EQUAL;
  return condition;
};

/**
 * Cleans Condition for empty conditions.
 * @param conditionExp
 */
export const cleanCondition = (condition: Condition) => {
    if (condition.firstValue && condition.firstValue !== '' && condition.secondValue) {
        return condition;
    }

    return;
};

/**
 * Cleans ConditionExpression for empty conditions.
 * @param conditionExp
 */
export const cleanConditionExpression = (conditionExp: ConditionExpression): ConditionExpression | undefined => {
    const subExpressions: Condition[] = [];
    conditionExp.subExpressions.forEach((condition) => {
        const cleanedCondition = cleanCondition(condition as Condition);
        if (cleanedCondition) {
            subExpressions.push(cleanedCondition);
        }
    });

   conditionExp.subExpressions = subExpressions;

   if (conditionExp.subExpressions.length === 0) {
       return undefined;
   }

   return conditionExp;

};
