import React from 'react';
import { DragListItemConfig } from './DragListItemConfig';
import { DragListItem } from './components/DragListItem/DragListItem';
import styles from './DraggableListComponent.module.css';
import { cloneDeep } from 'lodash';
import { DragDropContext, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd';
import { arrayMoveImmutable } from 'array-move';

const LIST_DROP_ZONE_ID = 'listDropId';
export const DraggableListComponent = (props: PropTypes) => {

  const updateItem = (item: DragListItemConfig) => {
    const items = cloneDeep(props.listItems);
    props.setListItems(items.map((element) => element.id === item.id ? item : element));
  };

  const handleElementDrop = (result: DropResult) => {
    if (!result.destination || result.destination.droppableId !== LIST_DROP_ZONE_ID) {
      return;
    }

    props.setListItems(arrayMoveImmutable(props.listItems, result.source.index, result.destination.index));
  };

  const renderItem = (item: DragListItemConfig, index: number) => {
    return (
      <DragListItem
        key={item.id}
        item={item}
        index={index}
        updateItem={updateItem}
        listItemWrapperClassName={props.listItemWrapperClassName}
        customItemRender={props.customItemRender}
      />
    );
  };

  const renderItems = (provided: DroppableProvided) => {
    return (
      <div {...provided.droppableProps} ref={provided.innerRef} >
        {props.listItems.map(renderItem)}
        {provided.placeholder}
      </div>
    );
  }

  return (
    <div className={styles.dragListItemWrapper}>
      <DragDropContext onDragEnd={handleElementDrop}>
        <Droppable droppableId={LIST_DROP_ZONE_ID}>
          {renderItems}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

type PropTypes = {
  listItems: DragListItemConfig[];
  setListItems: (listItems: DragListItemConfig[]) => void;
  listItemWrapperClassName?: string
  customItemRender?: (item : DragListItemConfig) => React.ReactNode;
};
