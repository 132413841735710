import {getStageNameByIdAndWorkflowId, getUserNameById, getFormattedDate, getUserStringByIdArray} from "@eazy2biz/common-package-ui";
import {CardResponse} from "@eazy2biz/common-util";
import {getColumnsForItems, ItemHeaders} from "../configs/ItemsTableConfig";
import {RowMetaData, generateTableDataFromItems, AppliedFilterItemConfig, AppliedFilterConfig} from "@eazy2biz-ui/common-components";
import { cloneDeep } from "lodash";
import {ItemFilterConfig} from "../components/workflow/Items/ItemFilterComponents/ItemFilterMenu";
import {ITEM_FILTER_MENU} from "../../contents/DisplayContent";

export const getTableDataForItems = (items: CardResponse[]) => {
    return generateTableDataFromItems(items, getColumnsForItems(), getValueForItem, getMetaDataForItem);
}

const getValueForItem = (item: CardResponse, key: string) => {
    switch (key) {
        case ItemHeaders.NAME:
            return item.details.name;
        case ItemHeaders.STAGE_NAME:
            return getStageNameByIdAndWorkflowId(item.workflowId, item.stageId);
        case ItemHeaders.DUE_ON:
            return getFormattedDate(item.dueDate);
        case ItemHeaders.CREATED_ON:
            return getFormattedDate(item.metaData.createdDate);
        case ItemHeaders.UPDATED_ON:
            return getFormattedDate(item.metaData.lastUpdatedDate);
        case ItemHeaders.UPDATED_BY:
            return getUserNameById(item.metaData.lastUpdatedBy);
        case ItemHeaders.ASSIGNEE:
            return getUserStringByIdArray(item.assignee, ', ', item.assigneeUserGroupIds);
        case ItemHeaders.CREATED_BY:
            return getUserNameById(item.metaData.createdBy);
        default:
            return "";
    }

};

const getMetaDataForItem = (item: CardResponse): RowMetaData => {
    return {
        id: item._id
    };
};

export const getAppliedFilterConfigFromItemFilters = (getFilter: () => ItemFilterConfig, setFilter: (filter: ItemFilterConfig) => void): AppliedFilterConfig => {
    const appliedFilters: AppliedFilterItemConfig[] = [];

    const filterConfigCopy = cloneDeep(getFilter());

    filterConfigCopy.pendingOnly && appliedFilters.push({
        title: ITEM_FILTER_MENU.PENDING_ONLY,
        onRemove: () => {
            filterConfigCopy.pendingOnly = false;
            setFilter(filterConfigCopy);
        }
    });

    filterConfigCopy.createdDateBefore && appliedFilters.push({
        title: ITEM_FILTER_MENU.CREATED_BEFORE,
        onRemove: () => {
            filterConfigCopy.createdDateBefore = undefined;
            setFilter(filterConfigCopy);
        }
    });

    filterConfigCopy.completedDateBefore && appliedFilters.push({
        title: ITEM_FILTER_MENU.COMPLETED_BEFORE,
        onRemove: () => {
            filterConfigCopy.completedDateBefore = undefined;
            setFilter(filterConfigCopy);
        }
    });

    filterConfigCopy.dueDate && appliedFilters.push({
        title: ITEM_FILTER_MENU.DUE_BEFORE,
        onRemove: () => {
            filterConfigCopy.dueDate = undefined;
            setFilter(filterConfigCopy);
        }
    });

    filterConfigCopy.assigneeUserId && appliedFilters.push({
        title: getUserNameById(filterConfigCopy.assigneeUserId) + ITEM_FILTER_MENU.ONLY,
        onRemove: () => {
            filterConfigCopy.assigneeUserId = undefined;
            setFilter(filterConfigCopy);
        }
    });

    filterConfigCopy.advancedFilters.length && appliedFilters.push({
        title: ITEM_FILTER_MENU.ADVANCED_FILTER,
        onRemove: () => {
            filterConfigCopy.advancedFilters = [];
            setFilter(filterConfigCopy);
        }
    });

    filterConfigCopy.currentStages.length && appliedFilters.push({
        title: ITEM_FILTER_MENU.CURRENT_STAGE,
        onRemove: () => {
            filterConfigCopy.currentStages = [];
            setFilter(filterConfigCopy);
        }
    });

    return {
      filters: appliedFilters
    };
};

