import { RestServices } from "@eazy2biz/common-package-ui";
import {
  TemplateCategory, TemplateCreateRequest,
  TemplateResponse
} from "@eazy2biz/common-util";

const TEMPLATE_SERVICE = () => RestServices.getInstance().getTemplateService();

export const getAllPDFTemplates = (): Promise<TemplateResponse[]> => {
  return TEMPLATE_SERVICE().getAllTemplates(TemplateCategory.PDF);
};

export const getPdfTemplateById = (templateId: string) => {
  return TEMPLATE_SERVICE().getTemplateById(TemplateCategory.PDF, templateId);
};

export const savePdfTemplate = (templateCreateRequest: TemplateCreateRequest) => {
  return TEMPLATE_SERVICE().createTemplate(TemplateCategory.PDF, templateCreateRequest);
};
